import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublishDialog } from '../dialogpublish';


import { Utils } from '../utils';

@Component({
  selector: 'app-dialogmenu',
  templateUrl: './dialogmenu.component.html',
  styleUrls: ['./dialogmenu.component.scss']
})
export class DialogmenuComponent implements OnInit {
  project: any;
  trafo = 0;

  constructor(public dialogRef: MatDialogRef<PublishDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public afs: AngularFirestore) {

    this.project = this.data.project;
    if (!this.project.menuSettings) {
      this.project.menuSettings = {};

      this.project.menuSettings.accentcolor = "#ff0000";;
      this.project.menuSettings.textcolor = "#111111";
      this.project.menuSettings.buttontextcolor = "#eeeeee";
      this.project.menuSettings.backgroundcolor = "#ffffff";

      this.project.menuSettings.menutype = "movable";
      this.project.menuSettings.menufloat = "left";

    }

    this.valuechange();
  }

  trafo2 = 0;
  valuechange() {
    if (this.project.menuSettings.menutype == "movable") {

    }

    if (this.project.menuSettings.menufloat == "right") this.trafo = 78; else this.trafo = 0;
    if (this.project.menuSettings.menufloat == "right" && this.project.menuSettings.menutype == "docked") this.trafo2 = 8; else this.trafo2 = 0;




  }

  ngOnInit(): void {
  }

  onChange() {
    Utils.basecsschange(this.project.id, this.project.menuSettings);
  }

}
