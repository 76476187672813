import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
export class UserTrackingService {
    private userId: string | null = null;
    private sessionId: string;
    private sessionStartTime: number;
    private lastActionTime: number;

    constructor(
        private afs: AngularFirestore,
        private auth: AngularFireAuth
    ) {
        // Generate a unique session ID for this session
        this.sessionId = this.generateUniqueId();
        this.sessionStartTime = Date.now();
        this.lastActionTime = this.sessionStartTime;

        // Get the current user ID if authenticated
        this.auth.user.subscribe(user => {
            this.userId = user ? user.uid : null;

            // Log session start
            this.trackEvent('session_start', {
                browser: navigator.userAgent,
                screenSize: {
                    width: window.screen.width,
                    height: window.screen.height
                },
                viewportSize: {
                    width: window.innerWidth,
                    height: window.innerHeight
                }
            });
        });

        // Track when the user leaves the page
        window.addEventListener('beforeunload', () => {
            this.trackEvent('session_end', {
                sessionDuration: Date.now() - this.sessionStartTime
            });
        });
    }

    /**
     * Track a user event
     * @param eventName Name of the event
     * @param eventData Additional data for the event
     */
    trackEvent(eventName: string, eventData: any = {}) {
        // Don't track if no user is logged in and it's not a session event
        if (!this.userId && !eventName.startsWith('session_')) {
            return;
        }

        const now = Date.now();
        const timeSinceLastAction = now - this.lastActionTime;
        this.lastActionTime = now;

        const eventRecord = {
            userId: this.userId || 'anonymous',
            sessionId: this.sessionId,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            clientTimestamp: now,
            eventName,
            timeSinceLastAction,
            ...eventData
        };

        // Add to analytics collection
        this.afs.collection('analytics').add(eventRecord)
            .catch(error => console.error('Error tracking event:', error));
    }

    /**
     * Track model creation
     */
    trackModelCreation(modelType: string, dimensions: any) {
        this.trackEvent('model_created', {
            modelType,
            dimensions
        });
    }

    /**
     * Track when a primitive is added
     */
    trackPrimitiveAdded(primitiveType: string) {
        this.trackEvent('primitive_added', {
            primitiveType
        });
    }

    /**
     * Track sheet changes
     */
    trackSheetChange(changeType: string, rowCount: number, colCount: number) {
        this.trackEvent('sheet_changed', {
            changeType,
            rowCount,
            colCount
        });
    }

    /**
     * Track camera movements
     */
    trackCameraMovement(movementType: string, duration: number) {
        this.trackEvent('camera_movement', {
            movementType,
            duration
        });
    }

    /**
     * Track search operations
     */
    trackSearch(query: string, resultCount: number) {
        this.trackEvent('search_performed', {
            query,
            resultCount
        });
    }

    /**
     * Track model export
     */
    trackExport(exportType: string, objectCount: number) {
        this.trackEvent('model_exported', {
            exportType,
            objectCount
        });
    }

    /**
     * Track when AI features are used
     */
    trackAIUsage(aiFeature: string, promptLength: number) {
        this.trackEvent('ai_used', {
            aiFeature,
            promptLength
        });
    }

    /**
     * Track render performance
     */
    trackRenderPerformance(fps: number, objectCount: number, complexity: string) {
        this.trackEvent('render_performance', {
            fps,
            objectCount,
            complexity
        });
    }

    /**
     * Generate a unique ID for session tracking
     */
    private generateUniqueId(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
} 