<!-- <p>overlays works!</p>
{{overlays | json}} -->
<div *ngFor="let o of overlays">
    <div *ngIf="o.typ === 'image'">

        <img [hidden]="o.visible==false || o.visible==0" style="position:relative" [style.left]="o.x" [style.top]="o.y" [style.width]="o.width" [style.height]="o.height" [src]="o.url" />

    </div>

    <!-- {{o|json}} -->
    <div *ngIf="o.typ === 'text2d'">
        <div [hidden]="o.visible==false || o.visible==0" style="position:relative" [style.left]="o.x" [style.top]="o.y" [innerHTML]="o.text" [style.backgroundColor]="o.backgroundcolor" [style.fontSize]="o.fontsize"
            [style.color]="o.color"></div>
    </div>


    <div *ngIf="o.typ === 'rectangle2d'">
        <div [hidden]="o.visible==false || o.visible==0" style="position:relative" [style.left]="o.x" [style.top]="o.y" [style.width]="o.width" [style.height]="o.height" [style.backgroundColor]="o.backgroundcolor"></div>
    </div>

    <div *ngIf="o.typ === 'chart'">
        <!-- {{o|json}} -->
        <div [hidden]="o.visible==false || o.visible==0" style="position:relative" [style.left]="o.x" [style.top]="o.y" [style.width]="o.width" [style.height]="o.height">
            <ngx-charts-bar-vertical [style.width]="o.width" [style.height]="o.height" [animations]="false" [results]="o.chartvalues" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical>
        </div>
    </div>


</div>