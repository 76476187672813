export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCl4_ewhEIqOK8cxkhZbylK4lpIqti1hdc",
    authDomain: "xbuild3d.firebaseapp.com",
    databaseURL: "https://xbuild3d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "xbuild3d",
    storageBucket: "xbuild3d.appspot.com",
    messagingSenderId: "45429560595",
    appId: "1:45429560595:web:48e46364951476b1a8f6e6",
    measurementId: "G-8BT108BTEF"
  }
};
