import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Upload } from '../utils/upload';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-uploadwidget',
  templateUrl: './uploadwidget.component.html',
  styleUrls: ['./uploadwidget.component.css']
})
export class UploadwidgetComponent implements OnInit {
  model: any;
  selectedFiles: FileList;
  public currentUpload: Upload;

  @Input()
  label: string;
  @Input()
  path: string;
  @Output()
  uploadCompleted = new EventEmitter();
  @Output()
  uploadFailed = new EventEmitter();

  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore, public _router: Router) {
    this.model = {};
  }

  ngOnInit() { }

  public uploadFile2(event, f) {
    console.log('upload  filevar ', f, event.target.files.item(0));
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    if (file.size > 50 * 1024 * 1024) {
      alert('Datei zu groß! Datei muss kleiner als 50MB sein.');
      return;
    }
    this.currentUpload = new Upload(file);
    this.pushUpload(this.currentUpload);
  }

  pushUpload(upload: Upload) {
    const storageRef = firebase.storage().ref();
    //  this.basePath = this.afAuth.auth.currentUser.email + '/' + databasepropertyname;
    const uploadTask = storageRef.child(`${this.path}/${upload.file.name}`).put(upload.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        // upload in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        upload.progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
      },
      error => {
        // upload failed
        this.uploadFailed.emit(error);
      },
      async () => {
        console.log('uplaodtask ', uploadTask);
        let uploadresult = {
          url: await uploadTask.snapshot.ref.getDownloadURL().then(),
          name: upload.file.name
        };
        this.uploadCompleted.emit(uploadresult);
        this.currentUpload = undefined;
        this.selectedFiles = undefined;
        this.model = {};
        return undefined;
      }
    );

    // uploadTask = await storageRef
    //   .child(sessionIdwithCounter + "generatedPreview.png")
    //   .put(src, { contentType: "image/png" })
    //   .then();
    // this.layout.generatedPreview = await uploadTask.ref.getDownloadURL().then();
  }
}
