import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';

export interface PartListOptions {
  includeUserData: boolean;
  exportType: 'clipboard' | 'excel';
  includePosition: boolean;
  includeRotation: boolean;
  includeScale: boolean;
  includeParent: boolean;
  customFields: string[];
}

export interface PartListNode {
  name: string;
  path: string;
  userData: any;
  type: string;
  visible: boolean;
  position: THREE.Vector3;
  rotation: THREE.Euler;
  scale: THREE.Vector3;
  parent?: {
    name: string;
    path: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PartListService {

  constructor(private toastr: ToastrService) { }

  /**
   * Generate a part list from the flattened nodes
   */
  generatePartList(flattenedNodes: PartListNode[], options: PartListOptions): string[][] {
    if (!flattenedNodes || flattenedNodes.length === 0) {
      return [];
    }

    // Create headers
    const headers = ['Name', 'Type'];

    if (options.includePosition) {
      headers.push('Position X', 'Position Y', 'Position Z');
    }

    if (options.includeRotation) {
      headers.push('Rotation X', 'Rotation Y', 'Rotation Z');
    }

    if (options.includeScale) {
      headers.push('Scale X', 'Scale Y', 'Scale Z');
    }

    if (options.includeParent) {
      headers.push('Parent Name', 'Parent Path');
    }

    headers.push('Row Data', 'Comment Row');

    if (options.includeUserData) {
      headers.push('Other User Data');
    }

    // Add custom fields
    if (options.customFields && options.customFields.length > 0) {
      options.customFields.forEach(field => {
        if (!headers.includes(field)) {
          headers.push(field);
        }
      });
    }

    // Create rows
    const rows: string[][] = [headers];

    // Create rows for each node
    flattenedNodes.forEach(node => {
      const position = node.position || { x: 0, y: 0, z: 0 };
      const rotation = node.rotation || { x: 0, y: 0, z: 0 };
      const scale = node.scale || { x: 1, y: 1, z: 1 };

      // Create a copy of userData without faceCenters, row, and commentrow
      const userDataCopy = { ...node.userData };
      delete userDataCopy.faceCenters;
      const rowData = userDataCopy.row || [];
      const commentRowData = userDataCopy.commentrow || [];
      delete userDataCopy.row;
      delete userDataCopy.commentrow;

      const row: string[] = [
        node.name || 'Unnamed',
        node.type || 'Unknown'
      ];

      if (options.includePosition) {
        row.push(
          position.x.toFixed(2),
          position.y.toFixed(2),
          position.z.toFixed(2)
        );
      }

      if (options.includeRotation) {
        row.push(
          rotation.x.toFixed(2),
          rotation.y.toFixed(2),
          rotation.z.toFixed(2)
        );
      }

      if (options.includeScale) {
        row.push(
          scale.x.toFixed(2),
          scale.y.toFixed(2),
          scale.z.toFixed(2)
        );
      }

      if (options.includeParent && node.parent) {
        row.push(
          node.parent.name || 'Unnamed',
          node.parent.path || ''
        );
      } else if (options.includeParent) {
        row.push('', '');
      }

      row.push(
        JSON.stringify(rowData),
        JSON.stringify(commentRowData)
      );

      if (options.includeUserData) {
        row.push(JSON.stringify(userDataCopy || {}));
      }

      // Add custom fields
      if (options.customFields && options.customFields.length > 0) {
        options.customFields.forEach(field => {
          if (!headers.slice(0, -1).includes(field)) { // Exclude fields already added
            const value = this.getNestedProperty(node, field) || '';
            row.push(typeof value === 'object' ? JSON.stringify(value) : String(value));
          }
        });
      }

      rows.push(row);
    });

    return rows;
  }

  /**
   * Export part list to clipboard
   */
  exportToClipboard(rows: string[][]): Promise<void> {
    // Convert to tab-separated values
    const partList = rows.map(row => row.join('\t')).join('\n');

    return navigator.clipboard.writeText(partList)
      .then(() => {
        this.toastr.success('Part list copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy part list:', err);
        this.toastr.error('Failed to copy part list to clipboard');
        throw err;
      });
  }

  /**
   * Export part list to Excel file
   */
  exportToExcel(rows: string[][], filename: string = 'part-list.xlsx'): void {
    try {
      const worksheet = XLSX.utils.aoa_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Parts');

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save file
      saveAs(data, filename);

      this.toastr.success('Part list exported to Excel file');
    } catch (err) {
      console.error('Failed to export part list to Excel:', err);
      this.toastr.error('Failed to export part list to Excel');
    }
  }

  /**
   * Get a nested property from an object using a dot-notation path
   */
  private getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((prev, curr) => {
      return prev && prev[curr] !== undefined ? prev[curr] : null;
    }, obj);
  }
}
