import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CSG } from 'three-csg-ts';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { createBackground } from 'three-vignette-background-esm/dist/noise-background'
import { ThreeUtils } from "./threeutils";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';


class BaseScene {
  sceneSettings = {
    exposure: 1,
    background1: "#ffffff",
    background2: "#ffffff",

    ambientIntensity: 3,
    ambientColor: "#ffffff",
    hemisphereIntensity: 0.3,
    hemisphereColor1: "#ffffff",
    hemisphereColor2: "#ffffff",
    directionalIntensity: 3,
    directionalColor: "#ffffff",
    envMap: "standard",
    showControls: true,
    showAnimations: false,
    showHelpers: true,
    showGrid: true
  }
  scaleX = 5;
  scaleY = 5;
  public grid: THREE.GridHelper;
  public axes: THREE.AxesHelper;
  public dirLabels: THREE.Mesh[];
  public grass: THREE.Mesh;
  public pmremGenerator: THREE.PMREMGenerator;
  private scene: THREE.Scene;
  public ambientLight: THREE.AmbientLight;
  constructor(scene: THREE.Scene, public renderer: any) {
    console.log('create basescene', scene);
    this.scene = scene;
    //scene.background = new THREE.Color().setRGB(0.9, 0.9, 0.9);

    //  renderer.outputEncoding =  THREE.sRGBEncoding;
    // vgl https://discoverthreejs.com/tips-and-tricks/
    renderer.gammaFactor = 2.2;
    console.log("gammaFactor: " + renderer.gammaFactor);
    renderer.colorSpace = THREE.SRGBColorSpace;
    renderer.physicallyCorrectLights = false;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // const color = new Color(0x800080);
    // color.convertSRGBToLinear();


    this.addLights(scene, true);
    this.addHelpers(scene);
    // this.addLabels(scene);
    this.pmremGenerator = new THREE.PMREMGenerator(renderer as any);

    // TODO https://matheowis.github.io/HDRI-to-CubeMap/
    // https://stackoverflow.com/questions/64454022/is-it-possible-to-rotate-a-cube-environment-map-180-degrees-around-y-axis
    // px 90guzs   py pz    pz ny            nx py 180      ny nz180     nz ny90uzs



    scene.environment = new THREE.CubeTextureLoader()
      .setPath('assets/')
      .load(['px2.png', 'nx2.png',
        'py2.png', 'ny2.png',
        'pz2.png', 'nz2.png']);
    //  scene.background = scene.environment;
    const textureLoader = new THREE.TextureLoader();

    // var textureEquirec = textureLoader.load('assets/adams_place_bridge_1k.hdr');
    // textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
    // textureEquirec.colorSpace = THREE.SRGBColorSpace;
    // scene.background = textureEquirec;
    // scene.environment = textureEquirec;


    // new RGBELoader().setDataType(THREE.UnsignedByteType).setPath('assets/').load('adams_place_bridge_1k.hdr', (texture) => {
    //   texture.mapping = THREE.EquirectangularReflectionMapping;
    //   var envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
    //   scene.background = envMap;
    //   scene.environment = envMap;
    //   texture.dispose();
    // });
    // this.pmremGenerator.compileEquirectangularShader();


    // SKYDOME

    // new RGBELoader()
    //   .setDataType(THREE.UnsignedByteType)
    //   .setPath('assets/textures/')
    //   .load('kloppenheim_03_4k.hdr', (texture) => {
    //     const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
    //     //scene.background = envMap;
    //     texture.dispose();
    //   });

  }
  updateScene(sceneSettings, camera) {
    // sceneSettings = {
    //   exposure: 1,
    //   background1: "#ffffff",
    //   background2: "#ffffff",

    //   ambientIntensity: 1,
    //   ambientColor: "#ffffff",
    //   hemisphereIntensity: 1,
    //   hemisphereColor1: "#ffffff",
    //   hemisphereColor2: "#ffffff",
    //   directionalIntensity: 1,
    //   directionalColor: "#ffffff",
    //   envMap: "standard"

    // }


    //   this.scene.background = createBackground(sceneSettings.background1, sceneSettings.background2, sceneSettings.exposure);
    //    this.scene.environment = sceneSettings.envMap;




    this.dirLight.color = new THREE.Color(sceneSettings.directionalColor);
    this.dirLight.intensity = sceneSettings.directionalIntensity;
    this.dirLight.position.set(Math.cos(-sceneSettings.dirAngle) * 10000, Math.sin(-sceneSettings.dirAngle) * 10000, 10000);
    this.dirLight.target.position.set(0, 0, 0);
    //   this.baseScene.dirLightHelper.parent.updateMatrixWorld();
    this.dirLightHelper.update();

    this.hemiLight.color = new THREE.Color(sceneSettings.hemisphereColor1);
    this.hemiLight.groundColor = new THREE.Color(sceneSettings.hemisphereColor2);
    this.hemiLight.intensity = sceneSettings.hemisphereIntensity;

    this.ambientLight.color = new THREE.Color(sceneSettings.ambientColor);
    this.ambientLight.intensity = sceneSettings.ambientIntensity;




    //this.scene.fog = new THREE.Fog(sceneSettings.background1, 1, 1000);
    this.scene.fog.color = new THREE.Color(sceneSettings.background1);
    this.scene.background = new THREE.Color(sceneSettings.background2);


    // this.scene.fog.density = 0.01;
    // this.scene.fog.near = 1;
    // this.scene.fog.far = 1000;
    // this.scene.fog.name = "baseScene";
    // this.scene.fog.visible = true;

  }
  getCubeMapTexture(environment) {
    const { path } = environment;

    // no envmap
    if (!path) return Promise.resolve({ envMap: null });

    return new Promise((resolve, reject) => {

      new RGBELoader().setDataType(UnsignedByteType as any).load(path, (texture) => {

        const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
        this.pmremGenerator.dispose();

        resolve({ envMap });

      }, undefined, reject);

    });

  }
  addHelpers(scene) {
    // Achsen
    this.axes = new THREE.AxesHelper(100 * this.scaleX);
    this.axes.name = "baseScene";
    scene.add(this.axes);
    this.axes.visible = true;
    (this.axes as any).isHelper = true;
    // Grid
    const size = 2000 * this.scaleX;
    const divisions = 40;
    this.grid = new THREE.GridHelper(size, divisions, 0xDDDDDD, 0xEEEEEE);
    this.grid.position.z = -1;
    this.grid.rotation.x = Math.PI / 2;
    this.grid.receiveShadow = true;
    scene.add(this.grid);
    this.grid.name = "baseScene";
    this.grid.visible = true;
    this.grid.userData.isHelper = true;

    // bodentextur


    const floor_material = new THREE.ShadowMaterial({ color: 0x000000, opacity: 0.2 });
    //    const floor_material = new THREE.MeshPhongMaterial({ color: 0xffffff });
    const loader = new THREE.TextureLoader();
    //  const Texture = loader.load("/assets/GrassGreenTexture0003.jpg", texture => { floor_material.map = texture; floor_material.needsUpdate = true; });
    //  Texture.wrapS = Texture.wrapT = THREE.RepeatWrapping;
    // Texture.repeat.set(44, 44);
    const floor_geometry = new THREE.PlaneGeometry(9000 * this.scaleX, 9000 * this.scaleX);
    // floor_geometry.receiveShadow = true;
    this.grass = new THREE.Mesh(floor_geometry, floor_material);
    this.grass.position.y -= 10.01;
    //   floor.position.set(0, -2, 0);
    this.grass.rotation.x -= Math.PI / 2;
    this.grass.receiveShadow = true;
    this.grass.name = "baseScene";
    this.grass.castShadow = false;

    this.grass.userData.isHelper = true;
    scene.add(this.grass);
    this.grass.visible = false;

    // var box = new THREE.Box3().setFromObject(this.axes);
    // var m = ThreeUtils.create3DMarker(box, 100, 50, 120, 0xf08a00);
    // this.scene.add(m);
    // var m2 = ThreeUtils.create3DMarker(box, 200, 8, 20, 0xff0000);
    // this.scene.add(m2);

  }

  hemiLight: THREE.HemisphereLight;
  dirLight: THREE.DirectionalLight;
  public dirLightHelper: THREE.DirectionalLightHelper;
  public hemiLightHelper: THREE.HemisphereLightHelper;
  addLights(scene, showHelper = true) {

    this.ambientLight = new THREE.AmbientLight(0xffffff, 3);
    this.scene.add(this.ambientLight);

    this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    //  hemiLight.color.setHSL(1, 1, 1);
    // hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    this.hemiLight.position.set(0, 0, 1000 * this.scaleX);
    //   hemiLight.castShadow = true;
    this.hemiLight.name = "baseScene";
    this.hemiLight.intensity = 0.5;
    scene.add(this.hemiLight);
    this.hemiLightHelper = new THREE.HemisphereLightHelper(this.hemiLight, 100);
    // if (showHelper)
    this.hemiLightHelper.name = "hemilighthelper";

    scene.add(this.hemiLightHelper);
    this.hemiLightHelper.visible = false;
    // var light = new THREE.AmbientLight(0xffffff); // soft white light
    // this.scene.add(light);


    this.dirLight = new THREE.DirectionalLight(0xffffff, 1);
    this.dirLight.color.setHSL(1, 1, 1);
    this.dirLight.position.set(-1, -1.45, 1.75);
    this.dirLight.position.multiplyScalar(3000);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.mapSize.width = 2048;  // TODO: ausreichend?
    this.dirLight.shadow.mapSize.height = 2048;
    const d = 9500;

    this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.top = d;
    this.dirLight.shadow.camera.bottom = -d;
    this.dirLight.shadow.camera.far = 95000;
    this.dirLight.shadow.bias = -.0010;
    this.dirLight.name = "baseScene";
    this.dirLight.intensity = 3;
    this.dirLightHelper = new THREE.DirectionalLightHelper(this.dirLight, 1000);
    scene.add(this.dirLight);
    scene.add(this.dirLight.target);

    //  if (showHelper)
    scene.add(this.dirLightHelper);
    this.dirLightHelper.visible = false;
  }


  addLabels(scene) {
    this.dirLabels = [];

    // schriften: https://github.com/mrdoob/three.js/tree/master/examples/fonts
    const loader2 = new FontLoader();
    loader2.load(
      "assets/Roboto_Bold.json",
      font => {
        // tslint:disable-next-line: one-variable-per-declaration
        let xMid: number,
          text: THREE.Mesh;

        const color = 0x006699;
        const matDark = new THREE.LineBasicMaterial({ color, side: THREE.DoubleSide });
        const matLite = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 0.7, side: THREE.DoubleSide });

        let shapes = font.generateShapes("Norden", 60 * this.scaleX);
        let geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        geometry.translate(xMid, 0, 0);
        text = new THREE.Mesh(geometry, matLite);
        text.position.z = -1000 * this.scaleX;
        text.name = "baseScene";
        this.dirLabels.push(text);
        scene.add(text);

        shapes = font.generateShapes("Osten", 60 * this.scaleX);
        geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        geometry.translate(xMid, 0, 0);
        text = new THREE.Mesh(geometry, matLite);
        text.position.x = 1000 * this.scaleX;
        text.name = "baseScene";
        this.dirLabels.push(text);
        scene.add(text);

        shapes = font.generateShapes("Süden", 60 * this.scaleX);
        geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        geometry.translate(xMid, 0, 0);
        text = new THREE.Mesh(geometry, matLite);
        text.position.z = 1000 * this.scaleX;
        text.name = "baseScene";
        this.dirLabels.push(text);
        scene.add(text);

        shapes = font.generateShapes("Westen", 60 * this.scaleX);
        geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        geometry.translate(xMid, 0, 0);
        text = new THREE.Mesh(geometry, matLite);
        text.position.x = -1000 * this.scaleX;
        text.name = "baseScene";
        this.dirLabels.push(text);
        scene.add(text);


      }
    ); // end load function
  }
  // GROUND

  // var groundGeo = new THREE.PlaneBufferGeometry(10000, 10000);
  // var groundMat = new THREE.MeshLambertMaterial({ color: 0xffffff });
  // groundMat.color.setHSL(0.095, 1, 0.75);

  // var ground = new THREE.Mesh(groundGeo, groundMat);
  // ground.position.y = -33;
  // ground.rotation.x = -Math.PI / 2;
  // ground.receiveShadow = true;
  // scene.add(ground);

  // SKYDOME

  // var vertexShader = document.getElementById("vertexShader").textContent;
  // var fragmentShader = document.getElementById("fragmentShader").textContent;
  // var uniforms = {
  //   topColor: { value: new THREE.Color(0x0077ff) },
  //   bottomColor: { value: new THREE.Color(0xffffff) },
  //   offset: { value: 33 },
  //   exponent: { value: 0.6 }
  // };
  // uniforms["topColor"].value.copy(hemiLight.color);

  // scene.fog.color.copy(uniforms["bottomColor"].value);

  // var skyGeo = new THREE.SphereBufferGeometry(4000, 32, 15);
  // var skyMat = new THREE.ShaderMaterial({
  //   uniforms: uniforms,
  //   vertexShader: vertexShader,
  //   fragmentShader: fragmentShader,
  //   side: THREE.BackSide
  // });

  // var sky = new THREE.Mesh(skyGeo, skyMat);
  // scene.add(sky);
}

export { BaseScene };











function UnsignedByteType(UnsignedByteType: any) {
  throw new Error("Function not implemented.");
}
//  //extruebeispiel
//   const length = 40;
//   const width = 20;
//   const shape = new THREE.Shape();
//   shape.moveTo(10, 10);
//   shape.lineTo(10, width);
//   shape.lineTo(length, width);
//   shape.lineTo(length, 0);
//   shape.lineTo(10, 10);
//   const extrudeSettings = {
//     steps: 2,
//     depth: 2,
//     bevelEnabled: false,
//     bevelThickness: 1,
//     bevelSize: 1,
//     bevelOffset: 0,
//     bevelSegments: 10
//   };
//   const geometry2 = new THREE.ExtrudeGeometry(
//     shape,
//     extrudeSettings
//   );
//   const material2 = new THREE.MeshPhongMaterial(
//     {
//       shadowSide: THREE.DoubleSide,
//       wireframe: false,
//       color: 0xaaaaaa,
//       specular: 0xaaaaaa,
//       shininess: 100
//       //   ambient: 0x000000,
//       //    wrapAround: false
//     }
//   ); // new THREE.MeshBasicMaterial({ color: 0x00ff00 });
//   const mesh2 = new THREE.Mesh(
//     geometry2,
//     material2
//   );
//   //  mesh2.position.z = 30;
//   mesh2.rotateOnAxis(new THREE.Vector3(0, 1, 0), THREE.MathUtils.DEG2RAD * 90);
//   mesh2.castShadow = true;
//   mesh2.receiveShadow = true;
//   this.testmesh = mesh2;
//   // this.scene.add(mesh2);
