import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialogdimensions',
  templateUrl: './dialogdimensions.component.html',
  styleUrls: ['./dialogdimensions.component.scss']
})
export class DialogdimensionsComponent implements OnInit {
  factor: number = 1;
  upaxis = "Z";
  favoriteSeason: string = "Spring";
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.factor = 1;
  }

  closedialog(value) {
    this.dialogRef.close(this.factor);
  }

}
