exports.examples = {
    root: [
        {
            id: "buildings",
            name: 'Buildings',
            children: [
                { id: "house", name: 'House' },
                { id: "commercial", name: 'Commercial' },
                { id: "industrial", name: 'Industrial' }
            ]
        },
        {
            id: "science",
            name: 'Science',
            children: [
                { id: "laboratory", name: 'Laboratory Equipment' },
                { id: "research", name: 'Research Instruments' },
                { id: "medical", name: 'Medical Devices' }
            ]
        },
        {
            id: "mechanical",
            name: 'Mechanical',
            children: [
                { id: "machinery", name: 'Machinery' },
                { id: "automotive", name: 'Automotive' },
                { id: "robotics", name: 'Robotics' }
            ]
        },
        {
            id: "electronics",
            name: 'Electronics',
            children: [
                { id: "pcb", name: 'PCB Design' },
                { id: "enclosures", name: 'Enclosures' },
                { id: "components", name: 'Components' }
            ]
        },
        {
            id: "consumer",
            name: 'Consumer Products',
            children: [
                { id: "furniture", name: 'Furniture' },
                { id: "appliances", name: 'Home Appliances' },
                { id: "sports", name: 'Sports Equipment' },
                { id: "toys", name: 'Toys & Games' }
            ]
        },
        {
            id: "aerospace",
            name: 'Aerospace',
            children: [
                { id: "aircraft", name: 'Aircraft Components' },
                { id: "drones", name: 'Drones & UAVs' },
                { id: "satellites", name: 'Satellite Parts' },
                { id: "space", name: 'Space Equipment' }
            ]
        },
        {
            id: "manufacturing",
            name: 'Manufacturing',
            children: [
                { id: "molds", name: 'Molds & Dies' },
                { id: "jigs", name: 'Jigs & Fixtures' },
                { id: "tooling", name: 'Tooling' },
                { id: "assembly", name: 'Assembly Lines' }
            ]
        },
        {
            id: "energy",
            name: 'Energy',
            children: [
                { id: "solar", name: 'Solar Systems' },
                { id: "wind", name: 'Wind Power' },
                { id: "hydro", name: 'Hydroelectric' },
                { id: "storage", name: 'Energy Storage' }
            ]
        },
        {
            id: "marine",
            name: 'Marine',
            children: [
                { id: "boats", name: 'Boats & Ships' },
                { id: "underwater", name: 'Underwater Equipment' },
                { id: "offshore", name: 'Offshore Structures' }
            ]
        },
        { id: "other", name: 'Other' }
    ]
};