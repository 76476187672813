
import * as THREE from "three";


class ThreeUtils {



    public static centerGltf(gltf) {
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());

        gltf.scene.position.x += (gltf.scene.position.x - center.x);
        gltf.scene.position.y += (gltf.scene.position.y - center.y);
        gltf.scene.position.z += (gltf.scene.position.z - center.z);
    }


    public static boudingBox(obj) {

        return this.getBoundingBox(obj);

        if (obj instanceof THREE.Mesh) {

            const geometry = obj.geometry;
            geometry.computeBoundingBox();
            return geometry.boundingBox;
        }

        if (obj instanceof THREE.Object3D) {

            const bb = new THREE.Box3();
            for (let i = 0; i < obj.children.length; i++) {
                bb.union(this.boudingBox(obj.children[i]));
            }
            return bb;
        }
        //        var bb1 = new THREE.BoxHelper(obj, 0xff44ff);

        let me = this;
        var bb1 = this.getBBox(obj);
        return bb1;
    }
    public static shapeCenterOfGravity(obj) {
        var bb = this.boudingBox(obj);
        var result = new THREE.Vector3();

        bb.getCenter(result);
        //     result.multiply(obj.scale);
        //   result.sub(obj.position);
        return result;
    }

    public static pointCameraTo(camera, controls, node) {
        var me = this;
        // Refocus camera to the center of the new object
        var COG = this.shapeCenterOfGravity(node);
        var v = new THREE.Vector3();
        v.subVectors(COG, controls.target);
        camera.position.addVectors(camera.position, v);
        // retrieve camera orientation and pass it to trackball  
        camera.lookAt(COG);
        controls.target.set(COG.x, COG.y, COG.z);
        return COG;
    };

    /**
     *  Zoom to object
     */
    public static zoomObject(camera, controls, node) {
        try {
            var bbox = this.boudingBox(node);
            if (bbox.isEmpty()) {
                return;
            }
            //  var COG = bbox.center();

            var cog = this.pointCameraTo(camera, controls, node);

            var s = new THREE.Vector3()
            bbox.getSize(s);
            //  s.multiply(node.scale);

            var sphereSize = s.length() * 0.5;
            var distToCenter = sphereSize / Math.sin(Math.PI / 180.0 * camera.fov * 0.5);

            // move the camera backward 
            var target = controls.target;
            var vec = new THREE.Vector3();
            vec.subVectors(camera.position, target);
            vec.setLength(distToCenter);
            camera.position.addVectors(vec, target);
            camera.updateProjectionMatrix();
        } catch (e) {
            console.warn("zoomObject", e);
        }
    };

    public centerGltf(gltf) {
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());

        gltf.scene.position.x += (gltf.scene.position.x - center.x);
        gltf.scene.position.y += (gltf.scene.position.y - center.y);
        gltf.scene.position.z += (gltf.scene.position.z - center.z);
    }



    public static clearScene(scene) {
        var toremove = [];
        scene.traverse(x => {
            if (x.name != "baseScene") {
                var object = scene.getObjectByName(x.name)
                if (object?.type != 'Scene') {
                    if (object)
                        toremove.push(x);
                }
            }
        })
        for (var i = 0; i < toremove.length; i++) {
            var object = scene.getObjectByProperty('uuid', toremove[i].uuid)
            if (object)
                scene.remove(toremove[i]);
        }
    }

    public static getBoundingBox(object) {
        var box = new THREE.Box3().setFromObject(object);
        return box;
    }


    public static getObjectByUserDataProp(scene, key, value) {
        let foundObject = null;

        scene.traverse(function (child) {
            if (child.userData[key] === value) {
                foundObject = child;
            }
        });

        return foundObject;
    }

    public static getBBox(subscene): THREE.Box3 {
        function getSizeFromObj( object ) {
            if ( object instanceof THREE.Mesh ) {
                // If geometry can be obtained from object, do not traverse the children
                var geometry = object.geometry;
                geometry.computeBoundingBox();
                object.updateMatrixWorld();
                var boundingBox = geometry.boundingBox.clone();
                boundingBox.applyMatrix4(object.matrixWorld);
                return boundingBox;
            } else {
                // Fallback method as default
                object.updateMatrixWorld();
                console.log(new THREE.Box3().setFromObject( object ));
                return new THREE.Box3().setFromObject( object ).getSize( new THREE.Vector3() );
            }
        }

        var bbox = { min: { x: Infinity, y: Infinity, z: Infinity }, max: { x: -Infinity, y: -Infinity, z: -Infinity } };

        if (!subscene) return;

        subscene.traverse((child) => {

            if(child.userData?.isHelper ){
                return;
            }
            if ((child.type === "Object3D" || child.type == "Mesh") && (child.userData?.type !== "refpoint" && child.userData?.type !== "refnormal" )) { // fenster: Object3D. regal: mesh
                if (child.visible) {

                   // var b = new THREE.Box3().setFromObject(child);
                    var b = getSizeFromObj(child);
                    if (b.min.x < bbox.min.x) bbox.min.x = b.min.x;
                    if (b.min.y < bbox.min.y) bbox.min.y = b.min.y;
                    if (b.min.z < bbox.min.z) bbox.min.z = b.min.z;
                    if (b.max.x > bbox.max.x) bbox.max.x = b.max.x;
                    if (b.max.y > bbox.max.y) bbox.max.y = b.max.y;
                    if (b.max.z > bbox.max.z) bbox.max.z = b.max.z;
                }

            }
        });

        var bb = new THREE.Box3(new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z), new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z));

        return bb;

    }

    HierarchyDisposal(showLogging = false) {
        const bag = []
        const ignoreKeys = [
            '_super',
            'parent'
        ]

        const dispose = (obj, ondone) => {
            const id = obj.uuid
            const name = obj.name || id || obj
            if (bag.includes(id)) return
            if (showLogging) console.log('calling dispose on', name)

            for (var key in obj) {
                const childObj = obj[key]
                if (
                    obj.hasOwnProperty(key) &&
                    !ignoreKeys.includes(key) &&
                    childObj &&
                    typeof childObj === 'object'
                ) dispose(childObj, false)
            }
            if (typeof obj.dispose === 'function' && obj.uuid) {
                if (showLogging) console.log('disposing of', name)
                obj.dispose()
                bag.push(id)
            }
            if (typeof ondone === 'function') ondone(bag)
        }

        return dispose
    }


    public static create3DMarker(box: THREE.Box3, length: number, width: number, gap: number, color: any) {
        var wgroup: THREE.Group;
        var mat = new THREE.MeshPhongMaterial({ color: color });//, transparent: false, opacity: 1, specular: 0xf08a00, emissive: 0xf08a00, shininess: 0 })
        var w = Math.abs(box.max.x - box.min.x);
        var h = Math.abs(box.max.y - box.min.y);
        var d = Math.abs(box.max.z - box.min.z);
        // get mininum w h d
        var min = Math.min(w, h, d);

        var length = min / 4;
        var width = 2;

        wgroup = new THREE.Group();

        var x1 = new THREE.BoxGeometry(length, width, width); var mx1 = new THREE.Mesh(x1, mat); wgroup.add(mx1);
        mx1.position.set(box.min.x + length / 2, box.min.y, box.min.z);
        var y1 = new THREE.BoxGeometry(width, length, width); var my1 = new THREE.Mesh(y1, mat); wgroup.add(my1);
        my1.position.set(box.min.x, box.min.y + length / 2, box.min.z);
        var z1 = new THREE.BoxGeometry(width, width, length); var mz1 = new THREE.Mesh(z1, mat); wgroup.add(mz1);
        mz1.position.set(box.min.x, box.min.y, box.min.z + length / 2);

        var x2 = new THREE.BoxGeometry(length, width, width); var mx2 = new THREE.Mesh(x2, mat); wgroup.add(mx2);
        mx2.position.set(box.max.x - length / 2, box.min.y, box.min.z);
        var y2 = new THREE.BoxGeometry(width, length, width); var my2 = new THREE.Mesh(y2, mat); wgroup.add(my2);
        my2.position.set(box.max.x, box.min.y + length / 2, box.min.z);
        var z2 = new THREE.BoxGeometry(width, width, length); var mz2 = new THREE.Mesh(z2, mat); wgroup.add(mz2);
        mz2.position.set(box.max.x, box.min.y, box.min.z + length / 2);

        var x3 = new THREE.BoxGeometry(length, width, width); var mx3 = new THREE.Mesh(x3, mat); wgroup.add(mx3);
        mx3.position.set(box.min.x + length / 2, box.max.y, box.min.z);
        var y3 = new THREE.BoxGeometry(width, length, width); var my3 = new THREE.Mesh(y3, mat); wgroup.add(my3);
        my3.position.set(box.min.x, box.max.y - length / 2, box.min.z);
        var z3 = new THREE.BoxGeometry(width, width, length); var mz3 = new THREE.Mesh(z3, mat); wgroup.add(mz3);
        mz3.position.set(box.min.x, box.max.y, box.min.z + length / 2);

        var x4 = new THREE.BoxGeometry(length, width, width); var mx4 = new THREE.Mesh(x4, mat); wgroup.add(mx4);
        mx4.position.set(box.max.x - length / 2, box.max.y, box.min.z);
        var y4 = new THREE.BoxGeometry(width, length, width); var my4 = new THREE.Mesh(y4, mat); wgroup.add(my4);
        my4.position.set(box.max.x, box.max.y - length / 2, box.min.z);
        var z4 = new THREE.BoxGeometry(width, width, length); var mz4 = new THREE.Mesh(z4, mat); wgroup.add(mz4);
        mz4.position.set(box.max.x, box.max.y, box.min.z + length / 2);

        // obenlinkshinten
        var x5 = new THREE.BoxGeometry(length, width, width); var mx5 = new THREE.Mesh(x5, mat); wgroup.add(mx5);
        mx5.position.set(box.min.x + length / 2, box.min.y, box.max.z);
        var y5 = new THREE.BoxGeometry(width, length, width); var my5 = new THREE.Mesh(y5, mat); wgroup.add(my5);
        my5.position.set(box.min.x, box.min.y + length / 2, box.max.z);
        var z5 = new THREE.BoxGeometry(width, width, length); var mz5 = new THREE.Mesh(z5, mat); wgroup.add(mz5);
        mz5.position.set(box.min.x, box.min.y, box.max.z - length / 2);

        var x6 = new THREE.BoxGeometry(length, width, width); var mx6 = new THREE.Mesh(x6, mat); wgroup.add(mx6);
        mx6.position.set(box.max.x - length / 2, box.min.y, box.max.z);
        var y6 = new THREE.BoxGeometry(width, length, width); var my6 = new THREE.Mesh(y6, mat); wgroup.add(my6);
        my6.position.set(box.max.x, box.min.y + length / 2, box.max.z);
        var z6 = new THREE.BoxGeometry(width, width, length); var mz6 = new THREE.Mesh(z6, mat); wgroup.add(mz6);
        mz6.position.set(box.max.x, box.min.y, box.max.z - length / 2);

        var x7 = new THREE.BoxGeometry(length, width, width); var mx7 = new THREE.Mesh(x7, mat); wgroup.add(mx7);
        mx7.position.set(box.min.x + length / 2, box.max.y, box.max.z);
        var y7 = new THREE.BoxGeometry(width, length, width); var my7 = new THREE.Mesh(y7, mat); wgroup.add(my7);
        my7.position.set(box.min.x, box.max.y - length / 2, box.max.z);
        var z7 = new THREE.BoxGeometry(width, width, length); var mz7 = new THREE.Mesh(z7, mat); wgroup.add(mz7);
        mz7.position.set(box.min.x, box.max.y, box.max.z - length / 2);

        var x8 = new THREE.BoxGeometry(length, width, width); var mx8 = new THREE.Mesh(x8, mat); wgroup.add(mx8);
        mx8.position.set(box.max.x - length / 2, box.max.y, box.max.z);
        var y8 = new THREE.BoxGeometry(width, length, width); var my8 = new THREE.Mesh(y8, mat); wgroup.add(my8);
        my8.position.set(box.max.x, box.max.y - length / 2, box.max.z);
        var z8 = new THREE.BoxGeometry(width, width, length); var mz8 = new THREE.Mesh(z8, mat); wgroup.add(mz8);
        mz8.position.set(box.max.x, box.max.y, box.max.z - length / 2);

        return wgroup;
    }




}

export { ThreeUtils };