import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'src/app/editor/utils';
import examples from './../../admin/projects/projectexamples';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})
export class ExamplesComponent implements OnInit {
  //   Example I Tesla
  // Example II Desk
  // Example III Building
  // Example IV Frame with Motors
  // Example V Molecule
  // Example VI Energy Visualization, Solar


  @Input() treenodes: any;
  @Input() idstr = "publicExampleID";

  path = "root";
  entries: any;


  nodes = null;

  curprojects: any = null;



  constructor(public afs: AngularFirestore, public dialogRef: MatDialogRef<ExamplesComponent>) {






  }

  oldpath = "";
  curnode = null;
  changePath(path, index, node) {
    this.curnode = node;
    console.log(path, index);
    this.oldpath = this.path;
    this.path = this.path + "[" + index + "].children";
    console.log(this.path);

    this.entries = Utils.getValue(this.nodes, this.path);
    console.log(this.entries);
    this.curprojects = null;

    this.afs.collection('projectspublic', ref => ref.where(this.idstr, "==", node.id)).valueChanges({ idField: "id" }).subscribe(data => {
      this.curprojects = data;
      console.log("curprojects", this.curprojects);
    });


  }

  upPath(path) {
    console.log("uppath", path);
    if (path == "") return;
    var p = path.split("[");
    this.oldpath = "";
    for (var i = 0; i < p.length - 1; i++) {
      // überler hack
      if (p[i].startsWith("0") || p[i].startsWith("1") || p[i].startsWith("2") || p[i].startsWith("3") || p[i].startsWith("4") || p[i].startsWith("5") || p[i].startsWith("6") || p[i].startsWith("7") || p[i].startsWith("8") || p[i].startsWith("9"))
        p[i] = "[" + p[i];
      this.oldpath += p[i];
    }

    console.log('oladpath', this.oldpath);

    // letzte löschen! = this.path;
    this.path = path;


    // if (this.path.endsWith("]"))
    //   this.path = this.path.substring(0, this.path.length - 3);

    this.entries = Utils.getValue(this.nodes, this.path);

    // remove last .children as string from string
    var npath = this.path.substring(0, this.path.length - 9);
    var n = Utils.getValue(this.nodes, npath);
    this.curnode = n;

    console.log("uppath", this.path, npath, n);

    this.curprojects = null;
    if (n)
      this.afs.collection('projectspublic', ref => ref.where(this.idstr, "==", n.id)).valueChanges({ idField: "id" }).subscribe(data => {
        this.curprojects = data;
        console.log("curprojects", this.curprojects);
      });
  }


  ngOnInit(): void {
    if (!this.treenodes)
      this.nodes = examples.examples;
    else
      this.nodes = this.treenodes;

    console.log("nodes", this.nodes, examples);
    this.entries = Utils.getValue(this.nodes, "root");
    var v1 = Utils.getValue(this.nodes, "root[1]");
    var v2 = Utils.getValue(this.nodes, "root[1].children");
  }


  projectSelected(p) {
    console.log("project selected", p);
    // close diealog
    this.dialogRef.close(p);
  }
}
