<div [style.top]="cellactiontop" [style.left]="cellactionleft" [hidden]="!cellactionvisible" class="box-shadow cellinfoback" (click)="showhidemenu()"
        style="position: absolute;z-index:9999;padding:5px 12px;border-radius:5px;background:rgb(255, 255, 255);cursor:pointer">
        <i class="fa-solid fa-lightbulb" style="color:rgb(255, 187, 0)"></i>&nbsp;<i class="fa-solid fa-sort-down"></i>
        <div class="" [hidden]="!cellactionmenuvisible" style="background-color: white;padding:6px 12px;">
                <div *ngIf="curSelectedRow<outputrow" style="position:relative;font-weight:bold;top: -28px;    left: 25px;width:100px">
                        Add control
                </div>
                <div *ngIf="curSelectedRow<outputrow" style="position:absolute;z-index:2;background-color:white;padding:2px 2px;left:0px;top:33px;width:100%">
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 1" (click)="addGui('label')"><i class="fa fa-window"></i> label</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 2" (click)="addGui('textbox')">
                                <i class="fa fa-door"></i> textbox
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 3" (click)="addGui('number')">
                                <i class="fa fa-door"></i> number
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 4" (click)="addGui('checkbox')"><i class="fa fa-door"></i> checkbox</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 5" (click)="addGui('radio')"><i class="fa fa-door"></i> radio</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 6" (click)="showfilesDialog('png')"><i class="fa fa-door"></i> image...</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 7" (click)="addGui('select')">
                                <i class="fa fa-door"></i> select
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 8" (click)="addGui('slider')">
                                <i class="fa fa-door"></i> slider
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 9" (mouseover)="showOverlaymenu1=true;showSheetsmenu=false;"><i class="fa fa-door"></i>
                                other <i class="fa fas fa-caret-right"></i></div>
                </div>
                <div *ngIf="showOverlaymenu1" style="left: 68px; top: 100px; background: white; position: relative; z-index: 999;">
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 22" (click)="addGui('expansion')"><i class="fa fa-door"></i> expansion</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 23" (click)="addGui('colorchooser')"><i class="fa fa-door"></i> colorchooser</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 21" (click)="addGui('button')">
                                <i class="fa fa-door"></i> button
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 24" (click)="addGui('savebutton')">save
                                button</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 25" (click)="addGui('exportbutton')">export
                                button</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem1 == 26" (click)="addGui('timer')">
                                timer</div>
                </div>
                <div *ngIf="curSelectedRow>outputrow && cellactionmenuvisible" style="position:relative;font-weight:bold;top: -28px;    left: 25px;width:100px">
                        Add object
                </div>
                <div *ngIf="curSelectedRow>outputrow && cellactionmenuvisible" style="position:absolute;z-index:2;background-color:white;padding:2px 2px;left:0px;top:33px;width:100%">
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 1" (click)="addPrimitive('extrusion')"><i class="fa fa-window"></i> extrusion</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 2" (click)="showfilesDialog('gltf')"><i class="fa fa-door"></i> 3D file...</div>
                        <!-- <div class="contextmenuitem" [class.active]="contextmenuitem == 13" (click)="addPrimitive('copy')"><i class="fa fa-copy"></i> copy object...</div> -->
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 3" (click)="addPrimitive('line')"><i class="fa fa-door"></i> line</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 3.5" (click)="addPrimitive('dimensionline')"><i class="fa fa-ru2ler"></i> dimension line
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 4" (click)="addPrimitive('shape')"><i class="fa fa-door"></i> shape</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 5" (click)="addPrimitive('cube')"><i class="fa fa-door"></i> cube</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 6" (click)="addPrimitive('cylinder')"><i class="fa fa-door"></i> cylinder</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 6.5" (click)="addPrimitive('ibeam')">
                                <i class="fa fa-i2ndustry"></i> I-beam
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 7" (click)="showfilesDialog('png',false,true)"><i class="fa fa-door"></i> image...</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 8" (mouseover)="showSheetsmenu=false;showOverlaymenu=false;showothermenu=false;" (click)="addPrimitive('textflat')"><i
                                        class="fa fa-door"></i>
                                textflat</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 9" (mouseover)="showothermenu=true;showSheetsmenu=false;"><i class="fa fa-door"></i> other
                                <i class="fa fas fa-caret-right"></i>
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 10" (mouseover)="showSheetsmenu=true;showOverlaymenu=false;showothermenu=false;"><i class="fa fa-door"></i>
                                sheet <i class="fa fas fa-caret-right"></i></div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 11" (mouseover)="showOverlaymenu=true;showSheetsmenu=false;showothermenu=false;"><i class="fa fa-door"></i>
                                overlay <i class="fa fas fa-caret-right"></i></div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 12" (mouseover)="showSheetsmenu=false;showOverlaymenu=false;" (click)="addProject()"><i class=" fa fa-door"></i> project...</div>
                </div>
                <div *ngIf="showothermenu" style="left: 68px; top: 130px; background: white; position: relative; z-index: 999;">
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 40" (click)="addPrimitive('subtraction')"><i class="fa fa-door"></i> subtraction </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 41" (click)="addPrimitive('sphere')"><i class="fa fa-door"></i> sphere </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 42" (click)="addPrimitive('surface')"><i class="fa fa-door"></i> surface </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 43" (click)="addPrimitive('light')"><i class="fa fa-door"></i> light </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 13" (click)="addPrimitive('profile')"><i class="fa fa-door"></i> profile</div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 44" (click)="addPrimitive('connector')"><i class="fa fa-pl2ug"></i> connector </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 45" (click)="addPrimitive('grid')">
                                measuring grid
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 46" (click)="addPrimitive('arrow')">
                                arrow
                        </div>
                </div>
                <div *ngIf="showSheetsmenu" style="left: 68px; top: 161px; background: white; position: relative; z-index: 999;">
                        <ng-container *ngFor="let t of project?.sheets; let i=index">
                                <div *ngIf="i>0" class="contextmenuitem" [class.active]="contextmenuitem == 20+i " (click)="addPrimitive('sheet: '+t.name)">{{t.name}}...</div>
                        </ng-container>
                </div>
                <div *ngIf="showOverlaymenu" style="left: 68px; top: 210px; background: white; position: relative; z-index: 999;">
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 31" (click)="addPrimitive('text2d')">Text
                        </div>
                        <div class="contextmenuitem" [class.active]="contextmenuitem == 32" (click)="addPrimitive('rectangle2d')">
                                Rectangle</div>
                </div>

        </div>
</div>