import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TREE_ACTIONS, IActionMapping } from '@circlon/angular-tree-component';
import nodes from './projectcategories';
import examples from './projectexamples';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: any;
  filteredProjects: any;
  searchText: string = '';

  showcategories = false;

  curProject: any = null;




  nodes = nodes.nodes.root;
  examples = examples.examples.root;

  constructor(public afs: AngularFirestore) {
    console.log("nodes", this.nodes);
    console.log("examples", this.examples);
    this.afs.collection('projectspublic').valueChanges
      ({ idField: "id" }).subscribe(data => {

        this.projects = data;
        this.filteredProjects = data;
        console.log("projects", this.projects);
      });

  }


  ngOnInit(): void {
  }
  updateProject(project) {
    //this.afs.collection('projectspublic').doc(project.id).update(project);
  }



  public select($event) {
    console.log("select", $event);
  }

  public activate(e) {
    console.log("activate", e.node?.data?.id, e);

  }


  curCategoryID = null;
  curExampleID = null;
  showexamples = false;
  nodeActivate(e) {
    console.log("nodeActivate", e.node?.data?.id, e);
    this.curCategoryID = e.node?.data?.id;
  }
  node2Activate(e) {
    console.log("node2Activate", e.node?.data?.id, e);
    this.curExampleID = e.node?.data?.id;
  }

  save() {
    console.log("save");
    if (this.curCategoryID)
      this.curProject.publicCategoryID = this.curCategoryID;
    if (this.curExampleID)
      this.curProject.publicExampleID = this.curExampleID;
    this.afs.collection('projectspublic').doc(this.curProject.id).update(this.curProject);
  }


  delCategory(project) {
    console.log("del");
    project.publicCategoryID = null;
    //   if (this.curExampleID)
    //     this.curProject.publicExampleID = this.curExampleID;
    this.afs.collection('projectspublic').doc(project.id).update(project);
  }


  delExample(project) {
    console.log("del");
    project.publicExampleID = null;
    this.afs.collection('projectspublic').doc(project.id).update(project);
  }

  filterProjects() {
    if (!this.searchText) {
      this.filteredProjects = this.projects;
      return;
    }

    this.filteredProjects = this.projects.filter(project =>
      project.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
      project.userid?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      project.id?.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

}
