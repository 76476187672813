import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as THREE from 'three';
import { StepExporter } from '../step-exporter';
import { IFCExporter } from '../ifc-exporter';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { ColoredSTLExporter } from '../ColoredSTLExporter';
import { ThreeMFExporter } from '../ThreeMFExporter';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface TreeNode {
    name: string;
    checked: boolean;
    object: THREE.Object3D;
    children: TreeNode[];
    level: number;
    expandable: boolean;
}

interface FlatNode {
    name: string;
    checked: boolean;
    object: THREE.Object3D;
    level: number;
    expandable: boolean;
}

@Component({
    selector: 'app-export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent {
    fileTypes = [
        { value: 'step', label: 'STEP (.stp)' },
        { value: 'ifc', label: 'IFC (.ifc)' },
        { value: 'stl', label: 'STL (.stl)' },
        { value: 'gltf', label: 'glTF (.gltf)' },
        { value: '3mf', label: '3MF (.3mf)' }
    ];
    selectedFileType = 'step';

    private transformer = (node: TreeNode, level: number): FlatNode => {
        return {
            name: node.name,
            checked: node.checked,
            object: node.object,
            level,
            expandable: !!node.children && node.children.length > 0
        };
    };

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this.transformer,
        node => node.level,
        node => node.expandable,
        node => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        public dialogRef: MatDialogRef<ExportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { scene: THREE.Scene }
    ) {
        this.buildTree(data.scene);
    }

    private buildTree(scene: THREE.Scene) {
        const processObject = (obj: THREE.Object3D): TreeNode | null => {
            // Only include objects with userData and name
            if (!obj.userData || !obj.name) {
                return null;
            }

            const node: TreeNode = {
                name: obj.name,
                checked: obj.name.toLowerCase().startsWith('root') && obj.name.toLowerCase() !== 'kante',
                object: obj,
                children: [],
                level: 0,
                expandable: obj.children.length > 0
            };

            // Process children
            if (obj.children && obj.children.length > 0) {
                for (const child of obj.children) {
                    const childNode = processObject(child);
                    if (childNode) {
                        node.children.push(childNode);
                    }
                }
            }

            return node;
        };

        const rootNode = processObject(scene);
        if (rootNode) {
            this.dataSource.data = [rootNode];
            // Expand all nodes by default
            this.treeControl.expandAll();
        }
    }

    hasChild = (_: number, node: FlatNode) => node.expandable;

    toggleNode(node: FlatNode) {
        node.checked = !node.checked;
        // Update children nodes
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(child => child.checked = node.checked);
    }

    async export() {
        try {
            // Create a new scene with only selected objects
            const exportScene = new THREE.Scene();
            const selectedObjects = this.getSelectedObjects();

            console.log('Selected objects for export:', selectedObjects);

            // Clone and prepare objects for export
            selectedObjects.forEach(obj => {
                try {
                    if (obj.visible) {
                        // First update the original object's matrix
                        obj.updateMatrix();
                        obj.updateMatrixWorld(true);

                        // Create a new mesh with the same geometry and material
                        if (obj instanceof THREE.Mesh) {
                            const geometry = obj.geometry.clone();
                            const material = obj.material.clone();
                            const newMesh = new THREE.Mesh(geometry, material);

                            // Apply the world matrix transform to the geometry
                            geometry.applyMatrix4(obj.matrixWorld);

                            // Reset the mesh's matrix since we applied it to the geometry
                            newMesh.position.set(0, 0, 0);
                            newMesh.quaternion.set(0, 0, 0, 1);
                            newMesh.scale.set(1, 1, 1);
                            newMesh.updateMatrix();

                            // Copy name and userData
                            newMesh.name = obj.name;
                            newMesh.userData = JSON.parse(JSON.stringify(obj.userData));

                            exportScene.add(newMesh);
                        }
                    }
                } catch (objError) {
                    console.error('Error processing object for export:', obj.name, objError);
                }
            });

            // Final matrix update for the export scene
            exportScene.updateMatrixWorld(true);

            switch (this.selectedFileType) {
                case 'step':
                    const stepExporter = new StepExporter();
                    stepExporter.export(exportScene);
                    break;
                case 'ifc':
                    const ifcExporter = new IFCExporter();
                    ifcExporter.export(exportScene);
                    break;
                case 'stl':
                    const stlExporter = new ColoredSTLExporter();
                    const result = stlExporter.parse(exportScene, { binary: true });
                    const blob = new Blob([result], { type: 'application/octet-stream' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'model.stl';
                    link.click();
                    break;
                case 'gltf':
                    const gltfExporter = new GLTFExporter();
                    gltfExporter.parse(
                        exportScene,
                        (gltf) => {
                            const gltfStr = JSON.stringify(gltf);
                            const gltfBlob = new Blob([gltfStr], { type: 'application/json' });
                            this.downloadBlob(gltfBlob, 'model.gltf');
                        },
                        (error) => {
                            console.error('Error exporting GLTF:', error);
                        },
                        { binary: false }
                    );
                    break;
                case '3mf':
                    const threeMFExporter = new ThreeMFExporter();
                    await threeMFExporter.export(exportScene);
                    break;
            }
        } catch (error) {
            console.error('Error during export:', error);
        }

        this.dialogRef.close();
    }

    private getSelectedObjects(): THREE.Object3D[] {
        return this.treeControl.dataNodes
            .filter(node => node.checked)
            .map(node => node.object);
    }

    private downloadBlob(blob: Blob, filename: string) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    cancel() {
        this.dialogRef.close();
    }
}
