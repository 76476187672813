<!-- <div [excels]="excels" [hf]="hf" [sheetid]="sheetid" [CONFIGURATION]="CONFIGURATION" [dataset]="dataset"
    [showAnimations]="showAnimations"></div> -->


<h3>Select Project</h3>

<div class="row" style="padding: 6px 3px;">
    <div class="col-md-12">

        <mat-tab-group>
            <mat-tab label="Own Projects">
                <div style="    height:80vh;    overflow: overlay;">
                    <div class="col-m2d-12" class="projectbtn" *ngFor="let p of projects">

                        <div (click)="projectSelected(p)" style="height:100px;width:100px;">
                            <!-- <img [src]="p.image" style="height:80px;" /><br>
                            {{p.name}} -->


                            <img [src]="p.image" style=" max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;    wi2dth: 100%;  h2seight: 100%;    obj2ect-fit: contain;" />
                            <br>
                            <div style="width:100%; text-align:center;font-size:16px;"> {{p.name}}</div>

                        </div>




                    </div>

                </div>
            </mat-tab>
            <mat-tab label="Public Projects">
                <div style="height:10px;"></div>
                <app-examples style="" [treenodes]="nodes" [idstr]="'publicCategoryID'"></app-examples>

            </mat-tab>
        </mat-tab-group>


    </div>


    <!-- <div class="col-md-6">

        <div id="threecontainerdlg1" #rendererContainer style="width:300px;height:300px"></div>
    </div> -->


</div>