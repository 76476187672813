import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { PartListOptions } from '../part-list.service';

@Component({
  selector: 'app-part-list-dialog',
  templateUrl: './part-list-dialog.component.html',
  styleUrls: ['./part-list-dialog.component.scss']
})
export class PartListDialogComponent implements OnInit {
  partListForm: FormGroup;
  customFields: FormArray;
  availableUserDataFields: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PartListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userDataFields: string[] },
    private fb: FormBuilder
  ) {
    this.availableUserDataFields = data.userDataFields || [];

    this.partListForm = this.fb.group({
      includeUserData: [true],
      exportType: ['clipboard'],
      includePosition: [true],
      includeRotation: [false],
      includeScale: [false],
      includeParent: [true],
      customFields: this.fb.array([]),
      filename: ['part-list.xlsx']
    });

    this.customFields = this.partListForm.get('customFields') as FormArray;
  }

  ngOnInit(): void {
    // Add checkboxes for available userData fields
    this.availableUserDataFields.forEach(field => {
      this.customFields.push(new FormControl(false));
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const formValue = this.partListForm.value;

    // Get selected custom fields
    const selectedCustomFields = this.availableUserDataFields.filter((field, i) =>
      formValue.customFields[i]
    );

    const options: PartListOptions = {
      includeUserData: formValue.includeUserData,
      exportType: formValue.exportType,
      includePosition: formValue.includePosition,
      includeRotation: formValue.includeRotation,
      includeScale: formValue.includeScale,
      includeParent: formValue.includeParent,
      customFields: selectedCustomFields
    };

    this.dialogRef.close({
      options,
      filename: formValue.filename
    });
  }
}
