import * as THREE from "three";
import { CSG } from 'three-csg-ts';
import { Utils } from './utils';
import SpriteText from 'three-spritetext';
import { HyperFormula } from 'hyperformula';
import { Vector2 } from 'three';
import traverse from 'traverse';


import _ from 'lodash';
import { Materials } from "./materials";
import { CADNodeEngine } from "./cadnodeengine";


class CADEngine {
    testmesh: THREE.Mesh;

    rows = [];
    CADNodeEngine: CADNodeEngine;

    texturen = true;

    changedElements = [];
    materials: Materials;
    public MAINCONFIGURATION;

    constructor(public baseScene, public excels, public excelsdata) {

        this.materials = new Materials();


    }


    public async xls2json(startrow, subkonfiguration, sheetname, sheetid, rows, offset, srotx, sroty, srotz, tree, parent, params, parentPositionHasChanged) {


        console.log('### ', subkonfiguration, ' parent: ' + parent + "startrow: " + startrow + " callcell: " + sheetid + " sheetname: " + sheetname);
        //   parent = parent + "_" + sheetname
        var l = "";
        var i = startrow - 1;
        var emptylines = 0;


        var colZ = await this.excels[subkonfiguration].getRangeValues({ start: { sheet: sheetid, col: 25, row: i }, end: { sheet: sheetid, col: 25, row: i + 2500 } });
        if (sheetname == "Fachwerkbinder S")
            console.log('test')

        while (l !== "END" && emptylines < 2500) {
            i = i + 1;
            // var celladress = await this.excels[subkonfiguration].simpleCellAddressFromString("Z" + i, sheetid);
            // var l2 = await this.excels[subkonfiguration].getCellValue(celladress);
            if (!colZ[i - startrow]) {

                emptylines++;
                continue;
            }

            l = colZ[i - startrow][0];

            if (l === "END") break;
            if (!l)
                emptylines++;
            if (Object.prototype.toString.call(l) !== "[object String]") continue;


            if (i == 1256) {
                console.log("");
            }

            var neuBerechnen = parentPositionHasChanged;
            if (l) {
                // var commponentpath = parent + "_" + callcell + "." + sheetname + "#Component_Z" + i;
                var commponentpath = parent + "." + sheetname + "#Component_Z" + i;
                var lastval = Utils.getValue(tree, commponentpath);
                var s = [];
                try {

                    s = (l as string)?.split(";");
                    if (s[3] === "Group") {
                        var commponentpath = parent + "." + sheetname + "#Component_Z" + i;
                        lastval = Utils.getValue(tree, commponentpath)?.o;
                    }

                    if (s[3] === "SubConfiguration") {
                        commponentpath = parent + "." + sheetname + "#Subconfiguration_Z" + i;// falschr sheetname
                        //   commponentpath = parent + "_" + callcell + ".Subconfiguration_Z" + i;
                        lastval = Utils.getValue(tree, commponentpath)?.o;
                    }

                } catch (e) {
                    //     console.log(e);
                }
                if (commponentpath == '_root.Öffnungen#Subconfiguration_Z1259')
                    console.log('test');
                if (commponentpath == '_root.Öffnungen#Subconfiguration_Z1259.Fenster#Subconfiguration_Z420')
                    console.log('test');
                if (l[0] === '0' && lastval) {
                    // Tree aufräumen
                    this.removeOrUpdateObjects(Object.assign({}, Utils.getValue(tree, commponentpath)), 'remove');
                }
                // nur update, wenn Änderung
                if ((l[0] === '0' || l.split(";").length <= 1) && s[3] !== "SubConfiguration") {
                    if (!lastval)
                        continue;
                }

                if (s[3] === "SubConfiguration" && lastval?.excelvalue) {
                    var cursplit = l.split(';');
                    var lastsplit = lastval?.excelvalue.split(';');
                    var curconfig = cursplit[10].split('#')[0];
                    var lastconfig = lastsplit[10].split('#')[0];
                    var curtransa = cursplit[4];
                    var lasttransa = lastsplit[4];
                    var curtrans = cursplit[13];
                    var lasttrans = lastsplit[13];
                    var curtrans2 = cursplit[14];
                    var lasttrans2 = lastsplit[14];

                    if (commponentpath == "_root.Öffnungen#Subconfiguration_Z1259")
                        console.log("test");
                    if (curconfig !== lastconfig) {
                        await this.removeOrUpdateObjects(Object.assign({}, Utils.getValue(tree, commponentpath)), 'remove');
                    }
                    else if (curtransa !== lasttransa || curtrans !== lasttrans || curtrans2 !== lasttrans2) {
                        // neue Transformation im Tree abspeichern.
                        neuBerechnen = true;
                        //    this.removeOrUpdateObjects(Object.assign({},Utils.getValue(tree, commponentpath)), 'update');
                    }

                    // Testen ob Bauteilliste geändert wurde

                }

                if (lastval?.excelvalue === l && !neuBerechnen) {
                    continue;
                }

                ///////////////// neue/geänderte componenten/subconfigs ////////////////
                if (l[0] === '1') {
                    try {

                        // subkonfigurations
                        if (s[3] === "SubConfiguration") {
                            var sub = s[10]?.split('#')[0];
                            var params = s[10]?.split('#')[1]; // 'LIGNA-SYSTEMS-WINDVERBAND#500/656.798626425459/731.7986264254589//24/Holz/16/16/12/0/0/2/15/LIGNA/nein//////////0/0/nein'

                            // TODO: ggf doch nachladen, damit nicht alle Dateien zu Beginn geladen werden
                            // if (!this.wasloaded(sub)) {
                            //     console.log('load data from API for ' + sub);
                            //     await this.loadExcel(sub);
                            // }


                            var t0 = performance.now();

                            var view3drowindex = 2;

                            console.log('sub', sub);
                            var sr = (await this.excels[sub].getView3DCell(view3drowindex))?.row;

                            ///               await this.excels[sub].setSubConfigurationParameters(params, parent, sheetname + "#Subconfiguration_Z" + i, this.tree);

                            // Parameter eintragen und Bauteilliste aus Hauptkonfiguration übertragen, TODO: bei gleichen werten gar nicht setzen
                            // if ((this.excel[sub] as HyperFormula).doesSheetExist("Bauteilliste Aktiv")) {
                            //   this.copyBauteilliste(sub);  // TODO: wird zu oft ausgeführt, zB bei Pfetten!
                            // }

                            while (sr) {
                                var vcell = await this.excels[sub].getView3DCell(view3drowindex);
                                sr = vcell?.row;
                                if (!vcell)
                                    break;

                                const o = {
                                    nr: "Z" + i, // s[0],
                                    cell: "Z" + i, // s[0],
                                    subconfiguration: sub,
                                    sheetid: sheetid,
                                    sheet: { sheetname: sheetname, col: 25, row: i },
                                    name: s[1],
                                    layer: s[2],
                                    typ: s[3],

                                    booleanOperation: subtract,
                                    parent: parent, // s[9],
                                    params: params,
                                    extrusion: {
                                        // extrusionsvektor
                                        x: ex,
                                        y: ey,
                                        z: ez
                                    },
                                    transformation: {
                                        offset: offsetstr,
                                        rotx: rotx,
                                        roty: roty,
                                        rotz: rotz
                                    },
                                    excelvalue: l,
                                    path: parent + "." + sheetname + "#Subconfiguration_Z" + i,
                                    material: s[11],
                                    s13: s[13],
                                    s14: s[14],
                                    s: s,
                                    verknuepfteElemente: ve
                                };

                                let sid = await this.excels[sub].getSheetId(vcell.sheet);
                                let v3did = await this.excels[sub].getSheetId("View-3d");
                                var isvisible = await this.excels[sub].getCellValue({ sheet: v3did, col: 4, row: view3drowindex });
                                if (isvisible === true) {

                                    // subconfig rotation
                                    var subrotu = s[13]; //X0Y0Z0
                                    var subrotx = subrotu !== "" ? parseFloat(subrotu.split('Y')[0]?.replace('X', '')) : 0;
                                    var subroty = subrotu !== "" ? parseFloat(subrotu.split('Y')[1]?.split('Z')[0]) : 0;
                                    var subrotz = subrotu !== "" ? parseFloat(subrotu.split('Z')[1]) : 0;
                                    var subtu = s[14]; //X0Y0Z0
                                    var subtx = subtu !== "" ? parseFloat(subtu.split('Y')[0]?.replace('X', '')) : 0;
                                    var subty = subtu !== "" ? parseFloat(subtu.split('Y')[1]?.split('Z')[0]) : 0;
                                    var subtz = subtu !== "" ? parseFloat(subtu.split('Z')[1]) : 0;



                                    var x = parseFloat(s[5].split(':')[0]);
                                    var y = parseFloat(s[5].split(':')[1]);
                                    var z = parseFloat(s[5].split(':')[2]);
                                    var layer = s[1];
                                    var offsets = s[4];
                                    var offsetxs = offsets !== "" ? parseFloat(offsets.split(':')[0]) : 0; // offset != "" ? parseFloat(offset.split('Y')[0].replace('X', '')) : 0;
                                    var offsetys = offsets !== "" ? parseFloat(offsets.split(':')[1]) : 0; // offset != "" ? parseFloat(offset.split('Y')[1].split('Z')[0]) : 0;
                                    var offsetzs = offsets !== "" ? parseFloat(offsets.split(':')[2]) : 0; // offset != "" ? parseFloat(offset.split('Z')[1]) : 0;

                                    subtx = subtx + offsetxs;
                                    subty = subty + offsetys;
                                    subtz = subtz + offsetzs;
                                    subtu = subtx + ":" + subty + ":" + subtz;

                                    Utils.setValue(tree, commponentpath + ".o", o);
                                    Utils.setValue(tree, commponentpath + ".subconfiguration", sub);
                                    Utils.setValue(tree, commponentpath + ".path", parent + "." + sheetname + "#Subconfiguration_Z" + i);
                                    /*    Utils.setValue(tree, parent + "." + sheetname + "#Subconfiguration_Z" + i + ".transformation",
                                          {
                                            offset: s[4], // subtu, // offsetstr,
                                            rotx: rotx,
                                            roty: roty,
                                            rotz: rotz
                                          });*/

                                    var lastTransformation = Utils.getValue(tree, commponentpath + ".transformation");
                                    var positionHasChanged = parentPositionHasChanged ||
                                        !lastTransformation ||
                                        lastTransformation.offset != subtu ||
                                        lastTransformation.rotx != subrotx ||
                                        lastTransformation.roty != subroty ||
                                        lastTransformation.rotz != subrotz
                                    Utils.setValue(tree, commponentpath + ".transformation", {
                                        offset: subtu,
                                        //  sheetname: sheetname,
                                        rotx: subrotx,
                                        roty: subroty,
                                        rotz: subrotz,
                                        // path: parent,
                                        // params: params,
                                        // excelvalue: l
                                    });
                                    //    console.log(' ### await xls2json ', sub, subkonfiguration);
                                    await this.xls2json(sr, sub, vcell.sheet, sid, rows, subtu, subrotx, subroty, subrotz, tree, parent + "." + sheetname + "#Subconfiguration_Z" + i, s[10], positionHasChanged);

                                    //                  await this.xls2json(sr, sub, vcell.sheet, sid, rows, s[4], subrotx, subroty, subrotz, tree, parent + "_" + callcell + "." + sub, "Z" + i, s[10]);

                                }

                                view3drowindex++;
                            }

                            var t1 = performance.now();
                            console.log("Auslesen " + sub + " took " + (t1 - t0) + " milliseconds.");
                        }



                        if (s[3] === "Line") {
                            //    console.log('Line', s);
                            // T rot ggf wie bei extrude
                            var oldlineid = null;
                            var commponentpath = parent + "." + sheetname + "#LINE_Z" + i;
                            lastval = Utils.getValue(tree, commponentpath);
                            this.updateLine(s, i, tree, parent, sheetname, sheetid, subkonfiguration, l, 0, 0, 0, 0, 0, 0, lastval?.lineuuid);
                        }
                        if (s[3] === "Text") {
                            //     console.log('Text', s);
                            var commponentpath = parent + "." + sheetname + "#TEXT_Z" + i;
                            lastval = Utils.getValue(tree, commponentpath);
                            this.updateText(s, i, tree, parent, sheetname, sheetid, subkonfiguration, l, 0, 0, 0, 0, 0, 0, lastval?.textuuid);
                        }

                        // kopieren // TODO BUGFIXEN!
                        if (s[3] === "Group") {
                            // =C2216&";"&D2216&";"&E2216&";"&F2216&";"&G2216&H2216&":"&I2216&":"&J2216&";"&K2216&":"&L2216&":"&M2216&";"&N2216&";"&O2216&";"&P2216&";"&Q2216&";"&R2216&";"&S2216&";"&T2216&";"&U2216&";"&V2216&";"&W2216&";"&X2216&";"&Y2216
                            // HIJ Translation, R Layer
                            var x = parseFloat(s[5].split(':')[0]);
                            var y = parseFloat(s[5].split(':')[1]);
                            var z = parseFloat(s[5].split(':')[2]);
                            var layer = s[10];
                            var offsetg = s[4];
                            var offsetxg = offsetg !== "" ? parseFloat(offsetg.split(':')[0]) : 0; // offset != "" ? parseFloat(offset.split('Y')[0].replace('X', '')) : 0;
                            var offsetyg = offsetg !== "" ? parseFloat(offsetg.split(':')[1]) : 0; // offset != "" ? parseFloat(offset.split('Y')[1].split('Z')[0]) : 0;
                            var offsetzg = offsetg !== "" ? parseFloat(offsetg.split(':')[2]) : 0; // offset != "" ? parseFloat(offset.split('Z')[1]) : 0;
                            // rotation
                            var rotu = s[13];//X0Y0Z0
                            var rotx = rotu !== "" ? parseFloat(rotu.split('Y')[0]?.replace('X', '')) : 0;
                            var roty = rotu !== "" ? parseFloat(rotu.split('Y')[1]?.split('Z')[0]) : 0;
                            var rotz = rotu !== "" ? parseFloat(rotu.split('Z')[1]) : 0;
                            rotx *= (Math.PI / 180);
                            roty *= (Math.PI / 180);
                            rotz *= (Math.PI / 180);
                            var copied = false;
                            var rz = "";
                            for (var r = 0; r < rows.length; r++)
                                if (rows[r].layer === layer && !rows[r].iscopy) {
                                    rz = "." + sheetname + "#Component_" + rows[r].cell;

                                    var original = Utils.getValue(tree, parent + rz)  // root_null.LIGNA-SYSTEMS-PFETTEN_Z1596.Component_COPY_Z1774 .Component_Z1615'
                                    Utils.setValue(tree, parent + rz + ".iscopied", true);

                                    //  var copy = JSON.parse(JSON.stringify(rows[r]));// TOO: coords zuerst transform... zxy offset speichern?

                                    // PROBLEM: kopien werden mitkopiert => aufwand steigt exponentiell

                                    var copy = JSON.parse(JSON.stringify(original));     //   console.log(' copy', layer, rows[r]);
                                    for (const key in copy) {
                                        if (key.startsWith('copy_'))
                                            copy[key] = undefined;
                                    }

                                    copy.transformation = {
                                        offset: offsetg,
                                        rotx: rotx,
                                        roty: roty,
                                        rotz: rotz
                                    }

                                    //                      var commponentpath = parent + "_" + callcell + ".Component_COPY_Z" + i;
                                    var commponentpath = parent + rz + ".copy_" + i;

                                    var o2 = {
                                        nr: "Z" + i, // s[0],
                                        cell: "Z" + i, // s[0],
                                        subconfiguration: subkonfiguration,
                                        sheetid: sheetid,
                                        sheet: { sheetname: sheetname, col: 25, row: i },
                                        name: s[1],
                                        typ: s[3],

                                        coords: copy.coords,
                                        parent: parent, // s[9],
                                        params: params,
                                        extrusion: {
                                            // extrusionsvektor
                                            x: ex,
                                            y: ey,
                                            z: ez
                                        },
                                        transformation: {
                                            offset: offsetg,
                                            rotx: rotx,
                                            roty: roty,
                                            rotz: rotz
                                        },
                                        excelvalue: l,
                                        path: commponentpath,
                                        material: s[11],
                                        s2: s[2]
                                    };

                                    var oldcopy = Utils.getValue(tree, commponentpath);
                                    var oldcopymeshuuid = oldcopy?.meshuuid;
                                    var oldcopylineuuid = oldcopy?.lineuuid;

                                    Utils.setValue(tree, commponentpath,
                                        //        Utils.setValue(tree, parent + "_" + callcell + ".Component_COPY_Z" + i,
                                        {
                                            sheetname: sheetname,
                                            i: i,
                                            o: o2,
                                            transformation: {
                                                offset: offsetg,
                                                rotx: rotx,
                                                roty: roty,
                                                rotz: rotz
                                            },
                                            path: commponentpath
                                        });

                                    copy.o = o2;
                                    copy.path = commponentpath;
                                    copy.name = s[1];
                                    copy.iscopied = false;
                                    copy.iscopy = true;
                                    /*
                                    // // rotation
                                    for (var c = 0; c < copy.coords.length; c++) {
                                      copy.coords[c].x = copy.coords[c].x * Math.cos(rotz + srotz) - copy.coords[c].y * Math.sin(rotz + srotz);
                                      copy.coords[c].y = copy.coords[c].x * Math.sin(rotz + srotz) + copy.coords[c].y * Math.cos(rotz + srotz);
                                      copy.coords[c].z = copy.coords[c].z;
                                    }
                                    var offsetv = copy.s14;
                                    var offsetvx = offsetv !== "" ? parseFloat(offsetv.split('Y')[0]?.replace('X', '')) : 0;
                                    var offsetvy = offsetv !== "" ? parseFloat(offsetv.split('Y')[1]?.split('Z')[0]) : 0;
                                    var offsetvz = offsetv !== "" ? parseFloat(offsetv.split('Z')[1]) : 0;
                                    // translation
                                    for (var c = 0; c < copy.coords.length; c++) {
                                      copy.coords[c].x += offsetxg //+ 804;
                                      copy.coords[c].y += offsetyg // + 2200;
                                      copy.coords[c].z += offsetzg;
                                    }*/
                                    // console.log('COPY ', layer, offsetg, rotu, r)

                                    copy = this.updateElement(copy, oldcopymeshuuid);// lastval?.meshuuid, lastval?.lineuuid);
                                    rows.push(copy);
                                    //console.log("copied ", r)

                                }// if
                        }

                        // components
                        if (s[3] === "Component" || s[3] === "Light" || s[3] === "Component-Union" || s[3] === "Component-Substract") {

                            var subtract = '';
                            var ve = null;
                            if (s[3] === "Component-Substract") {
                                subtract = 'Subtract';
                                ve = s[9].split('/');
                            }

                            // // Verschiebung aus Spalte V
                            var offsetv = s[14];
                            var offsetvx = offsetv !== "" ? parseFloat(offsetv.split('Y')[0]?.replace('X', '')) : 0;
                            var offsetvy = offsetv !== "" ? parseFloat(offsetv.split('Y')[1]?.split('Z')[0]) : 0;
                            var offsetvz = offsetv !== "" ? parseFloat(offsetv.split('Z')[1]) : 0;
                            var offsetstr = offsetvx + ":" + offsetvy + ":" + offsetvz;
                            // // rotation
                            var rotu = s[13];//X0Y0Z0
                            var rotx = rotu !== "" ? parseFloat(rotu.split('Y')[0]?.replace('X', '')) : 0;
                            var roty = rotu !== "" ? parseFloat(rotu.split('Y')[1]?.split('Z')[0]) : 0;
                            var rotz = rotu !== "" ? parseFloat(rotu.split('Z')[1]) : 0;
                            if (Number.isNaN(rotx)) rotx = 0;
                            if (Number.isNaN(roty)) roty = 0;
                            if (Number.isNaN(rotz)) rotz = 0;

                            const coords = s[4].split("/");
                            const cs = [];
                            for (var z = 0; z < coords.length; z++) {

                                var co = coords[z].split(":");
                                if (co[0].includes(']'))
                                    co[0] = co[0].split("]")[1];
                                if (co[1].includes(']'))
                                    co[1] = co[1].split("]")[1];
                                if (co[2].includes(']'))
                                    co[2] = co[2].split("]")[1];


                                if (!co[0] || co[0] === "") { co[0] = 0; }
                                if (!co[1] || co[1] === "") { co[1] = 0; }
                                if (!co[2] || co[2] === "") { co[2] = 0; }

                                // Rotation und Verschiebung
                                var px = parseFloat(co[0]);
                                var py = parseFloat(co[1]);
                                var pz = parseFloat(co[2]);

                                cs.push({ x: px, y: py, z: pz });

                            }

                            var ex = parseFloat(s[5].split(":")[0]);
                            var ey = parseFloat(s[5].split(":")[1]);
                            var ez = parseFloat(s[5].split(":")[2]);
                            if (!ex) { ex = 0; }
                            if (!ey) { ey = 0; }
                            if (!ez) { ez = 0; }

                            var commponentpath = parent + "." + sheetname + "#Component_Z" + i;
                            // var commponentpath = parent + "_" + callcell + ".Component_Z" + i;
                            // Utils.setValue(this.tree, commponentpath + ".bauteilliste", true)
                            // material
                            var variante = s[11].split('$')[0];

                            var pd = null;
                            /// var pd= await this.excels[this.MAINCONFIGURATION].getProductDataFromVariant(variante);
                            // TODO  var pd2 = Utils.getProductDataFromVariant(variante, this.excelsdata[this.MAINCONFIGURATION], this.assemblies);

                            var farbe = pd?.color;
                            var baugruppe = pd?.baugruppe;
                            var montageabschnitt = pd?.montageabschnitt;
                            var valueW = s;

                            var o = {
                                nr: "Z" + i, // s[0],
                                cell: "Z" + i, // s[0],
                                subconfiguration: subkonfiguration,
                                sheetid: sheetid,
                                sheet: { sheetname: sheetname, col: 25, row: i },
                                name: s[1],
                                layer: s[2],
                                baugruppe: baugruppe,
                                montageabschnitt: montageabschnitt,
                                variante: variante,

                                typ: s[3],
                                farbe: farbe,
                                coords: cs,
                                booleanOperation: subtract,
                                parent: parent, // s[9],
                                params: params,
                                valueW: valueW,
                                extrusion: {
                                    // extrusionsvektor
                                    x: ex,
                                    y: ey,
                                    z: ez
                                },
                                transformation: {
                                    offset: offsetstr,
                                    rotx: rotx,
                                    roty: roty,
                                    rotz: rotz
                                },
                                excelvalue: l,
                                path: commponentpath,
                                material: s[11],
                                s13: s[13],
                                s14: s[14],
                                verknuepfteElemente: ve
                            };

                            if (s[15] != '') {
                                // Wenn in Spalte W etwas steht, wird das Bauteil in die Bauteilliste geschrieben
                                //    this.bauteillisteTemp.push(l);
                            }
                            Utils.setValue(tree, parent + "." + sheetname + "#Component_Z" + i,
                                {
                                    //  callcell: callcell,
                                    i: i,
                                    o: o,
                                    transformation: {
                                        offset: offsetstr,
                                        rotx: rotx,
                                        roty: roty,
                                        rotz: rotz
                                    },
                                    path: parent + ".Component_Z" + i
                                });

                            o = this.updateElement(o, lastval?.meshuuid);

                            if (s[3] === "Component-Substract") {
                                console.log(o);
                                //                var cutmesh = this.getMeshByUUID((o as any).meshuuid);
                                //   cutmesh.visible = false;
                            }

                            rows.push(o);
                            globalThis.updateElement = this.updateElement;
                            globalThis.removeFromScene = this.removeFromScene;
                            globalThis.scene = this.baseScene;
                            if (lastval?.iscopied) {
                                // alle kopierten ebenfalls updates
                                var newtree = await traverse(lastval).forEach(function (value) {
                                    try {
                                        if (typeof (value) === "object" && value) {
                                            if (value?.meshuuid) {
                                                if (value?.iscopy) {
                                                    const meshtoremove = globalThis.scene.getObjectByProperty('uuid', value?.meshuuid);
                                                    globalThis.removeFromScene(meshtoremove);
                                                    // const lineoremove = globalThis.scene.getObjectByProperty('uuid', value?.lineuuid);
                                                    // globalThis.scene.remove(lineoremove);
                                                    //  globalThis.updateElement(value, value?.meshuuid, value?.lineuuid);
                                                }

                                            }
                                        }
                                    } catch (err) { }
                                });
                            }

                        }
                    }
                    catch (e) {
                        console.log(e, 'i', i, 'l', l);
                    }
                }
            }
        }


        return rows;
    }



    async removeOrUpdateObjects(updateTree, typ) {
        var paths2Remove = [];
        var tree = null;
        // alle zu löschenden Pfade durchlaufen und die entsprechenden Objekte löschen.
        var newtree = await traverse(updateTree).forEach(function (value) {
            try {
                if (typeof (value) === "object" && value) {

                    if (value.typ) {
                        if (typ == 'remove') {
                            // Objekt im original Tree löschen
                            if (value.path)
                                Utils.setValue(globalThis.tree, value.path, {});
                            if (value.meshuuid || value.lineuuid || value.textuuid)
                                paths2Remove.push(value.path);
                            if (value.excelvalue)
                                globalThis.bauteillisteTempDelete.push(value.excelvalue);
                            if (value.typ != "SubConfiguration") {
                                // Alle Objekte zum Updaten markieren
                                var objToUpdate = Utils.getValue(globalThis.tree, value.path);
                                globalThis.elements2Update.push(objToUpdate);
                            }
                        }
                        if (value.typ == "Component-Substract") {
                            for (var key in value.cuttedobjects) {
                                if (value.cuttedobjects.hasOwnProperty(key)) {
                                    var toupdate = value.cuttedobjects[key];
                                    var neu = Utils.getValue(globalThis.tree, toupdate.path);
                                    if (neu)
                                        globalThis.elements2Update.push(neu);
                                }
                            }
                        }

                        if (value?.meshuuid) {
                            for (var jj = 0; jj < globalThis.meshes.length; jj++) {
                                if (globalThis.meshes[jj]?.uuid === value?.meshuuid) {
                                    if (globalThis.scene && globalThis.meshes[jj]?.uuid) {
                                        var meshtoremove = globalThis.scene.getObjectByProperty('uuid', value?.meshuuid);
                                        globalThis.removeFromScene(meshtoremove);
                                        globalThis.meshes.splice(jj, 1);
                                        //  value.meshuuid = null;
                                        //  this.update(value);
                                        break;
                                    }
                                }
                            }
                        }
                        if (value?.lineuuid)
                            for (var jj = 0; jj < globalThis.lines.length; jj++) {
                                if (globalThis.lines[jj]?.uuid === value?.lineuuid) {
                                    if (globalThis.scene && globalThis.lines[jj]?.uuid) {
                                        var meshtoremove = globalThis.scene.getObjectByProperty('uuid', value?.lineuuid);
                                        globalThis.removeFromScene(meshtoremove);
                                        globalThis.lines.splice(jj, 1);
                                        break;
                                    }
                                }
                            }
                        if (value?.textuuid)
                            for (var jj = 0; jj < globalThis.texts.length; jj++) {
                                if (globalThis.texts[jj]?.uuid === value?.textuuid) {
                                    if (globalThis.scene && globalThis.texts[jj]?.uuid) {
                                        var meshtoremove = globalThis.scene.getObjectByProperty('uuid', value?.textuuid);
                                        globalThis.removeFromScene(meshtoremove);
                                        globalThis.texts.splice(jj, 1);
                                        break;
                                    }
                                }
                            }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        });


    }
    updateText(s, i, tree, parent, sheetname, sheetid, subkonfiguration, l, rotX, rotY, rotZ, tX, tY, tZ, oldid) {
        // // Verschiebung aus Spalte V
        var offsetv = s[14];
        var offsetvx = offsetv !== "" ? parseFloat(offsetv.split('Y')[0].replace('X', '')) : 0;
        var offsetvy = offsetv !== "" ? parseFloat(offsetv.split('Y')[1].split('Z')[0]) : 0;
        var offsetvz = offsetv !== "" ? parseFloat(offsetv.split('Z')[1]) : 0;
        var offsetstr = offsetvx + ":" + offsetvy + ":" + offsetvz;
        // // rotation
        var rotu = s[13];//X0Y0Z0
        var rotx = rotu !== "" ? parseFloat(rotu.split('Y')[0].replace('X', '')) : 0;
        var roty = rotu !== "" ? parseFloat(rotu.split('Y')[1].split('Z')[0]) : 0;
        var rotz = rotu !== "" ? parseFloat(rotu.split('Z')[1]) : 0;
        if (Number.isNaN(rotx)) rotx = 0;
        if (Number.isNaN(roty)) roty = 0;
        if (Number.isNaN(rotz)) rotz = 0;
        var commponentpath = parent + "." + sheetname + "#TEXT_Z" + i;
        var p1 = s[4].split(':');
        var rotadd = s[5].split(':');
        const cs = [];// l.points;
        cs.push({ x: parseFloat(p1[0]), y: parseFloat(p1[1]), z: parseFloat(p1[2]) });
        cs.push({ x: 1, y: 0, z: 0 });
        // cs.push({ x: parseFloat(p2[0]), y: parseFloat(p2[1]), z: parseFloat(p2[2]) });

        var o = {
            nr: "Z" + i, // s[0],
            cell: "Z" + i, // s[0],
            subconfiguration: subkonfiguration,
            sheetid: sheetid,
            sheet: { sheetname: sheetname, col: 25, row: i },
            name: s[1],
            layer: s[2],
            typ: s[3],
            text: s[15],
            textsize: s[16],
            // farbe: farbe,
            coords: cs,
            parent: parent, // s[9],
            baugruppe: "",
            montageabschnitt: "",

            transformation: {
                offset: offsetstr,
                rotx: rotx,
                roty: roty,
                rotz: rotz
            },
            extrusion: {
                x: 0, y: 0, z: 1
            },
            excelvalue: l,
            path: commponentpath,
            material: s[11],
            s13: s[13],
            s14: s[14]
        };

        Utils.setValue(tree, parent + "." + sheetname + "#TEXT_Z" + i,
            {
                i: i,
                o: o,
                transformation: {
                    offset: offsetstr,
                    rotx: rotx,
                    roty: roty,
                    rotz: rotz
                },
                path: parent + ".TEXT_Z" + i
            });


        //////////////////////

        const matLite = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
        let shapes = this.baseScene.font.generateShapes(o.text, o.textsize * 0.6);
        let geometry = new THREE.ShapeGeometry(shapes);

        var text: any = new THREE.Mesh(geometry, matLite);
        text.o = o;
        var lx = o.extrusion.x;
        var ly = o.extrusion.y; // wird gedreht in TC?
        var lz = o.extrusion.z;

        text.lookAt(lx, ly, lz);

        text.position.x = o.coords[0].x;
        text.position.y = o.coords[0].y;
        text.position.z = - o.coords[0].z;

        text.rotateOnAxis(new THREE.Vector3(0, 0, 1), THREE.MathUtils.DEG2RAD * -90);

        var pivot = new THREE.Object3D();
        pivot.add(text);

        // Positionieren
        var pathparts = o.path.split('.');
        for (var y = 0; y < pathparts.length; y++) {
            // Pfade rückwärts abarbeiten path ist zB:
            var curpath = pathparts[0];
            for (var j = 1; j < pathparts.length - y; j++)
                curpath += "." + pathparts[j];
            var c = null;// Utils.getValue(this.tree, curpath);// + ".transformation");
            var trafo = c.transformation;

            if (trafo) {

                var tx = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[0]) : 0;
                var ty = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[1]) : 0;
                var tz = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[2]) : 0;
                if (Number.isNaN(tx)) tx = 0;
                if (Number.isNaN(ty)) ty = 0;
                if (Number.isNaN(tz)) tz = 0;

                //  console.log('TC', curpath, trafo, o?.text);

                var rotx = Math.PI / 180 * trafo.rotx;
                var roty = Math.PI / 180 * trafo.roty;
                var rotz = Math.PI / 180 * trafo.rotz;

                if (rotx != 0)
                    this.rotateAboutWorldAxis(pivot, "x", rotx);
                if (roty != 0)
                    this.rotateAboutWorldAxis(pivot, "y", roty);
                if (rotz != 0)
                    this.rotateAboutWorldAxis(pivot, "z", rotz);


                pivot.position.set(pivot.position.x + tx, pivot.position.y + ty, pivot.position.z + tz);

                pivot.updateMatrix();
            }

        }
        pivot.updateMatrix();

        text.updateMatrixWorld();
        //   THREE.SceneUtils.detach( mesh, pivot, this.scene );

        // parent it to the scene
        this.baseScene.scene.attach(text);
        text.updateMatrix();

        text.name = "masstext";
        text.visible = !this.baseScene.visualisierteDarstellung;
        this.baseScene.scene.add(text);


        (o as any).textuuid = text.uuid;

        if (oldid)
            for (var j = 0; j < this.baseScene.texts.length; j++) {
                if (this.baseScene.texts[j])
                    if (this.baseScene.texts[j]?.uuid === oldid) {
                        const textremove = this.baseScene.scene.getObjectByProperty('uuid', this.baseScene.texts[j].uuid);
                        this.removeFromScene(textremove);
                        this.baseScene.texts.splice(j, 1);
                        break;
                    }
                if (j == this.baseScene.texts.length - 1)
                    console.log('text not found ' + oldid)
            }
        this.baseScene.texts.push(text);

        //   Utils.setValue(this.tree, o.path, o);
    }
    updateLine(s, i, tree, parent, sheetname, sheetid, subkonfiguration, l, rotX, rotY, rotZ, tX, tY, tZ, oldlineid) {
        // // Verschiebung aus Spalte V
        var offsetv = s[14];
        var offsetvx = offsetv !== "" ? parseFloat(offsetv.split('Y')[0].replace('X', '')) : 0;
        var offsetvy = offsetv !== "" ? parseFloat(offsetv.split('Y')[1].split('Z')[0]) : 0;
        var offsetvz = offsetv !== "" ? parseFloat(offsetv.split('Z')[1]) : 0;
        var offsetstr = offsetvx + ":" + offsetvy + ":" + offsetvz;
        // // rotation
        var rotu = s[13];//X0Y0Z0
        var rotx = rotu !== "" ? parseFloat(rotu.split('Y')[0].replace('X', '')) : 0;
        var roty = rotu !== "" ? parseFloat(rotu.split('Y')[1].split('Z')[0]) : 0;
        var rotz = rotu !== "" ? parseFloat(rotu.split('Z')[1]) : 0;
        if (Number.isNaN(rotx)) rotx = 0;
        if (Number.isNaN(roty)) roty = 0;
        if (Number.isNaN(rotz)) rotz = 0;
        var commponentpath = parent + "." + sheetname + "#LINE_Z" + i;
        const cs = [];// l.points;
        if (s[2] == "Bemassung") {
            var p1 = s[4].split(':');
            var p2 = s[5].split(':');
            cs.push({ x: parseFloat(p1[0]), y: -parseFloat(p1[1]), z: parseFloat(p1[2]) });
            cs.push({ x: parseFloat(p1[0]) + parseFloat(p2[0]), y: - parseFloat(p1[1]) - parseFloat(p2[1]), z: parseFloat(p1[2]) + parseFloat(p2[2]) });
        }
        else {
            var p12 = s[4].split('/');
            var p1 = p12[0].split(':');
            cs.push({ x: parseFloat(p1[0]), y: parseFloat(p1[1]), z: parseFloat(p1[2]) });

            if (p12[1]) {
                var p2 = p12[1].split(':');
                cs.push({ x: parseFloat(p2[0]), y: parseFloat(p2[1]), z: parseFloat(p2[2]) });
            }
        }
        var o = {
            nr: "Z" + i, // s[0],
            cell: "Z" + i, // s[0],
            subconfiguration: subkonfiguration,
            sheetid: sheetid,
            sheet: { sheetname: sheetname, col: 25, row: i },
            name: s[1],
            layer: s[2],
            // TODO     baugruppe: baugruppe,
            typ: s[3],
            // farbe: farbe,
            coords: cs,
            parent: parent, // s[9],
            transformation: {
                offset: offsetstr,
                rotx: rotx,
                roty: roty,
                rotz: rotz
            },
            excelvalue: l,
            path: commponentpath,
            material: s[11],
            s13: s[13],
            s14: s[14]
        };

        Utils.setValue(tree, parent + "." + sheetname + "#LINE_Z" + i,
            {
                i: i,
                o: o,
                transformation: {
                    offset: offsetstr,
                    rotx: rotx,
                    roty: roty,
                    rotz: rotz
                },
                path: parent + ".LINE_Z" + i
            });

        //////////////////////
        const material = new THREE.LineBasicMaterial({ color: 0xff00ff });

        const points = [];
        points.push(new THREE.Vector3(o.coords[0].x, o.coords[0].y, o.coords[0].z));
        if (o.coords[1])
            points.push(new THREE.Vector3(o.coords[1].x, o.coords[1].y, o.coords[1].z));
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const line: any = new THREE.Line(geometry, material);

        line.visible = !this.baseScene.visualisierteDarstellung;
        line.o = o;


        var pivot = new THREE.Object3D();
        pivot.add(line);

        // Positionieren
        var pathparts = o.path.split('.');
        for (var y = 0; y < pathparts.length; y++) {
            // Pfade rückwärts abarbeiten path ist zB:
            var curpath = pathparts[0];
            for (var j = 1; j < pathparts.length - y; j++)
                curpath += "." + pathparts[j];
            //       var c = Utils.getValue(this.tree, curpath);// + ".transformation");
            var trafo = null// c.transformation;

            if (trafo) {

                var tx = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[0]) : 0;
                var ty = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[1]) : 0;
                var tz = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[2]) : 0;
                if (Number.isNaN(tx)) tx = 0;
                if (Number.isNaN(ty)) ty = 0;
                if (Number.isNaN(tz)) tz = 0;

                //  console.log('TC', curpath, trafo, o?.text);

                var rotx = Math.PI / 180 * trafo.rotx;
                var roty = Math.PI / 180 * trafo.roty;
                var rotz = Math.PI / 180 * trafo.rotz;

                if (rotx != 0)
                    this.rotateAboutWorldAxis(pivot, "x", rotx);
                if (roty != 0)
                    this.rotateAboutWorldAxis(pivot, "y", roty);
                if (rotz != 0)
                    this.rotateAboutWorldAxis(pivot, "z", rotz);


                pivot.position.set(pivot.position.x + tx, pivot.position.y + ty, pivot.position.z + tz);

                pivot.updateMatrix();
            }

        }
        pivot.updateMatrix();

        line.updateMatrixWorld();
        //   THREE.SceneUtils.detach( mesh, pivot, this.scene );

        // parent it to the scene
        this.baseScene.scene.attach(line);

        //line.rotateOnAxis(new THREE.Vector3(rotx, roty, rotz), THREE.MathUtils.DEG2RAD * -90);
        this.baseScene.scene.add(line);
        (o as any).lineuuid = line.uuid;

        if (oldlineid)
            for (var j = 0; j < this.baseScene.lines.length; j++) {
                if (this.baseScene.lines[j])
                    if (this.baseScene.lines[j]?.uuid === oldlineid) {
                        const lineoremove = this.baseScene.scene.getObjectByProperty('uuid', this.baseScene.lines[j].uuid);
                        this.removeFromScene(lineoremove);
                        this.baseScene.lines.splice(j, 1);
                        break;
                    }
                if (i == this.baseScene.lines.length - 1)
                    console.log('line not found ' + oldlineid)
            }
        this.baseScene.lines.push(line);

        // Utils.setValue(this.tree, o.path, o);
    }
    updateElement(obj, oldmeshid) {
        if (!obj)
            return;

        // To Check
        var alpha = 0;

        try {
            obj.treevisible = true;
            let cs = [];// l.points;
            obj.coords.forEach(p => {
                cs.push({ x: p.x, y: -p.y, z: p.z });
            });
            //obj.extrusionsvektor = { x: obj.extrusion.x, y: obj.extrusion.y, z: obj.extrusion.z }
            //   l.farbe = 10592672;
            obj.id = obj.name; // falsch?!
            let o = obj;

            if (o.typ.toUpperCase() === "COMPONENT" || o.typ.toUpperCase() === "LIGHT" || o.typ === "Component-Substract") {

                if (o.typ === "Component-Substract") {
                    o.treevisible = false;
                };

                {
                    var samecoords = Utils.samecoordinates(o);
                    var plane = 'x';
                    if (Math.round(o.extrusion.x) != 0) { plane = 'x'; }
                    if (Math.round(o.extrusion.y) != 0) { plane = 'y'; }
                    if (Math.round(o.extrusion.z) != 0) { plane = 'z'; }

                    if (o.name.toUpperCase() === "ORTGANG") {
                        plane = 'y';
                    }
                    var extrudeSettings = {
                        steps: 1,
                        depth: 1,// cs[0].y,
                        bevelEnabled: false
                    };
                    const shape = new THREE.Shape();

                    var tX = 0; var tY = 0; var tZ = 0;
                    var offset = 0;

                    // shape.autoClose = true;
                    var rotX = 0; var rotY = 0; var rotZ = 0;

                    if (plane === "x") {
                        shape.moveTo(-cs[0].y, cs[0].z);
                    }
                    if (plane === "y") {
                        shape.moveTo(cs[0].x, cs[0].z);
                    }
                    if (plane === "z") {
                        shape.moveTo(cs[0].x, -cs[0].y);
                    }

                    for (let i = 1; i < cs.length; i++) {
                        if (plane === "x") {
                            if (o.extrusion.x < 0) {
                                tX = cs[0].x + o.extrusion.x; ///
                                extrudeSettings.depth = -this.length(o.extrusion);//o.extrusion.x;
                            }
                            else {
                                tX = cs[0].x;
                                extrudeSettings.depth = this.length(o.extrusion);// o.extrusion.x;
                            }
                            offset = tX;

                            rotY = 1;
                            shape.lineTo(-cs[i].y, cs[i].z);
                        }
                        if (plane === "y") {
                            if (o.extrusion.y * -1 < 0) {
                                tY = cs[0].y + o.extrusion.y * -1;
                                extrudeSettings.depth = this.length(o.extrusion);//o.extrusion.y;
                            }
                            else {
                                tY = cs[0].y;
                                extrudeSettings.depth = -this.length(o.extrusion);// o.extrusion.y;
                            }
                            offset = tY;

                            rotX = 1;
                            shape.lineTo(cs[i].x, cs[i].z);
                        }
                        if (plane === "z") {
                            if (o.extrusion.z < 0) {
                                tZ = cs[0].z + o.extrusion.z; ///
                                extrudeSettings.depth = -this.length(o.extrusion);//o.extrusion.z;
                            }
                            else {
                                tZ = cs[0].z;
                                extrudeSettings.depth = this.length(o.extrusion);//o.extrusion.z;
                            }
                            offset = tZ;

                            // rotX = 1;
                            shape.lineTo(cs[i].x, -cs[i].y);
                        }
                    }

                    var mesh: any;
                    if (o.typ.toUpperCase() === "LIGHT") {

                        var data = o.material.split("$")[1].split("/");
                        mesh = new THREE.Object3D();
                        mesh.position.set(cs[0].x, -cs[0].y, cs[0].z);
                        var light: any;
                        plane = "z";
                        if (data[0].toUpperCase() === "SPOTLIGHT") {
                            light = new THREE.SpotLight(Number(data[2]));
                            //    light.visible = data[1].toUpperCase() == "EXTERN";
                            light.intensity = Number(data[3]);
                            light.distance = Number(data[4]);
                            light.angle = Number(data[5]);
                            light.penumbra = Number(data[6]);
                            light.decay = Number(data[7]);

                            //  var bulbGeometry = new THREE.SphereGeometry( 2, 16, 8 );
                            var bulbMat = new THREE.MeshStandardMaterial({
                                emissive: 0xffffee,
                                emissiveIntensity: 1,
                                color: 0x000000
                            });
                            bulbMat.emissiveIntensity = light.intensity / Math.pow(0.02, 2.0); // convert from intensity to irradiance at bulb surface
                            //    var k = new THREE.Mesh( bulbGeometry, bulbMat ) ;
                            //  k.position.set( cs[0].x,  -cs[0].y , cs[0].z);
                            //    k.updateMatrix();
                            //    k.castShadow = false;
                            //   mesh.add( k);

                            //  light.position.set( cs[0].x,  -cs[0].y , cs[0].z);
                            light.castShadow = true;

                            // change the direction this spotlight is facing
                            var lightTarget = new THREE.Object3D();
                            lightTarget.position.set(0, 0, -0.01);
                            lightTarget.updateMatrix();
                            mesh.updateMatrix();
                            mesh.add(lightTarget);
                            light.target = lightTarget;
                        }
                        else {
                            light = new THREE.PointLight(Number(data[2]), Number(data[4]), Number(data[5]), Number(data[6]));
                            //   light.visible = data[1].toUpperCase() == "EXTERN";
                            //var bulbGeometry = new THREE.SphereGeometry( 2, 16, 8 );
                            var bulbMat = new THREE.MeshStandardMaterial({
                                emissive: 0xffffee,
                                emissiveIntensity: 1,
                                color: 0x000000
                            });
                            light.power = Number(data[3]);
                            bulbMat.emissiveIntensity = light.intensity / Math.pow(0.02, 2.0); // convert from intensity to irradiance at bulb surface
                            // light.add( new THREE.Mesh( bulbGeometry, bulbMat ) );

                            light.castShadow = false;
                        }

                        /*   light.shadow.mapSize.width = 512;
                           light.shadow.mapSize.height = 512;
                           light.shadow.camera.near = 10;
                           light.shadow.camera.far = 500;
                           light.shadow.focus = 1;*/

                        if (light != null) {
                            mesh.add(light);

                            //    mesh.position.z = offset;
                            //   mesh.receiveShadow = false;
                            //   mesh.castShadow = false;
                            light.updateMatrix();
                            mesh.updateMatrix();
                        }
                    }
                    else {

                        // Längeste Kante ermitteln
                        if (shape.curves.length > 0) {
                            var maxCurve = shape.curves[0] as THREE.LineCurve;
                            shape.curves.forEach(curve => {
                                if (maxCurve.getLength() < curve.getLength()) {
                                    maxCurve = curve as THREE.LineCurve;
                                }
                            }
                            );
                            var rotation = new Vector2(maxCurve.v1.x - maxCurve.v2.x, maxCurve.v1.y - maxCurve.v2.y).angle() as number;
                        }

                        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                        const material = this.materials.getMaterial(o.material, rotation, this.baseScene.texturen);
                        if (material.userData.hasOwnProperty("hastexture") && material.userData["hastexture"] !== true)
                            material.color = new THREE.Color(o.farbe);

                        mesh = new THREE.Mesh(geometry, material);
                        mesh.geometry.translate(0, 0, offset);
                        mesh.receiveShadow = true;
                        mesh.castShadow = true;

                        if (mesh.material && mesh.material.map) mesh.material.map.anisotropy = 4;

                        if (o.material.startsWith("LICHT") || o.material === "FENSTER_GLAS" || o.material.startsWith("WANDOFFNUNG") || o.material === 'ROHLING_OFFNUNG') {
                            material.opacity = 0.7;
                            material.transparent = true;
                            material.depthWrite = true;
                            mesh.receiveShadow = false;
                            mesh.castShadow = false;
                        }
                    }

                    mesh.updateMatrix();

                    if (plane == "x") {
                        mesh.rotateY(THREE.MathUtils.DEG2RAD * 90);
                        mesh.updateMatrix();

                        mesh.rotateZ(THREE.MathUtils.DEG2RAD * 90);
                    }
                    else if (plane == "y") {
                        mesh.rotateX(THREE.MathUtils.DEG2RAD * 90);
                    }

                    mesh.updateMatrix();

                    var pivot = new THREE.Object3D();
                    pivot.add(mesh);

                    // Positionieren
                    var pathparts = o.path.split('.');
                    for (var i = 0; i < pathparts.length; i++) {
                        // Pfade rückwärts abarbeiten path ist zB:
                        var curpath = pathparts[0];
                        for (var j = 1; j < pathparts.length - i; j++)
                            curpath += "." + pathparts[j];
                        var c = null// Utils.getValue(this.tree, curpath);// + ".transformation");
                        var trafo = c.transformation;

                        if (trafo) {

                            var tx = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[0]) : 0;
                            var ty = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[1]) : 0;
                            var tz = trafo.offset !== '' ? parseFloat(trafo.offset.split(':')[2]) : 0;
                            if (Number.isNaN(tx)) tx = 0;
                            if (Number.isNaN(ty)) ty = 0;
                            if (Number.isNaN(tz)) tz = 0;

                            //  console.log('TC', curpath, trafo, o?.text);

                            var rotx = Math.PI / 180 * trafo.rotx;
                            var roty = Math.PI / 180 * trafo.roty;
                            var rotz = Math.PI / 180 * trafo.rotz;

                            if (i == 0 && o.typ.toUpperCase() === "LIGHT") {
                                // Licht wird auf lokale Achse gedreht
                                if (rotx != 0)
                                    mesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), rotx);
                                if (roty != 0)
                                    mesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), roty);
                                if (rotz != 0)
                                    mesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), rotz);
                            }
                            else {
                                if (rotx != 0)
                                    this.rotateAboutWorldAxis(pivot, "x", rotx);
                                if (roty != 0)
                                    this.rotateAboutWorldAxis(pivot, "y", roty);
                                if (rotz != 0)
                                    this.rotateAboutWorldAxis(pivot, "z", rotz);
                            }

                            pivot.position.set(pivot.position.x + tx, pivot.position.y + ty, pivot.position.z + tz);

                            pivot.updateMatrix();
                        }

                    }
                    pivot.updateMatrix();

                    mesh.updateMatrixWorld();
                    //   THREE.SceneUtils.detach( mesh, pivot, this.scene );

                    // parent it to the scene
                    this.baseScene.scene.attach(mesh);

                    this.baseScene.meshes.push(mesh);
                    this.baseScene.scene.add(mesh);


                    mesh.name = obj.id;
                    (mesh as any).excel = {

                        nr: o.nr,
                        cell: o.cell,
                        subconfiguration: o.subconfiguration,
                        sheetid: o.sheetid,
                        sheet: { sheetname: o.sheet.sheetname, col: o.sheet.col, row: o.sheet.row }
                    };
                    (mesh as any).transformation = o.transformation;
                    (mesh as any).params = o.params;
                    (mesh as any).path = o.path;
                    (mesh as any).alpha = alpha;
                    (mesh as any).o = o;

                    obj.meshuuid = mesh.uuid;
                    // obj.lineuuid = line?.uuid;
                    globalThis.meshpathmapping[mesh.uuid] = o.path;

                    if (mesh && o.typ.toUpperCase() !== "COMPONENT-SUBSTRACT" && o.typ.toUpperCase() !== "LIGHT") {
                        mesh.visible = true;
                        mesh.updateMatrix();
                    }

                    if (o.typ.toUpperCase() === "COMPONENT" || o.typ.toUpperCase() === "LIGHT" || o.typ.toUpperCase() === "COMPONENT-SUBSTRACT") {

                        if (oldmeshid)
                            for (var i = 0; i < this.baseScene.meshes.length; i++) {
                                if (this.baseScene.meshes[i])
                                    if (this.baseScene.meshes[i]?.uuid === oldmeshid) {

                                        const meshtoremove = this.baseScene.scene.getObjectByProperty('uuid', this.baseScene.meshes[i].uuid);

                                        if (meshtoremove) {
                                            meshtoremove.visible = false;
                                            this.removeFromScene(meshtoremove);
                                            this.baseScene.meshes.splice(i, 1);

                                        }

                                        break;
                                    }
                            }

                        // Kanten zeichnen
                        if (mesh.geometry) {
                            var egeometry = new THREE.EdgesGeometry(mesh.geometry);
                            /*  var farbe = o.farbe;
                              if (o.material.startsWith("BG"))
                                farbe = 8421504;*/
                            var linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1, transparent: false, }));
                            linestandard.name = "KANTE";
                            linestandard.visible = !this.baseScene.visualisierteDarstellung;
                            mesh.add(linestandard);

                        }

                        // Utils.setValue(this.tree, obj.path, obj);

                        // kopien ebenfalls updaten
                        // if (l.iscopied)
                        //   console.log('iscopied');
                    }
                }

            }
            if (o.typ === "Line") {
            }

        } catch (err) {
            console.warn(err);
        }

        this.baseScene.requestRenderIfNotRequested();

        return obj;
    }


    spheres: any[];
    showcontextmenu = false;
    contextmenux = "10px";
    contextmenuy = "10px";
    createPoints(subconfiguration) {
        try {
            if (this.spheres)
                for (var i = 0; i < this.spheres.length; i++)
                    this.removeFromScene(this.spheres[i]);

            this.spheres = [];
            // dummy code
            var sid = (this.excels[subconfiguration] as HyperFormula).getSheetId("Punkte");


            //const sphere1 = new THREE.Mesh(geometry, material);
            var nr = 1;
            var i = 1;
            while (nr) {
                var aktiv = this.excels[subconfiguration].getCellValue({ sheet: sid, col: 1, row: i });
                if (aktiv) {

                    var x = this.excels[subconfiguration].getCellValue({ sheet: sid, col: 2, row: i });
                    var y = this.excels[subconfiguration].getCellValue({ sheet: sid, col: 3, row: i });
                    var z = this.excels[subconfiguration].getCellValue({ sheet: sid, col: 4, row: i });

                    //x -= this.hallenlaenge / 2;
                    // z += this.hallenbreite / 2;
                    const geometry = new THREE.BoxGeometry(28, 28, 28);
                    const material = new THREE.MeshPhongMaterial({ color: 0x007bff });
                    var sphere = new THREE.Mesh(geometry, material); // sphere1.clone();
                    sphere.userData.nr = nr;
                    sphere.userData.type = "inputpoint"
                    sphere.position.set(x, y, z);
                    this.spheres.push(sphere);
                    this.baseScene.add(sphere);
                }
                i++;
                nr = this.excels['LIGNA-SYSTEMS-OFFNUNG'].getCellValue({ sheet: sid, col: 0, row: i });


            }
        }
        catch (err) {

        }

    }



    removeFromScene(objectToRemove) {
        if (objectToRemove.geometry)
            objectToRemove.geometry.dispose();
        if (objectToRemove.material)
            objectToRemove.material.dispose();
        if (objectToRemove.children)
            objectToRemove.children.forEach(element => {
                this.removeFromScene(element);
            });
        this.baseScene.scene.remove(objectToRemove);
    }

    private rotateAboutWorldAxis(obj: THREE.Object3D, axis: string, rotation: number) {

        //Create a matrix
        var matrix = new THREE.Matrix4();
        // Rotate the matrix
        if (axis == "x")
            matrix.makeRotationX(rotation);
        else if (axis == "y")
            matrix.makeRotationY(rotation);
        else if (axis == "z")
            matrix.makeRotationZ(rotation);

        //rotate the object using the matrix
        obj.applyMatrix4(matrix);

    }


    async createmodel(subkonfiguration, selectedpath, tree) {
        var t0 = performance.now();
        console.log("createmodel");

        var view3drowindex = 2;
        var rowsa = [];
        this.baseScene.meshes = [];


        var startrow = "start";
        while (startrow) {
            // TODO geht nur beim ersten Mal in der Hauptkonfig
            var vcell = await this.excels[subkonfiguration].getView3DCell(view3drowindex);
            startrow = vcell?.row;
            if (!vcell)
                break;


            let sheetname = (await this.excels[subkonfiguration].getView3DCell(view3drowindex)).sheet;  // this.getStartCell3D(subkonfiguration).sheet;
            let sheetid = await this.excels[subkonfiguration].getSheetId(sheetname);
            var v3did = await this.excels[subkonfiguration].getSheetId("View-3d");
            var isvisible = await this.excels[subkonfiguration].getCellValue({ sheet: v3did, col: 4, row: view3drowindex });
            if (isvisible === true) {
                // neu parameter für unterkonfiguration
                var parent = selectedpath; //.substring(0, this.SELECTEDPATH.lastIndexOf("#"));
                // var callcell = this.SELECTEDPATH.substring(this.SELECTEDPATH.lastIndexOf("_") + 1, this.SELECTEDPATH.length);

                // SELECTEDPATH: '_root.LIGNA-SYSTEMS-GIEBELBINDER_Z1304.LIGNA-SYSTEMS-WINDVERBAND_Z1240'
                //   var parentpath = parent + callcell; // Utils.getParentPath(this.SELECTEDPATH) + "." + sheetname + "#Subconfiguration_" + callcell;
                var configval = Utils.getValue(tree, selectedpath);
                var s = configval?.o?.excelvalue.split(';');
                if (s) {

                    var subrotu = s[13]; //X0Y0Z0
                    var subrotx = subrotu !== "" ? parseFloat(subrotu.split('Y')[0].replace('X', '')) : 0;
                    var subroty = subrotu !== "" ? parseFloat(subrotu.split('Y')[1].split('Z')[0]) : 0;
                    var subrotz = subrotu !== "" ? parseFloat(subrotu.split('Z')[1]) : 0;
                    var subtu = s[14]; //X0Y0Z0
                    var subtx = subtu !== "" ? parseFloat(subtu.split('Y')[0].replace('X', '')) : 0;
                    var subty = subtu !== "" ? parseFloat(subtu.split('Y')[1].split('Z')[0]) : 0;
                    var subtz = subtu !== "" ? parseFloat(subtu.split('Z')[1]) : 0;
                    var x = parseFloat(s[5].split(':')[0]);
                    var y = parseFloat(s[5].split(':')[1]);
                    var z = parseFloat(s[5].split(':')[2]);
                    var layer = s[1];
                    var offsets = s[4];
                    var offsetxs = offsets !== "" ? parseFloat(offsets.split(':')[0]) : 0; // offset != "" ? parseFloat(offset.split('Y')[0].replace('X', '')) : 0;
                    var offsetys = offsets !== "" ? parseFloat(offsets.split(':')[1]) : 0; // offset != "" ? parseFloat(offset.split('Y')[1].split('Z')[0]) : 0;
                    var offsetzs = offsets !== "" ? parseFloat(offsets.split(':')[2]) : 0; // offset != "" ? parseFloat(offset.split('Z')[1]) : 0;
                    subtx = subtx + offsetxs;
                    subty = subty + offsetys;
                    subtz = subtz + offsetzs;
                    subtu = subtx + ":" + subty + ":" + subtz;
                    //                    startrow, subkonfiguration, sheetname, sheetid, rows, offset, srotx, sroty, srotz, tree, parent, params, parentPositionHasChanged
                    //    this.changedElements = await this.xls2json(startrow, subkonfiguration, sheetname, sheetid, rowsa, subtu, subrotx, subroty, subrotz, this.tree, parent, s[10], false);
                } else {
                    //   this.changedElements = await this.xls2json(startrow, subkonfiguration, sheetname, sheetid, rowsa, '', 0, 0, 0, this.tree, parent, "", false);
                }
            }
            view3drowindex++;
        }



        console.log('create cuts')
        await this.create3DCuts();
        console.log('cuts created')


        //// TODO: AB HIER IN in CONFIGURATOR.TS -----------------------------
        // // this.changeAssemblyViewAll(this.meshes);

        // // this.renderer.shadowMap.autoUpdate = true;
        // // this.renderer.shadowMap.needsUpdate = true;

        // // // Bauteilliste der Hauptkonfiguration aktualisieren
        // // //   if(subkonfiguration != this.MAINCONFIGURATION)
        // // //     this.updateBauteilliste(this.MAINCONFIGURATION);



        // // var t1 = performance.now();
        // // console.log("createmodel() took " + (t1 - t0) + " milliseconds.");

        // // this.loading = false;
        // // document.body.style.cursor = 'default';
        // // this.createModel = false;
        // // this.modelUpToDate = true;


        // // this.setVisualisierteDarstellung(this.visualisierteDarstellung);

        // // this.requestRenderIfNotRequested();

        // // if (this.firstModelCreation && subkonfiguration == this.MAINCONFIGURATION) {
        // //     this.firstModelCreation = false;
        // //     setTimeout(() => {
        // //         this.snackBar.openFromComponent(SimulationInfoBoxComponent, {
        // //             data: this,
        // //             duration: 10 * 1000,
        // //             horizontalPosition: 'right',
        // //             verticalPosition: 'bottom'
        // //         });
        // //     }, 5000);
        // // }

        //////// ---------------

    }


    async create3DCuts() {

        console.log('changedElements', this.changedElements);


        // 1. alle geschnittenen, aktualisierten Elemente updaten (= geschnitten Update + Schnittkörper abhängikeiten)
        var elementstocut = JSON.parse(JSON.stringify(this.changedElements));
        for (var i = 0; i < this.changedElements.length; i++) {
            //this.createCuts(this.changedElements[i]);

            var element = this.changedElements[i];

            if (element.verknuepfteElemente) {
                for (var v = 0; v < element.verknuepfteElemente.length; v++)
                    for (var j = 0; j < this.baseScene.meshes.length; j++) {
                        if (this.baseScene.meshes[j].name === element.verknuepfteElemente[v]) {
                            elementstocut.push(this.baseScene.meshes[j].o);
                        }
                    }
            }
        }


        elementstocut = _.uniqBy(elementstocut, function (o: any) {
            return o.meshuuid; // oder doch besser path abfragen?
        });
        console.log('cutted updated without duplicates', elementstocut)

        var t0 = performance.now();


        //          var newmesh = this.cutMesh(meshtocut, mesh);

        var t1 = performance.now();
        console.log("Verschneidungen took " + (t1 - t0) + " milliseconds.");
    }




    cutMesh(meshtocut, mesh) {
        if (!meshtocut || !mesh) return meshtocut?.uuid;
        if (meshtocut.uuid == mesh.uuid) { return meshtocut.uuid }
        meshtocut.updateMatrix();
        mesh.visible = false;
        mesh.updateMatrix();

        try {

            const bspA = CSG.fromMesh(mesh);
            const bspB = CSG.fromMesh(meshtocut);

            var intersection = bspB.intersect(bspA) as any;

            if (intersection?.polygons?.length > 0) {
                var p1 = globalThis.meshpathmapping[meshtocut.uuid];
                var p2 = globalThis.meshpathmapping[mesh.uuid];

                const bspResult = bspB.subtract(bspA); //.inverse();
                var meshResult = CSG.toMesh(bspResult, new THREE.Matrix4()) as any;
                meshResult.updateMatrix();
                meshResult.material = meshtocut.material;
                meshResult.receiveShadow = true;
                meshResult.castShadow = true;
                meshResult.name = meshtocut.name;
                meshResult.visible = true;
                (meshResult as any).o = meshtocut.o;
                (meshResult as any).o.meshuuid = meshResult.uuid;
                (meshResult as any).o.cutted = true;
                //  console.log('cutted ', meshResult.uuid)


                // Utils.setValue(this.tree, meshtocut.path + ".meshuuid", meshResult.uuid);
                // Utils.setValue(this.tree, meshtocut.path + ".o.meshuuid", meshResult.uuid);

                var p = globalThis.meshpathmapping[meshtocut.uuid];
                globalThis.meshpathmapping[meshResult.uuid] = p;

                this.baseScene.meshes.push(meshResult);
                this.baseScene.scene.add(meshResult);


                // geschnittene Meshes Schnittmesh hinzufügen (damit es beim Löschen des Schnittkörpers neu verarbeitet werden kann)

                //       Utils.setValue(this.tree, p2, mesh.o);
                //      var cuttedobj = Utils.getValue(this.tree, meshtocut.path);
                //     if (cuttedobj && meshResult.uuid)
                //          Utils.setValue(this.tree, p2 + ".cuttedobjects." + meshResult.uuid, cuttedobj);


                if (!meshResult) {
                    console.log('kein schnitt')
                }
                var egeometry = Utils.EdgesGeometry(meshResult.geometry); //new THREE.EdgesGeometry(meshResult.geometry, 220);//, 92); //

                // Kanten zeichnen
                var farbe = meshResult.o.farbe;
                if (meshResult.o.material.startsWith("BG"))
                    farbe = 8421504;
                var line = new THREE.LineSegments(egeometry as any, new THREE.LineBasicMaterial({ color: new THREE.Color(Utils.lightenDarkenColor(farbe, -40)), linewidth: 10, transparent: true }));
                line.name = "KANTE";
                ///            line.visible = !this.visualisierteDarstellung;
                meshResult.add(line);

                return meshResult;

            } else {
                return meshtocut;
            }
        }
        catch (ie) {
            //      continue;
            console.log("fehler", ie)

        }

    }




    length(ext) {
        var vz = 1;
        if (ext.x + 0.001 < 0) vz = -1;
        if (ext.y + 0.001 < 0) vz *= -1;
        if (ext.z + 0.001 < 0) vz *= -1;
        return vz * Math.sqrt(ext.x * ext.x + ext.y * ext.y + ext.z * ext.z);
    }



}


export { CADEngine }