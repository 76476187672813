// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCl4_ewhEIqOK8cxkhZbylK4lpIqti1hdc",
    authDomain: "xbuild3d.firebaseapp.com",
    databaseURL: "https://xbuild3d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "xbuild3d",
    storageBucket: "xbuild3d.appspot.com",
    messagingSenderId: "45429560595",
    appId: "1:45429560595:web:48e46364951476b1a8f6e6",
    measurementId: "G-8BT108BTEF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
