import {
    Document, Paragraph, Table, TableRow, TableCell, BorderStyle, WidthType,
    ImageRun, TextRun, HeadingLevel, AlignmentType, Packer, TableCellBorders
} from 'docx';
import * as THREE from 'three';

export class ReportCreator {
    constructor(private scene: THREE.Scene, private camera: THREE.Camera, private renderer: THREE.WebGLRenderer) { }

    private storeCameraState() {
        const state = {
            position: this.camera.position.clone(),
            rotation: this.camera.rotation.clone(),
            quaternion: this.camera.quaternion.clone(),
            up: this.camera.up.clone()
        };

        // Handle perspective/orthographic specific properties
        if (this.camera instanceof THREE.PerspectiveCamera) {
            state['zoom'] = (this.camera as THREE.PerspectiveCamera).zoom;
        } else if (this.camera instanceof THREE.OrthographicCamera) {
            state['zoom'] = (this.camera as THREE.OrthographicCamera).zoom;
        }

        return state;
    }

    private restoreCameraState(state: any) {
        this.camera.position.copy(state.position);
        this.camera.rotation.copy(state.rotation);
        this.camera.quaternion.copy(state.quaternion);
        this.camera.up.copy(state.up);

        // Handle perspective/orthographic specific properties
        if (this.camera instanceof THREE.PerspectiveCamera) {
            (this.camera as THREE.PerspectiveCamera).zoom = state.zoom;
            (this.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
        } else if (this.camera instanceof THREE.OrthographicCamera) {
            (this.camera as THREE.OrthographicCamera).zoom = state.zoom;
            (this.camera as THREE.OrthographicCamera).updateProjectionMatrix();
        }
    }

    private async takeScreenshot(camera: THREE.Camera, scene: THREE.Scene, renderer: THREE.WebGLRenderer): Promise<{ data: Uint8Array, width: number, height: number }> {
        // Store original camera position
        const originalPosition = camera.position.clone();
        const originalRotation = camera.rotation.clone();

        // Render the scene
        renderer.render(scene, camera);

        // Convert the render to a PNG
        const imgData = renderer.domElement.toDataURL('image/png');
        const base64Data = imgData.replace(/^data:image\/png;base64,/, '');

        // Get image dimensions
        const width = renderer.domElement.width;
        const height = renderer.domElement.height;

        // Restore camera position
        camera.position.copy(originalPosition);
        camera.rotation.copy(originalRotation);

        return {
            data: Uint8Array.from(atob(base64Data), c => c.charCodeAt(0)),
            width,
            height
        };
    }

    private async captureViews(): Promise<Array<{ data: Uint8Array, width: number, height: number }>> {
        const views = [];

        // Front view
        this.camera.position.set(0, -50, 0);
        this.camera.lookAt(0, 0, 0);
        views.push(await this.takeScreenshot(this.camera, this.scene, this.renderer));

        // Side view
        this.camera.position.set(-50, 0, 0);
        this.camera.lookAt(0, 0, 0);
        views.push(await this.takeScreenshot(this.camera, this.scene, this.renderer));

        // Top view
        const distance = 100; // Distance from camera to origin

        var temprot = this.camera.rotation.clone();
        this.camera.position.set(0, 0, 50);

        var tempup = this.camera.up.clone();
        this.camera.lookAt(0, 0, 0);
        this.camera.rotateZ(-Math.PI / 2);


        //  this.camera.up.set(0, 0, -1); // Set 

        //    this.camera.position.set(0, 50, 0);
        //      this.camera.lookAt(0, 0, 0);
        views.push(await this.takeScreenshot(this.camera, this.scene, this.renderer));

        // this.camera.up = tempup;
        this.camera.rotation.copy(temprot);
        // Isometric view
        this.camera.position.set(30, 30, 30);
        this.camera.lookAt(0, 0, 0);
        views.push(await this.takeScreenshot(this.camera, this.scene, this.renderer));

        return views;
    }

    private createImageCell(imageData: { data: Uint8Array, width: number, height: number }, title: string): TableCell {
        // Calculate the height that preserves the aspect ratio
        const targetWidth = 300;
        const aspectRatio = imageData.width / imageData.height;
        const targetHeight = Math.round(targetWidth / aspectRatio);

        return new TableCell({
            borders: {
                top: { style: BorderStyle.SINGLE, size: 1 },
                bottom: { style: BorderStyle.SINGLE, size: 1 },
                left: { style: BorderStyle.SINGLE, size: 1 },
                right: { style: BorderStyle.SINGLE, size: 1 },
            },
            width: {
                size: 50,
                type: WidthType.PERCENTAGE,
            },
            margins: {
                top: 100,
                bottom: 100,
                left: 100,
                right: 100,
            },
            children: [
                new Paragraph({
                    text: title,
                    alignment: AlignmentType.CENTER,
                    spacing: {
                        after: 200,
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new ImageRun({
                            data: imageData.data,
                            transformation: {
                                width: targetWidth,
                                height: targetHeight, // Set calculated height to maintain aspect ratio
                            },
                            type: "png"
                        } as any),
                    ],
                }),
            ],
        });
    }

    private getModelInformation(scene: THREE.Scene): any {
        const modelInfo = {
            components: 0,
            dimensions: new THREE.Box3(),
            materials: new Set<string>(),
            types: new Set<string>(),
            totalVertices: 0,
            totalFaces: 0
        };

        scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                modelInfo.components++;

                // Update bounding box
                object.geometry.computeBoundingBox();
                modelInfo.dimensions.expandByObject(object);

                // Count geometry details
                if (object.geometry instanceof THREE.BufferGeometry) {
                    const position = object.geometry.attributes.position;
                    if (position) {
                        modelInfo.totalVertices += position.count;
                        if (object.geometry.index) {
                            modelInfo.totalFaces += object.geometry.index.count / 3;
                        } else {
                            modelInfo.totalFaces += position.count / 3;
                        }
                    }
                }

                // Track materials
                if (object.material) {
                    if (Array.isArray(object.material)) {
                        object.material.forEach(mat => {
                            if (mat instanceof THREE.MeshStandardMaterial) {
                                modelInfo.materials.add(mat.color.getHexString());
                            }
                        });
                    } else if (object.material instanceof THREE.MeshStandardMaterial) {
                        modelInfo.materials.add(object.material.color.getHexString());
                    }
                }

                // Track component types
                if (object.userData?.typ) {
                    modelInfo.types.add(object.userData.typ);
                }
            }
        });

        return modelInfo;
    }

    async generateReport(): Promise<Document> {
        // Store initial camera state
        const initialCameraState = this.storeCameraState();

        const views = await this.captureViews();
        const modelInfo = this.getModelInformation(this.scene);

        // Restore camera to initial state
        this.restoreCameraState(initialCameraState);

        const doc = new Document({
            sections: [{
                properties: {},
                children: [
                    // Title
                    new Paragraph({
                        text: "3D Model Report",
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            after: 400,
                        },
                    }),

                    // Model Information Section
                    new Paragraph({
                        text: "Model Information",
                        heading: HeadingLevel.HEADING_2,
                        spacing: {
                            after: 200,
                        },
                    }),

                    // Model Statistics Table
                    new Table({
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                        },
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 1 },
                            bottom: { style: BorderStyle.SINGLE, size: 1 },
                            left: { style: BorderStyle.SINGLE, size: 1 },
                            right: { style: BorderStyle.SINGLE, size: 1 },
                        },
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Total Components")],
                                        width: { size: 30, type: WidthType.PERCENTAGE },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(modelInfo.components.toString())],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Dimensions (W × H × D)")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(
                                            `${(modelInfo.dimensions.max.x - modelInfo.dimensions.min.x).toFixed(2)} × ` +
                                            `${(modelInfo.dimensions.max.y - modelInfo.dimensions.min.y).toFixed(2)} × ` +
                                            `${(modelInfo.dimensions.max.z - modelInfo.dimensions.min.z).toFixed(2)} units`
                                        )],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Total Vertices")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(modelInfo.totalVertices.toString())],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Total Faces")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(modelInfo.totalFaces.toString())],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Component Types")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(Array.from(modelInfo.types).join(", "))],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Materials Used")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(Array.from(modelInfo.materials).map(color => `#${color}`).join(", "))],
                                    }),
                                ],
                            }),
                        ],
                    }),

                    // Model Views Section
                    new Paragraph({
                        text: "Model Views",
                        heading: HeadingLevel.HEADING_2,
                        spacing: {
                            after: 200,
                        },
                    }),

                    // Views Table
                    new Table({
                        alignment: AlignmentType.CENTER,
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                        },
                        margins: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                            right: 100,
                        },
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 1 },
                            bottom: { style: BorderStyle.SINGLE, size: 1 },
                            left: { style: BorderStyle.SINGLE, size: 1 },
                            right: { style: BorderStyle.SINGLE, size: 1 },
                        },
                        rows: [
                            // First Row
                            new TableRow({
                                children: [
                                    this.createImageCell(views[0], "Front View"),
                                    this.createImageCell(views[1], "Side View"),
                                ],
                            }),
                            // Second Row
                            new TableRow({
                                children: [
                                    this.createImageCell(views[2], "Top View"),
                                    this.createImageCell(views[3], "Isometric View"),
                                ],
                            }),
                        ],
                    }),

                    // Project Configuration Section
                    new Paragraph({
                        text: "Project Configuration",
                        heading: HeadingLevel.HEADING_2,
                        spacing: {
                            before: 400,
                            after: 200,
                        },
                    }),

                    // Configuration Details Table
                    new Table({
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                        },
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 1 },
                            bottom: { style: BorderStyle.SINGLE, size: 1 },
                            left: { style: BorderStyle.SINGLE, size: 1 },
                            right: { style: BorderStyle.SINGLE, size: 1 },
                        },
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Generated On")],
                                        width: { size: 30, type: WidthType.PERCENTAGE },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(new Date().toLocaleDateString())],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Sheet ID")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(this.scene.userData?.sheetid?.toString() || "N/A")],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Configuration")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(this.scene.userData?.configuration || "N/A")],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Source Sheet ID")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(this.scene.userData?.sourcesheetid?.toString() || "N/A")],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph("Component Hierarchy")],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(this.getComponentHierarchy())],
                                    }),
                                ],
                            }),
                        ],
                    }),

                    // Generation Metadata
                    new Paragraph({
                        text: "Generation Details",
                        heading: HeadingLevel.HEADING_3,
                        spacing: {
                            before: 200,
                            after: 100,
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "This report was automatically generated from the 3D model created using SheetBuild. ",
                                size: 20,
                            }),
                            new TextRun({
                                text: "The model was built using Excel spreadsheet data and transformed into a 3D representation.",
                                size: 20,
                            }),
                        ],
                    }),
                ],
            }],
        });

        return doc;
    }

    private getComponentHierarchy(): string {
        const hierarchy: string[] = [];

        const processObject = (object: THREE.Object3D, level: number = 0) => {
            if (object.userData?.typ) {
                const indent = '  '.repeat(level);
                const type = object.userData.typ;
                const name = object.name.split('#').pop() || object.name;
                hierarchy.push(`${indent}${name} (${type})`);
            }
            object.children.forEach(child => processObject(child, level + 1));
        };

        this.scene.children.forEach(child => processObject(child));
        return hierarchy.join('\n');
    }
} 