import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { HyperFormula } from 'hyperformula';
import { BaseScene } from '../basescene';
import { CADEngine } from '../cadengine';
import { CADNodeEngine } from '../cadnodeengine';
/* import { SnippetManager } from '../snippetmanager';
import { ThreeUtils } from '../threeutils';
 */
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { first } from 'rxjs/operators';

import nodes from './../../admin/projects/projectcategories';

@Component({
  templateUrl: './dialogsubproject.component.html',
  styleUrls: ['./dialogsubproject.component.scss']
})

export class DialogsubprojectComponent implements OnInit {
  scene: any; baseScene: BaseScene;
  camera: any;
  controls: any;
  cadengine: CADEngine;
  cad: CADNodeEngine;
  jsongui: any;
  renderer: any;
  objectloader: THREE.ObjectLoader;
  @Input() excels: any;
  @Input() hf: HyperFormula;
  @Input() sheetid: string;
  @Input() PROJECT: string;
  @Input() dataset: any;
  @Input() showAnimations: boolean;
  clock: any;
  @ViewChild("rendererContainer") rendererContainer: ElementRef;

  nodes = nodes.nodes;


  constructor(private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DialogsubprojectComponent>,
    public dialog: MatDialog, public http: HttpClient, private rtdb: AngularFireDatabase, public auth: AngularFireAuth, public afs: AngularFirestore, public router: Router, public snackBar: MatSnackBar, private appRef: ApplicationRef, private route: ActivatedRoute, private zone: NgZone, public ngrenderer: Renderer2) {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    this.baseScene = new BaseScene(this.scene, this.renderer);
    this.cadengine = new CADEngine(this.scene, this.excels, this.excels);
    this.scene.fog = new THREE.Fog(this.scene.background, 7000, 20000);
    //this.cadengine.linetest();
    this.cad = new CADNodeEngine();

    this.objectloader = new THREE.ObjectLoader();
    //   this.hf = HyperFormula.buildEmpty(this.hfoptions as any);




  }

  user: any; projects: any;
  async ngOnInit(): Promise<void> {
    //  ThreeUtils.clearScene(this.scene);

    //this.jsongui = await this.excels[this.CONFIGURATION].createJsonGUI(this.sheetid);
    //this.createModel(this.scene);#
    this.nodes = nodes.nodes;

    // projekte liste
    this.user = await this.auth.authState.pipe(first()).toPromise();

    this.afs.collection("projects/userprojects/" + this.user.uid, ref => ref.orderBy('date', 'desc')).valueChanges({ idField: 'id' }).subscribe(x => {
      if (x) {

        this.projects = x;
      }
    });
  }

  async ngAfterViewInit() {
    //  this.setupthreejs();
    //    this.leftpanelwidth = 0.4 * window.innerWidth;;

    //    this.onResize(null);

    // this.updateThreeFrame();


  }

  projectSelected(p) {

    this.dialogRef.close(p);
  }

  outputrow: number;
  async createModel(rootNode: any) {
    this.outputrow = this.getOutputRow();
    // for (var j = this.outputrow + 1; j < this.dataset.length; j++) {
    //   if (this.dataset[j][1] != null && this.dataset[j][1] != "") {
    //     var typ = this.dataset[j][1];
    //     var objectname = this.dataset[j][0];
    //     console.log('createCADNode ', objectname, typ);
    //     var newnode = await this.cad.createCADNode(objectname, this.dataset[j], this.dataset[j - 1], rootNode, this.excels, this.CONFIGURATION, this.toload, this.workers, this.customerid, this.projectid);
    //     newnode.name = objectname;
    //     rootNode.add(newnode);
    //   }
    // }
    //  this.updateThreeFrame();
  }

  getOutputRow(): number {
    var outputrow = 10000;
    for (var i = 0; i < this.dataset.length; i++)
      if (this.dataset[i][0] == "OUTPUTID") {
        outputrow = i;
      }
    return outputrow
  }

  updateThreeFrame() {
    if (!this.showAnimations) {
      this.controls.enableDamping = false;
      var delta = this.clock.getDelta();
      this.controls.update(delta);
      this.renderer.render(this.scene, this.camera);
    }
    else {
      this.controls.enableDamping = true;
    }
  }
  raycaster = null;
  mouse = new THREE.Vector2();
  INTERSECTED = null;
  mousemove(event: any) {

    this.updateThreeFrame();

    var container = document.getElementById("threecontainer");
    this.camera.aspect = container.clientWidth / container.clientHeight;

    this.mouse.x = (event.layerX / container.clientWidth) * 2 - 1;
    this.mouse.y = -(event.layerY / container.clientHeight) * 2 + 1;

    // var raycaster = new THREE.Raycaster();s
    // //var mouse = new THREE.Vector2();
    // raycaster.setFromCamera(this.mouse, this.camera);
    // var intersects = raycaster.intersectObjects(this.scene.children);
    // var intersection = false;
    // var j = 0;
    // for (var i = 0; i < intersects.length; i++) {

    //   if (intersects[i].object.type === "Mesh") {
    //     if (j == 0 && intersects[i].object.name != "") {
    //       this.selectedName = intersects[i].object.name;
    //       this.selectedobject = intersects[i].object as any;
    //       var obj = (intersects[i].object as any).o;
    //       if (obj) {
    //         intersection = true;
    //         document.body.style.cursor = "pointer;"

    //         return;
    //       }
    //     }
    //   }
    // }


  }

  setupthreejs() {
    var threecontainer = document.getElementById("threecontainerdlg1");
    this.camera = new THREE.PerspectiveCamera(75, threecontainer.clientWidth / threecontainer.clientHeight, 1, 20000);
    //    container.appendChild(this.renderer.domElement);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    console.log('threecontainer', threecontainer.clientWidth);
    this.renderer.setSize(threecontainer.clientWidth, threecontainer.clientHeight);
    this.renderer.shadowMap.enabled = true; // https://threejsfundamentals.org/threejs/lessons/threejs-shadows.html
    this.renderer.shadowMap.autoUpdate = true;
    this.renderer.shadowMap.needsUpdate = false;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.camera.position.z = 2500;
    this.camera.position.x = 1500;
    this.camera.position.y = 2000;


    // var container = new UIPanel();
    // container.setId('viewport');
    // container.setPosition('absolute');
    // container.add(new ViewportCamera(editor));
    // container.add(new ViewportInfo(editor));
    //

    //    this.viewHelper = new ViewHelper(this.camera, container);
    // this.stats = new Stats();
    //this.stats.showPanel(0);
    // stats.domElement.style.position = 'absolute';
    // stats.domElement.style.left = '0';
    // stats.domElement.style.top = '0';
    this.clock = new THREE.Clock();
    this.camera.up.set(0, 0, 1);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.enableRotate = true;
    this.controls.enablePan = true;
    this.controls.enableZoom = false;

    // var renderPass = new RenderPass(this.scene, this.camera);
    // this.composer = new EffectComposer(this.renderer);
    // this.composer.addPass(renderPass);
    // var selectedObjects = []
    // this.outlinePass = new OutlinePass(new THREE.Vector2(container.clientWidth, container.clientHeight), this.scene, this.camera, selectedObjects);
    // this.outlinePass.renderToScreen = true;
    // var params = { edgeStrength: 9, edgeGlow: 0, edgeThickness: 1, pulsePeriod: 0, usePatternTexture: false };
    // this.outlinePass.edgeStrength = params.edgeStrength;
    // this.outlinePass.edgeGlow = params.edgeGlow;
    // this.outlinePass.visibleEdgeColor.set(0x085cd4);
    // this.outlinePass.hiddenEdgeColor.set(0x085cd4);
    // this.composer.addPass(this.outlinePass);

    //this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    this.animate();
    //    this.leftpanelwidth = 40 * window.innerWidth / 10;;
    //  this.threecontainerwidth = window.innerWidth - 40 * window.innerWidth / 10;;

    // console.log('resize', this.threecontainerwidth, threecontainer.clientHeight)
    // this.camera.aspect = this.threecontainerwidth / threecontainer.clientHeight;
    // this.camera.updateProjectionMatrix();
    // this.renderer.setSize(this.threecontainerwidth, threecontainer.clientHeight);
    // this.updateThreeFrame();

    // nav axesHelpervar 
    // var container1 = new UIPanel(threecontainer);
    // container1.setId('vhcontainer');
    // container1.setPosition('absolute');
    // //      container1.add(new ViewportCamera(editor));
    // container1.add(this.camera);
    // //   var container2 = document.getElementById("vhcontainer");
    // var controls = new EditorControls(this.camera, container1);
    // // controls.addEventListener('change', function () {

    // //   // signals.cameraChanged.dispatch(camera);
    // //   // signals.refreshSidebarObject3D.dispatch(camera);

    // // });
    // var viewHelper = new ViewHelper(this.camera, threecontainer);
    // viewHelper.controls = this.controls;
    // console.log('viewheler', viewHelper)

  }

  animate() {
    if (this.showAnimations)
      window.requestAnimationFrame(() => this.animate());
    // this.mesh.rotation.x += 0.01;
    // this.mesh.rotation.y += 0.02;
    // this.mesh.position.x = 300;

    // this.raycaster = new THREE.Raycaster();

    // this.raycaster.setFromCamera(this.mouse, this.camera);
    // this.scene.traverse(function(node) {
    //   if (node instanceof THREE.Mesh) {
    //     node.material = new THREE.MeshNormalMaterial();
    //   }
    // });

    // // calculate objects intersecting the picking ray
    // var intersects = this.raycaster.intersectObjects(this.scene.children, true);
    // for (var i = 0; i < intersects.length; i++) {
    //   console.log("intersects" + i, intersects[i]);
    //   intersects[i].object.material = new THREE.MeshStandardMaterial(); //color.set(0x00ff00);
    //   break;
    // }
    var delta = this.clock.getDelta();

    this.controls.update(delta);


    // https://www.npmjs.com/package/three-outlinepass
    // if (this.selectedobject) {
    //   this.outlinePass.selectedObjects = [this.selectedobject];
    //   this.composer.render(this.scene, this.camera)
    // } else {
    // }
    this.renderer.render(this.scene, this.camera);


    // this.stats.update();
  }
}
