<style>
    /* Header styles to override base.css */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0.5rem;
        color: #333;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.75rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.25rem;
    }

    h6 {
        font-size: 1rem;
    }

    /* Use ::ng-deep to pierce Angular's view encapsulation */
    ::ng-deep h1 {
        font-size: 2.5rem !important;
        font-weight: 500 !important;
    }

    ::ng-deep h2 {
        font-size: 2rem !important;
        font-weight: 500 !important;
    }

    ::ng-deep h3 {
        font-size: 1.75rem !important;
        font-weight: 500 !important;
    }

    ::ng-deep h4 {
        font-size: 1.5rem !important;
        font-weight: 500 !important;
    }

    ::ng-deep h5 {
        font-size: 1.25rem !important;
        font-weight: 500 !important;
    }

    ::ng-deep h6 {
        font-size: 1rem !important;
        font-weight: 500 !important;
    }
</style>

<div style="max-height:400px;overflow:auto;" [innerHTML]="disclaimer"></div>