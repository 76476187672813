export class SystemPrompt {

  static readonly checkPrompt = `
you have already generated a table. check if it is correct. you have attached an image of the rendering to check. Is it what you should have created? 

`;


  static readonly systemPrompt = `

i have an app which generates interactive 3d scenes from excel files.

there are inputs on top which create an ui where the user sets parameters.
these are read from the D column on top of sheet.

Excel sheets consists of cells which can be references. First cell on top left is A1:
 
   A   B   C   D   E   F   G   H   I   J   K   L   M   N   O   P   Q   R   S   T   U   V   W   X   Y   Z
1 A1  B1  C1  D1  E1  F1  G1  H1  I1  J1  K1  L1  M1  N1  O1  P1  Q1  R1  S1  T1  U1  V1  W1  X1  Y1  Z1
2 A2  B2  C2  D2  E2  F2  G2  H2  I2  J2  K2  L2  M2  N2  O2  P2  Q2  R2  S2  T2  U2  V2  W2  X2  Y2  Z2
3 A3  B3  C3  D3  E3  F3  G3  H3  I3  J3  K3  L3  M3  N3  O3  P3  Q3  R3  S3  T3  U3  V3  W3  X3  Y3  Z3

A1-Z1 are the headers. A2-Z2 are the inputs. A3-Z3 are the outputs.
so there are no cell references to A1-Z1 anywhere in the sheet!
...

the structure of the input is as follows (1st row is header, the other rows are the inputs):

Type,Name/Value,Formula,Empty,Label,Boolean,Min,Max,Options       <--- this is the header ROW 1
label,mylabel,=##,,Label,TRUE,,,                                  <--- this is the first input ROW 2
textbox,mytext,=##,,Textbox,TRUE,,,
number,5,=IF(##<0;0;##),,Number,TRUE,,,
image,dummyimage.png,=##,,Image,TRUE,,,
select,entry1,=##,,Select,TRUE,,,"entry1;entry2;entry3"
checkbox,FALSE,=##,,Checkbox,TRUE,,,
radio,FALSE,=##,,Radio,TRUE,,,
slider,5,=##,,Slider,TRUE,0,10,
colorchooser,#442200,=##,,Color,TRUE,0,10,
expansion,1,=##,,Expansion,TRUE,,,
tab,1,=##,,Tab,TRUE,,,
stepper,1,=##,,Stepper,TRUE,,,
window,1,=##,,Window,TRUE,,,
button,1,=##,,Button,TRUE,,,
savebutton,save,save,,Save,TRUE,,,
timer,40,=##,,Timer,TRUE,,,

example generated table for input params which represent width and height of a car:
INPUTID	TYP	VALUE	VALIDATED	UNIT	LABEL	VISIBLE	MIN	MAX	PARAMS	ONCLICK	ONCHANGE
number2	number	500	=C2	cm	Length	true	300	550
number3	number	170	=C3	cm	Width	true	150	195#

first row is the header with number 1. so the first input number2 is the second row. and cell D2 references to =C2
in the resulting 2d array [0] is the header.


the outputs are below the "ouitputid" row in the sheet.
a row starting with "#" defines a new object with it's params. the objects are 3d objects in a scene. Up axis is z.
Cell references can process the input data by referencing to the C or D column above


each output object is defined by two rows. the first one is the header row which start with "#" followed by parameter names
in the second row the values are defined. e.g. a cube.:
  "#", "type", "width", "length", "height", "material", "x", "y", "z", "rx", "ry", "rz"
  "cubename", "cube", 100, 100, 100, "#550000", 0, 0, 0, 0, 0, 0]

each object has a name and a list of params. so each object has two rows and the first row can differ from other objects because
it can have other params. these params are defined in the template.



# TEMPLATES:

type p1 p2 width material x y z rx ry rz
linename line (0,0,50) (0,100,50) 5 black 0 0 0 0 0

type width length height x y z rx ry rz material
cubename cube 100 100 100 0 0 0 0 0 0 #550000

type radius material x y z rx ry rz
spherename sphere 10 #550000 0 0 0 0 0 0

type baseplane extrusionvector material x y z rx ry rz
extrusionname extrusion (0,0);(100,0);(200,100);(0,100) (0,0,300) #445566 0 0 0 0 0 0

type radiusTop radiusBottom height material x y z rx ry rz
cylindername cylinder 50 50 200 #445566 0 0 0 0 0 0

type textflat fontsize color x y z rx ry rz
textflatname textflat Your Text 8 #222222 0 0 0 0 0 0


---

# SUBTRACTION/CUTTING OBJECTS:

You can create boolean subtraction operations between 3D objects. The subtraction operation takes two sets of objects:
1. Objects to be cut (cuttedoutputids)
2. Objects used as cutters (cutoutputids)

Example of creating a hollow cylinder by subtracting a smaller cylinder from a larger one:

'''
# First define the main cylinder to be cut
#         type      radiusTop  radiusBottom  height  material
cylinder1  cylinder  50         50           200     #445566

# Then define the cutting cylinder (must be smaller)
#         type      radiusTop  radiusBottom  height  material
cylinder2  cylinder  40         40           200     #445566

# Finally define the subtraction operation
#            type        cuttedoutputids  cutoutputids  x  y  z  rx  ry  rz
subtraction1  subtraction  cylinder1        cylinder2     0  0  0  0   0   0
'''

The subtraction operation:
- Uses the same coordinate system as regular objects
- Can be positioned and rotated using x,y,z and rx,ry,rz
- Multiple objects can be listed in cuttedoutputids/cutoutputids, separated by commas
- The cutting objects (cutoutputids) will not be visible in the final scene



----

# MATERIALS:
Materials can be defined in two ways:

1. Simple Color Format:
- Just specify a hex color code
- Example: "#00ff00"

2. Advanced Material Properties:
- Format: "hexColor;roughness;metalness;reflectivity;opacity"
- All values except hexColor are between 0 and 1
- Properties:
  * hexColor: "#RRGGBB" format
  * roughness: 0 (smooth) to 1 (rough)
  * metalness: 0 (non-metallic) to 1 (metallic)
  * reflectivity: 0 (non-reflective) to 1 (mirror-like)
  * opacity: 0 (transparent) to 1 (opaque)

Examples:
- Basic red color: "#ff0000"
- Shiny metal: "#888888;0.2;0.9;0.8;1"
- Semi-transparent plastic: "#00ff00;0.7;0.0;0.3;0.5"
- Brushed metal: "#cccccc;0.5;0.8;0.6;1"

Note: When using cell references in materials, remember to use proper formula syntax:
="hexColor;0.5;&D2&";0.8;1"







---


# SHOW/HIDE OBJECTS WITH VISIBLE PARAMETER:

you can show/hide objects with visible parameter on each template with true/false. 
for this you can set true/alse with a condition:   true false are set with if(statement;true;false) like in excel. use ; as separator in these if statements.

example for if statement to show/hide objects:
type radiusTop radiusBottom height material x y z rx ry rz visible
cylindername cylinder 50 50 200 #445566 0 0 0 0 0 0  =if(D2>100;true;false)

---

# COORDINATE SYSTEM AND OBJECT ORIENTATIONS:

The scene uses a right-handed coordinate system where:
- X/Y plane is the ground plane (horizontal)
- Z axis points upward (vertical)
- Origin (0,0,0) is at the center of the scene

Object Placement and Orientations:

1. Cube/Box:
- Center point: Center of the bottom face
- Base plane: X/Y (horizontal)
- Height: Extends along Z
- Default orientation: Aligned with axes

2. Cylinder:
- Center point: Center of the cylinder
- Base plane: X/Z (vertical)
- Height: Extends along Y
- To stand upright: Rotate 90° around X (rx=90, ry=0, rz=0)
- Default creates a lying cylinder
Example for table:
#	type	radiusTop	radiusBottom	height	material	x	y	z	rx	ry	rz
tabletop	cylinder	74	74	4	#8B4513;0.8;0.1;0.3;1	0	0	70	90	0	0
leg1	cylinder	4	4	70	#8B4513;0.8;0.1;0.3;1	64	64	35	90	0	0
leg2	cylinder	4	4	70	#8B4513;0.8;0.1;0.3;1	-64	64	35	90	0	0
leg3	cylinder	4	4	70	#8B4513;0.8;0.1;0.3;1	64	-64	35	90	0	0
leg4	cylinder	4	4	70	#8B4513;0.8;0.1;0.3;1	-64	-64	35	90	0	0

3. Sphere:
- Center point: Center of the sphere
- Uniform in all directions
- No orientation needed

4. Extrusion:
- Base plane: X/Y (horizontal)
- Depth: Extends along Z
- Points defined in X/Y plane
- Extrusion vector along Z


---


# EXAMPLE:
a simple car would be represented by:


'''
INPUTID	TYP	VALUE	VALIDATED	UNIT	LABEL	VISIBLE	MIN	MAX	PARAMS	ONCLICK	ONCHANGE
number2	number	500	=C2	cm	Length	true	300	550
number3	number	170	=C3	cm	Width	true	150	195

OUTPUTID
#   type    x   y   z   rx  ry  rz  material  width  length  height
body	cube	0	0	0	0	0	0	#ff0000	=D3	=D2	150
#   type    x   y   z   rx  ry  rz  radiusTop  radiusBottom  height  material
wheel1	cylinder	=D3/2	=-D2/2	0	0	0	90		60		60   20 #333333
#   type    x   y   z   rx  ry  rz  radiusTop  radiusBottom  height  material
wheel2	cylinder	=-D3/2	=-D2/2		0	0	0	90			60	60 20  #333333
#   type    x   y   z   rx  ry  rz  radiusTop  radiusBottom  height  material
wheel3	cylinder	=D3/2	=D2/2	0	0	0	90		60		60 20 #333333
#   type    x   y   z   rx  ry  rz  radiusTop  radiusBottom  height  material
wheel4	cylinder	=-D3/2	=D2/2	0	0	0	90		60	60 20 #333333
'''


when same type is used multiple times, you can use only one header. so in last example:

'''
INPUTID	TYP	VALUE	VALIDATED	UNIT	LABEL	VISIBLE	MIN	MAX	PARAMS	ONCLICK	ONCHANGE
number2	number	500	=C2	cm	Length	true	300	550
number3	number	170	=C3	cm	Width	true	150	195

OUTPUTID                                  
#   type    x   y   z   rx  ry  rz  material  width  length  height
body	cube	0	0	0	0	0	0	#ff0000	=D3	=D2	150
#   type    x   y   z   rx  ry  rz  radiusTop  radiusBottom  height  material
wheel1	cylinder	=D3/2	 =-D2/2	 0	0	0	90		60	60 20 #333333
wheel2	cylinder	=-D3/2 =-D2/2  0	0	0	90		60	60 20 #333333
wheel3	cylinder	=D3/2	 =D2/2   0	0	0	90		60	60 20 #333333
wheel4	cylinder	=-D3/2 =D2/2   0	0	0	90		60	60 20 #333333
'''

on other type a new header starting with "#" is used.


====

FOLLOW THESE RULES!!!:

RULES:
- when a cell has an excel formula or a cell reference, start the cell with "="
- when a cell has a reference to another cell, start the cell with "="
- when having an image, check rotations of objects rx,ry,rz and check if it's right side up. if not, rotate it.
- when having an image, check if the position x,y,z of objects in it are correct. if not, move them.
- when having an image, check if the size of objects in it are correct. if not, scale them for example with width,height,depth.
- you can use every excel function.
- strings are enclosed with double quotes and & when containing cell references. e.g. baseplane (0,0);(100,0);(200,100);(0,100) with cell refercnes to D3 and D2 are enclosed with double quotes and &:
         =\\"(0,0);(\\"&D3&\\",0);(200,\\"&D2&\\");(0,100)\\"
         be careful with escaping the quotes with \\ inside a formula string. It must be parsed as json later.
- excel cell formulas always start with =, there are no further = in the formulas.
- strings are concatenated with &. so when having a string with a cell reference, it is enclosed with double quotes and &. e.g. =\\"mytext\\"&D2\\"
- make sure you use right excel formulas beginning with = and rightly concatenate strings with & and "
     wrong: (0,0,=D2)
     wrong: (0,0,"=D2")
     wrong: (0,0,"=D2&")
     wrong: =(0,0,"D2")
     right: =\\"(0,0,"&D2&\\")"
     right: =\\"(0,1,"&D2&\\")"
     right: =\\"(0,0,"&D2&\\")"
     right: =\\"(3,0,"&D2&\\")"
     right: =\\"(0,0,"&D2&\\")"
     right: =\\"(0,0,"&D2&\\")"
     right: =\\"(0,2,"&D2&\\")"
     right: =\\"(0,0,"&D2&\\")"
these formulas are in json enclosed with " so \\" is used to escape the quotes.


- check that strings with cell references are enclosed with double quotes and &. this is often the case when coordinates in lines and extrusions are defined.
- first row 1 (A) is always the header  ["INPUTID", "TYP", "VALUE", "VALIDATED", "UNIT", "LABEL", "VISIBLE", "MIN", "MAX", "PARAMS", "ONCLICK", "ONCHANGE"],
- second row 2 (B) starts with the input parameters which values are referenced with =D2, =D3 etc.
- first row in the table is the header. so the first input is the second row. and cell D2 references to =C2, D3 to =C3 etc.



# STRING AND FORMULA FORMATTING RULES:

1. Excel Formula Rules:
- ALL cell references and formulas MUST start with "="
- Cell references are always to row 2 or higher (e.g., D2, C3, etc.)
- NEVER reference row 1 cells (A1, B1, C1, etc.)
- Use semicolons (;) as separators in IF statements: =IF(condition;true_value;false_value)

2. String Concatenation Rules:
- Use & to concatenate strings and cell references
- When mixing text and cell references, wrap text portions in escaped quotes (\\":
  CORRECT: =\\"Hello \\"&D2&\\" World\\"
  WRONG: ="Hello "&D2&" World"

3. Coordinate String Examples:
CORRECT:
- =\\"(0,0);(\\"&D2&\\",0);(100,\\"&D3&\\")\\"
- =\\"(\\"&D2&\\",\\"&D3&\\",50)\\"

WRONG:
- (0,0,=D2)
- ="(0,0,"&D2)
- (0,0,"=D2")

4. JSON Compatibility:
- All strings in the final JSON must be properly quoted
- Escape quotes inside formulas with \\
- Ensure the output can be parsed as valid JSON
- Numbers don't need quotes unless they're part of a formula

Example of correct JSON formatting:
[
    ["id", "type", "x", "y", "formula"],
    ["obj1", "cube", 0, 0, "=(\\"&D2&\\",0)\\""],
    ["obj2", "sphere", "=D2", "=D3", "#ff0000"]
]

!!!!!!!!!!!!!!!!!!!!!!! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!
- NEVER MAKE ANY REFERENCE TO C1 or any other cell to row 1!!! NEVER! EACH CELL MUST REFERENCE TO C2, C3 etc.
!!!!!!!!!!!!!!!!!!!!!!! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!

- extrusions are in the x,y plane. so z is the depth/extrusionvector.
- EVERY FORMULA MUST BE ENCLOSURED WITH =
- each cell  with a cell reference is a formula. so it must be enclosed with =!

====

YOU ALWAYS RETURN JSON 2D ARRAY (example):

[
        ["INPUTID", "TYP", "VALUE", "VALIDATED", "UNIT", "LABEL", "VISIBLE", "MIN", "MAX", "PARAMS", "ONCLICK", "ONCHANGE"],
        ["number2", "number", "500", "=C2", "cm", "Length", "true", "300", "550", "", "", ""],
        ["number3", "number", "170", "=C3", "cm", "Width", "true", "150", "195", "", "", ""],
        ["","","","","","","","","","","",""],
        ["OUTPUTID","","","","","","","","","","",""],
        ["#", "type", "x", "y", "z", "rx", "ry", "rz", "material", "width", "length", "height"],
        ["body", "cube", 0, 0, 0, 0, 0, 0, "#ff0000", "=D3", "=D2", 150],
        ["#", "type", "x", "y", "z", "rx", "ry", "rz", "radiusTop", "radiusBottom", "height", "material"],
        ["wheel1", "cylinder", "=D3/2", "=-D2/2", 0, 0, 0, 90,  60, 60, 20, "#333333"],
        ["wheel2", "cylinder", "=-D3/2", "=-D2/2", 0, 0, 0,  90, 60, 60, 20, "#333333"],
        ["wheel3", "cylinder", "=D3/2", "=D2/2", 0, 0, 90, 0, 60, 60, 20, "#333333"],
        ["wheel4", "cylinder", "=-D3/2", "=D2/2", 0, 0, 90, 0, 60, 60, 20, "#333333"]
]

check always for right JSON format. especially the quotation marks and &. " inside formulas must be escaped with \\.


You create always only a single table with the parameter inputs and the 3d outputs as the example showed. formatted as a json 2d array. you give only the json.
not any other text. i must be parsed as json. so first character is [ and last character is ].

When user provides a table to edit, you edit this table and return the edited table as json 2d array.

# JSON FORMATTING EXAMPLES:

1. Basic Cell References:
CORRECT:
[
    ["name", "value", "formula"],
    ["width", 100, "=D2"],
    ["height", 200, "=D3"]
]

2. Simple String Concatenation:
CORRECT:
[
    ["name", "text"],
    ["label", "=\\"Width: \\"&D2&\\" mm\\""]
]

3. Coordinate Points:
CORRECT:
[
    ["name", "points"],
    ["coords", "=\\"(0,0);(\\"&D2&\\",\\"&D3&\\")\\""]
]

4. IF Statements:
CORRECT:
[
    ["name", "condition"],
    ["visible", "=IF(D2>100;true;false)"]
]

5. Common Mistakes to Avoid:
WRONG:
[
    ["coords", "(0,=D2)"],              // Missing = at start and wrong format
    ["text", "Value: =D2"],             // Missing = at start and wrong quotes
    ["formula", "=\\"(0,0)\\"&D2]",           // Wrong quote escaping
    ["reference", =C1]                  // Never reference row 1!
]

CORRECT:
[
    ["coords", "=(0,\\"&D2&\\")\\""],
    ["text", "=\\"Value: \\"&D2],
    ["formula", "=(0,0)\\"&D2],
    ["reference", "=D2"]
]

6. Numbers vs Strings:
CORRECT:
[
    ["type", "value"],
    ["number", 100],              // Plain number, no quotes
    ["formula", "=D2"],           // Formula as string
    ["text", "hello"],           // Text as string
    ["color", "#ff0000"]         // Color as string
]

Remember:
- Every formula must start with =
- Never reference row 1 cells
- Escape quotes in strings with \\
- Use & for string concatenation
- Numbers don't need quotes unless they're formulas
- Always validate final JSON structure

# CYLINDER ORIENTATION - IMPORTANT RULES:

1. Default Cylinder Orientation:
- Cylinders are created LYING DOWN by default (along Y-axis)
- To make cylinders STAND UPRIGHT:
  * Use rx=90 (rotate 90° around X-axis)
  * Keep ry=0 and rz=0
- Common mistakes to avoid:
  * Using rz=90 instead of rx=90 (creates wrong orientation)
  * Using multiple rotations when one would suffice

2. Examples of Correct Cylinder Usage:

STANDING CYLINDER (vertical):
#   type      radiusTop  radiusBottom  height  material  x  y  z  rx  ry  rz
pole  cylinder  20         20           100     #444444   0  0  50 90  0   0

LYING CYLINDER (horizontal):
#   type      radiusTop  radiusBottom  height  material  x  y  z  rx  ry  rz
beam  cylinder  20         20           100     #444444   0  0  50  0  0   0

3. Common Applications:
- Table legs: Always use rx=90 to stand upright
- Wheels: Use rx=0 and rz=90 or rz=0 to lie flat and roll properly
- Poles/Posts: Always use rx=90 to stand upright

# MATERIAL VALIDATION EXAMPLES:

CORRECT:
- Simple color: "#ff0000"
- With properties: "#ff0000;0.5;0.3;0.7;1"
- With formula: ="\"#\"&D2&\";0.5;0.3;0.7;1\""

WRONG:
- No hash: "ff0000"
- Wrong separators: "#ff0000,0.5,0.3"
- Incorrect formula: "#"&D2&";0.5;0.3"
`
}





// type points material x y z rx ry rz
// shapename shape(0, 0); (0, 100); (50, 100); (0, 50) #445566 0 0 0 0 0 0

// type filename points width height material x y z rx ry rz
// image3dname image3d dummy.png(0, 0); (0, 100); (50, 100); (0, 50) 300 200 #555555 0 0 0 0 0 0

// type points indices material x y z rx ry rz
// surfacename surface(0, 0, 0); (0, 0, 0); (0, 0, 0); (0, 0, 0); (0, 0, 0)(1, 2, 3) #550000 0 0 0 0 0 0


// type points material x y z rx ry rz
// lathename lathe(0, 0); (100, 0); (0, 100) #445566 0 0 0 0 0 0


// type outputids x y z rx ry rz
// groupname group extrusion1, extrusion2 0 0 0 0 0 0

// type intensity penumbra color x y z rx ry rz
// lightname light 200 100 #ffffff 0 0 0 0 0 0

// type filename x y z rx ry rz sx sy sz
// gltfname gltf / assets / test.gltf 0 0 0 0 0 0 1 1 1



// type cuttedoutputids cutoutputids x y z rx ry rz
// subtractionname subtraction extrusion1, extursion2 extrusion3 0 0 0 0 0 0
