import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomerrorhandlerService extends ErrorHandler {
  count: number = 0;
  userid: string;
  private toastrService: ToastrService | null = null;

  constructor(
    public afs: AngularFirestore,
    public auth: AngularFireAuth,
    private injector: Injector  // Inject Injector
  ) {
    super();
    this.auth.authState.subscribe(async x => {
      this.userid = x ? x.uid : null;
    });
  }

  get toastr(): ToastrService {
    if (!this.toastrService) {
      this.toastrService = this.injector.get(ToastrService);
    }
    return this.toastrService;
  }


  handleError(error) {
    this.count++;
    try {
      //      positionClass: 'toast-bottom-right',


      //The "render" method cannot be called because this Handsontable instance has been destroyed
      if (error.message.includes('NG0100: ExpressionChangedAfterItHasBeenCheckedError') || error.message.includes('ApplicationRef.tick')
        || error.message.includes('formula.split is not a function')) {
        return;

      }

      //      alert(`An error occurred: ${error.message}. Please try again.`);
      this.toastr.error(error.message, "Error", { positionClass: 'toast-bottom-full-width', timeOut: 12000, progressBar: true });

      if (this.count < 7) {
        var data = JSON.parse(JSON.stringify({ datetime: new Date().getTime(), userid: this.userid, error: error.message, errorstack: error.stack }));
        this.afs.collection("errors").add(data);
      }
      if (this.count >= 7) {
        console.warn('CRE', this.count, error);
        var data = JSON.parse(JSON.stringify({ datetime: new Date().getTime(), userid: this.userid, error: "#to many errors" }));
        this.afs.collection("errors").add(data);

      }
    } catch (e) {
      console.error('Error logging error', e);
    }

    super.handleError(error);
  }
}
