// firebase emulators:start --inspect-functions
import { AfterContentInit, ApplicationRef, APP_ID, Component, ElementRef, HostListener, Inject, NgZone, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ChangeDetectorRef } from "@angular/core";
import { HotTableRegisterer } from "@handsontable/angular";
import Handsontable from 'handsontable';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import traverse from 'traverse';
import { MatMenuTrigger } from '@angular/material/menu';

import { HyperFormula, SimpleCellAddress, SimpleCellRange } from 'hyperformula';
import { BaseScene } from './basescene';
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { TransformControls } from './transformcontrols.js';
import { EditorControls } from './EditorControls.js';
//import { ViewHelper } from './Viewport.ViewHelper.js';
//import { ViewHelper } from './viewhelper';

// import { RenderPass, EffectComposer, OutlinePass } from "three-outlinepass"

import { UIPanel } from './ui.js';
import { CADEngine } from './cadengine';
import { SplitComponent, SplitAreaDirective } from 'angular-split'
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Comlink from "comlink";
import { Router, ActivatedRoute } from '@angular/router';
import { SnippetManager } from './snippetmanager';
import { CADNodeEngine } from './cadnodeengine';
import { CellRange } from 'hyperformula/typings/Cell';
import { CompileShallowModuleMetadata, ThisReceiver, typeSourceSpan } from '@angular/compiler';
import { Utils } from './utils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { debounceTime, filter, switchMap, take, timeoutWith } from 'rxjs/operators';
import { ThreeUtils } from './threeutils';
import { FilesDialog } from '../files/files.component';
import { MaterialbComponent } from '../materialb/materialb.component';
import { DialogsubprojectComponent } from './dialogsubproject/dialogsubproject.component';
import { MonacoEditorComponent, MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import { utils } from 'protractor';
import VBAAPI from './vbapi';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from "firebase";
import { InputdialogComponent } from '../inputdialog/inputdialog.component';
import { Config, DiffPatcher } from 'jsondiffpatch';
import { DialogsaveconfigComponent } from './dialogsaveconfig/dialogsaveconfig.component';
import { DialogdisclaimerComponent } from './dialogdisclaimer/dialogdisclaimer.component';
import { DialogmenuComponent } from './dialogmenu/dialogmenu.component';
import { TransitionCheckState } from '@angular/material/checkbox';
import { ColorEvent } from 'ngx-color';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogdimensionsComponent } from './dialogdimensions/dialogdimensions.component';
import { DialogshapeComponent } from './dialogshape/dialogshape.component';
import { Taskmanager } from './taskmanager';


import { HotTableModule } from '@handsontable/angular';
import { CustomEditor } from '../customeditor';
import { PublishDialog } from './dialogpublish';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { AngJsoneditorComponent, JsonEditorOptions } from 'ang-json-editor-13';
import { DialogfunctionsComponent } from './dialogfunctions/dialogfunctions.component';

//import { ViewportGizmo } from "three-viewport-gizmo";
import { Options } from '@angular-slider/ngx-slider';
import { ViewportGizmo } from '../lib/ViewportGizmo';
import { ToastrService } from 'ngx-toastr';


// https://hofk.de/main/discourse.threejs/2020/FatLineEdges/FatLineEdges.html
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Wireframe } from "three/examples/jsm/lines/Wireframe.js";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import { TaskService } from './task.service';


// import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
// import { Wireframe } from 'three/addons/lines/Wireframe.js';
// import { WireframeGeometry2 } from 'three/addons/lines/WireframeGeometry2.js';


@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {


  hotid2 = 'hotInstance2';
  datasetsel = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];

  @ViewChild(AngJsoneditorComponent, { static: false }) jsoneditor: AngJsoneditorComponent;

  pickinfos = false;
  snippetManager: SnippetManager;
  autocompleteListControls = ['label', 'textbox', 'number', 'colorchooser', 'image', 'button', 'savebutton', 'select', 'slider', 'expansion', 'checkbox', 'radio', 'tab', 'stepper', 'window', 'timer'];// TODO: externe tabellen
  autocompleteListPrimitives = ['', 'line', 'shape', 'surface', 'extrusion', 'sphere', 'cylinder', 'cube', 'lathe', 'image3d', 'subtraction', 'textflat', 'gltf', 'group', 'light', 'gltfnode', 'text2d', 'overlay', 'rectangle2d', 'image2d', 'chart'];
  overlayTypes = ['overlay', 'image', 'rectangle2d', 'text2d', 'circle', 'ellipse', 'polygon', 'polyline', 'path', 'chart'];
  cellinfo: string; // = "dsfsd";
  cellinfoleft = "0"; cellinfotop = "0";
  celltooltipleft = "0"; celltooltiptop = "0";
  cellactionvisible = false;
  cellactionleft = "0"; cellactiontop = "0";
  cellinfomenuvisible = false;
  cellactionmenuvisible = false;
  showprojectinfo = false;
  templatesItems: any;
  info: string;
  dataset: any;
  disableEditorDrag = false;
  disableguidrag = false;
  hotsettings: any;
  slidingpanel = false;
  hfoptions = {
    licenseKey: 'gpl-v3',
    language: 'enGB',
    useColumnIndex: true,
    hiddenRows: true,

    //  evaluateNullToZero: true
    //  chooseAddressMappingPolicy: 'AlwaysSparse'
  };
  showComments = 1;
  cadengine: CADEngine;
  navigationType = "free";// center
  cad: CADNodeEngine;

  hf: HyperFormula;
  showTableMenu = false;
  menuleft = '0px';


  taskManager: Taskmanager;
  private hotRegisterer = new HotTableRegisterer();
  public hot: Handsontable;
  hotid = 'hotInstance';
  @ViewChild("rendererContainer") rendererContainer: ElementRef;
  @ViewChild("hot") hotContainer: ElementRef;

  valueSubject = new BehaviorSubject<any>(0);
  renderer;
  showSceneSettings = false;
  appguiVisible = true;
  backgroundSceneColor1: any;
  baseScene: BaseScene;
  scene = null;
  camera: any; // THREE.Camera;
  controls: any;
  viewportGizmo: ViewportGizmo;
  stats: any;
  clock: THREE.Clock;
  isMobile = false;
  showMenu = true;
  showSheetsmenu = false;
  scenemode = "select";
  mnuSelect = 2;
  tutorial: AngularFireObject<any>;
  tutorials: Observable<any[]>;
  showaccountmenu = false;
  SERVER = "https://us-central1-xbuild3d.cloudfunctions.net";  //http://localhost:5001/xbuild3d/us-central1";
  PROJECT: string;
  jsongui: any; jsongui2: any;
  files: any;
  loadSheet = true;
  selectedProjectName = "Main";

  excels: any;
  workers: Worker[];
  sheetid = 0;
  customerid: string;
  projectid: string;
  datasetids = []; datsetrows = [];
  cellformulas: any;
  fixedColumnsLeft = 0; fixedRowsTop = 0;
  project: any;
  showfiles = false;
  showGUIEditor = false; showScriptsEditor = false;
  curSelectionReadable: string;
  objectloader: THREE.ObjectLoader;

  changesQueue = [];

  tdstyles = [];

  selectedEnvMap: string;
  envMaps = [{ value: 'neutral' }, { value: 'technical' }, { value: 'sunset' }, { value: 'city' }];

  //monaco editor
  scripteditorOptions = { theme: 'vs-dark', language: 'javascript' };
  scriptcode: string = 'function xtest() {\nconsole.log("Hello world!"); console.log(globalThis["hftest"]); \n}';
  csseditorOptions = { theme: 'vs-dark', language: 'css' };
  csscode: string = '.class {\nbackground-color: #fff;\n}   .test{font-size:12px;} .test2{font-size:12px; color:red;}';
  monaceditor: MonacoEditorComponent;
  processing = true;
  vba: VBAAPI;

  //
  @HostListener('document:click', ['$event']) onDocumentClick(event) {

    this.scm = false;
    this.showSceneSettings = false;
    this.showSheetsmenu = false;
    this.showTableMenu = false;
    this.showOverlaymenu = false;
    this.showOverlaymenu1 = false;
    this.showothermenu = false;
    this.mnucolorvisible = false;
    this.mnucolorvisible2 = false;
    this.showaccountmenu = false;

    this.ss = false;
    this.scm = false;
    // this.cellactionvisible = false;
    // this.cellinfovisible = false;

  }

  isShiftDown = false;
  isControlDown = false;
  showObjectInfos = false;
  @HostListener('document:keydown', ['$event']) onDocumentd(event) {
    if (event.key == "Shift") {
      this.isShiftDown = true;
    }
    if (event.key == "Control") {
      this.isControlDown = true;
    }


  }


  beforekeydown = (e) => {
    // if (this.editcell) {
    //   var txtarea = document.getElementsByClassName('handsontableInput')[0] as any;

    //   this.setCaretToPos(txtarea, 2);
    // }
    try {
      if (!e.key) {
        //  e.stopImmediatePropagation();
        // e.preventDefault();
        return;
      }

      //    console.log("beforekeydown", e);
      if (e.key == "Alt") {
        if (this.curSelectedRow < this.getOutputRow() && this.curSelectedCol < 2) {
          this.contextmenuitem1 = 1;
          this.cellactionmenuvisible = true;
        }
        if (this.curSelectedRow > this.getOutputRow() && this.curSelectedCol < 2) {
          this.contextmenuitem = 1;

          this.cellactionmenuvisible = true;
        }
        if (this.cellinfovisible)
          this.cellinfomenuvisible = !this.cellinfomenuvisible;
        if (this.celltoolsvisible) {

          this.showcelltools();
        }
      }

      if (e.key == "Escape") {
        this.cellactionmenuvisible = false;
        this.cellinfomenuvisible = false;
        this.showOverlaymenu = false;
        this.showOverlaymenu1 = false;
        this.showothermenu = false;

      }

      // menu1
      if (this.cellactionmenuvisible && this.curSelectedRow < this.getOutputRow()) {
        if (e.key == "ArrowDown") {
          this.contextmenuitem1++;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowUp") {

          this.contextmenuitem1--;
          e.stopImmediatePropagation();
        }


        if (e.key == "ArrowRight" && this.contextmenuitem1 == 9) {
          this.contextmenuitem1 = 21;
          this.showOverlaymenu1 = true;
          e.stopImmediatePropagation();

          return;
        }
        if (e.key == "ArrowLeft" && this.contextmenuitem1 > 20) {
          this.contextmenuitem1 = 9;
          this.showOverlaymenu1 = false;
          e.stopImmediatePropagation();

          return;

        }

        if (e.key == "Enter") {
          var t = ['', 'label', 'textbox', 'number', 'checkbox', 'radio', 'PNG', 'select', 'slider', '', '',
            '', '', '', '', '', '', '', '', '', '',
            'button', 'expansion', 'colorchooser', 'savebutton'];
          if (this.contextmenuitem1 == 6)
            this.showfilesDialog('PNG');
          else if (this.contextmenuitem1 == 9)
            this.showOverlaymenu1 = true;
          else
            this.addGui(t[this.contextmenuitem1]);

          if (this.contextmenuitem1 != 9)
            this.cellactionmenuvisible = false;

          this.refs = null;
          this.applyCellMeta();

          e.stopImmediatePropagation();
        }

      }

      // menu2
      if (this.cellactionmenuvisible && this.curSelectedRow > this.getOutputRow()) {
        if (e.key == "ArrowDown") {
          this.contextmenuitem++;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowUp") {

          this.contextmenuitem--;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowRight" && this.contextmenuitem == 9) {
          this.contextmenuitem = 40;
          this.showothermenu = true;
          e.stopImmediatePropagation();

          return;
        }
        if (e.key == "ArrowRight" && this.contextmenuitem == 10) {  // showSheetsmenu showOverlaymenu
          this.contextmenuitem = 21;
          this.showSheetsmenu = true;
          e.stopImmediatePropagation();
          return;
        }
        if (e.key == "ArrowRight" && this.contextmenuitem == 11) {  // showSheetsmenu showOverlaymenu
          this.contextmenuitem = 30;
          this.showOverlaymenu = true;
          e.stopImmediatePropagation();
          return;
        }

        if (e.key == "ArrowLeft" && this.contextmenuitem > 19 && this.contextmenuitem < 30) {
          this.contextmenuitem = 10;
          this.showSheetsmenu = false;
          e.stopImmediatePropagation();
          return;
        }
        if (e.key == "ArrowLeft" && this.contextmenuitem > 29) {
          this.contextmenuitem = 11;
          this.showOverlaymenu = false;
          e.stopImmediatePropagation();
          return;
        }

        if (e.key == "Enter") {
          var t = ['', 'extrusion', '', 'line', 'shape', 'cube', 'cylinder', 'textflat', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            'overlay', 'text2d', ' rectangle2d', 'image2d', 'chart', "", "", "", "", "",
            'subtraction', 'sphere', 'surface', "light"];
          if (this.contextmenuitem == 2)
            this.showfilesDialog('gltf');
          else if (this.contextmenuitem == 9)
            this.showSheetsmenu = true;
          else if (this.contextmenuitem == 10)
            this.showOverlaymenu = true;
          else if (this.contextmenuitem == 11)
            this.addProject();
          else if (this.contextmenuitem > 11 && this.contextmenuitem < 30)
            this.addPrimitive('sheet: ' + this.project?.sheets[this.contextmenuitem].name);
          else
            this.addPrimitive(t[this.contextmenuitem]);

          if (this.contextmenuitem != 10)
            this.cellactionmenuvisible = false;
          e.stopImmediatePropagation();
        }

      }

    } catch (e) {
      console.warn("beforekeydown", e);
    }
  }

  @HostListener('document:keyup', ['$event']) onDocumentup(event) {
    if (event.key == "Shift") this.isShiftDown = false;
    if (event.key == "Control") this.isControlDown = false;
  }

  // darkmode
  switchMode() {
    // if (document.body.classList.contains('darkmode')) {
    //   document.body.classList.remove("darkmode");
    // } else {
    //   document.body.classList.add("darkmode");
    //    }
  }

  setSceneSettings() {
    this.project.animate;
    this.project.disclaimer
    this.project.camerapan
    this.project.camerazoom
    this.project.cameramidpoint
    this.project.subprojectselectable
    this.project.subprojectmouseover
    this.project.subprojectmouseovercolor
    this.project.usercansave
    this.project.userformmail
    this.project.initialcamera
    this.project.usermailsubject
    this.project.sceneSettings;
  }

  scripteditorInit($event: any) {
    console.log("scripteditorInit", $event);

  }
  csseditorInit($event) {
    console.log("csseditorInit", $event);
    $event.trigger("editor", "editor.action.formatDocument");
  }

  csschange($e, issubconfig = false) {
    if (!issubconfig)
      this.project.csscode = $e;
    console.log("csschange", this.project.csscode);
    // TODO: auch wechseln, bei configwechsel
    var htmlid = "customcss_" + this.projectid;
    if (document.getElementById(htmlid))
      document.getElementById(htmlid).remove();

    // Build your `<link>` dynamically
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = htmlid;
    link.href = 'data:text/css;charset=UTF-8,' + encodeURIComponent($e); // TODO: css von project
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  updateScene($event) {
    console.log("updateScene", this.project.sceneSettings.directionalIntensity, $event);
    this.baseScene.updateScene(this.project.sceneSettings, this.camera);

    this.updateThreeFrame();
  }

  ss = false;
  scm = false;
  async test() {


    console.log("test", this.scm);

    var cellrange = {
      start: { row: 0, col: 0, sheet: this.sheetid },
      end: { row: 0, col: 0, sheet: this.sheetid }
    };
    var t = await this.excels[this.PROJECT].cut(cellrange);
    var p = await this.excels[this.PROJECT].paste({ sheet: 0, row: 0, col: 4 });
    console.log("paste", p);
    this.scm = !this.scm;
  }
  timersEnabled = true;
  timers = []; intervals = [];
  async timerfunction(t, clock) {
    var interval = setInterval(async () => {
      console.log("timerfunction", t.name, clock);
      if (this.project.sceneSettings.showAnimations) {
        var delta = clock.getDelta();
        var elapsed = clock.getElapsedTime();
        //  for (var i = 0; i < this.timers.length; i++) {
        var isenabled = await this.excels[this.PROJECT].getCellSerialized({ sheet: 0, row: t.row, col: 6 });
        isenabled = isenabled == "true" ? true : false;
        if (isenabled)
          var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: t.row, col: 4 }, elapsed);
        //  }
        await globalThis.API.updateUI(); // warum auch immer das geht aber applyCellMeta nicht?!

        // var eventinfo = {
        //   point: intersects[0].point,
        //   distance: intersects[0].distance,
        // }

        var jsfunction2 = t.changefunction;

        if (jsfunction2) {
          //        var es = JSON.stringify(eventinfo);
          //       if (!es) es = "null";
          //      jsfgffunction2 = jsfunction2.replace("event", es);
          //       var ud = this.sceneobjectMouseOver;
          //       if (ud) ud = JSON.stringify(ud);
          //       else ud = "null";
          //       jsfunction2 = jsfunction2.replace("object", ud);
          eval(this.project.scriptcode + "  " + jsfunction2 + ";");
        }      //      console.log("timer", elapsed, delta);

        //      this.applyCellMeta();
      }
    }, t.interval);
  }


  clocks = [];
  async createTimers() {
    this.timers = await this.excels[this.PROJECT].getTimers();
    console.log("createTimers", this.timers);
    //
    // var timer = {
    //   name: name, row: j, sheet: i, interval: interval, changefunction: changefunction
    // };

    // neu: timer mit expression
    //await this.excels[this.PROJECT].changeNamedExpression("time100", 100);

    this.intervals = [];
    for (var i = 0; i < this.timers.length; i++) {
      var clock = new THREE.Clock();
      this.clocks.push(clock);
      var t = this.timers[i];


      this.timerfunction(t, this.clocks[i]);
      // var interval = setInterval(async () => {
      //   if (this.project.sceneSettings.showAnimations) {
      //     console.log("timer", t.name);
      //     var delta = clock.getDelta();
      //     var elapsed = clock.getElapsedTime();
      //     //   for (var i = 0; i < this.timers.length; i++) {
      //     var isenabled = await this.excels[this.PROJECT].getCellSerialized({ sheet: 0, row: t.row, col: 6 });
      //     isenabled = isenabled == "true" ? true : false;
      //     if (isenabled)
      //       var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: t.row, col: 4 }, elapsed);
      //     //  }
      //     await globalThis.API.updateUI(); // warum auch immer das geht aber applyCellMeta nicht?!

      //     var jsfunction2 = t.changefunction;

      //     if (jsfunction2) {
      //       //        var es = JSON.stringify(eventinfo);
      //       //       if (!es) es = "null";
      //       //      jsfunction2 = jsfunction2.replace("event", es);
      //       //       var ud = this.sceneobjectMouseOver;
      //       //       if (ud) ud = JSON.stringify(ud);
      //       //       else ud = "null";
      //       //       jsfunction2 = jsfunction2.replace("object", ud);
      //       eval(this.project.scriptcode + "  " + jsfunction2 + ";");
      //     }      //      console.log("timer", elapsed, delta);

      //     //      this.applyCellMeta();
      //   }
      // }, t.interval);
      // this.intervals.push(interval);
    }


  }


  diffpatchGUI(jsongui: any, jsongui2: any): any {
    var conf: Config;
    conf = {
      // used to match objects when diffing arrays, by default only === operator is used
      //  objectHash: function (obj) {
      // this function is used only to when objects are not equal by ref
      //   return obj._id || obj.id;
      // },
      arrays: {
        // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
        detectMove: true,
        // default false, the value of items moved is not included in deltas
        includeValueOnMove: false
      },
      textDiff: {
        // default 60, minimum string length (left and right sides) to use text diff algorythm: google-diff-match-patch
        minLength: 60
      },
      propertyFilter: function (name, context) {
        /*
         this optional function can be specified to ignore object properties (eg. volatile data)
          name: property name, present in either context.left or context.right objects
          context: the diff context (has context.left and context.right objects)
        */
        return name.slice(0, 1) !== '$';
      },
      cloneDiffValues: false /* default false. if true, values in the obtained delta will be cloned
      (using jsondiffpatch.clone by default), to ensure delta keeps no references to left or right objects. this becomes useful if you're diffing and patching the same objects multiple times without serializing deltas.
      instead of true, a function can be specified here to provide a custom clone(value)
      */
    };

    // editiert werte übernehmen
    //    if (!this.defaultchange)
    // var test2 = await traverse(this.menu).forEach((value) => {
    //   if (typeof (value) === "object" && value) {
    //     if (value.edited) {
    //       Utils.setValue(menu, value.jsonpath + ".edited", true);
    //     }
    //     if (value.isparam) {
    //       Utils.setValue(menu, value.jsonpath + ".isparam", true);
    //     }
    //   }
    // });

    var jsondiffpatch = new DiffPatcher(conf);
    var delta = jsondiffpatch.diff(this.jsongui, jsongui);
    //  jsondiffpatch.patch(this.jsongui, jsongui);
    //    this.jsongui = jsondiffpatch.patch(this.jsongui, delta);
    this.jsongui = jsondiffpatch.patch(this.jsongui, delta);
    //  this.jsongui2 = jsongui;
  }

  transformControl: any;

  public jsonEditorOptions: JsonEditorOptions;
  constructor(private cdr: ChangeDetectorRef, private toastr: ToastrService, private monacoLoaderService: MonacoEditorLoaderService, public sanitized: DomSanitizer,
    public storage: AngularFireStorage, public dialog: MatDialog, public http: HttpClient, private rtdb: AngularFireDatabase, public auth: AngularFireAuth, public afs: AngularFirestore, public router: Router, public snackBar: MatSnackBar, private appRef: ApplicationRef, private route: ActivatedRoute, private zone: NgZone, public ngrenderer: Renderer2) {
    this.scene = new THREE.Scene();
    this.scene.name = "root";
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, transparent: true } as any);

    this.baseScene = new BaseScene(this.scene, this.renderer);
    this.cadengine = new CADEngine(this.scene, this.excels, this.excels);
    this.scene.fog = new THREE.Fog(this.scene.background, 7000, 20000);

    this.cad = new CADNodeEngine();
    this.snippetManager = new SnippetManager();
    this.objectloader = new THREE.ObjectLoader();

    this.hf = HyperFormula.buildEmpty(this.hfoptions as any);

    this.hotsettings = {
      // TODO  editor: CustomEditor,
      licenseKey: 'non-commercial-and-evaluation',
      currentRowClassName: 'currentRow',

    };


    Handsontable.renderers.registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
      Handsontable.renderers.getRenderer('text')(hotInstance, TD, ...rest);
      if (rest?.length > 1) {
        if (this.project?.settings?.stylesoverride) {
          var r = rest[0];
          var c = rest[1];
          try {
            var meta = this.hot?.getCellMeta(r, c);
          } catch (error) {
          }
          // TODO: direkt aus this.tdstyles holen

          if (meta?.stylemeta?.background) {
            TD.style.background = meta.stylemeta.background;
          }
          if (meta?.stylemeta?.color) {
            TD.style.color = meta.stylemeta.color;
          }
        }

      }
    });
    //https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-element-in-angular
    this.ngrenderer.listen('window', 'click', (e: Event) => {
      //   console.log("window click", e);
      this.showaccountmenu = false;
      for (var i = 0; i < this.project?.sheets?.length; i++) {
        this.project.sheets[i].visible = false;
      }
    });
    this.jsonEditorOptions = new JsonEditorOptions()

    this.jsonEditorOptions.modes = ['view']; // set all allowed modes
    this.jsonEditorOptions.mode = 'view'; //set only one mode
    this.jsonEditorOptions.expandAll = true;

    //    this.jsonEditorOptions.statusBar = false;
    //  this.jsonEditorOptions.mainMenuBar = false;
    //    this.jsonEditorOptions.navigationBar = false;
    this.jsonEditorOptions.theme = 1;
  }

  private getCellStyles(sheetid) {
    this.afs.collection("/projects/userprojects/" + this.customerid + "/" + this.projectid + "/tdstyles/", ref => ref.where("sheetid", "==", sheetid).orderBy("datetime")).valueChanges({ idField: "id" }).subscribe(x => {
      this.tdstyles = x;
      for (var y = 0; y < this.tdstyles.length; y++) {
        // this.tdstyles[i].style = JSON.parse(this.tdstyles[i].style);
        var s = JSON.parse(this.tdstyles[y].s);
        var e = this.tdstyles[y].e;

        for (var i = s[0][0]; i <= s[0][2]; i++) {
          for (var j = s[0][1]; j <= s[0][3]; j++) {
            var cell = this.hot.getCell(i, j);


            try {
              var meta = this.hot.getCellMeta(i, j).stylemeta;

            } catch (error) {
              meta = {};
            }
            if (!meta) {
              meta = {};
            }

            if (e.background) {
              //        cell.style.background = e.background;
              meta.background = e.background;
            }
            if (e.foreground) {
              //      cell.style.color = e.foreground;
              meta.color = e.foreground;
            }
            try {

              this.hot.setCellMeta(i, j, 'stylemeta', meta);
            } catch (error) {
              console.warn(error);
            }

          }
        }

      }
      this.hot.render();
    });
  }


  color1: any; color2: any;
  setCellBackground(e) {
    try {
      this.project.settings.stylesoverride = true;
      var s = this.hot.getSelected() as any
      s = Utils.rangeAbsoulte(s);

      var json = { background: e.color.hex };
      this.afs.collection("/projects/userprojects/" + this.customerid + "/" + this.projectid + "/tdstyles/").add(
        { datetime: new Date().getTime(), sheetid: this.sheetid, s: JSON.stringify(s), e: json }
      )
      for (var i = s[0][0]; i <= s[0][2]; i++) {
        for (var j = s[0][1]; j <= s[0][3]; j++) {
          var cell = this.hot.getCell(i, j);
          cell.style.background = e.color.hex;
          try {
            var meta = this.hot.getCellMeta(i, j).stylemeta;
          } catch (error) {
            meta = {};
          }
          if (!meta) {
            meta = {};
          }
          meta.background = e.color.hex;
          try {
            this.hot.setCellMeta(i, j, 'stylemeta', meta);
          } catch (error) {
            console.warn(error);
          }

        }
      }
    } catch (err) {
      console.warn(err);
    }
  }
  //  this.hot.render();
  setCellForeground(e) {
    try {
      this.project.settings.stylesoverride = true;
      var s = this.hot.getSelected() as any;
      var json = { foreground: e.color.hex };

      s = Utils.rangeAbsoulte(s);
      this.afs.collection("/projects/userprojects/" + this.customerid + "/" + this.projectid + "/tdstyles/").add(
        { datetime: new Date().getTime(), sheetid: this.sheetid, s: JSON.stringify(s), e: json }
      )
      for (var i = s[0][0]; i <= s[0][2]; i++) {
        for (var j = s[0][1]; j <= s[0][3]; j++) {
          var cell = this.hot.getCell(i, j);
          cell.style.color = e.color.hex;
          var meta = this.hot.getCellMeta(i, j).stylemeta;
          if (!meta) {
            meta = {};
          }

          meta.color = e.color.hex;
          try {
            this.hot.setCellMeta(i, j, 'stylemeta', meta);
          } catch (error) {
            console.warn(error);
          }
        }
      }
    } catch (err) {
      console.warn(err);
    }
    //  this.hot.render();
  }

  async applyCellMeta() {
    if (!this.isEditMode) return;
    if (!this.dataset) return;
    this.cdr.detectChanges();

    var outputrow = this.getOutputRow();
    var rowcount = this.dataset.length;
    try {
      for (var i = 0; i < rowcount; i++) {
        var colcount = this.dataset[i].length;
        for (var j = 0; j < colcount; j++) {
          try {
            this.hot?.setCellMeta(i, j, 'className', "");
          } catch (error) {
            console.warn(error);
          }
        }

        try {
          this.hot?.setCellMeta(i, 0, 'className', "typ_cell");
          this.hot?.setCellMeta(i, 1, 'className', "typ_cell");
        } catch (error) {
          console.warn(error);
        }
        // spalten
        //      this.hot.setCellMeta(i, 1, 'className', "");

        // inputids einfärben
        if ((this.dataset[i][0] != '' && this.dataset[i][0] != null) && i < outputrow) {
          for (var j = 0; j < colcount; j++) {
            try {
              this.hot.setCellMeta(i, j, 'className', "parameters_cell");
            } catch (error) {
              console.warn(error);
            }
          }
        }
        // outputids einfärben
        if ((this.dataset[i][0] != '' && this.dataset[i][0] != null) && i > outputrow) {
          for (var j = 0; j < colcount; j++) {
            try {
              this.hot.setCellMeta(i, j, 'className', "parameters_cell");
            } catch (error) {
              console.warn(error);
            }
          }
        }

        if (this.dataset[i][0] == "OUTPUTID" || this.dataset[i][0] == "INPUTID") {
          for (var j = 0; j < colcount; j++) {
            try {
              this.hot.setCellMeta(i, j, 'className', "head1_cell");
            } catch (error) {
              console.warn(error);
            }
          }
        }
      }

    } catch (err) {
      console.warn(err);
    }


    try {

      // // references in editmode
      var el = document.getElementsByClassName('handsontableInputHolder')[0] as any;
      if (el?.style) {
        var count = 0; var added = [];
        for (var i = 0; i < this.refs?.length; i++) {
          var r = (await this.excels[this.PROJECT].simpleCellAddressFromString(this.refs[i], this.sheetid)).row;
          if (r < this.curSelectedRow) {
            if (!Utils.isinlist(added, r)) {
              added.push(r);
              count++;
            }
          }
        }
        //      console.log("count", count);
        var top = this.editcelltop + count * 3;
        el.style.top = top + "px";
        //     console.log("top", el.style.top);
      }
      for (var i = 0; i < this.refs?.length; i++) {
        var ref = this.refs[i];
        var ca = await this.excels[this.PROJECT].simpleCellAddressFromString(ref, this.sheetid);
        try {
          this.hot.setCellMeta(ca.row, ca.col, 'className', 'cellref' + i);
        } catch (error) {
          console.warn(error);
        }
      }
    } catch (err) {
      console.warn(err);
    }

    if (this.isEditMode)
      this.hot.render();
  }

  toload = [];

  getConfigSheets(configs: any, list: any) {  // TODO: funzt nicth mit public von anderen
    console.log('getconfigs', configs)
    if (configs == {} || !configs)
      return;

    for (var l in configs) {
      console.log('l', l)
      var c = configs[l];


      var userid = this.customerid;
      if (c.userid) userid = c.userid;
      list.push(userid + '_' + c.id);  // TODO: funzt nicth mit public von anderen
      this.getConfigSheets(c.configurations, list);
    }
  }


  impersonationId: string;
  leftpanelvisible = true; isEditMode = true;
  configid: string;
  async ngOnInit(): Promise<void> {

  }
  @ViewChild('viewportGizmo') viewportGizmoElement: ElementRef;


  cube1: any;
  async ngAfterViewInit() {
    this.projectid = this.route.snapshot.paramMap.get('projectid');
    this.configid = this.route.snapshot.paramMap.get('configid');
    var dbpath = "/projects/userprojects/" + this.customerid + "/" + this.projectid;
    // public
    if (this.route.snapshot.url[0].path == "p") {
      this.leftpanelvisible = false;
      this.isEditMode = false;
      dbpath = "projectspublic" + "/" + this.projectid;
    }


    this.setupthreejs();


    // tasks
    if (this.route.snapshot.url[0].path == "tasks") {
      // this.splitleft = 0;

      this.leftpanelwidth = 0;
      if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
      this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;

      this.initview();
      this.updateThreeFrame();
      this.loadSheet = false;
      this.taskManager = new Taskmanager(this.afs, this.storage, this.renderer, this.camera, this.scene, this.controls);
      this.scene.fog = null;
      return;
    }
    else {
      this.excelSync();


      this.auth.authState.subscribe(async x => {
        try {
          this.customerid = x?.uid;

          // impersonation
          this.impersonationId = this.route.snapshot.queryParamMap.get('impersonationId');
          if (this.impersonationId) {
            this.customerid = this.impersonationId;
            console.log('Impersonating customer ID:', this.customerid);
          }



          console.log("authstate sub: ", x);
          if (this.isEditMode)
            dbpath = "/projects/userprojects/" + this.customerid + "/" + this.projectid;

          this.toload = [];
          var pr = (await this.afs.doc(dbpath).get().toPromise());   //.subscribe(data => {

          // project loaded project json
          this.project = pr.data();
          if (!this.customerid) this.customerid = this.project.userid; //TODO: prüfen!!!
          if (!this.project.sceneSettings)
            this.project.sceneSettings = this.baseScene.sceneSettings
          else
            this.baseScene.sceneSettings = this.project.sceneSettings;


          // Add colWidths to each sheet in project if not exist
          if (this.project.sheets) {
            for (let sheetId in this.project.sheets) {
              if (!this.project.sheets[sheetId].colWidths) {
                this.project.sheets[sheetId].colWidths = new Array(300).fill(100);
              }
            }
          }


          if (!this.project.cameras)
            this.addCamera();
          if (this.project?.initialcamera) {
            this.camera.matrix.fromArray(JSON.parse(this.project.initialcamera));
            this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
          }
          if (this.isEditMode && this.project?.camera) {
            this.camera.matrix.fromArray(JSON.parse(this.project.camera));
            this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);

          }
        } catch (error) {
          console.error("Failed to load project data", error);


          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //   this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }


        try {
          if (!this.project.settings) {
            this.project.settings = {};
            this.project.settings.cameraZoom = true;

            this.project.settings.cameraZoomPointer = false;
            this.project.settings.subprojectSelectable = false;
            this.project.settings.cameraPan = true;

          }
          if (!this.project.sceneSettings.dirAngle)
            this.project.sceneSettings.dirAngle = 0;
          if (!this.project.menuSettings?.menuleft)
            this.project.menuSettings.menuleft = '0px';

          this.changeHandling();

          this.baseScene.updateScene(this.project.sceneSettings, this.camera);
          this.project.id = pr.id;
          this.toload.push(this.customerid + '_' + this.projectid);
          this.PROJECT = this.toload[0];

          this.getConfigSheets(this.project.configurations, this.toload);
          // remove duplicates from list
          this.toload = this.toload.filter((v, i, a) => a.indexOf(v) === i);
          console.log('toload ', this.toload);

          this.autocompleteListPrimitives.push('');
          for (var i = 0; i < this.project.sheets.length; i++) {
            this.autocompleteListPrimitives.push("sheet: " + this.project.sheets[i].name);
          }
          //      });


        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //        this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }


        try {

          // toload.push('userid-assemblyclass-2');
          this.files = this.toload;
          this.workers = [];
          this.excels = {};
          var promises = [];
          for (var i = 0; i < this.toload.length; i++) {
            const w = new Worker(new URL('./../app.worker', import.meta.url), { type: 'module' });
            this.workers.push(w);
            this.excels[this.toload[i]] = Comlink.wrap(this.workers[i]) as any;
            var p = this.excels[this.toload[i]].load(this.toload[i], this.project.sheets);
            promises.push(p);
          }

          var t0 = performance.now();
          var promisesresult = await Promise.all(promises).catch(reason => {
            console.error(reason)
          });
          console.log('promisesresult', promisesresult);
          var t1 = performance.now();
          console.log("webworker loads took " + (t1 - t0) + " milliseconds.");

          this.dataset = promisesresult[0].cells;
          this.datasetids = promisesresult[0].datasetids;
          this.cellformulas = promisesresult[0].cellvalues;

          await this.excels[this.PROJECT].addNamedExpression("screensize", "0,0");
          await this.excels[this.PROJECT].addNamedExpression("screenwidth", 0);
          await this.excels[this.PROJECT].addNamedExpression("screenheight", 0);

          if (this.isEditMode) {
            this.excels[this.PROJECT].setCurrentSheetId(this.sheetid);
            this.excels[this.PROJECT].monitorCellFormulas();
          }




          // config params
          if (this.configid) {
            var configpath = "/configs/" + this.customerid + "/" + this.projectid + "/" + this.configid;
            var configmodel = (await this.afs.doc(configpath).get().toPromise()).data();   //.subscribe(data => {
            this.project.configmodel = configmodel;
            //        { inputs: newsnippet.values[0], vals: newsnippet.values[1] };
            var outputRowsP = await this.excels[this.PROJECT].getConfiguration(0, this.project.configmodel.inputs, this.project.configmodel.vals, true);
            //         // outputrowsp to dataset
            // //        var outputRows = await excels[configuration].getConfiguration(sheetid, commentrow, row);

            for (var i = 1; i < outputRowsP.length; i += 2) {

              for (var j = 0; j < outputRowsP[i].length - 1; j++) {
                var rowindex = outputRowsP[i][outputRowsP[i].length - 1];
                this.dataset[rowindex][j] = outputRowsP[i][j];
                //           cadnode = await this.createOrUpdateCADNode(rowindex, sheetid, "$" + sheetid + "#" + outputRows[i + 1][0],
                //             outputRows[i + 1], outputRows[i], group, excels, configuration, loaded, workers, customerid, projectid,
                //             projectconfigmodel);
              }
            }
          }




        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error("Error loading project data: " + error.message, null, { positionClass: 'toast-bottom-center', });
          //       this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }



        try {


          this.hot = this.hotRegisterer.getInstance(this.hotid); //.loadData([['new', 'data']]);
          this.hot.addHook('afterColumnResize', (newSize,column) => {
            //  this.saveColumnWidth(column, newSize);
            console.log("afterColumnResize", column, newSize);
            this.project.sheets[this.sheetid].colWidths[column] = newSize;
          });



          if (this.hot)
            this.applyCellMeta();

          // td styles
          this.tdstyles = [];
          // TODO: nach sheetid filtern
          this.getCellStyles(this.sheetid);

          this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);

          this.setSceneSettings()
          this.gridchange();
          this.initview();

          this.createModel(this.scene, this.dataset, this.PROJECT);
          this.loadSheet = false;
          console.log("jsongui", this.jsongui);
          // API ------------------------------------
          globalThis.scene = this.scene;
          globalThis.excels = this.excels;
          globalThis.subprojectSelected = this.selectedSubprojectID;
          globalThis.project = this.project;
          this.vba = new VBAAPI(this.excels, this.hot, this.scene, this.dataset, this, this.controls, this.transformControl, this.project, this.afs);
          await this.vba.createScripts();
          globalThis.ActiveWorkbook = this.vba.ActiveWorkbook;

          globalThis.updateUI = this.applyCellMeta;
          globalThis.API = this.vba;
          this.createTimers();
          Utils.basecsschange(this.project.id, this.project.menuSettings);
          this.csschange(this.project.csscode);
          //----------------------------------------

          const container = document.body as any;
          // get htmlelement with id viewportGizmo

          //  console.log(this.viewportGizmoElement.nativeElement); // Access the native DOM element

          //  const viewportGizmoElement = this.viewportGizmoElement.nativeElement as any;// document.getElementById('viewportGizmo') as any;
          // var optioms = {
          //   container: viewportGizmoElement,
          //   size: 100,
          // }

          this.viewportGizmo = new ViewportGizmo(this.camera, this.renderer);

          this.viewportGizmo.target = this.controls.target;

          if (this.isEditMode)
            this.leftpanelwidth = this.splitEl.displayedAreas[0].size / 100.0 * window.innerWidth;
          else
            this.leftpanelwidth = 0;
          if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
          this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;
          this.viewportGizmo.left = this.threecontainerwidth - 132; // hack
          // // listeners
          var ended = false;
          this.viewportGizmo.addEventListener("start", () => {
            ended = false;
          }
            //this.controls.enabled = false
          );
          this.viewportGizmo.addEventListener("end", () => {
            ended = true;
            // this.controls.enabled = true;
            //          this.camera.updateProjectionMatrix();
            this.updateThreeFrame();
            //      this.renderer.render(this.scene, this.camera);
            //      this.viewportGizmo.render()
            console.log("end")
          });
          this.viewportGizmo.addEventListener("change", () => {
            //  this.updateThreeFrame()
            // var delta = this.clock.getDelta();
            // this.controls.update(delta);
            //   this.camera.updateProjectionMatrix();
            if (!ended) {
              console.log("change");
              this.renderer.render(this.scene, this.camera);
              this.viewportGizmo.render()
            }
            else {
              this.camera.updateProjectionMatrix();

            }

            //    this.updateThreeFrame();
          }

          );

          this.controls.addEventListener("change", () => {
            this.viewportGizmo?.update();
            this.viewportGizmo?.render();
          });



        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //     this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }



        eval(this.project.scriptcode); // erste ausführung von eval zur javascript ausführung zB gui erstellung über jquery

        this.updateThreeFrame();
        //    this.renderer.setAnimationLoop(this.animate)
        this.animate(0);

      });
    }
    var isSaving = false;
    document.addEventListener('keydown', async e => {
      //if (e.ctrlKey && e.key === 'e') {
      if (!isSaving)
        if (e.metaKey && e.key === 'e' || e.ctrlKey && e.key === 's') {
          // sleep for 2 seconds
          console.log("saving");
          isSaving = true;
          e.preventDefault();
          this.saveCells();
          // update model
          if (this.editval)
            this.sheetChange(this.editcell, this.editval, this.editval);

          ThreeUtils.clearScene(this.scene);
          this.applyCellMeta();
          this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
          this.createModel(this.scene, this.dataset, this.PROJECT);

          this.afs.doc("projects/userprojects/" + this.customerid + "/" + this.project.id).update(JSON.parse(JSON.stringify(this.project)));

          await new Promise(resolve => setTimeout(resolve, 4000));
          isSaving = false;
        }
    });


    this.subscriptions.add(
      this.valueSubject.pipe(
        debounceTime(20),  // Debounce inputs to reduce frequency
        switchMap(async inputValue => {
          if (this.iscreating) {
            // If a generation is ongoing, save the latest input to be processed later
            this.lastInputValue = inputValue;
            return [] as any;  // Return an empty observable
          } else {

            this.iscreating = true;
            await this.sheetChangeWrapper(inputValue);
            // Start generation immediately if nothing is currently generating
            this.iscreating = false;
            if (this.isEditMode)
              this.hot?.render();
          }
        })
      ).subscribe(model => {

        // TODO: wir nach cell editierung ausgelöst?!

        // console.log('subscribption', model)
        /*     if ((model as any).length > 0) {
              console.log('Model generated:', model);
            } */
        //      this.isGenerating = false;
        // Check if there is a queued last input to process next
        if (this.lastInputValue !== null) {
          let nextInput = this.lastInputValue;
          this.lastInputValue = null;  // Reset last input value
          this.valueSubject.next(nextInput);  // Re-emit the last input
        }
      })
    );


    //---- alter code
    /*     this.valueSubject.pipe(debounceTime(10)).
          subscribe(async value => {
            // http request can go here
            if (!this.pastedresult) {  //über hack?!
              console.log('debounce', JSON.stringify(value.data));
    
              //   this.changequeue.push(value);
    
              while (this.iscreating) {
                // wait 10ms
                await new Promise(resolve => setTimeout(resolve, 1));
              }
              this.iscreating = true;
              //   var change = this.changequeue.pop();// this.changequeue.shift();
              var change = value;
              console.log('change', change.data);
    
              await this.sheetChangeWrapper(change);
              this.iscreating = false;
    
    
              this.pastedresult = null;
            }
          }); */





  }

  private subscriptions = new Subscription();
  private inputSubject = new Subject<string>();
  isGenerating = false;
  lastInputValue: string | null = null;  // Store the last input value during generation

  tasksim() {


    const taskService = new TaskService();

    // Simulating task submissions
    taskService.addTask('Task 1'); // This task is likely to be canceled because another task follows soon after.
    setTimeout(() => taskService.addTask('Task 2'), 10); // Canceled by Task 3
    setTimeout(() => taskService.addTask('Task 3'), 40); // Canceled by Task 4
    setTimeout(() => taskService.addTask('Task 4'), 80); // Executes, as it comes 50ms after Task 3
    setTimeout(() => taskService.addTask('Task 5'), 140); // Canceled by Task 6
    setTimeout(() => taskService.addTask('Task 6'), 200); // Executes, as it comes 60ms after Task 5
    setTimeout(() => taskService.addTask('Task 7'), 2440); // Executes with clear isolation from previous tasks
    setTimeout(() => taskService.addTask('Task 8'), 2540); // Executes with clear isolation from previous tasks
    setTimeout(() => taskService.addTask('Task 9'), 5540); // Executes with clear isolation from previous tasks


  }

  iscreating = false;
  changequeue = [];

  private initview() {
    // https://github.com/mrdoob/three.js/blob/master/examples/misc_controls_transform.html
    const geometry = new THREE.BoxGeometry(200, 200, 200);
    const material = new THREE.MeshLambertMaterial({ transparent: false, opacity: 0.9, color: 0x00ff00 });
    const mesh = new THREE.Mesh(geometry, material);
    this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
    //  this.control.addEventListener('change', this.render);
    this.transformControl.addEventListener('dragging-changed', (event) => {
      console.log("dragging-changed", event, this.transformControl.object);
      if (this.transformControl.object?.userData?.row) {
        var o = this.transformControl.object;
        var ud = this.transformControl.object.userData;

        var p = {} as any;
        var r = {} as any;
        var s = {} as any;
        for (var i = 0; i < ud.commentrow.length; i++) {
          if (ud.commentrow[i] == "x") {
            this.dataset[ud.rowindex][i] = o.position.x; // p.x = ud.row[i];
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.x);
          }
          if (ud.commentrow[i] == "y") {

            this.dataset[ud.rowindex][i] = o.position.y;  //p.y = ud.row[i];
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.y);

          }
          if (ud.commentrow[i] == "z") {

            this.dataset[ud.rowindex][i] = o.position.z;
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.z);

          }
          // if (ud.commentrow[i] == "rx") r.x = ud.row[i];
          // if (ud.commentrow[i] == "ry") r.y = ud.row[i];
          // if (ud.commentrow[i] == "rz") r.z = ud.row[i];
          // if (ud.commentrow[i] == "sx") s.x = ud.row[i];
          // if (ud.commentrow[i] == "sy") s.y = ud.row[i];
          // if (ud.commentrow[i] == "sz") s.z = ud.row[i];
        }

        if (this.isEditMode)
          this.hot.render();

      }
      // this.transformControl.object.position.set(this.transformControl.object.position);
      this.controls.enabled = !event.value;
    });
    //    this.scene.add(mesh);
    this.transformControl.reset();
    this.transformControl.setSpace("local");//world
    //    this.transformControl.attach(mesh);
    this.transformControl.setMode('translate');
    // this.scene.add(this.transformControl);
    // this.transformControl.detach(mesh);
    //    this.leftpanelwidth = 0.4 * window.innerWidth;;
    console.log('ngAfterContentInit', window.innerWidth, this.leftpanelwidth)

    this.hot = this.hotRegisterer.getInstance(this.hotid); //.loadData([['new', 'data']]);


    this.cdr.detectChanges();

    this.onResize(null);

    if (this.isEditMode) {
      this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
      this.hot?.render();

    }



    // TODO: autoformat?
    this.monacoLoaderService.isMonacoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      // take(1),
    ).subscribe((x) => {
      console.log("monaco loaded", x, monaco);
      // // monaco.editor.setTheme('vs-light');
      // const editor = monaco.editor.create(document.getElementById("editor1"), {
      //   value: "function hello(){\nalert('Hello world2!');}",
      //   language: "css",
      //   //   "autoIndent": true,
      //   "formatOnPaste": true,
      //   "formatOnType": true
      // });
      // console.log('eiddotr1', editor, editor.getAction('editor.action.formatDocument'))
      // editor.getAction('editor.action.formatDocument').run();
      // //(monaco.editor as any).getAction('editor.action.formatDocument').run();
      // globalThis['hftest'] = this.scene;
      // eval('console.log("ausgewertet:",hftest);');
      // eval('globalThis["testf"] = ' + this.scriptcode);
    });

    //this.cdr.detectChanges();

    if (this.isEditMode) {

      this.applyCellMeta();
      this.hot?.render();
      this.hot?.rootElement?.focus();
      this.hot?.refreshDimensions();
      // hack fr splitter
      this.splitDragEnd({ sizes: [this.splitleft, 99 - this.splitleft] }).then(() => {
        this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
      });
    }

    //    this.rendererContainer.nativeElement.focus();
  }

  async cloneproject() {
    var p = JSON.parse(JSON.stringify(this.project));
    var pid = p.id;
    delete p.id;
    p.name = p.name + "_copy_";
    let currentDate = new Date();
    p.name += currentDate.getFullYear() + "-" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" +
      ("0" + currentDate.getDate()).slice(-2) + " " +
      ("0" + currentDate.getHours()).slice(-2) + "-" +
      ("0" + currentDate.getMinutes()).slice(-2);
    p.createtime = new Date().getTime();
    p.lastsave = p.createtime;
    var newdoc = await this.afs.collection("projects/userprojects/" + this.customerid).add(p);
    var npid = newdoc.id;
    this.loadSheet = true;
    let attempt = 0;
    const maxAttempts = 7;
    const attemptInterval = 400; // milliseconds

    const cloneProject = async () => {
      try {
        const response = await this.http.post(this.SERVER + "/copySheets", { customerid: this.customerid, projectid: pid, newprojectid: npid, sheetscount: 5 }).toPromise();
        console.log('clone result', response);
        this.loadSheet = false;
        this.snackBar.open("Project duplicated. Go To Dashboard to load copy.", null, { duration: 3000 });
      } catch (error) {
        if (attempt < maxAttempts) {
          setTimeout(() => {
            attempt++;
            cloneProject();
          }, attemptInterval);
        } else {
          console.error('Failed to clone project after ' + maxAttempts + ' attempts', error);
          this.snackBar.open("Failed to duplicate project. Please try again.", null, { duration: 3000 });
        }
      }
    };
    cloneProject();

  }

  async saveproject() {
    this.loadSheet = true;
    this.project.camera = JSON.stringify(this.camera.matrix.toArray());
    this.project.configmodel = await this.createConfigModel();
    this.project.lastsave = new Date().getTime();
    await this.afs.doc("projects/userprojects/" + this.customerid + "/" + this.projectid).update(JSON.parse(JSON.stringify(this.project)));

    await this.saveCells();
    this.hot.render();
    this.hot.refreshDimensions();
    this.splitDragEnd({ sizes: [this.splitleft, 99 - this.splitleft] }).then(() => {
      this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
    });
    this.loadSheet = false;
  }

  async saveCells() {
    console.log('saveCells', { customerid: this.customerid, projectid: this.projectid, sheets: [this.cellformulas], cells: this.cellformulas, datasetids: this.datasetids })

    this.cellformulas = await this.excels[this.PROJECT].getSheetSerializedByIndex(this.sheetid);

    try {
      var attempt = 0;
      var r;
      while (attempt < 12) {
        try {
          r = await this.http.post(this.SERVER + "/applyCellUpdates",
            {
              customerid: this.customerid, projectid: this.projectid, sheetid: this.sheetid,
              sheets: [this.cellformulas], cells: this.cellformulas, datasetids: this.datasetids
            }).toPromise();
          if (r) break; // Exit loop if request is successful
        } catch (e) {
          console.warn('Attempt ' + (attempt + 1) + ' failed', e);
        }
        attempt++;
        await new Promise(resolve => setTimeout(resolve, 400)); // Wait 300ms before retrying
      }

      //  .subscribe(result => {
      console.log('applyCellUpdates result', r);
      this.zone.run(() => {
        this.snackBar.open("Project saved", "", { duration: 1000 });
      });
    } catch (e) {
      console.error('saveCells error', e);
      this.snackBar.open("Error saving cells" + e.message, "", { duration: 2000, panelClass: ['error-snackbar'] });
    }
  }




  excelSync() {
    // excel sync
    this.afs.collection("cellupdates/" + this.projectid + "/excelupdates").valueChanges({ idField: 'id' }).subscribe(data => {
      for (var i = 0; i < data.length; i++) {
        console.log('cellupdate', JSON.parse((data[i] as any).valueAfter)); // data[i]);
        console.log('del', "cellupdates/" + this.projectid + "/excelupdates/" + (data[i] as any).id)
        this.afs.doc("cellupdates/" + this.projectid + "/excelupdates/" + (data[i] as any).id).delete();
      }
    });
  }

  async createConfigModel(): Promise<any> {
    // root params
    var newsnippet = await this.excels[this.PROJECT].getSnippet(this.project.sheets[0].name);

    // projects
    var configModel = { inputs: newsnippet.values[0], vals: newsnippet.values[1] };
    this.scene.traverse((child) => {
      if (child.userData?.typ?.toUpperCase().startsWith("PROJECT")) {
        var path = child.userData.path;
        configModel[path] = child.userData;
        //      Utils.setValue(configModel, path, child.userData);
      }
    });

    console.log('configModel', configModel)
    return configModel;
  }

  stylesoverride(e) {
    console.log('stylesoverride', e, this.project?.settings?.stylesoverride);
    this.project.settings.stylesoverride = e.checked;

    if (this.isEditMode)
      this.hot.render();
    //     if (e.checked) {
    //       this.dataset.
    //         for(var i = 0; i < dataset?.length; i++)
    //       for (var i = 0; i < dataset?.length; i++){

    //         dataset[i][0] 
    //         this.hot.setCellMeta(i, j, 'stylemeta', { background: e.color.hex });
    //       }

    //       for (var i = s[0][0]; i <= s[0][2]; i++) {
    //         for (var j = s[0][1]; j <= s[0][3]; j++) {
    //           var cell = this.hot.getCell(i, j);
    //           cell.style.background = e.color.hex;
    //           this.hot.setCellMeta(i, j, 'stylemeta', { background: e.color.hex });
    //         }
    //       }
    //     } else {
    //       for (var i = s[0][0]; i <= s[0][2]; i++) {
    //         for (var j = s[0][1]; j <= s[0][3]; j++) {
    //           var cell = this.hot.getCell(i, j);
    //           cell.style.background = e.color.hex;
    //           this.hot.setCellMeta(i, j, 'stylemeta', undefined);
    //         }
    //       }
    // }
  }


  // object is in list 
  isInList(list, object) {
    for (var i = 0; i < list.length; i++) {
      if (JSON.stringify(list[i]) == JSON.stringify(object)) {
        return true;
      }
    }
    return false;
  }

  // TODO: ggf in ww
  overlays: any = { root: {} }; showOverlaymenu = false;
  showOverlaymenu1 = false; showothermenu = false;
  contextmenuitem = -1;
  contextmenuitem1 = -1;
  overlayarray: any = [];
  async createModel(rootNode: any, dataset: any, CONFIGURATION: string) {
    this.processing = true;
    this.appRef.tick();

    this.updateThreeFrame();
    this.outputrow = this.getOutputRow();
    for (var j = this.outputrow + 1; j < dataset.length; j++) {
      try {

        if (dataset[j][1] != null && dataset[j][1] != "") {
          var typ = dataset[j][1];
          var objectname = "$" + this.sheetid + "#" + dataset[j][0];
          var commentrow = this.getCommentRow(dataset, j);

          // overlaytypes is in ist
          if (this.isInList(this.overlayTypes, typ)) {
            // 2d overlays 
            objectname = "root^$" + this.sheetid + "#" + dataset[j][0];
            var overlay = await this.createOverlay(j, objectname, dataset[j], commentrow, typ);
          }
          else {

            //  console.log('createCADNode ', objectname, typ);
            var newnode = await this.cad.createOrUpdateCADNode(j, 0, objectname, dataset[j], commentrow, rootNode, this.excels, CONFIGURATION, this.toload, this.workers, this.customerid, this.projectid, this.project.configmodel, 0);
            if (newnode) {
              //    newnode.name = objectname;
              rootNode.add(newnode);
            }
          }

        }
      } catch (e) {
        console.log('createModel error', e);
      }
    }

    // gltf nodes


    this.project.configmodel = await this.createConfigModel();

    this.updateThreeFrame();
    this.processing = false;
  }

  async createChart(params) {
    var sheetid = 0; // zunächst nur overlays aus 1.sheet aus mainconfig
    var from = await this.excels[this.PROJECT].simpleCellAddressFromString(params.values.split(':')[0], sheetid);
    var to = await this.excels[this.PROJECT].simpleCellAddressFromString(params.values.split(':')[1], sheetid);
    var table = await this.excels[this.PROJECT].getRangeValues({ start: from, end: to });
    params.chartvalues = Utils.transformTableToNgxChartsModel(table);
    return params.chartvalues;
  }
  async createOverlay(j, objectname, row, commentrow, typ) {
    var overlayname = "root"
    var params = Utils.mapRowToParams(row, commentrow);
    params.typ = typ;
    if (!this.overlays[overlayname][objectname])
      this.overlays[overlayname][objectname] = {};
    if (params.typ == "chart") {
      this.createChart(params);
    }
    this.overlays[overlayname][objectname] = params;
    this.overlays[overlayname][objectname].objectname = objectname;
    // json keys to array
    var ovkeys = Object.keys(this.overlays.root);
    this.overlayarray = [];
    for (var i = 0; i < ovkeys.length; i++) {
      this.overlayarray.push(this.overlays.root[ovkeys[i]]);
    }
  }
  async updateOverlay(j, objectname, row, commentrow, typ) {
    var overlayname = "root"
    var params = Utils.mapRowToParams(row, commentrow);
    params.typ = typ;
    if (!this.overlays[overlayname][objectname])
      this.overlays[overlayname][objectname] = {};
    if (params.typ == "chart") {
      var v = await this.createChart(params);
      this.overlays[overlayname][objectname] = this.diffchart(this.overlays[overlayname][objectname], params);
      this.overlays[overlayname][objectname].chartvalues = params.chartvalues;
    }
    else
      this.overlays[overlayname][objectname] = params;
    this.overlays[overlayname][objectname].objectname = objectname;
    // json keys to array
    //  var ovkeys = Object.keys(this.overlays.root);
    //    this.overlayarray = [];
    var found = false;
    for (var i = 0; i < this.overlayarray.length; i++) {
      if (this.overlayarray[i].objectname == objectname) {
        found = true;
        this.overlayarray[i] = this.overlays.root[objectname];
        break;

      }
    }
    if (!found) {
      if (!this.overlayarray) this.overlayarray = [];
      this.overlayarray.push(this.overlays.root[objectname]);
    }
  }

  diffchart(params, newparams) {
    var conf: Config;
    conf = {
      arrays: {
        detectMove: true,
        includeValueOnMove: false
      },
      textDiff: {
        minLength: 60
      },
      propertyFilter: function (name, context) {
        return name.slice(0, 1) !== '$';
      },
      cloneDiffValues: false
    };
    var jsondiffpatch = new DiffPatcher(conf);
    var delta = jsondiffpatch.diff(params, newparams);
    var r = jsondiffpatch.patch(params, delta);
    return r;
  }

  getCommentRow(dataset, row) {
    var i = row - 1;
    var outputrow = this.getOutputRow();
    while (dataset) {
      if (dataset[i][0] == "#")
        return dataset[i];
      i--;
      if (i <= outputrow)
        return null;

    }
    return null;
  }

  getHeadRow(dataset, row) {
    var i = row - 1;
    var firstcell = dataset[i][0];
    while (firstcell != '#' && i != 0) {
      firstcell = dataset[i][0];
      if (firstcell == '#') return i;
      i--;

    }
    return i;
  }

  getOldNameByRow(olddata, rowindex) {
    if (olddata)
      for (var i = 0; i < olddata.length; i++) {
        if (olddata[i].address?.row == rowindex && olddata[i].address?.col == 0) {
          return olddata[i].oldValue;
        }
      }
    return null;
  }
  dataset2: any;
  updatecount = 0;
  async updateModel(exportedchanges, olddata, scenenode) {
    //    console.log('updatemodel', exportedchanges);
    this.processing = true;
    // this.appRef.tick();

    var rowupdated = [];

    for (var j = 0; j < exportedchanges?.length; j++) {
      var change = exportedchanges[j];
      var a = change.address;
      if (!a) continue;
      var sheetid = a.sheet;

      this.outputrow = this.getOutputRow();
      var dataset = this.dataset
      if (!this.isMainConfig)
        dataset = this.dataset2;
      // TODO: check was geupdated wurde und nur zeilenweise updaten

      // gelöscht
      if (dataset[a.row][1] == "" && a.row > this.outputrow && dataset[a.row][0] != "#") {
        if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {
          // übergeordnete projekte nicht selektiert?
          // var nname = parent.name.replace(this.selectedprojectpath, "");
          // var n = "#" + nname.split('#')[1].split('^')[0];
          // var nname2 = this.selectedprojectpath + n;
          // if (!this.selectedprojectpath.endsWith("^$0"))
          //   nname2 = this.selectedprojectpath + "^$0" + n;
          // var nobj = this.scene.getObjectByName(nname2);
          // if (nobj) {
          //   this.selectedprojectpath = nname2;
          //   parent = nobj;
          // }



          var name = this.getOldNameByRow(olddata, a.row);
          var objectnameold = "$" + sheetid + "#" + name; // todo: subprojects
          var objectname = this.selectedprojectpath + "#" + name;
          objectname = Utils.preNames(this.scene) + "^" + objectname;
          objectname = objectname.replace("root^root^", "root^");

          rowupdated.push({ sheet: a.sheet, row: a.row });

          var toremoveobject = this.scene.getObjectByName(objectname);
          if (toremoveobject)
            this.scene.remove(toremoveobject);

        }
      }

      if (dataset[a.row][1] != null && dataset[a.row][1] != "" && a.row > this.outputrow && dataset[a.row][0] != "#") {
        //
        if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {

          rowupdated.push({ sheet: a.sheet, row: a.row });
          var typ = dataset[a.row][1];
          var objectname = "root^$" + sheetid + "#" + dataset[a.row][0]; // TODO falsch für unterprojekte
          objectname = Utils.preNames(this.scene) + "^" + objectname;
          objectname = objectname?.replace("root^root^", "root^");


          var subtraction = null;

          // TODO: subprojekte erst auf Änderung x,y,z,rx,ry,rz,tx,ty,tz,material,visible checken, dann erst remove/add

          // TODO funzt nicht mit gedropppten rows 
          var hr = this.getHeadRow(dataset, a.row);
          if (hr != 0)// {//hr = a.row - 1;// TODO über jack
            //  try {

            if (dataset[hr][a.col]?.startsWith("node")) { // == "nodematerial") {
              if (objectname) {
                var object = this.scene.getObjectByName(objectname);
                var nodename = dataset[a.row - 1][a.col].split('=')[1];

                var statement = dataset[a.row][a.col];
                var prop = statement.split('=')[0];
                var value = statement.split('=')[1];
                // hier wird die node nur updated --------------------
                this.cad.updateNodel(prop, nodename, value, object);
                // -----------------------------------------------
              }
            }
            //          } catch (error) {
            //            console.warn('updateModel', error);
            //           }
            //      }
            else {
              var isover = false;
              if (objectname) {
                if (scenenode) {
                  var object = scenenode?.getObjectByName(objectname);


                  if (object == this.sceneobjectSelected)
                    isover = true;

                }

                while (object) {
                  //      console.log(objectname, object);
                  // bestimmte Attribute werden direkt im model gesetzt => TODO: zweite prüfung in createORupdateNode nötig?
                  var hr = this.getHeadRow(dataset, a.row);

                  if (this.dataset[hr][a.col] == "x" || this.dataset[hr][a.col] == "y" || this.dataset[hr][a.col] == "z" ||
                    this.dataset[hr][a.col] == "rx" || this.dataset[hr][a.col] == "ry" || this.dataset[hr][a.col] == "rz" ||
                    this.dataset[hr][a.col] == "sx" || this.dataset[hr][a.col] == "sy" || this.dataset[hr][a.col] == "sz" ||
                    this.dataset[hr][a.col].startsWith("node") || this.dataset[hr][a.col] == "visible"
                  )
                    break;
                  if (object) {
                    //      console.log('remove', objectname)
                    if (object.userData.subtraction) {

                      var subtraction = scenenode.getObjectByName(object.userData.subtraction);
                      console.log('subtraction', object.userData.subtraction, subtraction);

                    }
                    scenenode.remove(object);

                  }
                  object = scenenode.getObjectByName(objectname);

                }
              }
              // get subproject node

              // var subnode = this.scene;

              if (!this.selectedSubprojectID)
                this.selectedSubprojectID = this.PROJECT;

              var commentrow = this.getCommentRow(dataset, a.row); // dataset[a.row - 1]

              if (this.isInList(this.overlayTypes, typ)) {
                // 2d overlays 
                var overlay = await this.updateOverlay(j, objectname, dataset[a.row], commentrow, typ);
              }
              else {
                // 3d model node estellen -----------------------------------------------
                var newnode = await this.cad.createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel, sheetid);

                if (newnode?.userData)
                  if (newnode.userData.typ == "gltf" && newnode.userData.cadstate == "created") {
                    var dim = JSON.parse(JSON.stringify(newnode)).object;
                    await this.handleDimensionsDialog(dim, a.row, commentrow);
                    rowupdated.push({ sheet: a.sheet, row: a.row });
                  }

                // postprocess cuts and boudnignsboxes and connections

              }

              if (subtraction) {
                scenenode = scenenode; //?
                var rowindex = subtraction.userData.rowindex;
                var row = subtraction.userData.row;
                var commentrow = subtraction.userData.commentrow;
                var name = subtraction.userData.path;
                scenenode.remove(subtraction);
                var newnode = await this.cad.createOrUpdateCADNode(rowindex, sheetid, name, row, commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel, sheetid);

              }

              if (isover && newnode)
                this.sceneobjectSelected = newnode;
            }
        }
      }

    }

    // this.updateSelectedObjectHelpe();
    console.log("this.sceneobjectSelected", this.sceneobjectSelected);
    this.handleObjectSelectionByRows(this.curSelectedRow);//TODO: nicht bei scene nclick und invisible
    this.updateSelectedObjectHelpe();

    //  this.updateThreeFrame();

    //  this.project.configmodel = await this.createConfigModel(); // muss nach rendering passieren, da sonst flackern!
    this.processing = false;
  }


  async copyFormulas(i: number, event: any) {
    console.log('copyFormulas', i);

    //   var r = await this.excels[this.PROJECT].copyall(i);
    //   console.log('result ', r);
    // copy 2d array to clipboard

    var promisesresult = await this.excels[this.PROJECT].getSheetByIndex(i);
    // var index = promisesresult.index;
    // this.dataset = promisesresult.cells; // cells
    // this.datasetids = promisesresult.datasetids;
    // this.cellformulas = promisesresult.cellvalues;


    var data = [
      [1, 2, 3],
      ["=a1", "=1+3+3+22", 6],
      [7, 8, 9]
    ];
    promisesresult.cellvalues
    var dataString = promisesresult.cellvalues.map(row => row.join("\t")).join("\n");

    // Write the string to the clipboard using navigator.clipboard.writeText()
    navigator.clipboard.writeText(dataString);


    event.stopPropagation();
  }
  renameSheet(i: number, event: any) {
    console.log('renameSheet', i);

    const dialogRef = this.dialog.open(InputdialogComponent, {
      width: '400px',
      data: { name: this.project.sheets[i].name, okbutton: "Rename" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        this.excels[this.PROJECT].renameSheet(i, result);
        this.project.sheets[i].name = result;
      }


    });

    event.stopPropagation();
  }
  removeSheet(i: number, event: any) {
    console.log('removeSheet', i);
    event.stopPropagation();
  }

  additionalColumns=5;
  additionalRows=100;
dlgresizetable = false;
  resizeTables() {

    this.loadSheet = true;
this.dlgresizetable=false;
    let firebaseFunctionUrl = 'https://us-central1-xbuild3d.cloudfunctions.net/resizeTables'; // Replace with your actual URL
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      customerid: this.customerid,
      projectid: this.projectid,
      additionalColumns: this.additionalColumns,
      numberOfRows: this.additionalRows
    };

    this.http.post(firebaseFunctionUrl, body, { headers }).subscribe(result => {
      console.log('resizeTables', result);
    window.location.reload();

    })
  }

  async updateControls(exportedchanges, srcchange) {
    var rowupdated = [];

    var uihaschanges = false;
    for (var j = 0; j < exportedchanges?.length; j++) {
      var change = exportedchanges[j];
      var a = change.address;
      var v = change.newValue;

      this.outputrow = this.getOutputRow();

      if (!(srcchange.col == 2 && srcchange.row == a.row)) // src control nicht aendern, da fokuc springt (uind nicht nöötig)
        if (this.dataset[a.row][1] != null && this.dataset[a.row][1] != "" && a.row < this.outputrow) {
          //
          if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {

            rowupdated.push({ sheet: a.sheet, row: a.row });
            var typ = this.dataset[a.row][1];
            var objectname = this.dataset[a.row][0];

            uihaschanges = true;
            // remove all objects from three js scene
            // var objects = this.scene.children;
            // for (var i = 0; i < objects.length; i++) {
            //   var object = objects[i];
            //   if (object.name == objectname) {
            //     this.scene.remove(object);
            //   }
            // }

            globalThis.paths = [];
            globalThis.editnodes = [];
            var newjsongui = await traverse(this.jsongui).forEach(function (value) {
              if (typeof (value) === "object" && value) {
                if (value.controlName == objectname) {
                  var paths = this.path;
                  globalThis.paths.push(paths);
                  globalThis.editnodes.push(value);
                }

              }
            });

            for (var i = 0; i < globalThis.paths.length; i++) {
              var path = globalThis.paths[i];
              var oldobj = globalThis.editnodes[i];

              var parentnode = this.jsongui; // TODO
              var result = await this.excels[this.PROJECT].createNodesFromRow({}, this.dataset, a.row, a.sheet, false, true);
              var newnode = result.rootnode.childs[0];
              console.log('createControlNode ', a, oldobj, path);
              var n = JSON.parse(JSON.stringify(this.jsongui));
              //  Utils.setValue2(this.jsongui, path, newnode);
              //            this.diffpatchGUI(n);
              uihaschanges = true;
              //    uihaschanges          Utils.setValue2(this.jsongui, path, newnode);
            }
            //          var newnode = //(this.dataset[a.row], this.dataset[a.row - 1]);

          }
        }

      // timer restart
      if (this.dataset[a.row][1] == "timer") {
        if (a.col == 6)
          for (var x = 0; x < this.timers.length; x++) {
            var timer = this.timers[x];
            if (timer.name == this.dataset[a.row][0]) {
              if (v == false) {
                // clearInterval(this.intervals[x]);
                this.clocks[x].stop();
                break;
              }
              if (v == true) {
                // var step = this.dataset[a.row][3];
                // var clock = new THREE.Clock();
                // this.clocks[x] = clock;
                this.clocks[x].start();

                // this.intervals[x] = setInterval(async () => {
                //   var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: this.timers[x].row, col: 4 }, 0);
                //   //   this.timers[x].clock = new THREE.Clock();
                //   this.timerfunction(this.timers[x], this.clocks[x]);
                // }, step);


                // = setInterval(this.timerfunction(null,null), step);
                break;
              }
            }
          }
      }
    }



    // temp hack
    this.outputrow = this.getOutputRow();
    let changesBeforeOutputRow = false;
    for (var j = 0; j < exportedchanges?.length; j++) {
      var change = exportedchanges[j];
      var a = change.address;
      var v = change.newValue;
      if (a.row < this.outputrow && a.col != 2 && a.col != 3) {
        changesBeforeOutputRow = true;
        break;
      }
    }

    //    console.log('###Changes before output row:', changesBeforeOutputRow);

    if (changesBeforeOutputRow) {
      var jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid); //TODO: nur bei change erstellen

      //console.log("######jsongui", jsongui);
      // TODO: diffpatch staatt neu erstelen
      // this.jsongui = jsongui;

      this.diffpatchGUI(jsongui, this.jsongui);
    }


  }

  getParameterIndex(commentrow, name: string) {
    var index = -1;
    for (var i = 0; i < commentrow.length; i++) {
      var comment = commentrow[i];
      if (comment == name) {
        index = i;
        break;
      }
    }
    return index;
  }

  async handleDimensionsDialog(node, row, commentrow) {
    const dialogRef = this.dialog.open(DialogdimensionsComponent, {
      width: '550px',
      data: { node: node },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);

      if (result) {
        var factor = result.factor;
        var upaxis = result.upaxis;

        // this.cellformulas[this.selectedRange[0]][this.selectedRange[1]] = result;
        // this.cellformulas[cell.row][cell.col] = result;

        //(node as THREE.Object).scale.set(result, result, result);
        var n = this.getParameterIndex(commentrow, 'sx') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);
        var n = this.getParameterIndex(commentrow, 'sy') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);
        var n = this.getParameterIndex(commentrow, 'sz') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);

        if (upaxis == 'X') {
          var n = this.getParameterIndex(commentrow, 'ry') as any;
          var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
          await this.sheetChange(cell, "=PI()/2", 0);
        }
        if (upaxis == 'Y') {
          var n = this.getParameterIndex(commentrow, 'rx') as any;
          var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
          await this.sheetChange(cell, "=PI()/2", 0);
        }

      }
    });
  }

  getOutputRow(dataset = null): number {
    if (!dataset) dataset = this.dataset;
    var outputrow = 10000;
    for (var i = 0; i < dataset?.length; i++)
      if (dataset[i][0] == "OUTPUTID") {
        outputrow = i;
      }
    return outputrow
  }

  addCamera() {
    const cameraState = JSON.stringify(this.camera.matrix.toArray());
    if (!this.project.cameras)
      this.project.cameras = [];
    this.project.cameras.push(cameraState);
  }

  currentcamindex = 1;
  loadCamera(i: number) {
    this.currentcamindex = i + 1;
    var cameraState = this.project.cameras[i];
    this.camera.matrix.fromArray(JSON.parse(cameraState));
    // Get back position/rotation/scale attributes
    this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
    this.updateThreeFrame();
  }



  async copyImpersonated() {
    const user = await this.auth.currentUser;
    const userId = user?.uid;
    console.log('Current User ID:', userId);


    var p = JSON.parse(JSON.stringify(this.project));
    var pid = p.id;
    delete p.id;
    p.name = p.name + "_copy_";
    let currentDate = new Date();
    p.name += currentDate.getFullYear() + "-" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" +
      ("0" + currentDate.getDate()).slice(-2) + " " +
      ("0" + currentDate.getHours()).slice(-2) + "-" +
      ("0" + currentDate.getMinutes()).slice(-2);
    p.createtime = new Date().getTime();
    p.lastsave = p.createtime;
    var newdoc = await this.afs.collection("projects/userprojects/" + userId).add(p);
    var npid = newdoc.id;
    this.loadSheet = true;
    let attempt = 0;
    const maxAttempts = 7;
    const attemptInterval = 400; // milliseconds

    const cloneProject = async () => {
      try {
        const response = await this.http.post(this.SERVER + "/copySheets", { customerid: this.customerid, userId: userId, projectid: pid, newprojectid: npid, sheetscount: 5 }).toPromise();
        console.log('clone result', response);
        this.loadSheet = false;
        this.snackBar.open("Project duplicated. Go To Dashboard to load copy.", null, { duration: 3000 });
      } catch (error) {
        if (attempt < maxAttempts) {
          setTimeout(() => {
            attempt++;
            cloneProject();
          }, attemptInterval);
        } else {
          console.error('Failed to clone project after ' + maxAttempts + ' attempts', error);
          this.snackBar.open("Failed to duplicate project. Please try again.", null, { duration: 3000 });
        }
      }
    };
    //   cloneProject();

  }

  onRightClick($event, index) {
    console.log('onRightClick', $event);
    for (var i = 0; i < this.project.sheets.length; i++) {
      this.project.sheets[i].visible = false;
    }
    this.project.sheets[index].visible = true;
    return false;
  }
  async setSheetIndex(event, i) {
    console.log('setSheetIndex ', i, event);

    this.loadSheet = true;
    // hack
    await this.saveCells();

    this.sheetid = i;

    var t0 = performance.now();
    //    this.CONFIGURATION = toload[0];
    var promisesresult = await this.excels[this.PROJECT].getSheetByIndex(i);
    var index = promisesresult.index;
    this.dataset = promisesresult.cells; // cells
    this.datasetids = promisesresult.datasetids;
    this.cellformulas = promisesresult.cellvalues;
    console.log('dataset', this.dataset);
    promisesresult;
    var t1 = performance.now();
    console.log("setSheetIndex took " + (t1 - t0) + " milliseconds.");
    if (this.isEditMode)
      this.hot.render();

    ThreeUtils.clearScene(this.scene);
    this.getCellStyles(this.sheetid);
    this.applyCellMeta();
    this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
    await this.createModel(this.scene, this.dataset, this.PROJECT);


    this.excels[this.PROJECT].setCurrentSheetId(this.sheetid);


    this.loadSheet = false;

  }
  selectedObject = null;
  selectedObjectHelper: any;// THREE.BoxHelper;
  selectedWidget = null;

  updateSelectedObjectHelpe() {
    if (this.project.sceneSettings.showHelpers) {

      if (this.selectedObjectHelper)
        this.scene.remove(this.selectedObjectHelper);
      if (this.selectedObject) {
        this.selectedObject.updateMatrix();


        //   this.selectedObjectHelper = new THREE.BoxHelper(this.selectedObject, 0xff44ff);
        // var bb = ThreeUtils.getBoundingBox(this.selectedObject);
        // var box = new THREE.Box3(bb.min, bb.max); // create a bounding box from min and max

        var bb = ThreeUtils.getBBox(this.selectedObject);
        var box = new THREE.Box3(bb.min, bb.max);
        const boxGeometry2 = new THREE.BoxGeometry(
          box.max.x - box.min.x,
          box.max.y - box.min.y,
          box.max.z - box.min.z
        );
        const center = new THREE.Vector3();
        bb.getCenter(center);

        const edgesGeometry2 = new THREE.EdgesGeometry(boxGeometry2);
        const lineGeometry2 = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry2);
        const lineMaterial = new LineMaterial({
          color: 0xff00ff,  // Choose the color as needed
          linewidth: 4,  // Adjust the line width for visibility
          resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
        });
        const wireframe2 = new Wireframe(lineGeometry2, lineMaterial);
        wireframe2.position.copy(center);
        wireframe2.position.add(this.selectedObject.position);
        wireframe2.scale.set(1.1, 1.1, 1.1);
        wireframe2.computeLineDistances();

        this.scene.add(wireframe2);
        this.selectedObjectHelper = wireframe2;

        if (this.selectedObject.material) {
          //  this.selectedObject.material.transparent = true;
          // this.selectedObject.material.opacity = 0.7;
        }

        this.scene.add(this.selectedObjectHelper);
      }

      this.cad.showFaceCenters(this.selectedObject, this.scene);

    }


  }
  async handleObjectSelectionByRows(row1) {
    if (!row1) return;

    if (this.project.sceneSettings.showHelpers) {

      // row selection
      if (this.selectedObjectHelper)
        this.scene.remove(this.selectedObjectHelper);
      if (this.selectedObject?.material) {
        //  this.selectedObject.material.transparent = false;
        //  this.selectedObject.material.opacity = 1.0;
      }
      this.selectedObject = null;
      if (this.dataset[row1] && row1 > this.getOutputRow())
        if (this.dataset[row1][0] && (this.isInList(this.autocompleteListPrimitives, this.dataset[row1][1]) || this.dataset[row1][1]?.startsWith('project'))) {
          // 3d object selected
          var objectname = "root^$" + this.sheetid + "#" + this.dataset[row1][0];
          var object = this.scene.getObjectByName(objectname, true);

          if (object) {
            var bb = ThreeUtils.getBBox(object);
            var box = new THREE.Box3(bb.min, bb.max);
            const boxGeometry2 = new THREE.BoxGeometry(
              box.max.x - box.min.x,
              box.max.y - box.min.y,
              box.max.z - box.min.z
            );
            const center = new THREE.Vector3();
            bb.getCenter(center);

            const edgesGeometry2 = new THREE.EdgesGeometry(boxGeometry2);
            const lineGeometry2 = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry2);
            const lineMaterial = new LineMaterial({
              color: 0xff00ff,  // Choose the color as needed
              linewidth: 4,  // Adjust the line width for visibility
              resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
            });
            const wireframe2 = new Wireframe(lineGeometry2, lineMaterial);
            wireframe2.position.copy(center);
            wireframe2.scale.set(1.1, 1.1, 1.1);
            wireframe2.computeLineDistances();
           this.scene.add(wireframe2);
            this.selectedObjectHelper = wireframe2;
            ////
            // const material = new THREE.LineDashedMaterial({
            //   //   color: 0xff44ff,
            //   color: 0xff00ff,
            //   linewidth: 10,
            //   scale: 1,
            //   dashSize: 20,
            //   gapSize: 33,
            // });
            // this.selectedObjectHelper.computeLineDistances();
            // this.selectedObjectHelper.material = material;
            // this.selectedObjectHelper.computeLineDistances();
            // this.scene.add(this.selectedObjectHelper);


            // object.material.transparent = true;
            // object.material.opacity = 0.7;
            this.selectedObject = object;
          }

        }


      await traverse(this.jsongui).forEach(function (value) {
        if (typeof (value) === "object" && value) {
          value.selected = false;
        }
      });
      try {
        if (this.dataset[row1][0] && this.isInList(this.autocompleteListControls, this.dataset[row1][1])) {
          globalThis.dataset = this.dataset;
          await traverse(this.jsongui).forEach(function (value) {
            if (typeof (value) === "object" && value) {
              if (value.controlName == globalThis.dataset[row1][0]) {
                value.selected = true;
              }
            }
          });
        }
      } catch (error) {
        console.warn('Error processing selection', error);
      }

      this.cad.showFaceCenters(this.selectedObject, this.scene);

      this.updateThreeFrame();

    }
    // const plugin1 = this.hot.getPlugin('hiddenRows');
    // plugin1.hideRow(4);
  }
  state = {
    h: 150,
    s: 0.50,
    l: 0.20,
    a: 1,
  };
  mnucolorvisible = false;
  mnucolorvisible2 = false;
  primaryColor = "#ff0000";

  changeComplete($event: ColorEvent): void {
    this.state = $event.color.hsl;
    this.primaryColor = $event.color.hex;
    console.log('changeComplete', $event);
  }
  formula: string;
  curSelectedRow: number;
  curSelectedCol: number;
  outputrow: number;
  celltooltip: any = null;
  celltoolstop: string;
  celltoolsleft: string;
  celltoolsvisible = false;

  cellSelected = async (row1, col1, row2, col2) => {
    this.curSelectedRow = row1;
    this.curSelectedCol = col1;
    console.log("cell selected", row1, col1, row2, col2, this.formula)
    this.handleObjectSelectionByRows(this.curSelectedRow);

    var from = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: row1, col: col1 }, this.sheetid);
    var to = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: row2, col: col2 }, this.sheetid);
    this.curSelectionReadable = "" + from + " : " + to;
    this.formula = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: row1, col: col1 });

    var commentrowindex = await this.excels[this.PROJECT].getCommentRowIndex({ sheet: this.sheetid, row: row1, col: col1 });
    if (commentrowindex == -1) commentrowindex - row1 - 1;
    var above = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: commentrowindex, col: col1 });

    this.outputrow = this.getOutputRow();

    this.handleObjectSelectionByRows(this.curSelectedRow);
    this.updateThreeFrame();

    var cellid = "";
    let element = this.hot?.getCell(row1, col1);
    this.showSheetsmenu = false; this.showOverlaymenu = false; this.showOverlaymenu1 = false; this.showothermenu = false;
    // tooltip
    if (col1 < 2) {
      if (element) {
        //var element = document.getElementById(cellid);
        var clientRect = element.getBoundingClientRect();
        var clientX = clientRect.left;
        var clientY = clientRect.top; // - element.offsetTop; // clientRect.top;
        this.cellactionleft = clientX + 'px';
        this.cellactiontop = clientY - 45 + 'px';
        this.cellactionvisible = true;
        this.cellactionmenuvisible = false;
        this.cellinfovisible = false;
      }
    }
    else {
      this.cellactionvisible = false;
    }
    // cell info
    if (col1 >= 2) {
      if (element) {
        //var element = document.getElementById(cellid);
        var clientRect = element.getBoundingClientRect();
        var clientX = clientRect.left;
        var clientY = clientRect.top; // - element.offsetTop; // clientRect.top;
        this.cellinfoleft = clientX + 'px';
        this.cellinfotop = clientY - 50 + 'px';
        if (this.dataset[row1][0] == "#") {
          var newsnippet = null;
          var typ = this.dataset[row1 + 1][1];
          for (var i = 0; i < this.snippetManager.snippets.length; i++) {
            var snippet = this.snippetManager.snippets[i];
            if (snippet.name == typ) {
              newsnippet = this.snippetManager.snippets[i];
              break;
            }
          }

          this.celltooltip = newsnippet?.infos;
          //  this.sanitized.bypassSecurityTrustHtml(newsnippet.infos); newsnippet?.infos;
          this.cellinfovisible = true;
          this.celltooltipleft = this.cellinfoleft;
          this.celltooltiptop = (this.hot.rootElement.clientHeight - clientY + 256) + "px";// this.cellinfotop;
        } else {
          this.cellinfovisible = false;
        }
        this.cellinfomenuvisible = false;
      }
    }
    // cell tools
    if (above == "material" || above == "baseplane" || above == "filename") {

      var clientRect = element.getBoundingClientRect();
      var clientX = clientRect.left;
      var clientY = clientRect.top; // - element.offsetTop; // clientRect.top;
      this.celltoolsleft = clientX + 'px';
      this.celltoolstop = clientY + 30 + 'px';
      this.celltoolsvisible = true;
    }
    else {
      this.celltoolsvisible = false;
    }

    try {
      var meta = this.hot.getCellMeta(row1, col1).stylemeta;
      if (meta) {
        this.color1 = meta.background;
        this.color2 = meta.color;
      }

    } catch (error) {

    }
  }

  async showcelltools() {
    // selected cell is material? .... TODO: auch fr obere zellen (suchen nach headline)
    var commentrowindex = await this.excels[this.PROJECT].getCommentRowIndex({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });
    if (commentrowindex == -1) commentrowindex - this.curSelectedRow - 1;

    var above = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: commentrowindex, col: this.curSelectedCol });
    var selected = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });

    if (above == "material") {
      this.showmaterialsDialog(this.baseScene, selected);
    }
    if (above == "baseplane") {
      this.showshapeDialog();
    }
    if (above == "filename") {
      // todo: rowtype statt dateiendung prüfen
      var cell = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });
      var filter = "";
      if (cell?.split('?')[0].toLowerCase().endsWith('.glb') ||
        cell?.split('?')[0].toLowerCase().endsWith('.gltf'))
        filter = "gltf";
      if (cell?.split('?')[0].toLowerCase().endsWith('.png') ||
        cell?.split('?')[0].toLowerCase().endsWith('.jpg') ||
        cell?.split('?')[0].toLowerCase().endsWith('.jpeg') ||
        cell?.split('?')[0].toLowerCase().endsWith('.svg'))
        filter = "png";


      this.showfilesDialog(filter, true);
    }

  }

  async cellinput(event) {
    console.log("You entered: ", event.target.value);
    var cell = { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid };
    this.cellformulas[this.selectedRange[0]][this.selectedRange[1]] = event.target.value;

    var val = event.target.value;
    var v = null;
    var f = parseFloat(val);
    if (isNaN(f))
      v = val
    else
      v = f;

    // angezeigte Daten aktualisieren
    this.cellformulas[cell.row][cell.col] = v;

    this.sheetChange(cell, v, v);
  }

  /// HANDONSTABLE
  cellinfovisible = false;
  afterOnCellMouseOver = async (event, coords, TD) => {

  }
  afterOnCellMouseOut = async (event, coords, TD) => {
    //  console.log("afterOnCellMouseOut", event, coords, TD);
  }

  copiedformulas: string;
  cutcopyRange: any;
  afterCopy = async (data) => {
    var sr = this.selectedRange[0];
    var er = this.selectedRange[2];
    if (this.selectedRange[2] < this.selectedRange[0]) {
      sr = this.selectedRange[2];
      er = this.selectedRange[0];
    }
    var sc = this.selectedRange[1];
    var ec = this.selectedRange[3];
    if (this.selectedRange[3] < this.selectedRange[1]) {
      sc = this.selectedRange[3];
      ec = this.selectedRange[1];
    }
    var cellrange = {
      start: { row: sr, col: sc, sheet: this.sheetid },
      end: { row: er, col: ec, sheet: this.sheetid }
    };

    // var cellrange = {
    //   start: { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid },
    //   end: { row: this.selectedRange[2], col: this.selectedRange[3], sheet: this.sheetid }
    // };
    this.cutcopyRange = cellrange;
    var copyresult = await this.excels[this.PROJECT].copy(cellrange);

    return true;

  }
  isCutting = false;
  cutvalues: any;
  beforeCut = (dat, coords) => {
    console.log("beforecut", dat, coords);



    this.isCutting = true;
    return true;
  }
  afterCut = async (data, coords) => {
    console.log("aftercut", data, coords);
    var cellrange = {
      start: { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid },
      end: { row: this.selectedRange[2], col: this.selectedRange[3], sheet: this.sheetid }
    };

    this.cutcopyRange = cellrange;
    // TODO: sonderfaelle

    // copy 2d array from cellformulas to cutvalues
    const startRow = this.selectedRange[0];
    const endRow = this.selectedRange[2];
    const startCol = this.selectedRange[1];
    const endCol = this.selectedRange[3];
    this.cutvalues = [];

    //var test = this.cellformulas[this.selectedRange[0]][this.selectedRange[1]];
    //this.cutvalues = JSON.parse(JSON.stringify(await this.excels[this.PROJECT].getRangeSerialized(cellrange)));
    console.log('afterCut cutvalues', this.cutvalues);
    var cutresult = await this.excels[this.PROJECT].cut(cellrange);

    //  var test = await this.excels[this.PROJECT].paste({ row: 10, col: 2, sheet: this.sheetid });


    var cellranget = {
      start: { row: 10, col: 2, sheet: this.sheetid },
      end: { row: 15, col: 5, sheet: this.sheetid }
    };
    var vals = await this.excels[this.PROJECT].getRangeSerialized(cellranget);
    console.log('vals', vals);

    this.isPasting = true;
    this.isCutting = true;
    return true;
  }

  pastedresult = null;
  isPasting = false;
  beforePaste = (dat, coords) => {
    console.log("beforePaste", this.isCutting, dat, coords, this.cutvalues);
    this.isPasting = true;

    return true;
  }
  afterPaste = async (data, coords) => {

    console.log("afterPaste", data, coords, this.cutvalues);

    // todo: olddata
    var olddata = null;
    var topleft = { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid };

    try {
      if (this.isCutting && false) {
        pasteresult = await this.excels[this.PROJECT].paste(topleft); // nach cut seltsamerweise "nothing to paste"

        //     var pasteresult = await this.excels[this.PROJECT].paste(topleft);
        //   var formulas = await this.excels[this.PROJECT].getRangeFormulas(this.cutcopyRange);

        /*    var formulas = this.cutvalues;
           await this.excels[this.PROJECT].setCellContents(topleft, formulas);
   
           for (let i = 0; i < formulas.length; i++) {
             const formulaRow = formulas[i];
             for (let j = 0; j < formulaRow.length; j++) {
               const formula = formulaRow[j];
               const targetRow = topleft.row + i;
               const targetCol = topleft.col + j;
               if (!this.cellformulas[targetRow]) {
                 this.cellformulas[targetRow] = [];
               }
               this.cellformulas[targetRow][targetCol] = formula;
             }
           } */
        //this.cellformulas[pasteresult[i].address.row][pasteresult[i].address.col] = name;


        // set cutvalues to excels
        //        await this.excels[this.PROJECT].setCellContents(topLeftCornerAddress: SimpleCellAddress, cellContents: RawCellContent[][]


        //        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = name;
        this.isCutting = false;
        this.isPasting = false;
        navigator.clipboard.writeText('').then(function () { // TTODO: ggf "null" workaround, so das keine leeren zellen gepastet werden können
        }, function (err) {
        });
        this.cutvalues = null;
        //  console.log('pasteresult', pasteresult, formulas);
        return;
      }
      // fehler=
      var pasteresult = null; //
      // try {
      pasteresult = await this.excels[this.PROJECT].paste(topleft); // nach cut seltsamerweise leer

      //} catch (err1) {

      //      }


      console.log('pasteresult', pasteresult);
      this.pastedresult = [];
      for (var i = 0; i < pasteresult?.length; i++) {
        var s2 = await this.excels[this.PROJECT].getCellSerialized(pasteresult[i].address);

        // replace name in first col -------------------
        if (pasteresult[i][0] && pasteresult[i][1]) {
          pasteresult[i][0] = pasteresult[i][1] + (topleft.row + i);
        }
        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = pasteresult[i].newValue;
        this.cellformulas[pasteresult[i].address.row][pasteresult[i].address.col] = s2;
        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = pasteresult[i].newValue;
        if (pasteresult[i].address.col == 0 && pasteresult[i].newValue != '#') {
          var t = await this.excels[this.PROJECT].getCellSerialized({ sheet: pasteresult[i].address.sheet, row: pasteresult[i].address.row, col: 1 })

          var t = Utils.remove_numbers_at_the_end(pasteresult[i].newValue);
          var name = t + (pasteresult[i].address.row + 1);
          this.excels[this.PROJECT].setCellContents({ sheet: pasteresult[i].address.sheet, row: pasteresult[i].address.row, col: 0 }, name);
          this.cellformulas[pasteresult[i].address.row][pasteresult[i].address.col] = name;
          this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = name;
        }
        // ---------

        //        this.dataset[this.pastedresult.address.row][this.pastedresult.address.col] = await this.excels[this.CONFIGURATION].getValueFromCell(this.pastedresult.address);

        console.log('cellformulas set', s2)



      }

      this.applyCellMeta();
      this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
      await this.updateModel(pasteresult, olddata, this.scene);
      if (this.isEditMode)
        this.hot.render();

    }
    catch (e) {
      console.warn(e);
      console.log('external copy?! ');
      try {

        // externe kopie => cell updates
        for (var i = parseInt(this.selectedRange[0]); i <= parseInt(this.selectedRange[2]); i++) {
          for (var j = parseInt(this.selectedRange[1]); j <= parseInt(this.selectedRange[3]); j++) {
            var di = i - parseInt(this.selectedRange[0]);
            var dj = j - parseInt(this.selectedRange[1]);
            if (data[di][dj] != null) {
              //   this.cellformulas[i][j] = data[di][dj]; // TODO: formeln copy format von excel?
              var exportedchagnes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: i, col: j }, data[di][dj]);

              for (var k = 0; k < exportedchagnes.length; k++) {
                var s2 = await this.excels[this.PROJECT].getCellSerialized(exportedchagnes[k].address);
                this.cellformulas[exportedchagnes[k].address.row][exportedchagnes[k].address.col] = s2;
                this.dataset[exportedchagnes[k].address.row][exportedchagnes[k].address.col] = exportedchagnes[k].newValue;
              }
              //this.dataset[i][j] = data[di][dj];
            }

          }
        }
      } catch (err) {
        console.error(err);
      }



      ///  this.sheetChange(topleft, this.cellformulas);


    }
    if (this.isEditMode)
      this.hot.render();

    this.isPasting = false;
    this.isCutting = false;

    this.pastedresult = null;
    return true;
  }
  selectedRange: any;
  afterSelectionEnd = async (y, x, y2, x2) => {
    // TODO: strg-Taste
    this.selectedRange = [y, x, y2, x2];
    // console.log('after selection end ', y, x, y2, x2)
    //    this.info = "cell:" + a + " " + b + " " + c + " " + d;
    this.handleObjectSelectionByRows(this.curSelectedRow);
    this.refs = [];
    this.applyCellMeta();
  }

  beforeChange = (a) => {
    //console.log('beforeChange', a)
    this.keydown = null;
  }
  afterChange = async (a) => {

    this.editcell = null;

    if (this.autofilling || this.isPasting || this.isCutting) // || iscopying TODO??? warum hier return???
      return;
    console.log("after change ", a)

    if (a == null) return;

    var topleft = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
    //  if (!this.isPasting) this.excels[this.PROJECT].setCellContents(topleft, a[0][3]);

    // änderung zu 2d array TODO: geht vermutlich nicht immer
    var newdata = [];
    var olddata = [];
    // 0:    (4)[33, 2, 'radiusTop', null]
    // 1:    (4)[33, 3, 'radiusBottom', null]
    // 2:    (4)[34, 2, 66, null]
    // 3:    (4)[34, 3, 40, null]
    var r = []; var r2 = [];
    var currow = topleft.row;
    for (var i = 0; i < a.length; i++) {
      if (a[i][0] == currow) {
        if (a[i][3] == null) a[i][3] = '';
        r.push(a[i][3]);
        //    r2.push(a[i][2]);
        olddata.push({ address: { row: a[i][0], col: a[i][1], sheet: this.sheetid }, oldValue: a[i][2] });
      }
      else {
        newdata.push(r);
        r = [];
        currow = a[i][0];
        // neue zeile
        if (a[i][3] == null) a[i][3] = '';
        r.push(a[i][3]);
        olddata.push({ address: { row: a[i][0], col: a[i][1], sheet: this.sheetid }, oldValue: a[i][2] });
      }
    }
    newdata.push(r);

    //////////////
    // snippet?
    if (a?.length == 1) {
      try {

        var row = a[0][0];
        var col = a[0][1];
        var typ = a[0][3];
        var cell = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
        var meta = this.hot.getCellMeta(cell.row, cell.col);
      } catch (error) {

      }

      // ---------------- snippet ----------------
      if (meta?.type == "autocomplete") {
        console.log('autocomplete fll');

        var newsnippet = null;
        for (var i = 0; i < this.snippetManager.snippets.length; i++) {
          var snippet = this.snippetManager.snippets[i];
          if (snippet.name == typ) {
            newsnippet = this.snippetManager.snippets[i];
            break;
          }
        }
      }

      // sheet?
      if (typ?.indexOf("sheet: ") >= 0) {
        // get snippet from excel
        var sheetname = typ.split(": ")[1];
        var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
        newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
      }
      // TODO: subproject?

      if (newsnippet) {
        // TODO: könnte ggf auch andere Zellen ändern?
        // primitive,sheet
        if (this.isInList(this.autocompleteListPrimitives, typ) || typ.indexOf("sheet: ") >= 0) {
          var outputid = typ + "" + (parseInt(row) + 2);
          this.dataset[row][0] = '#';
          this.cellformulas[row][0] = '#';
          this.dataset[row + 1][0] = outputid;
          this.cellformulas[row + 1][0] = outputid;
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, '#');
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row + 1, col: 0 }, outputid);
        }
        // control
        if (this.isInList(this.autocompleteListControls, typ)) {
          var outputid = typ + "" + (parseInt(row) + 1);
          this.dataset[row][0] = outputid;
          this.cellformulas[row][0] = outputid;
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, outputid);
        }

        console.log('call sheetshange ')
        this.sheetChange(cell, newsnippet.values, olddata, true);

        this.applyCellMeta();
        return; // automplete dn
      }

    }
    // ---------------- snippet ende ----------------


    /// normale änderung ----------------------------------------

    // floats parsen
    for (var i = 0; i < a?.length; i++) {
      var cell = { row: a[i][0], col: a[i][1], sheet: this.sheetid };
      var val = a[i][3];
      var v = null;
      var f = parseFloat(val);
      if (isNaN(f))
        v = val
      else
        v = f;
      // angezeigte Daten aktualisieren, nötig?
      //      this.cellformulas[cell.row][cell.col] = v;
    }

    var topleft = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
    await this.sheetChange(topleft, newdata, olddata, false, false);
    console.log('sheetChange from afterchange', olddata, newdata)
    if (this.isEditMode)
      this.hot?.render();
  }

  // TODO: olddata vorher bei allen setzen, weil das auch elemente löschen kann
  async sheetChange(topleft, data, olddata, isSnippet = false, debounce = true) {
    //  console.log('sheetChange', topleft, data);

    // TODO: alle sammeln?
    let data1 = {
      topleft: topleft,
      data: data,
      olddata: olddata,
      isSnippet: isSnippet
    }

    if (debounce)
      this.valueSubject.next(data1); // TODO: achtung! mehrere änderungen hinterneinader werden "verschluckt" nur bei änderung von gui bzw. gleichem param, sonst immer
    else
      await this.sheetChangeWrapper(data1)
  }

  async sheetChangeWrapper(datas) {
    var topleft = datas?.topleft;
    var data = datas?.data;
    var olddata = datas?.olddata;

    if (!datas.topleft) return;
    //   if (data) {
    // console.log('sheetChangeWrapper data', datas);
    var isSnippet = datas?.isSnippet;
    // daten entfernt
    // if (topleft?.col == 1 && !data) {
    //   var outputrow = this.getOutputRow()
    //   if (topleft.row < outputrow) {
    //   } else {
    //   }
    //   //   return;
    // }

    // snippets PLatzhalter durhc Zellen ersetzen
    if (isSnippet) {
      try {
        if (data) {

          var replacedData = JSON.parse(JSON.stringify(data));
          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].length; j++) {
              if (data[i][j]) {
                // bisher Zelle Spalte -1 standard ersatz für ##
                if (typeof data[i][j] === 'string')
                  if (data[i][j].indexOf("##") > -1) {
                    var cellstr = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: i + topleft.row, col: j + topleft.col - 1 }, this.sheetid);
                    replacedData[i][j] = data[i][j].replace(/##/g, cellstr);
                    //   data[i][j] = data[i][j].replaceAll('##', cellstr);


                  }
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (!replacedData) replacedData = datas.data;


    // TODO: nicht anwenden bei copy pastE?!
    if (this.pastedresult) {

      //      this.pastedresult.push({ address: pasteresult[i].address, val: s2 });
    }
    else {

      if (this.isMainConfig) {
        var exportedchanges = await this.excels[this.PROJECT].setCellContents(topleft, replacedData);
        // update handsontable
        if (exportedchanges)
          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            // console.log('update handasonstable ', cellAddress, cellValue);
            if (cellAddress)
              this.dataset[cellAddress.row][cellAddress.col] = cellValue;
          }
        if (Array.isArray(data)) {
          for (var i = 0; i < replacedData.length; i++) {
            for (var j = 0; j < replacedData[i].length; j++) {
              if (replacedData[i][j] != null) {
                this.cellformulas[i + topleft.row][j + topleft.col] = replacedData[i][j]; // TODO: formeln copy format von excel?
              }
            }
          }

        }
        this.applyCellMeta();
        if (exportedchanges)
          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            // console.log('update handasonstable ', cellAddress, cellValue);
            // if (cellAddress?.col == 2 || cellAddress?.col == 3) {
            try {
              var m = this.hot.getCellMeta(cellAddress.row, cellAddress.col);
              this.hot.setCellMeta(cellAddress.row, cellAddress.col, 'className', "pulsing " + m.className);
            } catch (error) {

            }
            //}

          }

        if (this.isEditMode)
          this.hot.render();
        // update model ------------------------

        try {
          console.log('update model')
          await this.updateModel(exportedchanges, olddata, this.scene);
        } catch (e) {
          console.error(e);
        }

        // update controls
        // if (topleft.col != 2) {
        try {
          console.log('update controls')
          await this.updateControls(exportedchanges, topleft);
        } catch (e) {
          console.error(e);
        }

        // var outputrow = this.getOutputRow(this.dataset);#
        // if (datas < outputrow) {
        //   this.excels[this.project].createJsonGUI(this.sheetid, this.dataset, this.cellformulas);
        // }
        //        }
      }

      else {
        // subconfig update
        var exportedchanges = await this.excels[this.selectedSubprojectID].setCellContents(topleft, replacedData);
        if (exportedchanges) {

          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            this.dataset2[cellAddress.row][cellAddress.col] = cellValue;

            // 
            var outputrow = this.getOutputRow(this.dataset2);
            for (var y = 1; y < outputrow; y++) {
              for (var k = 0; k < this.selectedNode.userData.commentrow.length; k++)
                if (this.dataset2[y][0] != "")
                  if (this.dataset2[y][0] == this.selectedNode.userData.commentrow[k]) {
                    this.selectedNode.userData.row[k] = this.dataset2[y][2];
                  }
            }
          }
          await this.updateModel(exportedchanges, olddata, this.selectedNode);
        }
      }
    }

    this.updateThreeFrame();
    this.changesQueue = [];
  }


  showcodemenu = false;
  addCodeSnippet(s: any) {
    if (!this.project.scriptcode) this.project.scriptcode = "";
    this.project.scriptcode += s.code;
  }

  // TODO: neuer Ansatz: addProject => excel laden, falls nicht ex., alles Projekte in metainfos speichern und beim Start parallel laden, weitere mögliche Optimierung: mehrmals gleiches Projekt laden?!

  // async getExcel(project): Promise<any> {
  //   var toload = [];
  //   toload.push(project);
  //   var promises = [];
  //   for (var i = 0; i < toload.length; i++) {
  //     const w = new Worker(new URL('./../app.worker', import.meta.url), { type: 'module' });
  //     this.workers.push(w);
  //     this.excels[toload[i]] = Comlink.wrap(this.workers[i]) as any;
  //     var sheets = [{ name: "Sheet 1" }, { came: "Sheet 2" }, { name: "Sheet 3" }, { name: "Sheet 4" }, { name: "Sheet 5" }]; // TODO aus projekt abgleichen oder über indices
  //     var p = this.excels[toload[i]].load(toload[i], sheets);
  //     promises.push(p);
  //   }

  //   var t0 = performance.now();
  //   var promisesresult = await Promise.all(promises).catch(reason => {
  //     console.error(reason)
  //   });
  //   console.log('promisesresult', promisesresult);
  //   var t1 = performance.now();
  //   console.log("webworker loads took " + (t1 - t0) + " milliseconds.");
  //   this.PROJECT = toload[0];
  //   var dataset = promisesresult[0].cells;

  //   var jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
  //   var projectid = project.split('_')[1];
  //   var newsnippet = this.projectGUI2Snippet(jsongui, projectid, this.curSelectedRow);
  //   var cell = { row: this.curSelectedRow, col: 1, sheet: this.sheetid };
  //   this.sheetChange(cell, newsnippet.values, newsnippet.values, true);
  //   this.dataset[this.curSelectedRow][0] = '#';
  //   this.cellformulas[this.curSelectedRow][0] = '#';
  //   var outputid = "project" + "" + (this.curSelectedRow + 2);
  //   this.dataset[this.curSelectedRow + 1][0] = outputid;
  //   this.cellformulas[this.curSelectedRow + 1][0] = outputid;

  // }


  async addProject(findemptyrows = false, insertpos = null) {
    const dialogRef = this.dialog.open(DialogsubprojectComponent, {
      width: '980px',
      //   data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (result.publicCategoryID) {
          var projectid = result.id;
          var pr = (await this.afs.doc("/projectspublic/" + projectid).get().toPromise());
          var newconfig = pr.data() as any;
          var project = newconfig.userid + '_' + projectid;
        } else {

          var projectid = result.id;
          var project = this.customerid + '_' + projectid
          var pr = (await this.afs.doc("/projects/userprojects/" + this.customerid + "/" + projectid).get().toPromise());
          var newconfig = pr.data() as any; //.valueChanges().subscribe(data => {
          newconfig.id = pr.id;
        }


        if (!this.project.configurations) this.project.configurations = {};
        this.project.configurations[this.curSelectedRow] = newconfig;

        if (this.excels[project]) {
          //  return this.excels[configuration];
        } else {
          var toload = [];
          toload.push(project);

          // alle unterkonfigs
          await traverse(newconfig.configmodel).forEach(function (value) {
            if (typeof (value) === "object" && value?.subproject) {
              toload.push(value.subproject);
            }
          });
          // delete duplicates from array
          toload = toload.filter(function (item, pos) {
            return toload.indexOf(item) == pos;
          }).sort();
          console.log("project data", pr);
          var promises = [];
          for (var i = 0; i < toload.length; i++) {
            const w = new Worker(new URL('./../app.worker', import.meta.url), { type: 'module' });
            this.workers.push(w);
            this.excels[toload[i]] = Comlink.wrap(w) as any;
            var p = this.excels[toload[i]].load(toload[i], newconfig.sheets); // TODO sheets von unterconfig
            promises.push(p);
          }

          var t0 = performance.now();
          var promisesresult = await Promise.all(promises).catch(reason => {
            console.error(reason)
          });
          console.log('promisesresult', promisesresult);
          var t1 = performance.now();
          console.log("webworker loads took " + (t1 - t0) + " milliseconds.");
        }

        // this.dataset = promisesresult[0].cells;
        // this.datasetids = promisesresult[0].datasetids;
        // this.cellformulas = promisesresult[0].cellvalues;
        //this.CONFIGURATION = configuration;
        //var dataset = promisesresult[0].cells;
        //   this.applyCellMeta();

        // var jsongui = await this.excels[project].createJsonGUI(0);
        // var newsnippet = this.projectGUI2Snippet(jsongui, projectid, this.curSelectedRow);

        var sheetname1 = await this.excels[project].getSheetNames();
        var newsnippet = await this.excels[project].getSnippet(sheetname1[0]);
        var outputid = "project" + "" + (this.curSelectedRow + 2);
        // 
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: this.curSelectedRow, col: 0 }, '#');
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: this.curSelectedRow + 1, col: 0 }, outputid);

        newsnippet.values[0][0] = '';
        newsnippet.values[1][0] = 'project: ' + project; // deprecated + projectid; // TODO:ggf mit userid? also project?
        /// TODO: insertpoint, empty insertrow
        var cell = { row: this.curSelectedRow, col: 1, sheet: this.sheetid };
        this.sheetChange(cell, newsnippet.values, newsnippet.values, true);
        this.dataset[this.curSelectedRow][0] = '#';
        this.cellformulas[this.curSelectedRow][0] = '#';
        this.dataset[this.curSelectedRow + 1][0] = outputid;
        this.cellformulas[this.curSelectedRow + 1][0] = outputid;

        this.applyCellMeta();
        this.createModel(this.scene, this.dataset, this.PROJECT);

        // TODO: add project model
        this.loadSheet = false;
        //    console.log("jsongui", this.jsongui);
      }
    });
  }

  openLink(link) {
    window.open(link, "_blank");
  }

  openDisclaimer() {
    console.log('openDisclaimer');
    const dialogRef = this.dialog.open(DialogdisclaimerComponent, {
      width: '880px',
      data: { disclaimer: this.project.disclaimer },
    });

  }
  projectGUI2Snippet(jsongui, projectid, curSelectedRow) {
    var result = {
      name: "label",
      values: [
        [""],
        ["project: " + projectid],
        // ["", "p1", "p2", "width", "material", "x", "y", "z", "rx", "ry", "rz"],
        // ["line", "(0,0,50)", "(0,100,50)", 5, "black", 0, 0, 0, 0, 0, 0],      ]
      ],
      format: [
        ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
        ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
      ]
    };
    for (var i = 1; i < jsongui.childs.length; i++) {
      var j = jsongui[i];
      result.values[0].push(jsongui.childs[i].controlName);
      result.values[1].push(jsongui.childs[i].outputValue);
    }

    return result;
  }

  async addPrimitive(typ: string, param = null) {


    var newsnippet = null;
    for (var i = 0; i < this.snippetManager.snippets.length; i++) {
      var snippet = this.snippetManager.snippets[i];
      if (snippet.name == typ) {
        newsnippet = this.snippetManager.snippets[i];
        break;
      }
    }

    // sheet?
    if (typ.indexOf("sheet: ") >= 0) {
      // get snippet from excel
      var sheetname = typ.split(": ")[1];
      var sn = await this.excels[this.PROJECT].getSheetNames();
      var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
      newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
    }


    if (newsnippet) {
      var row = this.curSelectedRow;
      // TODO: könnte ggf auch andere Zellen ändern?
      var outputid = typ + "" + (row + 2);
      // primitive,sheet
      if (this.isInList(this.autocompleteListPrimitives, typ) || typ.indexOf("sheet: ") >= 0) {
        this.dataset[row][0] = '#';
        this.cellformulas[row][0] = '#';
        this.dataset[row + 1][0] = outputid;
        this.cellformulas[row + 1][0] = outputid;
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, '#');
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row + 1, col: 0 }, outputid);
      }

      if (param)
        newsnippet.values[1][1] = param;

      var cell = { row: row, col: 1, sheet: this.sheetid };
      this.sheetChange(cell, newsnippet.values, newsnippet.values, true);

      this.applyCellMeta();
    }
  }
  async addGui(typ: string, param = null) {
    var newsnippet = null;
    for (var i = 0; i < this.snippetManager.snippets.length; i++) {
      var snippet = this.snippetManager.snippets[i];
      if (snippet.name == typ) {
        newsnippet = this.snippetManager.snippets[i];
        break;
      }
    }

    // sheet?
    if (typ.indexOf("sheet: ") >= 0) {
      // get snippet from excel
      var sheetname = typ.split(": ")[1];
      var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
      newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
    }
    // TODO: subproject
    if (newsnippet) {
      var row = this.curSelectedRow;
      // TODO: könnte ggf auch andere Zellen ändern?
      var outputid = typ + "" + (row + 1);

      // control
      if (this.isInList(this.autocompleteListControls, typ)) {
        this.dataset[row][0] = outputid;
        this.cellformulas[row][0] = outputid;
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, outputid);

        if (param) {
          newsnippet.values[0][1] = param;
        }

      }

      var cell = { row: row, col: 1, sheet: this.sheetid };
      this.sheetChange(cell, newsnippet.values, newsnippet.values, true);

      this.applyCellMeta();
    }
  }
  beforeAutoFillRange: SimpleCellRange;
  autofilling = false;
  beforeAutoFill = (changes, source) => {
    this.beforeAutoFillRange = this.selectedRange;
    console.log('beforeAutoFill changes', changes, 'source', source, 'selectedrange', this.selectedRange);
    this.autofilling = true;
  }
  afterAutoFill = async (startcellfill, endcellfill) => {
    // reihenfolger events(hf): beforeautofill, beforechange, afterchagne, (verzögert sheetchange), afterauffill,  sheetchange decoubnce,
    console.log('afterAutoFill startcellfill', startcellfill, 'endcellfill', endcellfill, 'beforefillautrange', this.beforeAutoFillRange);
    console.log('afterAutoFill startcellfill', startcellfill, 'endcellfill', endcellfill, 'selectedrange', this.selectedRange);

    var srcrange = { start: { sheet: this.sheetid, row: this.beforeAutoFillRange[0], col: this.beforeAutoFillRange[1] }, end: { sheet: this.sheetid, row: this.beforeAutoFillRange[2], col: this.beforeAutoFillRange[3] } };
    var src = await this.excels[this.PROJECT].getRangeSerialized(srcrange);
    var srct = src[0].map((_, colIndex) => src.map(row => row[colIndex])); // transpose
    var targetrange = { start: { sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, end: { sheet: this.sheetid, row: endcellfill.row, col: endcellfill.col } };
    var targetrowscount = targetrange.end.row - targetrange.start.row + 1;
    var targetcolscount = targetrange.end.col - targetrange.start.col + 1;
    var fill = [];
    for (var i = 0; i < targetrowscount; i++) fill.push([])

    // NaN Fehler,von llinks nach rechts erweitern, tokenisierung
    for (var i = 0; i < targetcolscount; i++) {
      var xs = srct[i].map((e, i) => { return i }); // transpose
      var ys = srct[i];
      for (var j = 0; j < ys.length; j++)
        if (!isNaN(ys[j]))
          // str to float
          ys[j] = parseFloat(ys[j]);
      // TODO: tokenisierung, Cell-references
      // https://stackoverflow.com/questions/3370263/separate-integers-and-text-in-a-string


      var mb = Utils.findLineByLeastSquares(xs, ys);
      if (!isNaN(mb.m)) {
        var fillcol = [];
        for (var j = 0; j < targetrowscount; j++) {
          var autofillrowindex = xs[xs.length - 1] + 1 + j;
          var yi = mb.m * autofillrowindex + mb.b;
          if (isNaN(yi)) yi = ys[0]; /// ggf unterste zelle?
          fillcol.push(yi);
          fill[j].push(yi);
        }
      } else {
        fill = await this.excels[this.PROJECT].getFillRangeData(
          srcrange,
          targetrange
          //  { start: { sheet: this.sheetid, row: 0, col: 0 }, end: { sheet: this.sheetid, row: 1, col: 1 } },
          //  { start: { sheet: this.sheetid, row: 1, col: 1 }, end: { sheet: this.sheetid, row: 3, col: 3 } }
        );

      }


    }

    if (startcellfill.col == 0) {
      for (var j = 0; j < targetrowscount; j++) {
        var t = Utils.remove_numbers_at_the_end(fill[j][0]);
        var name = t + (startcellfill.row + j + 1);
        fill[j][0] = name;
      }
    }
    var r = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, fill);
    this.sheetChange({ sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, fill, fill);

    this.autofilling = false;
    if (this.isEditMode)
      this.hot.render();
  }


  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }


  setCaretToPos(input, pos) {
    this.setSelectionRange(input, pos, pos);
  }
  keydown = null;
  editval = null;
  refcell = null;
  lastcellrefstr = null;
  lastcellvalue = "";
  refs: any;
  afterDocumentKeyDown = (e) => {
    //  console.log('afterDocumentKeyDown', e.key, this.editcell);
    try {
      if (this.editcell) {
        //  var t = this.hot.getDataAtCell(this.editcell.row, this.editcell.col);

        this.keydown = e.key;
        var ce = this.hot.getActiveEditor(); //(this.editcell.row, this.editcell.col);
        var v = ce.getValue();

        //      ce.enableFullEditMode();

        if (e.key == "Tab") {
          this.hot.selectCell(this.editcell.row, this.editcell.col + 1);
          this.refs = null;
          this.applyCellMeta();

          e.preventDefault();
        }

        if (v.endsWith('=') || v.endsWith('+') || v.endsWith('-') || v.endsWith('*') || v.endsWith('/') || v.endsWith('&') || v.endsWith('(') || v.endsWith('$') || v.endsWith(this.lastcellrefstr)) {
          if (e.key == 'ArrowUp' || e.key == 'ArrowDown' || e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
            this.refcell = this.editcell;
            if (e.key == 'ArrowUp') this.refcell.row--;
            if (e.key == 'ArrowDown') this.refcell.row++;
            if (e.key == 'ArrowLeft') this.refcell.col--;
            if (e.key == 'ArrowRight') this.refcell.col++;
            if (v.endsWith(this.lastcellrefstr)) {
              // remove lastcellrefstr from v
              var lastl = this.lastcellrefstr.length;
              this.lastcellrefstr = Utils.indices2cellaname(this.refcell.col, this.refcell.row); // await this.excels[this.PROJECT].simpleCellAddressToString(this.refcell, this.sheetid);
              v = v.substring(0, v.length - lastl);
              ce.setValue(v + this.lastcellrefstr);

              this.refs = Utils.getCellReferences(v + this.lastcellrefstr);
              //          this.colorizeCellReferences(refs);
              this.applyCellMeta();
              //   return
            }
            else {
              this.lastcellrefstr = Utils.indices2cellaname(this.refcell.col, this.refcell.row); // await this.excels[this.PROJECT].simpleCellAddressToString(this.refcell, this.sheetid);
              ce.setValue(v + this.lastcellrefstr);
              this.refs = Utils.getCellReferences(v + this.lastcellrefstr);
              //    this.colorizeCellReferences(refs);
              this.applyCellMeta();

              // return;
            }

            v = ce.getValue();
            var txtarea = document.getElementsByClassName('handsontableInput')[0] as any;

            this.setCaretToPos(txtarea, v.length);
            e.preventDefault();
            e.stopImmediatePropagation();
          } else {
            this.refcell = null;
            this.applyCellMeta();
            return;
          }
        } else {
          if (e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
            //  e.preventDefault();
            //      this.hot.selectCell(this.editcell.row, this.editcell.col);

            if (!v)
              this.hot.selectCell(this.editcell.row, this.editcell.col);

            this.refs = null;
            return;
          }
          if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
            // e.preventDefault();
            this.hot.selectCell(this.editcell.row, this.editcell.col);
            this.refs = null;
            return;
          }
          this.refs = null;
          this.applyCellMeta();
          if (e.key == 'Enter') {

          }
          else {
            e.stopImmediatePropagation();
          }
        }



      }
      else
        if (e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowLeft' && e.key != 'ArrowRight' && e.key != 'Enter') {
          var ce = this.hot.getActiveEditor();

          if (ce) {

            ce.enableFullEditMode();
            this.lastcellvalue = e.key;
            this.keydown = e.key;
            ce.setValue(this.lastcellvalue);
          }

        }
      if (e.key == 'Enter') {
        this.refs = null;
        this.applyCellMeta();
      }

    } catch (e) {
      console.warn("afterDocumentKeyDown", e);
    }
  }

  editcell = null; editcelltop = null;
  afterBeginEditing = async (row, col) => {
    try {
      console.log('afterBeginEditing', row, col);

      this.editcell = { sheet: this.sheetid, row: row, col: col };

      var el = document.getElementsByClassName('handsontableInputHolder')[0] as any;

      this.editcelltop = parseInt(el.style.top.replace("px", ""));

      var serializeddata = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: row, col: col });
      //    console.log('serializeddata', serializeddata)
      if ((!this.keydown || this.keydown == "Enter" || this.keydown == "Tab") && serializeddata != "" && serializeddata != null) {
        //    console.log('if', serializeddata);

        this.dataset[row][col] = serializeddata;
        if (this.isEditMode) {

          this.hot.getActiveEditor()?.setValue(serializeddata);
          this.hot.render();
          this.refs = Utils.getCellReferences(serializeddata);;
          this.applyCellMeta();
        }
      }
      // afterediting --> beforechange
    } catch (e) {
      console.warn("afterBeginEditing", e);
    }


  }
  afterDeselect = () => {
    console.log('afterDeselect');
    this.editcell = null;
    this.keydown = null;
  }

  afterSetDataAtRowProp(changes, source) {
    console.log('afterSetDataAtRowProp', changes, source, this.selectedRange);
  }
  viewHelper: any; composer: any; outlinePass: any; renderPass: any;
  setupthreejs() {
    var threecontainer = document.getElementById("threecontainer");
    this.camera = new THREE.PerspectiveCamera(75, threecontainer.clientWidth / threecontainer.clientHeight, 1, 20000);
    //    container.appendChild(this.renderer.domElement);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    console.log('threecontainer', threecontainer.clientWidth);
    this.renderer.setSize(threecontainer.clientWidth, threecontainer.clientHeight);
    this.renderer.shadowMap.enabled = true; // https://threejsfundamentals.org/threejs/lessons/threejs-shadows.html
    this.renderer.shadowMap.autoUpdate = true;
    this.renderer.shadowMap.needsUpdate = false;
    //    this.renderer.shadowMap.
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.camera.position.z = 2500;
    this.camera.position.x = 1500;
    this.camera.position.y = 2000;

    var container = new UIPanel();
    container.setId('viewport');
    container.setPosition('absolute');
    // container.add(new ViewportCamera(editor));
    // container.add(new ViewportInfo(editor));
    //

    //    this.viewHelper = new ViewHelper(this.camera, container);
    // this.stats = new Stats();
    //this.stats.showPanel(0);
    // stats.domElement.style.position = 'absolute';
    // stats.domElement.style.left = '0';
    // stats.domElement.style.top = '0';
    this.clock = new THREE.Clock();
    this.camera.up.set(0, 0, 1);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.enableRotate = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;



    //  this.composer = new EffectComposer(this.renderer);
    // var size = this.renderer.getSize();
    var pixelRatio = this.renderer.getPixelRatio();
    //  this.composer.setSize(window.innerWidth, window.innerHeight);
    //  this.renderPass = new RenderPass(this.scene, this.camera);
    var selectedObjects = [this.cube1]

    //  this.composer.addPass(this.renderPass);
    // this.composer.addPass(this.outlinePass);
    //this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    // TODO: erst nach project und nach viewinit
    //    this.leftpanelwidth = 40 * window.innerWidth / 10;;
    //  this.threecontainerwidth = window.innerWidth - 40 * window.innerWidth / 10;;
    // console.log('resize', this.threecontainerwidth, threecontainer.clientHeight)
    // this.camera.aspect = this.threecontainerwidth / threecontainer.clientHeight;
    // this.camera.updateProjectionMatrix();
    // this.renderer.setSize(this.threecontainerwidth, threecontainer.clientHeight);
    // this.updateThreeFrame();

    // nav axesHelpervar 
    // var container1 = new UIPanel(threecontainer);
    // container1.setId('vhcontainer');
    // container1.setPosition('absolute');
    // //      container1.add(new ViewportCamera(editor));
    // container1.add(this.camera);
    // //   var container2 = document.getElementById("vhcontainer");
    // var controls = new EditorControls(this.camera, container1);
    // // controls.addEventListener('change', function () {

    // //   // signals.cameraChanged.dispatch(camera);
    // //   // signals.refreshSidebarObject3D.dispatch(camera);

    // // });
    // var viewHelper = new ViewHelper(this.camera, threecontainer);
    // viewHelper.controls = this.controls;
    // console.log('viewheler', viewHelper)
  }

  zoomall() {
    //ThreeUtils.pointCameraTo(this.camera, this.controls, this.selectedObject);
    ThreeUtils.zoomObject(this.camera, this.controls, this.selectedObject);
    this.updateThreeFrame();
  }

  threecontainerwidth = 100;
  leftpanelwidth = 200;
  splitleft = 45;
  @ViewChild(SplitComponent) splitEl: SplitComponent
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  async onResize(event: any) {
    console.log('onResize', event);
    // fix for split rendering bug
    if (this.isEditMode)
      this.leftpanelwidth = this.splitEl.displayedAreas[0].size / 100.0 * window.innerWidth;
    else
      this.leftpanelwidth = 0;
    if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
    this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;
    var container = document.getElementById("threecontainer");
    this.camera.aspect = this.threecontainerwidth / container.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.threecontainerwidth, container.clientHeight);
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0x000000, 0.0);

    await this.setVars();

    this.splitleft = this.splitEl.displayedAreas[0].size;
    if (this.isEditMode)
      this.hot?.render();

    if (!this.isEditMode)
      this.splitleft = 0;
    this.splitDragEnd({ sizes: [this.splitleft, 99 - this.splitleft] }).then(() => {
      this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
    });

    if (this.viewportGizmo)
      this.viewportGizmo.left = this.threecontainerwidth - 128; // hack

    this.updateThreeFrame();

    // menu
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    //   var outline_shader = {
    //       uniforms: {
    //         "linewidth": { type: "f", value: 0.3 },
    //       },
    //       vertex_shader: [
    //         "uniform float linewidth;",
    //         "void main() {",
    //         "vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
    //         "vec4 displacement = vec4( normalize( normalMatrix * normal ) * linewidth, 0.0 ) + mvPosition;",
    //         "gl_Position = projectionMatrix * displacement;",
    //         "}"
    //       ].join("\n"),
    //       fragment_shader: [
    //         "void main() {",
    //         "gl_FragColor = vec4( 0.0, 0.0, 1.0, 0.4 );",
    //         "}"
    //       ].join("\n")
    //     };
    //     var outline_material = new THREE.ShaderMaterial({
    //       uniforms: THREE.UniformsUtils.clone(outline_shader.uniforms),
    //       vertexShader: outline_shader.vertex_shader,
    //       fragmentShader: outline_shader.fragment_shader
    //     });

    //     // ----- create a cartoon outline https://blog.mozvr.com/cartoon-outline-effect/
    //     const mat = new THREE.MeshLambertMaterial({
    //       color: 'black',
    //       side: THREE.BackSide,
    //       // depthWrite: false,
    //       // depthTest: false
    //     })
    //     mat.onBeforeCompile = (shader) => {
    //       const token = '#include <begin_vertex>'
    //       const customTransform = `
    //       vec3 transformed = position + objectNormal*2.0;
    // `
    //       shader.vertexShader = shader.vertexShader.replace(token, customTransform)
    //     }
    //     var obj = new THREE.Group()
    //     const c1 = new THREE.Mesh(new THREE.TorusKnotBufferGeometry(600, 100, 100, 20), mat)
    //     const s = 1.03
    //     c1.scale.set(s, s, s)
    //     obj.add(c1)
    //     const c2 = new THREE.Mesh(new THREE.TorusKnotBufferGeometry(600, 100, 100, 20), mat)
    //     const s2 = 0.97
    //     c2.scale.set(s2, s2, s2)
    //     obj.add(c2)
    //     obj.add(new THREE.Mesh(
    //       new THREE.TorusKnotBufferGeometry(600, 100, 300, 20),
    //       new THREE.MeshStandardMaterial({
    //         color: '#bdbdbd',
    //         metalness: 0.4,
    //         roughness: 0.5,
    //         side: THREE.FrontSide
    //       })
    //     ))
    // this.scene.add(c1);
    // this.scene.add(obj);
    // -------------------------------------------------




    // this.outlinePass = new OutlinePass(new THREE.Vector2(threecontainer.clientWidth, threecontainer.clientHeight), this.scene, this.camera, selectedObjects);
    if (this.cube1) {
      // this.cube1.isVisible = false;
      // this.cube1.material = mat;
      // const s = 1.03
      // this.cube1.scale.set(s, s, s)
      // var obj = new THREE.Group()
      // obj.add(this.cube1)
      // obj.add(new THREE.Mesh(
      //   new THREE.TorusKnotBufferGeometry(0.6, 0.1),
      //   new THREE.MeshPhongMaterial({
      //     color: 'yellow',
      //     side: THREE.FrontSide
      //   })
      // ))

      //   this.outlinePass = new OutlinePass(new THREE.Vector2(this.threecontainerwidth, container.clientHeight), this.scene, this.camera, [this.cube1]);
      //   this.outlinePass.renderToScreen = true;
      //   var params = { edgeStrength: 9, edgeGlow: 0, edgeThickness: 5, pulsePeriod: 0, usePatternTexture: false };
      //   this.outlinePass.edgeStrength = params.edgeStrength;
      //   this.outlinePass.edgeGlow = params.edgeGlow;
      //   this.outlinePass.edgeThickness = params.edgeThickness;
      //   this.outlinePass.visibleEdgeColor.set(0xf85cd4);
      //   this.outlinePass.hiddenEdgeColor.set(0x000000);
      //   this.composer.addPass(this.outlinePass);
    }

    // const background = createBackground({ grainScale: 0.0015 }) as any;
    // this.scene.add(background)
    // background.style({
    //   aspect: this.threecontainerwidth / container.clientHeight,
    //   aspectCorrection: true,
    //   scale: 2.5,
    //   colors: [0xff0000, 0x0000ff],
    //   offset: [-0.2, 0.25],
    //   // ensure even grain scale based on width/height
    //   grainScale: 1.5 / Math.min(this.threecontainerwidth, container.clientHeight)
    // })

    // this.threecontainerwidth = window.innerWidth - this.splitEl.displayedAreas[0].size * window.innerWidth / 100;;
    // var container = document.getElementById("threecontainer");
    // this.camera.aspect = this.threecontainerwidth / container.clientHeight;
    // this.camera.updateProjectionMatrix();
    // this.renderer.setSize(this.threecontainerwidth, container.clientHeight);

    //    getVisibleAreaSizes();
  }


  projecthelper: any;
  higlightProject() {
    try {

      if (this.projecthelper) {
        // console.log("remove projecthelper", this.projecthelper);
        this.scene.remove(this.projecthelper);
        this.projecthelper = null;
      }
      if (this.highlightproject) {
        var box = ThreeUtils.getBBox(this.highlightproject);
        //   bbox = bbox.expandByScalar(2.3);
        //   var newhelper = new THREE.Box3Helper(bbox, new THREE.Color(0xFF6600));

        //   console.log("highlightproject", this.highlightproject);
        //  var bbox = new THREE.Box3().setFromObject(this.highlightproject);
        //console.log("bbox", bbox);

        //      var box = new THREE.Box3().setFromObject(this.highlightproject);
        box = box.expandByScalar(10);
        var m2 = ThreeUtils.create3DMarker(box, 50, 5, 20, 0xff0000);
        this.projecthelper = m2;// new THREE.BoxHelper(this.highlightproject, 0xff0000);

        // this.projecthelper.geometry.computeBoundingBox();
        // this.projecthelper.material.transparent = false;
        // this.projecthelper.material.opacity = 1;
        //  this.projecthelper.material.depthTest = false;
        //      this.projecthelper.material.depthWrite = false;

        this.projecthelper.renderOrder = 99999;
        // this.projecthelper.onBeforeRender = function (renderer) {
        //   //renderer.clearDepth();
        // };
        this.projecthelper.userData.isHelper = true;

        this.scene.add(this.projecthelper);
      }
    }
    catch (err) {
      console.warn(err);
    }

  }


  scenemousedown(e: any) {
    this.handleSceneEvent("MouseDown", e);
    this.mousebuttondown = true;
  }

  sceneobjectMouseOver = null;
  sceneobjectSelected = null;
  highlightproject: any = null;
  mousedrag = false;
  mousebuttondown = false;
  scenemousemove(e: any) {
    // mouseMOVE immer aufgerufen, nicht objektbezogen => sceneMouseMove  objectEnter objectSelected

    this.handleSceneEvent("MouseMove", e);
    if (this.mousebuttondown) this.mousedrag = true;
    // handlerootevent
    // params:  deltax deltay deltaz pos lastpos deltapos  xydelta xzdelta yzdelta
  }


  scenemouseup(e: any) {
    this.mousebuttondown = false;
    this.showObjectInfos = false;
    this.gltfNodeBox(null);

    this.handleSceneEvent("MouseUp", e);
  }


  isGltfNode(node) {
    var curnode = node;
    while (curnode) {
      if (curnode.userData?.typ == "gltf") return true;
      curnode = curnode.parent;

    }
    return false;
  }


  highlightNode: any;
  gltfNodeBox(node) {
    if (this.highlightNode) {
      this.scene.remove(this.highlightNode);
      this.highlightNode = null;
    }
    if (node) {
      var bbox = new THREE.Box3().setFromObject(node);


      this.highlightNode = new THREE.BoxHelper(node, 0xff0000);
      this.highlightNode.geometry.computeBoundingBox();
      this.highlightNode.material.transparent = false;
      this.highlightNode.material.opacity = 1;
      this.highlightNode.material.depthTest = false;
      this.highlightNode.material.depthWrite = false;

      this.highlightNode.renderOrder = 99999;
      this.highlightNode.material.depthTest = false;
      this.highlightNode.material.depthWrite = false;
      this.highlightNode.onBeforeRender = function (renderer) { renderer.clearDepth(); };

      this.scene.add(this.highlightNode);
      return bbox;
    }

  }

  sceneobjectSceneSelected = null;
  overinfo = "";
  selectedprojectpath = "root^$0"; hoverObject = null;
  public cursorType = "default";

  handleSceneEvent(type: string, e: any) {
    //  console.log("handleSceneEvent", type, e);
    const canvasTarget = document.getElementById('threecontainer');
    var mouse = new THREE.Vector2();
    mouse.x = (e.layerX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.layerY / canvasTarget.clientHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);
    var intersects = raycaster.intersectObjects(this.scene.children);

    if (intersects.length > 0) {
      var obj = null;
      for (var i = 0; i < intersects.length; i++) {
        if (!intersects[i].object.userData.isHelper) {
          if (intersects[i].object.visible) {
            obj = intersects[i].object;
            break;
          }
        }
      }
      if (!obj) {
        this.highlightproject = nobj;
        console.log('!obj')
        this.higlightProject();
        this.cursorType = "default";
        this.updateThreeFrame();

        return; // todo: javascript wird hier nicht ausgewführt?
      }

      // widget
      /*       if (this.isShiftDown && Object.keys(this.transformControl.object).length == 0 && obj.isMesh) {
              // this.transformControl.reset();
              console.log("transformControl", this.transformControl.object);
              this.transformControl.setSpace("local");//world
              this.transformControl.attach(obj);
              this.transformControl.enabled = true;
              this.transformControl.setMode('translate');
              this.scene.add(this.transformControl);
              this.updateThreeFrame();
              //      return;
            }
            if (Object.keys(this.transformControl.object).length !== 0 && !this.isShiftDown) {
              this.transformControl.enabled = false;
              this.transformControl.detach(this.transformControl.object);
              console.log("detach", this.transformControl.object);
              this.scene.remove(this.transformControl);
            } */


      // gltf infos
      if (this.isGltfNode(obj) && this.isControlDown || this.scenemode == "info") {
        try {

          this.showObjectInfos = true;

          var bb = this.gltfNodeBox(obj);
          console.log("gltf obj", obj, this.isGltfNode(obj), obj.name, intersects[0].point);
          this.hoverObject = JSON.parse(JSON.stringify(obj)).object;
          var m = JSON.parse(JSON.stringify(obj.material));
          this.hoverObject.material = m;
          var m2 = JSON.parse(JSON.stringify(obj.position));
          this.hoverObject.position = m2;
          var normal = intersects[0].face?.normal;

          var w = Math.round(bb.max.x - bb.min.x);
          var h = Math.round(bb.max.y - bb.min.y);
          var d = Math.round(bb.max.z - bb.min.z);

          this.datasetsel = [
            ["Name", obj.name, "", ""],
            ["Position", intersects[0].point.x.toFixed(2), intersects[0].point.y.toFixed(2), intersects[0].point.z.toFixed(2)],
            ["Normal", normal.x.toFixed(2), normal.y.toFixed(2), normal.z.toFixed(2)],
            ["Size", w, h, d]
          ];
        } catch (e) {
          console.warn("gltf infos", e);
        }
        return;

      }

      // gltf node?
      while (obj?.userData?.isGltf) {
        obj = obj.parent;
        parent = obj;
      }

      // project?
      if (obj?.userData?.path?.indexOf("project") > -1) {
        this.overinfo = obj.userData?.path;
        //      console.log("project", obj); //"root^$0#project33^$0#extrusion29" $sheetid^typrow
        var projectpath = "root^$" + this.sheetid + "#" + obj.userData.path.split("^")[1];// TODO: je nach aktueller wahl
        projectpath = projectpath.replace('$' + this.sheetid + '$' + this.sheetid, '$' + this.sheetid);
        var nobj = null;
        if (obj.parent.name == "root") {

          nobj = this.scene.getObjectByName(projectpath);
        }
        else {

          // übergeordnete projekte nicht selektiert?
          var nname = obj.parent.name.replace(this.selectedprojectpath, "");
          if (nname == "") return; // object bereits gewählt
          var n = "#" + nname.split('#')[1].split('^')[0];
          var nname2 = this.selectedprojectpath + n;
          if (!this.selectedprojectpath.endsWith("^$" + this.sheetid))
            nname2 = this.selectedprojectpath + "^$" + this.sheetid + n;
          nobj = this.scene.getObjectByName(nname2);
          if (nobj) {
            //this.selectedprojectpath = nname2;
            obj = nobj;
          }
        }

        //https://chowdera.com/2021/04/20210402055611792p.html
        this.highlightproject = nobj; //.parent;
        this.cursorType = "pointer";

      }
      else {
        if (obj?.type != "LineSegments")
          this.cursorType = "default";
        this.highlightproject = null;
      }

    }
    else {
      this.cursorType = "default";
      this.highlightproject = null;
    }

    this.higlightProject();


    //    if (type == "MouseMove" && this.sceneobjectMouseDown && this.sceneobjectMouseDown == obj) {
    if (type == "MouseMove" && this.sceneobjectSceneSelected) {
      // this.vba.deactivateMouse();
      // var jsfunctiondrag = this.sceneobjectSelected?.userData?.row[this.sceneobjectSelected?.userData?.commentrow.indexOf("Drag")];
      // if (jsfunctiondrag) {
      //   // TODO: es point,distance umrechnen aus Ebene = dragy dragz dragx
      //   // ebene zuvor transformieren oderunterknoten  hinzufügen damit transformationshierarchie stimmt
      //   // alternativ widget von threejs nutzen?
      //   // alternativ dragcontrol? https://threejs.org/docs/#examples/en/controls/DragControls

      //   var es = JSON.stringify(eventinfo);
      //   if (!es) es = "null";
      //   jsfunctiondrag = jsfunctiondrag.replace("event", es);
      //   var ud = this.sceneobjectSelected.userData;
      //   if (ud) ud = JSON.stringify(ud);
      //   else ud = "null";
      //   jsfunctiondrag = jsfunctiondrag.replace("object", ud);
      //   eval(this.project.scriptcode + "  " + jsfunctiondrag + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
      // }
    }
    if (type == "MouseDown" && this.sceneobjectSceneSelected) {
      // this.vba.deactivateMouse();
    }

    //   console.log("mouseleave1", this.sceneobjectMouseOver, obj);
    if (this.sceneobjectMouseOver != obj) {
      // mouseleave 
      var jsfunction2 = this.sceneobjectMouseOver?.userData?.row[this.sceneobjectMouseOver?.userData?.commentrow.indexOf("MouseLeave")];
      if (jsfunction2) {
        var es = JSON.stringify(eventinfo);
        if (!es) es = "null";
        jsfunction2 = jsfunction2.replace("event", es);
        var ud = this.sceneobjectMouseOver;
        if (ud) ud = JSON.stringify(ud);
        else ud = "null";

        globalThis["leavedobject"] = this.sceneobjectMouseOver;
        jsfunction2 = jsfunction2.replace("object", "globalThis['leavedobject']");
        eval(this.project.scriptcode + "  " + jsfunction2 + ";");
      }
      this.sceneobjectMouseOver = null;
    }


    // neue beredchnung
    if (intersects.length > 0) {
      this.updateThreeFrame();
      obj = intersects[0].object;
      if (obj.parent?.parent?.gizmo) return;
      for (var i = 0; i < intersects.length; i++)
        if (!obj.userData?.commentrow)
          if (intersects[i].object.visible)
            obj = intersects[i].object;
      if (obj.parent?.parent?.gizmo) return;

      var parent = obj.parent;
      //   console.log("obj", obj.name);
      var eventinfo = {
        point: intersects[0].point,
        distance: intersects[0].distance,
        normal: intersects[0].face?.normal,
      }



      /*   if (type == "MouseDown") {
          if (this.sceneobjectSceneSelected && this.sceneobjectSceneSelected != obj && !obj.parent?.parent?.gizmo) {
            // deselect
            try {
              console.log("deselected1", this.sceneobjectSceneSelected);
              var jsfunction2b = this.sceneobjectSceneSelected?.userData?.row[this.sceneobjectSceneSelected?.userData?.commentrow.indexOf("DeSelected")];
              if (jsfunction2b) {
                var es = JSON.stringify(e);
                if (!es) es = "null";
                jsfunction2b = jsfunction2b.replace("event", es);
                var ud = this.sceneobjectSceneSelected;
                globalThis["sceneobjectDeSelected"] = ud;
                if (ud) ud = JSON.stringify(ud);
                else ud = "null";
                jsfunction2b = jsfunction2b.replace("object", ud);
                eval(this.project.scriptcode + "  " + jsfunction2b + ";");
              }
            } catch (ex) {
              console.warn(ex);
            }
            this.sceneobjectSceneSelected = null;
          }
        } */

      if (obj?.userData?.commentrow) {
        //   console.log('scenemousemove', obj?.userData?.commentrow);

        if (type == "MouseMove") {
          // Mouse-Handling unabhaening von definierten events auf sceneobjekte ------
          if (this.sceneobjectMouseOver != obj) {

            // mouseenter
            //    console.log("mouseenter", obj);
            var jsfunction3 = obj?.userData?.row[obj?.userData?.commentrow.indexOf("MouseEnter")];
            if (jsfunction3) {
              var es = JSON.stringify(eventinfo);
              if (!es) es = "null";
              jsfunction3 = jsfunction3.replace("event", es);
              var ud = obj;
              var test = this.scene.getObjectByProperty('uuid', obj.uuid);
              globalThis["enteredobject"] = obj;
              if (ud) ud = JSON.stringify(ud);
              else ud = "null";
              jsfunction3 = jsfunction3.replace("object", "globalThis['enteredobject']");
              eval(this.project.scriptcode + " " + jsfunction3 + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
            }
            this.sceneobjectMouseOver = obj;
          }
        }
        if (type == "MouseDown" && !obj.parent?.parent?.gizmo && obj != this.sceneobjectSceneSelected) {
          this.sceneobjectSceneSelected = obj;
          globalThis["sceneobjectSelected"] = obj;
          if (obj?.userData?.commentrow.indexOf("Selected") > -1) {
            var jsfunctions = obj?.userData?.row[obj?.userData?.commentrow.indexOf("Selected")];
            if (jsfunctions) {
              var es = JSON.stringify(eventinfo);
              if (!es) es = "null";
              jsfunctions = jsfunctions.replace("event", es);
              var ud = obj;
              if (ud) ud = JSON.stringify(ud);
              else ud = "null";
              jsfunctions = jsfunctions.replace("object", ud);
              try {

                eval(this.project.scriptcode + "  " + jsfunctions + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
              } catch (ex) {
                console.warn(ex);
              }
            }
            return;
          }
        }

        if (type == "MouseUp") {
          this.vba.activateMouse();
          // this.sceneobjectSelected = null;
          // globalThis["sceneobjectMouseDown"] = null;
        }

        // alle ereignisse von Objekten -----
        if (obj?.userData?.commentrow.indexOf(type) > -1) {

          var jsfunction = obj?.userData?.row[obj?.userData?.commentrow.indexOf(type)];
          if (jsfunction) {
            var es = JSON.stringify(eventinfo);
            if (!es) es = "null";
            jsfunction = jsfunction.replace("event", es);
            var ud = obj;
            if (ud) ud = JSON.stringify(ud);
            else ud = "null";
            jsfunction = jsfunction.replace("object", ud);
            eval(this.project.scriptcode + "  " + jsfunction + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
          }
          return;
        }
      }
      //  }
    } else {
      // keine intersection
      if (this.sceneobjectSceneSelected && type == "MouseUp") {
        // deselect 
        console.log("deselected2", this.sceneobjectSceneSelected);
        var jsfunction2b = this.sceneobjectSceneSelected?.userData?.row[this.sceneobjectSceneSelected?.userData?.commentrow.indexOf("DeSelected")];
        if (jsfunction2b) {
          var es = JSON.stringify(e);
          if (!es) es = "null";
          jsfunction2b = jsfunction2b.replace("event", es);
          var ud = this.sceneobjectSceneSelected;
          globalThis["sceneobjectDeSelected"] = ud;
          if (ud) ud = JSON.stringify(ud);
          else ud = "null";
          jsfunction2b = jsfunction2b.replace("object", ud);
          eval(this.project.scriptcode + "  " + jsfunction2b + ";");
        }
        //  this.sceneobjectMouseOver = null;
      }
      if (this.sceneobjectMouseOver) {
        // mouseleave 
        //  console.log("mouseleave2", this.sceneobjectMouseOver);
        var jsfunction2 = this.sceneobjectMouseOver?.userData?.row[this.sceneobjectMouseOver?.userData?.commentrow.indexOf("MouseLeave")];
        if (jsfunction2) {
          var es = JSON.stringify(e);
          if (!es) es = "null";
          jsfunction2 = jsfunction2.replace("event", es);
          var ud = this.sceneobjectMouseOver;

          if (ud) ud = JSON.stringify(ud);
          else ud = "null";
          jsfunction2 = jsfunction2.replace("object", ud);
          eval(this.project.scriptcode + "  " + jsfunction2 + ";");
        }
        this.sceneobjectMouseOver = null;
      }

    }
    this.updateThreeFrame();
  }


  selectedSubprojectID = null;
  selectedSubprojectPath = null;
  meshes: any;
  isMainConfig = true;
  sheetSelected = false;
  uihead = "";
  async sceneDoubleClick(e) {
    if (this.mousedrag) {
      this.mousedrag = false;
      return;
    }
    console.log('sceneDoubleClick', e);
    this.sheetSelected = false;
    // this.selectedNode = null;
    const canvasTarget = document.getElementById('threecontainer');

    // three js hit test
    var mouse = new THREE.Vector2();
    mouse.x = (e.layerX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.layerY / canvasTarget.clientHeight) * 2 + 1;

    //  mouse.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    //   mouse.y = -(this.mouse.y / window.innerHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);

    var r = this.selectedprojectpath;
    if (this.selectedprojectpath == "root^$" + this.sheetid) r = "root"
    var proobj = this.scene.getObjectByName(r);
    var intersects = raycaster.intersectObjects(proobj?.children);
    var obj = null;
    //    var intersects = raycaster.intersectObjects(this.scene.children);
    if (intersects?.length > 0) {
      let copied = false;
      for (var i = 0; i < intersects?.length; i++) {

        if(intersects[i].object?.userData?.outputid2 && intersects[i].object?.userData?.index){
          const outputid = intersects[i].object.userData.outputid2;
          const index = intersects[i].object.userData.index;
          const textToCopy = outputid+"."+index;
          copied = true;
          await this.excels[this.PROJECT].clearClipboard();

          navigator.clipboard.writeText(textToCopy).then(() => {
            console.log('Text copied to clipboard');
            this.toastr.info( "Copied to clipboard", textToCopy,{ positionClass: 'toast-bottom-right', timeOut: 3000 });
          });
          if (copied) break;
        }
      }

      for (var i = 0; i < intersects?.length; i++) {




        if (!intersects[i].object.userData.isHelper && intersects[i].object.visible) {
          if (intersects[i].object)
            obj = intersects[i].object;

          //  this.cad.visualizeFaceCenters(obj);

          break;
        }
      }
      if (obj) {

        var parent = obj.parent;

        // gltf node?
        while (obj?.userData?.isGltf) {
          obj = obj.parent;
          parent = obj;
        }

        // übergeordnete projekte nicht selektiert?
        var nname = parent.name.replace(this.selectedprojectpath, "");
        if (nname == "") return; // object bereits gewählt

        // object in scene
        if (nname == "root") {
          if (obj.userData.commentrow?.indexOf("ONCLICK") > -1) {
            var index = obj.userData.commentrow.indexOf("ONCLICK");
            var eventname = obj.userData.row[index];
            try {
              var s = eval(this.project.scriptcode + "  " + eventname + ";    console.log('excels. ', this.project)");

            } catch (error) {
              console.log('error', error);

            }

            if (obj.userData.sheetid == this.sheetid) {
              this.hot.selectCell(obj.userData.rowindex, 2);
            }

            return;
          }

          if (obj.userData.sheetid == this.sheetid) {
            this.hot.selectCell(obj.userData.rowindex, 2);
          }
          console.log("obj", obj);

          return;
        }

        // ------------------------

        // project, sheet selected
        var n = "#" + nname.split('#')[1].split('^')[0];
        var nname2 = this.selectedprojectpath + n;
        if (!this.selectedprojectpath.endsWith("^$" + this.sheetid))
          nname2 = this.selectedprojectpath + "^$" + this.sheetid + n;
        var nobj = this.scene.getObjectByName(nname2);
        if (nobj) {
          this.selectedprojectpath = nname2;
          parent = nobj;
        }

        this.scene.traverse((child) => {
          if (child.material) {
            child.material.opacity = 1;
            child.material.transparent = false;
          }
        });


        // project, sheet selected
        if (parent?.userData?.typ?.toUpperCase().startsWith('PROJECT') || parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
          if (parent.userData.sheetid == this.sheetid) {
            this.hot.selectCell(parent.userData.rowindex, 2);
          }

          if (parent?.userData?.typ?.toUpperCase().startsWith('PROJECT')) {
            if (parent.visible) {

              this.selectedNode = parent;
              this.selectedSubprojectID = parent.userData.subproject;
              this.selectedProjectName = parent.name;
              this.selectedSubprojectPath = parent.name;// parent.userData.path;
              var cellpath = await this.vba.getCellPath(this.selectedSubprojectPath); // zur Bestimmung von javascript selected event
              // TODO              (0, eval)(" globalThis." + configid + path + ".project_created()");

              this.isMainConfig = false;
            }
          }
          if (parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
            if (parent?.visible)
              this.selectedNode = parent;
          }

          this.scene.traverse((child) => {

            if (child.material) {

              if (child.userData?.isGltf) {
                child.material.format = THREE.RGBAFormat;
              }
              child.material.transparent = true;
              child.userData.opacity = child.material.opacity;
              child.material.opacity = 0.1;
              // m.material.transparent = false;
              // m.material.depthWrite = true;
              // m.renderOrder = 1;
            }
          });
          this.selectedNode.traverse((child) => {
            if (child.material) {
              if (child.userData.opacity > 0.99)
                child.material.transparent = false;
              if (child.userData.opacity)
                child.material.opacity = child.userData.opacity;
              else
                child.material.opacity = 1;

            }
          });

          this.highlightproject = null;
          this.higlightProject();

          this.updateThreeFrame();
          if (parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
            if (parent.visible) {

              this.sheetSelected = true;
              var sid = this.selectedNode.userData.sheetid;
              if (parent.userData.sourcesheetid == this.sheetid) {
                this.hot.selectCell(parent.userData.rowindex, 2);
              }
              // TODO: werte aus zeile holen, aber nicht dauerhaft!

              var vals = Utils.mapRowToParams(parent.userData.row, parent.userData.commentrow);
              this.uihead = parent.userData.row[0];
              this.jsongui2 = await this.excels[this.PROJECT].createJsonGUI(sid);
              this.updateThreeFrame();
            }
            return;
          } else {
            // subproject

            // konfigurationswerte setzen
            //  this.selectedNode.userData.row
            //    this.selectedNode.userData.commentrow, this.dataset2);
            await this.excels[this.selectedSubprojectID].setConfiugartionsParams(0, this.selectedNode.userData.commentrow, this.selectedNode.userData.row);
            var subsheet = await this.excels[this.selectedSubprojectID].getSheetByIndex(0);
            this.dataset2 = subsheet.cells;
            this.uihead = this.selectedNode.userData.row[0];

            this.jsongui2 = await this.excels[this.selectedSubprojectID].createJsonGUI(0);
            var newcss = this.project.configurations[this.selectedNode.userData.rowindex - 1]?.csscode; // TODO: sub-subproject
            this.csschange(newcss, true);
            // TODO: css laden für subproject      this.csschange(this.project.csscode);
            //   await this.createModel(this.scene, this.dataset, this.CONFIGURATION); // TODO: paramter ????
            this.updateThreeFrame();

            return;
          }
        }

      } else {

      }
    }

    this.selectedObject = obj;
   // if (obj == null)
   //   this.cad.showFaceCenters(this.selectedObject, this.scene); // removen, sonst über rowselect



    // zurück zum Hauptprojekt
    // werte in zelle setzen, wenn rootproject
    if (this.selectedNode?.parent?.name == "root") {
      try {

        for (var i = 0; i < this.selectedNode?.userData?.row?.length; i++) {
          var row = this.selectedNode?.userData?.row;
          var commentrow = this.selectedNode?.userData?.commentrow;
          var cellval = this.selectedNode.userData.row[i];
          var colindex = -1;
          //        await this.excels[this.PROJECT].setCellContents({ sheet: this.selectedNode.userData.sheetid, row: this.selectedNode.userData.rowindex, col: i }, cellval);
          await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: this.selectedNode.userData.rowindex, col: i }, cellval);
          this.dataset[this.selectedNode.userData.rowindex][i] = cellval;
        }
        if (this.isEditMode)
          this.hot.render();
      } catch (error) {
        console.warn('updateModel', error);
      }
    }

    if (this.selectedObjectHelper)
      this.scene.remove(this.selectedObjectHelper);
    this.selectedNode = this.scene;
    this.selectedSubprojectID = this.PROJECT;
    this.selectedProjectName = null;
    this.selectedSubprojectPath = null;
    this.selectedprojectpath = "root^$" + this.sheetid;
    this.isMainConfig = true;
    this.jsongui2 = null;
    this.scene.traverse((child) => {
      if (child.material) {
        if (child.userData.opacity > 0.99)
          child.material.transparent = false;
        if (child.userData.opacity)
          child.material.opacity = child.userData.opacity;
        //     else
        //       child.material.opacity = 1;
      }
    });

    this.jsongui = await this.excels[this.PROJECT].createJsonGUI(0);
    this.csschange(this.project.csscode);
    this.updateThreeFrame();

    console.log("scene", this.scene)
  }
  gridchange() {
    this.baseScene.axes.visible = this.project.sceneSettings.showGrid;
    this.baseScene.grid.visible = this.project.sceneSettings.showGrid;
    this.updateThreeFrame();
  }

  async logout() {
    var r = await this.auth.signOut();
    console.log('logout', r);
    this.router.navigate(['login']);
  }

  mouseWheel(event) {
    if (this.project.settings.cameraZoomPointer) {
      var container = document.getElementById("threecontainer");
      var offsetleft = window.innerWidth - container.clientWidth;
      var mX = (event.clientX - offsetleft) / (container.clientWidth) * 2 - 1;
      var mY = -event.clientY / container.clientHeight * 2 + 1;
      var vector = new THREE.Vector3(mX, mY, 0.1);
      vector.unproject(this.camera);
      vector.sub(this.camera.position);
      var factor = 8 + this.camera.position.distanceTo(vector) / 40;
      console.log("mousewheel vector", vector, "campos", this.camera.position, this.camera.position.distanceTo(vector));
      if (event.deltaY < 0) {
        this.camera.position.addVectors(this.camera.position, vector.setLength(factor));
        this.controls.target.addVectors(this.controls.target, vector.setLength(factor));
      } else {
        this.camera.position.subVectors(this.camera.position, vector.setLength(factor));
        this.controls.target.subVectors(this.controls.target, vector.setLength(factor));
      }
    }

    this.updateThreeFrame();
  }


  handleAngleChange(angle: number) {
    console.log('Angle changed to:', angle);
    //   this.baseScene.dirLight.position.set(Math.cos(angle) * 2000, Math.sin(angle) * 2000, 1000.75);
    //    this.baseScene.dirLight.position.set(-1, -1.45, 1.75);

    //   this.baseScene.dirLightHelper.parent.updateMatrixWorld();
    // this.baseScene.dirLightHelper.update();
    this.project.sceneSettings.dirAngle = angle;
    this.updateScene(null);
    this.updateThreeFrame();
  }

  changeHandling() {
    console.log('changeHandling', this.project.settings.subprojectSelectable, this.project.cameraPan, this.project.settings.midpontNavigation);
    if (this.project.settings.subprojectSelectable) {
    }
    // if (this.chkCameraPan) {
    this.controls.enablePan = this.project.settings.cameraPan;


    this.controls.enableZoom = this.project.settings.cameraZoom;
  }
  // outlinePass: OutlinePass;
  // composer: EffectComposer;
  animate(time) {
    if (this.project?.sceneSettings?.showAnimations) {
      window.requestAnimationFrame(() => {
        var delta = this.clock.getDelta();
        this.scene.traverse(function (node) {
          //if (node instanceof THREE.GLTF) {
          if (node?.userData != {})
            if (node?.userData?.mixer)
              node.userData.mixer.update(delta);
          // }
        });


        if (!this.iscreating) {
          //          this.camera.aspect = this.threecontainerwidth / this.threecontainerheight;
          this.camera.updateProjectionMatrix();
          this.controls.update(delta);
          this.renderer.render(this.scene, this.camera);
          this.viewportGizmo?.update();
          this.viewportGizmo?.render();

        }
        this.animate(time);
      }
      );
    }
    // this.mesh.rotation.x += 0.01;
    // this.mesh.rotation.y += 0.02;
    // this.mesh.position.x = 300;

    // this.raycaster = new THREE.Raycaster();

    // this.raycaster.setFromCamera(this.mouse, this.camera);
    // this.scene.traverse(function(node) {
    //   if (node instanceof THREE.Mesh) {
    //     node.material = new THREE.MeshNormalMaterial();
    //   }
    // });

    // // calculate objects intersecting the picking ray
    // var intersects = this.raycaster.intersectObjects(this.scene.children, true);
    // for (var i = 0; i < intersects.length; i++) {
    //   console.log("intersects" + i, intersects[i]);
    //   intersects[i].object.material = new THREE.MeshStandardMaterial(); //color.set(0x00ff00);
    //   break;
    // }


    // https://www.npmjs.com/package/three-outlinepass
    // if (this.selectedobject) {
    //   this.outlinePass.selectedObjects = [this.selectedobject];
    //   this.composer.render(this.scene, this.camera)
    // } else {
    // }

    //  this.renderer.render(this.scene, this.camera);

    // this.stats.update();
  }

  takeScreenshot(overwrite) {
    this.saveproject();

    var b1 = this.scene.fog.color;
    var b2 = this.scene.background;
    // this.scene.fog.color = null;
    this.scene.background = null;
    this.renderer.render(this.scene, this.camera);
    if (!this.project.imageoverwritten || overwrite) {


      this.renderer.setClearColor(0x000000, 0);
      this.renderer.domElement.toBlob((blob) => {
        const storageRef = firebase.storage().ref();
        var imageRef = storageRef.child('thumbnails/' + this.projectid + '.png');
        const uploadTask = imageRef.put(blob);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            const snap = snapshot as firebase.storage.UploadTaskSnapshot;
            console.log('snap', snap);

            // this.scene.fog.color = b1;
            this.scene.background = b2;
            this.updateThreeFrame();
          },
          error => {
            console.log(error);
          },
          () => {

            var url2 = uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              console.log(url)
              this.project.image = url;
              if (overwrite)
                this.project.imageoverwritten = true;

              //              this.saveproject();
              this.afs.doc("projects/userprojects/" + this.customerid + "/" + this.projectid).update(JSON.parse(JSON.stringify(this.project)));

            })
            return undefined;
          }
        );
      }, 'image/png', 1.0);

    } else {
      //    this.saveproject();

    }

  }
  updateThreeFrame() {
    if (!this.project?.sceneSettings?.showAnimations) {
      this.controls.enableDamping = false;

      var delta = this.clock.getDelta();
      this.controls.update(delta);
      this.camera.updateProjectionMatrix();

      this.scene.traverse((object) => {
        // Check if the object is a sprite
        if (object instanceof THREE.Sprite) {
          // Update the sprite's matrix
          object.updateMatrix();
        }
      });


    


      this.renderer.render(this.scene, this.camera);
      this.viewportGizmo?.update();
      this.viewportGizmo?.render();

      //  this.composer.render();
    }
    else {
      this.controls.enableDamping = true;
    }
  }
  raycaster = null;
  mouse = new THREE.Vector2();
  INTERSECTED = null;
  mousemove(event: any) {
    this.updateThreeFrame();
    var container = document.getElementById("threecontainer");
    this.camera.aspect = container.clientWidth / container.clientHeight;
    this.mouse.x = (event.layerX / container.clientWidth) * 2 - 1;
    this.mouse.y = -(event.layerY / container.clientHeight) * 2 + 1;
  }
  async splitDragEnd(x: any) {
    //  console.log("splitdragend", x);
    try {
      this.leftpanelwidth = x.sizes[0] * window.innerWidth;
      this.splitleft = x.sizes[0];
      this.threecontainerwidth = window.innerWidth - x.sizes[0] * window.innerWidth / 100;;
      //      this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;

      var container = document.getElementById("threecontainer");
      if (!container) throw new Error("Container 'threecontainer' not found");
      this.camera.aspect = this.threecontainerwidth / container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.threecontainerwidth, container.clientHeight);

      if (this.viewportGizmo)
        this.viewportGizmo.left = this.threecontainerwidth - 128; // hack

      this.updateThreeFrame();
      await this.setVars();

      this.hot.render();
    } catch (error) {
      console.warn("Error in splitDragEnd:", error);
    }
  }

  async setVars() {

    try {

      // API vars
      var changes = await this.excels[this.PROJECT].changeNamedExpression('screensize', this.threecontainerwidth + "," + window.innerHeight);
      this.updateHotChanges(changes);
      var changes2 = await this.excels[this.PROJECT].changeNamedExpression('screenwidth', this.threecontainerwidth);
      this.updateHotChanges(changes2);
      var changes3 = await this.excels[this.PROJECT].changeNamedExpression('screenheight', window.innerHeight);
      this.updateHotChanges(changes3);
      // TODO
      // var changes4 = await this.excels[this.PROJECT].addNamedExpression('window.height', 123);
      // this.updateHotChanges(changes4);
      // var changes5 = await this.excels[this.PROJECT].changeNamedExpression('window_width', 234324);
      // this.updateHotChanges(changes5);


      var dataset = this.dataset;
      var sheetid = this.sheetid; //TODO: muss nicht stimmen!

      var allchanges = changes.concat(changes2).concat(changes3);

      // TODO: update model/  verweise => setcontens
      for (var j = 0; j < allchanges?.length; j++) {
        var a = allchanges[j].address;
        if (a)
          if (dataset[a.row][1] != null && dataset[a.row][1] != "" && a.row > this.outputrow && dataset[a.row][0] != "#") {

            var typ = dataset[a.row][1];
            var objectname = "$" + sheetid + "#" + dataset[a.row][0];
            var commentrow = this.getCommentRow(dataset, a.row); // dataset[a.row - 1]

            if (this.isInList(this.overlayTypes, typ)) {
              // 2d overlays 
              var overlay = await this.updateOverlay(j, objectname, dataset[a.row], commentrow, typ);
            }
            else {
              //    var newnode = await this.cad.createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel);
            }
          }
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  private updateHotChanges(changes) {
    if (changes)
      for (var j = 0; j < changes.length; j++) {
        var change = changes[j];
        var cellAddress = change.address;
        var cellValue = change.newValue;
        if (cellAddress)
          this.dataset[cellAddress.row][cellAddress.col] = cellValue;
      }

  }
  openfunctionsdialog() {

    const dialogRef = this.dialog.open(DialogfunctionsComponent, {
      width: '980px',
      data: {
      }
    });

  }
  restrictMove = false
  selectedName = "";
  selectedNode: any;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  running = false;
  async controlC(val: any) {
    console.log('controlC from editor', JSON.stringify(val));

    // nur zumtetst    
    if (val.typ == "BUTTON" && val.outputValue == "save") {
      if (!this.isEditMode) {
        var configModel = await this.createConfigModel();
        const dialogRef = this.dialog.open(DialogsaveconfigComponent, {
          width: '980px',
          data: {
            configModel: configModel,
            project: this.project,
            projectid: this.projectid
          },
        });

        dialogRef.afterClosed().subscribe(async result => {
          console.log('The dialog was closed', result);
          if (result) {
            configModel.enduserdata = result;
            var r = await this.afs.collection("configs/" + this.customerid + "/" + this.projectid).add(
              JSON.parse(JSON.stringify(configModel)));
            var text = "";
            var configid = r.id;
            console.log("config saved", r);

            // save glb and collada
            var blob = Utils.saveGltf(this.scene, this.projectid, this.customerid, configid, this.afs);

            if (this.project.userformsendmail) text = "You will receive an email shortly with your configuration.";
            this.snackBar.open("Configuration saved. " + text, "", { duration: 5000 });
          }
        });
      }
    }
    if (val.typ == "BUTTON" && val.outputValue != "save") {
      console.log("BUTTON", this.project.scriptcode);
      eval(this.project.scriptcode + "  " + val.onclick + ";    console.log('excels. ', this.project)");
      //      eval(val.onclick)
      return;
    }
    if (val.typ == "CHECKBOX") {
      val.outputValue = !val.outputValue;
    }
    if (val.typ == "RADIO") {
      // !val.outputValue;
      // alten auf false setzen
      var r = val.sheet.row - 1;
      while (this.cellformulas[r][1]?.indexOf("radio") > -1) {
        this.cellformulas[r][2] = false;
        this.dataset[r][2] = false;
        var cell = JSON.parse(JSON.stringify(val.sheet)); cell.row = r;
        await this.sheetChange(cell, false, true, false, false);
        r--;
      }
      r = val.sheet.row + 1;
      while (this.cellformulas[r][1]?.indexOf("radio") > -1) {
        this.cellformulas[r][2] = false;
        this.dataset[r][2] = false;
        var cell = JSON.parse(JSON.stringify(val.sheet)); cell.row = r;
        await this.sheetChange(cell, false, true, false, false);
        r++;
      }
      val.outputValue = true;
      //       this.cellformulas[cell.row][cell.col] = false;
      //      await this.sheetChange(cell, v, v);

    }

    var cell = val.sheet;
    if (cell) {
      var valtemp = val.outputValue;
      var v = null;
      if (!isNaN(valtemp))
        var f = parseFloat(valtemp);
      if (isNaN(f))
        v = valtemp
      else
        v = f;

      // untersheet aktiviert -> Wert von UI als Parameter in Mainsheet
      if (this.sheetSelected) {
        cell.sheet = this.sheetid;
        var paramname = val.controlName;
        cell.row = this.selectedNode.userData.rowindex;

        // search col for paramname
        var ca1 = await Utils.searchCellByValue(this.excels[this.PROJECT], { col: 0, row: cell.row, sheet: cell.sheet }, "#", 1);
        cell.col = (await Utils.searchCellByValue(this.excels[this.PROJECT], { col: 0, row: ca1.row, sheet: ca1.sheet }, paramname, 2))?.col;

        //var address = await Utils.searchControlNameAddress(this.excels[this.PROJECT], paramname);



      }

      if (this.isMainConfig)
        this.cellformulas[cell.row][cell.col] = v;

      var exportedchanges = await this.sheetChange(cell, v, v);
      //  this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
      //  this.valueSubject.next(v);
    }


  }

  openDialog(): void {
    // this.saveproject();

    const dialogRef = this.dialog.open(PublishDialog, {
      width: '990px',
      height: '615px',
      data: { project: this.project, userid: this.customerid }
      //   data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });
  }

  openMenuDialog(): void {
    const dialogRef = this.dialog.open(DialogmenuComponent, {
      width: '980px',
      height: '615px',
      data: { project: this.project, userid: this.customerid }
      //   data: { name: this.name, animal: this.animal },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }


  openSubProjectDialog(): void {
    const dialogRef = this.dialog.open(DialogsubprojectComponent, {
      width: '980px',
      data: { excels: this.excels, hf: this.hf, sheetid: this.sheetid, showAnimations: this.project.sceneSettings.showAnimations, PROJECT: this.PROJECT, dataset: this.dataset },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('project dialog was closed', result);

    });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  showfilesDialog(filter, edit = false, primitive = false) {
    const dialogRef = this.dialog.open(FilesDialog, {
      width: '1250px',
      height: '95%',
      data: { filter: filter },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        this.copyMessage(result)

        // hack
        if (filter == "png")
          if (!edit)
            if (primitive)
              this.addPrimitive('image3d', result)
            else
              this.addGui('image', result)

        if (edit)
          this.cellSelected = result;

      }
      if (filter == "gltf") {
        if (!edit)
          this.addPrimitive('gltf', result);
        if (edit) {

          var cell = { row: this.curSelectedRow, col: this.curSelectedCol, sheet: this.sheetid };
          this.sheetChange(cell, [[result]], [[result]], true);
        }

      }

    });

  }
  async showmaterialsDialog(baseScene, selected) {
    const dialogRef = this.dialog.open(MaterialbComponent, {
      width: '800px',
      data: { baseScene: baseScene, val: selected },
      //   data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);

      if (result) {
        var sc = this.hot.getSelected();
        var col = sc[0][1];
        var row1 = sc[0][0];
        var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: col, row: row1 }, result);
        this.dataset[row1][col] = result;
        this.cellformulas[row1][col] = result;
        this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result, result);

        this.hot.render();
        // todo applyc
      }
    });

  }

  async showshapeDialog() {
    const dialogRef = this.dialog.open(DialogshapeComponent, {
      width: '1150px',
      //    height: '1000px',
      data: {
        value: this.dataset[this.hot.getSelected()[0][0]][this.hot.getSelected()[0][1]],
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);

      if (result) {
        if (result.copytable) {

          var ij = result.cellref;
          //result.td[0][0] = result.td[0][0].replace(/<br>/g, "\n");

          var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: ij[1], row: ij[0] }, result.td);
          for (var i = 0; i < result.td.length; i++)
            for (var j = 0; j < result.td[i].length; j++) {
              this.dataset[ij[0] + i][ij[1] + j] = result.td[i][j];
              this.cellformulas[ij[0] + i][ij[1] + j] = result.td[i][j];
              //   this.sheetChange({ sheet: this.sheetid, row: ij[0] + i, col: ij[1] + j }, result.td[i][j], result.td[i][j]);
            }

          this.hot.render();


          // this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result.val, result.val);

        }

        var sc = this.hot.getSelected();
        var col = sc[0][1];
        var row1 = sc[0][0];
        var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: col, row: row1 }, result.val);
        this.dataset[row1][col] = result.val;
        this.cellformulas[row1][col] = result.val;
        this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result.val, result.val);
        this.hot.render();

      }
    });

  }
}


