<div class="row">
    <div class="col-md-3">

        <!-- <mat-radio-group aria-label="Select an option" [(ngModel)]="curmaterial.type">
            <mat-radio-button value="phong">Phong Material</mat-radio-button>
            <mat-radio-button value="physical">Physical Material</mat-radio-button>
        </mat-radio-group>
        <hr> -->
        <style>
            label {
                min-width: 120px;
            }

            .cpinput {
                width: 80px;
                margin-bottom: 10px;
            }
        </style>
        <!-- <div *ngIf="curmaterial.type=='phong'">
            <label>Color</label>
            <input class="cpinput" [(ngModel)]="curmaterial.color" [style.background]="curmaterial.color" [(colorPicker)]="curmaterial.color" (colorPickerChange)="onChange()" />
        
            <br>
            <label>Emissive</label>
            <input class="cpinput" [(ngModel)]="curmaterial.emissive" [style.background]="curmaterial.emissive" [(colorPicker)]="curmaterial.emissive" (colorPickerChange)="onChange()" />
            <label>Specular</label>
            <input class="cpinput" [(ngModel)]="curmaterial.specular" [style.background]="curmaterial.specular" [(colorPicker)]="curmaterial.specular" (colorPickerChange)="onChange()" />
            <label>Shininess</label>
            <mat-slider min="0" max="1" step="0.05" [(ngModel)]="curmaterial.shininess" (input)="onChange($event,'shininess')">
            </mat-slider>
        </div> -->

        <!-- { type: "physical", roughness: 0.15, metalness: 0.8, reflecitiy: 0.4, color: 0xff0000, emissive: 0x000000, specular:
0xffffff, shininess: 50 }, // außerdem map, roughnessMap, alphaMao -->
        <div *ngIf="curmaterial.type=='physical'">
            <input style="visibility: hidden;" autofocus [(ngModel)]="curmaterial.type" />

            <!-- <label>Emissive</label>
            <input class="cpinput" [value]="curmaterial.emissive" [style.background]="curmaterial.emissive" [(colorPicker)]="curmaterial.emissive" (colorPickerChange)="onChange()" />
            <br> -->
            <label>Roughness</label><br>
            <mat-slider min="0" max="1" step="0.05" [(ngModel)]="curmaterial.roughness" (input)="onChange($event,'roughness')">
            </mat-slider><br>
            <label>Metalness</label><br>
            <mat-slider min="0" max="1" step="0.05" [(ngModel)]="curmaterial.metalness" (input)="onChange($event,'metalness')">
            </mat-slider><br>
            <label>Reflecitiy</label><br>
            <mat-slider min="0" max="1" step="0.05" [(ngModel)]="curmaterial.reflectivity" (input)="onChange($event,'reflectivity')">
            </mat-slider><br>
            <label>Opacity</label><br>
            <mat-slider min="0" max="1" step="0.05" [(ngModel)]="curmaterial.opacity" (input)="onChange($event,'opacity')">
            </mat-slider><br>
            <label>Color</label><br>
            <input class="cpinput" [value]="curmaterial.color" [style.background]="curmaterial.color" [(colorPicker)]="curmaterial.color" (colorPickerChange)="onChange()" />
            <!-- <mat-slider min="0" max="1" step="0.05" value="0.5"></mat-slider> -->
            <br>
        </div>


        <hr>

        <label>Cell Value</label>
        <mat-form-field>
            <input matInput #input1 [(ngModel)]="cellvalue">
        </mat-form-field><br>

        <div style="position:absolute;bottom:20px;">

            <!-- <button class="btn btn-default" (click)="copy()" style="margin-right:10px;">Copy</button> -->
            <button class="btn btn-default" (click)="apply()">Apply</button>
        </div>
    </div>


    <div class="col-md-9" style="height:650px">
        <div>Preview</div>
        <div id="threematerialscontainer" #rendererContainer style="height:300px; width:100%">
        </div>
        <div style="margin-top:10px;">Presets</div>
        <div id="threematerialscontainer2" (click)="paletteClick($event)" #rendererContainer2 style="cursor:pointer;height:300px; width:100%">
        </div>


    </div>
</div>