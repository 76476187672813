import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-auth-top-bar',
    templateUrl: './auth-top-bar.component.html',
    styleUrls: ['./auth-top-bar.component.scss']
})
export class AuthTopBarComponent {
    @Input() configid: string;
    @Input() isAuthenticated: boolean;
    @Input() isEditMode: boolean;
    @Input() configmodel: any;

    @Output() saveConfig = new EventEmitter<void>();
    @Output() exportScene = new EventEmitter<void>();
    @Output() zoomAll = new EventEmitter<void>();
    @Output() toggleCamera = new EventEmitter<void>();
    @Output() takeScreenshot = new EventEmitter<boolean>();
    @Output() createReport = new EventEmitter<void>();
    @Output() createPartList = new EventEmitter<void>();

    onSaveConfig(): void {
        this.saveConfig.emit();
    }

    onExportScene(): void {
        this.exportScene.emit();
    }

    onZoomAll(): void {
        this.zoomAll.emit();
    }

    onToggleCamera(): void {
        this.toggleCamera.emit();
    }

    onTakeScreenshot(): void {
        this.takeScreenshot.emit(false);
    }

    onCreateReport(): void {
        this.createReport.emit();
    }

    onCreatePartList(): void {
        this.createPartList.emit();
    }
} 