import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HotTableModule } from '@handsontable/angular';
import { AngularSplitModule } from 'angular-split';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditorComponent } from './editor/editor.component';
import { MdePopoverModule } from "@material-extended/mde";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilesComponent, FilesDialog } from './files/files.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ColorCompactModule } from 'ngx-color/compact'; // <color-compact></color-compact>
import { SocialShareComponent } from './social-share/social-share.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

import { UploadwidgetComponent } from './uploadwidget/uploadwidget.component';
import { CommonModule } from '@angular/common';

import { AngularFireStorageModule } from '@angular/fire/storage';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';


import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment.prod';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { AdmininvoicesComponent } from './admin/invoices/admininvoices.component';
import { LogComponent } from './admin/log/log.component';
import { UsersComponent } from './admin/users/users.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { MomentModule } from 'ngx-moment';
// import { FlexLayoutModule } from "@angular/flex-layout";
import * as firebase from 'firebase';
import { GuiComponent } from './gui/gui.component';
import { McmpipePipe, McmunitPipe } from './gui/mcmpipe.pipe';
import { TranslatesplitPipe } from './gui/translatesplit.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgxSliderModule } from "@angular-slider/ngx-slider";


import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MaterialbComponent } from './materialb/materialb.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DialogsubprojectComponent } from './editor/dialogsubproject/dialogsubproject.component';
import { ConfigurationassignmentsComponent } from './configurationassignments/configurationassignments.component';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';

import { AngularFireAnalyticsModule, ScreenTrackingService } from "@angular/fire/analytics";
import { M22ResizableComponent } from './m22-resizable/m22-resizable.component';
import { InputdialogComponent } from './inputdialog/inputdialog.component';
import { ConfigsComponent } from './configs/configs.component';
import { DialogsaveconfigComponent } from './editor/dialogsaveconfig/dialogsaveconfig.component';
import { DialogdisclaimerComponent } from './editor/dialogdisclaimer/dialogdisclaimer.component';
import { YesnodialogComponent } from './yesnodialog/yesnodialog.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { AdminnavComponent } from './admin/adminnav/adminnav.component';
import { AdmindashComponent } from './admin/admindash/admindash.component';
import { DialogmenuComponent } from './editor/dialogmenu/dialogmenu.component';
import { OverlaysComponent } from './overlays/overlays.component';
import { CircleComponent, NgxChartsModule } from '@swimlane/ngx-charts';
import { SafeHtmlPipe } from './utils/safehtmlpipe';
import { TreeModule } from '@circlon/angular-tree-component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { ExamplesComponent } from './dashboard/examples/examples.component';
import { TutorialsComponent } from './dashboard/tutorials/tutorials.component';
import { DialogdimensionsComponent } from './editor/dialogdimensions/dialogdimensions.component';
import { DialogshapeComponent } from './editor/dialogshape/dialogshape.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PublishDialog } from './editor/dialogpublish';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AngJsoneditorModule } from 'ang-json-editor-13';
import { DialogfunctionsComponent } from './editor/dialogfunctions/dialogfunctions.component';
import { CustomerrorhandlerService } from './customerrorhandler.service';
import { CirclecontrolComponent } from './editor/Circle/Circle.component';
import { ToastrModule } from 'ngx-toastr';
//import { SlidingPanelComponent } from './slidingpanel/slidingpanel.component';

import { AiPromptEditorComponent } from './ai-prompt-editor/ai-prompt-editor.component';
import { CellActionMenuComponent } from './cell-action-menu/cell-action-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmLeaveDialogComponent } from './editor/confirm-leave-dialog.component';
import { SaveReminderGuard } from './guards/save-reminder.guard';
import { ParallelAiDialogComponent } from './dashboard/parallel-ai-dialog/parallel-ai-dialog.component';
import { MatTreeModule } from '@angular/material/tree';
import { ExportDialogComponent } from './editor/export-dialog/export-dialog.component';
import { AIAgentComponent } from './ai-agent/ai-agent.component';
import { HallConfiguratorsComponent } from './hall-configurators/hall-configurators.component';
import { ExcludePropsPipe } from './utils/exclude-props.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TosComponent } from './login/tos/tos.component';
import { PartListDialogComponent } from './part-list-dialog/part-list-dialog.component';
import { AuthTopBarComponent } from './auth-top-bar/auth-top-bar.component';
import { UserAnalyticsComponent } from './admin/user-analytics/user-analytics.component';

registerLocaleData(localeDe, localeDeExtra);

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    EditorComponent,

    DashboardComponent,
    UploadwidgetComponent,
    FilesComponent,
    LoginComponent,
    AdmininvoicesComponent,
    LogComponent,
    UsersComponent,
    InvoicesComponent,

    CirclecontrolComponent,

    SettingsComponent,
    GuiComponent,
    SocialShareComponent,
    McmpipePipe, McmunitPipe, TranslatesplitPipe, SafeHtmlPipe,
    PublishDialog,
    FilesDialog,
    MaterialbComponent,
    DialogsubprojectComponent,
    ConfigurationassignmentsComponent,
    M22ResizableComponent,
    InputdialogComponent,
    ConfigsComponent,
    DialogsaveconfigComponent,
    DialogdisclaimerComponent,
    YesnodialogComponent,

    ProjectsComponent,
    AdminnavComponent,
    AdmindashComponent,
    DialogmenuComponent,
    OverlaysComponent,
    SidebarComponent,
    ExamplesComponent,
    TutorialsComponent,
    DialogdimensionsComponent,
    DialogshapeComponent,
    DialogfunctionsComponent,
    //  SlidingPanelComponent
    AiPromptEditorComponent,
    CellActionMenuComponent,
    ConfirmLeaveDialogComponent,
    ParallelAiDialogComponent,
    ExportDialogComponent,
    AIAgentComponent,
    HallConfiguratorsComponent,
    ExcludePropsPipe,
    TosComponent,
    PartListDialogComponent,
    AuthTopBarComponent,
    UserAnalyticsComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    AppRoutingModule,
    TreeModule,
    HotTableModule,
    AngularSplitModule,
    MatFormFieldModule,

    DragDropModule,
    MatInputModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularEditorModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NgxSliderModule,
    MatMenuModule,
    ColorPickerModule,
    NgxMatColorPickerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatExpansionModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTableModule,
    MatSnackBarModule,
    NgxChartsModule,
    MatIconModule,
    ColorCompactModule,
    MatRadioModule,
    FlexLayoutModule,
    MomentModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCardModule,
    MatStepperModule,
    MdePopoverModule,
    MatSnackBarModule,
    DragDropModule,
    PrettyJsonModule,
    MatTooltipModule,
    //   FlexLayoutModule,
    // ColorPickerModule,
    // AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatPaginatorModule,
    MatSortModule,

    FontAwesomeModule,

    ToastrModule.forRoot(),

    AngularFireAnalyticsModule,
    MonacoEditorModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,

    NgxAuthFirebaseUIModule.forRoot(environment.firebase,
      () => 'your_app_name_factory',
      {
        enableFirestoreSync: true,
        toastMessageOnAuthSuccess: false,
        toastMessageOnAuthError: true,
        authGuardFallbackURL: '/login',
        authGuardLoggedInURL: '/',
        passwordMaxLength: 60,
        passwordMinLength: 6,
        nameMaxLength: 50,
        nameMinLength: 2,
        guardProtectedRoutesUntilEmailIsVerified: false,
        enableEmailVerification: true,
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),

    // ModalModule.forRoot(),
    // NgJsonEditorModule,
    // AngularFireAnalyticsModule
    AngJsoneditorModule,
    MatTreeModule,
    MatProgressSpinnerModule

  ],
  providers: [
    ScreenTrackingService,
    { provide: ErrorHandler, useClass: CustomerrorhandlerService },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    },
    SaveReminderGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}