import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-tos',
    template: `
    <h2 mat-dialog-title>Terms of Service</h2>
    <mat-dialog-content>
      <div class="tos-content">
        <h3>1. Service Description</h3>
        <p>SheetBuild2D is a service that allows users to create 3D models using Excel spreadsheets. The service is provided on a freemium basis.</p>

        <h3>2. Free Plan</h3>
        <p>The free plan includes:</p>
        <ul>
          <li>Creation of basic 3D models</li>
          <li>Limited storage space (100MB)</li>
          <li>Export in basic formats</li>
          <li>Community support</li>
        </ul>

        <h3>3. Premium Features</h3>
        <p>Premium features may include:</p>
        <ul>
          <li>Advanced 3D modeling capabilities</li>
          <li>Increased storage space</li>
          <li>Priority support</li>
          <li>Additional export formats</li>
          <li>Collaboration features</li>
        </ul>

        <h3>4. User Obligations</h3>
        <p>Users agree to:</p>
        <ul>
          <li>Provide accurate registration information</li>
          <li>Maintain the security of their account</li>
          <li>Not misuse or abuse the service</li>
          <li>Respect intellectual property rights</li>
        </ul>

        <h3>5. Data Usage</h3>
        <p>We collect and process user data as described in our Privacy Policy. By using our service, you consent to such processing.</p>

        <h3>6. Intellectual Property</h3>
        <p>Users retain rights to their created content. We maintain rights to our service, technology, and platform.</p>

        <h3>7. Service Modifications</h3>
        <p>We reserve the right to modify or discontinue the service with reasonable notice to users.</p>

        <h3>8. Limitation of Liability</h3>
        <p>The service is provided "as is" without warranties. We are not liable for any damages arising from service use.</p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="onClose()">Close</button>
    </mat-dialog-actions>
  `,
    styles: [`
    .tos-content {
      max-height: 60vh;
      overflow-y: auto;
      padding: 0 20px;
    }
    h3 {
      margin-top: 20px;
      color: #333;
    }
    ul {
      margin-left: 20px;
    }
  `]
})
export class TosComponent {
    constructor(public dialogRef: MatDialogRef<TosComponent>) { }

    onClose(): void {
        this.dialogRef.close();
    }
} 