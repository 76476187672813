import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-leave-dialog',
    template: `
    <h2 mat-dialog-title>Unsaved Changes</h2>
    <mat-dialog-content>
      <!-- Your last save was {{ data.timeSinceLastSave }} seconds ago.  -->
      Are you sure you want to leave?
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="onNoClick()">Stay</button>
      <button mat-button [mat-dialog-close]="true" color="warn">Leave</button>
    </mat-dialog-actions>
  `
})
export class ConfirmLeaveDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmLeaveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { timeSinceLastSave: number }
    ) { }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
} 