import { HttpClient } from "@angular/common/http";

class AIManager {
    private apiUrl = 'https://us-central1-xbuild3d.cloudfunctions.net/createClaudePrompt'; // Replace with your actual Firebase Function URL

    lastResponse: string;

    public conversationHistory = [];


    constructor(private http: HttpClient) {




    }

    async chatWithClaude(prompt: string, systemPrompt: string, base64images: any, table: string): Promise<string> {
        try {

            this.conversationHistory.push({ role: 'user', content: prompt });

            const requestBody = {
                userInput: prompt,
                //  context: this.context,
                base64images: base64images,
                table: table,
                systemPrompt: systemPrompt
            };
            console.log(requestBody)
            // Make the HTTP POST request with attachments
            try {
                const response = await this.http.post<any>(this.apiUrl, requestBody).toPromise();
                this.lastResponse = JSON.stringify(response);

                console.log(response);
                console.log(JSON.parse(response.response));
                this.conversationHistory.push({ role: 'assistant', content: this.lastResponse });


                const jsonResponse = JSON.parse(response.response);
                // Iterate over the 2D array and format any formula cells
                // for (let i = 0; i < jsonResponse.length; i++) {
                //     for (let j = 0; j < jsonResponse[i].length; j++) {
                //         let cellValue = jsonResponse[i][j];
                //         if (typeof cellValue === 'string' && cellValue.startsWith('=')) {
                //             jsonResponse[i][j] = this.formatFormulaString(cellValue);
                //         }
                //         //    console.log(`Row ${i}, Column ${j}: ${jsonResponse[i][j]}`);
                //     }
                // }

                return jsonResponse;
            } catch (error) {
                this.lastResponse = "error " + error;
                console.error('Error:', error);
                this.lastResponse = 'An error occurred while processing your request.';
                return "error";
            }

            // const data = await this.lastResponse.json();
            // console.log("response", data);

        } catch (error) {
            console.error('Error sending request to Claude:', error);
            throw error;
        }
    }

    // Add new helper function to format formula strings
    private formatFormulaString(formula: string): string {
        // Remove the leading equals sign
        let content = formula.substring(1);

        // Regular expression to find cell references (e.g., A1, B2, AA12)
        const cellRefRegex = /[A-Z]+[0-9]+/g;

        // Split the formula into parts, preserving cell references
        let parts = content.split(cellRefRegex);
        let cellRefs = content.match(cellRefRegex) || [];

        // Build the new formula string
        let newFormula = '="';

        for (let i = 0; i < parts.length; i++) {
            // Add the non-cell-reference part
            if (parts[i]) {
                newFormula += parts[i];
            }
            // Add the cell reference if there is one
            if (i < cellRefs.length) {
                newFormula += '"&' + cellRefs[i] + '&"';
            }
            console.log(content, newFormula);
        }

        // Close the formula string
        newFormula += '"';

        return newFormula;
    }

    restartConversation() {
        this.conversationHistory = [];
    }







    generateClaudePrompt(tableDescription) {
        return `Please create a table based on the following description: "${tableDescription}". 
  Follow these guidelines:
  1. Use tab-separated values (TSV) format.
  2. Include a header row.
  3. Separate each row with a newline character.
  4. Do not include any additional text or explanations outside the table.
  5. Ensure the data is realistic and consistent.
  6. Provide at least 5 rows of data (including the header).
  
  Here's an example of the expected format:
  Column1\tColumn2\tColumn3
  Value1\tValue2\tValue3
  Value4\tValue5\tValue6
  
  Please generate the table now.`;
    }


    parseTsvTo2dArray(tsvString) {
        // Split the string into rows
        const rows = tsvString.trim().split('\n');

        // Map each row to an array of values
        return rows.map(row => {
            const values = row.split('\t');

            // Convert numeric strings to numbers
            return values.map(value => {
                const num = Number(value);
                return isNaN(num) ? value : num;
            });
        });
    }



}

export default AIManager;