<app-adminnav></app-adminnav>

<div class="container">

    <h1>Dashboard</h1>
    <div class="row">
        <div class="col-md-9">

        </div>
        <div class="col-md-3">
            <h4>News</h4>
            <div class="row" *ngFor="let n of news">
                <div class="col-md-12">

                    <div>

                        <span>{{n.datetime| date }}&nbsp;</span><strong>{{n.title}}</strong>
                    </div>
                    <div style="font-size: 12px;">{{n.content}} <i class="fa fa-trash" (click)="deletenews(n)"></i> </div>
                    <hr>
                </div>
            </div>
            <hr>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="newtitle" placeholder="title">
                </mat-form-field><br>
                <mat-form-field>
                    <textarea style="width:500px" matInput [(ngModel)]="newcontent" placeholder="content"></textarea>
                </mat-form-field><br>
                <button mat-raised-button (click)="addnews()">Add</button>
            </div>
        </div>
    </div>
</div>