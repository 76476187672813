import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatVerticalStepper } from '@angular/material/stepper';
import { Materials } from "../editor/materials";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { BaseScene } from '../editor/basescene';

@Component({
  templateUrl: './materialb.component.html',
  styleUrls: ['./materialb.component.scss']
})
export class MaterialbComponent implements OnInit {
  scene: THREE.Scene;
  scene2: THREE.Scene;
  baseScene: BaseScene;
  baseScene2: BaseScene;
  materials: Materials;
  curmaterial = {
    type: "physical", color: "#ff0000", emissive: "#000000", specular: "#ffffff", shininess: 0.5, roughness: 0.15, metalness: 0.8, reflectivity: 0.4, opacity: 1
  }
  camera: any;
  camera2: any;
  renderer: THREE.WebGLRenderer;
  renderer2: THREE.WebGLRenderer;
  cellvalue = "";

  @ViewChild("rendererContainer") rendererContainer: ElementRef;
  @ViewChild("rendererContainer2") rendererContainer2: ElementRef;
  color = "#ffff00";

  materialtype = 'phong';
  mesh: THREE.Mesh;
  boxmesh: THREE.Mesh;
  clock: THREE.Clock;
  @ViewChild('input1', { static: false }) inputEl: ElementRef;


  constructor(public dialogRef: MatDialogRef<MaterialbComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {


    // TODO: hier licht etc durchreichen
    console.log("material data ", data);

    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.scene2 = new THREE.Scene();
    this.renderer2 = new THREE.WebGLRenderer({ antialias: true });
    this.materials = new Materials();
    this.clock = new THREE.Clock();
  }
  onNoClick(): void {
    this.dialogRef.close('test');
  }


  onChange(event = null, prop = null, obj = null) {
    console.log('onchanc', event, prop, this.curmaterial);
    if (event) {
      this.curmaterial[prop] = event.value;
    }
    // if (this.curmaterial.type == "physical") {
    var physmat = {
      color: this.curmaterial.color,
      emissive: this.curmaterial.emissive,
      //    specular: this.curmaterial.specular,
      //  shininess: this.curmaterial.shininess,
      roughness: this.curmaterial.roughness,
      metalness: this.curmaterial.metalness,
      reflectivity: this.curmaterial.reflectivity,
      opacity: this.curmaterial.opacity
    }
    this.mesh.material = new THREE.MeshPhysicalMaterial(physmat);
    if (this.curmaterial.opacity < 1)
      this.mesh.material.transparent = true;
    else
      this.mesh.material.transparent = false;

    this.boxmesh.material = this.mesh.material;

    this.cellvalue = this.curmaterial.color + ";" + this.curmaterial.roughness + ";" + this.curmaterial.metalness + ";" + this.curmaterial.reflectivity + ";" + this.curmaterial.opacity;

    this.renderer.render(this.scene, this.camera);

    this.renderer2.render(this.scene2, this.camera2);

  }
  ngOnInit(): void {
    // Materials
    const geometry = new THREE.SphereGeometry(150, 32, 16);
    const boxgeo = new THREE.BoxGeometry(200, 200, 200);
    const specularShininess = 0.5;
    const specularColor = new THREE.Color(0xff0000);
    const diffuseColor = new THREE.Color(0x0000ff);
    const material = new THREE.MeshPhysicalMaterial({
      color: diffuseColor,
      //specular: specularColor,
      //reflectivity: beta,
      // shininess: specularShininess,
      //    envMap: alphaIndex % 2 === 0 ? null : reflectionCube
    });
    if (this.data.val) {
      this.cellvalue = this.data.val;
      // this.cellvalue = this.curmaterial.color + ";" + this.curmaterial.roughness + ";" + this.curmaterial.metalness + ";" + this.curmaterial.reflectivity + ";" + this.curmaterial.opacity;
      var parts = this.data.val.split(";");
      this.curmaterial.color = parts[0];
      this.curmaterial.roughness = parts[1];
      this.curmaterial.metalness = parts[2];
      this.curmaterial.reflectivity = parts[3];
      this.curmaterial.opacity = parts[4];
    }

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.x = -250;
    this.mesh.position.y = 0;
    this.mesh.position.z = 0;
    this.scene.add(this.mesh);
    this.boxmesh = new THREE.Mesh(boxgeo, material);
    this.boxmesh.position.x = 250;
    this.boxmesh.position.y = 0;
    this.boxmesh.position.z = 0;
    this.boxmesh.rotation.x = Math.PI / 7;
    this.boxmesh.rotation.y = Math.PI / 7;

    this.scene.add(this.boxmesh);





  }

  async ngAfterViewInit() {
    setTimeout(() => this.inputEl.nativeElement.focus());
    this.setupthreejs();
    //    this.leftpanelwidth = 0.4 * window.innerWidth;;

    this.onChange(null, null, null);
    this.animate();

  }

  createPalette() {
    for (var i = 0; i < this.materials.meshMaterials.length; i++) {
      var m = this.materials.meshMaterials[i];
      const geometry = new THREE.SphereGeometry(30, 32, 16);

      var material = null;
      material = new THREE.MeshPhysicalMaterial({
        roughness: m.roughness,
        metalness: m.metalness,
        // reflecitiy: m.reflectivity,
        color: new THREE.Color(m.color),
        emissive: new THREE.Color(m.emissive),
        //  shininess: 0.5,
        opacity: m.opacity,
      });



      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.x = i * 70 - 245
      mesh.position.y = -70;
      if (i > 7 && i < 16) {
        mesh.position.y = 0;
        mesh.position.x = (i - 8) * 70 - 245
      }
      if (i > 15) {
        mesh.position.y = 70;
        mesh.position.x = (i - 16) * 70 - 245
      }
      // TODO: 4.-reiehe mit 8 elementen

      mesh.position.z = 0;
      this.scene2.add(mesh);
    }
  }

  public pmremGenerator: THREE.PMREMGenerator;
  controls: any;
  setupthreejs() {



    this.baseScene2 = new BaseScene(this.scene2, this.renderer2);
    var threecontainer2 = document.getElementById("threematerialscontainer2");
    this.camera2 = new THREE.OrthographicCamera(threecontainer2.clientWidth / - 2, threecontainer2.clientWidth / 2, threecontainer2.clientHeight / 2, threecontainer2.clientHeight / - 2, 1, 1000);
    //  this.camera2 = new THREE.PerspectiveCamera(25, threecontainer2.clientWidth / threecontainer2.clientHeight, 1, 20000);
    this.rendererContainer2.nativeElement.appendChild(this.renderer2.domElement);
    this.renderer2.setSize(threecontainer2.clientWidth, threecontainer2.clientHeight);
    this.renderer2.shadowMap.enabled = true; // https://threejsfundamentals.org/threejs/lessons/threejs-shadows.html
    this.renderer2.shadowMap.autoUpdate = true;
    this.renderer2.shadowMap.needsUpdate = false;
    this.renderer2.shadowMap.type = THREE.PCFSoftShadowMap;
    this.camera2.position.z = 400;
    this.camera2.position.x = 0;
    this.camera2.position.y = 0;

    this.baseScene = new BaseScene(this.scene, this.renderer);
    var threecontainer = document.getElementById("threematerialscontainer");
    this.camera = new THREE.PerspectiveCamera(75, threecontainer.clientWidth / threecontainer.clientHeight, 1, 20000);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.renderer.setSize(threecontainer.clientWidth, threecontainer.clientHeight);
    this.renderer.shadowMap.enabled = true; // https://threejsfundamentals.org/threejs/lessons/threejs-shadows.html
    this.renderer.shadowMap.autoUpdate = true;
    this.renderer.shadowMap.needsUpdate = false;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.camera.position.z = 400;
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.enableRotate = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = true;

    this.baseScene.grid.visible = false;
    this.baseScene.axes.visible = false;
    this.baseScene2.grid.visible = false;
    this.baseScene2.axes.visible = false;

    this.scene.background = new THREE.Color().setRGB(0.9, 0.9, 0.9);
    this.scene2.background = new THREE.Color().setRGB(0.9, 0.9, 0.9);



    ////
    this.baseScene.dirLight.intensity = this.data.baseScene.dirLight.intensity;
    this.baseScene.hemiLight.intensity = this.data.baseScene.hemiLight.intensity;
    this.baseScene.ambientLight.intensity = this.data.baseScene.ambientLight.intensity;

    // this.baseScene2.dirLight.intensity = this.data.baseScene2.dirLight.intensity;
    // this.baseScene2.hemiLight.intensity = this.data.baseScene2.hemiLight.intensity;
    // this.baseScene2.ambientLight.intensity = this.data.baseScene2.ambientLight.intensity;
    ////



    this.createPalette();

    this.scene2.environment = this.scene.environment;
    // this.pmremGenerator = new THREE.PMREMGenerator(this.renderer2 as any);
    // new RGBELoader().setDataType(THREE.UnsignedByteType).setPath('assets/').load('adams_place_bridge_1k.hdr', (texture) => {
    //   const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
    //   //    scene.background = envMap;
    //   this.scene2.environment = envMap;
    //   texture.dispose();
    //   this.renderer2.render(this.scene2, this.camera2); // warum auch immer bei scene1 nicht nötig
    // });


    this.renderer.render(this.scene, this.camera);
  }


  // componentToHex(c) {
  //  var hex = c.toString(16);
  //  return hex.length == 1 ? "0" + hex : hex;
  // }
  componentToHex(c) {
    var hex = Math.round(c).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copy() {
    // copy to clipboard
    this.copyMessage(this.cellvalue);
    this.dialogRef.close();

  }
  apply() {
    this.dialogRef.close(this.cellvalue);

  }

  animate() {

    window.requestAnimationFrame(() => {
      var delta = this.clock.getDelta();
      this.controls.update(delta);
      this.renderer.render(this.scene, this.camera);
      this.animate()
    }
    );


  }
  paletteClick(e) {

    const canvasTarget = document.getElementById('threematerialscontainer2');

    // three js hit test
    var mouse = new THREE.Vector2();
    mouse.x = (e.offsetX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.offsetY / canvasTarget.clientHeight) * 2 + 1;

    //  mouse.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    //   mouse.y = -(this.mouse.y / window.innerHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera2);
    var intersects = raycaster.intersectObjects(this.scene2.children);
    if (intersects.length > 0) {
      for (var i = 0; i < intersects.length; i++) {
        var obj = intersects[i].object as any;


        if (obj.type == "Mesh") {
          this.curmaterial.emissive = obj.material.emissive;
          this.curmaterial.metalness = obj.material.metalness;
          this.curmaterial.roughness = obj.material.roughness;
          this.curmaterial.reflectivity = obj.material.reflectivity;
          this.curmaterial.shininess = obj.material.shininess,
            this.curmaterial.color = "#" + this.componentToHex(obj.material.color.r * 255.0) + this.componentToHex(obj.material.color.g * 255.0) + this.componentToHex(obj.material.color.b * 255.0);
          this.curmaterial.opacity = obj.material.opacity;

          this.onChange(null, null, obj);
          //  emissive: this.curmaterial.emissive,
          //  //    specular: this.curmaterial.specular,
          //  //  shininess: this.curmaterial.shininess,
          //  roughness: this.curmaterial.roughness,
          //  metalness: this.curmaterial.metalness,
          //  reflectivity: this.curmaterial.reflectivity,
          //  opacity: this.curmaterial.opacity
          console.log(obj.material);
          // this.selectedNode = parent;
          // this.selectedSubprojectID = parent.userData.subproject;
        }
      }
    }

  }
}
