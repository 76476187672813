<app-sidebar></app-sidebar>




<!-- <div style="backg2round-color:rgb(244, 244, 244);height:100%"> -->


<div class="container" style="margin-left:340px;padding: 20px;">
  <div class="gap10"></div>


  <!-- <mat-card>
  <mat-card-content> -->
  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <h2>Settings</h2>
      </td>
      <td><button mat-raised-button color="primary" style="margin-left:20px;" (click)="save()">Save</button></td>
      <td></td>
      <!-- <td>
        <button mat-raised-button>Filter</button>
      </td> -->
      <!-- <td>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center">
              <mat-slide-toggle [id]="34343"> fasdfas </mat-slide-toggle>
              <mat-slide-toggle [id]="3433343">fdsfs fasdfas </mat-slide-toggle>
            </div>
          </td> -->
      <td>

      </td>
    </tr>
  </table>
  <!-- </mat-card-content>
</mat-card> -->

  <div class="gap10"></div>
  <form [formGroup]="contactForm">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="center stretch">
      <mat-card fxFlex="50%">
        <mat-card-content>
          <h3>My Account</h3>
          <div fxLayout="column">
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="first name" formControlName="firstName" /> </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="text" placeholder="last name" formControlName="lastName" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="Street" formControlName="street" />
              </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="number" placeholder="Street No" formControlName="streetno" /> </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="City" formControlName="city" />
              </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="number" placeholder="Zip" formControlName="zip" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex="50%"> <input matInput type="text" placeholder="country" formControlName="country" /> </mat-form-field>
            </div>

            <div style="margin-top:22px;font-size:22px;font-weight:bold;">Subscription Plan: <span style="">{{user?.plan}}</span></div>

            <!-- <hr />
             <div class="input-row"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex> <input matInput type="text" placeholder="e-mail" formControlName="email" /> </mat-form-field>
            <mat-form-field fxFlex> <input matInput type="password" placeholder="Password" formControlName="password" /> </mat-form-field>
          </div> -->
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="50%">
        <mat-card-content>
          <h3>Settings</h3>


          <ngx-auth-firebaseui [signInTabText]="'ngxauthfirebaseui.signInTabText' | translate" [signInCardTitleText]="'ngxauthfirebaseui.signInCardTitleText' | translate" 
            [loginButtonText]="'ngxauthfirebaseui.loginButtonText' | translate" [forgotPasswordButtonText]="'ngxauthfirebaseui.forgotPasswordButtonText' | translate" [nameText]="'ngxauthfirebaseui.nameText' | translate"
            [nameErrorRequiredText]="'ngxauthfirebaseui.nameErrorRequiredText' | translate" [nameErrorMinLengthText]="'ngxauthfirebaseui.nameErrorMinLengthText' | translate"
            [nameErrorMaxLengthText]="'ngxauthfirebaseui.nameErrorMaxLengthText' | translate" [emailText]="'ngxauthfirebaseui.emailText' | translate"
            [emailErrorRequiredText]="'ngxauthfirebaseui.emailErrorRequiredText' | translate" [emailErrorPatternText]="'ngxauthfirebaseui.emailErrorPatternText' | translate"
            [passwordText]="'ngxauthfirebaseui.passwordText' | translate" [passwordErrorRequiredText]="'ngxauthfirebaseui.passwordErrorRequiredText' | translate"
            [registerTabText]="'ngxauthfirebaseui.registerTabText' | translate" [registerCardTitleText]="'ngxauthfirebaseui.registerCardTitleText' | translate"
            [registerButtonText]="'ngxauthfirebaseui.registerButtonText' | translate" [guestButtonText]="'ngxauthfirebaseui.guestButtonText' | translate"
            [resetPasswordTabText]="'ngxauthfirebaseui.resetPasswordTabText' | translate" [resetPasswordInputText]="'ngxauthfirebaseui.resetPasswordInputText' | translate"
            [resetPasswordErrorRequiredText]="'ngxauthfirebaseui.resetPasswordErrorRequiredText' | translate" [resetPasswordErrorPatternText]="'ngxauthfirebaseui.resetPasswordErrorPatternText' | translate"
            [resetPasswordActionButtonText]="'ngxauthfirebaseui.resetPasswordActionButtonText' | translate" [resetPasswordInstructionsText]="'ngxauthfirebaseui.resetPasswordInstructionsText' | translate"
            [emailConfirmationTitle]="'ngxauthfirebaseui.emailConfirmationTitle' | translate" [emailConfirmationText]="'ngxauthfirebaseui.emailConfirmationText' | translate">
          </ngx-auth-firebaseui>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="gap10"></div>


  </form>


</div>

<!-- </div> -->