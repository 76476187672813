import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import AIManager from '../editor/aimanager';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemPrompt } from './system-prompt';
@Component({
    selector: 'app-ai-prompt-editor',
    templateUrl: './ai-prompt-editor.component.html',
    styleUrls: ['./ai-prompt-editor.component.css']
})
export class AiPromptEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() PROJECT: string = '';
    @Input() sheetid: number = 0;
    @Input() excels: any[] = [];
    @Input() renderer: any;
    @Input() scene: any;
    @Input() camera: any;

    @Output() copyTableData = new EventEmitter<any>();
    private apiUrl = 'https://us-central1-xbuild3d.cloudfunctions.net'; // Replace with your actual Firebase Function URL

    private hotRegisterer = new HotTableRegisterer();

    hotid = 'hotInstance';
    //    @ViewChild("hot", { static: false }) hotContainer!: ElementRef;
    @Input() showAIPromptEditor: boolean = false;
    @Input() sheetformulas: any;

    hotsettings: any;
    aiPrompt: string = '';


    @Output() aiPromptChangee = new EventEmitter<string>();

    @Output() aiIterationChange = new EventEmitter<any>();

    @ViewChildren('promptTextarea') promptTextareas!: QueryList<ElementRef>;

    currentTabData: any[][] = [];

    private hotInstance: Handsontable | null = null;
    data: any;
    tabs: { id: number; prompt: string; data: any[][]; attachments: any[] }[] = [
        { id: 0, prompt: '', data: this.generateRandomData(5, 5), attachments: [] }
    ];
    currentTabId: number = 0;

    aiProvider: 'claude' | 'openai' = 'claude';
    temperature: number = 0.7;



    constructor(private http: HttpClient, private cdr: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.currentTabData = this.tabs[0].data;
        this.updateHandsontableData();
    }

    ngAfterViewInit() {
        // Delay the initialization to ensure the view is fully rendered
        setTimeout(() => {
            this.initializeHandsontable();
        });
    }

    ngOnDestroy() {
        if (this.hotInstance) {
            this.hotInstance.destroy();
        }
    }

    initializeHandsontable() {
        // if (this.hotContainer && this.hotContainer.nativeElement) {
        //     const container = this.hotContainer.nativeElement;
        //     const hotSettings = {
        //         licenseKey: 'non-commercial-and-evaluation',
        //         data: this.currentTabData,
        //         rowHeaders: true,
        //         colHeaders: true,
        //         height: '100%',
        //         width: '100%',
        //         contextMenu: true,
        //         manualColumnResize: true,
        //         manualRowResize: true,
        //         comments: true,
        //         fillHandle: true,
        //         autoColumnSize: true,
        //         autoRowSize: true,
        //     };

        //     this.hotInstance = new Handsontable(container, hotSettings);
        // } else {
        //     console.error('Hot container not found');
        // }
    }

    onTabChange(index: number) {
        this.currentTabId = index;
        this.currentTabData = this.tabs[index].data;
        this.aiPrompt = this.tabs[index].prompt || '';
        this.updateHandsontableData();
        // Update attachments
        this.attachments = this.tabs[index].attachments;
    }

    updateHandsontableData() {
        if (this.hotInstance) {
            this.hotInstance.loadData(this.currentTabData);
            this.hotInstance.render(); // Force re-render
        }
    }



    addTab() {
        const newTabId = this.tabs.length;
        this.tabs.push({ id: newTabId, prompt: '', data: this.generateRandomData(5, 5), attachments: [] });
        this.onTabChange(newTabId);
        this.cdr.detectChanges();
    }

    checkResult() {
        //     this.tabs[this.currentTabId].data = this.data;

    }

    generateRandomData(rows: number, cols: number): any[][] {
        const data = [];
        for (let i = 0; i < rows; i++) {
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(Math.floor(Math.random() * 100)); // Random number between 0 and 99
            }
            data.push(row);
        }
        return data;
    }

    async onAISubmit() {
        try {
            this.isLoading = true;
            var aiManager = new AIManager(this.http);

            this.attachments = this.tabs[this.currentTabId].attachments;

            let table = this.attachments.find(a => a.type === 'values' || a.type === 'formulas');
            let base64images = this.attachments.filter(a => a.type === 'image');

            // Remove empty rows from table if it exists
            if (table) {
                let values = table.formulas;
                if (Array.isArray(values)) {
                    let lastNonEmptyRow = values.length - 1;
                    while (lastNonEmptyRow >= 0) {
                        const row = values[lastNonEmptyRow];
                        if (row.some(cell => cell !== "")) {
                            break;
                        }
                        lastNonEmptyRow--;
                    }
                    if (lastNonEmptyRow >= 0) {
                        table.formulas = values.slice(0, lastNonEmptyRow + 1);
                    }
                }
            }

            let systemPrompt = SystemPrompt.systemPrompt;

            if (table) {
                systemPrompt += `You have already created a table which you should edit. Please edit the following table: "${table}".`;
            }
            if (base64images?.length > 0) {
                const imageCount = base64images.length;
                systemPrompt += `You have ${imageCount} image${imageCount !== 1 ? 's' : ''} of the model of the current situation.`;
            }

            var prompt = this.tabs.find(tab => tab.id === this.currentTabId)?.prompt || '';

            if (this.feedbackLoop && this.iterations > 0 && this.iteration > 0) {
                systemPrompt += SystemPrompt.checkPrompt;
            }

            // Choose API based on selected provider
            const endpoint = this.aiProvider === 'claude' ?
                '/createClaudePrompt' :
                '/createOpenAIPrompt';

            const response = await fetch(this.apiUrl + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userInput: prompt,
                    context: '',
                    systemPrompt,
                    base64images,
                    table: JSON.stringify(table?.formulas),
                    temperature: this.temperature // Only used by OpenAI
                })
            });

            if (!response.ok) {
                throw new Error('API request failed');
            }

            const data = await response.json();
            this.response = data.response;
            if (typeof this.response === 'string') {
                // Convert the string to a 2D array, e.g., by splitting it
                this.response = JSON.parse(this.response);
                //    this.tabs[this.currentTabId].data = this.response.split('\n').map(row => row.split(','));
            }
            this.tabs[this.currentTabId].data = this.response as any;
            this.attachments = [];
            this.currentTabData = this.tabs[this.currentTabId].data;
            this.updateHandsontableData();

            const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
            this.copyTableData.emit({ data: currentTab.data, run: null });

            if (this.feedbackLoop && this.iterations > 0 && this.iteration == 0) {
                this.aiIterationChange.emit({
                    aiPrompt: this.aiPrompt,
                    data: this.currentTabData,
                    iteration: this.iteration
                });
                return;
            }

        } catch (error) {
            console.error('Error in onAISubmit:', error);
        } finally {
            this.isLoading = false;
        }
    }

    async getPrompt(prompt: string) {
        try {
            this.isLoading = true;
            const endpoint = this.aiProvider === 'claude' ?
                '/createPromptExample' :
                '/createPromptExampleOpenAI';

            const response = await fetch(this.apiUrl + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    text: prompt,
                    temperature: this.temperature // Only used by OpenAI
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            this.tabs[this.currentTabId].prompt = data.response;

        } catch (error) {
            console.error('Error getting prompt example:', error);
        } finally {
            this.isLoading = false;
        }
    }

    aiPromptChange(event: any) {
        this.aiPrompt = event.target.value;
        this.aiPromptChangee.emit(this.aiPrompt);
        // Update the current tab's prompt
        const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
        if (currentTab) {
            currentTab.prompt = this.aiPrompt;
        }
    }



    iteration = 0;
    async nextIteration() { // wird vom editor aufgerufen
        this.iteration++;
        console.log('nextIteration', this.iteration);

        if (this.iteration > this.iterations) {
            this.iteration = 0;
            return;
        }

        console.log('addTab');
        var lastdata = this.tabs[this.currentTabId].data;


        await this.addTab();
        this.currentTabId = this.tabs.length - 1;
        const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);

        console.log('addScreenshot');
        await this.addScreenshot('model');

        currentTab.prompt = "You should have created a 3d model of simple geometries. An abstract presentations." +
            " Prompt was: \n\n" + this.tabs[0].prompt +
            "\n\nThe table you created was:\n\n " + this.tabs[this.currentTabId - 1].data +
            "\n\nCheck if it is represented in the attached image. Make it better or correct it. check orientations, positions, rotations of the objects." +
            "\n\nIf you can't identify the objects, take the table you created and make changes to it what you think is right. Give always only the table as 2d json array back! ";
        console.log('onAISubmit');
        await this.onAISubmit();

        console.log('aiIterationChange.emit');
        this.aiIterationChange.emit({ aiPrompt: this.aiPrompt, data: currentTab.data, iteration: this.iteration });

    }

    loadingText = '';
    async do5runs() {
        //   this.tabs = [];
        let prompt = this.tabs[0].prompt;
        this.attachments = this.tabs[0].attachments;
        this.isLoading = true;
        let table = this.attachments.find(a => a.type === 'values' || a.type === 'formulas');

        // Track progress of each promise
        let completedPromises = 0;
        const totalPromises = 5;

        const promises = Array(totalPromises).fill(null).map(async (_, i) => {
            var aiManager = new AIManager(this.http);
            let base64images = this.attachments.filter(a => a.type === 'image');
            let systemPrompt = SystemPrompt.systemPrompt;

            try {
                let response = await aiManager.chatWithClaude(prompt, systemPrompt, base64images, JSON.stringify(table?.formulas));
                completedPromises++;


                // Update UI with progress
                const progress = Math.round((completedPromises / totalPromises) * 100);
                this.loadingText = `Processing request ${completedPromises} of ${totalPromises} (${progress}%)`;

                this.copyTableData.emit({ data: response, run: i });
                await new Promise(resolve => setTimeout(resolve, 2000));
                return response;
            } catch (error) {
                completedPromises++;
                console.error(`Error in promise ${i + 1}:`, error);
                throw error;
            }
        });

        const responses = await Promise.all(promises);
        this.response = responses[responses.length - 1];
        this.loadingText = 'Processing complete';
        this.isLoading = false;
    }

    base64screenshotsChange(event: any) {
        this.attachments = event.target.value;

    }

    add2Sheet() {
        const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
        this.copyTableData.emit({ data: currentTab.data, run: 0 });
    }



    userInput: string = '';
    context: string = '';
    response: string = '';
    isLoading: boolean = false;
    screenshotDropdown = false;
    tableMarkingDropdown = false;
    tableMarkingType: string = 'values';
    attachments = [];
    feedbackLoop = false;
    iterations = 1;

    async addScreenshot(typ: string) {
        // Capture screenshot of the 3D scene
        const screenshot = this.renderer.domElement.toDataURL('image/png');
        // Create a new attachment object
        const newscreenshot = {
            type: typ,
            isScreenshot: true,
            index: this.attachments.length,
            data: screenshot,
            timestamp: new Date().toISOString()
        };

        try {

            this.renderer.render(this.scene, this.camera);
            const blob = await new Promise<Blob>(resolve => this.renderer.domElement.toBlob(resolve));
            const base64data = await new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.readAsDataURL(blob);
            });
            newscreenshot.data = base64data;
            const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
            if (currentTab) {
                currentTab.attachments.push(newscreenshot);
                console.log('Screenshot added:', newscreenshot);
            }

        } catch (error) {
            console.error('Screenshot failed:', error);
        }

    }
    removeScreenshot(index: number) {
        const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
        if (currentTab) {
            currentTab.attachments.splice(index, 1);
        }
    }

    async addTableMarking(type: string) {
        this.tableMarkingType = type;
        this.tableMarkingDropdown = false;
        // Add table marking to attachments
        const tableMarking = {
            type: type,
            markingType: this.tableMarkingType,
            index: this.attachments.length,
            timestamp: new Date().toISOString(),
            excelRange: "",
            selectedRange: {
                start: { sheet: 0, col: 0, row: 0 },
                end: { sheet: 0, col: 0, row: 0 }
            },
            formulas: [[]],
            values: [[]]
        };


        if (this.sheetformulas) {
            tableMarking.formulas = this.sheetformulas;
        }

        // Get selected cells from the Handsontable instance
        if (this.hotInstance) {
            const selectedCells = this.hotInstance.getSelected();
            if (selectedCells && selectedCells.length > 0) {
                const [startRow, startCol, endRow, endCol] = selectedCells[0];
                const selectedRange = {
                    start: { sheet: this.sheetid, col: startCol, row: startRow },
                    end: { sheet: this.sheetid, col: endCol, row: endRow }
                };

                var cellstart = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: startRow, col: startCol }, this.sheetid);
                var cellend = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: endRow, col: endCol }, this.sheetid);
                tableMarking.excelRange = cellstart + ":" + cellend;
                tableMarking.selectedRange = selectedRange; //  hfInstance.getRangeFormulas({ start: { sheet: 0, col: 0, row: 0 }, end: { sheet: 0, col: 1, row: 1 } });
                tableMarking.formulas = await this.excels[this.PROJECT].getRangeSerialized(selectedRange);
                tableMarking.values = await this.excels[this.PROJECT].getRangeValues(selectedRange);




                console.log('Selected cells tableMarking:', tableMarking);
            } else {
                console.log('No cells selected');
            }
        } else {
            console.log('No Handsontable instance found for the current tab');
        }

        const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
        if (currentTab) {
            currentTab.attachments.push(tableMarking);
        }
    }


    addImage() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const imageData = e.target.result;
                    const tableMarking = {
                        data: imageData,
                        type: 'image',
                        markingType: 'uploaded',
                        index: this.attachments.length,
                        timestamp: new Date().toISOString(),
                        excelRange: "",
                        selectedRange: null,
                        formulas: null,
                        values: null
                    };
                    const currentTab = this.tabs.find(tab => tab.id === this.currentTabId);
                    if (currentTab) {
                        currentTab.attachments.push(tableMarking);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();




    }

    adjustTextareaHeight(event: any): void {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }

}


