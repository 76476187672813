<!-- <div [excels]="excels" [hf]="hf" [sheetid]="sheetid" [CONFIGURATION]="CONFIGURATION" [dataset]="dataset"
    [showAnimations]="showAnimations"></div> -->

<div class="row" style="padding: 6px 3px;">
    <div class="col-md-12">
        <mat-tab-group>
            <mat-tab label="Own Projects">
                <mat-form-field class="search-field" appearance="outline">
                    <mat-label>Search own projects</mat-label>
                    <input matInput [(ngModel)]="searchText" (keyup)="filterProjects()">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <div style="height:80vh; overflow: overlay;">
                    <div class="projectbtn" *ngFor="let p of filteredProjects">
                        <img *ngIf="p.image" [src]="p.image" (click)="projectSelected(p)" />
                        <div *ngIf="!p.image" style="width: 130px; height: 130px; background-color: white;" (click)="projectSelected(p)"></div>
                        <div>{{p.name}}</div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Public Projects">
                <mat-form-field class="search-field" appearance="outline">
                    <mat-label>Search public projects</mat-label>
                    <input matInput [(ngModel)]="publicSearchText" (keyup)="filterPublicProjects()">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <div style="height:10px;"></div>
                <!-- Show tree when no search, show flat list when searching -->
                <ng-container *ngIf="!publicSearchText">
                    <app-examples [treenodes]="nodes" [idstr]="'publicCategoryID'"></app-examples>
                </ng-container>

                <ng-container *ngIf="publicSearchText">
                    <div class="projectbtn" *ngFor="let p of filteredPublicProjects">
                        <img [src]="p.image" (click)="projectSelected(p)" />
                        <div>{{p.name}}</div>
                    </div>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>