import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yesnodialog',
  templateUrl: './yesnodialog.component.html',
  styleUrls: ['./yesnodialog.component.scss']
})
export class YesnodialogComponent implements OnInit {

  text: string;
  constructor(public dialogRef: MatDialogRef<YesnodialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.text;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close("yes");
  }


  ngOnInit(): void {
  }

}
