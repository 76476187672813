exports.nodes = {
    root: [
        {
            "id": "buildings",
            "name": "Buildings",
            "children": [
                {
                    "name": "House"
                },
                {
                    "name": "Commercial"
                },
                {
                    "name": "Industrial"
                }
            ]
        },
        {
            "id": "machine-parts",
            "name": "Machine Parts",
            "children": [
                {
                    "id": "bearings",
                    "name": "Bearings"
                },
                {
                    "id": "profiles",
                    "name": "Profiles",
                    "children": [
                        {
                            "id": "profiles-round",
                            "name": "Round"
                        },
                        {
                            "id": "profiles-squared",
                            "name": "Squared"
                        }
                    ]
                },
                {
                    "id": "connections",
                    "name": "Connections",
                    "children": [
                        {
                            "id": "connections-profiles",
                            "name": "Profile connections"
                        },
                        {
                            "id": "connections-other",
                            "name": "Other"
                        }
                    ]
                }
            ]
        },
        {
            "id": "science",
            "name": "Science",
            "children": [
                {
                    "id": "molecules",
                    "name": "Molecules"
                },
                {
                    "id": "math",
                    "name": "Math",
                    "children": [
                        {
                            "id": "math-nurbssurface",
                            "name": "Nurbs Surface"
                        },
                        {
                            "id": "math-exponentialcurve",
                            "name": "Exponential Curve"
                        }
                    ]
                }
            ]
        },
        {
            "id": "other",
            "name": "Other"
        }
    ]
};