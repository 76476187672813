<h2 style="font-weight:bold;font-size:22px;">Menu Appearance</h2>
<hr>
<div class="row" style="height:430px">

    <div class="col-md-5">
        <input type="text" class="form-control" style="position: absolute;top:-200px;">
        <!-- <h5>Type</h5>
        <mat-radio-group [(ngModel)]="project?.menuSettings.menutype" (change)="valuechange()">
            <mat-radio-button value="movable">Movable</mat-radio-button>&nbsp;&nbsp;
            <mat-radio-button value="docked">Docked</mat-radio-button>
        </mat-radio-group> -->

        <div class="row" style="margin-top:30px; margin-bottom:20px;">
            <div class="col-md-6">
                <h5>Accent Color</h5>
                <input class="cpinput form-control" style="width:100px;margin-bottom:25px;" name="test"
                    [value]="project?.menuSettings.accentcolor" [style.background]="project?.menuSettings.accentcolor"
                    [(colorPicker)]="project?.menuSettings.accentcolor" (colorPickerChange)="onChange()"
                    [(ngModel)]="project?.menuSettings.accentcolor" />
                <h5>Background Color</h5>
                <input class="cpinput form-control" style="width:100px;margin-bottom:25px;" name="test"
                    [value]="project?.menuSettings.backgroundcolor"
                    [style.background]="project?.menuSettings.backgroundcolor"
                    [(colorPicker)]="project?.menuSettings.backgroundcolor" (colorPickerChange)="onChange()"
                    [(ngModel)]="project?.menuSettings.backgroundcolor" />
            </div>
            <div class="col-md-6">
                <h5>Text Color</h5>
                <input class="cpinput form-control" style="width:100px;margin-bottom:25px;" name="test"
                    [value]="project?.menuSettings.textcolor" [style.background]="project?.menuSettings.textcolor"
                    [(colorPicker)]="project?.menuSettings.textcolor" (colorPickerChange)="onChange()"
                    [(ngModel)]="project?.menuSettings.textcolor" />

                <h5>Button Text Color</h5>
                <input class="cpinput form-control" style="width:100px;margin-bottom:25px;" name="test"
                    [value]="project?.menuSettings.buttontextcolor"
                    [style.background]="project?.menuSettings.buttontextcolor"
                    [(colorPicker)]="project?.menuSettings.buttontextcolor" (colorPickerChange)="onChange()"
                    [(ngModel)]="project?.menuSettings.buttontextcolor" />
            </div>
        </div>

        <h5>Position</h5>
        <mat-radio-group [(ngModel)]="project?.menuSettings.menufloat" (change)="valuechange()">
            <mat-radio-button value="left">Left</mat-radio-button>&nbsp;&nbsp;
            <mat-radio-button value="right">Right</mat-radio-button>
        </mat-radio-group>


        <!-- <h5>Initial Camera</h5>
        <select style="margin-bottom:25px;width:200px;" class="form-control"
         [(ngModel)]="project.initialcamera">
            <option *ngFor="let cam of project.cameras; let i=index" [value]="cam">Camera {{i}}</option>
        </select> -->
    </div>
    <div class="col-md-7">
        <div>
            <h5>Preview</h5>
            <svg w2idth="252mm" h2eight="184mm" viewBox="0 0 132.6407 96.849086">
                <g transform="translate(0)">
                    <rect style="fill:#b3b3b3;stroke-width:0.264583" id="rect1241" width="132.14656" height="97.886345"
                        x="0" y="0" ry="0.44493791" />

                    <g [attr.transform]="'translate('+trafo+')'">
                        <rect *ngIf="project?.menuSettings.menutype=='movable'"
                            [attr.transform]="'translate('+trafo2+')'"
                            [attr.fill]="project?.menuSettings.backgroundcolor" style="stroke-width:0.140365"
                            id="rect739" width="45.383678" height="74.527107" x="6.6740685" y="8.4538221" />
                        <rect *ngIf="project?.menuSettings.menutype=='docked'"
                            [attr.transform]="'translate('+trafo2+')'"
                            [attr.fill]="project?.menuSettings.backgroundcolor" style="stroke-width:0.140365"
                            id="rect739" width="45.383678" height="110" x="0" y="0" />
                        <!--  -->

                        <rect style="fill:#b3b3b3;stroke-width:0.0460385" id="rect849-7" width="23.804182"
                            height="0.88987428" x="19.79974" y="17.686285" ry="0.015611829" />
                        <ellipse [attr.fill]="project?.menuSettings.accentcolor" style="stroke-width:0.174281"
                            id="path1239" cx="32.035534" cy="18.215452" rx="1.5572827" ry="1.6685171" />
                        <rect [attr.fill]="project?.menuSettings.accentcolor" style="sstroke-width:0.125491"
                            id="rect849" width="23.581713" height="6.6740675" x="19.799747" y="28.253561"
                            ry="0.11708891" />
                        <rect style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:0.0992116;stroke-opacity:1"
                            id="rect849-2" width="20.938364" height="4.6981258" x="20.898945" y="44.258186"
                            ry="0.08242327" />


                        <text xml:space="preserve" [attr.fill]="project?.menuSettings.textcolor"
                            style="font-size:3.175px;fill-opacity:1;stroke-width:0.264583" x="9.7886314" y="25.361464"
                            id="text1351">
                            <tspan sodipodi:role="line" id="tspan1349" style="fill-opacity:1;stroke-width:0.264583"
                                x="9.7886314" y="25.361464">Label</tspan>
                        </text>
                        <text xml:space="preserve" [attr.fill]="project?.menuSettings.textcolor"
                            style="font-size:3.175px;fill-opacity:1;stroke-width:0.264583" x="9.7390633" y="41.902447"
                            id="text1351-5">
                            <tspan sodipodi:role="line" id="tspan1349-2" style="fill-opacity:1;stroke-width:0.264583"
                                x="9.7390633" y="41.902447">Textbox Label</tspan>
                        </text>
                        <text xml:space="preserve" [attr.fill]="project?.menuSettings.buttontextcolor"
                            style="font-size:3.175px;fill-opacity:1;stroke-width:0.264583" x="26.424236" y="32.781219"
                            id="text1351-5-5">
                            <tspan sodipodi:role="line" id="tspan1349-2-2" style="fill-opacity:1;stroke-width:0.264583"
                                x="26.424236" y="32.781219">Button</tspan>
                        </text>
                        <text xml:space="preserve" [attr.fill]="project?.menuSettings.textcolor"
                            style="font-size:3.175px;fill-opacity:1;stroke-width:0.264583" x="9.5165977" y="14.316297"
                            id="text1351-9">
                            <tspan sodipodi:role="line" id="tspan1349-0" style="fill-opacity:1;stroke-width:0.264583"
                                x="9.5165977" y="14.316297">Slider Label</tspan>
                        </text>
                    </g>
                </g>
            </svg>

        </div>
    </div>
</div>
<hr>

<div class="row">
    <div class="col-md-12">
        <button style="float:right;" class="btn btn-primary" (click)="dialogRef.close()">Close</button>
    </div>
</div>