class SnippetManager {

    public codeSnippets = [

        {
            name: "get cell",
            code: 'async function getCell () {\n\tvar await cell = await ActiveWorkbook.getCellSerialized({ sheet: 0, row: 2, col: 4});\n\tconsole.log(cell);\n\tawait API.updateUI();\n}\n\n'
        },
        {
            name: "set cell",
            code: 'async function setCell () {\n\tawait ActiveWorkbook.setCellContents({ sheet: 0, row: 1, col: 1 }, "hello world");\n\tawait API.updateUI();\n}\n\n'
        },
        // {
        //     name: "edit 3D scene",
        //     code: "async function readCell () {\n\t\n}\n\n"
        // },
        {
            name: "ONLOADED",
            code: 'async function ONLOADED() {\n\t$(document).ready(async function() {\n\t\tconsole.log("DOM loaded");\n\t\tawait API.updateUI();\n\t});\n}\n\n'
        },

        {
            name: "object mouse enter",
            code: 'async function ONENTER(object) {\n\tconsole.log("blue ", object);\n\tobject.material.color.set("#ff0000");\n\t// await ActiveWorkbook.setCellContents(\n\t// { sheet: 0, row: 35, col: 5}, "#ff0000;0.15;0.8;0.4;1"\n\t// );\n\n\tawait API.updateUI();\n}\n\n'
        },
        {
            name: "object mouse leave",
            code: 'async function ONLEAVE(object) {\n\tconsole.log("blue ", object);\n\tobject.material.color.set("#ff0000");\n\t// await ActiveWorkbook.setCellContents(\n\t// { sheet: 0, row: 35, col: 5}, "#ff0000;0.15;0.8;0.4;1"\n\t// );\n\n\tawait API.updateUI();\n}\n\n'
        },


        {
            name: "project selected",
            code: 'async function projectSelected(project) {\n\tconsole.log("project selected", project);\n\tawait API.updateUI();\n}\n\n'
        },
        {
            name: "project deselected",
            code: 'async function projectDeselected(project) {\n\tconsole.log("project deselected", project);\n\tawait API.updateUI();\n}\n\n'
        },

        {
            name: "sheet selected",
            code: 'async function sheetSelected(sheet) {\n\tconsole.log("sheet selected", sheet);\n\tawait API.updateUI();\n}\n\n'
        },
        {
            name: "sheet deselected",
            code: 'async function sheetDeselected(sheet) {\n\tconsole.log("sheet deselected", sheet);\n\tawait API.updateUI();\n}\n\n'
        },


        // {
        //     name: "set configuration params",
        //     code: 'async function setConfigurationParams(sheetId, paramNames, params) {\n\tconsole.log("set configuration params", sheetId, paramNames, params);\n\tfor (let i = 0; i < paramNames.length; i++) {\n\t\tawait ActiveWorkbook.setCellContents({ sheet: sheetId, row: 1, col: i + 1 }, paramNames[i]);\n\t\tawait ActiveWorkbook.setCellContents({ sheet: sheetId, row: 2, col: i + 1 }, params[i]);\n\t}\n\tawait API.updateUI();\n}\n\n'
        // },

        // {
        //     name: "get active config",
        //     code: 'async function getActiveConfig() {\n\tconst config = await API.getActiveConfig();\n\tconsole.log("active config", config);\n\treturn config;\n}\n\n'
        // },

        // {
        //     name: "save config",
        //     code: 'async function saveconfig(id?: string) {\n\tconst config = await API.getActiveConfig();\n\tif (id) {\n\t\tconfig.id = id;\n\t}\n\tawait API.saveConfig(config);\n\tconsole.log("config saved", config);\n\tawait API.updateUI();\n}\n\n'
        // },

        // {
        //     name: "load config",
        //     code: 'async function loadconfig(configid: string) {\n\tconst config = await API.loadConfig(configid);\n\tconsole.log("config loaded", config);\n\tawait API.updateUI();\n\treturn config;\n}\n\n'
        // },

        //         async function test1() {

        //             var i = 0;
        //             let myinterval = setInterval(async () => {
        //                 console.log('i ' + i++);
        //                 await ActiveWorkbook.setCellContents({ sheet: 0, row: 1, col: 0 }, i / 15.0 * 4)
        //                 await API.updateUI();

        //                 if (i === 1) {
        //                     clearInterval(myinterval);
        //                 }
        //             }, 10);
        //         }

        // async function select(object, event) {
        //             console.log("#select", object, event);
        //             await API.TransformControls.attach(sceneobjectSelected);
        //             console.log(API.TransformControls)
        //             scene.add(API.TransformControls);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 29, col: 6 }, sceneobjectSelected.position.x)
        //             await API.updateUI();
        //         }
        // async function deselect(object, event) {
        //             console.log("#deselect", object.object, event);

        //             await API.TransformControls.detach(sceneobjectDeSelected);
        //             console.log(API.TransformControls)
        //             scene.remove(API.TransformControls);
        //             //  await ActiveWorkbook.setCellContents({ sheet: 0, row: 29, col: 6 }, enteredobject.position.x ) 
        //             await API.updateUI();
        //         }



        // // object highlighht on hover and selection on click
        // async function enter(object, event) {

        //         }
        // async function leave(object, event) {
        //             console.log("#leave", object, event);
        //             //    API.TransformControls.detach(object);
        //             //   await ActiveWorkbook.setCellContents({ sheet: 0, row: 32, col: 5 }, "gray") 
        //             await API.updateUI();
        //         }



        // // drag arrow
        // async function startdrag(object, event) {
        //             console.log("#startdrag", object, event);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 6 }, true);
        //             await API.updateUI();
        //         }
        // async function drag(object, event) {
        //             //  API.deactivateMouse();   
        //             // var dragging = await ActiveWorkbook.getCellSerialized({ sheet: 0, row: 28, col: 6 }); 
        //             console.log("#drag", object, event);

        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 7 }, event.point.y)
        //             await API.updateUI();
        //             //      API.deactivateMouse();   

        //         }
        // async function enddrag(object, event) {
        //             console.log("#enddrag", object, event);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 6 }, false);
        //             await API.updateUI();
        //             //  API.activateMouse();
        //         }

    ]

    public snippets = [
        {
            name: "head1",
            values: [
                ["INPUTID", "TYP", "PARAMETERS", "VALIDATED", "", "", "LABEL", "UNIT", "", "", "", "", "", "PARENT", ""],
            ],
            format: [
                ["head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell"],
            ]
        },



        // input widgets
        // 'label', 'textbox', 'number', 'image', 'select', 'checkbox','slider', 'expansion', 'tab','stepper','window'

        {
            name: "label",
            values: [
                ["label", "mylabel", "=##", "", "Label", true]
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "textbox",
            values: [
                ["textbox", "mytext", "=##", "", "Textbox", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "number",
            values: [
                ["number", "5", "=IF(##<0;0;##)", "", "Number", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "image",
            values: [
                ["image", "dummyimage.png", "=##", "", "Image", true]
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "select",
            values: [
                ["select", "entry1", '=##', "", "Select", true, "", "", "entry1;entry2;entry3"] // if-Verschachtelung zu kompliziert?
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "checkbox",
            values: [
                ["checkbox", false, "=##", "", "Checkbox", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "radio",
            values: [
                ["radio", false, "=##", "", "Radio", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "slider",
            values: [
                ["slider", 5, "=##", "", "Slider", true, 0, 10] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "colorchooser",
            values: [
                ["colorchooser", "#442200", "=##", "", "Color", true, 0, 10] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "expansion",
            values: [
                ["expansion", "1", "=##", "", "Expansion", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "tab",
            values: [
                ["tab", "1", "=##", "", "Tab", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "stepper",
            values: [
                ["stepper", "1", "=##", "", "Stepper", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "window",
            values: [
                ["window", "1", "=##", "", "Window", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "html",
            values: [
                ["html", "<strong style='color:red;font-size:20px;font-style:italic;'>Hello World</strong>", "=##", "", "HTML", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "button",
            values: [
                ["button", "1", "=##", "", "Button", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "savebutton",
            values: [
                ["button", "save", "save", "", "Save", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "exportbutton",
            values: [
                ["button", "export", "export", "", "Export", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            //            timer23	timer	40		6.10780000000075		true
            name: "timer",
            values: [
                ["timer", "40", "=##", "", "Timer", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },

        // 3d outputs
        {
            name: "line",
            values: [
                ["", "p1", "p2", "width", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["line", "(0,0,50)", "(0,100,50)", 5, "black", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Line",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'p1', info: 'Start point, Format: (x,y,z)' },
                    { name: 'p2', info: 'End point, Format: (x,y,z)' },
                    { name: 'width', info: 'Width, Format (x)' },
                    { name: 'material', info: 'test' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "shape",
            values: [
                ["", "points", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["shape", "(0,0);(0,100);(50,100);(0,50)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Shape (2d)",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'points', info: 'List of points, Format: (x1,y1,z1);(x2,y2,z2);...' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "image3d",
            values: [
                ["", "filename", "width", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["image3d", "dummy.png", 300, 200, "#555555", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Image",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                    { name: 'filename', info: 'The filename of the image' },
                    { name: 'width', info: 'The width of the image' },
                    { name: 'height', info: 'The height of the image' },
                ]
            }

        }, {
            name: "surface",
            values: [
                ["", "points", "indices", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["surface", "(0,0,0);(0,0,0);(0,0,0);(0,0,0);(0,0,0)", "(1,2,3)", "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Surface",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'points', info: 'List of points, Format: (x1, y1, z1);(x2, y2, z2); ...' },
                    { name: 'indices', info: 'List of point indices, 3 indices create a tringale' },
                    { name: 'material', info: '' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "cube",
            values: [
                ["", "width", "length", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["cube", 100, 100, 100, "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Cube",
                link: "https://sheetbuild.com/documentation-cube",
                params: [
                    { name: 'width', info: 'width off the cube' },
                    { name: 'length', info: 'length off the cube' },
                    { name: 'height', info: 'height off the cube' },
                    { name: 'material', info: 'The material' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "sphere",
            values: [
                ["", "radius", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["sphere", 10, "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Sphere",
                link: "https://sheetbuild.com/documentation-sphere",
                params: [
                    { name: 'Radius', info: 'radius off the sphere' },
                    { name: 'material', info: 'The material' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "extrusion",
            values: [
                ["", "baseplane", "extrusionvector", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["extrusion", "(0,0);(100,0);(200,100);(0,100)", "(0,0,300)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Extrusion",
                link: "https://sheetbuild.com/documentation-extrusion",
                params: [
                    { name: 'baseplane', info: 'Defines the 2D shape to be extruded in the XY plane. Format: (x1,y1);(x2,y2);... Points define a closed polygon.' },
                    { name: 'extrusionvector', info: 'Direction and length of extrusion. Format: (x,y,z). Z component MUST be non-zero. For standard vertical extrusions, only use Z component (e.g. (0,0,100)). X,Y components create sheared extrusions.' },
                    { name: 'x,y,z', info: 'Position of the extrusion base in 3D space' },
                    { name: 'rx,ry,rz', info: 'Rotation angles in degrees. Applied after extrusion to orient the final shape.' },
                    { name: 'material', info: 'Material/color of the extruded shape' }
                ]
            }

        },

        {
            name: "cylinder",
            values: [
                ["", "radiusTop", "radiusBottom", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["cylinder", 50, 50, 200, "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Cylinder",
                link: "https://sheetbuild.com/documentation-cylinder",
                params: [
                    { name: 'radiusTop', info: 'radius on the top' },
                    { name: 'radiusBottom', info: 'radius on the bottom' },
                    { name: 'height', info: 'the height/length' },
                    { name: 'material', info: 'The material' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "lathe",
            values: [
                ["", "points", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["lathe", "(0,0);(100,0);(0,100)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Lathe",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'points', info: '' },
                    { name: 'material', info: 'The material' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "arrow",
            values: [
                ["", "start", "end", "width", "material", "headLength", "headWidth", "x", "y", "z", "rx", "ry", "rz"],
                ["arrow", "(0,0,0)", "(100,0,0)", 2, "#000000", 10, 5, 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Arrow",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'start', info: 'Start point of the arrow, Format: (x,y,z)' },
                    { name: 'end', info: 'End point of the arrow, Format: (x,y,z)' },
                    { name: 'width', info: 'Width of the arrow line' },
                    { name: 'material', info: 'Color of the arrow' },
                    { name: 'headLength', info: 'Length of the arrow head' },
                    { name: 'headWidth', info: 'Width of the arrow head' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "subtraction",
            values: [
                ["", "cuttedoutputids", "cutoutputids", "x", "y", "z", "rx", "ry", "rz"],
                ["subtraction", "extrusion1,extursion2", "extrusion3", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "subtraction",
                link: "https://sheetbuild.com/documentation-subtraction",
                params: [
                    { name: 'cuttedoutputids', info: 'List of  Ids of cutted objects' },
                    { name: 'cutoutputids', info: 'List of objects which cut' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "textflat",
            values: [
                ["", "textflat", "fontsize", "color", "align", "x", "y", "z", "rx", "ry", "rz"],
                ["textflat", "Your Text", 8, "#222222", "center", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Textflat",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'textflat', info: 'The text (content)' },
                    { name: 'fontsize', info: 'size' },
                    { name: 'color', info: 'the color' },
                    { name: 'align', info: 'text alignment (left, center, right)' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "group",
            values: [
                ["", "outputids", "x", "y", "z", "rx", "ry", "rz"],
                ["group", "extrusion1,extrusion2", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Group",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'outputids', info: 'List of  Ids of objects' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "ibeam",
            values: [
                ["", "profile", "width", "height", "length", "flangeThickness", "webThickness", "material", "startSlope", "endSlope", "x", "y", "z", "rx", "ry", "rz"],
                ["ibeam", "IPE 200", "", "", "150", "", "", "#445566", "0", "0", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "I-Beam",
                link: "https://sheetbuild.com/documentation-i-beam",
                params: [
                    { name: 'profile', info: 'Standard profile (e.g., "IPE 200", "HEA 180", "HEB 220") - leave empty for custom dimensions' },
                    { name: 'width', info: 'Total width of the I-beam (only used if profile is not specified)' },
                    { name: 'height', info: 'Total height of the I-beam (only used if profile is not specified)' },
                    { name: 'length', info: 'Length of the I-beam' },
                    { name: 'flangeThickness', info: 'Thickness of the flanges (only used if profile is not specified)' },
                    { name: 'webThickness', info: 'Thickness of the web (only used if profile is not specified)' },
                    { name: 'material', info: 'Material color/texture' },
                    { name: 'startSlope', info: 'Angle in degrees for the cut at the start of the beam (0 = perpendicular)' },
                    { name: 'endSlope', info: 'Angle in degrees for the cut at the end of the beam (0 = perpendicular)' },
                    { name: 'x,y,z', info: 'Position coordinates' },
                    { name: 'rx,ry,rz', info: 'Rotation angles' }
                ]
            }
        },
        {
            name: "aluprofile",
            values: [
                ["", "series", "size", "length", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["aluprofile", "ITEM", "ITEM-40x40-8", 1000, "#A4A4A4", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Aluminum Profile",
                link: "https://sheetbuild.com/documentation-aluminum-profile",
                params: [
                    { name: 'series', info: 'Profile series (BASIC, LIGHT, HEAVY, ECONOMIC, DIN, ISO, INDUSTRIAL, ITEM)' },
                    { name: 'size', info: 'Profile size designation (e.g., "ITEM-40x40-8", "DIN-30x30")' },
                    { name: 'length', info: 'Length of the profile in mm' },
                    { name: 'material', info: 'Material color/texture' },
                    { name: 'x,y,z', info: 'Position coordinates' },
                    { name: 'rx,ry,rz', info: 'Rotation angles' }
                ]
            }
        },


        {
            name: "light",
            values: [
                ["", "intensity", "penumbra", "color", "x", "y", "z", "rx", "ry", "rz"],
                ["light", 200, 100, "#ffffff", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Light",
                link: "https://sheetbuild.com/documentation-light",
                params: [
                    { name: 'intensity ', info: 'rlight intensity, valuues 1-100' },
                    { name: 'penumbra', info: 'penumbra, value 1-100' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        }, {
            name: "gltf",
            values: [
                ["", "filename", "x", "y", "z", "rx", "ry", "rz", "sx", "sy", "sz"],
                ["gltf", "/assets/test.gltf", 0, 0, 0, 0, 0, 0, 1, 1, 1],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "gltf/glb 3D File",
                link: "https://sheetbuild.com/documentation-gltf",
                params: [
                    { name: 'filename', info: 'url of the file' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                    { name: 'node=[name]', info: 'name of the gltf node, property e.g.: "visible=FALSE"' }
                ]
            }
        },
        // ---------------- overlays ------------------
        {
            name: "overlay",
            values: [
                ["", "width", "height", "backgroundcolor", "x", "y"],
                ["overlay", "80%", "400px", "#ffffff", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Overlay",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'width', info: 'rectangle width of background' },
                    { name: 'height', info: 'rectangle height of background' },
                    { name: 'backgroundcolor', info: 'the background color' },
                    { name: 'material', info: 'The material' },
                    { name: 'x,y', info: 'position' },
                ]
            }
        },
        {
            name: "overlayhtml",
            values: [
                ["", "html", "x", "y"],
                ["overlayhtml", "<div>Hello World</div>", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell"]
            ],
            infos: {
                header: "Overlay HTML",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'html', info: 'html code' },
                    { name: 'x,y', info: 'position' },
                ]
            }
        },
        {
            name: "chart",
            values: [
                ["", "width", "height", "backgroundcolor", "type", "values", "x", "y"],
                ["chart", "600px", "400px", "#ffffff", "bar", "A2:D7", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Chart",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'width', info: 'radius on the top' },
                    { name: 'height', info: 'radius on the bottom' },
                    { name: 'backgroundcolor', info: 'the height/length' },
                    { name: 'type', info: 'bar chart' },
                    { name: 'values', info: 'cell range of values, format  ([topleft cell]:[rightdown cell])' },
                    { name: 'x,y', info: 'position' },
                ]
            }
        },

        //https://docs.google.com/spreadsheets/d/1SW_Ic11KG-GsFqQziNL9n9ROAzLugXCCf6bzxDA5O-0/edit#gid=0
        // line2D
        // circle2D
        // polyline2D
        // path2D
        // group

        {
            name: "rectangle2d",
            values: [
                ["", "width", "height", "backgroundcolor", "border", "bordercolor", "x", "y"],
                ["rectangle2d", "400px", "150px", "#dddddd", "2px", "#bbbbbb", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },
        {
            name: "text2d",
            values: [
                ["", "text", "width", "height", "color", "fontsize", "backgroundcolor", "x", "y"],
                ["text2d", "yourtext", "200px", "100px", "#000000", "22px", "#ffffff", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },
        {
            name: "image2d",
            values: [
                ["", "width", "height", "url", "x", "y"],
                ["image", "400px", "300px", "", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },

        {
            name: "connector",
            values: [
                ["", "label", "normal", "x", "y", "z", "rx", "ry", "rz", "sx", "sy", "sz"],
                ["connector", "1", "(1,0,0)", 0, 0, 0, 0, 0, 0, 1, 1, 1],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Connector Point",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'label', info: 'label of the connector' },
                    { name: 'normal', info: 'normal vector of the connector' },
                    { name: 'x,y,z', info: 'Position of the connector point' },
                    { name: 'rx,ry,rz', info: 'Rotation of the connector point' },
                    { name: 'sx,sy,sz', info: 'Scale factors' }
                ]
            }
        },

        {
            name: "dimensionline",
            values: [
                ["", "p1", "p2", "width", "material", "orthogonalLength", "orthogonalOffset", "dashed", "rotation", "textSize", "textBackground", "editcell", "x", "y", "z", "rx", "ry", "rz"],
                ["dimensionline", "(0,0,50)", "(0,100,50)", 2, "#000000", 40, 0, "false", 0, 10, "rgba(255,255,255,0.7)", "", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Dimension Line",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'p1', info: 'Start point, Format: (x,y,z)' },
                    { name: 'p2', info: 'End point, Format: (x,y,z)' },
                    { name: 'width', info: 'Line width' },
                    { name: 'material', info: 'Line color' },
                    { name: 'orthogonalLength', info: 'Total length of orthogonal end lines (in world units)' },
                    { name: 'orthogonalOffset', info: 'Offset of orthogonal lines from endpoints' },
                    { name: 'dashed', info: 'true/false for dashed line style' },
                    { name: 'rotation', info: 'Rotation around line axis in degrees' },
                    { name: 'textSize', info: 'Size of dimension text' },
                    { name: 'textBackground', info: 'Background color of text sprite (rgba format)' },
                    { name: 'editcell', info: 'Cell reference to edit when clicking dimension text (e.g. "B5")' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "grid",
            values: [
                ["", "width", "length", "mainGap", "subGap", "nthLabel", "labelSize", "lineWidth", "mainColor", "subColor", "labelColor", "showSubLines", "showLabels", "startX", "endX", "startY", "endY", "labelOffsetX", "labelOffsetY", "x", "y", "z", "rx", "ry", "rz"],
                ["grid", 1000, 1000, 100, 50, 2, 40, 1, "#000000", "#666666", "#000000", "true", "true", "", "", "", "", 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell", "header_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Measuring Grid",
                link: "https://sheetbuild.com/documentation/",
                params: [
                    { name: 'width', info: 'Total width of the grid' },
                    { name: 'length', info: 'Total length of the grid' },
                    { name: 'mainGap', info: 'Distance between main grid lines' },
                    { name: 'subGap', info: 'Distance between sub grid lines' },
                    { name: 'nthLabel', info: 'Show label every nth main line' },
                    { name: 'labelSize', info: 'Size of measurement labels' },
                    { name: 'lineWidth', info: 'Width of grid lines' },
                    { name: 'mainColor', info: 'Color of main grid lines' },
                    { name: 'subColor', info: 'Color of sub grid lines' },
                    { name: 'labelColor', info: 'Color of measurement labels' },
                    { name: 'showSubLines', info: 'Show or hide sub grid lines (true/false)' },
                    { name: 'showLabels', info: 'Show or hide measurement labels (true/false)' },
                    { name: 'startX', info: 'Start X coordinate for partial grid (optional)' },
                    { name: 'endX', info: 'End X coordinate for partial grid (optional)' },
                    { name: 'startY', info: 'Start Y coordinate for partial grid (optional)' },
                    { name: 'endY', info: 'End Y coordinate for partial grid (optional)' },
                    { name: 'labelOffsetX', info: 'Value added to X-axis labels (e.g., 100 will shift labels from -500..500 to -400..600)' },
                    { name: 'labelOffsetY', info: 'Value added to Y-axis labels (e.g., 100 will shift labels from -500..500 to -400..600)' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "export button",
            values: [
                ["button", "Export Scene", "exportScene", "", "Export 3D Scene", true]
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },

    ]

}


export { SnippetManager }