import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  news: any;
  constructor(public afs: AngularFirestore) {
    this.afs.collection('news').valueChanges({
      idField: 'id'
    }).subscribe(data => {
      console.log(data);
      this.news = data;
    });


  }

  ngOnInit(): void {
  }

}
