import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdmininvoicesComponent } from './admin/invoices/admininvoices.component';
import { LogComponent } from './admin/log/log.component';
import { UsersComponent } from './admin/users/users.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorComponent } from './editor/editor.component';
import { FilesComponent } from './files/files.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoginComponent } from './login/login.component';

import { LoggedInGuard } from 'ngx-auth-firebaseui';
import { SettingsComponent } from './settings/settings.component';
import { ConfigsComponent } from './configs/configs.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { AdmindashComponent } from './admin/admindash/admindash.component';
import { HallConfiguratorsComponent } from './hall-configurators/hall-configurators.component';
import { UserAnalyticsComponent } from './admin/user-analytics/user-analytics.component';
// import { SaveReminderGuard } from './guards/save-reminder.guard';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [LoggedInGuard] },
  { path: 'e/:projectid', component: EditorComponent, canActivate: [LoggedInGuard] },

  { path: 'configurations/:projectid', component: ConfigsComponent, canActivate: [LoggedInGuard] },
  { path: 'hall-configurators', component: HallConfiguratorsComponent, canActivate: [LoggedInGuard] },

  { path: 'p/:projectid', component: EditorComponent },
  { path: 'p/:projectid/:configid', component: EditorComponent },
  { path: 'tasks', component: EditorComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [LoggedInGuard] },
  { path: 'files', component: FilesComponent, canActivate: [LoggedInGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'invoicesettings', component: InvoicesComponent, canActivate: [LoggedInGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [LoggedInGuard] },


  { path: 'admin', component: AdmindashComponent, canActivate: [LoggedInGuard] },
  { path: 'admin/logs', component: LogComponent, canActivate: [LoggedInGuard] },
  { path: 'admin/users', component: UsersComponent, canActivate: [LoggedInGuard] },
  { path: 'admin/projects', component: ProjectsComponent, canActivate: [LoggedInGuard] },
  { path: 'admin/analytics', component: UserAnalyticsComponent, canActivate: [LoggedInGuard] },

  {
    path: 'editor/:projectid',
    component: EditorComponent,
    // canDeactivate: [SaveReminderGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {



}
