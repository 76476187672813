import { Options } from '@angular-slider/ngx-slider';
import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-gui',
  templateUrl: './gui.component.html',
  styleUrls: ['./gui.component.scss']
})
export class GuiComponent implements OnInit {

  lang = 'de';
  @Input() jsongui: any;
  @Input() projectdata: any;

  @Input() celllabels: any = true;

  focusedColorPicker: string | null = null;
  isColorPickerOpen = false;

  @Output() conrolChanged = new EventEmitter<any>();
  // public keepOriginalOrder = (a, b) :any=> {
  //   a.key as any
  // };

  //vgl https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
  keepOriginalOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return 0;
  }


  cpToggleChange(event: any) {
    console.log('cpToggleChange', event);
    this.isColorPickerOpen = event;
  }

  pickerclose(test, event: any) {
    console.log('pickerclose', event);
    this.isColorPickerOpen = false;
    this.focusedColorPicker = null;
  }

  // Close color picker when clicking on the GUI panel
  closeColorPicker() {
    if (this.isColorPickerOpen || this.focusedColorPicker) {
      this.isColorPickerOpen = false;
      this.focusedColorPicker = null;
    }
  }

  slideroptions: any;
  valueSubject = new BehaviorSubject<number>(0);

  constructor(private cdr: ChangeDetectorRef) {
    const newOptions: Options = Object.assign({}, this.slideroptions);
    newOptions.ceil = 100;
    this.slideroptions = newOptions;
  }

  ngOnInit(): void {
    this.valueSubject.pipe(debounceTime(5)).subscribe(value => {
      // http request can go here
      //  console.log('debounce', JSON.stringify(value));
      this.conrolChanged.emit(value);
    });
  }
  // onSlide(event: MatSliderChange) {
  // }

  sliderChange(event, item) {
    item.value.outputValue = event.value;
    console.log('onchanc', event);
    this.valueSubject.next(item.value);
    if (event) {
      // item.value = event.value;

    }
    //    item.value.outputValue = event;
  }

  radiochange(event, item) {
    console.log('radiochange', event, item.value);
    //    this.controlC(item.value);
  }
  controlC(value: any) {
    if (value.typ != "COLORCHOOSER")
      this.closeColorPicker();

    //console.log('controlC', value);
    if (value?.outputValue === 'exportScene') {
      this.conrolChanged.emit({ action: 'exportScene' });
    } else {
      this.conrolChanged.emit(value);
    }
  }
  controlUp(item: any) {
    item.outputValue++;
    this.controlC(item);
  }
  controlDown(item: any) {
    item.outputValue--;
    this.controlC(item);

  }
  focusControl(controlName: any) {
    this.focusedColorPicker = controlName;
    this.isColorPickerOpen = true;
  }

  blurControl() {
    // Don't close immediately to allow clicking on the color picker
    setTimeout(() => {
      if (!this.isColorPickerOpen) {
        this.focusedColorPicker = null;
      }
    }, 200);
  }

  nbrChange(event, item) {
    console.log('nbrChange', event);
    item.value.outputValue = event;
    this.cdr.detectChanges();
    this.controlC(item.value);

  }
}
