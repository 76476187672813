import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-user-analytics',
    templateUrl: './user-analytics.component.html',
    styleUrls: ['./user-analytics.component.scss']
})
export class UserAnalyticsComponent implements OnInit, OnDestroy {
    // Analytics data
    analyticsData: any[] = [];
    filteredData: any[] = [];
    dataSource = new MatTableDataSource<any>([]);
    userCount: number = 0;
    sessionCount: number = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Chart data
    eventTypeData: any[] = [];
    userActivityData: any[] = [];
    primitiveUsageData: any[] = [];
    performanceData: any[] = [];

    // Filters
    dateRange: { start: Date, end: Date };
    selectedEventTypes: string[] = [];
    selectedUsers: string[] = [];

    // Chart options
    colorScheme: any = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#4B77BE', '#2EADD3']
    };

    // Subscriptions
    private subscriptions = new Subscription();

    constructor(private afs: AngularFirestore) {
        // Set date range to last 7 days by default
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        this.dateRange = { start: startDate, end: endDate };
    }

    ngOnInit(): void {
        this.loadAnalyticsData();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Load analytics data from Firestore
     */
    loadAnalyticsData(): void {
        // Get data from the last 30 days (we'll filter further in the UI)
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const sub = this.afs.collection('analytics', ref =>
            ref.where('clientTimestamp', '>=', thirtyDaysAgo.getTime())
                .orderBy('clientTimestamp', 'desc')
                .limit(10000) // Reasonable limit for dashboard performance
        )
            .snapshotChanges()
            .pipe(
                map((actions: any[]) => actions.map(a => {
                    const data = a.payload.doc.data() as any;
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            )
            .subscribe(data => {
                this.analyticsData = data;
                this.applyFilters();
                this.prepareChartData();
            });

        this.subscriptions.add(sub);
    }

    /**
     * Apply date range and other filters to the analytics data
     */
    applyFilters(): void {
        // Filter by date range
        this.filteredData = this.analyticsData.filter(item => {
            const timestamp = item.clientTimestamp;
            return timestamp >= this.dateRange.start.getTime() &&
                timestamp <= this.dateRange.end.getTime();
        });

        // Filter by event types if selected
        if (this.selectedEventTypes.length > 0) {
            this.filteredData = this.filteredData.filter(item =>
                this.selectedEventTypes.includes(item.eventName)
            );
        }

        // Filter by users if selected
        if (this.selectedUsers.length > 0) {
            this.filteredData = this.filteredData.filter(item =>
                this.selectedUsers.includes(item.userId)
            );
        }

        // Update the data source
        this.dataSource.data = this.filteredData;

        // Calculate metrics
        this.calculateMetrics();
    }

    /**
     * Calculate key metrics from filtered data
     */
    calculateMetrics(): void {
        // Count unique users
        const uniqueUsers = new Set();
        this.filteredData.forEach(item => uniqueUsers.add(item.userId));
        this.userCount = uniqueUsers.size;

        // Count unique sessions
        const uniqueSessions = new Set();
        this.filteredData.forEach(item => uniqueSessions.add(item.sessionId));
        this.sessionCount = uniqueSessions.size;
    }

    /**
     * Prepare data for various charts
     */
    prepareChartData(): void {
        this.prepareEventTypeChart();
        this.prepareUserActivityChart();
        this.preparePrimitiveUsageChart();
        this.preparePerformanceChart();
    }

    /**
     * Prepare data for event type distribution chart
     */
    prepareEventTypeChart(): void {
        // Count events by type
        const eventCounts = {};
        this.filteredData.forEach(item => {
            if (!eventCounts[item.eventName]) {
                eventCounts[item.eventName] = 0;
            }
            eventCounts[item.eventName]++;
        });

        // Format for chart
        this.eventTypeData = Object.keys(eventCounts).map(key => {
            return {
                name: key,
                value: eventCounts[key]
            };
        });

        // Sort by count
        this.eventTypeData.sort((a, b) => b.value - a.value);

        // Limit to top 10
        if (this.eventTypeData.length > 10) {
            this.eventTypeData = this.eventTypeData.slice(0, 10);
        }
    }

    /**
     * Prepare data for user activity over time chart
     */
    prepareUserActivityChart(): void {
        // Group by day
        const activityByDay = {};

        this.filteredData.forEach(item => {
            const date = moment(item.clientTimestamp).format('YYYY-MM-DD');
            if (!activityByDay[date]) {
                activityByDay[date] = 0;
            }
            activityByDay[date]++;
        });

        // Format for chart
        this.userActivityData = Object.keys(activityByDay).map(key => {
            return {
                name: key,
                value: activityByDay[key]
            };
        });

        // Sort by date
        this.userActivityData.sort((a, b) => {
            return new Date(a.name).getTime() - new Date(b.name).getTime();
        });
    }

    /**
     * Prepare data for primitive usage chart
     */
    preparePrimitiveUsageChart(): void {
        // Filter to primitive_added events
        const primitiveEvents = this.filteredData.filter(item =>
            item.eventName === 'primitive_added'
        );

        // Count by primitive type
        const primitiveCounts = {};
        primitiveEvents.forEach(item => {
            if (!primitiveCounts[item.primitiveType]) {
                primitiveCounts[item.primitiveType] = 0;
            }
            primitiveCounts[item.primitiveType]++;
        });

        // Format for chart
        this.primitiveUsageData = Object.keys(primitiveCounts).map(key => {
            return {
                name: key,
                value: primitiveCounts[key]
            };
        });

        // Sort by count
        this.primitiveUsageData.sort((a, b) => b.value - a.value);
    }

    /**
     * Prepare data for performance chart
     */
    preparePerformanceChart(): void {
        // Filter to render_performance events
        const perfEvents = this.filteredData.filter(item =>
            item.eventName === 'render_performance'
        );

        // Group by complexity
        const perfByComplexity = {
            low: [],
            medium: [],
            high: []
        };

        perfEvents.forEach(item => {
            if (item.complexity && perfByComplexity[item.complexity]) {
                perfByComplexity[item.complexity].push(item.fps);
            }
        });

        // Calculate averages
        this.performanceData = Object.keys(perfByComplexity).map(key => {
            const values = perfByComplexity[key];
            const avg = values.length > 0
                ? values.reduce((sum, val) => sum + val, 0) / values.length
                : 0;

            return {
                name: `${key} complexity`,
                value: Math.round(avg)
            };
        });
    }

    /**
     * Update date range filter
     */
    updateDateRange(range: { start: Date, end: Date }): void {
        this.dateRange = range;
        this.applyFilters();
        this.prepareChartData();
    }

    /**
     * Update event type filter
     */
    updateEventTypeFilter(eventTypes: string[]): void {
        this.selectedEventTypes = eventTypes;
        this.applyFilters();
        this.prepareChartData();
    }

    /**
     * Update user filter
     */
    updateUserFilter(users: string[]): void {
        this.selectedUsers = users;
        this.applyFilters();
        this.prepareChartData();
    }

    /**
     * Export analytics data as CSV
     */
    exportDataAsCsv(): void {
        // Generate CSV content
        let csvContent = 'data:text/csv;charset=utf-8,';

        // Add headers
        csvContent += 'Timestamp,User ID,Session ID,Event Name,Event Data\n';

        // Add rows
        this.filteredData.forEach(item => {
            const timestamp = new Date(item.clientTimestamp).toISOString();
            const userId = item.userId;
            const sessionId = item.sessionId;
            const eventName = item.eventName;
            const eventData = JSON.stringify(item)
                .replace(/"/g, '""') // Escape quotes
                .replace(/,/g, ';'); // Replace commas with semicolons

            csvContent += `${timestamp},"${userId}","${sessionId}","${eventName}","${eventData}"\n`;
        });

        // Create download link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `user-analytics-${new Date().toISOString()}.csv`);
        document.body.appendChild(link);

        // Trigger download
        link.click();

        // Clean up
        document.body.removeChild(link);
    }

    // Property needed for the table expansion
    showExpansionRow = (index: number, rowData: any) => {
        return rowData.showDetails === true;
    };
} 