<div>
    <div class="dialog-header">
        <h4>Dimensions</h4>
    </div>
    <div class="dialog-content">
        <div>
            <strong>Factor</strong>
        </div>

        <div class="row">
            <div class="col-md-12">

                <mat-radio-group aria-label="Select an option" [(ngModel)]="factor">
                    <mat-radio-button [value]="0.1">0.1</mat-radio-button>
                    <mat-radio-button [value]="1">1</mat-radio-button>
                    <mat-radio-button [value]="10">10</mat-radio-button>
                    <mat-radio-button [value]="100">100</mat-radio-button>
                    <mat-radio-button [value]="1000">1000</mat-radio-button>
                </mat-radio-group>

            </div>
        </div>


        <div class="row">
            <div class="col-md-6" style="font-size: 12px;margin-top:10px;">
                <div style="font-weight:bold;">Dimensions</div>
                <div>x: {{data.node.userData.dimensions.x*factor}}</div>
                <div>y: {{data.node.userData.dimensions.y*factor}}</div>
                <div>z: {{data.node.userData.dimensions.z*factor}}</div>
            </div>
            <div class="col-md-6" style="font-size: 12px;margin-top:10px;">
                <div style="font-weight:bold;">Up Axis:</div>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="upaxis">
                    <mat-radio-button [value]="'X'">X</mat-radio-button>
                    <mat-radio-button [value]="'Y'">Y</mat-radio-button>
                    <mat-radio-button [value]="'Z'">Z</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>


    </div>


    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="{ factor: factor, upaxis:upaxis}" cdkFocusInitial>Apply Factor</button>
    </mat-dialog-actions>
</div>



<!-- <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="width">Width</label>
                    <input type="number" class="form-control" id="width" placeholder="Width" [(ngModel)]="data.node.userData.dimensions.x">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="height">Height</label>
                    <input type="number" class="form-control" id="height" placeholder="Height" [(ngModel)]="data.node.userData.dimensions.x">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="height">Depth</label>
                    <input type="number" class="form-control" id="height" placeholder="Height" [(ngModel)]="data.node.userData.dimensions.x">
                </div>
            </div>
        </div> -->