<!-- Auth Top Bar - Only shown when user is authenticated -->
<div *ngIf="isAuthenticated && !isEditMode" class="auth-top-bar">
    <div class="top-bar-content">
        <div class="top-bar-title">

            <!-- <div *ngIf="configid"><strong>ADMIN:</strong> {{configid}}</div> -->
            <div *ngIf="configmodel">
                <div><strong>Customer:</strong> {{configmodel?.enduserdata?.mail}}</div>
                <!-- <div><strong>Project:</strong> {{configmodel?.enduserdata?.projectname}}</div> -->
                <div><strong>Date:</strong> {{configmodel?.enduserdata?.savetime | date:'dd.MM.yyyy HH:mm'}}</div>
                <!-- <div><strong>Configuration:</strong> {{configmodel?.enduserdata?.configname}}</div> -->
            </div>
            <div *ngIf="!configid">No Configuration Selected</div>
        </div>
        <div class="top-bar-buttons">
            <button class="top-bar-btn" (click)="onExportScene()">
                <i class="fas fa-file-export"></i> Export
            </button>
            <button class="top-bar-btn" (click)="onCreateReport()">
                <i class="fas fa-file-alt"></i> Create Report
            </button>
            <button class="top-bar-btn" (click)="onCreatePartList()">
                <i class="fas fa-list"></i> Create Part List
            </button>

        </div>
    </div>
</div>