import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AbstractControl, FormControl } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'dialog-publish-dialog',
    templateUrl: 'dialogpublish.html',
})
export class PublishDialog {
    public disabled = false;
    public color: ThemePalette = 'primary';
    public touchUi = false;
    public disclaimer = "";
    window: any;

    colorCtr: AbstractControl = new FormControl(null);

    color1: any;
    publishedprojects: any;
    project: any; newproject: any;
    replaceid = null;
    step = 1;
    SERVER = "https://us-central1-xbuild3d.cloudfunctions.net";  //http://localhost:5001/xbuild3d/us-central1";




    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '230px',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        //     upload: (file: File) => { ... }
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [
                'undo',
                'redo',
                //         'bold',
                //         'italic',
                //         'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                //       'justifyLeft',
                //       'justifyCenter',
                //       'justifyRight',
                //       'justifyFull',
                'indent',
                'outdent',
                //       'insertUnorderedList',
                //       'insertOrderedList',
                //       'heading',
                'fontName'
            ],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                //           'link',
                //         'unlink',
                'insertImage',
                'insertVideo',
                //       'insertHorizontalRule',
                'removeFormat',
                //         'toggleEditorMode'
            ]
        ]
    };
    editorConfig2: AngularEditorConfig = {

    };

    constructor(public http: HttpClient, public dialogRef: MatDialogRef<PublishDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public afs: AngularFirestore) {
        this.color1 = "#ff0000";
        this.window = window;
        console.log("PublishDialog", this.data);
        this.editorConfig2 = JSON.parse(JSON.stringify(this.editorConfig));
        this.editorConfig2.height = '130px';

        this.project = this.data.project;
        if (!this.project.initialcamera)
            this.project.initialcamera = this.project.cameras[0];

        this.afs.collection("projectspublic/", ref => ref.orderBy('date', 'desc').where('userid', "==", this.data.userid)).valueChanges({ idField: 'id' }).subscribe(x => {
            if (x) {
                console.log('publishedprojects', x);
                this.publishedprojects = x;
            }
        });

    }

    private generateShortId(length: number = 4): string {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const timestamp = Date.now().toString(36).slice(-4);
        const randomPart = Array(length - 4).fill(0)
            .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
            .join('');
        return timestamp + randomPart;
    }

    async publish() {
        this.step = 2;
        this.newproject = JSON.parse(JSON.stringify(this.project));
        this.newproject.id = this.replaceid;
        this.newproject.userid = this.data.userid;
        this.newproject.publisdate = new Date().getTime();

        try {
            if (!this.replaceid) {
                this.newproject.id = this.generateShortId();
                console.log('Generated new short ID:', this.newproject.id);
            }
            this.newproject.publishtime = new Date().getTime();
            await this.afs.collection('projectspublic').doc(this.newproject.id).set(this.newproject);

            // Traverse all properties of configmodel
            // Replace configuration properties containing old project ID with new project ID
            const oldConfigId = this.data.userid + "_" + this.project.id;
            const newConfigId = this.data.userid + "_" + this.newproject.id;

            // Traverse all properties of configmodel
            // for (const key in this.newproject.configmodel) {
            //     if (this.newproject.configmodel.hasOwnProperty(key)) {
            //         const value = this.newproject.configmodel[key];

            //         // Check if value is an object with configuration property
            //         if (value && typeof value === 'object' && value.configuration) {
            //             // Replace old project ID with new project ID in configuration
            //             if (value.configuration === oldConfigId) {
            //                 value.configuration = newConfigId;
            //             }
            //         }
            //     }
            // }





            //  this.newproject.configmodel[this.data.userid + "_" + this.newproject.id] = this.newproject.configmodel[this.data.userid + "_" + this.project.id];

            // Update the document with the new configmodel
            // await docRef.update({
            //     //    configmodel: this.newproject.configmodel,
            //     id: this.newproject.id
            // });

            console.log('published with id:', this.newproject.id);

            var result = await ((this.http.post(this.SERVER + "/copySheets",
                {
                    customerid: this.data.userid,
                    projectid: this.project.id,
                    newprojectid: this.newproject.id,
                    sheetscount: this.project.sheets.length
                })).toPromise());
            this.step = 3;
            console.log('result', result);
        } catch (error) {
            console.error('Error publishing project:', error);
            throw error;
        }
    }

    publishtopublicdatabase() {

    }

    onChange(event = null, prop = null) {
        console.log('onchanc', event, prop);
    }

    onNoClick(): void {
        this.dialogRef.close('test');
    }


    onClose(): void {
        console.log('Dialog is closing');
        this.dialogRef.close();
    }
}
