<div class="row">
    <h4>Extrusion Baseplane</h4>
</div>

<div class="row" style="min-height: 495px;">
    <div class="col-md-9">

        <div>
            <svg viewBox="-300 -200 600 400" width="600px" height="400px" xmlns="http://www.w3.org/2000/svg"
                (mousemove)="this.mousemove($event)" (mouseup)="this.mouseup($event)">


                <g *ngFor="let i of xs">
                    <line [attr.x1]="i*gridsize" y1="-200" [attr.x2]="i*gridsize" y2="200" stroke="#dddddd" />
                </g>
                <g *ngFor="let i of ys">
                    <line [attr.y1]="i*gridsize" x1="-300" [attr.y2]="i*gridsize" x2="300" stroke="#dddddd" />
                </g>
                <line x1="-300" y1="0" x2="300" y2="0" stroke="red" />
                <line x1="0" y1="-200" x2="0" y2="200" stroke="green" />

                <!-- <line x1="0" y1="-10" x2="400" y2="-10" stroke="black" />
                <line x1="-10" y1="0" x2="-10" y2="400" stroke="black" /> -->


                <g [attr.transform]="'scale ('+-zoom+', '+zoom+')'">
                    <polygon [attr.points]="polyline" fill="#bbbbbb" stroke="#555555" stroke-width="2" />
                    <ng-container *ngFor="let c of circles;let i = index">

                        <rect *ngIf="i != currow" (mousedown)="this.mousedown($event, i)" stroke="black"
                            stroke-width="1" [attr.x]="c.cx-4" [attr.y]="c.cy-4" [attr.width]="8" [attr.height]="8"
                            fill="black" stroke-width="3" />
                        <rect *ngIf="i == currow" (mousedown)="this.mousedown($event, i)" stroke="red" stroke-width="1"
                            [attr.x]="c.cx-4" [attr.y]="c.cy-4" [attr.width]="8" [attr.height]="8" fill="red"
                            stroke-width="3" />
                    </ng-container>

                </g>

            </svg>
        </div>

        <button class="btn btn-default btn-sm" (click)="this.zoomIn()" style="margin-right:5px;"><i
                class="fa-solid fa-magnifying-glass-plus"></i> </button>
        <button class="btn btn-default btn-sm" (click)="this.zoomOut()"><i
                class="fa-solid fa-magnifying-glass-minus"></i></button>

    </div>
    <div class="col-md-3">
        <div class="hot-container">
            <mat-tab-group class="small-tabs">
                <mat-tab label="Shape">
                    <hot-table [hotId]="hotid" style="height:500px;" [afterSelection]="cellSelected" [width]="300"
                        [afterChange]="afterChange" [autoColumnSize]="true" [outsideClickDeselects]="false"
                        [manualColumnResize]="true" [colWidths]="80" [rowHeaders]="true" [data]="dataset"
                        [colHeaders]="['x','y']" [settings]="hotsettings">
                    </hot-table>
                </mat-tab>
                <mat-tab label="Hole">
                    <hot-table [hotId]="hotid2" style="height:500px;" [afterSelection]="cellSelected" [width]="300"
                        [afterChange]="afterChange" [autoColumnSize]="true" [outsideClickDeselects]="false"
                        [manualColumnResize]="true" [colWidths]="80" [rowHeaders]="true" [data]="dataset2"
                        [colHeaders]="['x','y']" [settings]="hotsettings">
                    </hot-table>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div style="position:absolute;top:200px;">

            <button class="btn btn-default btn-sm" style="margin-right:5px;" (click)="this.addPoint()">Add
                Point</button>
            <button class="btn btn-default btn-sm" (click)="this.removePoint()">Remove Point</button>
            <br><br>
            <mat-checkbox [(ngModel)]="snap">snap to grid</mat-checkbox><br>
            Grid size: <input class="form-control input-sm" type="number" [(ngModel)]="gridsize"
                (change)="gridchange()" />

            <br>
            <mat-checkbox [(ngModel)]="copytable" (change)="updateData()">copy table with references</mat-checkbox><br>
            Cell Address: <input class="form-control input-xs" type="text" [(ngModel)]="cellref"
                (change)="updateData()" />


        </div>

    </div>




</div>
<hr>


<div class="row" style="margin-top:10px;">
    <div class="col-md-12">
        <input class="form-control" type="text" style="width:70%;float:left;" [(ngModel)]="value1" />

        <button class="btn btn-default" style="float:right;" (click)="apply()">apply</button>
        <button class="btn btn-outline" style="float:right;margin-right:10px;"
            (click)="this.dialogRef.close()">cancel</button>
    </div>
</div>