

import * as THREE from "three";

// freie texturen: http://www.texturise.club
class Materials {


  public meshBasicMaterials = [
    { color: 0xff0000 }
  ];
  public meshMaterials = [
    // { type: "phong", color: 0xff0000, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0xdd0000, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x440000, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x00ff00, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x00dd00, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x004400, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x0000ff, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x0000dd, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    // { type: "phong", color: 0x000044, emissive: 0x000000, specular: 0xffffff, shininess: 50 },
    { type: "physical", roughness: 0.25, metalness: 0.9, reflectivity: 0.5, color: 0xff5555, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.5, metalness: 0.9, reflectivity: 0.5, color: 0xff5555, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.75, metalness: 0.9, reflectivity: 0.5, color: 0xff5555, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 1.0, metalness: 0.9, reflectivity: 0.5, color: 0xff5555, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao

    { type: "physical", roughness: 0.25, metalness: 0.1, reflectivity: 0.5, color: 0x525252, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.5, metalness: 0.1, reflectivity: 0.5, color: 0x525252, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.75, metalness: 0.1, reflectivity: 0.5, color: 0x525252, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 1.0, metalness: 0.1, reflectivity: 0.5, color: 0x525252, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao

    { type: "physical", roughness: 0.25, metalness: 0.9, reflectivity: 0.5, color: 0x00ff00, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.5, metalness: 0.9, reflectivity: 0.5, color: 0x00ff00, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.75, metalness: 0.9, reflectivity: 0.5, color: 0x00ff00, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 1.0, metalness: 0.9, reflectivity: 0.5, color: 0x00ff00, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao

    { type: "physical", roughness: 0.55, metalness: 0.4, reflectivity: 0.9, color: 0xbc8900, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.55, metalness: 0.5, reflectivity: 0.9, color: 0xbc8900, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.55, metalness: 0.7, reflectivity: 0.9, color: 0xbc8900, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.55, metalness: 0.9, reflectivity: 0.9, color: 0xbc8900, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao

    { type: "physical", roughness: 0.25, metalness: 0.9, reflectivity: 0.5, color: 0x0000ff, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.5, metalness: 0.9, reflectivity: 0.5, color: 0x0000ff, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.75, metalness: 0.9, reflectivity: 0.5, color: 0x0000ff, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 1.0, metalness: 0.9, reflectivity: 0.5, color: 0x0000ff, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao

    { type: "physical", roughness: 0.25, metalness: 0.1, reflectivity: 0.5, color: 0x16607a, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.5, metalness: 0.1, reflectivity: 0.5, color: 0x16607a, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 0.75, metalness: 0.1, reflectivity: 0.5, color: 0x16607a, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao
    { type: "physical", roughness: 1.0, metalness: 0.1, reflectivity: 0.5, color: 0x16607a, emissive: 0x000000, specular: 0xffffff, shininess: 0.5, opacity: 1 }, // außerdem map, roughnessMap, alphaMao


  ]




  textures: any;
  public materialsset: any;
  constructor() {
    this.textures = [];
    this.materialsset = [];
  }



  getTexture(filename: string, rotation: number) {
    for (var i = 0; i < this.textures.length; i++) {
      if (this.textures[i].filename === filename && this.textures[i].rotation === rotation)
        return this.textures[i];
    }
    return null;
  }

  getMaterial(materialname: string, rotation: number, texturen: boolean): THREE.MeshPhongMaterial {

    //   Materialdb.getmaterial();
    var m = materialname;
    var mat = null;
    if (m.includes("$"))
      m = m.substr(0, m.indexOf("$"));
    this.materialsset.push(m);
    for (var i = 0; i < this.materialsset.length - 1; i++) {
      if (this.materialsset[i] === m) {
        this.materialsset.pop();
        break;
      }
    }







    // default, wenn nicht in liste
    /*  const material = new THREE.MeshPhongMaterial({
          color: 0xffffff, wireframe: false, side: THREE.FrontSide, name: m,
          shininess: 100
      });*/

    const material = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: false,
      side: THREE.FrontSide,
      name: m,
      //specular: 0xffffff,
      shininess: materialname.includes("GL24H") ? 0 : 80
    });

    return material;

  }

}
export { Materials };
