import * as THREE from "three";
import { CSG } from 'three-csg-ts';
// //import { Utils } from './utils';
//import { HyperFormula } from 'hyperformula';

import _ from 'lodash';
//import { Materials } from "./materials";
// import * as Comlink from "comlink";

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GroupedObservable } from "rxjs";
import threeSpritetext from "three-spritetext";
import { Utils } from "./utils";
import { ThreeUtils } from "./threeutils";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
// import { ThreeUtils } from "./threeutils";
// import { AngularFireStorage } from "@angular/fire/storage";
// import { AngularFirestore } from "@angular/fire/firestore";
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { InteractionManager } from "./interaction-manager";
import { StepExporter } from './step-exporter';
import { IBeamStandards, IBeamStandard, IBeamDimensions } from './ibeam-standards';
import { AluminumProfiles, AluProfileSeries } from './aluminum-profiles';

// Add this interface before the class definition
interface RefPos {
    index: number;
    position: THREE.Vector3;
    worldPosition: THREE.Vector3;
    outputid: string;
    name: string;
    path: string;
    parentName?: string;
    parentPath?: string;
    origPos?: THREE.Vector3;
    normal?: THREE.Vector3;
}

class CADNodeEngine {
    testmesh: THREE.Mesh;
    interactionManager: InteractionManager;

    rows = [];

    spheres: any[];

    texturen = true;
    changedElements = [];

    loader: GLTFLoader;
    constructor() {
        THREE.Cache.enabled = true;
        //  console.log('cache', THREE.Cache.files)
        this.loader = new GLTFLoader();

        //     this.materials = new Materials();
    }




    // transformNodeFromRow(row: any[], commentrow: any[]) {
    //     var params = Utils.mapRowToParams(row, commentrow);
    //     var pos = params['position'].split(',');
    //     var rot = params['rotation'].split(',');

    //     var group = new THREE.Group();
    //     group.position.set(parseFloat(pos[0]), parseFloat(pos[1]), parseFloat(pos[2]));
    //     group.rotation.set(parseFloat(rot[0]), parseFloat(rot[1]), parseFloat(rot[2]));
    //     return group();

    // }


    // prop, nodename, value, object
    updateNodel(prop, nodename: string, value: any, scene: THREE.Scene) {
        //    if (nodetype == "material")
        scene.traverse((child) => {

            // node = door_lf_primary
            // visible = TRUE
            //  if (child.type === 'Mesh') {

            if (child?.name == nodename) {
                try {

                    if (value.toUpperCase() == 'TRUE')
                        value = true;
                    if (value.toUpperCase() == 'FALSE')
                        value = false;
                } catch (e) { }

                // Utils.setValue(child, prop, value);
                //    child[prop] = value;
                var props = prop.split('.');
                if (props.length > 1) {
                    if (props[0] == 'material') {
                        child['material'][props[1]].set(value);
                    } else
                        if (props[0] == 'scale') {
                            child['scale'][props[1]] = value;
                        } else {
                            child[props[0]][props[1]] = value;

                        }

                }
                else {
                    child[prop] = value;
                }

            }

            // if (child.material?.name == nodename) {
            //     child[prop].color = new THREE.Color(value);
            // }
            //   }
        });


    }

    grad2rad(grad) {
        return grad * Math.PI / 180;
    }


    diffTypeOfRow(commentRow, oldRow, newRow) {
        // Type: 0:none, 1:pos,rot,scale, 2:time, 3: recreate
        var typ = 0;

        if (commentRow)
            for (var i = 0; i < commentRow.length; i++) {

                if (oldRow[i] != newRow[i]) {
                    if (commentRow[i]) {

                        try {
                            if (commentRow[i].toLowerCase() == 'x' || commentRow[i].toLowerCase() == 'y' || commentRow[i].toLowerCase() == 'z'
                                || commentRow[i].toLowerCase() == 'rx' || commentRow[i].toLowerCase() == 'ry' || commentRow[i].toLowerCase() == 'rz'
                                || commentRow[i].toLowerCase() == 'sx' || commentRow[i].toLowerCase() == 'sy' || commentRow[i].toLowerCase() == 'sz') {
                                typ = 1;
                                continue;
                            }
                            if (commentRow[i].toLowerCase() == 'time') {
                                typ = 2;
                                continue;
                            }
                        } catch (e) {
                            console.warn('diffTypeOfRow', commentRow[i]);
                        }

                        typ = 3;
                        return typ;
                    }
                }
            }

        return typ;

    }

    public calculateFaceCenters(geometry) {
        const faceCenters = [];
        const faceNormals = [];
        const position = geometry.attributes.position;

        if (geometry.type === 'ExtrudeGeometry') {
            const shapeVertices = geometry.parameters.shapes.extractPoints().shape;
            const depth = geometry.parameters.options.depth;


            // Add corner points
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);

                faceCenters.push(vA);
                faceNormals.push(null);
            }
            // Calculate centers for the shape vertices
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, 0);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);

                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add corner points for the 0.5 extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth / 2);
                faceCenters.push(vB);
                faceNormals.push(null);
            }
            // Calculate centers for the  0.5 extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth / 2);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, depth / 2);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);
                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add corner points for the extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                faceCenters.push(vB);
                faceNormals.push(null);
            }
            // Calculate centers for the extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, depth);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);
                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }
            // Calculate centers for the planes on the extrusion depth
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);

                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add center points of extrusion shape
            const shapeCenter = new THREE.Vector3();
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                shapeCenter.add(new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0));
            }
            shapeCenter.divideScalar(shapeVertices.length - 1);
            faceCenters.push(shapeCenter);
            faceNormals.push(null);

            const extrudedShapeCenter = new THREE.Vector3(shapeCenter.x, shapeCenter.y, depth / 2);
            faceCenters.push(extrudedShapeCenter);
            faceNormals.push(null);
            const extrudedShapeCenter2 = new THREE.Vector3(shapeCenter.x, shapeCenter.y, depth);
            faceCenters.push(extrudedShapeCenter2);
            faceNormals.push(null);




        }
        if (geometry.type === 'BoxGeometry') {
            const halfWidth = geometry.parameters.width / 2;
            const halfHeight = geometry.parameters.height / 2;
            const halfDepth = geometry.parameters.depth / 2;

            // Define the 6 face centers of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, 0, 0),   // +X face
                new THREE.Vector3(-halfWidth, 0, 0),  // -X face
                new THREE.Vector3(0, halfHeight, 0),  // +Y face
                new THREE.Vector3(0, -halfHeight, 0), // -Y face
                new THREE.Vector3(0, 0, halfDepth),   // +Z face
                new THREE.Vector3(0, 0, -halfDepth)   // -Z face
            );

            // Define the 8 corner points of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, halfHeight, halfDepth),
                new THREE.Vector3(halfWidth, halfHeight, -halfDepth),
                new THREE.Vector3(halfWidth, -halfHeight, halfDepth),
                new THREE.Vector3(halfWidth, -halfHeight, -halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, -halfDepth),
                new THREE.Vector3(-halfWidth, -halfHeight, halfDepth),
                new THREE.Vector3(-halfWidth, -halfHeight, -halfDepth)
            );

            // Define the 12 middle points on the edges of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, halfHeight, 0),
                new THREE.Vector3(halfWidth, -halfHeight, 0),
                new THREE.Vector3(halfWidth, 0, halfDepth),
                new THREE.Vector3(halfWidth, 0, -halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, 0),
                new THREE.Vector3(-halfWidth, -halfHeight, 0),
                new THREE.Vector3(-halfWidth, 0, halfDepth),
                new THREE.Vector3(-halfWidth, 0, -halfDepth),
                new THREE.Vector3(0, halfHeight, halfDepth),
                new THREE.Vector3(0, halfHeight, -halfDepth),
                new THREE.Vector3(0, -halfHeight, halfDepth),
                new THREE.Vector3(0, -halfHeight, -halfDepth)
            );

            // Define the 6 face normals of a box
            faceNormals.push(
                new THREE.Vector3(1, 0, 0),   // +X normal
                new THREE.Vector3(-1, 0, 0),  // -X normal
                new THREE.Vector3(0, 1, 0),   // +Y normal
                new THREE.Vector3(0, -1, 0),  // -Y normal
                new THREE.Vector3(0, 0, 1),   // +Z normal
                new THREE.Vector3(0, 0, -1)   // -Z normal
            );

            // Add null normals for corner and edge points
            for (let i = 0; i < 20; i++) {
                faceNormals.push(null);
            }
        }
        if (geometry.type === 'CylinderGeometry') {
            //  console.log("###ConeGeometry", geometry)
            const halfHeight = geometry.parameters.height / 2;
            const halfRadiusBottom = geometry.parameters.radiusBottom / 2;
            const halfRadiusTop = geometry.parameters.radiusTop / 2;

            // Define the center point of the cone
            const center = new THREE.Vector3(0, 0, 0);
            faceCenters.push(center);
            faceNormals.push(null);

            // Define the center points of the flat sides
            const baseCenter = new THREE.Vector3(0, -halfHeight, 0);
            const topCenter = new THREE.Vector3(0, halfHeight, 0);
            faceCenters.push(baseCenter);
            faceCenters.push(topCenter);
            faceNormals.push(new THREE.Vector3(0, -1, 0));
            faceNormals.push(new THREE.Vector3(0, 1, 0));

            // Define 4 points around the base of the cone
            const angles = [0, Math.PI / 2, Math.PI, 3 * Math.PI / 2];
            angles.forEach(angle => {
                const xBase = Math.cos(angle) * halfRadiusBottom * 2;
                const zBase = Math.sin(angle) * halfRadiusBottom * 2;
                const basePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                faceCenters.push(basePoint);
                faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());


                // const xBase = Math.cos(angle) * halfRadiusBottom;
                // const zBase = Math.sin(angle) * halfRadiusBottom;
                // const basePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                // faceCenters.push(basePoint);
                // faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());

                // Define points on the mantle surface
                const xTop = Math.cos(angle) * halfRadiusTop * 2;
                const zTop = Math.sin(angle) * halfRadiusTop * 2;
                const mantleBasePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                const mantleTopPoint = new THREE.Vector3(xTop, halfHeight, zTop);
                faceCenters.push(mantleBasePoint);
                faceCenters.push(mantleTopPoint);
                faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());
                faceNormals.push(new THREE.Vector3(xTop, 0, zTop).normalize());
            });
        }

        if (geometry.type === 'SphereGeometry') {
            const radius = geometry.parameters.radius;

            // Define the center point of the sphere
            const center = new THREE.Vector3(0, 0, 0);
            faceCenters.push(center);
            faceNormals.push(new THREE.Vector3(0, 0, 1)); // Normal pointing outwards

            // Define points on each side of the sphere
            const directions = [
                new THREE.Vector3(1, 0, 0),  // Right
                new THREE.Vector3(-1, 0, 0), // Left
                new THREE.Vector3(0, 1, 0),  // Top
                new THREE.Vector3(0, -1, 0), // Bottom
                new THREE.Vector3(0, 0, 1),  // Front
                new THREE.Vector3(0, 0, -1)  // Back
            ];

            directions.forEach(direction => {
                const point = direction.clone().multiplyScalar(radius);
                faceCenters.push(point);
                faceNormals.push(direction.clone().normalize());
            });
        }


        if (geometry.type === 'LineGeometry' && false) {
            const position = geometry.attributes.position;
            const count = position.count;

            // For a line, we have two points
            if (count === 2) {
                const start = new THREE.Vector3().fromBufferAttribute(position, 0);
                const end = new THREE.Vector3().fromBufferAttribute(position, 1);

                // Calculate the center point of the line
                const center = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
                faceCenters.push(center);

                // Calculate the direction vector of the line
                const direction = new THREE.Vector3().subVectors(end, start).normalize();

                // Calculate a normal vector perpendicular to the line
                // This is arbitrary for a line in 3D space, so we choose one
                const normal = new THREE.Vector3(-direction.y, direction.x, 0).normalize();
                faceNormals.push(normal);

                // Add start and end points as face centers
                faceCenters.push(start.clone());
                faceCenters.push(end.clone());

                // Add null normals for start and end points
                faceNormals.push(null);
                faceNormals.push(null);
            }
        }

        return { faceCenters, faceNormals };

    }

    public addFaceCenters(object, outputid, index?: number) {
        if (!object?.geometry) return;

        // Calculate the face centers and normals
        const { faceCenters, faceNormals } = this.calculateFaceCenters(object.geometry);

        // Store the face centers and normals in the object's userData
        object.userData.faceCenters = faceCenters.map((center, i) => ({
            position: center.clone(),
            normal: faceNormals[i]?.clone() || null,
            index: i + 1,
            outputid: outputid
        }));
    }

    showFaceCenters(obj, scene) {
        // Guard against undefined scene
        if (!scene) {
            console.warn('Scene is undefined in showFaceCenters');
            return;
        }

        // First, find all objects with helpers and remove them
        const objectsWithHelpers = [];
        scene.traverse((child) => {
            if (child.children) {
                const hasHelpers = child.children.some(c =>
                    c.userData.type === "refpoint" || c.userData.type === "refnormal"
                );
                if (hasHelpers) {
                    objectsWithHelpers.push(child);
                }
            }
        });

        // Remove helpers and reset materials
        objectsWithHelpers.forEach(object => {
            // Remove helpers
            const toRemove = object.children.filter(child =>
                child.userData.type === "refpoint" || child.userData.type === "refnormal"
            );
            toRemove.forEach(child => object.remove(child));
        });

        // Add new helpers for the selected object
        if (obj && obj.userData.faceCenters) {
            obj.userData.faceCenters.forEach((faceCenter, index) => {
                if (!faceCenter.position) return;

                // Create sprite for face center point
                const sprite = this.createNumberedSprite(index);
                sprite.position.copy(faceCenter.position);
                // Initial scale will be adjusted by camera distance in animate loop
                sprite.scale.set(14, 14, 1);  // Reduced by 30% from previous 20
                sprite.userData.type = "refpoint";
                sprite.userData.outputid = faceCenter.outputid;
                sprite.userData.index = faceCenter.index;
                sprite.renderOrder = 999;  // Ensure sprites render on top
                obj.add(sprite);

                // Create arrow for normal if it exists
                if (faceCenter.normal) {
                    const arrowLength = 50;  // Much bigger arrow length
                    const arrowHelper = new THREE.ArrowHelper(
                        faceCenter.normal.normalize(),
                        faceCenter.position,
                        arrowLength,
                        0x00ff00,  // Green color for normals
                        arrowLength * 0.45,  // Bigger head length
                        arrowLength * 0.35   // Bigger head width
                    );
                    arrowHelper.userData.type = "refnormal";
                    arrowHelper.renderOrder = 1000;  // Ensure arrows render on top of everything

                    // Make the arrow line thicker and ignore depth
                    if (arrowHelper.line) {
                        const lineMaterial = new THREE.LineBasicMaterial({
                            color: 0x00ff00,
                            linewidth: 3,
                            depthTest: false,
                            depthWrite: false,
                            transparent: true
                        });
                        (arrowHelper.line as THREE.Line).material = lineMaterial;
                    }

                    // Make the arrow head ignore depth too
                    if (arrowHelper.cone) {
                        const coneMaterial = new THREE.MeshBasicMaterial({
                            color: 0x00ff00,
                            depthTest: false,
                            depthWrite: false,
                            transparent: true
                        });
                        (arrowHelper.cone as THREE.Mesh).material = coneMaterial;
                    }

                    obj.add(arrowHelper);
                }
            });
        }
    }

    // Helper method to create numbered sprite
    private createNumberedSprite(index: number): THREE.Sprite {
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        const context = canvas.getContext('2d');

        // Clear the canvas with transparent background
        context.clearRect(0, 0, canvas.width, canvas.height);

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = canvas.width / 2 - 5;

        // Create a radial gradient with transparency
        const gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
        gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');  // Full opacity for better visibility
        gradient.addColorStop(1, 'rgba(200, 200, 200, 1)');

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        context.fillStyle = gradient;
        context.fill();

        // Add a more visible border
        context.strokeStyle = 'rgba(0, 0, 0, 0.8)';
        context.lineWidth = 6;
        context.stroke();

        // Draw the number with improved contrast
        context.fillStyle = 'rgba(0, 0, 0, 1)';  // Solid black for better visibility
        context.strokeStyle = 'rgba(255, 255, 255, 0.8)';
        context.lineWidth = 6;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.font = 'bold 160px Arial';
        const text = `${index + 1}`;
        context.strokeText(text, centerX, centerY);
        context.fillText(text, centerX, centerY);

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;

        const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
            depthTest: false,  // Ignore z-depth
            depthWrite: false,
            sizeAttenuation: true  // Enable size attenuation for camera distance scaling
        });

        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(15, 15, 1);  // Make sprites bigger
        return sprite;
    }

    prependUnderscoreToNumbers(inputString) {
        return inputString.replace(/(\d+)/g, '_$1');
    }
    removeUnderscoreBeforeNumbers(inputString) {
        return inputString.replace(/_+(\d+)/g, '$1');
    }


    private async updateNamedExpressions(row: any[], commentrow: any[], objectnames: string[], excel, scene, path: string) {
        // namedObjectExpressions beim erzeugen des graphen
        var sheetid = await excel.getSheetId("sheetname");

        for (var i = 0; i < commentrow.length; i++) {
            if (commentrow[i] == "pos" || commentrow[i] == "p1" || commentrow[i] == "p2") {
                if (row[i] && typeof row[i] === 'string') {
                    for (const objectName of objectnames) {
                        // Match pattern: objectName followed by dot and number
                        const regex = new RegExp(`^${objectName}\\.\\d+$`);
                        if (regex.test(row[i])) {
                            // Found match
                            const namedExpression = row[i]; // this.prependUnderscoreToNumbers(row[i]);
                            var index = parseInt(namedExpression.split('.')[1]);
                            var outputid2 = namedExpression.split('.')[0];
                            // get value of anchor, bis jetzt nur anchors in gleichem projekt unterstützt
                            var anchorpath = path;

                            // userdata von anchor sprite                         
                            // index =
                            // 8
                            // isHelper =
                            // true
                            // origpos =
                            // Vector3 {x: 50, y: 50, z: -50}
                            // outputid2 =
                            // 'cube35'
                            // type =
                            // 'refpoint'
                            const anchor = ThreeUtils.getObjectByUserDataProps(scene, ["outputid2", "index"], [outputid2, index]);
                            if (anchor) {
                                const value = anchor.position.toArray();
                                const worldPosition = new THREE.Vector3();
                                anchor.getWorldPosition(worldPosition);
                                row[i] = `(${worldPosition.x},${worldPosition.y},${worldPosition.z})`;
                                const worldValue = worldPosition.toArray();
                                const origpos = anchor.userData.origpos.toArray();
                                console.log("anchor", anchor, value, origpos, worldValue);
                            }

                            // ggf. über namedExpression setzen, flexibler?
                            //  const cellRef = `Sheet${sheetid + 1}!${String.fromCharCode(65 + i)}${rowindex + 1}`;
                            // if (!excel.hasNamedExpression(namedExpression)) {
                            //    const changes =  excel.addNamedExpression(namedExpression, `(0,0,0)`, sheetid);
                            // } else {
                            //     const changes = excel.updateNamedExpression(namedExpression, `(0,0,0)`, sheetid);
                            // }
                        }
                    }
                }
            }
        }
        return row;
    }

    // Helper method to check if row exists in other nodes and create an instance if found
    private checkRowExistsInOtherNodes(row: any[], scene: THREE.Scene, currentPath: string, params: any): THREE.Mesh | THREE.InstancedMesh | null {
        let existingMesh: THREE.Mesh | null = null;
        let instanceCount = 0;

        // First pass: count instances and find existing mesh
        scene.traverse((node) => {
            if (node.userData?.row && node.name !== currentPath) {
                // Create copies of the rows to compare
                const rowToCheck = [...row];
                const existingRow = [...node.userData.row];
                const commentrow = node.userData.commentrow;

                // Skip comparison of transformation parameters
                if (commentrow) {
                    for (let i = 0; i < commentrow.length; i++) {
                        const comment = commentrow[i]?.toLowerCase();
                        if (comment === 'x' || comment === 'y' || comment === 'z' ||
                            comment === 'rx' || comment === 'ry' || comment === 'rz' ||
                            comment === 'sx' || comment === 'sy' || comment === 'sz') {
                            rowToCheck[i] = null;
                            existingRow[i] = null;
                        }
                    }
                    rowToCheck[0] = null;
                    existingRow[0] = null;
                }

                // Compare the modified rows
                if (JSON.stringify(rowToCheck) === JSON.stringify(existingRow)) {
                    instanceCount++;
                    if (!existingMesh && node instanceof THREE.Mesh) {
                        existingMesh = node;
                    }
                }
            }
        });

        // If we found an existing mesh, create a new mesh with the same geometry and material
        if (existingMesh) {
            console.log(`Found ${instanceCount} instances of the same object. Creating new mesh.`);

            // Create a new mesh with the same geometry and material
            const newMesh = new THREE.Mesh(
                existingMesh.geometry,
                existingMesh.material
            );

            // Copy basic properties
            newMesh.castShadow = existingMesh.castShadow;
            newMesh.receiveShadow = existingMesh.receiveShadow;
            newMesh.name = currentPath;

            return newMesh;
        }

        return null;
    }


    async createOrUpdateCADNode(rowindex: number, sheetid: number, name: string, row: any[], commentrow: any[], scene: THREE.Scene, excels: any, configuration: string, loaded: any, workers: any, customerid: string, projectid: string, projectconfigmodel: any, sourcesheetid, forceupdate = false) {
        if (!row[0])
            return;

        // if (row[0] === "copy") {
        //     const sourceObjectId = row[1];
        //     const copiedObject = await this.createCopy(sourceObjectId, scene);

        //     if (copiedObject) {
        //         copiedObject.name = name;
        //         scene.add(copiedObject);

        //         // Apply standard transformations
        //         if (row[5]) copiedObject.position.x = parseFloat(row[5]);
        //         if (row[6]) copiedObject.position.y = parseFloat(row[6]);
        //         if (row[7]) copiedObject.position.z = parseFloat(row[7]);
        //         if (row[8]) copiedObject.rotation.x = this.grad2rad(parseFloat(row[8]));
        //         if (row[9]) copiedObject.rotation.y = this.grad2rad(parseFloat(row[9]));
        //         if (row[10]) copiedObject.rotation.z = this.grad2rad(parseFloat(row[10]));

        //         return copiedObject;
        //     }
        //     return null;
        // }
        name = name.replace(/root\^/g, "");
        //    console.log('name', name)
        var prenames = scene.name; // Utils.preNames(scene);
        var path = prenames + "^" + name;
        path = path.replace(/root\^/g, "");
        path = "root^" + path;
        path = path.replace(/\^^/g, "^");
        path = path.replace(/\^\^/g, "^");
        path = path.replace(/\^\^/g, "^");



        // For subprojects, ensure consistent path format
        // if (row[1]?.toUpperCase().startsWith('PROJECT:')) {
        //     // If this is not the first sheet (sheetid > 0), ensure sheet info is included
        //     if (sheetid > 0) {
        //         const sheetPrefix = `$${sourcesheetid || sheetid}#sheet: Sheet${sheetid + 1}`;
        //         //     if (!path.includes(sheetPrefix)) {
        //         // Extract project and object parts
        //         const projectMatch = path.match(/\$\d+#project\d+/);
        //         const objectName = name.split('#').pop();
        //         if (projectMatch) {
        //             path = `root^${sheetPrefix}^${projectMatch[0]}^${objectName}`;
        //         }
        //         //     }
        //     }
        // }

        //  console.log("path", path);
        var params = Utils.mapRowToParams(row, commentrow);

        // Check for existing instances and create instanced mesh if needed
        // const instancedMesh = this.checkRowExistsInOtherNodes(row, scene, path, params);
        // if (instancedMesh) {
        //     scene.add(instancedMesh);
        //     // set userdata of instanced mesh
        //     instancedMesh.userData.row = row;
        //     instancedMesh.userData.commentrow = commentrow;
        //     instancedMesh.userData.path = path;
        //     instancedMesh.userData.isInstanced = true;
        //     instancedMesh.userData.rowindex = rowindex;
        //     instancedMesh.userData.sheetid = sheetid;
        //     instancedMesh.userData.name = name;
        //     instancedMesh.userData.typ = row[1];
        //     instancedMesh.userData.isSheetOrSubproject = isSheetOrSubproject;
        //     instancedMesh.userData.configuration = configuration;
        //     instancedMesh.userData.loaded = loaded;
        //     instancedMesh.userData.workers = workers;
        //     instancedMesh.userData.customerid = customerid;
        //     instancedMesh.userData.projectid = projectid;
        //     instancedMesh.userData.projectconfigmodel = projectconfigmodel;
        //     instancedMesh.userData.sourcesheetid = sourcesheetid;
        //   //  return instancedMesh;
        // }

        // Continue with original createOrUpdateCADNode logic...
        var cadnode = null;
        var object = scene.getObjectByName(path);
        // 'root^$0#sheet: Sheet84^$2#project49^$0#outerframe'
        //  root^$0#sheet: Sheet84^$2#project49^$0#outerframe

        // "root^$2#project49^$0#outerframe"    nach ersten eerzeugen

        // Check if node was previously cut and needs recreation
        var cuttedBy = false;
        var tocutoutputids = null;
        var cutoutputids = null;
        if (!forceupdate) {
            //          console.log('Node was previously cut, recreating:', name);
            cuttedBy = object?.userData?.cuttedBy;
            // const recreatedNode = await this.recreateCADNode(existingNode, scene, excels);
            // if (recreatedNode) {
            //     return recreatedNode;
            tocutoutputids = object?.userData?.tocutoutputids;
            cutoutputids = object?.userData?.cutoutputids;



            // }
        }
        //  if (object?.userData.tocutoutputids)
        //      tocutoutputids = object.userData.tocutoutputids;

        // "root^$0#sheet: Sheet84^$2#project49^$0#cube14"

        //        console.log("path", path, object);
        //        console.log('scene', scene);

        var isSheetOrSubproject = false; 6
        if (row?.length > 1)
            isSheetOrSubproject = row[1]?.toUpperCase().startsWith('SHEET:') || row[1]?.toUpperCase().startsWith('PROJECT:') || false;

        var diff = 3;// recreate
        if (object) {
            var oldrow = object.userData.row;
            commentrow = object.userData.commentrow;
            var diff = this.diffTypeOfRow(commentrow, oldrow, row); // x,y,z etc keine neueerstellung, #TODO: bei subproject subnodes check und update
            if (forceupdate) diff = 3;
            if (diff > 2) { // && !isSheetOrSubproject) {
                //       console.log("remove", object.name);
                scene.remove(object);
            }
            else
                cadnode = object;

        }

        // Check if row is marked as not visible
        if (params?.visible === false) {
            if (object) {
                scene.remove(object);
            }
            return null;
        }

        // parameter überschreiben, wenn config gesetzt
        var config = null;
        if (projectconfigmodel)
            if (projectconfigmodel[path])
                config = projectconfigmodel[path];
        if (config) {
            row = config.row;
            commentrow = config.commentrow;
            // ##TODO! _hier_ check auf zell abhängigkeiten von row auf andere zellen im aktuellem sheet und neu holen

        }


        // other sheets
        var group = null;

        if (diff > 2) {

            if (row[1].toUpperCase().startsWith('SHEET:')) {

                var sheetname = row[1].split(': ')[1];
                sheetid = await excels[configuration].getSheetId(sheetname);
                const startTime = performance.now();
                // #todo: set/getconfiguration kann auch andere änderungen verursachen in sheet
                var outputRowsObject = await excels[configuration].getConfiguration(sheetid, commentrow, row);
                var outputRows = outputRowsObject.map(row => row.data);
                var outputRowIndexes = outputRowsObject.map(row => row.rowindex);
                const endTime = performance.now();
                //      console.log(`getConfiguration took ${endTime - startTime} milliseconds`);
                group = new THREE.Group();

                // wenn neue cadnode project, config holen und in sheet schreiben. geänderte Zellen updaten/outputRows neu holen, die noch über sind
                const { outputRows: updatedOutputRows, outputRowIndexes: updatedOutputRowIndexes } =
                    await this.updateConfigurationAndOutputRows(path, projectconfigmodel, excels, outputRows, outputRowIndexes);
                outputRows = updatedOutputRows;
                outputRowIndexes = updatedOutputRowIndexes;


                var cr = null;
                for (var i = 0; i < outputRows.length; i += 1) {

                    if (outputRows[i][0] == '#') {
                        cr = outputRows[i];
                        i++
                    }
                    try {
                        const startTime = performance.now();
                        var name = path + "$" + sheetid + "#" + outputRows[i][0];
                        var outputRowIndex = outputRowIndexes[i];
                        cadnode = await this.createOrUpdateCADNode(outputRowIndex, sheetid, name, outputRows[i], cr, group, excels, configuration, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid);

                        const endTime = performance.now();
                        //       console.log(`createOrUpdateCADNode took ${endTime - startTime} milliseconds`);
                        //   cadnode.userData.issheet = true;
                    } catch (error) {
                        console.log("Error in createOrUpdateCADNode sheet", path, cadnode)

                    }
                    // cadnode.userData.configuration = subconfigurationname;
                    // cadnode.userData.commentrow = commentrow;
                    // cadnode.userData.row = row;
                    //  cadnode.userData.rowindex = rowindex;
                    // cadnode.userData.typ = outputRows[i][1];
                }
            }
            // other Projects
            // TODO: laden
            var typ = row[1].toUpperCase();
            if (row[1].toUpperCase().startsWith('PROJECT:')) { // TODO: LIBRARY: ...
                var sourcesheetid1 = sheetid;
                var subconfigurationname = row[1].split(': ')[1]; // deprecated: customerid + "_" + row[1].split(': ')[1];
                console.log(path);

                // Extract last number between ^ and # from path
                const pathMatch = path.match(/\^(\d+)#/);
                const lastNumber = pathMatch ? pathMatch[1] : null;
                console.log('Last number in path:', lastNumber);
                if (lastNumber) {
                    sourcesheetid1 = parseInt(lastNumber);
                }

                var sheetnameP = "Sheet 1";
                var sheetIdP = 0;// await excels[subconfigurationname].getSheetId(sheetnameP);
                // konfigparameter setzen, achtung bei mehrerenunterkongiruation und konfigspeicherung von nutzer
                //     console.log("subconfigurationname", subconfigurationname, excels[subconfigurationname])
                if (!excels[subconfigurationname])
                    return null;

                // #TODO: schreibt eigentlich auch werte in parent sheet
                var outputRowsObject = await excels[subconfigurationname].getConfiguration(sheetIdP, commentrow, row);
                var outputRowsP = outputRowsObject.map(row => row.data);
                var outputRowIndexes = outputRowsObject.map(row => row.rowindex);
                // wenn neue cadnode project, config holen und in sheet schreiben. geänderte Zellen updaten/outputRows neu holen, die noch über sind
                const { outputRows: updatedOutputRows, outputRowIndexes: updatedOutputRowIndexes } =
                    await this.updateConfigurationAndOutputRows(path, projectconfigmodel, excels, outputRows, outputRowIndexes);
                outputRows = updatedOutputRows;
                outputRowIndexes = updatedOutputRowIndexes;

                group = new THREE.Group();
                // Remove any double carets from path
                path = path.replace(/\^\^+/g, '^');
                group.name = path;// name;
                group.userData.typ = row[1];
                group.userData.subproject = subconfigurationname;
                group.userData.issubproject = true;
                group.userData.sourcesheetid = sourcesheetid1;
                scene.add(group);
                //  group.name = this.preNames(group);
                // cadnode = await this.createCADNode(row[0], row[1], row[0], group, excels, configurationname, loaded, workers, customerid, projectid);

                // TODO achtung mit weiteren zeilen TODO: head bei mehrren rows !!
                var cr = null;
                for (var i = 0; i < outputRowsP.length; i += 1) {
                    if (outputRowsP[i][0] == '#') {
                        cr = outputRowsP[i];
                        i++
                    }
                    try {
                        const startTime = performance.now();
                        //    console.log('subconfigurationname', subconfigurationname);
                        var name = "$" + sheetIdP + "#" + outputRowsP[i][0];
                        var outputRowIndex = outputRowIndexes[i];
                        cadnode = await this.createOrUpdateCADNode(outputRowIndex, sheetIdP, name, outputRowsP[i], cr, group, excels, subconfigurationname, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid1);
                        const endTime = performance.now();
                        //        console.log(`createOrUpdateCADNode took ${endTime - startTime} milliseconds`);
                        //        console.log("subproject cadnode", cadnode);
                        //  cadnode = await this.createOrUpdateCADNode(rowindex, sheetid, "$" + sheetid + "#" + outputRows[i][0], outputRows[i], cr, group, excels, configuration, loaded, workers, customerid, projectid, projectconfigmodel);
                    } catch (error) {
                        console.log("Error", error)

                    }

                }

            }

            // 
            var objectnames = await excels[configuration].getObjects3dNames(sheetid);
            row = await this.updateNamedExpressions(row, commentrow, objectnames, excels[configuration], scene, path);
            params = Utils.mapRowToParams(row, commentrow);


            const startTime1 = performance.now();

            // Primitives
            switch (typ) {
                case "LINE":
                    cadnode = await this.createLine(params['p1'], params['p2'], params['width'], params['material']);
                    break;
                case "DIMENSIONLINE":
                    var paramsobj = {
                        orthogonalLength: params.orthogonalLength,
                        orthogonalOffset: params.orthogonalOffset,
                        textSize: params.textSize,
                        textPadding: params.textPadding,
                        textBackground: params.textBackground,
                        textColor: params.textColor,
                        rotation: params.rotation
                    }
                    cadnode = await this.createDimensionLine(params['p1'], params['p2'], params['width'], params['material'], paramsobj);
                    break;

                case "SHAPE":
                    cadnode = await this.createShape(params['points'], params['depth'], params['material']);
                    break;
                case "SURFACE":
                    cadnode = await this.createSurface(params['points'], params['indices'], params['material']);
                    break;
                case "TEXTFLAT":
                    cadnode = await this.createText(params['textflat'], params['font'], params['fontsize'], params['color'], params['align']);
                    break;
                case "EXTRUSION":
                    cadnode = await this.createExtrusion(params['baseplane'], params['extrusionvector'], params['material']);
                    break;
                case "SUBTRACTION":
                    // TODO: funktioniert noch nicht für unterprojekte
                    // hier wird nur die subtraction geändert, nicht wenn sich eines der objekte geändert hat
                    cadnode = await this.createSubtraction(params['cuttedoutputids'], params['cutoutputids'], sheetid, scene, name, path, configuration, excels);
                    return;
                    break;
                case "CYLINDER":
                    try {

                        cadnode = await this.createCylinder(params['radiusTop'], params['radiusBottom'], params['height'], params['material']);
                    }
                    catch (err) {
                        console.log("error", err)
                    }

                    break;
                case "SPHERE":
                    cadnode = this.createSphere(params['radius'], params['material']);
                    break;
                case "CUBE":
                    cadnode = this.createCube(params['width'], params['length'], params['height'], params['material']);
                    break;
                case "IMAGE3D":
                    cadnode = await this.createBillboard(params['width'], params['height'], params['material'], params['filename']);
                    break;
                case "LATHE":
                    cadnode = this.createLathe(params['points'], params['material']);
                    break;
                case "LIGHT":
                    cadnode = await this.createLight(params['color'], params['intensity'], params['distance'], params['decay']);
                    break;
                case "GLTF":
                    cadnode = await this.createGltf(params['filename']);
                    break;
                case "PROFILE":
                    cadnode = await this.createProfile(params['points'], params['depth'], params['material']);
                    break;
                case "CONNECTOR":
                    let connectorPosition = new THREE.Vector3(
                        parseFloat(params.x) || 0,
                        parseFloat(params.y) || 0,
                        parseFloat(params.z) || 0
                    );

                    // Parse normal vector from string "(x,y,z)"
                    let connectorNormalStr = params.normal.replace('(', '').replace(')', '').split(',');
                    let connectorNormal = new THREE.Vector3(
                        parseFloat(connectorNormalStr[0]) || 1,
                        parseFloat(connectorNormalStr[1]) || 0,
                        parseFloat(connectorNormalStr[2]) || 0
                    );

                    let connectorLabel = params.label || '1';
                    cadnode = this.createConnectorVisualization(connectorPosition, connectorNormal, connectorLabel);
                    break;
                case "IBEAM":
                case "ibeam":
                    const ibeamParams = {
                        profile: row[2],
                        width: row[3] ? parseFloat(row[3]) : undefined,
                        height: row[4] ? parseFloat(row[4]) : undefined,
                        length: row[5] ? parseFloat(row[5]) : 150,
                        flangeThickness: row[6] ? parseFloat(row[6]) : undefined,
                        webThickness: row[7] ? parseFloat(row[7]) : undefined,
                        material: this.str2material(row[8] || "#445566"),
                        startSlope: parseFloat(row[9] || "0"),
                        endSlope: parseFloat(row[10] || "0"),
                        x: parseFloat(row[11] || "0"),
                        y: parseFloat(row[12] || "0"),
                        z: parseFloat(row[13] || "0"),
                        rx: parseFloat(row[14] || "0"),
                        ry: parseFloat(row[15] || "0"),
                        rz: parseFloat(row[16] || "0")
                    };

                    console.log("Creating I-beam with parameters:", ibeamParams);

                    cadnode = this.createIBeam(
                        ibeamParams.profile,
                        ibeamParams.width,
                        ibeamParams.height,
                        ibeamParams.length,
                        ibeamParams.flangeThickness,
                        ibeamParams.webThickness,
                        ibeamParams.material,
                        ibeamParams.startSlope,
                        ibeamParams.endSlope
                    );

                    if (cadnode) {
                        this.setNodeTransformations(cadnode, {
                            x: ibeamParams.x,
                            y: ibeamParams.y,
                            z: ibeamParams.z,
                            rx: ibeamParams.rx,
                            ry: ibeamParams.ry,
                            rz: ibeamParams.rz
                        });
                    } else {
                        console.warn("Failed to create I-beam", ibeamParams);
                    }
                    break;

                case "aluprofile":
                    const aluMaterial = this.str2material(params.material);
                    const aluProfileMesh = this.createAluProfile(params.series, params.size, params.length, aluMaterial);
                    if (aluProfileMesh) {
                        cadnode = aluProfileMesh;
                    }
                    break;

                case "light":
                    cadnode = await this.createLight(params.color, params.intensity, params.distance, params.decay);
                    break;
                case "GLTF":
                    cadnode = await this.createGltf(params['filename']);
                    break;
                case "PROFILE":
                    cadnode = await this.createProfile(params['points'], params['depth'], params['material']);
                    break;

                case "GRID":
                case "grid":
                    cadnode = this.createMeasuringGrid(
                        name,
                        parseFloat(params['width']), // width
                        parseFloat(params['length']), // length
                        parseFloat(params['mainGap']), // mainGap
                        parseFloat(params['subGap']), // subGap
                        parseFloat(params['nthLabel']), // nthLabel
                        parseFloat(params['labelSize']), // labelSize
                        parseFloat(params['lineWidth']), // lineWidth
                        params['mainColor'],             // mainColor
                        params['subColor'],             // subColor
                        params['labelColor'],            // labelColor
                        {
                            showSubLines: params['showSubLines'] !== 'false',
                            showLabels: params['showLabels'] !== 'false',
                            startX: params['startX'] ? parseFloat(params['startX']) : undefined,
                            endX: params['endX'] ? parseFloat(params['endX']) : undefined,
                            startY: params['startY'] ? parseFloat(params['startY']) : undefined,
                            endY: params['endY'] ? parseFloat(params['endY']) : undefined,
                            labelOffsetX: params['labelOffsetX'] ? parseFloat(params['labelOffsetX']) : 0,
                            labelOffsetY: params['labelOffsetY'] ? parseFloat(params['labelOffsetY']) : 0
                        }
                    );
                    break;

                case "ARROW":
                    cadnode = this.createArrow(
                        params['start'],
                        params['end'],
                        parseFloat(params['width']),
                        params['material'],
                        parseFloat(params['headLength']),
                        parseFloat(params['headWidth'])
                    );
                    break;

                case "lathe":
                    cadnode = this.createLathe(params['points'], params['material']);
                    break;
            }



            const endTime1 = performance.now();
            //     console.log(`GEOMETRY Generation took ${endTime1 - startTime1} milliseconds`);

            if (!cadnode && !group) {
                console.warn("no cadnode ", typ, rowindex, name, row, commentrow, configuration);
                return;
            }

            // Kanten
            if (typ == "EXTRUSION" || typ == "CYLINDER" || typ == "SUBTRACTION" || typ == "CUBE") {

                if (!(params['edges'] == false && typeof (params['edges']) === "boolean")) {

                    var egeometry = new THREE.EdgesGeometry(cadnode.geometry, 6);
                    var linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1, transparent: false, }));
                    linestandard.name = "KANTE";
                    linestandard.userData.type = "edge";
                    linestandard.userData.isHelper = true;
                    linestandard.userData.path = cadnode.userData.path;

                    cadnode.add(linestandard);
                }


            }


            //            cadnode.userData.outputid = path.split('#')[path.split('#').length - 1];


            var fcname = path.split('#')[path.split('#').length - 1];;

            const startTime2 = performance.now();
            this.addFaceCenters(cadnode, fcname); // #TODO: zieht zu viel zeit!!!
            const endTime2 = performance.now();
            //console.log(`addFaceCenters took ${endTime2 - startTime2} milliseconds`);

            // scene.traverse(child => { // TODO: gehjt schöner?
            //     if (child.userData.type === "refpoint" || child.userData.type === "refnormal") {
            //         child.visible = false;
            //     }
            // });
        }

        if (group) {
            cadnode = group;
        }
        cadnode.position.x = 0;
        cadnode.position.y = 0;
        cadnode.position.z = 0;
        cadnode.rotation.x = 0;
        cadnode.rotation.y = 0;
        cadnode.rotation.z = 0;
        cadnode.scale.x = 1;
        cadnode.scale.y = 1;
        cadnode.scale.z = 1;

        const startTime = performance.now();


        if (params['refrot'] != null) {   // params von cube
            try {
                const [refoutputid, indexStr] = params['refrot'].split('.');
                const index = parseInt(indexStr);
                const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                // const refnormalob = refobj?.children?.find(child => child.userData.index === index && child.userData.type === "refnormal" && child.userData.isHelper);
                let reforientationvector = ThreeUtils.getRotationVectorByObjectNameAndFaceCenterIndex(scene, refoutputid, index, cadnode);
                let refnormalob = reforientationvector;


                if (refnormalob) {
                    const refnormal = refnormalob.clone();
                    console.log("refnormal", refnormal);
                    cadnode.updateMatrixWorld(true);

                    const normalMatrix = new THREE.Matrix4();
                    normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
                    var q = new THREE.Quaternion();

                    q.setFromRotationMatrix(normalMatrix);
                    //     const xAxis = new THREE.Vector3(1, 0, 0);
                    //     const qRotation = new THREE.Quaternion();
                    //     qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
                    //     q.multiply(qRotation);

                    // cadnode.quaternion.copy(q);
                    //  refobj.updateMatrixWorld(true);
                    //  refobj.updateMatrix();      
                    cadnode.quaternion.copy(refobj.quaternion.clone());
                    cadnode.quaternion.multiply(q);

                    const rotationQuat = new THREE.Quaternion();
                    if (params['rx'] != null) {
                        const rxQuat = new THREE.Quaternion();
                        rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(params['rx'])));
                        rotationQuat.multiply(rxQuat);
                        cadnode.userData.rx = params['rx'];
                    }
                    if (params['ry'] != null) {
                        const ryQuat = new THREE.Quaternion();
                        ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(params['ry'])));
                        rotationQuat.multiply(ryQuat);
                        cadnode.userData.ry = params['ry'];
                    }
                    if (params['rz'] != null) {
                        const rzQuat = new THREE.Quaternion();
                        rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(params['rz'])));
                        rotationQuat.multiply(rzQuat);
                        cadnode.userData.rz = params['rz'];
                    }
                    cadnode.quaternion.multiply(rotationQuat);

                    cadnode.updateMatrix();
                    cadnode.userData.refnormalobj = refnormalob;
                    if (!refobj.userData.refnormalobjs)
                        refobj.userData.refnormalobjs = [];
                    refobj.userData.refnormalobjs[params['refrot']] = cadnode;
                    cadnode.userData.refrot = params['refrot'];
                }

            } catch (error) {
                console.warn("refrot error", error);
            }
        }


        // refpoints
        // parent object durchiterieren und beziehungen entsprechend udpaten (nur x,y,z,rx,ry,rz,sx,sy,sz)
        if (params['refpos']) {
            try {
                const [refoutputid, indexStr] = params['refpos'].split('.');
                const index = parseInt(indexStr);
                const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);

                let refposvector = ThreeUtils.getWorldVectorByObjectNameAndFaceCenterIndex(scene, refoutputid, index, cadnode);
                if (refposvector) {
                    // Store the original position before applying rotations
                    const originalPosition = refposvector.clone();
                    // Now set the position
                    cadnode.position.copy(originalPosition);

                    // Apply rotations in the correct order (first rotations, then position)
                    // if (params['rx'] != null)
                    //     cadnode.rotation.x = this.grad2rad(parseFloat(params['rx']));
                    // if (params['ry'] != null)
                    //     cadnode.rotation.y = this.grad2rad(parseFloat(params['ry']));
                    // if (params['rz'] != null)
                    //     cadnode.rotation.z = this.grad2rad(parseFloat(params['rz']));

                    // Update matrices after rotation
                    cadnode.updateMatrix();
                    cadnode.updateMatrixWorld(true);
                    cadnode.userData.refposobj = refobj;
                    if (!refobj.userData.refposobjs)
                        refobj.userData.refposobjs = [];
                    refobj.userData.refposobjs[params['refpos']] = cadnode;
                    cadnode.userData.refpos = params['refpos'];
                }
            } catch (error) {
                console.warn("refpos error", error);
            }
        }

        // Handle remaining transformations if refpos wasn't set
        // if (!params['refpos']) {
        //     if (params['rx'] != null && params['refrot'] == null)
        //         cadnode.rotation.x = this.grad2rad(parseFloat(params['rx']));
        //     if (params['ry'] != null && params['refrot'] == null)
        //         cadnode.rotation.y = this.grad2rad(parseFloat(params['ry']));
        //     if (params['rz'] != null && params['refrot'] == null)
        //         cadnode.rotation.z = this.grad2rad(parseFloat(params['rz']));
        // }

        // Apply local position offsets after refpos and rotations
        cadnode.updateMatrix();
        if (params['x'] != null) {
            let xDirection = new THREE.Vector3(parseFloat(params['x']), 0, 0); // Local X
            xDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(xDirection);
        }
        if (params['y'] != null) {
            let yDirection = new THREE.Vector3(0, parseFloat(params['y']), 0); // Local Y
            yDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(yDirection);
        }
        if (params['z'] != null) {
            let zDirection = new THREE.Vector3(0, 0, parseFloat(params['z'])); // Local Z
            zDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(zDirection);
        }
        if (params['rx'] != null && params['refrot'] == null)
            cadnode.rotation.x = this.grad2rad(parseFloat(params['rx']));
        if (params['ry'] != null && params['refrot'] == null)
            cadnode.rotation.y = this.grad2rad(parseFloat(params['ry']));
        if (params['rz'] != null && params['refrot'] == null)
            cadnode.rotation.z = this.grad2rad(parseFloat(params['rz']));
        if (params['sx'] != null)
            cadnode.scale.x = parseFloat(params['sx']);
        if (params['sy'] != null)
            cadnode.scale.y = parseFloat(params['sy']);
        if (params['sz'] != null)
            cadnode.scale.z = parseFloat(params['sz']);

        // Handle origin after all other transformationsgr
        if (params['origin']) {
            const origin = params['origin'].split(',');
            if (origin.length == 3) {
                let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                direction.applyQuaternion(cadnode.quaternion);
                cadnode.position.sub(direction);
                cadnode.userData.origin = direction;
            } else {
                const [refoutputid, indexStr] = params['origin'].split('.');
                const index = parseInt(indexStr);
                let refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                if (!refobj)
                    refobj = cadnode;


                let refposvector = ThreeUtils.getLocalVectorByObjectNameAndFaceCenterIndex(refobj, index, cadnode);
                if (refposvector) {
                    // Transform refposvector to account for rotation
                    const rotatedRefPosVector = refposvector.clone();
                    if (cadnode.quaternion) {
                        rotatedRefPosVector.applyQuaternion(cadnode.quaternion);
                    }
                    cadnode.position.sub(rotatedRefPosVector);
                }
                cadnode.userData.origin = refposvector;
            }
        }

        if (isNaN(cadnode.position.x)) cadnode.position.x = 0;
        if (isNaN(cadnode.position.y)) cadnode.position.y = 0;
        if (isNaN(cadnode.position.z)) cadnode.position.z = 0;
        if (isNaN(cadnode.rotation.x)) cadnode.rotation.x = 0;
        if (isNaN(cadnode.rotation.y)) cadnode.rotation.y = 0;
        if (isNaN(cadnode.rotation.z)) cadnode.rotation.z = 0;
        if (isNaN(cadnode.scale.x)) cadnode.scale.x = 1;
        if (isNaN(cadnode.scale.y)) cadnode.scale.y = 1;
        if (isNaN(cadnode.scale.z)) cadnode.scale.z = 1;



        cadnode.visible = true;
        if (params['visible'] != null && params['visible'] != undefined) {
            if (params['visible'] == 0)
                cadnode.visible = false;
            else
                cadnode.visible = (params['visible']);
        }


        const endTime = performance.now();

        // gltf nodes
        // createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel);
        for (var i = 0; i < commentrow?.length; i++) {
            try {
                if (commentrow[i]?.startsWith("node")) { // == "nodematerial") {
                    var objectname = path;
                    if (objectname) {
                        var object1 = cadnode;// scene.getObjectByName(objectname);
                        var nodename = commentrow[i].split('=')[1];

                        var statement = row[i];
                        var prop = statement.split('=')[0];
                        var value = statement.split('=')[1];
                        this.updateNodel(prop, nodename, value, object1);
                    }
                }

            } catch (error) {

            }
        }


        //  tnode.add(cadnode);
        cadnode.updateMatrix();
        path = path.replace(/\^\^+/g, '^');

        // Add sheet name to path if it's from a different sheet
        // if (sourcesheetid !== undefined && sourcesheetid !== sheetid) {
        //     const sheetName = row[0]; // Sheet name is in first column
        //     if (sheetName && !path.includes(sheetName)) {
        //         path = `${sheetName}^${path}`;
        //     }
        // }

        //  console.log('path', path);

        cadnode.name = path;
        cadnode.userData.configuration = configuration;
        cadnode.userData.typ = row[1];
        cadnode.userData.commentrow = commentrow;
        cadnode.userData.row = JSON.parse(JSON.stringify(row));
        if (!cadnode.userData.rowindex)
            cadnode.userData.rowindex = rowindex;
        cadnode.userData.sheetid = sheetid;
        cadnode.userData.sourcesheetid = sourcesheetid;
        cadnode.userData.outputid = path.split('#').pop();
        cadnode.userData.rx = params['rx'];
        cadnode.userData.ry = params['ry'];
        cadnode.userData.rz = params['rz'];


        // Calculate the bounding box of the cadnode
        const boundingBox = new THREE.Box3().setFromObject(cadnode);
        //        console.log('Bounding Box:', boundingBox);
        cadnode.userData.boundingbox = boundingBox;
        try {
            // var exc = excels[configuration];
            // if (object) {
            //     const changes1 = await excels[configuration].changeNamedExpression(row[0] + ".width", sheetid);
            //     const changes2 = await excels[configuration].changeNamedExpression(row[0] + ".height", sheetid);
            //     const changes3 = await excels[configuration].changeNamedExpression(row[0] + ".depth", sheetid);
            // } else {
            //     var na = this.prependUnderscoreToNumbers(row[0]) + ".width";
            //     var val = (boundingBox.max.x - boundingBox.min.x).toString();
            //     var changes4 = await exc.addNamedExpression(na, val);
            //     //   var changes5 = await excels[configuration].addNamedExpression(row[0] + "height", boundingBox.max.y - boundingBox.min.y, sheetid);
            //     //   var changes6 = await excels[configuration].addNamedExpression(row[0] + ".depth", (boundingBox.max.z - boundingBox.min.z).toString(), sheetid);
            // }
        } catch (error) {
            console.warn("error", error);
        }

        if (!object)
            cadnode.userData.cadstate = "created";
        else
            cadnode.userData.cadstate = "updated";


        // refpos
        cadnode.updateMatrixWorld(true);

        this.handleRefObjects(cadnode, scene);

        //        cadnode.children.forEach(child => this.updateChild(child, cadnode));
        // cadnode.children.forEach(child => this.updateChild(child, cadnode));
        // cadnode.children.forEach(child => this.updateChild(child, cadnode));
        // cadnode.children.forEach(child => this.updateChild(child, cadnode));
        // cadnode.children.forEach(child => this.updateChild(child, cadnode));

        if (diff > 2) {

            scene.add(cadnode);
        }
        cadnode.userData.path = Utils.preNames(cadnode);
        cadnode.userData.path = cadnode.userData.path.replace("root^root^", "root^")
        //  console.log(`postprocessing took ${endTime - startTime} milliseconds`);
        // console.log('#### cadnode.name', cadnode.name + " " + cadnode.userData.path);


        // ggf. erneut schneiden
        if (cuttedBy || tocutoutputids) {
            //    cadnode.userData.cutted = false;
            //   var cuttedoutputids = cadnode.userData.outputid; //  params['cuttedoutputids'];
            //   var cutoutputids = (cuttedBy as any).split('#').pop(); //  params['cutoutputids'];
            var o = object.userData;
            var subtractionname = cadnode.userData.subtraction;

            cadnode = await this.createSubtraction(tocutoutputids, cutoutputids, sheetid, scene, name, path, configuration, excels, true);
        }
        // if (tocutoutputids) {

        //     cadnode = await this.createSubtraction(tocutoutputids, cadnode.userData.outputid, sheetid, scene, name, path, configuration, excels, true);
        // }


        return cadnode;

        return cadnode;
        return cadnode.toJSON();

    }


    handleRefObjects(cadnode: THREE.Object3D, scene: THREE.Scene) {
        // Early return if no ref objects exist
        if (!cadnode.userData.refnormalobjs && !cadnode.userData.refposobjs) return;

        // Merge refnormalobjs and refposobjs
        const mergedRefs = {
            ...(cadnode.userData.refnormalobjs || {}),
            ...(cadnode.userData.refposobjs || {})
        };

        // Handle each reference object recursively
        Object.values(mergedRefs).forEach((refObj: THREE.Object3D) => {
            if (!refObj) return;

            // Update position and rotation based on the parent node
            if (refObj.userData.refpos) {
                // Update position from reference
                const [refoutputid, indexStr] = refObj.userData.refpos.split('.');
                const index = parseInt(indexStr);
                //  const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);

                let refposvector = ThreeUtils.getWorldVectorByObjectNameAndFaceCenterIndex(scene, refoutputid, index, cadnode);
                if (refposvector) {
                    // Store the original position before applying rotations
                    const originalPosition = refposvector.clone();
                    // Now set the position
                    refObj.position.copy(originalPosition);

                    // Apply rotations in the correct order (first rotations, then position)
                    // if (params['rx'] != null)
                    //     cadnode.rotation.x = this.grad2rad(parseFloat(params['rx']));
                    // if (params['ry'] != null)
                    //     cadnode.rotation.y = this.grad2rad(parseFloat(params['ry']));
                    // if (params['rz'] != null)
                    //     cadnode.rotation.z = this.grad2rad(parseFloat(params['rz']));

                    // Update matrices after rotation
                    refObj.updateMatrix();
                    refObj.updateMatrixWorld(true);
                    // cadnode.userData.refposobj = refobj;
                    // if (!refobj.userData.refposobjs)
                    //     refobj.userData.refposobjs = [];
                    // refobj.userData.refposobjs[refObj.userData.refpos] = cadnode;
                }
            }

            if (refObj.userData.refrot) {
                try {
                    const [refoutputid, indexStr] = refObj.userData.refrot.split('.');
                    const index = parseInt(indexStr);
                    const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                    // const refnormalob = refobj?.children?.find(child => child.userData.index === index && child.userData.type === "refnormal" && child.userData.isHelper);
                    let reforientationvector = ThreeUtils.getRotationVectorByObjectNameAndFaceCenterIndex(scene, refoutputid, index, cadnode);
                    let refnormalob = reforientationvector;


                    if (refnormalob) {
                        const refnormal = refnormalob.clone();
                        console.log("refnormal", refnormal);
                        refObj.updateMatrixWorld(true);

                        const normalMatrix = new THREE.Matrix4();
                        normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
                        var q = new THREE.Quaternion();

                        q.setFromRotationMatrix(normalMatrix);
                        //     const xAxis = new THREE.Vector3(1, 0, 0);
                        //     const qRotation = new THREE.Quaternion();
                        //     qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
                        //     q.multiply(qRotation);

                        // cadnode.quaternion.copy(q);
                        //  refobj.updateMatrixWorld(true);
                        //  refobj.updateMatrix();      
                        refObj.quaternion.copy(refobj.quaternion.clone());
                        refObj.quaternion.multiply(q);

                        const rotationQuat = new THREE.Quaternion();
                        if (refObj.userData.rx != null) {
                            const rxQuat = new THREE.Quaternion();
                            rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(refObj.userData.rx)));
                            rotationQuat.multiply(rxQuat);
                        }
                        if (refObj.userData.ry != null) {
                            const ryQuat = new THREE.Quaternion();
                            ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(refObj.userData.ry)));
                            rotationQuat.multiply(ryQuat);
                        }
                        if (refObj.userData.rz != null) {
                            const rzQuat = new THREE.Quaternion();
                            rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(refObj.userData.rz)));
                            rotationQuat.multiply(rzQuat);
                        }
                        refObj.quaternion.multiply(rotationQuat);

                        refObj.updateMatrix();
                        // cadnode.userData.refnormalobj = refnormalob;
                        // if (!refobj.userData.refnormalobjs)
                        //     refobj.userData.refnormalobjs = [];
                        //     refobj.userData.refnormalobjs[refObj.userData.refrot] = cadnode;

                    }

                } catch (error) {
                    console.warn("refrot error", error);
                }
            }

            // Handle origin point if specified
            if (refObj.userData.origin) {
                const origin = refObj.userData.origin.split(',');
                let direction = refObj.userData.origin;
                direction.applyQuaternion(refObj.quaternion);
                refObj.position.sub(direction);


            }

            // Update matrices
            refObj.updateMatrix();
            refObj.updateMatrixWorld(true);

            // Recursively handle nested reference objects
            this.handleRefObjects(refObj, scene);
        });
    }

    createProfile(cadnode, depth, material) {
        const profile = new THREE.Mesh(
            new THREE.BoxGeometry(1, 1, depth),
            material
        );
        profile.position.set(0, 0, 0);
        cadnode.add(profile);
    }

    updateChild(child, cadnode) {
        child.updateMatrixWorld(true);

        if (child.userData.refposobj) {
            child.userData.refposobj.updateMatrixWorld(true);
            const worldPos = new THREE.Vector3();
            child.getWorldPosition(worldPos);
            //           child.userData.refposobj.position.copy(worldPos);
            child.userData.refposobj.position.set(0, 0, 0); //
        }

        if (child.userData.refnormalobj) {
            child.userData.refnormalobj.updateMatrixWorld(true);
            const refNormal = child.userData.normal.clone();

            const normalMatrix = new THREE.Matrix4().lookAt(
                new THREE.Vector3(0, 0, 0),
                refNormal,
                new THREE.Vector3(0, 0, -1)
            );

            const baseQuaternion = new THREE.Quaternion().setFromRotationMatrix(normalMatrix);
            const xRotation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

            const finalQuaternion = new THREE.Quaternion().multiplyQuaternions(
                cadnode.quaternion,
                baseQuaternion.multiply(xRotation)
            );

            child.userData.refnormalobj.quaternion.copy(finalQuaternion);

            // Apply additional rotations (rx, ry, rz)
            this.applyAdditionalRotations(child.userData.refnormalobj);
        }

        if (child.userData.refposobj) {
            const refPos = new THREE.Vector3();
            child.userData.refposobj.getWorldPosition(refPos);
            //  cadnode.position.copy(refPos);
        }

        if (child.userData.origin) {
            const originParams = child.userData.origin.split(',');
            if (originParams.length === 3) {
                const originVector = new THREE.Vector3(
                    parseFloat(originParams[0]),
                    parseFloat(originParams[1]),
                    parseFloat(originParams[2])
                );
                //    cadnode.position.add(originVector);
            }
        }

        if (child.userData.refposobj) {
            child.userData.refposobj.position.copy(child.position);

            // 
            const refPosObjRotation = new THREE.Euler().setFromQuaternion(child.userData.refposobj.quaternion);
            const vector = new THREE.Vector3(1, 0, 0); // Example vector
            vector.applyEuler(refPosObjRotation);

            // get refposobj origin
            const refPosObjOrigin = child.userData.refposobj.userData.
                refPosObjOrigin.sub(vector);




            //  this.applyLocalTransforms(child.userData.refposobj, child.parent);
            //  child.userData.refposobj.children.forEach(grandChild => this.updateChild(grandChild, cadnode));
        }
    }

    applyAdditionalRotations(obj) {
        const rotationOrder = ['rx', 'ry', 'rz'];
        const rotationAxes = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];

        rotationOrder.forEach((rot, index) => {
            if (obj.userData[rot] != null) {
                const angle = THREE.MathUtils.degToRad(parseFloat(obj.userData[rot]));
                this.rotateAroundPoint(obj, new THREE.Vector3(), rotationAxes[index], angle);
            }
        });

        obj.updateMatrix();
    }

    applyLocalTransforms(obj, subscene) {
        const params = Utils.mapRowToParams(obj.userData.row, obj.userData.commentrow);
        const localDirections = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];

        ['x', 'y', 'z'].forEach((axis, index) => {
            if (params[axis] != null) {
                const direction = localDirections[index].clone().applyQuaternion(obj.quaternion);
                obj.position.add(direction.multiplyScalar(parseFloat(params[axis])));
            }
        });

        if (params['origin']) {
            this.applyOrigin(obj, params, subscene);
        }
    }

    applyOrigin(obj, params, subscene) {
        const origin = params['origin'].split(',');
        if (origin.length === 3) {
            const originPoint = new THREE.Vector3(
                parseFloat(origin[0]),
                parseFloat(origin[1]),
                parseFloat(origin[2])
            );
            obj.position.sub(originPoint);
            this.applyRotationsAroundOrigin(obj, originPoint, params);
            obj.position.add(originPoint);
        } else {
            const [refOutputId, indexStr] = params['origin'].split('.');
            const index = parseInt(indexStr);
            const refObj = ThreeUtils.getObjectByUserDataProp(subscene.parent, "outputid", refOutputId) || obj;
            const refPosObj = refObj.children.find(child => child.userData.index === index);

            if (refPosObj && refPosObj.userData.origPos) {
                const originPoint = refPosObj.userData.origPos.clone();
                obj.position.sub(originPoint);
                this.applyRotationsAroundOrigin(obj, originPoint, params);
                obj.position.add(originPoint);
            }
        }
    }

    applyRotationsAroundOrigin(obj, originPoint, params) {
        const rotationOrder = ['rx', 'ry', 'rz'];
        const rotationAxes = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];

        rotationOrder.forEach((rot, index) => {
            if (params[rot] != null) {
                const angle = THREE.MathUtils.degToRad(parseFloat(params[rot]));
                this.rotateAroundPoint(obj, originPoint, rotationAxes[index], angle);
            }
        });
    }

    rotateAroundPoint(obj, point, axis, angle) {
        obj.position.sub(point);
        obj.position.applyAxisAngle(axis, angle);
        obj.position.add(point);
        obj.rotateOnAxis(axis, angle);
    }
    updateChild2(child, cadnode) {

        if (child.userData.refposobj) {
            child.userData.refposobj.updateMatrixWorld(true);
            const worldpos = child.getWorldPosition(new THREE.Vector3());
            child.userData.refposobj.position.copy(worldpos);
        }

        let quat = new THREE.Quaternion();
        if (child.userData.refnormalobj) {
            child.updateMatrixWorld(true);
            child.updateMatrix();
            child.userData.refnormalobj.updateMatrixWorld(true);
            const refnormal = child.userData.normal;
            const normalMatrix = new THREE.Matrix4();
            normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
            child.userData.refnormalobj.quaternion.setFromRotationMatrix(normalMatrix);
            quat = child.userData.refnormalobj.quaternion.clone();
            const xAxis = new THREE.Vector3(1, 0, 0);
            const qRotation = new THREE.Quaternion();
            qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
            quat.multiply(qRotation);

            child.userData.refnormalobj.quaternion.copy(cadnode.quaternion);
            child.userData.refnormalobj.quaternion.multiply(quat);
        }

        const rotationQuat = new THREE.Quaternion();
        if (child.userData.refposobj?.userData?.rx) {
            const rxQuat = new THREE.Quaternion();
            rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.rx)));
            rotationQuat.multiply(rxQuat);
        }
        if (child.userData.refposobj?.userData?.ry) {
            const ryQuat = new THREE.Quaternion();
            ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.ry)));
            rotationQuat.multiply(ryQuat);
        }
        if (child.userData.refposobj?.userData?.rz) {
            const rzQuat = new THREE.Quaternion();
            rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.rz)));
            rotationQuat.multiply(rzQuat);
        }

        child.userData.refposobj?.quaternion.multiply(rotationQuat);
        child.userData.refposobj?.updateMatrix();

        //     const refnormal = child.userData.refnormalobj.userData.normal.clone();
        //     console.log("refnormal", refnormal);
        //     cadnode.updateMatrixWorld(true);

        //     const normalMatrix = new THREE.Matrix4();
        //     normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
        //     var q = new THREE.Quaternion();

        //     q.setFromRotationMatrix(normalMatrix);
        //     const xAxis = new THREE.Vector3(1, 0, 0);
        //     const qRotation = new THREE.Quaternion();
        //     qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
        //     q.multiply(qRotation);

        //    // cadnode.quaternion.copy(q);
        //     //  refobj.updateMatrixWorld(true);
        //     //  refobj.updateMatrix();      
        //     cadnode.quaternion.copy(child.userData.refnormalobj.quaternion.clone());
        //     cadnode.quaternion.multiply(q);

        //     const rotationQuat = new THREE.Quaternion();
        //     if (params['rx'] != null) {
        //         const rxQuat = new THREE.Quaternion();
        //         rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(params['rx'])));
        //         rotationQuat.multiply(rxQuat);
        //     }
        //     if (params['ry'] != null) {
        //         const ryQuat = new THREE.Quaternion();
        //         ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(params['ry'])));
        //         rotationQuat.multiply(ryQuat);
        //     }
        //     if (params['rz'] != null) {
        //         const rzQuat = new THREE.Quaternion();
        //         rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(params['rz'])));
        //         rotationQuat.multiply(rzQuat);
        //     }
        //     cadnode.quaternion.multiply(rotationQuat);

        //     cadnode.updateMatrix();
        //     refnormalob.userData.refnormalobj = cadnode;





        if (child.userData.refposobj) {

            this.applylocaltrafos2(child.userData.refposobj, child.parent);
            child.userData.refposobj.children.forEach(grandChild => this.updateChild(grandChild, cadnode)); // TODO: ggf mehrfach aufrufen?
        }
    }

    applylocaltrafos2(obj, subscene) {
        var cadnode = obj;
        // get params from rows

        var params = Utils.mapRowToParams(obj.userData.row, obj.userData.commentrow);


        if (params['x'] != null) {
            let xDirection = new THREE.Vector3(parseFloat(params['x']), 0, 0); // Local X
            xDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(xDirection);
        }
        if (params['y'] != null) {
            let yDirection = new THREE.Vector3(0, parseFloat(params['y']), 0); // Local Y
            yDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(yDirection);
        }
        if (params['z'] != null) {
            let zDirection = new THREE.Vector3(0, 0, parseFloat(params['z'])); // Local Z
            zDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(zDirection);
        }


        // if (params['origin']) {
        //     const origin = params['origin'].split(',');
        //     if (origin.length == 3) {
        //         let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
        //         direction.applyQuaternion(cadnode.quaternion);
        //         cadnode.position.add(direction);
        //     }
        //     // sonst pos von reobj
        //     else {
        //         const [refoutputid, indexStr] = params['origin'].split('.');
        //         const index = parseInt(indexStr);
        //         let refobj = ThreeUtils.getObjectByUserDataProp(subscene, "outputid", refoutputid);
        //         if (!refobj)
        //             refobj = cadnode
        //         const refposob = refobj.children.find(child => child.userData.index === index);
        //         if (refposob)
        //             cadnode.position.sub(refposob.position);
        //     }

        // }
        if (params['origin']) {
            const origin = params['origin'].split(',');
            if (origin.length == 3) {
                let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                direction.applyQuaternion(cadnode.quaternion);
                const rotx = params['rx'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rx'])) : 0;
                const roty = params['ry'] != null ? THREE.MathUtils.degToRad(parseFloat(params['ry'])) : 0;
                const rotz = params['rz'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rz'])) : 0;
                const rotationMatrix = new THREE.Matrix4();
                rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                    THREE.MathUtils.degToRad(rotx),
                    THREE.MathUtils.degToRad(roty),
                    THREE.MathUtils.degToRad(rotz)
                ));
                //   direction.applyMatrix4(rotationMatrix);
                cadnode.position.add(direction);
            }
            // sonst pos von reobj
            else {
                const [refoutputid, indexStr] = params['origin'].split('.');
                const index = parseInt(indexStr);
                let refobj = ThreeUtils.getObjectByUserDataProp(subscene, "outputid", refoutputid);
                if (!refobj)
                    refobj = cadnode
                const refposob = refobj.children.find(child => child.userData.index === index);

                if (refposob) {
                    let direction = refposob.position.clone(); // beim 2. durchlauf hier nicht mehr oginal position, weil bereits verschoben // new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                    // if (!refposob.userData.origpos) {
                    //     refposob.userData.origpos = refposob.position.clone();
                    // } else {
                    direction = refposob.userData.origpos.clone();
                    // }
                    direction.applyQuaternion(cadnode.quaternion);

                    const rotx = params['rx'] != null ? parseFloat(params['rx']) : 0;
                    const roty = params['ry'] != null ? parseFloat(params['ry']) : 0;
                    const rotz = params['rz'] != null ? parseFloat(params['rz']) : 0;
                    const rotationMatrix = new THREE.Matrix4();
                    rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                        THREE.MathUtils.degToRad(rotx),
                        THREE.MathUtils.degToRad(roty),
                        THREE.MathUtils.degToRad(rotz)
                    ));
                    //      direction.applyMatrix4(rotationMatrix);

                    // todo, rot dazu

                    //cadnode.position.sub(refposob.position);
                    cadnode.position.sub(direction);
                }
            }

        }
    }


    addEdgesToNode(cadnode, params, typ) {
        if (["EXTRUSION", "CYLINDER", "SUBTRACTION", "CUBE"].includes(typ) && !(params['edges'] === false && typeof params['edges'] === "boolean")) {
            const egeometry = new THREE.EdgesGeometry(cadnode.geometry, 6);
            const linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1 }));
            linestandard.name = "KANTE";
            cadnode.add(linestandard);
        }
    }

    setNodeTransformations(cadnode, params) {
        cadnode.position.set(params['x'] || 0, params['y'] || 0, params['z'] || 0);
        cadnode.rotation.set(this.grad2rad(params['rx'] || 0), this.grad2rad(params['ry'] || 0), this.grad2rad(params['rz'] || 0));
        cadnode.scale.set(params['sx'] || 1, params['sy'] || 1, params['sz'] || 1);
    }

    handleGltfNodes(cadnode, path, commentrow, row) {
        for (const comment of commentrow || []) {
            if (comment.startsWith("node")) {
                const objectname = path;
                const object1 = cadnode;
                const nodename = comment.split('=')[1];
                const [prop, value] = row[commentrow.indexOf(comment)].split('=');
                this.updateNodel(prop, nodename, value, object1);
            }
        }
    }

    async createGltf(filename) {

        var mesh = null;
        // var texture = null;
        // var material = null;
        // var textureLoader = new THREE.TextureLoader();
        // var materialLoader = new THREE.MaterialLoader();

        var gltf = await (this.loader).loadAsync(filename);
        var mixer = new THREE.AnimationMixer(gltf.scene);

        gltf.animations.forEach((clip) => {
            mixer.clipAction(clip).play();
            gltf.scene.userData.mixer = mixer;
        });

        gltf.scene.traverse((child) => {
            if ((child as any).isMesh || (child as any).type == "Object3D") {
                (child as any).cursor = 'pointer';
                child.castShadow = true;
                child.receiveShadow = true;
                //   (child as any).geometry.center(); // center here
            }
            if (child?.userData) {
                child.userData.isGltf = true;
            }
        });
        mesh = gltf.scene;
        // gltf = gltf;
        // mesh = gltf.scene.children[0];
        // loader.load(filename, function (gltf) {
        // texture = textureLoader.load(gltf.textures[0].source);
        // material = materialLoader.parse(gltf.materials[0]);
        // material.map = texture;
        // material.needsUpdate = true;
        // mesh.material = material;
        // });

        //  ThreeUtils.centerGltf(gltf);
        var bb = ThreeUtils.getBoundingBox(mesh);
        const dimensions = new THREE.Vector3().subVectors(bb.max, bb.min);
        mesh.userData.bb = bb;
        mesh.userData.dimensions = dimensions;

        return mesh;
    }





    cutMesh(meshtocut, cutmesh) {
        meshtocut.updateMatrixWorld(true);
        cutmesh.updateMatrixWorld(true);

        try {
            const bspA = CSG.fromMesh(cutmesh);
            const bspB = CSG.fromMesh(meshtocut);
            var intersection = bspB.intersect(bspA) as any;
            if (intersection?.polygons?.length > 0) {
                const bspResult = bspB.subtract(bspA);
                var meshResult = CSG.toMesh(bspResult, meshtocut.matrix) as any;
                meshResult.updateMatrix();
                meshResult.material = meshtocut.material;
                meshResult.receiveShadow = true;
                meshResult.castShadow = true;
                meshResult.name = meshtocut.name;
                meshResult.visible = true;
                meshResult.userData = JSON.parse(JSON.stringify(meshtocut.userData));

                // Preserve the original matrix transformations
                meshResult.position.copy(meshtocut.position);
                meshResult.rotation.copy(meshtocut.rotation);
                meshResult.scale.copy(meshtocut.scale);
                meshResult.updateMatrix();
                meshResult.updateMatrixWorld(true);

                meshtocut.visible = false;

                // Add edges like in cadnode
                const edges = new THREE.EdgesGeometry(meshResult.geometry);
                const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
                const edgesMesh = new THREE.LineSegments(edges, edgesMaterial);
                meshResult.add(edgesMesh);

                // Recalculate face centers with the updated matrix
                this.addFaceCenters(meshResult, meshResult.userData.outputid);

                if (!meshResult) {
                    //       console.log('kein schnitt')
                }
                return meshResult;

            } else {
                //     console.log('keine überschneidung')

            }
        }
        catch (ie) {
            //      continue;
            console.log("fehler", ie)
        }
        return meshtocut;
    }

    async createSubtraction(tocutoutputids, cutoutputids, sheetid, scene, name, path, configuration, excels, recreated = false) {
        console.log('createSubtraction', tocutoutputids, cutoutputids, sheetid, scene, name, path, configuration)


        // vorhandene ggf. löschen


        var tocutoutmeshes = tocutoutputids.split(",");
        var cutmeshes = cutoutputids.split(",");
        var resultmeshes = [];
        var configpath = path.substring(0, path.lastIndexOf('#'));
        for (var j = 0; j < cutmeshes.length; j++) {
            cutmeshes[j] = configpath + "#" + cutmeshes[j].trim();
        }
        for (var j = 0; j < tocutoutmeshes.length; j++) {
            tocutoutmeshes[j] = configpath + "#" + tocutoutmeshes[j].trim();
        }

        for (var i = 0; i < tocutoutmeshes.length; i++) {

            var meshtocut = scene.getObjectByName(tocutoutmeshes[i], true);

            if (recreated) {
                meshtocut = await this.recreateCADNode(meshtocut, scene, excels);
            }

            if (meshtocut) {


                resultmeshes = [];

                // var cutmesh = scene.getObjectByName(cutmeshes[i], true) as THREE.Mesh;
                // var resultmesh = this.cutMesh(meshtocut, cutmesh);
                // resultmesh.updateMatrix();
                // resultmesh.updateMatrixWorld(true);
                // resultmeshes.push(resultmesh);
                var resultmesh = meshtocut;
                var parent = meshtocut.parent;
                for (var j = 0; j < cutmeshes.length; j++) {
                    var cutmesh = scene.getObjectByName(cutmeshes[j], true);
                    if (cutmesh) {
                        cutmesh.updateMatrix();
                        cutmesh.updateMatrixWorld(true);

                        resultmesh = this.cutMesh(resultmesh, cutmesh);
                        if (resultmesh) {
                            //   resultmeshes.push(resultmesh);
                            resultmesh.visible = true;
                            cutmesh.visible = false;
                            resultmesh.userData = { ...meshtocut.userData };
                            resultmesh.userData.cuttedBy = cutmesh.name;
                            if (!cutmesh.userData.cutted)
                                cutmesh.userData.cutted = {};
                            cutmesh.userData.cutted[resultmesh.name] = resultmesh.name;
                            cutmesh.userData.tocutoutputids = tocutoutputids;
                            cutmesh.userData.cutoutputids = cutoutputids;
                            cutmesh.userData.subtraction = name;

                            // Add to parent and update matrices
                            if (j > 0) {
                                console.log('remove', resultmeshes[j - 1].name);
                                parent?.remove(resultmeshes[j - 1]);
                            } else {
                                console.log('remove', meshtocut.name);
                                parent?.remove(meshtocut);
                            }


                            //     console.log('add', resultmesh.name);
                            parent?.add(resultmesh);
                            resultmesh.updateMatrix();
                            resultmesh.updateMatrixWorld(true);



                            // Ensure face centers are updated with correct world positions
                            this.addFaceCenters(resultmesh, resultmesh.userData.outputid);


                            // connection
                            meshtocut.userData.subtraction = name;
                            meshtocut.userData.tocutoutputids = tocutoutputids;
                            meshtocut.userData.cutoutputids = cutoutputids;
                            resultmesh.userData.subtraction = name;
                            resultmesh.userData.tocutoutputids = tocutoutputids;
                            resultmesh.userData.cutoutputids = cutoutputids;
                            resultmeshes.push(resultmesh);
                        }
                        if (!name.startsWith('root^')) {
                            name = "root^" + name;
                        }
                    }
                }

            }
        }

        //        var parent1 = resultmeshes[resultmeshes.length - 1].parent;
        //        parent1.add(resultmeshes[resultmeshes.length - 1]);
        return resultmeshes[resultmeshes.length - 1];
    }

    // async createSubtraction2(tocutoutputids, cutoutputids, sheetid, scene, name, path, configuration, excels, recreated = false) {
    //     console.log('createSubtraction', tocutoutputids, cutoutputids, sheetid, scene, name, path, configuration)
    //     var tocutoutmeshes = tocutoutputids.split(",");
    //     var cutmeshes = cutoutputids.split(",");
    //     var resultmeshes = [];

    //     // TODO: ggf mehrere verarbeiten
    //     var configpath = path.substring(0, path.lastIndexOf('#'));
    //     for (let i = 0; i < cutmeshes.length; i++) {
    //         cutmeshes[i] = configpath + "#" + cutmeshes[i].trim();
    //     }
    //     var cutmesh = scene.getObjectByName(cutmeshes[0], true);

    //     if (cutmesh) {
    //         var meshtocut = scene.getObjectByName(configpath + "#" + tocutoutmeshes[0], true);
    //         var resultmesh = this.cutMesh(meshtocut, cutmesh);
    //         for (var i = 1; i < tocutoutmeshes.length; i++) {
    //             var configpath = path.substring(0, path.lastIndexOf('#'));
    //             //   tocutoutmeshes[i] = configpath + "#" + tocutoutmeshes[i].trim();
    //             //     var meshtocut = scene.getObjectByName(tocutoutmeshes[i], true);
    //             cutmesh = scene.getObjectByName(cutmeshes[i], true);
    //             resultmesh = this.cutMesh(resultmesh, cutmesh);

    //             if (recreated) {
    //                 meshtocut = await this.recreateCADNode(meshtocut, scene, excels);
    //             }

    //             if (meshtocut) {
    //                 for (var j = 0; j < cutmeshes.length; j++) {
    //                     if (resultmesh) {
    //                         resultmeshes.push(resultmesh);
    //                         resultmesh.visible = true;
    //                         cutmesh.visible = false;
    //                         resultmesh.userData = { ...meshtocut.userData };
    //                         resultmesh.userData.cuttedBy = cutmesh.name;
    //                         if (!cutmesh.userData.cutted)
    //                             cutmesh.userData.cutted = {};
    //                         cutmesh.userData.cutted[resultmesh.name] = resultmesh.name;
    //                         cutmesh.userData.tocutoutputids = tocutoutputids;
    //                         var parent = meshtocut.parent;

    //                         // Add to parent and update matrices
    //                         parent.add(resultmesh);
    //                         resultmesh.updateMatrix();
    //                         resultmesh.updateMatrixWorld(true);

    //                         // Ensure face centers are updated with correct world positions
    //                         this.addFaceCenters(resultmesh, resultmesh.userData.outputid);

    //                         parent.remove(meshtocut);

    //                         // connection
    //                         meshtocut.userData.subtraction = name;
    //                         cutmesh.userData.subtraction = name;
    //                     }
    //                     if (!name.startsWith('root^')) {
    //                         name = "root^" + name;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return resultmeshes[resultmeshes.length - 1];
    // }

    updateCuts(cutmesh, resultmesh) {
        if (cutmesh.userData.cutted) {
            // Update the cutting relationship for the cut mesh
            if (!cutmesh.userData.cutted[resultmesh.name]) {
                cutmesh.userData.cutted[resultmesh.name] = resultmesh.name;

                // Create new result mesh from the cut operation
                var newResultMesh = this.cutMesh(resultmesh, cutmesh);
                if (newResultMesh) {
                    newResultMesh.visible = true;
                    cutmesh.visible = false;
                    newResultMesh.userData = { ...resultmesh.userData };
                    newResultMesh.userData.cuttedBy = cutmesh.name;

                    // Update parent relationship
                    var parent = resultmesh.parent;
                    if (parent) {
                        parent.add(newResultMesh);
                        parent.remove(resultmesh);
                    }
                }
            }
        } else {
            cutmesh.userData.cutted = { [resultmesh.name]: resultmesh.name };
        }

        if (resultmesh.userData.cuttedBy) {
            // Update the cutting relationship for the result mesh
            if (!resultmesh.userData.cuttedBy[cutmesh.name]) {
                resultmesh.userData.cuttedBy[cutmesh.name] = cutmesh.name;
            }
        } else {
            resultmesh.userData.cuttedBy = { [cutmesh.name]: cutmesh.name };
        }
    }

    createSphere(radius, material) {
        const widthSegments = 12;
        const heightSegments = 8;

        var material1 = this.str2material(material);


        const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);
        const mesh = new THREE.Mesh(geometry, material1);



        return mesh;
    }
    str2material(str): THREE.Material {
        try {
            var material1 = new THREE.MeshStandardMaterial({
                color: 'gray',
                metalness: 0.5,
                roughness: 0.5,
                side: THREE.DoubleSide
            });

            var splitted = str.split(';');
            material1 = new THREE.MeshStandardMaterial({
                color: splitted[0],
                metalness: 0.5,
                roughness: 0.5,
                side: THREE.DoubleSide
            });
            if (splitted.length > 1) {
                material1.roughness = parseFloat(splitted[1]);
            }
            if (splitted.length > 2) {
                material1.metalness = parseFloat(splitted[2]);
            }
            if (splitted.length > 3) {
                //material1.reflectivity = parseFloat(splitted[3]);
                material1.transparent = true;
            }
            if (splitted.length > 4) {
                material1.opacity = parseFloat(splitted[4]);
            }
        } catch (error) {
            console.warn("str2material", str, error);

        }
        return material1;

    }

    createCube(width, length, height, material) {
        // create box
        const geometry = new THREE.BoxGeometry(width, length, height);

        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        return mesh;
    }
    createCylinder(radiusTop, radiusBottom, height, material) {
        const radialSegments = 120;
        const geometry = new THREE.CylinderGeometry(radiusTop, radiusBottom, height, radialSegments);

        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        return mesh;
    }
    async createBillboard(width, height, material, imagePath) {
        // create sprite material
        // var material1 = this.str2material(material) as any;
        // material1 = new THREE.SpriteMaterial();
        // //       material1.
        // // Load the texture
        // const loader = new THREE.TextureLoader();
        // var texture = await loader.loadAsync(imagePath);
        // material1.map = texture;
        // //  texture.encoding = THREE.sRGBEncoding;
        // //      texture.encoding = THREE.sRGBEncoding;
        // texture.colorSpace = THREE.SRGBColorSpace;
        // //   material1.transparent = true;
        // //   material1.premultipliedAlpha = false;

        // const sprite = new THREE.Sprite(material1);
        // sprite.scale.set(width, height, 1);
        return this.createShapeWithTexture(imagePath, width, height);
    }

    async createShapeWithTexture(texturePath, width, height) {
        const shape = new THREE.Group();

        // Create front face
        const geometry = new THREE.PlaneGeometry(width, height);
        const textureLoader = new THREE.TextureLoader();
        const texture = await textureLoader.loadAsync(texturePath);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.repeat.set(1, 1);
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        const material = new THREE.MeshStandardMaterial({
            map: texture,
            side: THREE.DoubleSide
        });

        const plane = new THREE.Mesh(geometry, material);
        shape.add(plane);

        return shape;
    }


    createDimensionLine(p1, p2, lineWidth, lineColor, params = {} as any) {
        console.log('Dimension line params:', params);

        var depthTest = params.depthTest || true;

        // Parse p1 and p2 from strings
        p1 = p1.replace('(', '').replace(')', '').split(',');
        p2 = p2.replace('(', '').replace(')', '').split(',');

        const x1 = parseFloat(p1[0]);
        const y1 = parseFloat(p1[1]);
        const z1 = parseFloat(p1[2]);
        const x2 = parseFloat(p2[0]);
        const y2 = parseFloat(p2[1]);
        const z2 = parseFloat(p2[2]);

        const start = new THREE.Vector3(x1, y1, z1);
        const end = new THREE.Vector3(x2, y2, z2);
        let center = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
        const group = new THREE.Group();

        // Calculate main direction and rotation vectors
        const direction = new THREE.Vector3().subVectors(end, start).normalize();
        const up = new THREE.Vector3(0, 0, 1);
        const right = new THREE.Vector3().crossVectors(direction, up).normalize();

        // Apply rotation around line axis if specified
        if (params.rotation) {
            const angle = THREE.MathUtils.degToRad(parseFloat(params.rotation));
            right.applyAxisAngle(direction, angle);
            up.crossVectors(right, direction);
        }

        // Create the main dimension line
        let points = [x1, y1, z1, x2, y2, z2];
        let lineGeometry = new LineGeometry();
        lineGeometry.setPositions(points);

        let lineMaterial = new LineMaterial({
            color: lineColor,
            linewidth: lineWidth,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
            dashed: params.dashed || false,
            dashScale: params.dashScale || 1,
            dashSize: params.dashSize || 3,
            gapSize: params.gapSize || 1,
            depthTest: depthTest
        });

        let line = new Line2(lineGeometry, lineMaterial);
        line.computeLineDistances();
        line.renderOrder = 999999;
        // group.add(line);

        // Create text sprite
        const length = start.distanceTo(end);
        const dimensionText = params.text || `${length.toFixed(1)}`;
        const sprite = new threeSpritetext(dimensionText);
        sprite.position.copy(center);
        sprite.material.depthTest = depthTest;
        sprite.textHeight = params.textSize || 10;
        sprite.padding = params.textPadding || 2;
        sprite.backgroundColor = params.textBackground || 'rgba(255,255,255,0.9)';
        sprite.color = params.textColor || lineColor;
        sprite.renderOrder = 999999;

        // this.addInteractiveObject(sprite);
        this.interactionManager.add(sprite, {
            mouseEnter: (obj) => {
                console.log('Mouse entered object:', obj);
                this.interactionManager.cursorType = 'pointer';
                sprite.scale.multiplyScalar(1.1);
                sprite.color = 'red';
                //   sprite.material.color.set('red');
            },
            mouseLeave: (obj) => {
                this.interactionManager.cursorType = 'default';
                sprite.scale.multiplyScalar(1 / 1.1);
                sprite.color = 'black';
                //   sprite.material.color.set('black');
            },
            click: (obj, event) => {
                console.log('Object clicked:', obj);
            }
        });
        // Add user data for interaction
        sprite.userData.isDimensionText = true;
        sprite.userData.dimensionValue = length;
        sprite.userData.dimensionLine = group;
        sprite.userData.editcell = params.editcell;
        sprite.userData.p1 = p1;
        sprite.userData.p2 = p2;
        sprite.userData.originalScale = sprite.scale.clone();
        sprite.userData.originalColor = sprite.color;
        sprite.userData.originalBgColor = sprite.backgroundColor;
        sprite.userData.isInteractive = true;

        // Create cones at endpoints
        if (params.showArrows !== false) {
            const coneHeight = lineWidth * (params.arrowSize || 4);
            const coneRadius = coneHeight * 0.5;
            const coneGeometry = new THREE.ConeGeometry(coneRadius, coneHeight, 12);
            const coneMaterial = new THREE.MeshBasicMaterial({
                color: lineColor,
                depthTest: depthTest
            });

            // Start cone
            const startCone = new THREE.Mesh(coneGeometry, coneMaterial);
            const startConeOffset = direction.clone().multiplyScalar(coneHeight / 2);
            startCone.position.copy(start).add(startConeOffset);
            startCone.lookAt(end);
            startCone.rotateX(-Math.PI / 2);
            if (params.rotation) {
                //    startCone.rotateZ(THREE.MathUtils.degToRad(parseFloat(params.rotation)));
            }
            startCone.renderOrder = 999999;
            //    group.add(startCone);

            // End cone
            const endCone = new THREE.Mesh(coneGeometry, coneMaterial);
            const endConeOffset = direction.clone().multiplyScalar(-coneHeight / 2);
            endCone.position.copy(end).add(endConeOffset);
            endCone.lookAt(end);
            endCone.rotateX(Math.PI / 2);
            if (params.rotation) {
                //     endCone.rotateZ(THREE.MathUtils.degToRad(parseFloat(params.rotation)));
            }
            endCone.renderOrder = 999999;
            //    group.add(endCone);
        }

        // Create orthogonal lines
        const orthogonalLength = params.orthogonalLength || 20;
        const orthogonalOffset = params.orthogonalOffset || 0;

        // Calculate orthogonal direction based on main line direction
        const orthoDirection = right.clone();

        // If rotation is specified, rotate the orthogonal direction around the dimension line axis
        if (params.rotation) {
            const rotationAngle = THREE.MathUtils.degToRad(parseFloat(params.rotation));

            // Get the initial up vector
            const up = new THREE.Vector3(0, 0, 1);

            // Calculate initial right vector as cross product of direction and up
            const initialRight = new THREE.Vector3().crossVectors(direction, up).normalize();

            // Create quaternion for rotation
            const quaternion = new THREE.Quaternion();
            quaternion.setFromAxisAngle(direction.normalize(), rotationAngle);

            // Apply rotation to initial right vector
            initialRight.applyQuaternion(quaternion);

            // Set orthoDirection to the rotated vector
            orthoDirection.copy(initialRight);
        }

        // Start orthogonal line
        const startOffset = start.clone();
        if (orthogonalOffset) {
            startOffset.add(direction.clone().multiplyScalar(orthogonalOffset));
        }

        const startOrthoStart = startOffset.clone();
        const startOrthoEnd = startOffset.clone().add(
            orthoDirection.clone().normalize().multiplyScalar(orthogonalLength)
        );

        // End orthogonal line
        const endOffset = end.clone();
        if (orthogonalOffset) {
            endOffset.sub(direction.clone().multiplyScalar(orthogonalOffset));
        }

        const endOrthoStart = endOffset.clone();
        const endOrthoEnd = endOffset.clone().add(
            orthoDirection.clone().normalize().multiplyScalar(orthogonalLength)
        );

        // Create the main dimension line between orthogonal line endpoints
        // Calculate gap size based on actual text dimensions
        const textLength = dimensionText.toString().length;
        const charWidth = (params.textSize || 10) * 0.6; // Approximate width per character
        const textWidth = charWidth * textLength;
        const gapSize = textWidth * 1.1; // Slightly smaller than text width for better appearance

        const gapCenter = new THREE.Vector3().addVectors(startOrthoEnd, endOrthoEnd).multiplyScalar(0.5);
        const lineDirection = new THREE.Vector3().subVectors(endOrthoEnd, startOrthoEnd).normalize();
        const gapOffset = lineDirection.clone().multiplyScalar(gapSize / 2);

        // Create first half of the line
        const firstLinePoints = [
            startOrthoEnd.x, startOrthoEnd.y, startOrthoEnd.z,
            gapCenter.x - gapOffset.x, gapCenter.y - gapOffset.y, gapCenter.z - gapOffset.z
        ];
        const firstLineGeometry = new LineGeometry();
        firstLineGeometry.setPositions(firstLinePoints);
        const firstLine = new Line2(firstLineGeometry, lineMaterial);
        firstLine.computeLineDistances();
        firstLine.renderOrder = 999999;
        group.add(firstLine);

        // Create second half of the line
        const secondLinePoints = [
            gapCenter.x + gapOffset.x, gapCenter.y + gapOffset.y, gapCenter.z + gapOffset.z,
            endOrthoEnd.x, endOrthoEnd.y, endOrthoEnd.z
        ];
        const secondLineGeometry = new LineGeometry();
        secondLineGeometry.setPositions(secondLinePoints);
        const secondLine = new Line2(secondLineGeometry, lineMaterial);
        secondLine.computeLineDistances();
        secondLine.renderOrder = 999999;
        group.add(secondLine);

        // Create orthogonal lines
        const orthoLineMaterial = new LineMaterial({
            color: lineColor,
            linewidth: lineWidth,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
            dashed: false,
            depthTest: depthTest
        });

        // Start orthogonal line
        const startOrthoPoints = [
            startOrthoStart.x, startOrthoStart.y, startOrthoStart.z,
            startOrthoEnd.x, startOrthoEnd.y, startOrthoEnd.z
        ];
        const startOrthoGeometry = new LineGeometry();
        startOrthoGeometry.setPositions(startOrthoPoints);
        const startOrthoLine = new Line2(startOrthoGeometry, orthoLineMaterial);
        startOrthoLine.computeLineDistances();
        startOrthoLine.renderOrder = 999999;
        group.add(startOrthoLine);

        // End orthogonal line
        const endOrthoPoints = [
            endOrthoStart.x, endOrthoStart.y, endOrthoStart.z,
            endOrthoEnd.x, endOrthoEnd.y, endOrthoEnd.z
        ];
        const endOrthoGeometry = new LineGeometry();
        endOrthoGeometry.setPositions(endOrthoPoints);
        const endOrthoLine = new Line2(endOrthoGeometry, orthoLineMaterial);
        endOrthoLine.computeLineDistances();
        endOrthoLine.renderOrder = 999999;
        group.add(endOrthoLine);

        // Create cones at the orthogonal line endpoints
        if (params.showArrows !== false) {
            const coneHeight = lineWidth * (params.arrowSize || 4);
            const coneRadius = coneHeight * 0.5;
            const coneGeometry = new THREE.ConeGeometry(coneRadius, coneHeight, 12);
            const coneMaterial = new THREE.MeshBasicMaterial({
                color: lineColor,
                depthTest: depthTest
            });

            // Calculate direction for cones (along the dimension line)
            const dimensionDirection = new THREE.Vector3().subVectors(endOrthoEnd, startOrthoEnd).normalize();

            // Start cone
            const startCone = new THREE.Mesh(coneGeometry, coneMaterial);
            const startConeOffset = dimensionDirection.clone().multiplyScalar(coneHeight / 2);
            startCone.position.copy(startOrthoEnd).add(startConeOffset);
            startCone.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dimensionDirection);
            startCone.rotateZ(Math.PI);
            startCone.renderOrder = 999999;
            group.add(startCone);

            // End cone
            const endCone = new THREE.Mesh(coneGeometry, coneMaterial);
            const endConeOffset = dimensionDirection.clone().multiplyScalar(-coneHeight / 2);
            endCone.position.copy(endOrthoEnd).add(endConeOffset);
            endCone.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dimensionDirection);
            endCone.rotateY(Math.PI / 2);
            endCone.renderOrder = 999999;
            group.add(endCone);
        }

        // Update text position to be centered on the dimension line
        center = new THREE.Vector3().addVectors(startOrthoEnd, endOrthoEnd).multiplyScalar(0.5);
        sprite.position.copy(center);

        group.renderOrder = 999999;
        // Add the sprite to the group
        group.add(sprite);

        return group;
    }

    createLathe(points, material) {
        var ps = points.split(";");

        for (let i = 0; i < ps.length; i++) {
            var p = ps[i].replace('(', '').replace(')', '').split(",");
            points.push(new THREE.Vector2(p[0], p[1]));
        }
        const geometry = new THREE.LatheGeometry(ps);
        const mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: material }));
        return mesh;
    }

    ////////////////////////////////////////////////////////////
    createShape(points, depth, material) {
        const shape1 = new THREE.Shape();
        shape1.autoClose = true;
        // split coordinates (x,y,z) string into array of coordinates
        var ps = points.split(";");
        for (var i = 0; i < ps.length; i++) {
            var p = ps[i].replace('(', '').replace(')', '').split(",");
            if (i == 0)
                shape1.moveTo(parseFloat(p[0]), parseFloat(p[1]));
            else
                shape1.lineTo(parseFloat(p[0]), parseFloat(p[1]));
            //heartShape.bezierCurveTo(35, 0, 25, 25, 25, 25);
        }

        const extrudeSettings = { depth: depth, bevelEnabled: false, bevelSegments: 2, steps: 2, bevelSize: 1, bevelThickness: 1 };
        const geometry = new THREE.ExtrudeGeometry(shape1, extrudeSettings);


        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        return mesh;
    }



    // create three.js surface
    createSurface(points, indices, material) {

        //var geometry = new THREE.BufferGeometry();
        // var material = new THREE.MeshPhongMaterial({
        //     color: 0xffff00,
        //     polygonOffset: true,
        //     polygonOffsetFactor: 1, // positive value pushes polygon further away
        //     polygonOffsetUnits: 1
        // });

        // var vertices = mesh.vertices;
        // var faces = mesh.faces;
        // var normals = mesh.faceVertexNormals;

        // for (var i = 0; i < faces.length; i++) {
        //     var face = faces[i];
        //     var normal = normals[i];
        //     var va = vertices[face.a];
        //     var vb = vertices[face.b];
        //     var vc = vertices[face.c];

        //     geometry.vertices.push(va);
        //     geometry.vertices.push(vb);
        //     geometry.vertices.push(vc);

        //     geometry.faces.push(new THREE.Face3(i * 3, i * 3 + 1, i * 3 + 2));
        //     geometry.faces[i].normal = normal;
        // }

        // geometry.computeFaceNormals();
        // geometry.computeVertexNormals();

        // var mesh = new THREE.Mesh(geometry, material);
        // mesh.material.side = THREE.DoubleSide;
        // mesh.material.depthWrite = false;

        // return mesh;
    }

    // // create three js text

    async createText(text, fonturl, fontsize, color, align = 'left') {
        fonturl = "assets/Roboto_Bold.json";
        return new Promise((resolve, reject) => {
            const loader = new FontLoader();
            loader.load(fonturl, function (font) {
                const textGeometry = new TextGeometry(text, {
                    font: font,
                    size: fontsize * 10,
                    height: 0.1,
                    curveSegments: 12,
                });

                const material = this.str2material(color);
                const textMesh = new THREE.Mesh(textGeometry, material);

                // Center the text geometry based on alignment
                textGeometry.computeBoundingBox();
                const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;

                switch (align.toLowerCase()) {
                    case 'center':
                        textMesh.position.x = -textWidth / 2;
                        break;
                    case 'right':
                        textMesh.position.x = -textWidth;
                        break;
                    case 'left':
                    default:
                        textMesh.position.x = 0;
                        break;
                }

                resolve(textMesh);
            }.bind(this));
        });
    }


    // // create three.js light
    createLight(color, intensity, distance, decay) {
        var light = new THREE.PointLight(color, intensity, distance, decay);

        return light;
    }

    // // create three.js line
    createLine(p1, p2, width, material) {

        p1 = p1.replace('(', '').replace(')', '').split(',');
        p2 = p2.replace('(', '').replace(')', '').split(',');
        var x1 = parseFloat(p1[0]);
        var y1 = parseFloat(p1[1]);
        var z1 = parseFloat(p1[2]);
        var x2 = parseFloat(p2[0]);
        var y2 = parseFloat(p2[1]);
        var z2 = parseFloat(p2[2]);
        //    const points = [];
        //   points.push(new THREE.Vector3(x1, y1, z1));
        //   points.push(new THREE.Vector3(x2, y2, z2));
        const points = [x1, y1, z1, x2, y2, z2];

        // const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const geometry = new LineGeometry();
        geometry.setPositions(points);
        const material2 = new LineMaterial({
            color: material,  // Choose the color as needed
            linewidth: width,  // Adjust the line width for visibility
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
        });
        var material1 = new THREE.LineBasicMaterial({ color: material, linewidth: width });
        var line = new Line2(geometry, material2);
        line.computeLineDistances();

        return line;
    }

    createArrow(start, end, width, material, headLength, headWidth) {
        // Parse start and end points
        start = start.replace('(', '').replace(')', '').split(',');
        end = end.replace('(', '').replace(')', '').split(',');
        const startVec = new THREE.Vector3(
            parseFloat(start[0]),
            parseFloat(start[1]),
            parseFloat(start[2])
        );
        const endVec = new THREE.Vector3(
            parseFloat(end[0]),
            parseFloat(end[1]),
            parseFloat(end[2])
        );

        // Create group to hold all arrow parts
        const group = new THREE.Group();

        // Create the line part
        const points = [
            startVec.x, startVec.y, startVec.z,
            endVec.x, endVec.y, endVec.z
        ];
        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(points);
        const lineMaterial = new LineMaterial({
            color: material,
            linewidth: width,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)
        });
        const line = new Line2(lineGeometry, lineMaterial);
        line.computeLineDistances();
        group.add(line);

        // Create the arrow head
        const direction = new THREE.Vector3().subVectors(endVec, startVec).normalize();
        const coneGeometry = new THREE.ConeGeometry(headWidth, headLength, 12);
        const coneMaterial = new THREE.MeshBasicMaterial({ color: material });
        const cone = new THREE.Mesh(coneGeometry, coneMaterial);

        // Position the cone at the end point
        cone.position.copy(endVec);

        // Orient the cone to point in the direction of the line
        cone.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction);

        group.add(cone);

        return group;
    }


    // createLine() {

    //     var geometry = new THREE.BoxGeometry(100, 100, 200);

    //     // mesh
    //     var material = new THREE.MeshPhongMaterial({
    //         color: 0xff0000,
    //         polygonOffset: true,
    //         polygonOffsetFactor: 1, // positive value pushes polygon further away
    //         polygonOffsetUnits: 1
    //     });
    //     var mesh = new THREE.Mesh(geometry, material);
    //     //     this.scene.add(mesh);
    //     this.testmesh = mesh;


    //     var geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
    //     var mat = new THREE.LineBasicMaterial({ color: 0xffffff });
    //     var wireframe = new THREE.LineSegments(geo, mat);
    //     mesh.add(wireframe);
    // }

    public createExtrusion(baseplane, extrusionvector, material) {
        try {
            var material1 = this.str2material(material);

            // Parse extrusion vector
            extrusionvector = extrusionvector.replace(/\s/g, '').replace('(', '').replace(')', '').split(',');
            var extrusionDir = new THREE.Vector3(
                parseFloat(extrusionvector[0]),
                parseFloat(extrusionvector[1]),
                parseFloat(extrusionvector[2])
            );

            // Parse base plane points
            var pointsstrs = baseplane.replace(/\s/g, '').split(";");
            var points = [];
            for (var i = 0; i < pointsstrs.length; i++) {
                var pointstr = pointsstrs[i].substring(1, pointsstrs[i].length - 1);
                var x = parseFloat(pointstr.split(",")[0]);
                var y = parseFloat(pointstr.split(",")[1]);
                points.push({ x: x, y: y });
            }

            // Create shape from points
            const shape1 = new THREE.Shape();
            shape1.autoClose = true;
            shape1.moveTo(points[0].x, points[0].y);
            for (var i = 1; i < points.length; i++) {
                shape1.lineTo(points[i].x, points[i].y);
            }

            // Create extrude settings with the Z component as depth
            var extrudeSettings = {
                steps: 1,
                bevelEnabled: false,
                depth: Math.abs(extrusionDir.z)
            };

            // Create geometry and mesh
            const geometry = new THREE.ExtrudeGeometry(shape1, extrudeSettings);
            const mesh = new THREE.Mesh(geometry, material1);

            // Create shear matrix
            const matrix = new THREE.Matrix4();
            if (extrusionDir.z !== 0) {
                // Calculate shear factors - note the change in how we calculate these
                const Sxz = extrusionDir.x / extrusionDir.z; // X shear in Z direction
                const Syz = extrusionDir.y / extrusionDir.z; // Y shear in Z direction

                // Set up the shear matrix - note the changed positions of the shear factors
                matrix.set(
                    1, 0, Sxz, 0,
                    0, 1, Syz, 0,
                    0, 0, 1, 0,
                    0, 0, 0, 1
                );

                // Apply shear transformation
                geometry.applyMatrix4(matrix);
            }

            // If extrusion is in negative Z direction, flip the mesh
            if (extrusionDir.z < 0) {
                const flipMatrix = new THREE.Matrix4();
                flipMatrix.makeScale(1, 1, -1);
                geometry.applyMatrix4(flipMatrix);
                mesh.position.z = extrusionDir.z;
            }

            mesh.receiveShadow = true;
            mesh.castShadow = true;

            return mesh;
        } catch (error) {
            console.error("Error creating extrusion:", error);
            return null;
        }
    }

    public createAluProfile(series: string, size: string, length: number, material: THREE.Material): THREE.Mesh {
        // Get profile dimensions from AluminumProfiles class
        const profileDimensions = AluminumProfiles.getDimensions(series as AluProfileSeries, size);

        if (!profileDimensions) {
            console.error(`Profile not found: ${series} ${size}`);
            return null;
        }

        const { width, height, wallThickness, cornerRadius = 0, slotWidth = 0, slotDepth = 0 } = profileDimensions;

        // Create the base shape for extrusion
        const shape = new THREE.Shape();

        // Outer profile
        shape.moveTo(-width / 2, -height / 2);
        shape.lineTo(width / 2, -height / 2);
        shape.lineTo(width / 2, height / 2);
        shape.lineTo(-width / 2, height / 2);
        shape.lineTo(-width / 2, -height / 2);

        // Inner profile (hollow part)
        const innerWidth = width - 2 * wallThickness;
        const innerHeight = height - 2 * wallThickness;
        const hole = new THREE.Path();
        hole.moveTo(-innerWidth / 2, -innerHeight / 2);
        hole.lineTo(innerWidth / 2, -innerHeight / 2);
        hole.lineTo(innerWidth / 2, innerHeight / 2);
        hole.lineTo(-innerWidth / 2, innerHeight / 2);
        hole.lineTo(-innerWidth / 2, -innerHeight / 2);
        shape.holes.push(hole);

        // Create T-slots if specified
        if (slotWidth > 0 && slotDepth > 0) {
            // Add T-slots on each side
            const addSlot = (x: number, y: number, rotation: number) => {
                const slot = new THREE.Path();
                slot.moveTo(x, y);
                slot.lineTo(x + slotWidth, y);
                slot.lineTo(x + slotWidth, y + slotDepth);
                slot.lineTo(x, y + slotDepth);
                slot.lineTo(x, y);
                shape.holes.push(slot);
            };

            // Add slots on all four sides
            addSlot(-slotWidth / 2, -height / 2, 0);  // Bottom
            addSlot(-slotWidth / 2, height / 2 - slotDepth, Math.PI);  // Top
            addSlot(-width / 2, -slotWidth / 2, -Math.PI / 2);  // Left
            addSlot(width / 2 - slotDepth, -slotWidth / 2, Math.PI / 2);  // Right
        }

        // Create the extrusion geometry
        const extrudeSettings = {
            steps: 1,
            depth: length,
            bevelEnabled: false
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        const mesh = new THREE.Mesh(geometry, material);

        // Rotate the profile so it's oriented along the Z-axis
        mesh.rotation.x = -Math.PI / 2;

        return mesh;
    }

    public createIBeam(profile: string, width: number, height: number, length: number, flangeThickness: number, webThickness: number, material: THREE.Material, startSlope: number = 0, endSlope: number = 0): THREE.Mesh {
        console.log("Creating I-beam with params:", { profile, width, height, length, flangeThickness, webThickness, startSlope, endSlope });

        // Get standard profile dimensions if a profile name is provided
        if (profile) {
            const [standard, size] = profile.split(' ');
            const profileDims = IBeamStandards.getDimensions(standard as IBeamStandard, size);
            if (profileDims) {
                width = profileDims.width;
                height = profileDims.height;
                flangeThickness = profileDims.flangeThickness;
                webThickness = profileDims.webThickness;
                console.log("Using standard profile dimensions:", profileDims);
            } else {
                console.warn("Profile not found:", profile);
            }
        }

        // Validate dimensions
        if (!width || !height || !length) {
            console.warn("Invalid I-beam dimensions:", { width, height, length });
            return null;
        }

        // Create the basic I-beam shape
        const shape = new THREE.Shape();
        const halfWidth = width / 2;
        const halfWebThickness = webThickness / 2;

        // Define the I-beam profile points
        shape.moveTo(-halfWidth, -height / 2);
        shape.lineTo(halfWidth, -height / 2);
        shape.lineTo(halfWidth, -height / 2 + flangeThickness);
        shape.lineTo(halfWebThickness, -height / 2 + flangeThickness);
        shape.lineTo(halfWebThickness, height / 2 - flangeThickness);
        shape.lineTo(halfWidth, height / 2 - flangeThickness);
        shape.lineTo(halfWidth, height / 2);
        shape.lineTo(-halfWidth, height / 2);
        shape.lineTo(-halfWidth, height / 2 - flangeThickness);
        shape.lineTo(-halfWebThickness, height / 2 - flangeThickness);
        shape.lineTo(-halfWebThickness, -height / 2 + flangeThickness);
        shape.lineTo(-halfWidth, -height / 2 + flangeThickness);
        shape.lineTo(-halfWidth, -height / 2);

        // Create extrusion settings
        const extrudeSettings = {
            steps: 1,
            depth: length,
            bevelEnabled: false
        };

        // Create the basic geometry
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        // Apply slopes if needed
        if (startSlope !== 0 || endSlope !== 0) {
            const positions = geometry.attributes.position.array;
            const vertexCount = positions.length / 3;

            for (let i = 0; i < vertexCount; i++) {
                const x = positions[i * 3];
                const y = positions[i * 3 + 1];
                const z = positions[i * 3 + 2];

                // Apply start slope (at z = 0)
                if (z < 0.01) {
                    const startOffset = y * Math.tan(this.grad2rad(startSlope));
                    positions[i * 3 + 2] = startOffset;
                }

                // Apply end slope (at z = length)
                if (Math.abs(z - length) < 0.01) {
                    const endOffset = y * Math.tan(this.grad2rad(endSlope));
                    positions[i * 3 + 2] = length - endOffset;
                }
            }

            geometry.attributes.position.needsUpdate = true;
            geometry.computeVertexNormals();
        }

        // Create and return the mesh
        const mesh = new THREE.Mesh(geometry, material);

        // Add edges to the I-beam
        const egeometry = new THREE.EdgesGeometry(geometry, 6);
        const linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1 }));
        linestandard.name = "KANTE";
        linestandard.userData.type = "edge";
        linestandard.userData.isHelper = true;
        mesh.add(linestandard);

        // Rotate the beam to align with Z-axis like other profiles
        mesh.rotation.x = -Math.PI / 2;

        // Center the beam along its length
        mesh.position.z = -length / 2;

        console.log("I-beam created successfully");
        return mesh;
    }

    /// deprecated ///////////////////////////////////////////////////////
    private rotateAboutWorldAxis(obj: THREE.Object3D, axis: string, rotation: number) {

        //Create a matrix
        var matrix = new THREE.Matrix4();
        // Rotate the matrix
        if (axis == "x")
            matrix.makeRotationX(rotation);
        else if (axis == "y")
            matrix.makeRotationY(rotation);
        else if (axis == "z")
            matrix.makeRotationZ(rotation);

        //rotate the object using the matrix
        obj.applyMatrix4(matrix);

    }

    public EdgesGeometry(geometry) {

        // var thresholdAngle = 1; // (thresholdAngle !== undefined) ? thresholdAngle : 1;
        // // buffer
        var vertices = [];
        // // helper variables
        var thresholdDot = Math.cos(THREE.MathUtils.DEG2RAD * 1);
        var thresholdDot2 = Math.cos(THREE.MathUtils.DEG2RAD * 1111);
        var edge = [0, 0], edges = {}, edge1, edge2;
        var key, keys = ['a', 'b', 'c'];
        // prepare source geometry
        var geometry2;
        if (geometry.isBufferGeometry) {
            geometry2 = new THREE.BufferGeometry();
            //     geometry2.fromBufferGeometry(geometry);
        } else {
            geometry2 = geometry.clone();
        }
        geometry2.mergeVertices();
        geometry2.computeFaceNormals();
        var sourceVertices = geometry2.vertices;
        var faces = geometry2.faces;
        // now create a data structure where each entry represents an edge with its adjoining faces
        for (var i = 0, l = faces.length; i < l; i++) {
            var face = faces[i];
            for (var j = 0; j < 3; j++) {
                edge1 = face[keys[j]];
                edge2 = face[keys[(j + 1) % 3]];
                edge[0] = Math.min(edge1, edge2);
                edge[1] = Math.max(edge1, edge2);
                key = edge[0] + ',' + edge[1];
                if (edges[key] === undefined) {
                    edges[key] = { index1: edge[0], index2: edge[1], face1: i, face2: undefined };
                } else {
                    edges[key].face2 = i;
                }
            }
        }

        // generate vertices
        for (key in edges) {
            var e = edges[key];
            // an edge is only rendered if the angle (in degrees) between the face normals of the adjoining faces exceeds this value. default = 1 degree.
            if (
                e.face2 === undefined ||
                (faces[e.face1].normal.dot(faces[e.face2].normal) <= thresholdDot)) {//} && faces[e.face1].normal.dot(faces[e.face2].normal) >= thresholdDot2)) {

                if (e.face2 !== undefined) {
                    var vertex = sourceVertices[e.index1];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                    vertex = sourceVertices[e.index2];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                }

            }
        }

        var g = new THREE.BufferGeometry() as any; /// TODO: funzt?
        g.type = "EdgesGeometry";
        // build geometry
        var b = new THREE.Float32BufferAttribute(vertices, 3);
        g.setAttribute('position', b);

        return g;

    }

    // Add this method to the CADNodeEngine class
    private createConnectorSprite(label: string): THREE.Sprite {
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        const context = canvas.getContext('2d');

        // Clear the canvas with transparent background
        context.clearRect(0, 0, canvas.width, canvas.height);

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = canvas.width / 2 - 5;

        // Create a radial gradient with transparency
        const gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
        gradient.addColorStop(0, 'rgba(255, 100, 100, 1)');  // Full opacity for better visibility
        gradient.addColorStop(1, 'rgba(200, 50, 50, 1)');

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        context.fillStyle = gradient;
        context.fill();

        // Add a more visible border
        context.strokeStyle = 'rgba(0, 0, 0, 0.8)';
        context.lineWidth = 6;
        context.stroke();

        // Draw the label with improved contrast
        context.fillStyle = 'rgba(255, 255, 255, 1)';  // Solid white for better visibility
        context.strokeStyle = 'rgba(0, 0, 0, 0.8)';
        context.lineWidth = 6;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.font = 'bold 100px Arial';
        context.strokeText(label, centerX, centerY);
        context.fillText(label, centerX, centerY);

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;

        const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
            depthTest: false,  // Ignore z-depth
            depthWrite: false,
            sizeAttenuation: true  // Enable size attenuation for camera distance scaling
        });

        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(15, 15, 1);  // Make sprites bigger
        return sprite;
    }

    // Add this method to create connector visualization with normal vector
    private createConnectorVisualization(position: THREE.Vector3, normal: THREE.Vector3, label: string): THREE.Group {
        const group = new THREE.Group();

        // Create sprite for the connector point
        const sprite = this.createConnectorSprite(label);
        sprite.position.copy(position);
        //   sprite.visible = isMainConfig; // Set initial visibility based on isMainConfig
        group.add(sprite);

        // Create arrow to show normal direction
        const arrowLength = 15;
        const arrowHelper = new THREE.ArrowHelper(
            normal.normalize(),
            position,
            arrowLength,
            0xff0000, // Red color
            arrowLength * 0.3, // Head length
            arrowLength * 0.2  // Head width
        );
        //   arrowHelper.visible = isMainConfig; // Set initial visibility based on isMainConfig
        group.add(arrowHelper);

        // Add a userData flag to identify this as a connector group
        group.userData.isConnector = true;

        return group;
    }

    public setupDimensionLineInteractions(group: THREE.Group, interactionManager: InteractionManager) {
        group.traverse((object) => {
            if (object.userData?.isDimensionText && object.userData?.isInteractive) {
                interactionManager.add(object, {
                    mouseEnter: (obj) => {
                        const sprite = obj as threeSpritetext;  // Cast to correct type
                        sprite.scale.multiplyScalar(1.2);
                        interactionManager.cursorType = 'pointer';
                        sprite.color = '#ff0000';
                        sprite.backgroundColor = 'rgba(255,255,255,0.9)';
                    },
                    mouseLeave: (obj) => {
                        const sprite = obj as threeSpritetext;  // Cast to correct type
                        sprite.scale.copy(sprite.userData.originalScale);
                        interactionManager.cursorType = 'default';
                        sprite.color = sprite.userData.originalColor;
                        sprite.backgroundColor = sprite.userData.originalBgColor;
                    },
                    click: (obj, event) => {
                        const sprite = obj as THREE.Sprite;
                        console.log('Clicked dimension text:', sprite.userData.dimensionValue);
                        if (sprite.userData.editcell) {
                            // Handle edit cell interaction if needed
                        }
                    }
                });
            }
        });
        return group;
    }



    // Example of adding an object with interactions:
    private addInteractiveObject(object: THREE.Object3D) {
        this.interactionManager.add(object, {
            mouseEnter: (obj) => {
                console.log('Mouse entered object:', obj);
                document.body.style.cursor = 'pointer';
                // Add hover effects
            },
            mouseLeave: (obj) => {
                document.body.style.cursor = 'default';
                // Remove hover effects
            },
            click: (obj, event) => {
                // Handle click
                console.log('Object clicked:', obj);
            },
            doubleClick: (obj, event) => {
                // Handle double click
                //  this.sceneDoubleClick(event);
            }
        });
    }

    /**
     * Creates an InstancedMesh from an existing mesh
     * @param sourceMesh The source mesh to create instances from
     * @param count The number of instances to create
     * @param transforms Array of objects containing position, rotation, and scale for each instance
     * @returns THREE.InstancedMesh
     */
    createInstancedMeshFromExisting(sourceMesh: THREE.Mesh, count: number, transforms: Array<{
        position: THREE.Vector3,
        rotation: THREE.Euler,
        scale: THREE.Vector3
    }> = []): THREE.InstancedMesh {
        // Create the instanced mesh
        const instancedMesh = new THREE.InstancedMesh(
            sourceMesh.geometry,
            sourceMesh.material,
            count
        );

        // Copy properties from source mesh
        instancedMesh.castShadow = sourceMesh.castShadow;
        instancedMesh.receiveShadow = sourceMesh.receiveShadow;
        instancedMesh.name = sourceMesh.name + "_instanced";

        // Set up matrix for each instance
        const matrix = new THREE.Matrix4();
        const position = new THREE.Vector3();
        const rotation = new THREE.Euler();
        const quaternion = new THREE.Quaternion();
        const scale = new THREE.Vector3(1, 1, 1);

        // Apply transforms for each instance
        for (let i = 0; i < count; i++) {
            if (transforms[i]) {
                position.copy(transforms[i].position);
                rotation.copy(transforms[i].rotation);
                scale.copy(transforms[i].scale);
            } else {
                // Default transform if not provided
                position.set(0, 0, 0);
                rotation.set(0, 0, 0);
                scale.set(1, 1, 1);
            }

            // Create matrix from position, rotation, and scale
            matrix.compose(
                position,
                quaternion.setFromEuler(rotation),
                scale
            );

            // Set the matrix for this instance
            instancedMesh.setMatrixAt(i, matrix);
        }

        // Copy user data from source mesh
        instancedMesh.userData = { ...sourceMesh.userData };
        instancedMesh.userData.isInstanced = true;
        instancedMesh.userData.sourceGeometry = sourceMesh.geometry.uuid;
        instancedMesh.userData.instanceCount = count;

        // Update the instance matrix
        instancedMesh.instanceMatrix.needsUpdate = true;

        return instancedMesh;
    }

    /**
     * Updates the transform of a specific instance in an InstancedMesh
     * @param instancedMesh The InstancedMesh to update
     * @param instanceIndex The index of the instance to update
     * @param position New position
     * @param rotation New rotation
     * @param scale New scale
     */
    updateInstanceTransform(
        instancedMesh: THREE.InstancedMesh,
        instanceIndex: number,
        position: THREE.Vector3,
        rotation: THREE.Euler,
        scale: THREE.Vector3
    ): void {
        if (instanceIndex >= instancedMesh.count) {
            console.warn('Instance index out of bounds');
            return;
        }

        const matrix = new THREE.Matrix4();
        const quaternion = new THREE.Quaternion();
        quaternion.setFromEuler(rotation);

        matrix.compose(position, quaternion, scale);
        instancedMesh.setMatrixAt(instanceIndex, matrix);
        instancedMesh.instanceMatrix.needsUpdate = true;
    }

    // Add this method to the CADNodeEngine class

    getRefPoses(scene: THREE.Scene): RefPos[] {
        const refPoses: RefPos[] = [];

        // Traverse the scene to find all reference points
        scene.traverse((child) => {
            if (child.userData?.type === "refpoint" && child.userData?.isHelper) {
                const refPos: RefPos = {
                    index: child.userData.index,
                    position: child.position.clone(),
                    worldPosition: new THREE.Vector3(),
                    outputid: child.userData.outputid2,
                    name: child.name,
                    path: child.userData.path,
                    parentName: child.parent?.name,
                    parentPath: child.parent?.userData?.path,
                    origPos: child.userData.origpos?.clone(),
                    normal: undefined  // Initialize as undefined
                };

                // Get world position
                child.getWorldPosition(refPos.worldPosition);

                // Add any normal vector if it exists
                const normalObject = child.parent?.children.find(
                    sibling => sibling.userData?.type === "refnormal" &&
                        sibling.userData?.index === child.userData.index
                );

                if (normalObject?.userData?.normal) {
                    refPos.normal = normalObject.userData.normal.clone();
                }

                refPoses.push(refPos);
            }
        });

        // Sort by outputid and index for consistent ordering
        refPoses.sort((a, b) => {
            if (a.outputid === b.outputid) {
                return a.index - b.index;
            }
            return a.outputid.localeCompare(b.outputid);
        });

        return refPoses;
    }

    // Add after other methods
    public exportToSTEP(scene: THREE.Scene): void {
        const stepExporter = new StepExporter();
        stepExporter.export(scene);
    }

    public async createCopy(sourceObjectId: string, scene: THREE.Scene): Promise<THREE.Object3D | null> {
        // Find the source object in the scene
        const sourceObject = scene.getObjectByName(sourceObjectId);
        if (!sourceObject) {
            console.warn(`Source object with ID ${sourceObjectId} not found`);
            return null;
        }

        // Create a deep clone of the object
        const clonedObject = sourceObject.clone();

        // Generate a unique name for the cloned object
        const baseName = sourceObjectId.replace(/\d+$/, '');
        let counter = 1;
        let newName = `${baseName}${counter}`;
        while (scene.getObjectByName(newName)) {
            counter++;
            newName = `${baseName}${counter}`;
        }
        clonedObject.name = newName;

        // Slightly offset the position of the cloned object
        if (clonedObject.position) {
            clonedObject.position.x += 50; // Offset by 50 units in x direction
        }

        // Copy materials
        if (clonedObject instanceof THREE.Mesh && sourceObject instanceof THREE.Mesh) {
            if (Array.isArray(sourceObject.material)) {
                clonedObject.material = sourceObject.material.map(mat => mat.clone());
            } else {
                clonedObject.material = sourceObject.material.clone();
            }
        }

        // Copy any custom properties
        for (const prop in sourceObject.userData) {
            clonedObject.userData[prop] = JSON.parse(JSON.stringify(sourceObject.userData[prop]));
        }

        return clonedObject;
    }


    createMeasuringGrid(path: string, width: number, length: number, mainGap: number, subGap: number, nthLabel: number, labelSize: number, lineWidth: number, mainColor: string, subColor: string, labelColor: string, params: any = {}): THREE.Group {
        const group = new THREE.Group();

        var outputid = path.split('#').pop();
        // Default visibility settings
        const showMainLines = params.showMainLines !== false;
        const showSubLines = params.showSubLines !== false;
        const showLabels = params.showLabels !== false;
        const showXAxis = params.showXAxis !== false;
        const showYAxis = params.showYAxis !== false;
        const startX = params.startX || -width / 2;
        const endX = params.endX || width / 2;
        const startY = params.startY || -length / 2;
        const endY = params.endY || length / 2;
        const labelOffsetX = parseFloat(params.labelOffsetX) || 0;  // Ensure we parse the offset value
        const labelOffsetY = parseFloat(params.labelOffsetY) || 0;  // Ensure we parse the offset value

        // Create main grid lines
        const mainLinesX = Math.floor(width / mainGap) + 1;
        const mainLinesY = Math.floor(length / mainGap) + 1;

        // Create sub grid lines
        const subLinesX = Math.floor(width / subGap) + 1;
        const subLinesY = Math.floor(length / subGap) + 1;

        // Helper function to format point as string
        const formatPoint = (x: number, y: number, z: number): string => {
            return `(${x},${y},${z})`;
        };

        // Helper function to create a line
        const createLine = (startX: number, startY: number, startZ: number, endX: number, endY: number, endZ: number, color: string, width: number) => {
            const line = this.createLine(
                formatPoint(startX, startY, startZ),
                formatPoint(endX, endY, endZ),
                width,
                color
            );
            group.add(line);
            return line;
        };

        // Helper function to create text sprite
        const createLabel = (text: string, position: THREE.Vector3) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Set canvas size based on text metrics
            if (context) {
                context.font = `${labelSize * 10}px Arial`;
                const metrics = context.measureText(text);
                const textWidth = metrics.width * 1.5; // Stretch text width
                const textHeight = labelSize * 22; // Reduce text height

                // Add padding around text
                canvas.width = textWidth * 1.1;
                canvas.height = textHeight * 1.1;

                // Need to reset font after changing canvas size
                context.font = `${labelSize * 10}px Arial`;
                context.fillStyle = labelColor;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(text, canvas.width / 2, canvas.height / 2);
            }

            const texture = new THREE.CanvasTexture(canvas);
            const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.position.copy(position);
            sprite.scale.set(labelSize, labelSize, 1);
            return sprite;
        };

        // Create sub grid lines
        if (showSubLines) {
            for (let i = 0; i < subLinesX; i++) {
                const x = (i * subGap) - (width / 2);
                if (x >= startX && x <= endX) {
                    createLine(
                        x, Math.max(-length / 2, startY), 0,  // start point
                        x, Math.min(length / 2, endY), 0,   // end point
                        subColor,
                        lineWidth * 0.5
                    );
                }
            }

            for (let i = 0; i < subLinesY; i++) {
                const y = (i * subGap) - (length / 2);
                if (y >= startY && y <= endY) {
                    createLine(
                        Math.max(-width / 2, startX), y, 0,  // start point
                        Math.min(width / 2, endX), y, 0,   // end point
                        subColor,
                        lineWidth * 0.5
                    );
                }
            }
        }

        // Store face centers for interaction
        const faceCenters = [];

        // Create main grid lines and labels, and store face centers
        for (let i = 0; i < mainLinesX; i++) {
            const x = (i * mainGap) - (width / 2);

            // Only create visible grid lines if they're within the visible range
            if (showMainLines && x >= startX && x <= endX) {
                createLine(
                    x, Math.max(-length / 2, startY), 0,  // start point
                    x, Math.min(length / 2, endY), 0,   // end point
                    mainColor,
                    lineWidth
                );

                // Add labels every nth line if they're within the visible range
                if (showLabels && i % nthLabel === 0) {
                    const labelValue = Math.round(x); // Get actual position
                    const labelX = x;
                    const labelY = Math.max(-length / 2, startY) - labelSize; // Position label at the start of visible grid
                    const label = createLabel((labelValue + labelOffsetX).toString(), new THREE.Vector3(labelX, labelY, 0));
                    group.add(label);
                }
            }

            // Add face centers at all main grid intersections, regardless of visibility
            for (let j = 0; j < mainLinesY; j++) {
                const y = (j * mainGap) - (length / 2);
                faceCenters.push({
                    position: new THREE.Vector3(x, y, 0),
                    normal: new THREE.Vector3(0, 0, 1),
                    index: i * mainLinesY + j + 1,
                    outputid: outputid
                });
            }
        }

        if (showMainLines) {
            for (let i = 0; i < mainLinesY; i++) {
                const y = (i * mainGap) - (length / 2);
                if (y >= startY && y <= endY) {
                    createLine(
                        Math.max(-width / 2, startX), y, 0,  // start point
                        Math.min(width / 2, endX), y, 0,   // end point
                        mainColor,
                        lineWidth
                    );

                    // Add labels every nth line
                    if (showLabels && i % nthLabel === 0) {
                        const labelValue = Math.round(y); // Get actual position
                        const labelX = Math.max(-width / 2, startX) - labelSize; // Position label at the start of visible grid
                        const labelY = y;
                        const label = createLabel((labelValue + labelOffsetY).toString(), new THREE.Vector3(labelX, labelY, 0));
                        group.add(label);
                    }
                }
            }
        }

        // Store face centers in userData for editor interaction
        group.userData.faceCenters = faceCenters;

        return group;
    }

    // needed for cuts to just recreate one node without tree generation
    async recreateCADNode(oldNode: THREE.Object3D, scene: THREE.Scene, excels: any): Promise<THREE.Object3D | null> {
        if (!oldNode) {
            console.warn("Cannot recreate node - missing oldNode", oldNode);
            return null;
        }
        if (!oldNode.userData?.row || !oldNode.userData?.commentrow) {
            console.warn("Cannot recreate node - missing userData", oldNode);
            return null;
        }


        // Store the old node's data
        const oldUserData = JSON.parse(JSON.stringify(oldNode?.userData));
        const oldName = oldNode?.name;
        const oldPath = oldNode?.userData?.path;
        const oldParent = oldNode?.parent;

        // Create new node using stored data
        const newNode = await this.createOrUpdateCADNode(
            oldUserData.rowindex,
            oldUserData.sheetid,
            oldName,
            oldUserData.row,
            oldUserData.commentrow,
            scene,
            excels, // excels - not needed for recreation
            oldUserData.configuration,
            null, // loaded nicht gnutzt
            null, // workers nicht gnutzt
            oldUserData.customerid,
            oldUserData.projectid,
            null, // projectconfigmodel 
            oldUserData.sourcesheetid,
            true
        );

        if (newNode) {
            // Remove the old node from its parent
            console.log('removefp', oldNode.name);
            oldNode.removeFromParent();

            // Add new node to the old node's parent if it exists, otherwise to the scene
            if (oldParent) {
                oldParent.add(newNode);
            } else {
                scene.add(newNode);
            }

            // Ensure the new node has the same name and path
            newNode.name = oldName;
            if (!newNode.userData) newNode.userData = {};
            newNode.userData.path = oldPath;

            return newNode;
        }

        return null;
    }

    private async updateConfigurationAndOutputRows(path: string, projectconfigmodel: any, excels: any, outputRows: any[], outputRowIndexes: number[]) {
        let configProps = {} as any;
        if (projectconfigmodel) {
            configProps = Object.keys(projectconfigmodel)
                .filter(key => key.startsWith(path))
                .reduce((obj, key) => {
                    obj[key] = projectconfigmodel[key];
                    return obj;
                }, {});
        }

        for (const key in configProps) {
            let config = configProps[key];
            let rowindex = config.rowindex;
            let sheetid = config.sheetid;
            let row = config.row;
            let configuration = config.configuration;
            var changes = await excels[configuration].setCellContents({ sheet: sheetid, row: rowindex, col: 0 }, [row]);
            console.log("changes", changes);

            if (changes && changes.length > 0) {
                for (const change of changes) {
                    const changeRowIndex = change.address.row;
                    const outputRowPosition = outputRowIndexes.indexOf(changeRowIndex);
                    if (outputRowPosition !== -1 && changeRowIndex !== rowindex) {
                        outputRows[outputRowPosition][change.address.col] = change.newValue;
                    }
                }
            }
        }

        return { outputRows, outputRowIndexes };
    }
}


export { CADNodeEngine }