export class SheetHelper {
    static getCommentRow(dataset: any[][], row: number): any[] | null {
        let i = row - 1;
        const outputrow = SheetHelper.getOutputRow(dataset);
        while (dataset) {
            if (dataset[i][0] === "#")
                return dataset[i];
            i--;
            if (i <= outputrow)
                return null;
        }
        return null;
    }

    static getHeadRow(dataset: any[][], row: number): number {
        let i = row - 1;
        let firstcell = dataset[i][0];
        while (firstcell !== '#' && i !== 0) {
            firstcell = dataset[i][0];
            if (firstcell === '#') return i;
            i--;
        }
        return i;
    }

    static getOutputRow(dataset: any[][]): number {
        let outputrow = 10000;
        for (let i = 0; i < dataset?.length; i++)
            if (dataset[i][0] === "OUTPUTID") {
                outputrow = i;
            }
        return outputrow;
    }

    static getOldNameByRow(olddata, rowindex) {
        if (olddata)
            for (var i = 0; i < olddata.length; i++) {
                if (olddata[i].address?.row == rowindex && olddata[i].address?.col == 0) {
                    return olddata[i].oldValue;
                }
            }
        return null;
    }

    static getParameterIndex(commentrow: string[], name: string): number {
        for (let i = 0; i < commentrow.length; i++) {
            if (commentrow[i] === name) {
                return i;
            }
        }
        return -1;
    }

    static cellStartsWithCellReference(cell: string): boolean {
        if (!cell || typeof cell !== 'string') return false;

        // Regular expression to match cell references like A1, $A$1, $A1, A$1, etc.
        // First character must be optional $ followed by letter(s) followed by optional $ followed by number(s)
        const cellReferencePattern = /^\$?[A-Za-z]+\$?[0-9]+/;

        return cellReferencePattern.test(cell);
    }
}
