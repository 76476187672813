exports.examples = {
    root: [
        {
            id: "buildings",
            name: 'Buildings',
            children: [
                { id: "house", name: 'House' },
                { id: "commercial", name: 'Commercial' },
                { id: "industrial", name: 'Industrial' }
            ]
        },


        {
            id: "science",
            name: 'Science',


        },
        { id: "other", name: 'Other' },

    ]
};