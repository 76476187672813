<div class="dialog-container p-4">
    <h2 class="text-center mb-4">Save Configuration</h2>
    <div class="card border-0">
        <div class="card-body">
            <form #configForm="ngForm" (ngSubmit)="save()">
                <div class="row g-3">
                    <div class="col-md-6">
                        <div class="form-group mb-3" *ngIf="project.userformname">
                            <label for="name" class="form-label">Name</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="bi bi-person"></i></span>
                                <input type="text" id="name" name="name" class="form-control" [(ngModel)]="name" placeholder="Enter your name" required #nameInput="ngModel">
                            </div>
                            <div *ngIf="nameInput.invalid && (nameInput.dirty || nameInput.touched)" class="text-danger mt-1">
                                <small *ngIf="nameInput.errors?.['required']">Name is required</small>
                            </div>
                        </div>

                        <div class="form-group mb-3" *ngIf="project.userformmail">
                            <label for="mail" class="form-label">Email</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                                <input type="email" id="mail" name="mail" class="form-control" [(ngModel)]="mail" placeholder="Enter your email" required email #mailInput="ngModel">
                            </div>
                            <div *ngIf="mailInput.invalid && (mailInput.dirty || mailInput.touched)" class="text-danger mt-1">
                                <small *ngIf="mailInput.errors?.['required']">Email is required</small>
                                <small *ngIf="mailInput.errors?.['email']">Please enter a valid email address</small>
                            </div>
                        </div>

                        <div class="form-group mb-3" *ngIf="project.userformphone">
                            <label for="phone" class="form-label">Phone</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="bi bi-telephone"></i></span>
                                <input type="text" id="phone" name="phone" class="form-control" [(ngModel)]="phone" placeholder="Enter your phone number" required #phoneInput="ngModel">
                            </div>
                            <div *ngIf="phoneInput.invalid && (phoneInput.dirty || phoneInput.touched)" class="text-danger mt-1">
                                <small *ngIf="phoneInput.errors?.['required']">Phone is required</small>
                            </div>
                        </div>

                        <div class="form-group mb-3" *ngIf="project.userformcompany">
                            <label for="company" class="form-label">Company</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="bi bi-building"></i></span>
                                <input type="text" id="company" name="company" class="form-control" [(ngModel)]="company" placeholder="Enter your company" required #companyInput="ngModel">
                            </div>
                            <div *ngIf="companyInput.invalid && (companyInput.dirty || companyInput.touched)" class="text-danger mt-1">
                                <small *ngIf="companyInput.errors?.['required']">Company is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3" *ngIf="project.userformcomment">
                            <label for="comment" class="form-label">Comment</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="bi bi-chat-left-text"></i></span>
                                <textarea class="form-control" id="comment" name="comment" rows="5" [(ngModel)]="comment" placeholder="Enter your comments" required #commentInput="ngModel"></textarea>
                            </div>
                            <div *ngIf="commentInput.invalid && (commentInput.dirty || commentInput.touched)" class="text-danger mt-1">
                                <small *ngIf="commentInput.errors?.['required']">Comment is required</small>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="my-4">

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary btn-lg" [disabled]="configForm.invalid">
                        <i class="bi bi-save me-1"></i> Save Configuration
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>