import * as THREE from "three";
import { CSG } from 'three-csg-ts';
// //import { Utils } from './utils';
//import { HyperFormula } from 'hyperformula';

import _ from 'lodash';
//import { Materials } from "./materials";
// import * as Comlink from "comlink";

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GroupedObservable } from "rxjs";
import threeSpritetext from "three-spritetext";
import { Utils } from "./utils";
import { ThreeUtils } from "./threeutils";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
// import { ThreeUtils } from "./threeutils";
// import { AngularFireStorage } from "@angular/fire/storage";
// import { AngularFirestore } from "@angular/fire/firestore";
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";


class CADNodeEngine {
    testmesh: THREE.Mesh;


    rows = [];

    spheres: any[];

    texturen = true;
    changedElements = [];

    loader: GLTFLoader;
    constructor() {
        THREE.Cache.enabled = true;
        //  console.log('cache', THREE.Cache.files)
        this.loader = new GLTFLoader();

        //     this.materials = new Materials();
    }




    // transformNodeFromRow(row: any[], commentrow: any[]) {
    //     var params = Utils.mapRowToParams(row, commentrow);
    //     var pos = params['position'].split(',');
    //     var rot = params['rotation'].split(',');

    //     var group = new THREE.Group();
    //     group.position.set(parseFloat(pos[0]), parseFloat(pos[1]), parseFloat(pos[2]));
    //     group.rotation.set(parseFloat(rot[0]), parseFloat(rot[1]), parseFloat(rot[2]));
    //     return group();

    // }


    // prop, nodename, value, object
    updateNodel(prop, nodename: string, value: any, scene: THREE.Scene) {
        //    if (nodetype == "material")
        scene.traverse((child) => {

            // node = door_lf_primary
            // visible = TRUE
            //  if (child.type === 'Mesh') {

            if (child?.name == nodename) {
                try {

                    if (value.toUpperCase() == 'TRUE')
                        value = true;
                    if (value.toUpperCase() == 'FALSE')
                        value = false;
                } catch (e) { }

                // Utils.setValue(child, prop, value);
                //    child[prop] = value;
                var props = prop.split('.');
                if (props.length > 1) {
                    if (props[0] == 'material') {
                        child['material'][props[1]].set(value);
                    } else
                        if (props[0] == 'scale') {
                            child['scale'][props[1]] = value;
                        } else {
                            child[props[0]][props[1]] = value;

                        }

                }
                else {
                    child[prop] = value;
                }

            }

            // if (child.material?.name == nodename) {
            //     child[prop].color = new THREE.Color(value);
            // }
            //   }
        });


    }

    grad2rad(grad) {
        return grad * Math.PI / 180;
    }


    diffTypeOfRow(commentRow, oldRow, newRow) {
        // Type: 0:none, 1:pos,rot,scale, 2:time, 3: recreate
        var typ = 0;

        if (commentRow)
            for (var i = 0; i < commentRow.length; i++) {

                if (oldRow[i] != newRow[i]) {
                    if (commentRow[i]) {

                        if (commentRow[i].toLowerCase() == 'x' || commentRow[i].toLowerCase() == 'y' || commentRow[i].toLowerCase() == 'z'
                            || commentRow[i].toLowerCase() == 'rx' || commentRow[i].toLowerCase() == 'ry' || commentRow[i].toLowerCase() == 'rz'
                            || commentRow[i].toLowerCase() == 'sx' || commentRow[i].toLowerCase() == 'sy' || commentRow[i].toLowerCase() == 'sz') {
                            typ = 1;
                            continue;
                        }
                        if (commentRow[i].toLowerCase() == 'time') {
                            typ = 2;
                            continue;
                        }

                        typ = 3;
                        return typ;
                    }
                }
            }

        return typ;

    }

    public calculateFaceCenters(geometry) {
        const faceCenters = [];
        const faceNormals = [];
        const position = geometry.attributes.position;

        if (geometry.type === 'ExtrudeGeometry') {
            const shapeVertices = geometry.parameters.shapes.extractPoints().shape;
            const depth = geometry.parameters.options.depth;


            // Add corner points
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);

                faceCenters.push(vA);
                faceNormals.push(null);
            }
            // Calculate centers for the shape vertices
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, 0);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);

                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add corner points for the 0.5 extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth / 2);
                faceCenters.push(vB);
                faceNormals.push(null);
            }
            // Calculate centers for the  0.5 extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth / 2);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, depth / 2);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);
                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add corner points for the extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                faceCenters.push(vB);
                faceNormals.push(null);
            }
            // Calculate centers for the extruded side
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                const vB = new THREE.Vector3(shapeVertices[(i + 1) % shapeVertices.length].x, shapeVertices[(i + 1) % shapeVertices.length].y, depth);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);
                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }
            // Calculate centers for the planes on the extrusion depth
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                const vA = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0);
                const vB = new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, depth);
                const center = new THREE.Vector3()
                    .add(vA)
                    .add(vB)
                    .divideScalar(2);
                faceCenters.push(center);

                const tangent = new THREE.Vector3().subVectors(vB, vA).normalize();
                const normal = new THREE.Vector3(tangent.y, -tangent.x, 0); // Correcting the direction of the 2D normal vector
                faceNormals.push(normal);
            }

            // Add center points of extrusion shape
            const shapeCenter = new THREE.Vector3();
            for (let i = 0; i < shapeVertices.length - 1; i++) {
                shapeCenter.add(new THREE.Vector3(shapeVertices[i].x, shapeVertices[i].y, 0));
            }
            shapeCenter.divideScalar(shapeVertices.length - 1);
            faceCenters.push(shapeCenter);
            faceNormals.push(null);

            const extrudedShapeCenter = new THREE.Vector3(shapeCenter.x, shapeCenter.y, depth / 2);
            faceCenters.push(extrudedShapeCenter);
            faceNormals.push(null);
            const extrudedShapeCenter2 = new THREE.Vector3(shapeCenter.x, shapeCenter.y, depth);
            faceCenters.push(extrudedShapeCenter2);
            faceNormals.push(null);





        }
        if (geometry.type === 'BoxGeometry') {
            const halfWidth = geometry.parameters.width / 2;
            const halfHeight = geometry.parameters.height / 2;
            const halfDepth = geometry.parameters.depth / 2;

            // Define the 6 face centers of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, 0, 0),   // +X face
                new THREE.Vector3(-halfWidth, 0, 0),  // -X face
                new THREE.Vector3(0, halfHeight, 0),  // +Y face
                new THREE.Vector3(0, -halfHeight, 0), // -Y face
                new THREE.Vector3(0, 0, halfDepth),   // +Z face
                new THREE.Vector3(0, 0, -halfDepth)   // -Z face
            );

            // Define the 8 corner points of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, halfHeight, halfDepth),
                new THREE.Vector3(halfWidth, halfHeight, -halfDepth),
                new THREE.Vector3(halfWidth, -halfHeight, halfDepth),
                new THREE.Vector3(halfWidth, -halfHeight, -halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, -halfDepth),
                new THREE.Vector3(-halfWidth, -halfHeight, halfDepth),
                new THREE.Vector3(-halfWidth, -halfHeight, -halfDepth)
            );

            // Define the 12 middle points on the edges of a box
            faceCenters.push(
                new THREE.Vector3(halfWidth, halfHeight, 0),
                new THREE.Vector3(halfWidth, -halfHeight, 0),
                new THREE.Vector3(halfWidth, 0, halfDepth),
                new THREE.Vector3(halfWidth, 0, -halfDepth),
                new THREE.Vector3(-halfWidth, halfHeight, 0),
                new THREE.Vector3(-halfWidth, -halfHeight, 0),
                new THREE.Vector3(-halfWidth, 0, halfDepth),
                new THREE.Vector3(-halfWidth, 0, -halfDepth),
                new THREE.Vector3(0, halfHeight, halfDepth),
                new THREE.Vector3(0, halfHeight, -halfDepth),
                new THREE.Vector3(0, -halfHeight, halfDepth),
                new THREE.Vector3(0, -halfHeight, -halfDepth)
            );

            // Define the 6 face normals of a box
            faceNormals.push(
                new THREE.Vector3(1, 0, 0),   // +X normal
                new THREE.Vector3(-1, 0, 0),  // -X normal
                new THREE.Vector3(0, 1, 0),   // +Y normal
                new THREE.Vector3(0, -1, 0),  // -Y normal
                new THREE.Vector3(0, 0, 1),   // +Z normal
                new THREE.Vector3(0, 0, -1)   // -Z normal
            );

            // Add null normals for corner and edge points
            for (let i = 0; i < 20; i++) {
                faceNormals.push(null);
            }
        }
        if (geometry.type === 'CylinderGeometry' ) {
            console.log("###ConeGeometry", geometry)
            const halfHeight = geometry.parameters.height / 2;
            const halfRadiusBottom = geometry.parameters.radiusBottom / 2;
            const halfRadiusTop = geometry.parameters.radiusTop / 2;

            // Define the center point of the cone
            const center = new THREE.Vector3(0, 0, 0);
            faceCenters.push(center);
            faceNormals.push(null);

            // Define the center points of the flat sides
            const baseCenter = new THREE.Vector3(0, -halfHeight, 0);
            const topCenter = new THREE.Vector3(0, halfHeight, 0);
            faceCenters.push(baseCenter);
            faceCenters.push(topCenter);
            faceNormals.push(new THREE.Vector3(0, -1, 0));
            faceNormals.push(new THREE.Vector3(0, 1, 0));

            // Define 4 points around the base of the cone
            const angles = [0, Math.PI / 2, Math.PI, 3 * Math.PI / 2];
            angles.forEach(angle => {
                const xBase = Math.cos(angle) * halfRadiusBottom*2;
                const zBase = Math.sin(angle) * halfRadiusBottom*2;
                const basePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                faceCenters.push(basePoint);
                faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());


                // const xBase = Math.cos(angle) * halfRadiusBottom;
                // const zBase = Math.sin(angle) * halfRadiusBottom;
                // const basePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                // faceCenters.push(basePoint);
                // faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());

                // Define points on the mantle surface
                const xTop = Math.cos(angle) * halfRadiusTop*2;
                const zTop = Math.sin(angle) * halfRadiusTop*2;
                const mantleBasePoint = new THREE.Vector3(xBase, -halfHeight, zBase);
                const mantleTopPoint = new THREE.Vector3(xTop, halfHeight, zTop);
                faceCenters.push(mantleBasePoint);
                faceCenters.push(mantleTopPoint);
                faceNormals.push(new THREE.Vector3(xBase, 0, zBase).normalize());
                faceNormals.push(new THREE.Vector3(xTop, 0, zTop).normalize());
            });
        }

        if (geometry.type === 'SphereGeometry') {
            const radius = geometry.parameters.radius;

            // Define the center point of the sphere
            const center = new THREE.Vector3(0, 0, 0);
            faceCenters.push(center);
            faceNormals.push(new THREE.Vector3(0, 0, 1)); // Normal pointing outwards

            // Define points on each side of the sphere
            const directions = [
                new THREE.Vector3(1, 0, 0),  // Right
                new THREE.Vector3(-1, 0, 0), // Left
                new THREE.Vector3(0, 1, 0),  // Top
                new THREE.Vector3(0, -1, 0), // Bottom
                new THREE.Vector3(0, 0, 1),  // Front
                new THREE.Vector3(0, 0, -1)  // Back
            ];

            directions.forEach(direction => {
                const point = direction.clone().multiplyScalar(radius);
                faceCenters.push(point);
                faceNormals.push(direction.clone().normalize());
            });
        }

        
        if (geometry.type === 'LineGeometry' && false) {
            const position = geometry.attributes.position;
            const count = position.count;

            // For a line, we have two points
            if (count === 2) {
                const start = new THREE.Vector3().fromBufferAttribute(position, 0);
                const end = new THREE.Vector3().fromBufferAttribute(position, 1);

                // Calculate the center point of the line
                const center = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
                faceCenters.push(center);

                // Calculate the direction vector of the line
                const direction = new THREE.Vector3().subVectors(end, start).normalize();

                // Calculate a normal vector perpendicular to the line
                // This is arbitrary for a line in 3D space, so we choose one
                const normal = new THREE.Vector3(-direction.y, direction.x, 0).normalize();
                faceNormals.push(normal);

                // Add start and end points as face centers
                faceCenters.push(start.clone());
                faceCenters.push(end.clone());

                // Add null normals for start and end points
                faceNormals.push(null);
                faceNormals.push(null);
            }
        }

        return { faceCenters, faceNormals };

    }

    public addFaceCenters(object, outputid) {
        if (!object.geometry) return;

        // Calculate the face centers and normals
        const { faceCenters, faceNormals } = this.calculateFaceCenters(object.geometry);

        // Apply transformations to the face centers
        const worldFaceCenters = faceCenters.map(center => {
            const worldCenter = center.clone();
            //    object.localToWorld(worldCenter);
            return worldCenter;
        });

        function createNumberedSprite(index) {
            const canvas = document.createElement('canvas');
            canvas.width = 256;
            canvas.height = 256;
            const context = canvas.getContext('2d');

            // Clear the canvas
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Draw circular background
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = canvas.width / 2 - 5; // Slightly smaller than half the canvas

            // Create a radial gradient for a 3D effect
            const gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
            gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
            gradient.addColorStop(1, 'rgba(200, 200, 200, 1)');

            context.beginPath();
            context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            context.fillStyle = gradient;
            context.fill();

            // Add a border to the circle
            context.strokeStyle = 'rgba(0, 0, 0, 0.5)';
            context.lineWidth = 4;
            context.stroke();

            // Draw the number
            context.fillStyle = 'black';
            context.strokeStyle = 'rgba(255, 255, 255, 0.5)';
            context.lineWidth = 4;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.font = 'bold 160px Arial'; // Adjusted font size to fit within circle
            const text = `${index + 1}`;
            context.strokeText(text, centerX, centerY);
            context.fillText(text, centerX, centerY);

            const texture = new THREE.CanvasTexture(canvas);
            texture.needsUpdate = true;

            const spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                transparent: true,
                depthTest: false,
                depthWrite: false
            });

            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.scale.set(10, 10, 1); // Adjust scale as needed

            return sprite;
        }

        function addNumberedSprites(object, centers, outputid) {
            centers.forEach((center, index) => {
                const sprite = createNumberedSprite(index);
                sprite.position.copy(center);
                sprite.userData.index = index + 1;
                sprite.userData.outputid2 = outputid;
                sprite.userData.type = "refpoint";
                sprite.userData.origpos = center.clone();
                sprite.userData.isHelper = true;
                sprite.name = `refpoint__${object.name}__${index}`;
                sprite.renderOrder = 999999;

                object.visible = false;
                object.add(sprite);

       

            });
        }

        // Usage
        // addNumberedSprites(object, centers);
        addNumberedSprites(object, worldFaceCenters, outputid);


        // Usage
        // Assuming 'object' is your Three.js Object3D and 'centers' is an array of Vector3 positions


        // Visualize the face centers as points with text
        worldFaceCenters.forEach((center, index) => {

            return;

            // Create a canvas for the text
            const canvas = document.createElement('canvas');
            canvas.width = 512; // Increase the canvas size for better resolution
            canvas.height = 512;
            const context = canvas.getContext('2d');
            context.fillStyle = 'white';
            context.fillRect(0, 0, canvas.width, canvas.height); // Fill the background with white
            context.fillStyle = 'black';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.font = 'bold 152px Arial'; // Double the font size and make it bold
            context.fillText("" + (index + 1), canvas.width / 2, canvas.height / 2);
            const texture = new THREE.CanvasTexture(canvas);
            texture.needsUpdate = true;

            // Adjust the texture offset
            texture.offset.set(0, 0); // Adjust as needed to correct the orientation

            // Create a sphere at the position of the center with texture
            const pointGeometry = new THREE.SphereGeometry(5);
            const pointMaterial = new THREE.MeshBasicMaterial({ map: texture });
            const point = new THREE.Mesh(pointGeometry, pointMaterial);
            point.position.copy(center);
            point.userData.index = index + 1;
            point.userData.type = "refpoint";
            point.userData.origpos = center.clone();
            point.name = "refpoint__" + object.name + "__" + index;


            // Orient the text to face the camera
            point.onBeforeRender = function (renderer, scene, camera) {
                point.quaternion.copy(camera.quaternion);
                point.rotateY(-Math.PI / 2);
            };

            point.visible = false;

            object.add(point);



        });

        // Visualize the face normals as lines
        worldFaceCenters.forEach((center, index) => {
            const normal = faceNormals[index];
            if (normal) {

                const normalEnd = center.clone().add(normal.multiplyScalar(30)); // Adjust the length of the normal line

                const normalPoints = [center.x, center.y, center.z, normalEnd.x, normalEnd.y, normalEnd.z];
                const normalGeometry = new LineGeometry();
                normalGeometry.setPositions(normalPoints);
                const normalMaterial = new LineMaterial({
                    color: 0xff0000, // Red color for normals
                    linewidth: 3, // Adjust the line width as needed
                    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight) // Necessary for LineMaterial
                });
                const normalLine = new Line2(normalGeometry, normalMaterial);
                normalLine.computeLineDistances();
                normalLine.userData.index = index + 1;
                normalLine.userData.outputid = object.userData.outputid;
                normalLine.userData.type = "refnormal";
                normalLine.userData.isHelper = true;
                normalLine.userData.normal = normal;
                normalLine.visible = false;
                normalLine.name = "refnormal__" + object.name + "__" + index;
                object.add(normalLine);
                object.visible = false;
            }
        });
    }

    showFaceCenters(obj, scene) {
        scene.traverse(child => {
            if (child.userData.type === "refpoint" || child.userData.type === "refnormal") {
                child.visible = false;
            }
        });
        if (obj && obj.children) {
            obj.children.forEach(child => {
                if (child.userData.type === "refpoint" || child.userData.type === "refnormal") {
                    child.visible = true;
                }
            });
        }
    }


    prependUnderscoreToNumbers(inputString) {
        return inputString.replace(/(\d+)/g, '_$1');
    }
    removeUnderscoreBeforeNumbers(inputString) {
        return inputString.replace(/_+(\d+)/g, '$1');
    }


    

    async createOrUpdateCADNode(rowindex: number, sheetid: number, name: string, row: any[], commentrow: any[], scene: THREE.Scene, excels: any, configuration: string, loaded: any, workers: any, customerid: string, projectid: string, projectconfigmodel: any, sourcesheetid) { // TODO: excel hierhin?!

        var cadnode = null;
        var path = Utils.preNames(scene) + "^" + name;
        path = path.replace(/root\^/g, "");
        path = "root^" + path;
        path = path.replace(/\^^/g, "^");
        //console.log("###Creating cadnode", name, path, configuration, row, commentrow,)


        var object = scene.getObjectByName(path);

        var diff = 3;// recreate
        if (object) {
            var oldrow = object.userData.row;
            commentrow = object.userData.commentrow;
            var diff = this.diffTypeOfRow(commentrow, oldrow, row);
            //   console.log("diff", diff)
            // todo: je nach diff nur aktualisieren oder entfernen und neu erstellen
            if (diff > 2)
                scene.remove(object);
            else
                cadnode = object;

        }


        // parameter überschreiben, wenn config gesetzt
        var config = null;
        if (projectconfigmodel)
            if (projectconfigmodel[path])
                config = projectconfigmodel[path];
        if (config) {
            row = config.row;
            commentrow = config.commentrow;
        }


        var params = Utils.mapRowToParams(row, commentrow);

        // other sheets
        var group = null;

        if (diff > 2) {

            if (row[1].toUpperCase().startsWith('SHEET:')) {

                var sheetname = row[1].split(': ')[1];
                sheetid = await excels[configuration].getSheetId(sheetname);
                var outputRows = await excels[configuration].getConfiguration(sheetid, commentrow, row);
                group = new THREE.Group();


                var cr = null;
                for (var i = 0; i < outputRows.length; i += 1) {
                    if (outputRows[i][0] == '#') {
                        cr = outputRows[i];
                        i++
                    }
                    try {
                        cadnode = await this.createOrUpdateCADNode(rowindex, sheetid, "$" + sheetid + "#" + outputRows[i][0], outputRows[i], cr, group, excels, configuration, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid);
                        cadnode.userData.issheet = true;
                    } catch (error) {
                        console.log("Error", error)

                    }
                    // cadnode.userData.configuration = subconfigurationname;
                    // cadnode.userData.commentrow = commentrow;
                    // cadnode.userData.row = row;
                    // cadnode.userData.rowindex = rowindex;
                    // cadnode.userData.typ = outputRows[i][1];
                }
            }
            // other Projects
            // TODO: laden
            var typ = row[1].toUpperCase();
            if (row[1].toUpperCase().startsWith('PROJECT:')) { // TODO: LIBRARY: ...
                var sourcesheetid1 = sheetid;
                var subconfigurationname = row[1].split(': ')[1]; // deprecated: customerid + "_" + row[1].split(': ')[1];

                var sheetnameP = "Sheet 1";
                var sheetIdP = 0;// await excels[subconfigurationname].getSheetId(sheetnameP);
                // konfigparameter setzen, achtung bei mehrerenunterkongiruation und konfigspeicherung von nutzer
                //     console.log("subconfigurationname", subconfigurationname, excels[subconfigurationname])
                if (!excels[subconfigurationname])
                    return null;
                var outputRowsP = await excels[subconfigurationname].getConfiguration(sheetIdP, commentrow, row);

                group = new THREE.Group();
                group.name = path;// name;
                group.userData.typ = row[1];
                group.userData.subproject = subconfigurationname;
                group.userData.issubproject = true;
                scene.add(group);
                //  group.name = this.preNames(group);
                //  cadnode = await this.createCADNode(row[0], row[1], row[0], group, excels, configurationname, loaded, workers, customerid, projectid);

                // TODO achtung mit weiteren zeilen TODO: head bei mehrren rows !!
                var cr = null;
                for (var i = 0; i < outputRowsP.length; i += 1) {
                    if (outputRowsP[i][0] == '#') {
                        cr = outputRowsP[i];
                        i++
                    }
                    try {
                        cadnode = await this.createOrUpdateCADNode(rowindex, sheetIdP, "$" + sheetIdP + "#" + outputRowsP[i][0], outputRowsP[i], cr, group, excels, subconfigurationname, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid1);
                        //  cadnode = await this.createOrUpdateCADNode(rowindex, sheetid, "$" + sheetid + "#" + outputRows[i][0], outputRows[i], cr, group, excels, configuration, loaded, workers, customerid, projectid, projectconfigmodel);
                    } catch (error) {
                        console.log("Error", error)

                    }

                }

                // cadnode.userData.configuration = subconfigurationname;
                // cadnode.userData.typ = outputRowsP[i + 1][1];
                // cadnode.userData.commentrow = commentrow;
                // cadnode.userData.row = row;
                // cadnode.userData.rowindex = rowindex;




            }


            // Primitives
            switch (typ) {
                case "LINE":
                    cadnode = await this.createLine(params['p1'], params['p2'], params['width'], params['material']);
                    break;
                case "DIMENSIONLINE":
                    cadnode = await this.createDimensionLine(params['p1'], params['p2'], params['width'], params['material']);
                    break;

                case "SHAPE":
                    cadnode = await this.createShape(params['points'], params['depth'], params['material']);
                    break;
                case "SURFACE":
                    cadnode = await this.createSurface(params['points'], params['indices'], params['material']);
                    break;
                case "TEXTFLAT":
                    cadnode = await this.createText(params['textflat'], params['font'], params['fontsize'], params['color']);
                    break;
                case "EXTRUSION":
                    cadnode = await this.createExtrusion(params['baseplane'], params['extrusionvector'], params['material']);
                    break;
                case "SUBTRACTION":
                    // TODO: funktioniert noch nicht für unterprojekte
                    // hier wird nur die subtraction geändert, nicht wenn sich eines der objekte geändert hat
                    cadnode = await this.createSubtraction(params['cuttedoutputids'], params['cutoutputids'], sheetid, scene, name);
                    break;
                case "CYLINDER":
                    try {

                        cadnode = await this.createCylinder(params['radiusTop'], params['radiusBottom'], params['height'], params['material']);
                    }
                    catch (err) {
                        console.log("error", err)
                    }

                    break;
                case "SPHERE":
                    cadnode = this.createSphere(params['radius'], params['material']);
                    break;
                case "CUBE":
                    cadnode = this.createCube(params['width'], params['length'], params['height'], params['material']);
                    break;
                case "IMAGE3D":
                    cadnode = await this.createBillboard(params['width'], params['height'], params['material'], params['filename']);
                    break;
                case "LATHE":
                    cadnode = this.createLathe(params['points'], params['material']);
                    break;
                case "LIGHT":
                    cadnode = await this.createLight(params['color'], params['intensity'], params['distance'], params['decay']);
                    break;
                case "GLTF":
                    cadnode = await this.createGltf(params['filename']);
                    break;


            }

            if (!cadnode) {
                console.warn("no cadnode", name, row, commentrow, configuration);
                return;
            }

            // Kanten
            if (typ == "EXTRUSION" || typ == "CYLINDER" || typ == "SUBTRACTION" || typ == "CUBE") {

                if (!(params['edges'] == false && typeof (params['edges']) === "boolean")) {

                    var egeometry = new THREE.EdgesGeometry(cadnode.geometry, 6);
                    var linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1, transparent: false, }));
                    linestandard.name = "KANTE";
                    cadnode.add(linestandard);
                }


            }


//            cadnode.userData.outputid = path.split('#')[path.split('#').length - 1];


var fcname = path.split('#')[path.split('#').length - 1];;
            this.addFaceCenters(cadnode,  fcname);
            scene.traverse(child => { // TODO: gehjt schöner?
                if (child.userData.type === "refpoint" || child.userData.type === "refnormal") {
                    child.visible = false;
                }
            });
        }

        if (group) {
            cadnode = group;
        }
        cadnode.position.x = 0;
        cadnode.position.y = 0;
        cadnode.position.z = 0;
        cadnode.rotation.x = 0;
        cadnode.rotation.y = 0;
        cadnode.rotation.z = 0;
        cadnode.scale.x = 1;
        cadnode.scale.y = 1;
        cadnode.scale.z = 1;


      

        if (params['refrot'] != null) {   // params von cube
            try {
                const [refoutputid, indexStr] = params['refrot'].split('.');
                const index = parseInt(indexStr);
                const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                const refnormalob = refobj?.children?.find(child => child.userData.index === index && child.userData.type === "refnormal" && child.userData.isHelper);

                if (refnormalob) {
                    const refnormal = refnormalob.userData.normal.clone();
                    console.log("refnormal", refnormal);
                    cadnode.updateMatrixWorld(true);

                    const normalMatrix = new THREE.Matrix4();
                    normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
                    var q = new THREE.Quaternion();

                    q.setFromRotationMatrix(normalMatrix);
                    const xAxis = new THREE.Vector3(1, 0, 0);
                    const qRotation = new THREE.Quaternion();
                    qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
                    q.multiply(qRotation);

                   // cadnode.quaternion.copy(q);
                    //  refobj.updateMatrixWorld(true);
                    //  refobj.updateMatrix();      
                    cadnode.quaternion.copy(refobj.quaternion.clone());
                    cadnode.quaternion.multiply(q);

                    const rotationQuat = new THREE.Quaternion();
                    if (params['rx'] != null) {
                        const rxQuat = new THREE.Quaternion();
                        rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(params['rx'])));
                        rotationQuat.multiply(rxQuat);
                    }
                    if (params['ry'] != null) {
                        const ryQuat = new THREE.Quaternion();
                        ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(params['ry'])));
                        rotationQuat.multiply(ryQuat);
                    }
                    if (params['rz'] != null) {
                        const rzQuat = new THREE.Quaternion();
                        rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(params['rz'])));
                        rotationQuat.multiply(rzQuat);
                    }
                    cadnode.quaternion.multiply(rotationQuat);

                    cadnode.updateMatrix();
                    refnormalob.userData.refnormalobj = cadnode;
                }

            } catch (error) {
                console.warn("refrot error", error);
            }
        }


              // refpoints
        // parent object durchiterieren und beziehungen entsprechend udpaten (nur x,y,z,rx,ry,rz,sx,sy,sz)
        if (params['refpos']) {
            try {
                const [refoutputid, indexStr] = params['refpos'].split('.');
                const index = parseInt(indexStr);
                const refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                const refposob = refobj.children.find(child => child.userData.index === index && child.userData.isHelper);
                const refposobneu = cadnode.children.find(child => child.userData.index === index && child.userData.isHelper);
                if (refposob) {
                    const worldpos = refposob.getWorldPosition(new THREE.Vector3());
                    cadnode.position.copy(worldpos);

                    //  cadnode.userData.refposobj = refobj;
                    //  cadnode.userData.refposobjid = refobj.uuid;
                    refposob.userData.refposobj = cadnode;

                }
            } catch (error) {
                console.warn("refpos error", error);
            }
        }
        // cadnode.updateMatrix();
        if (params['x'] != null) {
            let xDirection = new THREE.Vector3(parseFloat(params['x']), 0, 0); // Local X
            xDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(xDirection);
        }
        if (params['y'] != null) {
            let yDirection = new THREE.Vector3(0, parseFloat(params['y']), 0); // Local Y
            yDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(yDirection);
        }
        if (params['z'] != null) {
            let zDirection = new THREE.Vector3(0, 0, parseFloat(params['z'])); // Local Z
            zDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(zDirection);
        }

        if (params['rx'] != null && params['refrot'] == null)
            cadnode.rotation.x = this.grad2rad(parseFloat(params['rx']));
        if (params['ry'] != null && params['refrot'] == null)
            cadnode.rotation.y = this.grad2rad(parseFloat(params['ry']));
        if (params['rz'] != null && params['refrot'] == null)
            cadnode.rotation.z = this.grad2rad(parseFloat(params['rz']));

        if (params['origin']) {
            const origin = params['origin'].split(',');
            if (origin.length == 3) {
                let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                direction.applyQuaternion(cadnode.quaternion);
                const rotx = params['rx'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rx'])) : 0;
                const roty = params['ry'] != null ? THREE.MathUtils.degToRad(parseFloat(params['ry'])) : 0;
                const rotz = params['rz'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rz'])) : 0;
                const rotationMatrix = new THREE.Matrix4();
                rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                    THREE.MathUtils.degToRad(rotx),
                    THREE.MathUtils.degToRad(roty),
                    THREE.MathUtils.degToRad(rotz)
                ));
             //   direction.applyMatrix4(rotationMatrix);
                cadnode.position.add(direction);
            }
            // sonst pos von reobj
            else {
                const [refoutputid, indexStr] = params['origin'].split('.');
                const index = parseInt(indexStr);
                let refobj = ThreeUtils.getObjectByUserDataProp(scene, "outputid", refoutputid);
                if (!refobj)
                    refobj = cadnode
                const refposob = refobj.children.find(child => child.userData.index === index && child.userData.isHelper);

                if (refposob) {
                    let direction = refposob.position.clone(); // beim 2. durchlauf hier nicht mehr oginal position, weil bereits verschoben // new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                    // if (!refposob.userData.origpos) {
                    //     refposob.userData.origpos = refposob.position.clone();
                    // } else {
                    direction = refposob.userData.origpos.clone();
                    // }
                    direction.applyQuaternion(cadnode.quaternion);


                    const rotx = params['rx'] != null ? parseFloat(params['rx']) : 0;
                    const roty = params['ry'] != null ? parseFloat(params['ry']) : 0;
                    const rotz = params['rz'] != null ? parseFloat(params['rz']) : 0;
                    const rotationMatrix = new THREE.Matrix4();
                    rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                        THREE.MathUtils.degToRad(rotx),
                        THREE.MathUtils.degToRad(roty),
                        THREE.MathUtils.degToRad(rotz)
                    ));
              //      direction.applyMatrix4(rotationMatrix);



                    // todo, rot dazu

                    //cadnode.position.sub(refposob.position);
                    cadnode.position.sub(direction);
                }
            }

        }

        if (params['sx'] != null)
            cadnode.scale.x = parseFloat(params['sx']);
        if (params['sy'] != null)
            cadnode.scale.y = parseFloat(params['sy']);
        if (params['sz'] != null)
            cadnode.scale.z = parseFloat(params['sz']);

        if (isNaN(cadnode.position.x)) cadnode.position.x = 0;
        if (isNaN(cadnode.position.y)) cadnode.position.y = 0;
        if (isNaN(cadnode.position.z)) cadnode.position.z = 0;
        if (isNaN(cadnode.rotation.x)) cadnode.rotation.x = 0;
        if (isNaN(cadnode.rotation.y)) cadnode.rotation.y = 0;
        if (isNaN(cadnode.rotation.z)) cadnode.rotation.z = 0;
        if (isNaN(cadnode.scale.x)) cadnode.scale.x = 1;
        if (isNaN(cadnode.scale.y)) cadnode.scale.y = 1;
        if (isNaN(cadnode.scale.z)) cadnode.scale.z = 1;



        cadnode.visible = true;
        if (params['visible'] != null && params['visible'] != undefined) {
            if (params['visible'] == 0)
                cadnode.visible = false;
            else
                cadnode.visible = (params['visible']);
        }

        // gltf nodes
        // createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel);
        for (var i = 0; i < commentrow?.length; i++) {
            try {
                if (commentrow[i]?.startsWith("node")) { // == "nodematerial") {
                    var objectname = path;
                    if (objectname) {
                        var object1 = cadnode;// scene.getObjectByName(objectname);
                        var nodename = commentrow[i].split('=')[1];

                        var statement = row[i];
                        var prop = statement.split('=')[0];
                        var value = statement.split('=')[1];
                        this.updateNodel(prop, nodename, value, object1);
                    }
                }

            } catch (error) {

            }
        }


        //  tnode.add(cadnode);
        cadnode.updateMatrix();
        cadnode.name = path;
        cadnode.userData.configuration = configuration;
        cadnode.userData.typ = row[1];
        cadnode.userData.commentrow = commentrow;
        cadnode.userData.row = JSON.parse(JSON.stringify(row));
        cadnode.userData.rowindex = rowindex;
        cadnode.userData.sheetid = sheetid;
        cadnode.userData.sourcesheetid = sourcesheetid;
        cadnode.userData.outputid = path.split('#').pop();
        cadnode.userData.rx = params['rx'];
        cadnode.userData.ry = params['ry'];
        cadnode.userData.rz = params['rz'];


        // Calculate the bounding box of the cadnode
        const boundingBox = new THREE.Box3().setFromObject(cadnode);
        console.log('Bounding Box:', boundingBox);
        cadnode.userData.boundingbox = boundingBox;
        try {
            // var exc = excels[configuration];
            // if (object) {
            //     const changes1 = await excels[configuration].changeNamedExpression(row[0] + ".width", sheetid);
            //     const changes2 = await excels[configuration].changeNamedExpression(row[0] + ".height", sheetid);
            //     const changes3 = await excels[configuration].changeNamedExpression(row[0] + ".depth", sheetid);
            // } else {
            //     var na = this.prependUnderscoreToNumbers(row[0]) + ".width";
            //     var val = (boundingBox.max.x - boundingBox.min.x).toString();
            //     var changes4 = await exc.addNamedExpression(na, val);
            //     //   var changes5 = await excels[configuration].addNamedExpression(row[0] + "height", boundingBox.max.y - boundingBox.min.y, sheetid);
            //     //   var changes6 = await excels[configuration].addNamedExpression(row[0] + ".depth", (boundingBox.max.z - boundingBox.min.z).toString(), sheetid);
            // }
        } catch (error) {
            console.warn("error", error);
        }

        if (!object)
            cadnode.userData.cadstate = "created";
        else
            cadnode.userData.cadstate = "updated";


        // refpos
        cadnode.updateMatrixWorld(true);

        cadnode.children.forEach(child => this.updateChild(child, cadnode));
        cadnode.children.forEach(child => this.updateChild(child, cadnode));
        cadnode.children.forEach(child => this.updateChild(child, cadnode));
        cadnode.children.forEach(child => this.updateChild(child, cadnode));
        cadnode.children.forEach(child => this.updateChild(child, cadnode));

        if (diff > 2)
            scene.add(cadnode);
        cadnode.userData.path = Utils.preNames(cadnode);
        cadnode.userData.path = cadnode.userData.path.replace("root^root^", "root^")
        console.log('#### cadnode.name', cadnode.name);

        return cadnode;

        return cadnode;
        return cadnode.toJSON();

    }


    updateChild(child, cadnode) {
        child.updateMatrixWorld(true);
    
        if (child.userData.refposobj) {
            child.userData.refposobj.updateMatrixWorld(true);
            const worldPos = new THREE.Vector3();
            child.getWorldPosition(worldPos);
//           child.userData.refposobj.position.copy(worldPos);
child.userData.refposobj.position.set(0, 0, 0); //
        }
    
        if (child.userData.refnormalobj) {
            child.userData.refnormalobj.updateMatrixWorld(true);
            const refNormal = child.userData.normal.clone();
            
            const normalMatrix = new THREE.Matrix4().lookAt(
                new THREE.Vector3(0, 0, 0),
                refNormal,
                new THREE.Vector3(0, 0, -1)
            );
            
            const baseQuaternion = new THREE.Quaternion().setFromRotationMatrix(normalMatrix);
            const xRotation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);
            
            const finalQuaternion = new THREE.Quaternion().multiplyQuaternions(
                cadnode.quaternion,
                baseQuaternion.multiply(xRotation)
            );
    
            child.userData.refnormalobj.quaternion.copy(finalQuaternion);
    
            // Apply additional rotations (rx, ry, rz)
            this.applyAdditionalRotations(child.userData.refnormalobj);
        }

        if (child.userData.refposobj) {
            const refPos = new THREE.Vector3();
            child.userData.refposobj.getWorldPosition(refPos);
            cadnode.position.copy(refPos);
        }

        if (child.userData.origin) {
            const originParams = child.userData.origin.split(',');
            if (originParams.length === 3) {
                const originVector = new THREE.Vector3(
                    parseFloat(originParams[0]),
                    parseFloat(originParams[1]),
                    parseFloat(originParams[2])
                );
                cadnode.position.add(originVector);
            }
        }
    
        if (child.userData.refposobj) {
            this.applyLocalTransforms(child.userData.refposobj, child.parent);
            child.userData.refposobj.children.forEach(grandChild => this.updateChild(grandChild, cadnode));
        }
    }
    
    applyAdditionalRotations(obj) {
        const rotationOrder = ['rx', 'ry', 'rz'];
        const rotationAxes = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];
    
        rotationOrder.forEach((rot, index) => {
            if (obj.userData[rot] != null) {
                const angle = THREE.MathUtils.degToRad(parseFloat(obj.userData[rot]));
                this.rotateAroundPoint(obj, new THREE.Vector3(), rotationAxes[index], angle);
            }
        });
    
        obj.updateMatrix();
    }
    
    applyLocalTransforms(obj, subscene) {
        const params = Utils.mapRowToParams(obj.userData.row, obj.userData.commentrow);
        const localDirections = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];
    
        ['x', 'y', 'z'].forEach((axis, index) => {
            if (params[axis] != null) {
                const direction = localDirections[index].clone().applyQuaternion(obj.quaternion);
                obj.position.add(direction.multiplyScalar(parseFloat(params[axis])));
            }
        });
    
        if (params['origin']) {
            this.applyOrigin(obj, params, subscene);
        }
    }
    
    applyOrigin(obj, params, subscene) {
        const origin = params['origin'].split(',');
        if (origin.length === 3) {
            const originPoint = new THREE.Vector3(
                parseFloat(origin[0]),
                parseFloat(origin[1]),
                parseFloat(origin[2])
            );
            obj.position.sub(originPoint);
            this.applyRotationsAroundOrigin(obj, originPoint, params);
            obj.position.add(originPoint);
        } else {
            const [refOutputId, indexStr] = params['origin'].split('.');
            const index = parseInt(indexStr);
            const refObj = ThreeUtils.getObjectByUserDataProp(subscene.parent, "outputid", refOutputId) || obj;
            const refPosObj = refObj.children.find(child => child.userData.index === index);
    
            if (refPosObj && refPosObj.userData.origPos) {
                const originPoint = refPosObj.userData.origPos.clone();
                obj.position.sub(originPoint);
                this.applyRotationsAroundOrigin(obj, originPoint, params);
                obj.position.add(originPoint);
            }
        }
    }
    
    applyRotationsAroundOrigin(obj, originPoint, params) {
        const rotationOrder = ['rx', 'ry', 'rz'];
        const rotationAxes = [
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1)
        ];
    
        rotationOrder.forEach((rot, index) => {
            if (params[rot] != null) {
                const angle = THREE.MathUtils.degToRad(parseFloat(params[rot]));
                this.rotateAroundPoint(obj, originPoint, rotationAxes[index], angle);
            }
        });
    }
    
    rotateAroundPoint(obj, point, axis, angle) {
        obj.position.sub(point);
        obj.position.applyAxisAngle(axis, angle);
        obj.position.add(point);
        obj.rotateOnAxis(axis, angle);
    }
    updateChild2(child, cadnode) {

        if (child.userData.refposobj) {
            child.userData.refposobj.updateMatrixWorld(true);
            const worldpos = child.getWorldPosition(new THREE.Vector3());
            child.userData.refposobj.position.copy(worldpos);
        }

        let quat = new THREE.Quaternion();
        if (child.userData.refnormalobj) {
             child.updateMatrixWorld(true);
             child.updateMatrix();
             child.userData.refnormalobj.updateMatrixWorld(true);
             const refnormal = child.userData.normal;
            const normalMatrix = new THREE.Matrix4();
            normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
            child.userData.refnormalobj.quaternion.setFromRotationMatrix(normalMatrix);
            quat = child.userData.refnormalobj.quaternion.clone();
            const xAxis = new THREE.Vector3(1, 0, 0);
            const qRotation = new THREE.Quaternion();
            qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
            quat.multiply(qRotation);

            child.userData.refnormalobj.quaternion.copy(cadnode.quaternion);
            child.userData.refnormalobj.quaternion.multiply(quat);
        }

            const rotationQuat = new THREE.Quaternion();
            if (child.userData.refposobj?.userData?.rx) {
                const rxQuat = new THREE.Quaternion();
                rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.rx)));
                rotationQuat.multiply(rxQuat);
            }
            if (child.userData.refposobj?.userData?.ry) {
                const ryQuat = new THREE.Quaternion();
                ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.ry)));
                rotationQuat.multiply(ryQuat);
            }
            if (child.userData.refposobj?.userData?.rz) {
                const rzQuat = new THREE.Quaternion();
                rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(child.userData.refposobj.userData.rz)));
                rotationQuat.multiply(rzQuat);
            }
            
            child.userData.refposobj?.quaternion.multiply(rotationQuat);
            child.userData.refposobj?.updateMatrix();

            //     const refnormal = child.userData.refnormalobj.userData.normal.clone();
            //     console.log("refnormal", refnormal);
            //     cadnode.updateMatrixWorld(true);

            //     const normalMatrix = new THREE.Matrix4();
            //     normalMatrix.lookAt(new THREE.Vector3(0, 0, 0), refnormal, new THREE.Vector3(0, 0, -1));
            //     var q = new THREE.Quaternion();

            //     q.setFromRotationMatrix(normalMatrix);
            //     const xAxis = new THREE.Vector3(1, 0, 0);
            //     const qRotation = new THREE.Quaternion();
            //     qRotation.setFromAxisAngle(xAxis, Math.PI / 2);
            //     q.multiply(qRotation);

            //    // cadnode.quaternion.copy(q);
            //     //  refobj.updateMatrixWorld(true);
            //     //  refobj.updateMatrix();      
            //     cadnode.quaternion.copy(child.userData.refnormalobj.quaternion.clone());
            //     cadnode.quaternion.multiply(q);

            //     const rotationQuat = new THREE.Quaternion();
            //     if (params['rx'] != null) {
            //         const rxQuat = new THREE.Quaternion();
            //         rxQuat.setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(parseFloat(params['rx'])));
            //         rotationQuat.multiply(rxQuat);
            //     }
            //     if (params['ry'] != null) {
            //         const ryQuat = new THREE.Quaternion();
            //         ryQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(parseFloat(params['ry'])));
            //         rotationQuat.multiply(ryQuat);
            //     }
            //     if (params['rz'] != null) {
            //         const rzQuat = new THREE.Quaternion();
            //         rzQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(parseFloat(params['rz'])));
            //         rotationQuat.multiply(rzQuat);
            //     }
            //     cadnode.quaternion.multiply(rotationQuat);

            //     cadnode.updateMatrix();
            //     refnormalob.userData.refnormalobj = cadnode;



            
        
        if (child.userData.refposobj) {

            this.applylocaltrafos2(child.userData.refposobj, child.parent);
            child.userData.refposobj.children.forEach(grandChild => this.updateChild(grandChild, cadnode)); // TODO: ggf mehrfach aufrufen?
        }
    }
    
    applylocaltrafos2(obj, subscene) {
        var cadnode = obj;
        // get params from rows

        var params = Utils.mapRowToParams(obj.userData.row, obj.userData.commentrow);


        if (params['x'] != null) {
            let xDirection = new THREE.Vector3(parseFloat(params['x']), 0, 0); // Local X
            xDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(xDirection);
        }
        if (params['y'] != null) {
            let yDirection = new THREE.Vector3(0, parseFloat(params['y']), 0); // Local Y
            yDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(yDirection);
        }
        if (params['z'] != null) {
            let zDirection = new THREE.Vector3(0, 0, parseFloat(params['z'])); // Local Z
            zDirection.applyQuaternion(cadnode.quaternion);
            cadnode.position.add(zDirection);
        }


        // if (params['origin']) {
        //     const origin = params['origin'].split(',');
        //     if (origin.length == 3) {
        //         let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
        //         direction.applyQuaternion(cadnode.quaternion);
        //         cadnode.position.add(direction);
        //     }
        //     // sonst pos von reobj
        //     else {
        //         const [refoutputid, indexStr] = params['origin'].split('.');
        //         const index = parseInt(indexStr);
        //         let refobj = ThreeUtils.getObjectByUserDataProp(subscene, "outputid", refoutputid);
        //         if (!refobj)
        //             refobj = cadnode
        //         const refposob = refobj.children.find(child => child.userData.index === index);
        //         if (refposob)
        //             cadnode.position.sub(refposob.position);
        //     }

        // }
        if (params['origin']) {
            const origin = params['origin'].split(',');
            if (origin.length == 3) {
                let direction = new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                direction.applyQuaternion(cadnode.quaternion);
                const rotx = params['rx'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rx'])) : 0;
                const roty = params['ry'] != null ? THREE.MathUtils.degToRad(parseFloat(params['ry'])) : 0;
                const rotz = params['rz'] != null ? THREE.MathUtils.degToRad(parseFloat(params['rz'])) : 0;
                const rotationMatrix = new THREE.Matrix4();
                rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                    THREE.MathUtils.degToRad(rotx),
                    THREE.MathUtils.degToRad(roty),
                    THREE.MathUtils.degToRad(rotz)
                ));
             //   direction.applyMatrix4(rotationMatrix);
                cadnode.position.add(direction);
            }
            // sonst pos von reobj
            else {
                const [refoutputid, indexStr] = params['origin'].split('.');
                const index = parseInt(indexStr);
                let refobj = ThreeUtils.getObjectByUserDataProp(subscene, "outputid", refoutputid);
                if (!refobj)
                    refobj = cadnode
                const refposob = refobj.children.find(child => child.userData.index === index);

                if (refposob) {
                    let direction = refposob.position.clone(); // beim 2. durchlauf hier nicht mehr oginal position, weil bereits verschoben // new THREE.Vector3(parseFloat(origin[0]), parseFloat(origin[1]), parseFloat(origin[2]));
                    // if (!refposob.userData.origpos) {
                    //     refposob.userData.origpos = refposob.position.clone();
                    // } else {
                    direction = refposob.userData.origpos.clone();
                    // }
                    direction.applyQuaternion(cadnode.quaternion);


                    const rotx = params['rx'] != null ? parseFloat(params['rx']) : 0;
                    const roty = params['ry'] != null ? parseFloat(params['ry']) : 0;
                    const rotz = params['rz'] != null ? parseFloat(params['rz']) : 0;
                    const rotationMatrix = new THREE.Matrix4();
                    rotationMatrix.makeRotationFromEuler(new THREE.Euler(
                        THREE.MathUtils.degToRad(rotx),
                        THREE.MathUtils.degToRad(roty),
                        THREE.MathUtils.degToRad(rotz)
                    ));
              //      direction.applyMatrix4(rotationMatrix);



                    // todo, rot dazu

                    //cadnode.position.sub(refposob.position);
                    cadnode.position.sub(direction);
                }
            }

        }
    }




    async createOrUpdateCADNode2(
        rowindex: number,
        sheetid: number,
        name: string,
        row: any[],
        commentrow: any[],
        scene: THREE.Scene,
        excels: any,
        configuration: string,
        loaded: any,
        workers: any,
        customerid: string,
        projectid: string,
        projectconfigmodel: any,
        sourcesheetid: any
    ) {

        // ###Creating cadnode $1#cylinder48 root^^$1#cylinder48 xfURm2W5LBVLuODVpUlJFIio8sg1_cYj0A94HiHj8r51saGRM
        // ###Creating cadnode $0#project52 root^$0#project52 xfURm2W5LBVLuODVpUlJFIio8sg1_cYj0A94HiHj8r51saGR
        // ###Creating cadnode $0#project32 root^$0#project52^$0#project32 xfURm2W5LBVLuODVpUlJFIio8sg1_twiW1k2LLZlanGifLQJJ 

        // Construct the path
        let path = "root^" + Utils.preNames(scene) + "^" + name;
        path = path.replace(/root\^/g, "").replace(/\^^/g, "^");
        path = "root^" + path;
        path = path.replace(/\^\^/g, "^");

        console.log("###Creating cadnode", name, path, configuration, row, commentrow,)
        // ###Creating cadnode $1#cylinder48 ^$1#cylinder48 xfURm2W5LBVLuODVpUlJFIio8sg1_cYj0A94HiHj8r51saGRM 
        // ###Creating cadnode $0#project52 root$0#project52 xfURm2W5LBVLuODVpUlJFIio8sg1_cYj0A94HiHj8r51saGRM
        // ###Creating cadnode $0#project32 root^xfURm2W5LBVLuODVpUlJFIio8sg1_twiW1k2LLZlanGifLQJJ^$0#project32 xfURm2W5LBVLuODVpUlJFIio8sg1_twiW1k2LLZlanGifLQJJ 
        let cadnode = null;
        const object = scene.getObjectByName(path);

        // Determine the difference type
        let diff = 3; // recreate
        if (object) {
            const oldrow = object.userData.row;
            commentrow = object.userData.commentrow;
            diff = this.diffTypeOfRow(commentrow, oldrow, row);
            if (diff > 2) scene.remove(object);
            else cadnode = object;
        }

        // Override parameters if configuration is set
        const config = projectconfigmodel?.[path];
        if (config) {
            row = config.row;
            commentrow = config.commentrow;
        }

        const params = Utils.mapRowToParams(row, commentrow);

        // Handle other sheets
        let group = null;
        if (diff > 2) {
            if (row[1].toUpperCase().startsWith('SHEET:')) {
                group = await this.handleSheet(row, commentrow, sheetid, configuration, excels, scene, rowindex, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid, path);
            } else if (row[1].toUpperCase().startsWith('PROJECT:')) {
                group = await this.handleProject(row, commentrow, sheetid, excels, scene, rowindex, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid, path);
            } else {
                cadnode = await this.handlePrimitives(row, params);
            }

            if (!cadnode && !group) {
                console.warn("No CAD node created", name, row, commentrow, configuration);
                return;
            }

            if (cadnode) {
                this.addEdgesToNode(cadnode, params, row[1].toUpperCase());
            }
        }

        if (group) {
            cadnode = group;
        }

        this.setNodeTransformations(cadnode, params);

        cadnode.visible = params['visible'] !== 0 && params['visible'] !== false;

        this.handleGltfNodes(cadnode, path, commentrow, row);

        cadnode.updateMatrix();
        cadnode.name = path;

        //before:
        // #### cadnode.name root^^$1#cylinder48
        // #### cadnode.name root^$0#sheet: Sheet 242
        // #### cadnode.name root^$0#project52^$0#project32^$0#cube32
        //now wrong:
        // #### cadnode.name root^$1#cylinder48
        // #### cadnode.name root^$0#sheet: Sheet 242
        // #### cadnode.name root^$0#cube32

        cadnode.userData = {
            configuration,
            typ: row[1],
            commentrow,
            row: JSON.parse(JSON.stringify(row)),
            rowindex,
            sheetid,
            sourcesheetid,
            cadstate: object ? "updated" : "created",
            path: Utils.preNames(cadnode).replace("root^root^", "root^")
        };
        if (!cadnode.userData.path.startsWith("root^"))
            cadnode.userData.path = "root^" + cadnode.userData.path.replace("root^", "");
        //   cadnode.name = cadnode.userData.path;

        console.log('#### cadnode.name', cadnode.name);
        if (diff > 2) scene.add(cadnode);
        return cadnode;
    }

    // Helper functions for better readability and maintainability
    async handleSheet(row, commentrow, sheetid, configuration, excels, scene, rowindex, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid, parentPath) {
        const sheetname = row[1].split(': ')[1];
        const newSheetid = await excels[configuration].getSheetId(sheetname);
        const outputRows = await excels[configuration].getConfiguration(newSheetid, commentrow, row);
        const group = new THREE.Group();
        group.name = parentPath + "^" + "$" + newSheetid + "#sheet:" + sheetname;

        let cr = null;
        for (let i = 0; i < outputRows.length; i++) {
            if (outputRows[i][0] === '#') {
                cr = outputRows[i];
                i++;
            }
            try {
                await this.createOrUpdateCADNode(rowindex, newSheetid, `$${newSheetid}#${outputRows[i][0]}`, outputRows[i], cr, group as any, excels, configuration, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid);
            } catch (error) {
                console.log("Error", error);
            }
        }
        return group;
    }

    async handleProject(row, commentrow, sheetid, excels, scene, rowindex, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid, parentPath) {
        const subconfigurationname = row[1].split(': ')[1];
        const sheetIdP = 0; // Assuming the sheet ID is 0 for project configurations

        if (!excels[subconfigurationname]) return null;

        const outputRowsP = await excels[subconfigurationname].getConfiguration(sheetIdP, commentrow, row);
        const group = new THREE.Group();
        group.name = parentPath + "^" + `$${sheetIdP}#project:${subconfigurationname}`; // Ensuring correct path naming
        group.userData = { typ: row[1], subproject: subconfigurationname };
        scene.add(group);

        let cr = null;
        for (let i = 0; i < outputRowsP.length; i++) {
            if (outputRowsP[i][0] === '#') {
                cr = outputRowsP[i];
                i++;
            }
            try {
                await this.createOrUpdateCADNode(rowindex, sheetIdP, `$${sheetIdP}#${outputRowsP[i][0]}`, outputRowsP[i], cr, group as any, excels, subconfigurationname, loaded, workers, customerid, projectid, projectconfigmodel, sourcesheetid);
            } catch (error) {
                console.log("Error", error);
            }
        }
        return group;
    }


    async handlePrimitives(row, params) {
        const typ = row[1].toUpperCase();
        try {
            switch (typ) {
                case "LINE":
                    return await this.createLine(params['p1'], params['p2'], params['width'], params['material']);

                case "DIMENSIONLINE":
                    return await this.createDimensionLine(params['p1'], params['p2'], params['width'], params['material']);
                case "SHAPE":
                    return await this.createShape(params['points'], params['depth'], params['material']);
                case "SURFACE":
                    return await this.createSurface(params['points'], params['indices'], params['material']);
                case "TEXTFLAT":
                    return await this.createText(params['textflat'], params['font'], params['fontsize'], params['color']);
                case "EXTRUSION":
                    return await this.createExtrusion(params['baseplane'], params['extrusionvector'], params['material']);
                //  case "SUBTRACTION":
                //      return await this.createSubtraction(params['cuttedoutputids'], params['cutoutputids'], rowindex, sheetid, scene, row[0]);
                case "CYLINDER":
                    return await this.createCylinder(params['radiusTop'], params['radiusBottom'], params['height'], params['material']);
                case "SPHERE":
                    return this.createSphere(params['radius'], params['material']);
                case "CUBE":
                    return this.createCube(params['width'], params['length'], params['height'], params['material']);
                case "IMAGE3D":
                    return await this.createBillboard(params['width'], params['height'], params['material'], params['filename']);
                case "LATHE":
                    return this.createLathe(params['points'], params['material']);
                case "LIGHT":
                    return await this.createLight(params['color'], params['intensity'], params['distance'], params['decay']);
                case "GLTF":
                    return await this.createGltf(params['filename']);
                default:
                    console.warn("Unknown primitive type:", typ);
                    return null;
            }
        } catch (err) {
            console.log("Error handling primitive type", typ, err);
            return null;
        }
    }

    addEdgesToNode(cadnode, params, typ) {
        if (["EXTRUSION", "CYLINDER", "SUBTRACTION", "CUBE"].includes(typ) && !(params['edges'] === false && typeof params['edges'] === "boolean")) {
            const egeometry = new THREE.EdgesGeometry(cadnode.geometry, 6);
            const linestandard = new THREE.LineSegments(egeometry, new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 1 }));
            linestandard.name = "KANTE";
            cadnode.add(linestandard);
        }
    }

    setNodeTransformations(cadnode, params) {
        cadnode.position.set(params['x'] || 0, params['y'] || 0, params['z'] || 0);
        cadnode.rotation.set(this.grad2rad(params['rx'] || 0), this.grad2rad(params['ry'] || 0), this.grad2rad(params['rz'] || 0));
        cadnode.scale.set(params['sx'] || 1, params['sy'] || 1, params['sz'] || 1);
    }

    handleGltfNodes(cadnode, path, commentrow, row) {
        for (const comment of commentrow || []) {
            if (comment.startsWith("node")) {
                const objectname = path;
                const object1 = cadnode;
                const nodename = comment.split('=')[1];
                const [prop, value] = row[commentrow.indexOf(comment)].split('=');
                this.updateNodel(prop, nodename, value, object1);
            }
        }
    }

    async createGltf(filename) {

        var mesh = null;
        // var texture = null;
        // var material = null;
        // var textureLoader = new THREE.TextureLoader();
        // var materialLoader = new THREE.MaterialLoader();




        var gltf = await (this.loader).loadAsync(filename);


        var mixer = new THREE.AnimationMixer(gltf.scene);

        gltf.animations.forEach((clip) => {
            mixer.clipAction(clip).play();
            gltf.scene.userData.mixer = mixer;
        });



        gltf.scene.traverse((child) => {
            if ((child as any).isMesh || (child as any).type == "Object3D") {
                (child as any).cursor = 'pointer';
                child.castShadow = true;
                child.receiveShadow = true;
                //   (child as any).geometry.center(); // center here
            }
            if (child?.userData) {
                child.userData.isGltf = true;
            }
        });
        mesh = gltf.scene;
        // gltf = gltf;
        // mesh = gltf.scene.children[0];
        // loader.load(filename, function (gltf) {
        // texture = textureLoader.load(gltf.textures[0].source);
        // material = materialLoader.parse(gltf.materials[0]);
        // material.map = texture;
        // material.needsUpdate = true;
        // mesh.material = material;
        // });

        //  ThreeUtils.centerGltf(gltf);


        var bb = ThreeUtils.getBoundingBox(mesh);
        const dimensions = new THREE.Vector3().subVectors(bb.max, bb.min);
        mesh.userData.bb = bb;
        mesh.userData.dimensions = dimensions;

        return mesh;
    }



    async create3DCuts() {

        console.log('changedElements', this.changedElements);


        // 1. alle geschnittenen, aktualisierten Elemente updaten (= geschnitten Update + Schnittkörper abhängikeiten)
        var elementstocut = JSON.parse(JSON.stringify(this.changedElements));
        for (var i = 0; i < this.changedElements.length; i++) {
            //this.createCuts(this.changedElements[i]);

            var element = this.changedElements[i];

            if (element.verknuepfteElemente) {
                // for (var v = 0; v < element.verknuepfteElemente.length; v++)
                //     for (var j = 0; j < this.baseScene.meshes.length; j++) {
                //         if (this.baseScene.meshes[j].name === element.verknuepfteElemente[v]) {
                //             elementstocut.push(this.baseScene.meshes[j].o);
                //         }
                //     }
            }
        }


        elementstocut = _.uniqBy(elementstocut, function (o: any) {
            return o.meshuuid; // oder doch besser path abfragen?
        });
        console.log('cutted updated without duplicates', elementstocut)

        var t0 = performance.now();


        //          var newmesh = this.cutMesh(meshtocut, mesh);

        var t1 = performance.now();
        console.log("Verschneidungen took " + (t1 - t0) + " milliseconds.");
    }




    cutMesh(meshtocut, cutmesh) {
        meshtocut.updateMatrix();
        cutmesh.updateMatrix();
        try {
            const bspA = CSG.fromMesh(cutmesh);
            const bspB = CSG.fromMesh(meshtocut);
            var intersection = bspB.intersect(bspA) as any;
            if (intersection?.polygons?.length > 0) {
                const bspResult = bspB.subtract(bspA); //.inverse();
                var meshResult = CSG.toMesh(bspResult, new THREE.Matrix4()) as any;
                meshResult.updateMatrix();
                meshResult.material = meshtocut.material;
                meshResult.receiveShadow = true;
                meshResult.castShadow = true;
                meshResult.name = meshtocut.name;
                meshResult.visible = true;
                // (meshResult as any).o = meshtocut.o;
                // (meshResult as any).o.meshuuid = meshResult.uuid;
                // (meshResult as any).o.cutted = true;

                if (!meshResult) {
                    console.log('kein schnitt')
                }
                return meshResult;

            } else {
                console.log('keine überschneidung')

            }
        }
        catch (ie) {
            //      continue;
            console.log("fehler", ie)
        }
        return null;
    }

    createSubtraction(tocutoutputids, cutoutputids, sheetid, scene, name) {
        // TODO: sheets
        var tocutoutmeshes = tocutoutputids.split(",");
        var cutmeshes = cutoutputids.split(",");
        var resultmeshes = [];

        for (var i = 0; i < tocutoutmeshes.length; i++) {
            tocutoutmeshes[i] = "root^$" + sheetid + "#" + tocutoutmeshes[i].trim();
            var meshtocut = scene.getObjectByName(tocutoutmeshes[i], true);
            if (meshtocut) {
                for (var j = 0; j < cutmeshes.length; j++) {
                    cutmeshes[j] = "root^$" + sheetid + "#" + cutmeshes[j].trim();
                    var cutmesh = scene.getObjectByName(cutmeshes[j], true);
                    if (cutmesh) {

                        var resultmesh = this.cutMesh(meshtocut, cutmesh);
                        resultmeshes.push(resultmesh);
                        // beide vorhanden => schneiden
                        meshtocut.visible = false;
                        cutmesh.visible = false;

                        if (!name.startsWith('root^')) {
                            name = "root^" + name;
                        }
                        // connection
                        meshtocut.userData.subtraction = name;
                        cutmesh.userData.subtraction = name;
                    }
                }
            }
        }
        return resultmeshes[resultmeshes.length - 1];
    }

    createSphere(radius, material) {
        const widthSegments = 12;
        const heightSegments = 8;

        var material1 = this.str2material(material);


        const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);
        const mesh = new THREE.Mesh(geometry, material1);



        return mesh;
    }
    str2material(str): THREE.Material {
        try {
            var material1 = new THREE.MeshStandardMaterial({
                color: 'gray',
                metalness: 0.5,
                roughness: 0.5,
                side: THREE.DoubleSide
            });

            var splitted = str.split(';');
            material1 = new THREE.MeshStandardMaterial({
                color: splitted[0],
                metalness: 0.5,
                roughness: 0.5,
                side: THREE.DoubleSide
            });
            if (splitted.length > 1) {
                material1.roughness = parseFloat(splitted[1]);
            }
            if (splitted.length > 2) {
                material1.metalness = parseFloat(splitted[2]);
            }
            if (splitted.length > 3) {
                //material1.reflectivity = parseFloat(splitted[3]);
                material1.transparent = true;
            }
            if (splitted.length > 4) {
                material1.opacity = parseFloat(splitted[4]);
            }
        } catch (error) {


        }
        return material1;

    }

    createCube(width, length, height, material) {
        // create box
        const geometry = new THREE.BoxGeometry(width, length, height);

        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        return mesh;
    }
    createCylinder(radiusTop, radiusBottom, height, material) {
        const radialSegments = 120;
        const geometry = new THREE.CylinderGeometry(radiusTop, radiusBottom, height, radialSegments);

        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        return mesh;
    }
    async createBillboard(width, height, material, imagePath) {
        // create sprite material
        var material1 = this.str2material(material) as any;
        material1 = new THREE.SpriteMaterial();
        //       material1.
        // Load the texture
        const loader = new THREE.TextureLoader();
        var texture = await loader.loadAsync(imagePath);
        material1.map = texture;
        //  texture.encoding = THREE.sRGBEncoding;
        //      texture.encoding = THREE.sRGBEncoding;
        texture.colorSpace = THREE.SRGBColorSpace;
        //   material1.transparent = true;
        //   material1.premultipliedAlpha = false;

        const sprite = new THREE.Sprite(material1);
        sprite.scale.set(width, height, 1);

        return sprite;
    }


    createDimensionLine(p1, p2, lineWidth, lineColor) {
        // Parse p1 and p2 from strings
        p1 = p1.replace('(', '').replace(')', '').split(',');
        p2 = p2.replace('(', '').replace(')', '').split(',');
        const x1 = parseFloat(p1[0]);
        const y1 = parseFloat(p1[1]);
        const z1 = parseFloat(p1[2]);
        const x2 = parseFloat(p2[0]);
        const y2 = parseFloat(p2[1]);
        const z2 = parseFloat(p2[2]);

        const start = new THREE.Vector3(x1, y1, z1);
        const end = new THREE.Vector3(x2, y2, z2);

        const group = new THREE.Group();

        // Create the main dimension line
        const points = [x1, y1, z1, x2, y2, z2];
        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(points);
        const lineMaterial = new LineMaterial({
            color: lineColor,
            linewidth: lineWidth,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)
        });
        const line = new Line2(lineGeometry, lineMaterial);
        line.computeLineDistances();
        group.add(line);

        // Create the triangles at the endpoints
        const triangleSize = lineWidth * 5;
        const triangleShape = new THREE.Shape();
        triangleShape.moveTo(0, 0);
        triangleShape.lineTo(triangleSize, triangleSize / 2);
        triangleShape.lineTo(triangleSize, -triangleSize / 2);
        triangleShape.lineTo(0, 0);

        const triangleGeometry = new THREE.ShapeGeometry(triangleShape);
        const triangleMaterial = new THREE.MeshBasicMaterial({ color: lineColor, side: THREE.DoubleSide });


        const startTriangle = new THREE.Mesh(triangleGeometry, triangleMaterial);
        startTriangle.position.copy(start);
        startTriangle.lookAt(end);
        startTriangle.rotateX(-Math.PI / 2);
        startTriangle.rotateY(Math.PI / 2);
        group.add(startTriangle);

        const endTriangle = new THREE.Mesh(triangleGeometry, triangleMaterial);
        endTriangle.position.copy(end);
        endTriangle.lookAt(start);
        endTriangle.rotateX(-Math.PI / 2);
        endTriangle.rotateY(Math.PI / 2);
        group.add(endTriangle);

        // Create the orthogonal boundary lines
        const boundaryLineMaterial = new THREE.LineBasicMaterial({ color: lineColor, linewidth: lineWidth });

        const startBoundaryPoints = [
            start.x, start.y, start.z,
            start.x + triangleSize * 4, start.y, start.z
        ];
        const startBoundaryGeometry = new LineGeometry();
        startBoundaryGeometry.setPositions(startBoundaryPoints);
        const startBoundaryLine = new Line2(startBoundaryGeometry, lineMaterial);
        startBoundaryLine.computeLineDistances();
        group.add(startBoundaryLine);

        const endBoundaryPoints = [
            end.x, end.y, end.z,
            end.x + triangleSize * 4, end.y, end.z
        ];
        const endBoundaryGeometry = new LineGeometry();
        endBoundaryGeometry.setPositions(endBoundaryPoints);
        const endBoundaryLine = new Line2(endBoundaryGeometry, lineMaterial);
        endBoundaryLine.computeLineDistances();
        group.add(endBoundaryLine);
        return group;
    }

    createLathe(points, material) {
        var ps = points.split(";");

        for (let i = 0; i < ps.length; i++) {
            var p = ps[i].replace('(', '').replace(')', '').split(",");
            points.push(new THREE.Vector2(p[0], p[1]));
        }
        const geometry = new THREE.LatheGeometry(ps);
        const mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: material }));
        return mesh;
    }

    ////////////////////////////////////////////////////////////
    createShape(points, depth, material) {
        const shape1 = new THREE.Shape();
        shape1.autoClose = true;
        // split coordinates (x,y,z) string into array of coordinates
        var ps = points.split(";");
        for (var i = 0; i < ps.length; i++) {
            var p = ps[i].replace('(', '').replace(')', '').split(",");
            if (i == 0)
                shape1.moveTo(parseFloat(p[0]), parseFloat(p[1]));
            else
                shape1.lineTo(parseFloat(p[0]), parseFloat(p[1]));
            //heartShape.bezierCurveTo(35, 0, 25, 25, 25, 25);
        }

        const extrudeSettings = { depth: depth, bevelEnabled: false, bevelSegments: 2, steps: 2, bevelSize: 1, bevelThickness: 1 };
        const geometry = new THREE.ExtrudeGeometry(shape1, extrudeSettings);


        var material1 = this.str2material(material);

        const mesh = new THREE.Mesh(geometry, material1);
        return mesh;
    }



    // create three.js surface
    createSurface(points, indices, material) {

        //var geometry = new THREE.BufferGeometry();
        // var material = new THREE.MeshPhongMaterial({
        //     color: 0xffff00,
        //     polygonOffset: true,
        //     polygonOffsetFactor: 1, // positive value pushes polygon further away
        //     polygonOffsetUnits: 1
        // });

        // var vertices = mesh.vertices;
        // var faces = mesh.faces;
        // var normals = mesh.faceVertexNormals;

        // for (var i = 0; i < faces.length; i++) {
        //     var face = faces[i];
        //     var normal = normals[i];
        //     var va = vertices[face.a];
        //     var vb = vertices[face.b];
        //     var vc = vertices[face.c];

        //     geometry.vertices.push(va);
        //     geometry.vertices.push(vb);
        //     geometry.vertices.push(vc);

        //     geometry.faces.push(new THREE.Face3(i * 3, i * 3 + 1, i * 3 + 2));
        //     geometry.faces[i].normal = normal;
        // }

        // geometry.computeFaceNormals();
        // geometry.computeVertexNormals();

        // var mesh = new THREE.Mesh(geometry, material);
        // mesh.material.side = THREE.DoubleSide;
        // mesh.material.depthWrite = false;

        // return mesh;
    }

    // // create three js text

    async createText(text, fonturl, fontsize, color) {
        fonturl = "assets/Roboto_Bold.json";
        const loader = new FontLoader();
        const font = await new Promise(res => new FontLoader().load(fonturl, res))

        var geometry = new TextGeometry(text, {
            font: font as any,
            size: fontsize * 50,
            height: 0,
            curveSegments: 12,
            bevelEnabled: false,
            bevelThickness: 10,
            bevelSize: 8,
            bevelOffset: 0,
            bevelSegments: 5
        });


        var material1 = this.str2material(color);


        var mesh = new THREE.Mesh(geometry, material1);

        return mesh;
    }


    // // create three.js light
    createLight(color, intensity, distance, decay) {
        var light = new THREE.PointLight(color, intensity, distance, decay);

        return light;
    }

    // // create three.js line
    createLine(p1, p2, width, material) {

        p1 = p1.replace('(', '').replace(')', '').split(',');
        p2 = p2.replace('(', '').replace(')', '').split(',');
        var x1 = parseFloat(p1[0]);
        var y1 = parseFloat(p1[1]);
        var z1 = parseFloat(p1[2]);
        var x2 = parseFloat(p2[0]);
        var y2 = parseFloat(p2[1]);
        var z2 = parseFloat(p2[2]);
        //    const points = [];
        //   points.push(new THREE.Vector3(x1, y1, z1));
        //   points.push(new THREE.Vector3(x2, y2, z2));
        const points = [x1, y1, z1, x2, y2, z2];

        // const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const geometry = new LineGeometry();
        geometry.setPositions(points);
        const material2 = new LineMaterial({
            color: material,  // Choose the color as needed
            linewidth: width,  // Adjust the line width for visibility
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
        });
        var material1 = new THREE.LineBasicMaterial({ color: material, linewidth: width });
        var line = new Line2(geometry, material2);
        line.computeLineDistances();

        return line;
    }


    // createLine() {

    //     var geometry = new THREE.BoxGeometry(100, 100, 200);

    //     // mesh
    //     var material = new THREE.MeshPhongMaterial({
    //         color: 0xff0000,
    //         polygonOffset: true,
    //         polygonOffsetFactor: 1, // positive value pushes polygon further away
    //         polygonOffsetUnits: 1
    //     });
    //     var mesh = new THREE.Mesh(geometry, material);
    //     //     this.scene.add(mesh);
    //     this.testmesh = mesh;


    //     var geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
    //     var mat = new THREE.LineBasicMaterial({ color: 0xffffff });
    //     var wireframe = new THREE.LineSegments(geo, mat);
    //     mesh.add(wireframe);
    // }

    public createExtrusion(baseplane, extrusionvector, material) {
        //   https://stackoverflow.com/questions/25639268/three-js-extrudegeometry-using-depth-and-a-direction-vector
        // var matrix = new THREE.Matrix4();
        // var dir = new THREE.Vector3(0.25, 1, 0.25); // you set this. a unit-length vector is not required.
        // var Syx = dir.x / dir.y,
        //     Syz = dir.z / dir.y;
        // matrix.set(1, Syx, 0, 0,
        //     0, 1, 0, 0,
        //     0, Syz, 1, 0,
        //     0, 0, 0, 1);
        // ((geometry.applyMatrix4(matrix);
        // var Syx = 0,
        //     Szx = 0,
        //     Sxy = 0,
        //     Szy = -Math.abs(o.extrusion.z / o.extrusion.y), // -3.75,
        //     Sxz = 0,
        //     Syz = 0;
        // var matrix = new THREE.Matrix4();
        // matrix.set(1, Syx, Szx, 0,
        //     Sxy, 1, Szy, 0, //-o.extrusion.y,
        //     Sxz, Syz, 1, 0,
        //     0, 0, 0, 1);
        // if (o.extrusion.z / o.extrusion.y > 0) {
        //     const scale = new THREE.Vector3(1, 1, -1);
        //     mesh.scale.multiply(scale);
        //     mesh.position.z = tY + this.hallenbreite / 2 - o.extrusion.y;;
        // }
        // else {
        //     mesh.position.z = tY + this.hallenbreite / 2;
        // }
        // mesh.geometry.applyMatrix4(matrix);

        var material1 = this.str2material(material);

        extrusionvector = extrusionvector.replace(/\s/g, '').replace('(', '').replace(')', '').split(',');
        var ev = { x: parseFloat(extrusionvector[0]), y: parseFloat(extrusionvector[1]), z: parseFloat(extrusionvector[2]) };

        var pointsstrs = baseplane.replace(/\s/g, '').split(";");
        var points = [];
        for (var i = 0; i < pointsstrs.length; i++) {
            var pointstr = pointsstrs[i].substring(1, pointsstrs[i].length - 1);
            var x = parseFloat(pointstr.split(",")[0]);
            var y = parseFloat(pointstr.split(",")[1]);
            points.push({ x: x, y: y });
        }

        const shape1 = new THREE.Shape();
        shape1.autoClose = true;
        shape1.moveTo(points[0].x, points[0].y);
        for (var i = 1; i < points.length; i++) {
            shape1.lineTo(points[i].x, points[i].y);
        }
        var c = new THREE.LineCurve3(
            new THREE.Vector3(0, 0, 250.001),
            new THREE.Vector3(250, 0, 1000)
        );
        var extrudeSettings = {
            steps: 1,
            bevelEnabled: false,
            depth: ev.z,
            //    extrudePath: c
        };
        const geometry = new THREE.ExtrudeGeometry(shape1, extrudeSettings);
        const mesh = new THREE.Mesh(geometry, material1);
        mesh.receiveShadow = true;
        mesh.castShadow = true;

        return mesh;
        // //      this.baseScene.add(mesh);
        // //  mesh.rotateZ(Math.PI / 2);
        // var geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
        // var mat = new THREE.LineBasicMaterial({ color: 0xffffff });
        // var wireframe = new THREE.LineSegments(geo, mat);
        // mesh.add(wireframe);

        // var extrudeSettings2 = {
        //     steps: 1,
        //     depth: 100,
        //     bevelEnabled: false,
        // };
        //   const geometry2 = new THREE.ExtrudeGeometry(shape, extrudeSettings2);
        //   const mesh2 = new THREE.Mesh(geometry2, material);
        //    //   this.baseScene.add(mesh2);
        //  var geo2 = new THREE.EdgesGeometry(mesh2.geometry); // or WireframeGeometry
        //  var wireframe = new THREE.LineSegments(geo2, mat);
        //  mesh.add(wireframe);


        // TEST mit curve und extrude path
        // https://threejs.org/docs/#api/en/extras/curves/LineCurve
        // https://threejs.org/examples/#webgl_geometry_extrude_shapes



    }



    /// deprecated ///////////////////////////////////////////////////////
    private rotateAboutWorldAxis(obj: THREE.Object3D, axis: string, rotation: number) {

        //Create a matrix
        var matrix = new THREE.Matrix4();
        // Rotate the matrix
        if (axis == "x")
            matrix.makeRotationX(rotation);
        else if (axis == "y")
            matrix.makeRotationY(rotation);
        else if (axis == "z")
            matrix.makeRotationZ(rotation);

        //rotate the object using the matrix
        obj.applyMatrix4(matrix);

    }

    public EdgesGeometry(geometry) {

        // var thresholdAngle = 1; // (thresholdAngle !== undefined) ? thresholdAngle : 1;
        // // buffer
        var vertices = [];
        // // helper variables
        var thresholdDot = Math.cos(THREE.MathUtils.DEG2RAD * 1);
        var thresholdDot2 = Math.cos(THREE.MathUtils.DEG2RAD * 1111);
        var edge = [0, 0], edges = {}, edge1, edge2;
        var key, keys = ['a', 'b', 'c'];
        // prepare source geometry
        var geometry2;
        if (geometry.isBufferGeometry) {
            geometry2 = new THREE.BufferGeometry();
            //     geometry2.fromBufferGeometry(geometry);
        } else {
            geometry2 = geometry.clone();
        }
        geometry2.mergeVertices();
        geometry2.computeFaceNormals();
        var sourceVertices = geometry2.vertices;
        var faces = geometry2.faces;
        // now create a data structure where each entry represents an edge with its adjoining faces
        for (var i = 0, l = faces.length; i < l; i++) {
            var face = faces[i];
            for (var j = 0; j < 3; j++) {
                edge1 = face[keys[j]];
                edge2 = face[keys[(j + 1) % 3]];
                edge[0] = Math.min(edge1, edge2);
                edge[1] = Math.max(edge1, edge2);
                key = edge[0] + ',' + edge[1];
                if (edges[key] === undefined) {
                    edges[key] = { index1: edge[0], index2: edge[1], face1: i, face2: undefined };
                } else {
                    edges[key].face2 = i;
                }
            }
        }

        // generate vertices
        for (key in edges) {
            var e = edges[key];
            // an edge is only rendered if the angle (in degrees) between the face normals of the adjoining faces exceeds this value. default = 1 degree.
            if (
                e.face2 === undefined ||
                (faces[e.face1].normal.dot(faces[e.face2].normal) <= thresholdDot)) {//} && faces[e.face1].normal.dot(faces[e.face2].normal) >= thresholdDot2)) {

                if (e.face2 !== undefined) {
                    var vertex = sourceVertices[e.index1];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                    vertex = sourceVertices[e.index2];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                }

            }
        }

        var g = new THREE.BufferGeometry() as any; /// TODO: funzt?
        g.type = "EdgesGeometry";
        // build geometry
        var b = new THREE.Float32BufferAttribute(vertices, 3);
        g.setAttribute('position', b);

        return g;

    }


}


export { CADNodeEngine }