import {
  Component,
  OnInit,
  ViewChild,
  Injectable,
  Inject
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { map } from "rxjs/operators";
import { Observable, of as observableOf, merge } from "rxjs";
// import { LogdetailComponent } from "../../_dialogs/logdetail/logdetail.component";


@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<LogtableItem>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    "datetime",
    "message",
    //  "meta",
    "source",
    //    "level",
    //   "functions"
  ];

  public options: any;

  entries: any;

  @ViewChild(MatPaginator, { static: true })
  paginator2: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort2: MatSort;

  filter2: string;
  //  dataSource: InfotableDataSource;
  dataSource2: MatTableDataSource<LogtableItem>;
  displayedColumns2 = [
    "datetime",
    "message",
    //  "meta",
    "source",
    //    "level",
    //   "functions"
  ];




  @ViewChild(MatPaginator, { static: true })
  paginator3: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort3: MatSort;

  filter3: string;
  //  dataSource: InfotableDataSource;
  dataSource3: MatTableDataSource<any>;
  displayedColumns3 = [
    "datetime",
    "message",
    //  "meta",
    "source",
    //    "level",
    //   "functions"
  ];

  constructor(public afs: AngularFirestore, public dialog: MatDialog) {
    this.dataSource = new InfotableDataSource();
    this.dataSource.data = [];
    this.options = "option";

    this.dataSource2 = new ActiontableDataSource();
    this.dataSource2.data = [];

    this.dataSource3 = new MatTableDataSource<any>();
    this.dataSource3.data = [];

  }



  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs.collection("logs", r => r.orderBy("datetime", "desc").limit(1000)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;

        return { id, ...data as {} };
      });
    })
    )
      .subscribe(x => {
        this.dataSource.data = <any>x;
        // this.dataSource.filterPredicate = (data: LogtableItem, filter: string) => {
        //   filter = filter.trim(); // Remove whitespace
        //   filter = filter.toLowerCase();
        //   return data.message.includes(filter);
        // };

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

    this.afs.collection("useractions", r => r.orderBy("datetimets", "desc").limit(1000)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as {} };
      });
    })
    )
      .subscribe(x => {
        this.dataSource2.data = <any>x;
        this.dataSource2.filter = this.filter2;
        this.dataSource2.paginator = this.paginator2;
        this.dataSource2.sort = this.sort;
      });


    this.afs.collection("errors", r => r.orderBy("datetime", "desc").limit(1000)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as {} };
      });
    })
    )
      .subscribe(x => {
        this.dataSource3.data = <any>x;
        this.dataSource3.filter = this.filter3;
        this.dataSource3.paginator = this.paginator3;
        this.dataSource3.sort = this.sort;
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteLog(id) {
    console.log("delete " + id);
    this.afs.doc("/logs/" + id).delete();
  }

  applyFilter2(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }

  applyFilter3(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource3.filter = filterValue;
  }
}

// TODO: Replace this with your own data model type
export interface LogtableItem {
  datetime: any;
  message: string;
  source: string;
  level: string;
  meta: any;
}

export class InfotableDataSource extends MatTableDataSource<LogtableItem> {
  constructor() {
    super();
  }

  disconnect() { }
}




export interface ActiontableItem {
  datetime: any;
  message: string;
  source: string;
  level: string;
  meta: any;
}

export class ActiontableDataSource extends MatTableDataSource<any> {
  constructor() {
    super();
  }

  disconnect() { }
}