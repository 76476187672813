import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, Component, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogsubprojectComponent } from '../dialogsubproject/dialogsubproject.component';

@Component({
  selector: 'app-dialogsaveconfig',
  templateUrl: './dialogsaveconfig.component.html',
  styleUrls: ['./dialogsaveconfig.component.scss']
})
export class DialogsaveconfigComponent implements OnInit {

  name: string;
  mail: string;
  phone: string;
  address: string;
  city: string;
  comment: string;
  state: string;
  zip: string;
  country: string;

  company: string;
  project: any;

  constructor(private cdr: ChangeDetectorRef,


    public dialogRef: MatDialogRef<DialogsubprojectComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient, private rtdb: AngularFireDatabase, public auth: AngularFireAuth, public afs: AngularFirestore) {
    {
      console.log(data)
      this.project = data.project;
      data.projectid;// TODO: einstellungen von projectid holen (aus data)
    }





  }
  ngOnInit(): void {


  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    var p = {
      name: this.name,
      mail: this.mail,
      phone: this.phone,
      address: this.address,

      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
      company: this.company,
      comment: this.comment,
      savetime: new Date().getTime()
    }
    this.dialogRef.close(p);
  }
}