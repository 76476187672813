<!-- <h1 mat-dialog-title>Files</h1> -->
<!-- <div mat-dialog-content> -->

<div style="width:100%;height:100%;background-color:white;z-index:99999;">
    <app-files (fileSelected)="fileselected($event)" [filter]="filter"></app-files>
</div>

<!-- </div> -->
<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button cdkFocusInitial>Ok</button>
</div> -->