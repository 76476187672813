<div class="container" fxLayout="row" fxLayoutAlign="center center" style="height:80vh;" fxLayoutGap="10px" fxLayoutGap.xs="0">

  <div style="height:100px;"></div>

  <div class="container" fxLa2yout="row" f2xLayoutAlign="center center" style="height:60vh;" fx2LayoutGap="0px" fxLayou2tGap.xs="0">

    <div style="text-align:center;">

      <img src="/assets/logo-site-head1.png" />
    </div>
    <div style="clear:both;height:100px;"></div>

    <!-- <ngx-auth-firebaseui [guestEnabled]="false" [providers]="['google']" -->
    <div style="width:400px;margin:auto">

      <ngx-auth-firebaseui (onSuccess)="printUser($event)" [signInTabText]="'ngxauthfirebaseui.signInTabText' | translate" [signInCardTitleText]="'ngxauthfirebaseui.signInCardTitleText' | translate"
      [loginButtonText]="'ngxauthfirebaseui.loginButtonText' | translate" [forgotPasswordButtonText]="'ngxauthfirebaseui.forgotPasswordButtonText' | translate"
        [nameText]="'ngxauthfirebaseui.nameText' | translate" [nameErrorRequiredText]="'ngxauthfirebaseui.nameErrorRequiredText' | translate"
        [nameErrorMinLengthText]="'ngxauthfirebaseui.nameErrorMinLengthText' | translate" [nameErrorMaxLengthText]="'ngxauthfirebaseui.nameErrorMaxLengthText' | translate"
        [emailText]="'ngxauthfirebaseui.emailText' | translate" [emailErrorRequiredText]="'ngxauthfirebaseui.emailErrorRequiredText' | translate"
        [emailErrorPatternText]="'ngxauthfirebaseui.emailErrorPatternText' | translate" [passwordText]="'ngxauthfirebaseui.passwordText' | translate"
        [passwordErrorRequiredText]="'ngxauthfirebaseui.passwordErrorRequiredText' | translate" [registerTabText]="'ngxauthfirebaseui.registerTabText' | translate"
        [registerCardTitleText]="'ngxauthfirebaseui.registerCardTitleText' | translate" [registerButtonText]="'ngxauthfirebaseui.registerButtonText' | translate"
        [guestButtonText]="'ngxauthfirebaseui.guestButtonText' | translate" [resetPasswordTabText]="'ngxauthfirebaseui.resetPasswordTabText' | translate"
        [resetPasswordInputText]="'ngxauthfirebaseui.resetPasswordInputText' | translate" [resetPasswordErrorRequiredText]="'ngxauthfirebaseui.resetPasswordErrorRequiredText' | translate"
        [resetPasswordErrorPatternText]="'ngxauthfirebaseui.resetPasswordErrorPatternText' | translate" [resetPasswordActionButtonText]="'ngxauthfirebaseui.resetPasswordActionButtonText' | translate"
        [resetPasswordInstructionsText]="'ngxauthfirebaseui.resetPasswordInstructionsText' | translate" [emailConfirmationTitle]="'ngxauthfirebaseui.emailConfirmationTitle' | translate"
        [emailConfirmationText]="'ngxauthfirebaseui.emailConfirmationText' | translate">
      </ngx-auth-firebaseui>
    </div>

  </div>



</div>