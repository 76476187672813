import { HyperFormula, SimpleCellAddress } from "hyperformula";
import { Observable } from "rxjs";
import * as THREE from "three";
import { MathUtils, Float32BufferAttribute } from "three";
import traverse from 'traverse';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
// import { ColladaExporter } from 'three/examples/jsm/exporters/ColladaExporter.js';
import firebase from "firebase";
declare var document;





class Utils {

    public static rangeAbsoulte(range) {
        for (var i = 0; i < range.length; i++) {

            if (range[i].length == 4) {
                if (range[i][0] > range[i][2]) {
                    var tmp1 = range[i][0];
                    range[i][0] = range[i][2];
                    range[i][2] = tmp1;
                }
                if (range[i][1] > range[i][3]) {
                    var tmp1 = range[i][1];
                    range[i][1] = range[i][3];
                    range[i][3] = tmp1;
                }
            }
        }
        return range;
    }
    public static mapRowToParams(row: any[], commentrow: any[]): any {
        var params: any = {};
        for (var i = 0; i < row.length; i++) {
            try {
                var key: string = commentrow[i];
                params[key] = row[i];
            } catch (e) {
                console.warn('error in mapRowToParams', e);
            }
        }
        return params;
    }

    public static mapParamsToRow(params: any): any {
        var keys = Object.keys(params);
        var values = keys.map(key => params[key]);
        return [keys, values];
    }


    public static transformTableToNgxChartsModel(table) {
        var data = [];
        var header = table[0];
        for (var i = 1; i < table.length; i++) {
            var row = table[i];
            var obj = {};
            for (var j = 0; j < header.length; j++) {
                //     obj[header[j]] = row[j];
                obj[header[j]] = row[j];
            }
            data.push(obj);
        }
        return data;

    }
    // css für menu aus einstellungen
    public static basecsschange(projectid: string, menuSettings: any) {
        var css = "#gui-panel .btn-default { background-color: " + menuSettings.accentcolor + "; }";
        css += "#gui-panel .btn-default{ color:  " + menuSettings.buttontextcolor + "!important;    } "
        css += "#gui-panel .mat-slider-thumb{background-color: " + menuSettings.accentcolor + "!important;} "
        css += "#gui-panel .mat-slider-track-fill{ background-color:  " + menuSettings.accentcolor + "!important;    } "
        css += "#gui-panel label{ color:  " + menuSettings.textcolor + "!important;    } "

        var htmlid = "basecss_" + projectid;
        if (document.getElementById(htmlid))
            document.getElementById(htmlid).remove();
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.id = htmlid;
        link.href = 'data:text/css;charset=UTF-8,' + encodeURIComponent(css);
        document.getElementsByTagName('head')[0].appendChild(link);
    }



    public static saveGltf(scene: any, projectid: string, customerid: string, configid: string, afs: any): any {
        //  const exporter1 = new ColladaExporter();
        // const data = exporter1.parse(scene, null, (result ) => {
        //     var blob = new Blob([new Uint8Array(result, 0, result.byteLength)]);
        //     console.log(blob)
        //     // upload file to firebase
        //     const storageRef = firebase.storage().ref();
        //     //  this.afs.collection("configs/" + this.customerid + "/" + this.projectid + "files/exported/").add(
        //     var imageRef = storageRef.child("configs/" + customerid + "/" + projectid + "files/exported/" + "test" + '.dae');
        //     const uploadTask = imageRef.put(blob);
        //     uploadTask.on(
        //         firebase.storage.TaskEvent.STATE_CHANGED,
        //         snapshot => {
        //             const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        //             console.log('snap', snap);
        //         },
        //         () => {
        //             uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //                 console.log('File available at', downloadURL);
        //             });
        //         }

        //     );

        // });
        //        downloadFile(data);



        var exporter = new GLTFExporter();
        exporter.parse(scene, (result) => {

            // arraybuffer result
            var blob = new Blob([new Uint8Array(result as any, 0, result.byteLength)]);
            console.log(blob)
            // upload file to firebase
            const storageRef = firebase.storage().ref();
            //  this.afs.collection("configs/" + this.customerid + "/" + this.projectid + "files/exported/").add(
            var imageRef = storageRef.child("configs/" + customerid + "/" + projectid + "files/exported/" + "test" + '.glb');
            const uploadTask = imageRef.put(blob);
            uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snapshot => {
                    const snap = snapshot as firebase.storage.UploadTaskSnapshot;
                    console.log('snap', snap);
                }
                // get downloadurl
                , (error) => {
                    console.log(error);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        afs.doc("configs/" + customerid + "/" + projectid + "/" + configid).update(
                            { gltfurl: downloadURL });

                    });
                }


            );

            return blob;

        }, {
            binary: true
        } as any);


    }

    public static preNames(node: any) {
        var n = node.name.split('^');

        var path = n;// node.name;
        if (n.length > 0)
            path = n[n.length - 1];

        var parent = node.parent;
        // var n = parent.name.split('^')[parent.name.split('^').length - 1];
        while (parent) {
            var np = parent.name.split('^')[parent.name.split('^').length - 1];

            path = np + '^' + path;
            // path = parent.name + '^' + path;
            parent = parent.parent;
        }
        return path;
    }


    public static setValue(obj, path, value) {
        try {

            var a = path.split('.')
            var o = obj
            while (a.length - 1) {
                var n = a.shift()
                if (!(n in o)) { o[n] = {} }
                o = o[n]
            }
            o[a[0]] = value
        }
        catch (e) {
            console.warn(e)
        }
    }
    public static setValue2(obj, a, value) {
        try {


            var o = obj
            while (a.length - 1) {
                var n = a.shift()
                if (!(n in o)) { o[n] = {} }
                o = o[n]
            }
            o[a[0]] = value
        }
        catch (e) {
            console.warn(e)
        }
    }
    public static getValue(obj, path) {
        try {

            path = path.replace(/\[(\w+)\]/g, '.$1')
            path = path.replace(/^\./, '')
            var a = path.split('.')
            var o = obj
            while (a.length) {
                var n = a.shift()
                if (!(n in o)) { return }
                o = o[n]
            }
            return o
        }
        catch (e) {
            return undefined;
        }
    }
    public static getValue2(obj, a) {
        try {


            var o = obj
            while (a.length) {
                var n = a.shift()
                if (!(n in o)) { return }
                o = o[n]
            }
            return o
        }
        catch (e) {
            return undefined;
        }
    }
    public static async inputsForSaving(tree: any, excels: any): Promise<any> {
        var newtree = {};

        // unterste Ebene == Hauptkonfig, nodes danach => jeder 2. Subconfig
        globalThis.newtree = {};
        globalThis.setValue = Utils.setValue;

        newtree = await traverse(tree.params).forEach(function (value) {
            try {
                //                if (typeof (value) === "object" && value) {
                // if (value?.meshuuid) {
                // }
                console.log(this.path, typeof (value), this.isLeaf, value)
                var cpath = this.path[0];
                if (this.path.length == 0)
                    cpath = "";
                for (var i = 1; i < this.path.length; i++)
                    cpath = cpath + "." + this.path[i];
                console.log('cp', cpath)
                if (this.isLeaf)
                    globalThis.setValue(globalThis.newtree, cpath, "ui");
                //               }
            } catch (err) {
                console.error(err)
            }
        });
        console.log('newtree for save', globalThis.newtree)
        return newtree;
    }

    public static getIDfromPath(path: string): string {
        return "abc";
    }

    public static getParentPath(path: string): string {
        var parts = path.split('.');
        var s = parts[0];
        for (var i = 1; i < parts.length - 1; i++) {
            s += "." + parts[i]
        }

        return s;
    }
    public static getPathWithoutRoot(path: string): string {
        var parts = path.split('.');
        var s = "";
        for (var i = 1; i < parts.length; i++) {
            s += "." + parts[i]
        }

        return s;
    }





    public static async transformLoadedInputs(tree: any, excels: any): Promise<any> {
        globalThis.newtree = {};
        globalThis.setValue = Utils.setValue;
        await traverse(tree.inputs).forEach(function (value) {
            try {
            } catch (e) {

            }
        });
    }



    public static async findSubConfigByParameters(tree: any, konfigurationTypId: string, parameters: any, inputs: any, id: number): Promise<any> {
        var newtree = {};

        await traverse(tree._root).forEach(function (value) {
            if (this.parent && this.parent.key) {
                if (this.parent.key.startsWith(konfigurationTypId + "_")) {
                    var p = "_root";
                    for (var i = 0; i < this.path.length; i++) {
                        p += "." + this.path[i];
                    }
                    p = p.replace(this.parent.key, konfigurationTypId + "_Z" + id);
                    value.path = p;
                    globalThis.setValue(newtree, p, value);
                }
            }

        });

        return newtree;
    }


    public static getProductDataFromVariant(variant, excel, assemblies) {
        try {

            var produktesid = excel.getSheetId('Produkte');
            if (!produktesid) return null;
            var r = 0;
            var p = excel.getCellValue({ sheet: produktesid, col: 0, row: r });
            while (p !== null) {
                r++;
                p = excel.getCellValue({ sheet: produktesid, col: 0, row: r });
                if (p === variant) {
                    var color = excel.getCellValue({ sheet: produktesid, col: 5, row: r });
                    var baugruppe = excel.getCellValue({ sheet: produktesid, col: 9, row: r });
                    var montageabschnitt = excel.getCellValue({ sheet: produktesid, col: 10, row: r });

                    if (baugruppe) {

                        var bindex = parseInt((baugruppe as string)?.split(' ')[0].replace('.', ''));
                        var vis = true;
                        if (bindex) {

                            vis = assemblies[bindex]?.visible;
                            if (vis === null || vis === undefined) vis = true;
                            if (assemblies[bindex] == null)
                                assemblies[bindex] = { visible: vis, baugruppe: baugruppe as string, montageabschnitte: [...new Array(99)].map(() => null) };
                            if (montageabschnitt) {
                                var mindex = parseInt((montageabschnitt as string)?.split(' ')[0].replace('.', ''));
                                var vis = true;
                                if (mindex) {
                                    vis = assemblies[bindex].montageabschnitte[mindex]?.visible;
                                    if (vis === null || vis === undefined) vis = true;
                                    assemblies[bindex].montageabschnitte[mindex] = { visible: vis, montageabschnitt: montageabschnitt as string };
                                }
                            }
                        }

                    }

                    return { color: color, baugruppe: baugruppe, montageabschnitt: montageabschnitt };
                }
            }
        } catch (e) {
            console.log('error in getProductDataFromVariant', e);
        }
        return null;
    }

    static async dbConfiguration2Configurator(json, excelsdata, mainconfig): Promise<any> {

        globalThis.konfiguration = {

        };
        globalThis.setValue = Utils.setValue;
        globalThis.getValue = Utils.getValue;
        globalThis.mainconfig = mainconfig;
        json['[[Prototype]]'] = null;
        console.log('db json', json);
        // await travasync(json, async function (node, next) {
        //     console.log("Current node", node);
        //     console.log("Context object", this);
        //     if (this.key == "Eingaben") {
        //         var config = globalThis.mainconfig;
        //         var subconfigspath = "";
        //         var curpath = this.path[0];
        //         var dobreak = false;
        //         if (this.path.length > 2)
        //             for (var i = 1; i < this.path.length; i++) {
        //                 curpath += "." + this.path[i];
        //                 if (this.path[i - 1] == "DetailKonfigurationen") {
        //                     var subconfigcontrolname = this.path[i];
        //                     // nur Zeile auslesen, konfiguration wird aus Baum genommen!

        //                     var a = await Utils.searchSubconfigNameAddress(excels, subconfigcontrolname);

        //                     var curkonfig = globalThis.getValue(json, curpath).KonfigurationTypId;
        //                     // alt: var sub = (excels[a.subconfig] as HyperFormula).getCellValue({ sheet: a.address.sheet, col: 17, row: a.address.row }).toString();
        //                     // sub = sub.split('#')[0];
        //                     config = this.parent.node.KonfigurationTypId;
        //                     if (!a?.address) {
        //                         console.log('FEHLER searchSubconfigNameAddress nicht gefunden', subconfigcontrolname, this.path)
        //                         dobreak = true;
        //                         return;
        //                     }
        //                     subconfigspath += "." + await excels[a.subconfig].getSheetName(a.address.sheet) + "#Subconfiguration_Z" + (a.address.row + 1);


        //                 }
        //             }

        //         subconfigspath = subconfigspath.substr(1, subconfigspath.length - 1);

        //         var excel = excels[config] as HyperFormula;
        //         var controlname = this.key;
        //         var address = await Utils.searchControlNameAddress(excel, controlname);
        //         // Tabelle
        //         if (controlname.includes("_ROW_")) {
        //             address.col -= 1;
        //             address.row += 4;
        //         }

        //         // A->J
        //         if (address.col == 0) address.col = 9;
        //         var sheetname = await excel.getSheetName(address.sheet);
        //         var row = address.row + 1;
        //         var cellname = await excel.simpleCellAddressToString(address, address.sheet);

        //         var newpath = "inputs." + subconfigspath + "." + sheetname + "." + cellname;
        //         newpath = newpath.replace('..', '.')

        //         globalThis.setValue(globalThis.konfiguration, newpath, node);
        //         //                        globalThis.setValue(globalThis.konfiguration, newpath, value);


        //     }

        //     next();
        // }, function (newObj) {
        //     console.log("Done!");
        // });
        // console.log('done2');

        await traverse(json).forEach(function (value) {
            try {

                if (this.parent)
                    if (this.parent.key == "Eingaben") {
                        if (this.key[0] != '$') {

                            // // control in Excel suchen, Excel folgt aus pfad
                            var config = globalThis.mainconfig;
                            var subconfigspath = "";

                            var curpath = this.path[0];
                            var dobreak = false;
                            //neue pfadberechnung
                            if (this.path.length > 2)
                                for (var i = 1; i < this.path.length; i++) {
                                    curpath += "." + this.path[i];
                                    if (this.path[i - 1] == "DetailKonfigurationen") {
                                        var subconfigcontrolname = this.path[i];
                                        // nur Zeile auslesen, konfiguration wird aus Baum genommen!

                                        var a = Utils.searchSubconfigNameAddressData(excelsdata, subconfigcontrolname).then(a => {

                                            var curkonfig = globalThis.getValue(json, curpath).KonfigurationTypId;
                                            // alt: var sub = (excels[a.subconfig] as HyperFormula).getCellValue({ sheet: a.address.sheet, col: 17, row: a.address.row }).toString();
                                            // sub = sub.split('#')[0];
                                            config = this.parent.parent.node.KonfigurationTypId;
                                            if (!a?.address) {
                                                console.log('FEHLER searchSubconfigNameAddress nicht gefunden', subconfigcontrolname, this.path)
                                                dobreak = true;
                                                return;
                                            }
                                            subconfigspath += "." + excelsdata[a.subconfig].getSheetName(a.address.sheet) + "#Subconfiguration_Z" + (a.address.row + 1);

                                        });

                                    }
                                }

                            subconfigspath = subconfigspath.substr(1, subconfigspath.length - 1);

                            var exceldata = excelsdata[config] as HyperFormula;
                            var controlname = this.key;
                            var address = Utils.searchControlNameAddressData(exceldata, controlname) as any;
                            // Tabelle
                            if (controlname.includes("_ROW_")) {
                                address.col -= 1;
                                address.row += 4;
                            }
                            else
                                // A->J
                                if (address.col == 0)
                                    address.col = 9;
                            var sheetname = Object.keys(exceldata)[address.sheet]; //  exceldata.getSheetName(address.sheet);
                            var row = address.row + 1;

                            var cellname = Utils.indices2cellaname(address.col, address.row); // exceldata.simpleCellAddressToString(address, address.sheet);

                            var newpath = "inputs." + subconfigspath + "." + sheetname + "." + cellname;

                            newpath = newpath.replace('..', '.')

                            console.log('path, val', newpath, value)
                            globalThis.setValue(globalThis.konfiguration, newpath, value);

                        }
                    }

            } catch (err) {
                console.error(err)
            }
        });



        console.log('newtree for load', globalThis.konfiguration)
        return globalThis.konfiguration;

    }

    public static saveProjectCookies(cookieService, data) {
        cookieService.set('logintoken', null);
    }

    public static extendPath(path: string[], tree): string {
        // alte version
        //inputs:
        // Halle1:
        //       J27: 2001
        //       J33: 16
        //       LIGNA-SYSTEMS-WANDGRUPPE_Z229:
        //       LIGNA-SYSTEMS-WANDPANEEL_Z133:
        // Huelle:
        //   J28: "RAL 9010 Reinweiß"
        //   J30: "RAL 8011 Nussbraun"
        //   Öffnungen:
        //      K44: "Fenster B.220-H.120"
        var p = "_root";
        for (var i = 0; i < path.length; i++) {
            if (path[i].includes('LIGNA-SYSTEMS-')) { // hack da unterkonfigurationen noch nicht nach Tabellenblatt in Pfad
                var z = path[i].split("_Z")[1];
                var t = p + ".Subconfiguration_Z" + z;
                var v = Utils.getValue(tree, t);
                if (v?.sheetname)
                    p += "." + v.sheetname + "." + path[i];
                else
                    p += "." + path[i];
            }
            else {
                p += "." + path[i];
            }
        }




        //console.log('p', p, path)
        return p;
    }

    public static async getIDfromExtendedExcelPath(tree, path: string, excel: HyperFormula, configuration): Promise<string> {
        var splitted = path.split('.');

        var sheetname = splitted[splitted.length - 2];

        var rt = splitted[splitted.length - 1].replace(/[0-9]/g, '');
        var row = parseInt(splitted[splitted.length - 1].replace(rt, "")) - 1;
        var sheetid = await excel.getSheetId(sheetname);
        var controlname = await excel.getCellValue({ sheet: sheetid, col: 0, row: row }) as string;

        // tabelle
        var tcell = await excel.simpleCellAddressFromString(rt + (row - 3), sheetid)
        var tablecheck = await excel.getCellValue(tcell);
        if (tablecheck == "Combobox" || tablecheck == "Textbox" || tablecheck == "NumericTextbox" || tablecheck == "Produkt" || tablecheck == "ProduktNoLabel") // hack für Tabellenverarbeitung
        {
            //    Halle1!B701: D1091
            var n = await excel.simpleCellAddressFromString(splitted[splitted.length - 1], sheetid);
            var xy = await Utils.searchTableIndex(excel, splitted[splitted.length - 1], sheetid);
            var startcellname = await excel.simpleCellAddressToString({ sheet: sheetid, col: xy.x + 1, row: xy.y }, sheetid);
            var tablename = await Utils.searchTableControlName(excel, splitted[splitted.length - 1], sheetid, startcellname);


            // todo: col, row relativ statt absolut
            var rx = n.col - xy.x - 1;
            var ry = (n.row - xy.y - 5) / 10;
            controlname = tablename + "_ROW_" + ry + "_COL_" + rx;


        }

        // tabelle


        return controlname;
        //     var p2 = parseInt(path[i].split('_Z')[1]);
        // var subconfig = path[i].split('_Z')[0];

    }
    public static getKonfigurationTypIdfromExtendedExcelPath(tree, path: string, mainconfiguration): string {
        var splitted = path.split('.');
        var subconfig = mainconfiguration;

        if (splitted[splitted.length - 3].includes('#Subconfiguration_Z')) {
            var parent = "";
            for (let i = 0; i < splitted.length - 2; i++) {
                parent += "." + splitted[i];
            }
            parent = parent.replace("._root", "_root");
            subconfig = globalThis.getValue(tree, parent).subconfiguration;
        }

        return subconfig;

    }

    public static async searchTableControlName(excel: HyperFormula, startcell: string, sheetid: number, startcellname): Promise<any> {
        var cell = await excel.simpleCellAddressFromString(startcell, sheetid);
        for (var i = 0; i < 2000; i++) {
            var tablecheck = await excel.getCellValue({ sheet: sheetid, row: i, col: 14 }) as string;
            try {

                //    Halle1!B701: D1091
                if (tablecheck?.includes(startcellname)) {

                    return await excel.getCellValue({ sheet: sheetid, row: i, col: 0 }) as string;
                    // spakte o  14

                }
            }
            catch (e) {
                console.log(i, e);
            }

        }
        return null;
    }
    public static async searchTableIndex(excel: HyperFormula, startcell: string, sheetid: number): Promise<any> {
        var rt = startcell.replace(/[0-9]/g, '');
        var row = parseInt(startcell.replace(rt, "")) - 1;

        var cell = await excel.simpleCellAddressFromString(startcell, sheetid);
        var leftcell = { sheet: cell.sheet, col: cell.col - 1, row: cell.row };

        var y = cell.row;
        var x = cell.col;
        for (var i = cell.col; i >= 0; i--) {
            var tablecheck = await excel.getCellValue({ sheet: sheetid, row: cell.row, col: i });
            if (tablecheck == "Input") {
                x = i;
                break;
            }
        }
        for (var i = cell.row; i > 0; i--) {
            var tablecheck1 = await excel.getCellValue({ sheet: sheetid, row: i, col: x });
            var tablecheck2 = await excel.getCellValue({ sheet: sheetid, row: i - 1, col: x });
            if (tablecheck1 == "Typ" && tablecheck2 != "Auswahl") {
                y = i - 1;
                break;
            }
        }
        return { x: x, y: y };
    }

    public static replaceCell2Controlname(extendedPath, controlid): string {
        var p = "";
        var splittedpath = extendedPath.split('.');
        for (var i = 0; i < splittedpath.length - 1; i++) {
            p += splittedpath[i] + ".";
        }
        p += controlid;
        return p;
    }




    // für konvertierung
    public static async searchControlNameAddress(excel: HyperFormula, controlname: string): Promise<SimpleCellAddress> {
        var sheetnames = await excel.getSheetNames();

        // 'TabelleVerbandeH1_ROW_700_COL_0'
        var col = null; var row = null;
        var istable = false;
        if (controlname.includes("_ROW_")) {
            row = parseInt(controlname.split('_ROW_')[1].split("_COL_")[0]);
            col = parseInt(controlname.split('_COL_')[1]);
            controlname = controlname.split('_ROW')[0];
            istable = true;
        }

        var address = null;
        for (var i = 0; i < sheetnames.length; i++) {

            var startcell = { sheet: i, col: 0, row: 0 };
            address = Utils.searchCellByValue(excel, startcell, controlname, 3);
            if (address.sheet != -1) {
                if (!istable)
                    return address;
                else
                    break;
            }
        }


        if (istable) {
            for (var i = 0; i < sheetnames.length; i++) {

                var v = await excel.getCellValue({ sheet: address.sheet, row: address.row, col: 14 }).toString(); // Halle1!H701:P1091
                var tc = v.split('!')[1].split(':')[0];
                var ca = await excel.simpleCellAddressFromString(tc, address.sheet);
                address.col = ca.col + col + 1;
                address.row = ca.row + row * 10 + 1;

                if (address.sheet != -1) {
                    // cell o verweis + offset (input offsety)
                    return address;
                }
            }

        }

        return { sheet: -1, row: -1, col: -1 };
    }




    public static searchControlNameAddressData(exceldata: any, controlname: string): SimpleCellAddress {
        var sheetnames = this.getExcelsDataSheetNamesexcels(exceldata);

        // 'TabelleVerbandeH1_ROW_700_COL_0'
        var col = null; var row = null;
        var istable = false;
        if (controlname.includes("_ROW_")) {
            row = parseInt(controlname.split('_ROW_')[1].split("_COL_")[0]);
            col = parseInt(controlname.split('_COL_')[1]);
            controlname = controlname.split('_ROW')[0];
            istable = true;
        }

        var address = null;
        for (var i = 0; i < sheetnames.length; i++) {

            var startcell = { sheet: i, col: 0, row: 0 };
            address = Utils.searchCellByValueData(exceldata, startcell, controlname, 3);
            if (address.sheet != -1) {
                if (!istable)
                    return address;
                else
                    break;
            }
        }


        if (istable) {
            for (var i = 0; i < sheetnames.length; i++) {

                var sheet = exceldata[sheetnames[address.sheet]];
                var v = sheet[address.row][14]; //  exceldata.getCellValue({ sheet: address.sheet, row: address.row, col: 14 }).toString(); // Halle1!H701:P1091


                var tc = v.split('!')[1].split(':')[0];

                //                var ca = await exceldata.simpleCellAddressFromString(tc, address.sheet);
                var ca = Utils.cellname2indices(tc);

                address.col = ca[0] + col + 1;// ca.col + col + 1;
                address.row = ca[1] + row * 10 + 1; // ca.row + row * 10 + 1;

                if (address.sheet != -1) {
                    // cell o verweis + offset (input offsety)
                    return address;
                }
            }

        }

        return { sheet: -1, row: -1, col: -1 };
    }

    public static cellname2indices(cellname: string): number[] {
        var ij = [0, 0];
        var c1 = cellname.match(/[a-zA-Z]+/g)[0].toLowerCase();
        var num1 = cellname.match(/\d+/g)[0];
        ij[0] = c1.charCodeAt(0) - 97;
        ij[1] = parseInt(num1) - 1; // -1 ?
        return ij;
    }

    public static indices2cellaname(i, j) {
        let varname = String.fromCharCode(97 + i).toUpperCase() + (j + 1);
        return varname;
    }

    // TODO: mit webworker praktisch unmöglich? oder als promises parallel?
    public static async searchSubconfigNameAddress(excels: HyperFormula[], subconfigname: string): Promise<any> {

        /// TODO

        for (const [key, excel] of Object.entries(excels)) {

            var sheetnames = await excel.getSheetNames();

            for (var i = 0; i < sheetnames.length; i++) {

                var startcell = { sheet: i, col: 3, row: 0 }; // subconfig IDs in spalte D statt A!
                var address = await Utils.searchCellByValue(excel, startcell, subconfigname, 3);
                if (address.sheet != -1) {
                    return { address: address, subconfig: key };
                }

            }
        }


        return -1;
    }



    public static searchSubconfigNameAddressData(excelsdata: HyperFormula[], subconfigname: string): any {

        /// TODO

        for (const [key, excel] of Object.entries(excelsdata)) {
            var sheetnames = Utils.getExcelsDataSheetNamesexcels(excelsdata);
            for (var i = 0; i < sheetnames.length; i++) {

                var startcell = { sheet: i, col: 3, row: 0 }; // subconfig IDs in spalte D statt A!
                var address = Utils.searchCellByValueData(excelsdata, startcell, subconfigname, 3);
                if (address.sheet != -1) {
                    return { address: address, subconfig: key };
                }

            }
        }


        return -1;
    }

    public static getExcelsDataSheetNamesexcels(excelsdata) {
        var sheetnames = [];
        for (const [key, excel] of Object.entries(excelsdata)) {
            sheetnames.push(key);
        }
        return sheetnames;
    }

    public static searchCellByValueData(exceldata: any, startcell: any, value: string, direction: number): any {
        var c = startcell.col;
        var r = startcell.row;
        var s = startcell.sheet;

        value = value.toUpperCase();

        if (direction == 1) { // search up
            for (const [key, excel] of Object.entries(exceldata)) {
                var sheetnames = this.getExcelsDataSheetNamesexcels(exceldata);
                for (var j = 0; j < sheetnames.length; j++) {

                    for (var i = startcell.row; i > -1; i--) {
                        var v = exceldata[sheetnames[j]];
                        v = v[i][c]?.toUpperCase();
                        //var v = await excelsdata.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
                        if (v === value)
                            return { sheet: s, col: c, row: i };
                        if (v == "END")
                            return { sheet: -1, row: c, col: i };
                    }

                }
            }
        }
        if (direction == 3) { // search down

            //  for (const [key, excel] of Object.entries(exceldata)) {
            var sheetnames = this.getExcelsDataSheetNamesexcels(exceldata);
            for (var j = 0; j < sheetnames.length; j++) {
                var table = exceldata[sheetnames[j]];

                if (table)
                    try {

                        for (var i = startcell.row; i < 10000; i++) {
                            v = table[i][c];
                            if (v)
                                v = v.toUpperCase()
                            //var v = await exceldata.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
                            if (v === value)
                                return { sheet: j, col: c, row: i };
                            if (v == "END")
                                break;
                            //       return { sheet: -1, row: c, col: i };
                        }
                    }
                    catch (err) {
                        //  console.log(err);
                    }

                //   }
            }

        }
        if (direction == 4) { // search left
            for (const [key, excel] of Object.entries(exceldata)) {
                var sheetnames = this.getExcelsDataSheetNamesexcels(exceldata);
                for (var j = 0; j < sheetnames.length; j++) {
                    for (var i = startcell.col; i > -1; i--) {
                        var v = exceldata[sheetnames[j]];
                        v = v[i][c]?.toUpperCase();
                        //var v = await excelsdata.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
                        if (v === value)
                            return { sheet: s, col: i, row: r };
                        if (v == "END")
                            return { sheet: -1, row: i, col: r };
                    }

                }
            }
        }
        if (direction == 2) { // search right
            for (const [key, excel] of Object.entries(exceldata)) {
                var sheetnames = this.getExcelsDataSheetNamesexcels(exceldata);
                for (var j = 0; j < sheetnames.length; j++) {

                    for (var i = startcell.row; i < 10000; i++) {
                        var v = exceldata[sheetnames[j]];
                        v = v[i][c]?.toUpperCase();
                        //var v = await excelsdata.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
                        if (v === value)
                            return { sheet: s, col: i, row: r };
                        if (v == "END")
                            return { sheet: -1, row: i, col: r };
                    }

                }
            }
        }


        return { sheet: -1, row: -1, col: -1 };
    }


    public searchParamCol(excel: any, paramname: string, row, startcell: any,) {

    }


    // TODO: sufu in backgroundworker. dauert zu lange
    public static async searchCellByValue(excel: any, startcell: any, value: string, direction: number): Promise<any> {
        var c = startcell.col;
        var r = startcell.row;
        var s = startcell.sheet;

        value = value.toUpperCase();

        if (direction == 1) { // search up
            for (var i = startcell.row; i > -1; i--) {
                var v = (await excel.getCellValue({ sheet: s, col: c, row: i }))?.toString().toUpperCase();
                if (v === value)
                    return { sheet: s, col: c, row: i };
                if (v == "END")
                    return { sheet: -1, row: c, col: i };
            }
        }
        if (direction == 3) { // search down
            for (var i = startcell.row; i < 10000; i++) {
                var v = (await excel.getCellValue({ sheet: s, col: c, row: i }))?.toString().toUpperCase();
                if (v === value)
                    return { sheet: s, col: c, row: i };
                if (v == "END")
                    return { sheet: -1, row: c, col: i };
            }
        }
        if (direction == 4) { // search left
            for (var i = startcell.col; i > -1; i--) {
                var v = (await excel.getCellValue({ sheet: s, col: i, row: r }))?.toString().toUpperCase();
                if (v === value)
                    return { sheet: s, col: i, row: r };
                if (v == "END")
                    return { sheet: -1, row: i, col: r };
            }
        }
        if (direction == 2) { // search right
            for (var i = startcell.col; i < 10000; i++) {
                var v = (await excel.getCellValue({ sheet: s, col: i, row: r }))?.toString().toUpperCase();
                if (v === value)
                    return { sheet: s, col: i, row: r };
                if (v == "END")
                    return { sheet: -1, row: i, col: r };
            }
        }


        return { sheet: -1, row: -1, col: -1 };
    }

    // TODO: für WW zusammenfassen
    public static async setInputValue(xls: any, name: string, value: any): Promise<any> {
        var sheets = await xls.getSheetNames();
        for (var i = 0; i < sheets.length; i++) {
            var sheetId = await xls.getSheetId(sheets[i]);
            var ij = await xls.searchCell(value, { start: { row: 0, col: 0, sheet: sheetId }, end: { row: 10000, col: 0, sheet: sheetId } });
            if (ij)
                await xls.setCellContents({ sheet: sheetId, col: 9, row: ij[0] }, value);
            //   var cc = await Utils.searchCellByValueOld(xls, { sheet: sheetId, row: 0, col: 0 }, name, 2);
            // if (cc.sheet != -1)
            //    await xls.setCellContents({ sheet: cc.sheet, col: 9, row: cc.row }, value);
        }
    }

    // public static async searchCellByValueOld(excel: HyperFormula, startcell: any, value: string, direction: number): Promise<any> {
    //     var c = startcell.col;
    //     var r = startcell.row;
    //     var s = startcell.sheet;

    //     value = value.toUpperCase();

    //     if (direction == 0) { // search up
    //         for (var i = startcell.row; i > -1; i--) {
    //             var v = await excel.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
    //             if (v === value)
    //                 return { sheet: s, col: c, row: i };
    //             if (v == "END")
    //                 return { sheet: -1, row: c, col: i };
    //         }
    //     }
    //     if (direction == 2) { // search down
    //         for (var i = startcell.row; i < 10000; i++) {
    //             var v = await excel.getCellValue({ sheet: s, col: c, row: i })?.toString().toUpperCase();
    //             if (v === value)
    //                 return { sheet: s, col: c, row: i };
    //             if (v == "END")
    //                 return { sheet: -1, row: c, col: i };
    //         }
    //     }
    //     if (direction == 3) { // search left
    //         for (var i = startcell.col; i > -1; i--) {
    //             var v = await excel.getCellValue({ sheet: s, col: i, row: r })?.toString().toUpperCase();
    //             if (v === value)
    //                 return { sheet: s, col: i, row: r };
    //             if (v == "END")
    //                 return { sheet: -1, row: i, col: r };
    //         }
    //     }
    //     if (direction == 2) { // search right
    //         for (var i = startcell.row; i < 10000; i++) {
    //             var v = await excel.getCellValue({ sheet: s, col: i, row: r })?.toString().toUpperCase();
    //             if (v === value)
    //                 return { sheet: s, col: i, row: r };
    //             if (v == "END")
    //                 return { sheet: -1, row: i, col: r };
    //         }
    //     }


    //     return { sheet: -1, row: -1, col: -1 };
    // }

    public static EdgesGeometry(geometry) {

        var thresholdAngle = 1; // (thresholdAngle !== undefined) ? thresholdAngle : 1;
        // buffer
        var vertices = [];
        // helper variables
        var thresholdDot = Math.cos(MathUtils.DEG2RAD * 1);
        var thresholdDot2 = Math.cos(MathUtils.DEG2RAD * 1111);
        var edge = [0, 0], edges = {}, edge1, edge2;
        var key, keys = ['a', 'b', 'c'];
        // prepare source geometry
        var geometry2;
        if (geometry.isBufferGeometry) {
            geometry2 = new THREE.BufferGeometry();
            geometry2 = geometry.clone();

            // geometry2.fromBufferGeometry(geometry);
        } else {
            geometry2 = geometry.clone();
        }
        //    geometry2.mergeVertices();
        geometry2.computeFaceNormals();
        var sourceVertices = geometry2.vertices;
        var faces = geometry2.faces;
        // now create a data structure where each entry represents an edge with its adjoining faces
        for (var i = 0, l = faces.length; i < l; i++) {
            var face = faces[i];
            for (var j = 0; j < 3; j++) {
                edge1 = face[keys[j]];
                edge2 = face[keys[(j + 1) % 3]];
                edge[0] = Math.min(edge1, edge2);
                edge[1] = Math.max(edge1, edge2);
                key = edge[0] + ',' + edge[1];
                if (edges[key] === undefined) {
                    edges[key] = { index1: edge[0], index2: edge[1], face1: i, face2: undefined };
                } else {
                    edges[key].face2 = i;
                }
            }
        }

        // generate vertices
        for (key in edges) {
            var e = edges[key];
            // an edge is only rendered if the angle (in degrees) between the face normals of the adjoining faces exceeds this value. default = 1 degree.
            if (
                e.face2 === undefined ||
                (faces[e.face1].normal.dot(faces[e.face2].normal) <= thresholdDot)) {//} && faces[e.face1].normal.dot(faces[e.face2].normal) >= thresholdDot2)) {

                if (e.face2 !== undefined) {
                    var vertex = sourceVertices[e.index1];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                    vertex = sourceVertices[e.index2];
                    vertices.push(vertex.x, vertex.y, vertex.z);
                }

            }
        }

        var g = new THREE.BufferGeometry() as any;
        g.type = "EdgesGeometry";
        // build geometry
        var b = new Float32BufferAttribute(vertices, 3);
        g.setAttribute('position', b);

        return g;

    }

    // ebene finden
    public static samecoordinates(o) {
        let x = true;
        for (let i = 1; i < o.coords.length; i++) {
            if (o.coords[i].x.toFixed(2) !== o.coords[i - 1].x.toFixed(2)) {
                x = false;
                break;
            }
        }
        if (x) { return "x"; }

        let y = true;
        for (let i = 1; i < o.coords.length; i++) {
            if (o.coords[i].y.toFixed(2) !== o.coords[i - 1].y.toFixed(2)) {
                y = false;
                break;
            }
        }
        if (y) { return "y"; }

        let z = true;
        for (let i = 1; i < o.coords.length; i++) {
            if (o.coords[i].z.toFixed(2) !== o.coords[i - 1].z.toFixed(2)) {
                z = false;
                break;
            }
        }
        if (z) { return "z"; }

        return '-1';
    }



    public static abslength(ext) {
        var vz = 1;
        if (ext.x + 0.001 < 0) vz = -1;
        if (ext.y + 0.001 < 0) vz *= -1;
        if (ext.z + 0.001 < 0) vz *= -1;
        return vz * Math.sqrt(ext.x * ext.x + ext.y * ext.y + ext.z * ext.z);
    }


    public static angle2vectors(p1, p2) {
        var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
        var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
        var angle = (Math.atan2(p2.y, p2.x) - Math.atan2(p1.y, p1.x)) * 180 / Math.PI;



        return angle;
    }


    public static lightenDarkenColor(col, amt) {
        var usePound = false;
        var num = 0;
        if (!col)
            return 0;
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
            num = parseInt(col, 16);
        }
        else
            num = parseInt(col);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        if (usePound)
            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        else
            return (g | (b << 8) | (r << 16));

    }




    public static findLineByLeastSquares(values_x, values_y): any {
        var x_sum = 0;
        var y_sum = 0;
        var xy_sum = 0;
        var xx_sum = 0;
        var count = 0;

        /*
         * The above is just for quick access, makes the program faster
         */
        var x = 0;
        var y = 0;
        var values_length = values_x.length;

        if (values_length != values_y.length) {
            throw new Error('The parameters values_x and values_y need to have same size!');
        }

        /*
         * Above and below cover edge cases
         */
        if (values_length === 0) {
            return [[], []];
        }

        /*
         * Calculate the sum for each of the parts necessary.
         */
        for (let i = 0; i < values_length; i++) {
            x = values_x[i];
            y = values_y[i];
            x_sum += x;
            y_sum += y;
            xx_sum += x * x;
            xy_sum += x * y;
            count++;
        }

        /*
         * Calculate m and b for the line equation:
         * y = x * m + b
         */
        var m = (count * xy_sum - x_sum * y_sum) / (count * xx_sum - x_sum * x_sum);
        var b = (y_sum / count) - (m * x_sum) / count;

        /*
         * We then return the x and y data points according to our fit
         */
        var result_values_x = [];
        var result_values_y = [];

        for (let i = 0; i < values_length; i++) {
            x = values_x[i];
            y = x * m + b;
            result_values_x.push(x);
            result_values_y.push(y);
        }
        return { m: m, b: b };
        return [result_values_x, result_values_y];
    }


    //     //(1, 6), (2,5), (3,7), (4,10)
    // var X = [1, 2, 3, 4]
    // var Y = [6, 5, 7, 10]
    // var ret = {}
    // var f = lsq(X, Y, ret)
    // console.dir(ret) //{ m: 1.4, b: 3.5 }
    // console.log(f(3.6)) //8.54
    // var f = lsq(X, Y, true, ret)
    // console.dir(ret) //{ m: 1.4, b: 3.5, bErr: 1.7748239349298847, mErr: 0.648074069840786 }
    public static LeastSquares2(X, Y, computeError, ret) {
        if (typeof computeError == 'object') {
            ret = computeError
            computeError = false
        }

        if (typeof ret == 'undefined') ret = {}

        var sumX = 0
        var sumY = 0
        var sumXY = 0
        var sumXSq = 0
        var N = X.length

        for (var i = 0; i < N; ++i) {
            sumX += X[i]
            sumY += Y[i]
            sumXY += X[i] * Y[i]
            sumXSq += X[i] * X[i]
        }

        ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
        ret.b = sumY / N - ret.m * sumX / N

        if (computeError) {
            var varSum = 0
            for (var j = 0; j < N; ++j) {
                varSum += (Y[j] - ret.b - ret.m * X[j]) * (Y[j] - ret.b - ret.m * X[j])
            }

            var delta = N * sumXSq - sumX * sumX
            var vari = 1.0 / (N - 2.0) * varSum

            ret.bErr = Math.sqrt(vari / delta * sumXSq)
            ret.mErr = Math.sqrt(N / delta * vari)
        }

        return function (x) {
            return ret.m * x + ret.b
        }
    }

    // for debugging -----------------------------------------------
    async printCellData(cell: string, sheet, excel) {
        var cellcontent = await excel.getCellFormula(excel.simpleCellAddressFromString(cell, sheet)); // H169 ='Statik Tetra'!C44
        var name = await excel.getSheetName(sheet);
        console.log("'" + name + "'!", cell, cellcontent);
        var result = await excel.getCellValue(excel.simpleCellAddressFromString(cell, sheet));
        console.log(result)
    }

    async printCellDepends(cell, sheet, excel) {
        try {

            this.printCellData(cell, sheet, excel);
            var deps = await excel.getCellDependents(excel.simpleCellAddressFromString(cell, sheet));
            for (var i = 0; i < deps.length; i++) {
                var ca = await excel.simpleCellAddressToString(deps[i]);
                this.printCellDepends(ca, sheet, excel);
            }
        }
        catch (err) { }
    }


    async printCellPrecedents(cell, sheet, excel) {
        this.printCellData(cell, sheet, excel);
        var deps = excel.getCellPrecedents(excel.simpleCellAddressFromString(cell, sheet));
        for (var i = 0; i < deps.length; i++) {
            var ca = await excel.simpleCellAddressToString(deps[i]);
            await this.printCellPrecedents(ca, sheet, excel);
        }
    }


    // remove numbers at the end of a string
    public static remove_numbers_at_the_end(str) {
        var reg = /\d+$/;
        var match = reg.exec(str);
        if (match) {
            return str.substring(0, match.index);
        }
        return str;
    }


    public static getCellReferences(formula, dolog = false): string[] {
        //      formula = '=A100+B$2:2+INDIRECT("A2:B")+$C3-SUM($D$1:$E5)';
        try {

            if (!formula || typeof formula !== 'string')
                return;

            var fSegments = formula.split('"'); // I want to exclude references within double quotation marks
            var rangeRefRe = /[^0-9a-zA-Z_$]([0-9a-zA-Z$]+?:[0-9a-zA-Z$]+)(?![0-9a-zA-Z_])/g;
            var cellRefRe = /[^0-9a-zA-Z_$:](\$?[a-zA-Z]{1,2}\$?[1-9][0-9]*)(?![0-9a-zA-Z_:]|\$?[0-9]{1,7}\:\$?[0-9]{1,7})/g;
            var re = /[^0-9a-zA-Z_$:]((((\'.+\')|([a-zA-Z0-9]+))\!)?\$?([a-zA-Z]+(\$?[1-9]\d*)(:(\$?[a-zA-Z]+)?\$?([1-9]\d*)?)?|((:\$?[a-zA-Z]+\$?([1-9]\d*)?))))/g;

            var refResult;
            var references = [];
            for (var i = 0; i < fSegments.length; i += 2) {
                //            console.log('fSegments', fSegments[i]);

                while (refResult = rangeRefRe.exec(fSegments[i])) {
                    //       if (dolog) console.log('refResult[1] ' + refResult[1])
                    var v = refResult[1].split(':');
                    // TODO: all cells from range
                    for (var j = 0; j < v.length; j++) {
                        references.push(v[j]);
                        if (dolog) console.log('range ' + v[j])
                    }
                    //references.push(refResult[1]);

                }
                // while (refResult = cellRefRe.exec(fSegments[i])) {
                //     console.log('refResult[1] ' + refResult[1])
                //     var v2 = refResult[1].split(':');

                //     for (var j = 0; j < v2.length; j++) {
                //         if (dolog) console.log('v ' + v2[j])
                //         references.push(v2[j]);
                //     }
                //     //           references.push(refResult[1]);
                // }

                while (refResult = re.exec(fSegments[i])) {
                    //     if (dolog) console.log('refResult[1] ' + refResult[1])
                    var v2 = refResult[1].split(':');

                    for (var j = 0; j < v2.length; j++) {
                        //      if (dolog) console.log('re ' + v2[j])
                        references.push(v2[j]);
                    }
                    //           references.push(refResult[1]);
                }

            }
            if (dolog) console.log(references);
        } catch (err) {
            console.log(err);
            return null;
        }
        return references;
    }

    public static isinlist(list, value) {
        for (var i = 0; i < list.length; i++) {
            if (list[i] == value) return true;
        }
        return false;
    }

    static async copyFormulas(excels: any, sheetIndex: number): Promise<void> {
        try {
            const promisesResult = await excels.getSheetByIndex(sheetIndex);
            const dataString = promisesResult.cellvalues.map(row => row.join("\t")).join("\n");
            await navigator.clipboard.writeText(dataString);
            console.log('Formulas copied to clipboard');
        } catch (error) {
            console.error('Error copying formulas:', error);
        }
    }
}


export { Utils };