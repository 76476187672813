<!-- <select [(ngModel)]="selectedFile" (change)="changeFile(selectedSheet)" class="form-control form-control-sm col-sm-4">
    <option *ngFor="let c of files">{{c}}</option>
</select>

<select [(ngModel)]="selectedSheet" class="form-control form-control-sm col-sm-4" (change)="changeFile(selectedSheet)">
    <option *ngFor="let c of filesheets[selectedFile]">{{c}}</option>
</select>

<input type="text" style="margin:3px 0;" class="form-control form-control-sm col-sm-6" [(ngModel)]="hotformula"
    #ctrl="ngModel" />

<button (click)="changeFile(selectedSheet)">aktualisieren</button>-->
<!-- <button (click)="test()">test</button> -->

<!-- <div style="position: absolute;z-index: 999; left:0;top:0;background:white;color:blue">{{this.editcell|json}}</div> -->

<!-- Auth Top Bar component -->
<app-auth-top-bar style="position: absolute;z-index: 999; left:0;top:0;" [configmodel]="project?.configmodel" [configid]="configid" [isAuthenticated]="isAuthenticated" [isEditMode]="isEditMode"
    (saveConfig)="saveconfig()" (exportScene)="exportScene()" (zoomAll)="zoomall()" (toggleCamera)="toggleCameraView()" (takeScreenshot)="takeScreenshot(false)" (createReport)="generateReport()"
    (createPartList)="createPartList()">
</app-auth-top-bar>

<div [style.background-color]="isDarkMode ? '#1e1e1e' : '#ffffff'" style="width:100%; height: 100%;">
    <as-split direction="horizontal" [restrictMove]="restrictMove" (dragEnd)="splitDragEnd($event)">
        <as-split-area [size]="splitleft" [visible]="leftpanelvisible">

            <!-- <div style="color:white;background-color:rgb(12, 105, 12)"> -->
            <div class="menubar" (click)="switchMode()">
                <a href="/dashboard"><img src="assets/sheetbuildlogo.svg" style="height:42px; padding:7px 16px 6px 16px;float:left; cursor:pointer;" /></a>

                <div (click)="showprojectinfo = !showprojectinfo" style="cursor:pointer;padding: 10px 20px 8px 2px;color:white;float:left;max-width:220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{project?.name}}</div>

                <div [hidden]="!showprojectinfo"
                    style="    position: absolute;    z-index: 973;    background-color: white;    left: 10px;    top: 30px;    width: 320px;    font-size:12px;    color: black;    padding: 10px 18px;;">
                    <label>Project Name:</label><br>
                    <input *ngIf="project" [(ngModel)]="project.name" class="form-control" />
                    <br>
                    <div class="btn btn-default savebtn" #btnclone [class.savebtn-disabled]="isCloning" (click)="cloneproject()" style="float:left;float: left;margin: 4px 0px; font-size: 22px; padding: 0px 9px;">
                        <i *ngIf="!isCloning" class="fas fa-copy"></i>
                        <div *ngIf="isCloning" class="spinner-border spinner-border-sm" role="status" style="width: 1rem; height: 1rem; color: #b84eff;">
                            <span class="sr-only">Copying...</span>
                        </div>
                        Save Copy
                    </div>

                    <mat-checkbox [(ngModel)]="isEditMode">Edit mode</mat-checkbox>
                    <div style="margin-top: 10px; padding: 5px 0; border-top: 1px solid #e7e7e7;">
                        <input type="checkbox" [(ngModel)]="debugInfos" />
                        <label>Debug Infos</label>
                    </div>
                    <br>
                    <label>Project Thumbnail</label><br>
                    <img [src]="project?.image" style="height:120px;" /><br>
                    <button class="btn btn-default" (click)="takeScreenshot(true)">Screenshot</button>


                    <div *ngIf="isLocalhost()">
                        <button class="btn btn-default" (click)="getactiveconfig()">active Config</button>
                        <button class="btn btn-default" (click)="overwriteconfig()">overwrite Config</button>
                    </div>

                    <!-- Theme Toggle -->
                    <div style="margin-top: 10px; padding: 5px 0; border-top: 1px solid #e7e7e7;">
                        <label style="display: flex; align-items: center; cursor: pointer;" (click)="toggleTheme(); $event.stopPropagation()">
                            <span style="flex-grow: 1;">Theme</span>
                            <i class="fas" [class.fa-sun]="isDarkMode" [class.fa-moon]="!isDarkMode" style="margin-left: 8px;"></i>
                            <span style="margin-left: 8px;">{{isDarkMode ? 'Dark' : 'Light'}}</span>
                        </label>
                    </div>




                    <!-- <prettyjson [obj]="project?.configmodel | excludeProps:['sheets', 'disclaimer', 'colWidths']">
                    </prettyjson> -->


                </div>

                <div *ngIf="!impersonationId" [class.savebtn]="!isSaving" [class.savebtn-disabled]="isSaving" (click)="save()" style="float:left;float: left;margin: 4px 0px; font-size: 22px; padding: 0px 9px;">
                    <i *ngIf="!isSaving && !saveSuccess" class="fas fa-save"></i>
                    <i *ngIf="saveSuccess" class="fas fa-check save-success-icon"></i>
                    <div *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" style="width: 1rem; height: 1rem; color: #b84eff;">
                        <span class="sr-only">Saving...</span>
                    </div>
                </div>

                <div *ngIf="impersonationId" style="font-weight:bold;color:white;background:red;padding:2px 5px; border-radius: 3px;;">
                    Impersonation

                    <div style="background-color: white;color:black;" (click)="copyImpersonated()">copy to own</div>
                </div>


                <div class="savebtn" (click)="undo()" style="float:left;float: left;margin: 4px 0px; font-size: 22px; padding: 0px 9px;">
                    <i class="fa-solid fa-undo"></i>
                    <!-- <span class="mnuiconlabel">Undo</span> -->
                </div>
                <div class="savebtn" (click)="redo()" style="float:left;float: left;margin: 4px 0px; font-size: 22px; padding: 0px 9px;">
                    <i class="fa-solid fa-redo"></i>
                    <!-- <span class="mnuiconlabel">Redo</span> -->
                </div>


                <div class="mnuhead" style="float:left;">
                    <div [ngClass]="(mnuSelect==2)?'mnuactive':'mnunotactive'" (click)="mnuSelect=2">Editor</div>
                    <!-- <div [πngClass]="(mnuSelect==1)?'mnuactive':'mnunotactive'" (click)="mnuSelect=1">Snippets</div> -->
                    <div [ngClass]="(mnuSelect==3)?'mnuactive':'mnunotactive'" (click)="mnuSelect=3">Scene</div>
                    <!-- <div [ngClass]="(mnuSelect==4)?'mnuactive':'mnunotactive'" (click)="mnuSelect=4">Publish</div> -->
                </div>

                <div style="position:relative;float:right;top:7px;">


                    <button (click)="openDialog()" class="btn btn-primary btn-sm" style="padding: 4px 6px;   border-radius: 3px; color: white !important;;   margin-right: 9px;    font-size: 12px;   background: #b84eff;
    border: #b84eff;"><i class="fa-solid fa-arrow-up-right-from-square"></i>&nbsp;Publish</button>
                </div>
                <!-- 
                <div style="float:right; padding:5px 10px;cursor:pointer;position:relative;" (click)="showaccountmenu=!showaccountmenu;$event.stopPropagation()   "><i class="fa-solid fa-circle-user"></i>&nbsp;
                    <div id="accountmenu" [hidden]="!showaccountmenu" style="position:absolute;z-index:2;background-color:white;padding:2px 2px;right:0px;">
                        <div class="contextmenuitem" [routerLink]="'/settings'"><i class="fa fa-window"></i> Settings
                        </div>
                        <div class="contextmenuitem" (click)="logout()"><i class="fa fa-door"></i> Logout</div>
                    </div>
                </div> -->

                <div style="clear:both;"></div>

                <div class="mnu">
                    <!-- snippets Tab -->
                    <!-- <div *ngIf="mnuSelect==1" class="">
                        <div class="btn btn-default" (click)="test()"><i class=" fa fab fas fa-tasks"></i><br>Box
                            Template
                        </div>
                        <div class="btn btn-default" (click)="applyCellMeta()"><i
                                class="fa fab fas fas fa-list"></i><br>Extrusion on Last Row</div>
                        <div class="btn btn-default"><i class="fa fab fas fas fa-tasks"></i><br> Camera Template</div>

                        <div class="btn btn-default btn-sm" (click)="addSnippet('box')"><i
                                class="fa fab fas fa-table"></i>Box</div>
                        <div class="btn btn-default  btn-sm"><i class="fa fab fas fa-table"></i>Handling Template</div>

                        <div class="btn btn-default btn-xs"><i class="fa fab fas fa-tasks"></i><br>Other</div>

                    </div> -->

                    <!-- table Tab -->
                    <div *ngIf="mnuSelect==2 && project" class="">

                        <div class="mnutextdivider">SHEETS</div>


                        <!-- Add AI Agent component with visibility control -->
                        <app-ai-agent *ngIf="showAIAgent" [excelData]="dataset" [cadEngine]="cad" [scene]="scene" (suggestionApplied)="handleAISuggestion($event)" (windowClosed)="showAIAgent = false"
                            (windowMinimized)="minimizeAIAgent()" [style.display]="isAIAgentMinimized ? 'none' : 'block'">
                        </app-ai-agent>

                        <div style="display: inline-block;widt3h:80px;he2ight:100px;zoom:0.9">
                            <div style="position: relative;top: -24px;left:-5px;he2ight:80px; width:100px;">
                                <!-- interaction -->
                                <mat-checkbox (change)="changeHandling()" [(ngModel)]="project.settings.highlightrow" class="mnucheckbox" style="position:absolute;top:12px;left:0px;color:black; height:10px">
                                    Highlight Row</mat-checkbox>
                                <mat-checkbox (change)="changeHandling()" [(ngModel)]="project.settings.autozoomrow" class="mnucheckbox" style="position:absolute;top:33px;left:0px;color:black; height:10px">
                                    Autozoom Row</mat-checkbox>
                                <div class="mnubtnsm btn " (click)="zoomall()" style="position:relative;top:41px;">
                                    <i style="font-size:12px;" class="mnuiconsm fa-solid fa-magnifying-glass"></i>
                                    &nbsp; <span class="mnuiconlabel"> Zoom</span>
                                </div>

                            </div>

                        </div>

                        <div style="display:inline-block;vertical-align:middle;margin-left: 20px;" *ngIf="project">

                            <div class="btn mnubtnsm" (click)="showTableMenu=!showTableMenu;$event.stopPropagation()">
                                <i class="mnuiconsm fa fab fas fa-tasks" style="    font-size: 15px;position: relative;  top: 2px;"></i>
                                <!-- <br> -->
                                &nbsp;<span class="mnuiconlabel" style="font-size:14px;">Table Style&nbsp;<i class="fa fa-caret-down"></i></span>
                            </div><br>
                            <div [hidden]="!showTableMenu" class="box-shadow dialogwindow" (click)="$event.stopPropagation()" style="
                                    position: absolute;    width: 159px;    top: 72px;  left: 140px;    padding: 8px 17px;
                                    border-radius: 5px;     z-index: 999;    ">

                                <!--                                 <mat-checkbox class="mnucheckbox" style="position:abs2olute;to2p:-12px;height:10px;"
                                    (click)="(fixedRowsTop==0)?fixedRowsTop=1:fixedRowsTop=0">
                                    Fixed Headers
                                </mat-checkbox>
 --><label> Fixed Rows</label>
                                <input type="number" class="form-control" [(ngModel)]="project.settings.fixedRowsTop" />

                                <!-- <span style="font-size:1px;"><br></span> -->
                                <mat-checkbox class="mnucheckbox" style="positi2on:absolute;to2p:8px; height:10px"
                                    (click)="project.settings.fixedColumnsLeft==0?project.settings.fixedColumnsLeft=1:project.settings.fixedColumnsLeft=0">
                                    Fixed 1st Column
                                </mat-checkbox>

                                <mat-checkbox class="mnucheckbox" (change)="stylesoverride($event)" style="ma2rgin-right: 7px; ma2rgin-left: 7px;t2op: 6px;height: 10px; position: relative;"
                                    [checked]="project?.settings?.stylesoverride">
                                    override styles
                                </mat-checkbox>

                                <!-- <mat-checkbox class="mnucheckbox" style="positio2n:absolute;t2op:29px;color:black; height:10px" (click)="showComments==0?showComments=1:showComments=0">
                                Show Comments
                            </mat-checkbox> -->

                            </div>


                            <!-- <div class="mnubtnsm btn " (click)="showSceneSettings=!showSceneSettings">
                                <i class="mnuiconsm fa-solid fa-panorama"></i>
                                &nbsp; <span class="mnuiconlabel">Scene&nbsp;<i class="fa fa-caret-down"></i></span>
                            </div> -->
                            <div class="mnucolorbtn" style="display:inline-block" (click)="mnucolorvisible=!mnucolorvisible;$event.stopPropagation()">
                                <i style=" margin-left:8px" class="fa-solid fa-fill coloricon"></i>
                                <i *ngIf="color1" class="fa-solid fa-xmark" style="margin-left:2px;cursor:pointer;font-size:10px;color:#666" (click)="resetCellBackground();$event.stopPropagation()"></i>
                                <br>
                                <div style="position:relative;margin:2px;width:26px;height:8px;" class="dialogwindow" [style.backgroundColor]="color1">
                                    <div style="left:0px;top:12px;width: 245px; display: inline-block; position:absolute;z-index:999" [hidden]="!mnucolorvisible">
                                        <color-compact [(ngModel)]="color1" (color)="state" (onChangeComplete)="setCellBackground($event)" (onChange)="setCellBackground($event)"></color-compact>
                                    </div>
                                </div>

                            </div>
                            <div class="mnucolorbtn" style="display:inline-block" (click)="mnucolorvisible2=!mnucolorvisible2;$event.stopPropagation()">
                                <i style=" margin-left:8px" class="fa-solid fa-font coloricon"></i>
                                <i *ngIf="color2" class="fa-solid fa-xmark" style="margin-left:2px;cursor:pointer;font-size:10px;color:#666" (click)="resetCellForeground();$event.stopPropagation()"></i>
                                <br>
                                <div style="position:relative;margin:2px;width:26px;height:8px;" class="dialogwindow" [style.backgroundColor]="color2">

                                    <div style="left:-2px;top:12px;width: 245px; display: inline-block; position:absolute;z-index:999" [hidden]="!mnucolorvisible2">
                                        <color-compact [(ngModel)]="color2" [color]="state" (onChangeComplete)="setCellForeground($event)" (onChange)="setCellForeground($event)"></color-compact>
                                    </div>
                                </div>
                            </div>


                            <button class="mnubtn btn" (click)="dlgresizetable = !dlgresizetable" style="margin-left:5px;">
                                <i class="fa fa-expand-arrows-alt" style="    font-size: 15px;position: relative;  top: 2px;"></i> <span style="font-size: 14px">&nbsp;Resize</span>
                            </button>
                            <div *ngIf="dlgresizetable" style="margin-left:0px;padding:5px 0px;background:rgb(43, 43, 43);border-radius:5px;position:absolute;top:105px;left:220px;width:180px;hei2ght:20px;z-index:9999;">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <div class="col">
                                        <label style="position:relative;top:1px;left:1px;font-size:12px;">Columns</label>
                                        <input type="number" class="form-control form-control-sm" [(ngModel)]="additionalColumns" />
                                    </div>
                                    <div class="col">
                                        <label style="position:relative;top:1px;left:1px;font-size:12px;">Rows</label><br>
                                        <input type="number" class="form-control form-control-sm" [(ngModel)]="additionalRows" />
                                    </div>
                                </div>
                                <button class="btn btn-primary btn-sm" style="padding: 4px 6px;  float:right;margin-right:16px;margin-bottom:10px; border-radius: 3px; color: white !important;;  margin-top:10px;    font-size: 12px;   background: #b84eff;
                                border: #b84eff;" (click)="resizeTables()">Add Columns and Rows</button>

                            </div>

                        </div>
                        <!-- <div class="mnubtn btn" (click)="showTableMenu=!showTableMenu;$event.stopPropagation()">
                            <i class="mnuicon fa fab fas fa-tasks"></i><br><span class="mnuiconlabel">Table Style</span>
                        </div> -->


                        <!-- <div class="mnutextdivider">SCENE</div> -->



                        <div class="mnubtn btn" style="width: 0px;height:62px;">
                        </div>


                        <div class="mnutextdivider">EDITORS</div>


                        <div [hidden]="!showGUIEditor" cdkDrag [cdkDragDisabled]="disableEditorDrag" m22Resizable class="box-shadow dialogwindow" style="    padding: 1px 3px;    position: absolute;    z-index: 999;   
                            width: 700px;    height: 750px;    left: 100px;    border: 1px solid ;  color:black;  border-radius: 2px;">
                            <div style="width:100%;background:rgb(88, 2, 101);height:24px;color:white;">
                                <div style="    float: left;padding: 2px 11px;font-size: 13px;">CSS Styles</div>
                                <div style="padding: 0px 9px;float:right;cursor:pointer;" (click)="showGUIEditor=false">
                                    <i class="fa-solid fa-xmark"></i>
                                </div>
                            </div>
                            <div [ngStyle]="{'height': 'calc(100% - 34px)'}">
                                <ngx-monaco-editor *ngIf="project" (mouseenter)="disableEditorDrag=true" (mouseleave)="disableEditorDrag=false" id="editor1" (ngModelChange)="csschange($event)"
                                    [options]="csseditorOptions" [(ngModel)]="project.csscode" (init)="csseditorInit($event)">
                                </ngx-monaco-editor>
                            </div>

                        </div>

                        <div class="mnubtn btn" (click)="showScriptsEditor=!showScriptsEditor">
                            <i class="mnuicon fa-solid fa-code" style="    font-size: 20px;    margin-bottom: -5px;    margin-top: 2px;    position: relative;    top: 3px;"></i><br>
                            <span class="mnuiconlabel">Scripts</span>
                        </div>
                        <div [hidden]="!showScriptsEditor" cdkDrag [cdkDragDisabled]="disableEditorDrag" m22Resizable class="box-shadow dialogwindow" style=" padding: 1px 3px;    position: absolute;    z-index: 999;   
                                                                            width: 600px;    height: 490px;    left: 100px;    border: 1px solid ;  color:black;  border-radius: 2px;">

                            <div style="width:100%;background:rgb(88, 2, 101);height:24px;color:white;">
                                <div style="    float: left;padding: 2px 11px;font-size: 13px;">Javascript</div>


                                <div (click)="showcodemenu=!showcodemenu" style="     float: left;cursor:pointer;   padding: 1px 6px;  font-size: 12px;  background: rgb(77, 74, 74); border-radius: 3px;margin-top: 3px;">
                                    Templates <i class="fa far fa-caret-square-down"></i></div>

                                <div *ngIf="showcodemenu" style="position:relative">
                                    <div style="    padding: 2px 0px;  cursor: pointer; color: #dedede;  position: absolute;  background: #303131;
                                    z-index: 3;   left: 70px;   top: 23px;   border-radius: 6px;">
                                        <div style="padding:0px 6px;" class="codemenu" *ngFor="let s of snippetManager.codeSnippets" (click)="addCodeSnippet(s);showcodemenu=false;">{{s.name}}</div>
                                    </div>

                                </div>

                                <div style=" padding: 0px 9px;float:right;cursor:pointer;" (click)="showScriptsEditor=false"><i class="fa-solid fa-xmark"></i></div>
                                <a href="https://sheetbuild.com/documentation" target="_blank" style="padding: 0px 9px;float:right;cursor:pointer;color:white;"><i class="fa-solid far fa-question-circle"></i></a>
                                <div style=" float: right; padding: 0px 4px;font-size: 11px;background: red; border-radius: 3px;margin-top: 4px;">
                                    Beta</div>
                            </div>
                            <div [ngStyle]="{'height': 'calc(100% - 34px)'}">
                                <ngx-monaco-editor *ngIf="project" (mouseenter)="disableEditorDrag=true" (mouseleave)="disableEditorDrag=false" style="height:100%" [options]="scripteditorOptions"
                                    [(ngModel)]="project.scriptcode" (init)="scripteditorInit($event)">
                                </ngx-monaco-editor>

                            </div>

                        </div>

                        <!-- AI Prompt Editor -->
                        <div class="mnubtn btn" (click)="showAIPromptEditor=!showAIPromptEditor">
                            <i class="mnuicon fa-solid fa-robot" style="font-size: 20px; margin-bottom: -5px; margin-top: 2px; position: relative; top: 3px;"></i><br>
                            <span class="mnuiconlabel">AI Prompt</span>
                        </div>
                        <app-ai-prompt-editor #aiPromptEditor [showAIPromptEditor]="showAIPromptEditor" [PROJECT]="PROJECT" [sheetid]="sheetid" [excels]="excels" [renderer]="renderer" [scene]="scene" [camera]="camera"
                            [sheetformulas]="cellformulas" [project]="project" (copyTableData)="handleTableDataCopy($event)" (aiPromptChangee)="aiPrompt=$event" (aiIterationChange)="handleAIITeration($event)"
                            (chatHistoryChange)="saveChatHistory($event)"></app-ai-prompt-editor>

                        <!-- Add AI Agent toggle button -->
                        <!-- <div class="mnubtn btn" (click)="toggleAIAgent()">
                            <i class="mnuicon fa-solid fa-robot" [style.color]="showAIAgent ? '#b84eff' : ''"></i><br>
                            <span class="mnuiconlabel">AI Assistant</span>
                        </div> -->


                        <div class="mnubtn btn" (click)="showGUIEditor=!showGUIEditor">
                            <i class="mnuicon fa-brands fa-css3-alt" style="    font-size: 20px;    margin-bottom: -5px;    margin-top: 2px;    position: relative;    top: 3px;"></i><br>
                            <span class="mnuiconlabel">Styles</span>
                        </div>



                    </div>

                    <!-- ----------------- Scene Tab ----------------- -->
                    <div *ngIf="mnuSelect==3" class="">

                        <div class="mnutextdivider" style="font-size:12px;">SCENE</div>

                        <div style="display: inline-block;vertical-align:middle;width:80px;">
                            <mat-checkbox class="mnucheckbox" style="position:relative;top:1px;color:black; height:10px" [(ngModel)]="project.sceneSettings.showAnimations" (change)="animate(0)">
                                Animate
                            </mat-checkbox>

                            <mat-checkbox class="mnucheckbox" style="    position: relative;    top: -6px;    color: black;    height: 10px;    float: left;" [(ngModel)]="project.sceneSettings.showHelpers">
                                Helpers
                            </mat-checkbox>
                            <mat-checkbox class="mnucheckbox" style="position: relative;   top: 2px;    color: black;   height: 10px;  " [(ngModel)]="project.sceneSettings.showGrid" (change)="gridchange()">
                                Grid/Axes
                            </mat-checkbox>
                        </div>
                        <!-- <div style="display:inline-block;vertical-align:middle;">

                            <button (click)="setIsometricView()"><i class="fas fa-dice-d6"></i></button><br>
                            <button (click)="resetToPerspective()"><i class="fas fa-cube"></i></button>

                        </div>
                        <div style="display:inline-block;vertical-align:middle;">
                            <button (click)="zoomallscene()"><i class="fas fa-search-plus"></i></button><br>
                        </div> -->

                        <!-- <div class="mnubtn btn" style="width: 0px;height:62px;" (click)="test()">
                            ddd
                        </div> -->
                        <div style="display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="scm=!scm;$event.stopPropagation();">
                                <i class=" fa fab fas far fas fa-video" style="fon2t-size:14px;"></i>
                                <br>
                                &nbsp;<span class="mnuiconlabel">Camera {{currentcamindex}}&nbsp;<i class="fa fa-caret-down"></i></span>
                            </div>
                            <div *ngIf="scm" class="box-shadow dialogwindow" style="    position: absolute;
                                     border-radius: 2px;   padding: 2px 4px;    z-index: 999;    top: 110px;    left: 126px;    width: 180px;    font-size: 14px;">
                                <div class="mnubtn" *ngFor="let c of project.cameras; let i=index;" (click)="loadCamera(i)">
                                    Camera {{i+1}}</div>

                                <div style="float: right; font-size:12px; color:white!important;  padding: 7px 19px;    margin:10px 10px;    border-radius: 4px;" class=" btn btn-primary" (click)="addCamera()">
                                    <i class="fa fas fa-plus"></i> Add Current View
                                </div>

                            </div>
                        </div>

                        <div style="display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="ss=!ss;$event.stopPropagation();">
                                <i class="fa-solid fa-panorama"></i><br>
                                <span class="mnuiconlabel">Scene <i class="fa fa-caret-down"></i></span>
                            </div>
                            <div [hidden]="!ss" (click)="$event.stopPropagation();" class="box-shadow dialogwindow" style="   padding: 6px 23px;    position: absolute;    z-index: 9999;   
                                width: 500px;    height: 490px;    left: 150px;     border-radius: 2px;">

                                <div style="height:20px"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h2 style="font-weight:bold;font-size:22px;">Scene Settings</h2>
                                    </div>
                                </div>
                                <hr style="margin-bottom:12px;">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5 style="font-weight:bold">General</h5>
                                        <!-- <label>Exposure</label>
                                        <mat-slider min="0" max="1" step="0.05" value="value" (input)="project.sceneSettings.directionalIntensity= $event.value;updateScene($event)" [(ngModel)]="sceneSettings.exposure"></mat-slider><br> -->

                                        <label>Background 1</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.background1" [style.background]="project.sceneSettings.background1"
                                            [(colorPicker)]="project.sceneSettings.background1" (colorPickerChange)="updateScene($event)" />
                                        <label>Background 2</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.background2" [style.background]="project.sceneSettings.background2"
                                            [(colorPicker)]="project.sceneSettings.background2" (colorPickerChange)="updateScene($event)" />

                                        <div style="height:40px"></div>
                                        <h5 style="font-weight:bold">Ambient Light</h5>
                                        <label>Intensity</label>
                                        <mat-slider min="0" max="10" step="0.05" value="value" (input)="project.sceneSettings.ambientIntensity= $event.value;updateScene($event)"
                                            [(ngModel)]="project.sceneSettings.ambientIntensity"></mat-slider>{{project.sceneSettings.ambientIntensity}}<br>
                                        <label>Color</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.ambientColor" [style.background]="project.sceneSettings.ambientColor"
                                            [(colorPicker)]="project.sceneSettings.ambientColor" (colorPickerChange)="updateScene($event)" />

                                        <div style="height:40px;"></div>
                                        <!-- <h5>Environment Map</h5>
                                        <select class="form-select" [(ngModel)]="project?.sceneSettings.envMap" name="item">

                                            <option *ngFor="let item of envMaps" [value]="item.value">
                                                {{item.value}}
                                            </option>

                                        </select> -->



                                    </div>
                                    <div class="col-md-6">
                                        <h5 style="font-weight:bold">Directional Light</h5>
                                        <label>Intensity</label>
                                        <mat-slider min="0" max="10" step="0.05" value="value" (input)="project.sceneSettings.directionalIntensity= $event.value;updateScene($event)"
                                            [(ngModel)]="project.sceneSettings.directionalIntensity"></mat-slider>{{project.sceneSettings.directionalIntensity}}<br>
                                        <label>Color</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.directionalColor" [style.background]="project.sceneSettings.directionalColor"
                                            [(colorPicker)]="project.sceneSettings.directionalColor" (colorPickerChange)="updateScene($event)" />
                                        <br>
                                        <label>Position</label>
                                        <app-circle [angle]="project.sceneSettings.dirAngle" (angleChanged)="handleAngleChange($event)"></app-circle>

                                        <label>Height (Z-Position)</label>
                                        <mat-slider min="0" max="30000" step="1000" value="value" (input)="project.sceneSettings.dirLightZ= $event.value;updateScene($event)"
                                            [(ngModel)]="project.sceneSettings.dirLightZ"></mat-slider>{{project.sceneSettings.dirLightZ}}<br>

                                        <div style="height:40px;"></div>
                                        <h5 style="font-weight:bold">Hemisphere</h5>
                                        <label>Intensity</label>
                                        <mat-slider min="0" max="10" step="0.05" value="value" (input)="project.sceneSettings.hemisphereIntensity= $event.value;updateScene($event)"
                                            [(ngModel)]="project.sceneSettings.hemisphereIntensity"></mat-slider>{{project.sceneSettings.hemisphereIntensity}}<br>
                                        <label>Color 1</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.hemisphereColor1" [style.background]="project.sceneSettings.hemisphereColor1"
                                            [(colorPicker)]="project.sceneSettings.hemisphereColor1" (colorPickerChange)="updateScene($event)" /><br>
                                        <label>Color 2</label>
                                        <input class="cpinput" name="test" [value]="project.sceneSettings.hemisphereColor2" [style.background]="project.sceneSettings.hemisphereColor2"
                                            [(colorPicker)]="project.sceneSettings.hemisphereColor2" (colorPickerChange)="updateScene($event)" />

                                    </div>


                                </div>

                            </div>
                        </div>








                        <!-- <div class="" style="display: inline-block;width: 0px;height:62px;">
                        </div> -->

                        <div class="mnutextdivider">VIEW</div>
                        <div style="display: inline-block;width:55px;">
                            <!-- <mat-checkbox class="mnucheckbox" style="position:relative;top:6px;left:11px;color:black;height:10px; float:left;" [(ngModel)]="project.sceneSettings.showControls">
                                                                                        Menu
                                                                                    </mat-checkbox> -->



                            <div class="mnubtn btn" (click)="openMenuDialog()">
                                <i class="mnuicon fab fa-elementor" style="    font-size: 20px;    margin-bottom: -5px;    margin-top: 2px;    position: relative;    top: 3px;"></i><br>
                                <span class="mnuiconlabel">Menu</span>
                            </div>






                            <!-- <div style="position: relative;top: -24px;left:-0px;" *ngIf="project.sceneSettings">

                                <mat-checkbox class="mnucheckbox" style="position:absolute;top:-12px;color:black; height:10px" [(ngModel)]="project.sceneSettings.showAnimations" (change)="animate()">
                                    Animate
                                </mat-checkbox>
                                <mat-checkbox class="mnucheckbox" style="position:absolute;top:29px;color:black; height:10px" [(ngModel)]="project.sceneSettings.showHelpers">
                                    Helpers
                                </mat-checkbox>
                                <mat-checkbox class="mnucheckbox" style="position:absolute;top:8px;color:black; height:10px" [(ngModel)]="project.sceneSettings.showGrid" (change)="gridchange()">
                                    Grid/Axes
                                </mat-checkbox>
                            </div> -->
                        </div>



                        <div class="mnutextdivider" style="font-size: 11px;">TOOLS</div>


                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" [style.background]="scenemode=='select' ? '#e2ccf0':''" (click)="scenemode='select'">
                                <i class="fab fas fa-mouse-pointer"></i><br>
                                <span class="mnuiconlabel">Select</span>
                            </div>
                        </div>
                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" [style.background]="scenemode=='info' ? '#e2ccf0':''" (click)="scenemode='info'">
                                <i class="fab fas fa-info-circle"></i><br>
                                <span class="mnuiconlabel">Info</span>
                            </div>
                        </div>
                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" [style.background]="scenemode=='move' ? '#e2ccf0':''" (click)="scenemode='move'">
                                <i class="fab fas fa-arrows-alt"></i><br>
                                <span class="mnuiconlabel">Move</span>
                            </div>
                        </div>


                        <!-- <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="exportToSTEP()">
                                <i class="fab fas fa-file-download"></i><br>
                                <span class="mnuiconlabel">Export</span>
                            </div>
                        </div> -->

                        <!-- <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="createPartList()">
                                <i class="fas fa-list"></i><br>
                                <span class="mnuiconlabel">Part List</span>
                            </div>
                        </div>

                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="generateReport()">
                                <i class="fas fa-file-word"></i><br>
                                <span class="mnuiconlabel">Report</span>
                            </div>
                        </div> -->

                        <!-- Camera Flight Controls -->
                        <!-- <div class="mnutextdivider" style="font-size: 11px;">CAMERA</div>

                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="startCameraFlight(10000, false)">
                                <i class="fas fa-film"></i><br>
                                <span class="mnuiconlabel">Orbit</span>
                            </div>
                        </div>

                        <div style="margin:4px 1px;display: inline-block;vertical-align:middle;">
                            <div class="mnubtn btn" (click)="stopCameraFlight()">
                                <i class="fas fa-stop"></i><br>
                                <span class="mnuiconlabel">Stop</span>
                            </div>
                        </div> -->

                        <!-- <div class="mnubtn btn" (click)="showGUIEditor=!showGUIEditor">
                            <i class="mnuicon fa-brands fa-css3-alt"></i><br>
                            <span class="mnuiconlabel">Gltf Viewer</span>
                        </div> -->



                    </div>

                    <!-- publish Tab -->
                    <div *ngIf="mnuSelect==4" class="">
                        <div class="btn btn-default"><i class="fa fab fas fab fa-first-order"></i><br>GUI Settings</div>
                    </div>
                </div>
            </div>

            <input type="text" [(ngModel)]="curSelectionReadable" style="float:left;width:80px;" class="form-control form-control-sm " />
            <button type="button" class="btn btn-sm btn-outline-secondary" style="float: left; height: 31px; border: 1px solid #404040; padding: 1px 4px; background-color: #1e1e1e; color: #e0e0e0;"
                (click)="openfunctionsdialog()">f(x)</button>
            <!-- formula input -->
            <input type="text" [(ngModel)]="formula" style="width: calc(100% - 120px); " class="form-control form-control-sm " (change)="formulaChange($event)" (ngModelChange)="formulaChange($event)"
                (blur)="editformula=false" (focus)="editformula=true" (keydown.enter)="cellinput($event)" />

            <div style="display: flex; flex-wrap: wrap; gap: 4px;min-height: 29px;">
                <div *ngFor="let ref of refnames" style="background: #2f2f2f; color: #f2f2f2; padding: 4px 12px; border-radius: 16px; font-size: 14px;">
                    {{ref}}</div>
                <span *ngIf="refnames2?.length > 0" [style.color]="isDarkMode ? '#f2f2f2' : '#333333'">Dependents</span>
                <div *ngFor="let ref of refnames2" [style.background-color]="isDarkMode ? '#2f2f2f' : '#e0e0e0'" [style.color]="isDarkMode ? '#f2f2f2' : '#333333'"
                    style="padding: 4px 12px; border-radius: 16px; font-size: 14px;">
                    {{ref}}</div>

            </div>

            <!-- Search UI for Handsontable -->
            <div class="table-search-container" *ngIf="isSearchBarVisible" [style.background-color]="isDarkMode ? '#2d2d2d' : '#f5f5f5'">
                <div class="search-input-container">
                    <input type="text" [(ngModel)]="searchQuery" placeholder="Search in table..." class="form-control form-control-sm search-input" (keyup.enter)="searchInTable()"
                        [style.background-color]="isDarkMode ? '#3d3d3d' : '#ffffff'" [style.color]="isDarkMode ? '#e0e0e0' : '#333333'" />
                    <button class="btn btn-sm search-button" (click)="searchInTable()" [style.background-color]="isDarkMode ? '#4d4d4d' : '#e0e0e0'">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <div class="search-navigation">
                    <span *ngIf="searchResults.length > 0" class="search-count">
                        {{currentSearchResultIndex + 1}} of {{searchResults.length}}
                    </span>
                    <button class="btn btn-sm nav-button" (click)="navigateToPreviousSearchResult()" [disabled]="searchResults.length === 0" [style.background-color]="isDarkMode ? '#4d4d4d' : '#e0e0e0'">
                        <i class="fas fa-chevron-up"></i>
                    </button>
                    <button class="btn btn-sm nav-button" (click)="navigateToNextSearchResult()" [disabled]="searchResults.length === 0" [style.background-color]="isDarkMode ? '#4d4d4d' : '#e0e0e0'">
                        <i class="fas fa-chevron-down"></i>
                    </button>
                    <button class="btn btn-sm clear-button" (click)="clearSearch()" [style.background-color]="isDarkMode ? '#4d4d4d' : '#e0e0e0'">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>

            <div class="hot-container" *ngIf="project" style="background-color: #1e1e1e;">
                <div *ngIf="selectedProjectName && selectedProjectName!='Main'" [style.width]="leftpanelwidth+'px'" style="left:-10px;height:100%;overflow:auto;position:absolute;z-index:99999;background: #0709095e;">
                    <div style="text-align: center; width: 100%; padding: 10px;font-size: 22px;color:rgb(211, 211, 211)">
                        <span>Subproject selected:
                            {{selectedSubprojectID.split('_').pop()}}
                            <br>
                            <a class="btn btn-default" style="color:white; border-radius:3px;" target="_blank" [href]="'/e/'+selectedSubprojectID.split('_').pop()">Edit in new tab</a>
                        </span>
                    </div>


                </div>

                <hot-table *ngIf="dataset && leftpanelvisible" [hotId]="hotid" enabled="false" [afterSelection]="cellSelected" [width]="leftpanelwidth" [afterCopy]="afterCopy"
                    [afterDocumentKeyDown]="afterDocumentKeyDown" [beforePaste]="beforePaste" [beforeCut]="beforeCut" [afterPaste]="afterPaste" [afterCut]="afterCut" [afterChange]="afterChange"
                    [beforeAutofill]="beforeAutoFill" [autoColumnSize]="true" [afterAutofill]="afterAutoFill" [beforeChange]="beforeChange" [afterSelectionEnd]="afterSelectionEnd" [outsideClickDeselects]="false"
                    [manualColumnResize]="true" [afterOnCellMouseOver]="afterOnCellMouseOver" [beforeOnCellMouseDown]="beforeCellMouseDown" [beforeKeyDown]="beforekeydown" [afterOnCellMouseOut]="afterOnCellMouseOut"
                    [afterSetDataAtRowProp]="afterSetDataAtRowProp" [colWidths]="project?.sheets[sheetid]?.colWidths" [afterBeginEditing]="afterBeginEditing" [afterDeselect]="afterDeselect" [rowHeaders]="true"
                    [data]="dataset" [renderer]="'customStylesRenderer'" [colHeaders]="true" [fixedColumnsLeft]="project.settings.fixedColumnsLeft" [fixedRowsTop]="project.settings.fixedRowsTop" [settings]="hotsettings">
                </hot-table>

                <app-cell-action-menu [cellactiontop]="cellactiontop" [cellactionleft]="cellactionleft" [cellactionvisible]="cellactionvisible" [cellactionmenuvisible]="cellactionmenuvisible"
                    [curSelectedRow]="curSelectedRow" [outputrow]="outputrow" [project]="project" [contextmenuitem]="contextmenuitem" [contextmenuitem1]="contextmenuitem1" [sheetid]="sheetid"
                    (addGuiEvent)="addGui($event)" (addPrimitiveEvent)="addPrimitive($event)" (showfilesDialogEvent)="showfilesDialog($event.type, $event.arg2, $event.arg3)"
                    (addProjectEvent)="addProject()"></app-cell-action-menu>

                <!-- cell tooltip -->
                <div [style.bottom]="celltooltiptop" [style.left]="celltooltipleft" [hidden]="!cellinfovisible" class="box-shadow cellinfoback" (click)="cellinfomenuvisible=true;!cellinfomenuvisible"
                    style="position: absolute;z-index:9999;padding:5px 12px;border-radius:5px;background:rgb(255, 255, 255);cursor:pointer">
                    <i class="fa-solid fas fa-info-circle" style="color:rgb(22, 117, 226)"></i>&nbsp;<i class="fa-solid fa-sort-right"></i>
                    <div *ngIf="cellinfomenuvisible && celltooltip" style="float:right">

                        <label style="font-weight:bold;">{{celltooltip.header}}
                        </label>
                        <ul class="infolist" style="padding-inline-start: 8px;">
                            <li style="font-size:14px;" *ngFor="let p of celltooltip.params">
                                <span style="display:inline-block;font-weight:bold;width:110px; color:rgb(0, 0, 0);">{{p.name}}:</span>
                                <span style="color:gray;max-width:500px;display:block;" [innerHtml]="p.info | safeHtml">{{p.info}}</span>

                            </li>
                        </ul>
                        <div *ngIf="celltooltip.link" style="position: relative; left: 7px;    font-weight: bold;"><a target="_blank" [href]="celltooltip.link" (click)="openLink(celltooltip.link)"
                                style="color: #8b2fbd;">Documentation <i class="fa-solid fa-external-link-alt"></i></a>
                        </div>


                    </div>
                </div>

                <!-- cell helper -->
                <div [style.top]="celltoolstop" [style.left]="celltoolsleft" [hidden]="!celltoolsvisible" class="box-shadow cellinfoback" (click)="celltoolsvisible=false;showcelltools();"
                    style="position: absolute;z-index:9999;padding:5px 12px;border-radius:5px;background:rgb(255, 255, 255);cursor:pointer">
                    <i class="fa-solid fas fa-sliders-h" style="color:rgb(23, 36, 45)"></i>&nbsp;<i class="fa-solid fa-sort-right"></i>

                </div>

            </div>

            <div class="xlstabs" style="background-color: #2d2d2d;">
                <div *ngFor="let t of project?.sheets; let i=index" class="xlstab" [class.active]="i==sheetid" [style.background-color]="i==sheetid ? '#3d3d3d' : '#2d2d2d'" [style.color]="'#e0e0e0'"
                    (contextmenu)="onRightClick($event,i)" (click)="setSheetIndex($event, i)">
                    {{t.name}}

                    <div id="sheetmenu" [hidden]="!project.sheets[i].visible" style="position:absolute;z-index:999;padding:2px 2px;bottom:30px;width:160px">
                        <div class="contextmenuitem" (click)="renameSheet(i,$event)"><i class="fa fa-window"></i> Rename
                            Sheet</div>
                        <div class="contextmenuitem" (click)="copyFormulas(i,$event)"><i class="fa fa-door"></i> Copy
                            Formulas</div>
                        <!-- <div class="contextmenuitem" (click)="clearSheet(i,$event)"><i class="fa fa-door"></i> Clear
                            Sheet</div> -->
                    </div>
                </div>
                <div class="xlstab add-sheet" (click)="addNewSheet()" style="cursor:pointer;padding:0 10px;">
                    <i class="fa fa-plus"></i>
                </div>
            </div>
            <div style="float:right;color:#808080;margin-right:10px;">v 1.0.0 RC2</div>
        </as-split-area>


        <!--     -----------------------------------------------------------------------------------         -->
        <!--     -----------------------------------------------------------------------------------         -->
        <!--     -----------------------------------------------------------------------------------         -->
        <as-split-area id="rightpane" [size]="100-splitleft">
            <div id="scenecontainer" style="position:relative;height:100%;">
                <div style=" position: relative;">
                    <div *ngIf="isMobile && !showMenu" (click)="showMenu=true" style="position: absolute;left:10px; top:10px; z-index:9999;"> <i class="fa fas fas fa-bars"></i>
                    </div>
                </div>

                <div style="position:relative;height:100%" class="guicontainer">
                    <div [hidden]="!project?.sceneSettings?.showControls" id="gui-panel" cdkDrag [cdkDragDisabled]="disableguidrag" m22Resizable (resizeStart)="disableguidrag=true" (resizeEnd)="disableguidrag=false;"
                        (resized)="onGuiResized($event)" (cdkDragEnded)="onGuiDragEnd($event)" class="box-shadow gui gui-panel" cdkDragBoundary=".guicontainer"
                        [ngStyle]="{'background': project?.menuSettings?.backgroundcolor, 'min-width': '300px', 'min-height': '200px', 'width': (project?.menuSettings?.width) ? project?.menuSettings?.width + 'px' : '400px', 'height': (project?.menuSettings?.height) ? project?.menuSettings?.height + 'px' : '600px'}"
                        [style.margin-left]="showMenu==true || !isMobile ? '0px' : '-1200px' " [style.left]="project?.menuSettings?.menuleft" [style.top]="project?.menuSettings?.menutop"
                        [style.right]="project?.menuSettings?.menufloat=='right' ? '10px' : '' ">

                        <!-- Add drag handle at the top -->
                        <div cdkDragHandle class="drag-handle" style="height: 20px; width: 100%; cursor: move; position: absolute; top: 0; left: 0;"></div>

                        <div *ngIf="isMobile" (click)="showMenu=false" style="position: absolute;right:10px; top:10px; z-index:9999;"> <i class="fa fas fas fa-bars"></i> </div>

                        <!-- gui -->
                        <app-gui *ngIf="!jsongui2" [jsongui]="jsongui" [projectdata]="selectedProjectName" (conrolChanged)="controlC($event)" class="light-theme" [celllabels]="editformula"></app-gui>

                        <!-- <div id="jsongui2" #jsongui2 *ngIf="jsongui2" [style.background]="project?.menuSettings.backgroundcolor" [style.borderBottomColor]="project?.menuSettings.accentcolor"
                            style="border-bottom: 1px solid ;    margin-bottom: 15px;    font-size: 16px;" [style.color]="project?.menuSettings?.accentcolor">{{uihead}}</div>
                    -->
                        <app-gui [jsongui]="jsongui2" style="border-top:3px solid #8C30F5;width:420px;background:#ffffff;" (mouseenter)="disableguidrag=true" (mouseleave)="disableguidrag=false" [celllabels]="editformula"
                            [projectdata]="selectedProjectName" (conrolChanged)="controlC($event)" class="light-theme"></app-gui>


                        <ng-container *ngIf="project?.menuSettings?.menuleft">
                            <div class="btnminimizemenu" (click)="project.menuSettings.menuleft=='-280px' ? project.menuSettings.menuleft='0px' : project.menuSettings.menuleft='-280px'"
                                style="background:white;position: absolute;top: 220px;right: 2px;">
                                <i *ngIf="project.menuSettings.menuleft=='0px'" class="fa fa-chevron-left" style="font-size:18px; color:rgb(177, 177, 177);"></i>
                                <i *ngIf="project.menuSettings.menuleft=='-280px'" class="fa fa-chevron-right" style="font-size:18px; color:rgb(177, 177, 177);"></i>
                            </div>
                        </ng-container>


                    </div>

                    <!-- three container -->
                    <div style="position: relative;height:100%;" class="vignette" [style.cursor]="cursorType">
                        <!-- <div style="position:absolute;left:10px; top:5px;"><i class="fa fa-unlink"></i></div> -->
                        <!-- <div id="vhcontainer" style="position:absolute;top:5px;left:5px;height:100px; background-color:blue;">
                </div> -->

                        <div id="threecontainer" #rendererContainer (touchmove)="scenemousemove($event)" (mousemove)="scenemousemove($event)" (mousedown)="scenemousedown($event)" (mouseup)="scenemouseup($event)"
                            (click)="sceneDoubleClick($event)" (window:resize)="onResize($event)" (wheel)="mouseWheel($event)" [style.width]="threecontainerwidth+'px'" [style.left]="leftpanelwidth+'px'"
                            style="height:100%">
                        </div>

                        <div style="position: absolute;top:0px;left:0px;pointer-e2vents: none;">
                            <!-- <app-sliding-panel [isOpen]="slidingpanel" (click)="slidingpanel=!slidingpanel"></app-sliding-panel> -->
                            <!-- <div style="width:22px;height:22px;background:red;" (click)="slidingpanel=!slidingpanel"></div> -->

                            <!-- TODO svg overlays -->
                            <app-overlays [overlays]="overlayarray"></app-overlays>
                        </div>

                        <button (click)="recreateModel()" class="btn btn-primary btn-sm" [class.recreating]="isRecreating"
                            style="position:absolute;top:10px;right:120px;padding: 4px 6px; border-radius: 3px; color: rgb(51, 51, 51) !important; margin-right: 9px; font-size: 12px; background: #d3d3d3; border: #7c7c7c;">
                            <i class="fa-solid fa-rotate" [class.fa-spin]="isRecreating"></i>
                            &nbsp;{{isRecreating ? 'Recreating...' : 'Recreate Model'}}
                        </button>

                    </div>

                    <div *ngIf="showObjectInfos" style="left:10px;wi2dth:400px; bottom:20px;position:absolute;z-index:999;background-color:#2d2d2d;padding:10px;border-radius:4px;">

                        <ng-json-editor [options]="jsonEditorOptions" [data]="hoverObject"></ng-json-editor>

                        <hot-table style="zoom:0.7" height="150" [hotId]="hotid2" [width]="600" [autoColumnSize]="true" [manualColumnResize]="true" [rowHeaders]="true" [data]="datasetsel" [settings]="hotsettings"
                            [currentRowClassName]="'currentRow'">
                        </hot-table>
                    </div>

                    <div [class.fade-processing]="processing" [class.fade-processing-out]="!processing" style="position:absolute;bottom:20px;left:20px;z-index:999;color:#e0e0e0;">
                        <div class="loading-spinner">
                            <div class="spinner-border" role="status" style="color: #b84eff;">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span class="processing-message" style="margin-left: 10px; color: #c4c4c4;">{{processingMessage
                                || 'Processing...'}}</span>
                        </div>

                        <style>
                            .loading-spinner {
                                display: inline-block;
                                margin-left: 10px;
                            }

                            .spinner-border {
                                width: 1.2rem;
                                height: 1.2rem;
                                border-width: 0.15em;
                                vertical-align: middle;
                            }

                            .processing-message {
                                font-size: 14px;
                                vertical-align: middle;
                                font-weight: 500;
                            }

                            .fade-processing {
                                opacity: 1;
                                visibility: visible;
                                transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
                            }

                            .fade-processing-out {
                                opacity: 0;
                                visibility: hidden;
                                transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
                            }
                        </style>
                    </div>

                    <app-social-share *ngIf="!isEditMode" style="position:absolute;bottom:70px;right:20px;z-index:999;" [shareUrl]="getCurrentModelShareUrl()"
                        [title]="project?.name ? (project?.name + ' - sheetBuild 3D Model') : 'sheetBuild - Create 3D models with Excel spreadsheets'"
                        [description]="'Check out this 3D model created with sheetBuild using Excel spreadsheets!'" [image]="project?.image || 'https://sheetbuild.com/assets/sheetbuildlogogray.svg'"
                        [enableMetaTags]="true">
                    </app-social-share>
                    <a href="https://sheetbuild.com" class="disable-select" style="position:absolute;bottom:20px;right:20px;z-index:999;">
                        <img style="opacity: 0.7;" src="/assets/sheetbuildlogogray.svg" />
                    </a>
                    <a (click)="openDisclaimer()" style="cursor:pointer; position:absolute;bottom:2px;right:70px;z-index:999;color:gray;font-size:16px;">Disclaimer</a>

                    <div style="position:absolute;top:130px;right:20px;z-index:999;color:#e0e0e0;">
                        <img [src]="!this.project?.sceneSettings?.iso ? 'assets/grid-3x3b.svg' : 'assets/grid-3x3.svg'" (click)="toggleCameraView()" style="width: 24px; height: 24px; cursor: pointer; opacity: 0.7;"
                            [style.opacity]="'0.3'" [matTooltip]="this.project?.sceneSettings?.iso ? 'Switch to Perspective View' : 'Switch to Orthographic View'" matTooltipPosition="left" alt="Camera View Toggle">
                    </div>

                    <div style="position:absolute;top:170px;right:20px;z-index:999;color:#e0e0e0;">
                        <div style="display: flex; align-items: center; background-color: rgba(60, 60, 60, 0.7); padding: 4px 8px; border-radius: 4px; cursor: pointer;" (click)="zoomAllVisible()"
                            matTooltip="Zoom to All Visible Objects" matTooltipPosition="left">
                            <img src="assets/zoom-all.svg" style="width: 24px; height: 24px; opacity: 1;" alt="Zoom All">
                            <!-- <span style="margin-left: 5px; white-space: nowrap;">Zoom All</span> -->
                        </div>
                    </div>

                    <div *ngIf="this.project?.sceneSettings?.iso" style="position:absolute;top:220px;right:20px;z-index:999;color:#e0e0e0;">
                        <div style="display: flex; align-items: center; background-color: rgba(60, 60, 60, 0.7); padding: 4px 8px; border-radius: 4px; cursor: pointer;" (click)="toggleCameraZoomPointer()"
                            matTooltip="Toggle Zoom on Pointer" matTooltipPosition="left">
                            <i class="fa-solid fa-mouse-pointer" style="width: 24px; height: 24px; opacity: 1; font-size: 18px;" [style.color]="project?.settings?.cameraZoomPointer ? '#b84eff' : '#ffffff'"></i>
                            <!-- <span style="margin-left: 5px; white-space: nowrap;">Zoom on Pointer</span> -->
                        </div>
                    </div>

                </div>
            </div>
        </as-split-area>
    </as-split>




</div>


<!-- <div id="viewportGizmo" #viewportGizmo style="right:10px;width:300px;height:300px; bottom:20px;position:absolute;z-index:9999;background-color: rgba(255, 0, 0, 0.175);"></div> -->

<div style="position:absolute;right:100px;bottom:120px;" *ngIf="debugInfos">
    <div>
        <label>overinfo:</label> {{overinfo}}
    </div>
    <div>
        <label>Is Main Config:</label> {{isMainConfig}}
    </div>
    <div>
        <label>Selected Subproject ID:</label> {{selectedSubprojectID}}
    </div>
    <div>
        <label>Selected Subproject Path:</label> {{selectedSubprojectPath}}
    </div>
    <div>
        <label>Selected Project Name:</label> {{selectedProjectName}}<br> {{selectedprojectpath}}
    </div>
    <div>
        <label>Selected Node path:</label> {{selectedNode?.userData?.path}}
        <br>
        <label>Selected Node name:</label> {{selectedNode?.userData?.name}}
    </div>
</div>

<div *ngIf="overinfo" [style.left]="mouse.x + 'px'" [style.top]="mouse.y + 'px'"
    style="position: absolute; background-color: rgba(181, 8, 190, 0.7);font-weight:bold; color: white; padding: 3px 6px; border-radius: 4px; font-size: 10px; pointer-events: none; z-index: 1000;">
    {{overinfo}}
</div>

<!-- 
<div style="position:absolute;right:100px;bottom:100px;">
    v 1.0.0 RC1
</div> -->