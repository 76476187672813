<app-sidebar></app-sidebar>


<div style="ba2ckground-color:rgb(244, 244, 244);height:100%; " class="container">



    <div style="height:30px"></div>
    <div *ngIf="impersonationId" style="position:absolute;top:0;right:0;padding:5px 10px;color:white;background-color:rgb(228, 6, 6);z-index:99;">
        Impersonating user ID: {{ impersonationId }}
    </div>


    <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="p-4">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis distinctio nisi laborum consectetur
            itaque provident veritatis quaerat quibusdam amet. Iste obcaecati unde ducimus veniam incidunt qui, sunt
            doloremque non pariatur.
        </div>
        <div class="p-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia placeat tempora autem architecto odit
            eligendi consectetur facere ea voluptates distinctio accusamus quas explicabo voluptatem sint, nisi,
            praesentium aspernatur enim! Dolor?
            <button class="px-4 py-2 font-bold text-white bg-blue-200 rounded hover:bg-blue-700">
                Mein Button
            </button>
            <button class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
                Mein Button
            </button>
            <button class="px-4 py-2 font-bold text-white bg-blue-600 rounded hover:bg-blue-700">
                Mein Button
            </button>
            <button class="px-4 py-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-700">
                Mein Button
            </button>
            <button class="px-4 py-2 font-bold text-white bg-blue-800 rounded hover:bg-blue-700">
                Mein Button
            </button>
        </div>
    </div> -->


    <!-- <h1 class="text-3xl font-bold mb-2">Dashboard</h1> -->
    <div class="flex justify-around" style="display: flex; justify-content: space-around; flex-wrap: nowrap;">
        <div (click)="newproject()" class="dashbox m-4 bg-white shadow-lg rounded-lg p-5 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            style="flex: 1 1 30%; max-width: 400px;cursor:pointer">
            <h2 class="text-2xl font-bold mb-3 text-center">New Project</h2>

            <div class="text-gray-700 d-flex justify-content-center align-items-center">
                <div class="col-2md-3 dashi2con">
                    <img src="assets/icon-newproject.png" style="height:60px; @media (min-width: 768px) { height: 100px; }" />
                    <!-- <h5 style="margin-top:15px;">New Project</h5> -->
                </div>
            </div>
        </div>
        <div (click)="openExamples()" class="dashbox  m-4 bg-white shadow-lg rounded-lg p-5 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            style="flex: 1 1 30%; max-width: 400px;;cursor:pointer">
            <h2 class="text-2xl font-bold mb-3 text-center">Examples</h2>

            <div class="text-gray-700 d-flex justify-content-center align-items-center">
                <div class="col-m2d-3 dashico2n">
                    <img src="assets/icon-example.png" style="height:60px; @media (min-width: 768px) { height: 100px; }" />
                    <!-- <h5 style="margin-top:15px;">Examples</h5> -->
                </div>
            </div>
        </div>
        <div (click)="openTutorials()" class="dashbox  m-4 bg-white shadow-lg rounded-lg p-5 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            style="flex: 1 1 30%; max-width: 400px;;cursor:pointer">
            <h2 class="text-2xl font-bold mb-3 text-center">Tutorials</h2>
            <div class="text-gray-700 d-flex justify-content-center align-items-center">
                <div class="col2-md-3 dashi2con">
                    <img src="assets/icon-tutorials.png" style="height:60px; @media (min-width: 768px) { height: 100px; }" />
                    <!-- <h5 style="margin-top:15px;">Tutorials</h5> -->
                </div>
            </div>
        </div>
    </div>


    <!-- <div style="position:absolute; top:10px; right:10px; padding:5px 10px;"><i class="fa far fa-user-circle" (click)="showaccountmenu=true"></i>Account
    </div> -->



    <!-- <div class="row" style="background:white; padding: 16px 26px;">
        <div class="col-md-12">
            <h2>Dashboard</h2>
        </div>
    </div> -->


    <!-- <div class="row" style="padding: 6px 36px;background:rgb(229, 229, 229);">
        <div class="col-md-12">
            <h4 style="padding-top:40px; padding-bottom:0px;">Start</h4>
        </div>
    </div> -->


    <div style="right:0px;top:0px; padding:5px 10px;cursor:pointer;position:absolute;z-index:99;cursor:pointer;" (click)="showaccountmenu=!showaccountmenu;$event.stopPropagation()   ">
        <i class="fa-solid fa-circle-user"></i>&nbsp;Account
        <div id="accountmenu" [hidden]="!showaccountmenu" style="position:absolute;z-index:999;background-color:white;padding:2px 2px;right:0px;">
            <div class="contextmenuitem" [routerLink]="'/settings'"><i class="fa fa-window"></i> Settings
            </div>
            <!-- <div class="contextmenuitem" [routerLink]="'invoices'"><i class="fa fa-door"></i> Invoices
                            </div> -->
            <div class="contextmenuitem" (click)="logout()"><i class="fa fa-door"></i> Logout</div>

        </div>
    </div>





    <!-- <div class="row" style="padding-left:68px;background:rgb(229, 229, 229);margin-bottom:20px;padding-bottom:20px;">
        <div class="col-md-3 dashicon" (click)="newproject()">
            <img src="assets/icon-newproject.png" style="height:100px" />
            <h5 style="margin-top:15px;">New Project</h5>
        </div>
        <div class="col-md-3 dashicon" (click)="openExamples()">
            <img src="assets/icon-example.png" style="height:100px" />
            <h5 style="margin-top:15px;">Examples</h5>
        </div>
        <div class="col-md-3 dashicon" (click)="openTutorials()">
            <img src="assets/icon-tutorials.png" style="height:100px" />
            <h5 style="margin-top:15px;">Tutorials</h5>
        </div>
    </div> -->

    <div style="height:10px"></div>

    <div class="flex justify-center my-4">

        <input class="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" [(ngModel)]="query" (ngModelChange)="searchProjects($event)" id="search"
            type="text" placeholder="Search" style="padding-right: 30px;">
        <!-- <i class="fa fa-search" style="position: absolute; right: 20px; top: 15px;"></i> -->
        <style>
            #search:focus {
                border-color: blue;
            }
        </style>
    </div>

    <div style="height:30px"></div>



    <div class="row" id="contprojects" style="padding: 6px; padding-left: 136px; padding-right: 136px;">
        <style>
            @media (min-width: 768px) {
                #contprojects {
                    padding-left: 236px;
                    padding-right: 236px;
                }
            }

            ::-webkit-scrollbar {
                width: 12px;
            }

            ::-webkit-scrollbar-track {
                background: #f1f1f1 !important;
            }

            ::-webkit-scrollbar-thumb {
                background: #e3e3e3 !important;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #a8a8a8 !important;
            }
        </style>


        <div class="col-md-6">
            <h4 class="font-bold text-gray-800" style="font-size:22px">Current Projects</h4>
            <div style="padding: 6px 6px;    height:50vh; margin-right:10px;     overflow: overlay; overflow-x:hidden;">


                <div class="row filerow" *ngFor="let p of filteredProjects" (click)="navigateTo(p.route)" style="margin-left:0px; margin-right:10px;border-bottom:1px solid rgb(236, 236, 236)">
                    <div class="col-md-10">
                        <div style=" vertical-align:middle ;">
                            <!-- <i class="fa fa-file"></i>  -->
                            <div style="width:50px;height:40px;margin-right:10px;float:left;">
                                <img [src]="p.image" style="max-height:40px;max-width:50px;margin-right:10px;float:left;" />
                            </div>
                            <div style="width:calc(100% - 80px);float:left;">
                                <span class="text-gray-800" style="line-height:26px;font-weight:bold">{{p.name}}</span>

                                <br>
                                <span class="text-gray-400" style="line-heig2ht:14px;font-size:12px">{{p.lastsave |
                                    date:'short'}}</span>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-2">
                        <!--          <button style="float:right;" (click)="$event.stopPropagation()" mat-button
                            [matMenuTriggerFor]="menu"> <i class="fa fas fa-caret-down fa1-2x text-gray-800"></i>
                        </button> -->

                        <button style="float:right; visibility: hidden;" class="show-on-hover text-gray-800" (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="menu"> <i
                                class="fa fas  fa-bars fa1-2x"></i> </button>

                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deleteproject(p);$event.stopPropagation()">Delete</button>
                            <button mat-menu-item (click)="cloneproject(p);$event.stopPropagation()">Duplicate</button>
                            <!-- <button mat-menu-item (click)="publishproject(p);$event.stopPropagation()">Publish...</button> -->
                        </mat-menu>

                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-6">

            <h4 class="font-bold text-gray-800" style="font-size:22px">Published Projects</h4>

            <div style="padding: 6px 6px;    height:50vh;  margin-right:10px;  overflow: overlay; overflow-x:hidden;">
                <div class="row filerow" *ngFor="let p of filteredPublishedProjects" [routerLink]="'/p/'+p.id" style="margin-left:0px; margin-right:10px;border-bottom:1px solid rgb(236, 236, 236)">

                    <div class="col-md-10">
                        <div style=" vertical-align:middle ;">
                            <!-- <i class="fa fa-file"></i>  -->
                            <div style="width:50px;height:40px;margin-right:10px;float:left;">
                                <img [src]="p.image" style="max-height:40px;max-width:50px;margin-right:10px;float:left;" />
                            </div>

                            <span class="text-gray-800" style="line-height:26px;font-weight:bold">
                                {{p.name}}
                                <i class="fa fa- fa-window"></i>
                                <i class="fa fa- fa-window"></i>
                            </span>

                            <span *ngIf="p.configscount" class="bg-blue-600" (click)="goToLink('/configurations/'+p.id,false);$event.stopPropagation()"
                                style="backgro2und-color:rgb(179, 137, 255); color:white; padding:2px 5px; border-radius:5px;font-size:12px;">
                                {{p.configscount}} configurations</span>


                            <br>
                            <span class="text-gray-400" style="line-heig2ht:14px;font-size:12px">{{p.publishdate |
                                date:'short'}}</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button style="float:right; visibility: hidden;" class="show-on-hover text-gray-800" (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="menu"> <i
                                class="fa fas  fa-bars fa1-2x"></i> </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deletepublicproject(p);$event.stopPropagation()">Delete</button>
                            <button mat-menu-item (click)="goToLink('/p/'+p.id);$event.stopPropagation()">Open</button>

                        </mat-menu>

                    </div>

                </div>
                <style>
                    .filerow:hover .show-on-hover {
                        visibility: visible !important;
                    }
                </style>
            </div>
        </div>



    </div>
</div>




<!-- loading animation -->
<div [hidden]="!loading" style="position:absolute;top:0;left:0;z-index:999;width:100%;height:100%;background:rgba(0, 0, 0, 0.265);">
    <div style="position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);z-index:999;">
        <img src="/assets/loading-m.png" style="">
    </div>
</div>