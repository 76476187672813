<style>
    .adminmnu {
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #333;
        margin-bottom: 20px;
    }

    .adminmnu a {
        display: inline-block;


        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
    }
</style>



<div class="adminmnu">
    <a routerLink="/admin" routerLinkActive="active">Dashboard</a>
    <a routerLink="/admin/users" routerLinkActive="active">Users</a>
    <a routerLink="/admin/logs" routerLinkActive="active">Logs</a>
    <a routerLink="/admin/projects" routerLinkActive="active">Projects</a>

</div>