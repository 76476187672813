<!-- <h1 mat-dialog-title>Publish</h1> -->
<div mat-dialog-content class="publish-dialog-container">
    <style>
        :host {
            --primary-color: #9f26f0;
            --primary-light: #e1c5ff;
            --text-color: #333;
            --border-color: #e0e0e0;
            --bg-color: #fff;
            --section-spacing: 24px;
        }

        /* Header styles to override base.css */
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            line-height: 1.2;
            margin-top: 0;
            margin-bottom: 0.5rem;
            color: var(--text-color);
        }

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.75rem;
        }

        h4 {
            font-size: 1.5rem;
        }

        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: 1rem;
        }

        /* Angular Editor specific heading styles */
        ::ng-deep .angular-editor-textarea h1 {
            font-size: 2.5rem !important;
            font-weight: 500 !important;
        }

        ::ng-deep .angular-editor-textarea h2 {
            font-size: 2rem !important;
            font-weight: 500 !important;
        }

        ::ng-deep .angular-editor-textarea h3 {
            font-size: 1.75rem !important;
            font-weight: 500 !important;
        }

        ::ng-deep .angular-editor-textarea h4 {
            font-size: 1.5rem !important;
            font-weight: 500 !important;
        }

        ::ng-deep .angular-editor-textarea h5 {
            font-size: 1.25rem !important;
            font-weight: 500 !important;
        }

        ::ng-deep .angular-editor-textarea h6 {
            font-size: 1rem !important;
            font-weight: 500 !important;
        }

        .publish-dialog-container {
            font-family: 'Roboto', sans-serif;
            color: var(--text-color);
        }

        .publish-dialog-content {
            height: 100%;
            min-height: 400px;
            position: relative;
            width: 100%;
            /* max-width: 900px; */
            overflow: auto;
            padding: 0;
        }

        .mat-dialog-content {
            max-height: 100vh !important;
        }

        .section-card {
            background: var(--bg-color);
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
            padding: 20px;
            margin-bottom: var(--section-spacing);
            border: 1px solid var(--border-color);
        }

        .form-label {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
            color: #555;
        }

        .form-control {
            width: 100%;
            padding: 10px;
            border: 1px solid var(--border-color);
            border-radius: 4px;
            margin-bottom: 16px;
            transition: border-color 0.2s;
        }

        .form-control:focus {
            border-color: var(--primary-color);
            outline: none;
            box-shadow: 0 0 0 2px var(--primary-light);
        }

        .dialogbottom {
            display: flex;
            justify-content: flex-end;
            padding: 16px 0;
        }

        .btn-primary {
            background-color: var(--primary-color);
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 4px;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.2s, transform 0.1s;
        }

        .btn-primary:hover {
            background-color: #8921d9;
            transform: translateY(-1px);
        }

        mat-checkbox {
            margin-bottom: 12px;
            display: block;
        }

        .preview-img {
            max-height: 150px;
            object-fit: contain;
            border-radius: 4px;
            border: 1px solid var(--border-color);
        }

        .step-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 16px;
            color: var(--primary-color);
        }

        .success-container {
            text-align: center;
            padding: 20px;
        }

        .success-icon {
            width: 160px;
            margin: 24px auto;
        }

        .success-link {
            color: var(--primary-color);
            text-decoration: underline;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            gap: 6px;
        }

        /* Custom mat-stepper styles */
        ::ng-deep .mat-step-header .mat-step-icon-selected {
            background-color: var(--primary-color);
        }

        ::ng-deep .mat-step-header .mat-step-icon-state-edit {
            background-color: var(--primary-color);
        }

        ::ng-deep .mat-stepper-horizontal-line {
            border-top-color: var(--border-color);
        }

        /* Reset all styling first */
        ::ng-deep .mat-step-header {
            color: #000000;
            font-size: 14px;
            font-weight: 400;
        }

        /* Make ONLY the selected header bigger and purple */
        ::ng-deep .mat-step-header[aria-selected="true"] {
            color: var(--primary-color);
        }

        /* Make ONLY the selected header text bigger and purple */
        ::ng-deep .mat-step-header[aria-selected="true"] .mat-step-label {
            color: var(--primary-color);
            font-size: 16px;
            font-weight: 600;
        }

        /* Ensure non-selected headers remain black */
        ::ng-deep .mat-step-header:not([aria-selected="true"]) .mat-step-label {
            color: #000000;
            font-size: 14px;
            font-weight: 400;
        }
    </style>

    <mat-stepper [linear]="false" #stepper class="publish-stepper">
        <mat-step>
            <ng-template matStepLabel>General</ng-template>
            <div class="publish-dialog-content">

                <div class="row">
                    <div class="col-md-4">
                        <div class="section-card">
                            <label for="frmname" class="form-label">Project Name</label>
                            <input type="text" name="frmname" class="form-control" [(ngModel)]="project.name" placeholder="Enter project name" />

                            <label for="frmkeywords" class="form-label">Keywords</label>
                            <input type="text" name="frmkeywords" class="form-control" [(ngModel)]="project.keywords" placeholder="Keywords (comma separated)" />

                            <label class="form-label">Preview Image</label>
                            <img [src]="project.image" class="preview-img" />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="section-card">
                            <mat-tab-group animationDuration="200ms">
                                <mat-tab label="Description">
                                    <div style="position:relative;height:320px;margin-top:16px;">
                                        <angular-editor [config]="editorConfig" [placeholder]="'Enter detailed project description here...'" [(ngModel)]="project.description"></angular-editor>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Disclaimer">
                                    <div style="position:relative;height:320px;margin-top:16px;">
                                        <angular-editor [config]="editorConfig" [placeholder]="'Enter disclaimer text here...'" [(ngModel)]="project.disclaimer"></angular-editor>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>

                <div class="dialogbottom">
                    <button mat-button matStepperNext class="btn-primary">Next</button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Configurations</ng-template>
            <div class="publish-dialog-content">

                <div class="row">
                    <div class="col-md-4">
                        <div class="section-card">
                            <h4>Save Configurations</h4>


                            <div class="text-muted small mb-3">Save button must be visible in UI</div>

                            <h5 class="mt-4 mb-2">User Form Data:</h5>
                            <div class="checkbox-group">
                                <mat-checkbox [(ngModel)]="project.userformmail" color="primary">E-mail</mat-checkbox>
                                <mat-checkbox [(ngModel)]="project.userformsendmail" color="primary" [disabled]="!project.userformmail">
                                    Send Configuration Email
                                </mat-checkbox>
                                <mat-checkbox [(ngModel)]="project.userformname" color="primary">Name</mat-checkbox>
                                <mat-checkbox [(ngModel)]="project.userformphone" color="primary">Phone</mat-checkbox>
                                <mat-checkbox [(ngModel)]="project.userformcompany" color="primary">Company</mat-checkbox>
                                <mat-checkbox [(ngModel)]="project.userformcomment" color="primary">Comment</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="section-card">



                            <div *ngIf="project.userformsendmail">
                                <label class="form-label mt-3">Email Subject</label>
                                <input [(ngModel)]="project.usermailsubject" class="form-control" type="text" placeholder="Enter email subject" />

                                <label class="form-label">Email Body</label>
                                <angular-editor [config]="editorConfig2" [placeholder]="'Enter email body text here...'" [(ngModel)]="project.usermailtext"></angular-editor>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dialogbottom">
                    <button mat-button matStepperNext class="btn-primary">Next</button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Finish</ng-template>
            <div class="publish-dialog-content">


                <div *ngIf="step==1" class="section-card">
                    <div class="text-center mb-4">
                        <h2>Ready to Publish</h2>
                        <p>Your new public project will be created.</p>

                        <div class="mt-4 mb-3">
                            <p>Or replace an existing public project:</p>
                            <select class="form-control mx-auto" style="max-width: 400px;" [(ngModel)]="replaceid">
                                <option value="null">Create new project</option>
                                <option *ngFor="let p of publishedprojects" [value]="p.id">
                                    {{p.name}} (ID: {{p.id}})
                                </option>
                            </select>
                        </div>

                        <div class="mt-4">
                            <button mat-button (click)="publish()" class="btn-primary">Publish Now</button>

                            <div class="mt-3">
                                <mat-checkbox [(ngModel)]="project.private" [checked]="!project.private" disabled color="primary">
                                    Publish to open library
                                </mat-checkbox>
                                <div class="text-muted small">(you are in free tier)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="step==2" class="section-card">
                    <div class="text-center py-5">
                        <h2>Publishing project...</h2>
                        <img *ngIf="true" src="/assets/loading-m.png" class="mx-auto mt-4">
                    </div>
                </div>

                <div *ngIf="step==3" class="success-container">
                    <h1 class="text-3xl font-bold">Project successfully published!</h1>

                    <img src="/assets/92460-checkmark-animation.gif" alt="" class="success-icon" />

                    <h3 class="mb-4">
                        <a target="_blank" [href]="'/p/'+newproject.id" class="success-link">
                            Visit Public Project
                            <mat-icon>open_in_new</mat-icon>
                        </a>
                    </h3>

                    <div class="mt-5">
                        <h4>Share:</h4>
                        <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','reddit']" [show]="5" [showText]="true" [url]="'https://app.sheetbuild.com/p/'+newproject.id"
                            [autoSetMeta]="false">
                        </share-buttons>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</div>