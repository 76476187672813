import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, filter, scan } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sheetBuild';



  constructor(translate: TranslateService, private router: Router, public auth: AngularFireAuth, public afs: AngularFirestore) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.auth.authState.subscribe(async a => {
      //  a.uid;

      this.router.events.pipe(
        filter((event: any) => event instanceof NavigationEnd)
      ).subscribe(x => {
        this.afs.collection("useractions").add({
          uid: a?.uid,
          action: "navigate " + x.id + ": " + x.url,
          datetime: new Date(),
          datetimets: new Date().getTime(),
        });
      });
    });
  }


}







