import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    // Default meta values
    private defaultValues = {
        title: 'sheetBuild - Create 3D models with Excel spreadsheets',
        description: 'Design and build 3D models easily with the help of Excel spreadsheets. Turn your spreadsheet data into beautiful 3D visualizations.',
        image: 'assets/social-preview.png',
        url: 'https://sheetbuild.com/'
    };

    // Current meta values
    private current = { ...this.defaultValues };

    constructor(
        private meta: Meta,
        private titleService: Title,
        private router: Router
    ) {
        // Listen for route changes to update URL
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.current.url = window.location.href;
            this.updateMetaTags();
        });

        // Initialize with default values
        this.updateMetaTags();
    }

    /**
     * Update meta tags with current values
     */
    private updateMetaTags(): void {
        // Set page title
        this.titleService.setTitle(this.current.title);

        // Basic meta tags
        this.meta.updateTag({ name: 'description', content: this.current.description });
        this.meta.updateTag({ name: 'title', content: this.current.title });

        // Open Graph tags
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:url', content: this.current.url });
        this.meta.updateTag({ property: 'og:title', content: this.current.title });
        this.meta.updateTag({ property: 'og:description', content: this.current.description });
        this.meta.updateTag({ property: 'og:image', content: this.current.image });

        // Twitter Card tags
        this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.updateTag({ name: 'twitter:url', content: this.current.url });
        this.meta.updateTag({ name: 'twitter:title', content: this.current.title });
        this.meta.updateTag({ name: 'twitter:description', content: this.current.description });
        this.meta.updateTag({ name: 'twitter:image', content: this.current.image });
    }

    /**
     * Update meta information based on current content (3D model, etc.)
     * @param data Object containing meta data to update
     */
    updateMeta(data: {
        title?: string;
        description?: string;
        image?: string;
        url?: string;
    }): void {
        // Update current values with any provided values
        this.current = {
            ...this.current,
            ...data
        };

        // Refresh meta tags
        this.updateMetaTags();
    }

    /**
     * Reset meta to default values
     */
    resetToDefault(): void {
        this.current = { ...this.defaultValues };
        this.updateMetaTags();
    }
} 