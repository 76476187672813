import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root'
})
export class ScreenshotService {
    constructor() { }

    captureElement(elementId: string): Promise<{ blob: Blob, dataUrl: string }> {
        const element = document.getElementById(elementId);

        if (!element) {
            return Promise.reject('Element not found');
        }

        return html2canvas(element).then(canvas => {
            return new Promise<{ blob: Blob, dataUrl: string }>((resolve, reject) => {
                canvas.toBlob(blob => {
                    if (blob) {
                        const dataUrl = canvas.toDataURL('image/png');
                        resolve({ blob, dataUrl });
                    } else {
                        reject('Blob creation failed');
                    }
                }, 'image/png');
            });
        });
    }

    downloadScreenshot(blob: Blob, fileName: string = 'screenshot.png') {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
    }
}