import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export class CameraManager {
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    controls: OrbitControls;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;

    private previousCameraPosition: THREE.Vector3;
    private previousControlsTarget: THREE.Vector3;

    constructor(scene: THREE.Scene, renderer: THREE.WebGLRenderer, container: HTMLElement) {
        this.scene = scene;
        this.renderer = renderer;
        this.setupCamera(container);
        this.setupControls(container);
    }

    private setupCamera(container: HTMLElement) {
        this.camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 1, 20000);
        this.camera.position.set(1500, 2000, 2500);
        this.camera.up.set(0, 0, 1);
    }

    private setupControls(container: HTMLElement) {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.enableRotate = true;
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
    }

    updateAspect(width: number, height: number) {
        if (this.camera instanceof THREE.PerspectiveCamera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        } else if (this.camera instanceof THREE.OrthographicCamera) {
            // Update orthographic camera frustum
            this.camera.left = width / -2;
            this.camera.right = width / 2;
            this.camera.top = height / 2;
            this.camera.bottom = height / -2;
            this.camera.updateProjectionMatrix();
        }
        this.renderer.setSize(width, height);
    }

    setIsometricView() {
        this.previousCameraPosition = this.camera.position.clone();
        this.previousControlsTarget = this.controls.target.clone();

        const boundingBox = new THREE.Box3().setFromObject(this.scene);
        const center = boundingBox.getCenter(new THREE.Vector3());
        const size = boundingBox.getSize(new THREE.Vector3());

        const maxDim = Math.max(size.x, size.y, size.z);
        let distance;

        if (this.camera instanceof THREE.PerspectiveCamera) {
            const fov = this.camera.fov * (Math.PI / 180);
            distance = maxDim / (2 * Math.tan(fov / 2));
        } else {
            // For OrthographicCamera, use a different approach
            distance = maxDim;
        }

        distance *= 1.5;

        const iso = new THREE.Vector3(1, -1, 1).normalize();
        this.camera.position.copy(center).add(iso.multiplyScalar(distance));

        this.camera.lookAt(center);
        this.controls.target.copy(center);
        this.camera.up.set(0, 0, 1);
        this.camera.updateProjectionMatrix();
        this.controls.update();

        const aspect = this.renderer.domElement.width / this.renderer.domElement.height;
        const frustumSize = maxDim * 1.5 / 100;
        this.camera = new THREE.OrthographicCamera(
            frustumSize * aspect / -2, frustumSize * aspect / 2,
            frustumSize / 2, frustumSize / -2,
            0.01, distance * 3
        );

        this.camera.position.copy(this.previousCameraPosition);
        this.camera.lookAt(this.previousControlsTarget);
        this.camera.up.set(0, 0, 1);

        this.controls.object = this.camera;
        this.controls.target.copy(this.previousControlsTarget);
        this.controls.update();
    }

    resetToPerspective() {
        if (this.previousCameraPosition && this.previousControlsTarget) {
            const aspect = this.renderer.domElement.width / this.renderer.domElement.height;
            this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);

            this.camera.position.copy(this.previousCameraPosition);
            this.controls.target.copy(this.previousControlsTarget);
            this.camera.up.set(0, 0, 1);

            this.controls.object = this.camera;
            this.controls.update();
        }
    }

    update(delta: number) {
        this.controls.update(delta);
    }
}