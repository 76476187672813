<div style="float:left;width:320px;color:white;background-color:#8C30F5; height:100vh">

    <div style="padding-bottom:130px;cursor:pointer;" [routerLink]="'/'">
        <img src="assets/sheetbuildlogo.svg" style="height:80px; padding:16px 16px;float:left" />
    </div>

    <div style="padding:20px 20px;">

        <h4>Updates</h4>
        <div class="row updates" *ngFor="let n of news">
            <div class="col-md-12">
                <div style="font-size: 12px;">
                    <span>{{n.datetime| date }}&nbsp;</span><strong>{{n.title}}</strong>
                </div>
                <div style="font-size: 11px;" [innerHtml]="n.content"></div>
                <div style="border-top:1px solid white dottet; height:10px;">
                </div>
            </div>
        </div>



        <div style="height:40px"></div>

        <!-- <a class="sideicon">
        Onshape Integration
    </a>
    <a class="sideicon">
        Shopify Integration
    </a> -->

    </div>

    <a style="bottom:220px;position:absolute;width:320px;" href="https://sheetbuild.com/documentation/" target="_blank" class="sideicon"> Help </a>
    <a style="bottom:180px;position:absolute;width:320px;" href="https://sheetbuild.com/developer-api-documentation" target="_blank" class="sideicon">API & Services</a>
    <!-- <a style="bottom:140px;position:absolute;width:320px;" class="sideicon">Plugins/Addonss</a> -->

    <a style="bottom:30px;position:absolute;width:320px;font-size:20px;" href="/settings/" class="sideicon"><i class="fa fa-cog"></i>&nbsp;Settings</a>

</div>