import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorEvent } from 'ngx-color';
import { first, throwIfEmpty } from 'rxjs/operators';
import { InputdialogComponent } from '../inputdialog/inputdialog.component';
import { YesnodialogComponent } from '../yesnodialog/yesnodialog.component';
import { ExamplesComponent } from './examples/examples.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { ParallelAiDialogComponent } from './parallel-ai-dialog/parallel-ai-dialog.component';
import { SheetHelper } from '../editor/sheet-helper';
import { ProjectManager } from '../editor/projectmanager';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  afauth: any;
  window: any;
  public options: any;
  isLocalhost = false;

  entries: any;
  user: firebase.User;
  sdlg = false;
  lastfolder = '/';
  curfiles: any;
  curfolders: any;
  showaccountmenu = false;

  query = "";

  SERVER = "https://us-central1-xbuild3d.cloudfunctions.net"; // "http://localhost:5001/xbuild3d/us-central1";

  constructor(public afs: AngularFirestore, public route: ActivatedRoute, public snackbar: MatSnackBar, public dialog: MatDialog, public auth: AngularFireAuth, public router: Router, public http: HttpClient) {


    this.options = 'option';
    // auth.authState.subscribe(user => {
    //   this.user = user;
    //   console.log('user', user)
    // });


    // afs.collection("mail")
    //   .add({
    //     to: "info@seoaachen.de",
    //     message: {
    //       subject: "Hello from Firebase!",
    //       text: "This is the plaintext section of the email body.",
    //       html: "This is the <code>HTML</code> section of the email body.",
    //     },
    //   })
    //   .then(() => console.log("Queued email for delivery!"));
  }
  async logout() {
    var r = await this.auth.signOut();
    console.log('logout', r);
    this.router.navigate(['login']);
  }

  getUser(): Promise<any> {
    return this.auth.authState.pipe(first()).toPromise();
  }

  async cloneproject(p) {
    this.loading = true;
    try {
      await ProjectManager.cloneProject(p, this.userid, this.http, this.afs, this.snackbar);
    } finally {
      this.loading = false;
    }
  }


  projects: any;
  publishedprojects: any;
  sub1: any; sub2: any
  impersonationId: string;
  userid: string;
  async ngOnInit() {
    this.isLocalhost = window.location.hostname === 'localhost';

    this.user = await this.getUser();
    this.userid = this.user.uid;

    // impersonation
    this.impersonationId = this.route.snapshot.queryParamMap.get('impersonationId');
    if (this.impersonationId) {
      this.userid = this.impersonationId;
      console.log('Impersonating customer ID:', this.userid);
    }

    this.sub1 = this.afs.collection("projects/userprojects/" + this.userid, ref => ref.orderBy('date', 'desc')).valueChanges({ idField: 'id' }).subscribe(x => {
      if (x) {

        this.projects = x;
        console.log('projects', this.projects);
        this.filteredProjects = this.projects.sort((a, b) => {
          if (!a.lastsave) return 1;
          if (!b.lastsave) return -1;
          return b.lastsave - a.lastsave;
        });
        this.filteredProjects.forEach(project => {
          project.sheets = [{ name: 'Sheet 1' }, { name: 'Sheet 2' }, { name: 'Sheet 3' }, { name: 'Sheet 4' }, { name: 'Sheet 5' }];

          if (this.impersonationId)
            project.route = '/e/' + project.id + '?impersonationId=' + this.impersonationId;
          else
            project.route = '/e/' + project.id;
        });
      }
    });

    this.sub2 = this.afs.collection("projectspublic/", ref => ref.orderBy('publishtime', 'desc').where('userid', "==", this.userid)).valueChanges({ idField: 'id' }).subscribe(async x => {
      if (x) {
        this.publishedprojects = x;
        this.filteredPublishedProjects = this.publishedprojects;

        for (var i = 0; i < this.publishedprojects.length; i++) {


          var dbpath = "/configs/" + this.userid + "/" + this.publishedprojects[i].id;
          var pr = await this.afs.collection(dbpath).get().toPromise();
          this.publishedprojects[i].configscount = pr.docs?.length;

          // pr.docs.forEach(doc => {
          //   var d = doc.data() as any;
          //   d.id = doc.id;
          //   this.publishedprojects[i].configs = d;
          // });

        }

      }
    });

  }

  deleteproject(p) {
    const dialogRef = this.dialog.open(YesnodialogComponent, {
      //      width: '980px',
      data: { text: "Delete project permanently?" },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.afs.doc("projects/userprojects/" + this.userid + "/" + p.id).delete();
      }
    });
  }
  navigateTo(route: string, event?: MouseEvent) {
    // Check if ctrl/cmd key is pressed or if it's a middle click
    const newTab = event && (event.ctrlKey || event.metaKey || event.button === 1);

    if (newTab) {
      // Prevent default behavior
      event.preventDefault();
      // Open in new tab
      window.open(route, '_blank');
    } else {
      // Navigate in same tab
      this.router.navigate([route]);
    }
  }
  deletepublicproject(p) {
    const dialogRef = this.dialog.open(YesnodialogComponent, {
      //      width: '980px',
      data: { text: "Delete project permanently?" },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.afs.doc("projectspublic/" + p.id).delete();
      }
    });
  }


  depublishproject(p) {
    this.afs.doc("projects/userprojects/" + this.userid + "/" + p.id).update({ published: false });
  }
  publishproject(p) {

    // TODO: clone
    //    this.afs.doc("projects/userprojects/" + this.user.uid + "/" + p.id).update({ published: true });


  }

  filteredProjects: any; filteredPublishedProjects: any;
  searchProjects($event) {
    const query = this.query?.toLowerCase();
    this.filteredProjects = this.query ?
      this.projects.filter(project => project.name.toLowerCase().includes(query)) :
      this.projects;
    this.filteredPublishedProjects = this.query ?
      this.publishedprojects.filter(project => project.name.toLowerCase().includes(query)) :
      this.publishedprojects;
  }

  loading = false;

  async newemptyproject(projectname) {
    this.loading = true;
    var n = await this.afs.collection("projects/userprojects/" + this.userid).add({
      name: projectname, date: new Date().getTime(),
      sheets: [{ name: 'Sheet1' }, { name: 'Sheet2' }, { name: 'Sheet3' }, { name: 'Sheet4' }, { name: 'Sheet5' }],
      configurations: {},
      image: "",

      disclaimer: this.disclaimer,
      sceneSettings: {
        iso: true,  // This will make orthographic camera the default
        exposure: 1,
        background1: "#ffffff",
        background2: "#ffffff",
        ambientIntensity: 3,
        ambientColor: "#ffffff",
        hemisphereIntensity: 0.3,
        hemisphereColor1: "#ffffff",
        hemisphereColor2: "#ffffff",
        directionalIntensity: 3,
        directionalColor: "#ffffff",
        dirLightZ: 10000,
        envMap: "standard",
        showControls: true,
        showAnimations: false,
        showHelpers: true,
        showGrid: true
      },
      menuSettings: {
        accentcolor: "#8C30F5",
        textcolor: "#000000",
        backgroundcolor: "#ffffff",
        buttontextcolor: "#ffffff",
        menutype: "movable",
        menufloat: "left",

      }
    });
    console.log(n.id);

    var result;
    var attempts = 0;
    while (attempts < 7) {
      try {
        result = await this.http.post(this.SERVER + "/createNewSheets", { customerid: this.userid, projectid: n.id }).toPromise();
        console.log('result', result);
        await new Promise(resolve => setTimeout(resolve, 300));
        break; // Exit loop if request is successful
      } catch (error) {
        attempts++;
        if (attempts >= 7) {
          console.error('Failed after 7 attempts', error);
        }
      }
    }

    this.router.navigate(["e", n.id]);

  }



  newproject() {
    var projectname = 'project ' + this.projects.length;
    const dialogRef = this.dialog.open(InputdialogComponent, {
      //      width: '980px',
      data: { okbutton: "Create New Project", value: projectname, head: "New Project", property: "Project Name" },
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.newemptyproject(result);
      }
    });
  }

  goToLink(url: string, newwtab = true) {
    if (newwtab) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  }



  openExamples() {
    const dialogRef = this.dialog.open(ExamplesComponent, {
      width: '800px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.cloneproject(result);
      }
    });
  }

  openTutorials() {
    const dialogRef = this.dialog.open(TutorialsComponent, {
      width: '850px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
  }



  disclaimer = `<h2>Terms of Use</h2>

By using sheetBuild you agree to the terms of use.<br><br>
<br>

1. sheetBuild is provided on an "as is" basis and is not guaranteed to be accurate, complete, or up - to - date.sheetBuild is not responsible for any errors or omissions that may occur in the use of the application.
<br>
<br>
2. sheetBuild is not responsible for any loss or damage suffered by any user as a result of the use of sheetBuild.
<br>
<br>
3. sheetBuild does not guarantee that any information or services provided by sheetBuild will be error - free or uninterrupted.
<br>
<br>
4. sheetBuild is not responsible for any computer viruses that may be transmitted through the use of sheetBuild.
<br>
<br>
5. sheetBuild reserves the right to change or modify the terms of use at any time without prior notice.
<br>
<br>
6. sheetBuild is not responsible for any content posted by users of the application.
<br>
<br>
7. sheetBuild is not responsible for any third - party websites that may be linked to or from the application.
<br>
<br>
8. sheetBuild reserves the right to terminate any user's access to the application at any time without prior notice.
<br>
<br>
9. By using sheetBuild, you agree to indemnify, defend and hold harmless sheetBuild and its affiliates against any claims, damages, losses, costs, and expenses(including attorney's fees) arising out of or related to your use of the application.
<br>
<br>
10. sheetBuild reserves the right to modify or discontinue the application(or any part thereof) at any time without prior notice.sheetBuild shall not be liable to you or any third party for any modification, suspension, or discontinuance of the application.
<br>
<br>
11. These terms of use constitute the entire agreement between you and sheetBuild.
<br>
<br>
12. This agreement shall be governed by the laws of germany, and any disputes arising out of or relating to this agreement shall be subject to the exclusive jurisdiction of the courts of germany.
<br>
<br>
13. sheetBuild reserves the right to update these terms of use from time to time at its sole discretion.
<br>
<br>`;

  async openParallelAIDialog() {
    const dialogRef = this.dialog.open(ParallelAiDialogComponent, {
      width: '600px',
      data: {
        mode: 'models',
        prompt: '',
        models: {
          claude: true,
          gemini: true,
          deepseek: true
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.runParallelAI(result);
      }
    });
  }


  async renameproject(p) {
    const dialogRef = this.dialog.open(InputdialogComponent, {
      data: { okbutton: "Rename Project", value: p.name, head: "Rename Project", property: "Project Name" },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.afs.doc("projects/userprojects/" + this.userid + "/" + p.id).update({ name: result });
      }
    });
  }

  async runParallelAI(data: any) {
    this.loading = true;
    try {
      if (data.mode === 'models') {
        // Original functionality for running across different models
        const selectedModels = Object.entries(data.models)
          .filter(([_, selected]) => selected)
          .map(([model]) => model);

        console.log('Selected models:', selectedModels);

        // Create projects first
        const projectIds = await Promise.all(selectedModels.map(async model => {
          const projectName = `AI Run - ${model} - ${new Date().toLocaleString()}`;
          console.log('Creating project:', projectName);

          const projectDoc = await this.afs.collection("projects/userprojects/" + this.userid).add({
            name: projectName,
            date: new Date().getTime(),
            sheets: [{ name: 'Sheet 1' }, { name: 'Sheet 2' }, { name: 'Sheet 3' }, { name: 'Sheet 4' }, { name: 'Sheet 5' }],
            configurations: {},
            image: "",
            aiModel: model,
            prompt: data.prompt,
            disclaimer: this.disclaimer,
            menuSettings: {
              accentcolor: "#8C30F5",
              textcolor: "#000000",
              backgroundcolor: "#ffffff",
              buttontextcolor: "#ffffff",
              menutype: "movable",
              menufloat: "left",
            }
          });

          console.log('Created project with ID:', projectDoc.id);

          // Create sheets for the project
          let attempts = 0;
          while (attempts < 7) {
            try {
              await this.http.post(this.SERVER + "/createNewSheets", {
                customerid: this.userid,
                projectid: projectDoc.id
              }).toPromise();
              console.log('Created sheets for project:', projectDoc.id);
              break;
            } catch (error) {
              attempts++;
              if (attempts >= 7) {
                console.error('Failed to create sheets after 7 attempts', error);
                throw error;
              }
              await new Promise(resolve => setTimeout(resolve, 400));
            }
          }

          return projectDoc.id;
        }));

        console.log('Created all projects:', projectIds);

        // Open each project in a new tab with the appropriate parameters
        projectIds.forEach((projectId, index) => {
          const model = selectedModels[index];
          const url = new URL(`${window.location.origin}/e/${projectId}`);
          url.searchParams.append('prompt', data.prompt);
          url.searchParams.append('model', model);
          url.searchParams.append('do5runs', 'true');

          console.log('Opening URL:', url.toString());
          window.open(url.toString(), '_blank');
        });

      } else if (data.mode === 'claude-prompts' || data.mode === 'gemini-prompts') {
        // Handle multiple prompts mode (Claude or Gemini)
        const prompts = data.prompt.split('\n').filter(p => p.trim().length > 0);
        const aiModel = data.mode === 'claude-prompts' ? 'claude' : 'gemini';
        console.log(`Multiple prompts mode for ${aiModel}, prompts:`, prompts);

        // Create a project for each prompt
        const projectIds = await Promise.all(prompts.map(async (prompt, index) => {
          const projectName = `AI Run - ${aiModel} - Prompt ${index + 1} - ${new Date().toLocaleString()}`;
          console.log('Creating project:', projectName);

          const projectDoc = await this.afs.collection("projects/userprojects/" + this.userid).add({
            name: projectName,
            date: new Date().getTime(),
            sheets: [{ name: 'Sheet 1' }, { name: 'Sheet 2' }, { name: 'Sheet 3' }, { name: 'Sheet 4' }, { name: 'Sheet 5' }],
            configurations: {},
            image: "",
            aiModel: aiModel,
            prompt: prompt,
            disclaimer: this.disclaimer,
            menuSettings: {
              accentcolor: "#8C30F5",
              textcolor: "#000000",
              backgroundcolor: "#ffffff",
              buttontextcolor: "#ffffff",
              menutype: "movable",
              menufloat: "left",
            }
          });

          console.log('Created project with ID:', projectDoc.id);

          // Create sheets for the project
          let attempts = 0;
          while (attempts < 7) {
            try {
              await this.http.post(this.SERVER + "/createNewSheets", {
                customerid: this.userid,
                projectid: projectDoc.id
              }).toPromise();
              console.log('Created sheets for project:', projectDoc.id);
              break;
            } catch (error) {
              attempts++;
              if (attempts >= 7) {
                console.error('Failed to create sheets after 7 attempts', error);
                throw error;
              }
              await new Promise(resolve => setTimeout(resolve, 400));
            }
          }

          return { id: projectDoc.id, prompt };
        }));

        console.log('Created all projects:', projectIds);

        // Open each project in a new tab
        projectIds.forEach(({ id, prompt }) => {
          const url = new URL(`${window.location.origin}/e/${id}`);
          url.searchParams.append('prompt', prompt);
          url.searchParams.append('model', aiModel);
          url.searchParams.append('do5runs', 'true');
          url.searchParams.append('multiPrompt', 'true');

          console.log('Opening URL:', url.toString());
          window.open(url.toString(), '_blank');
        });
      }

    } catch (error) {
      console.error('Error in runParallelAI:', error);
      this.snackbar.open('Error creating AI projects. Please try again.', null, { duration: 3000 });
    } finally {
      this.loading = false;
    }
  }

}
