<h2 mat-dialog-title>Export Part List</h2>

<form [formGroup]="partListForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div class="dialog-content">
            <h3>Export Options</h3>

            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Export Type</mat-label>
                <mat-select formControlName="exportType">
                    <mat-option value="clipboard">Clipboard (Tab-separated)</mat-option>
                    <mat-option value="excel">Excel File</mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="partListForm.get('exportType')?.value === 'excel'">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Filename</mat-label>
                    <input matInput formControlName="filename" placeholder="part-list.xlsx">
                </mat-form-field>
            </div>

            <h3>Include Fields</h3>

            <div class="checkbox-section">
                <mat-checkbox formControlName="includePosition">Position (X, Y, Z)</mat-checkbox>
                <mat-checkbox formControlName="includeRotation">Rotation (X, Y, Z)</mat-checkbox>
                <mat-checkbox formControlName="includeScale">Scale (X, Y, Z)</mat-checkbox>
                <mat-checkbox formControlName="includeParent">Parent Information</mat-checkbox>
                <mat-checkbox formControlName="includeUserData">Other User Data</mat-checkbox>
            </div>

            <h3 *ngIf="availableUserDataFields.length > 0">Custom User Data Fields</h3>

            <div class="checkbox-section" formArrayName="customFields">
                <div *ngFor="let field of availableUserDataFields; let i = index" class="custom-field-checkbox">
                    <mat-checkbox [formControlName]="i">{{ field }}</mat-checkbox>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button type="button" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" type="submit">Export</button>
    </mat-dialog-actions>
</form>