import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AngularFirestore } from "@angular/fire/firestore";

import { map } from "rxjs/operators";
import { combineLatest } from 'rxjs';



@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"]
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<any>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["name", "email", "registerDate", "lastLogin", "plan", "functions"];

  public options: any;

  entries: any;
  constructor(public afs: AngularFirestore, public dialog: MatDialog) {
    this.dataSource = new UsersDataSource();
    this.dataSource.data = [
    ];


    this.options = "option";
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(LogdetailComponent, {
  //     data: { name: "austin" }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  // openEditDialog(logitem) {
  //   if (!logitem) {
  //     logitem = {};
  //   }

  //   const dialogRef = this.dialog.open(LogdetailComponent, {
  //     data: logitem
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs
      .collection("users")
      .valueChanges({ idField: 'id' })
      .subscribe(x => {
        this.dataSource.data = <any>x;
        console.log("users ", x);

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.entries = x.map(user => {
          return this.afs.collection('projects', ref => ref.where('userId', '==', user.id)).valueChanges({ idField: 'projectId' })
            .pipe(map(projects => ({ ...user, projects })));
        });
        combineLatest(this.entries).subscribe(combinedEntries => {
          this.entries = combinedEntries;
          console.log('Users with their projects:', this.entries);
        });


      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteLog(id) {
    console.log("delete " + id);
    this.afs.doc("/users/" + id).delete();
  }

  updatePlan(row) {
    console.log("updatePlan ", row);
    this.afs.doc("/users/" + row.id).update({ plan: row.plan });
  }
}

export class User {
  public name: string;
  public company: string;
  public street: string;
  public postcode: string;
  public city: string;
  public country: string;
  public taxid: string;
  email: string;
  lastLogin: any;
  registerDate: any;
  provider: string;
  uid: string;
  vms: any;
}

export class UsersDataSource extends MatTableDataSource<any> {
  data2: any[];

  constructor() {
    super();
  }

  disconnect() { }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
