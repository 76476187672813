<!-- <div [@slidepanel]="showleftpanel" [style.width.px]="leftpanelwidth" mwlResizable [resizeEdges]="{ right: true }"
    [validateResize]="validateResize" [resizeCursorPrecision]="5" [enableGhostResize]="true"
    [resizeSnapGrid]="{ left: 1, right: 1 }" (resizeEnd)="onResizeEnd($event)" [style.top]="getHeaderMenuHeight()+'px'"
    style="position: absolute;z-index:20; float:left; left: 0px;max-width:86%;padding:10px 0;background:white;padding:6px 12px 10px 12px;border-top-right-radius:7px; border-bottom-right-radius:7px;border-right: 4px dashed lightgray;border-radius: 0px;bottom: 0px;"
    class="m2at-elevation-z2 "> -->
<!-- <div style="position:relative;cursor:pointer;">
        <a id="infotoggle" class="infotoggle btn btn-primary" (click)="switchsidepanel()"
            style="  background-color: #95c21e;border-color: #84ac1b;position: absolute;  color:white; z-index: 100;    top: 10px;     right: -56px;    padding: 2px 1px; ">
            <i style="width:40px" class="fa  fa-2x fa-times"></i>
        </a>
    </div> -->

<!-- <div style="position:absolute;z-index:99">
     <ng-container style="font-size:9px;">
        <ng-template #recursiveList1 let-list>
            <ng-container *ngFor="let item of (list|keyvalue:keepOriginalOrder ) ; let i = index">
                <div style="padding-left:20px;border:1px solid gray;font-size: 8px;">{{i}} {{item.value.controlName}}
                </div>
                <ng-container *ngTemplateOutlet="recursiveList1; context:{ $implicit: item?.value?.childs  }">
                </ng-container>
            </ng-container>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList1; context:{ $implicit: jsongui?.childs  }">
        </ng-container>
    </ng-container> 
</div> -->



<!-- TABS -->
<!-- rekursion: https://gist.github.com/arniebradfo/5cf89c362cc216df6fc1d9ca4d536b72 -->
<ng-container style="font-size:9px;">

    <!-- <div>{{projectdata}}</div>
    <hr> -->

    <ng-template #recursiveList let-list>
        <!-- List2:{{list|keyvalue:keepOriginalOrder |json}} -->



        <ng-container *ngFor="let item of (list|keyvalue:keepOriginalOrder ) ; let i = index">
            <ng-container>
                <ng-container *ngIf="item?.value?.isVisible ">
                    <!-- # {{item.value.controlName}} -->
                    <mat-tab-group *ngIf="item.value.typ==='TAB'">
                        <mat-tab [label]="(item.value.label | translatesplit:lang)" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='TAB'">
                            <div class="panelcontent" *ngIf="item.value">
                                <ng-container *ngIf="item.value.childs ">
                                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.value.childs }">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div *ngIf="item?.value?.groupboxTyp==='EXPANSION'" style="margin-bottom:3px;">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]='false'>
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="font-weight:bold;">
                                        {{item?.value?.label | translatesplit:lang}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="overflow:hidden;" *ngIf="item?.value?.childs">
                                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item?.value?.childs   }"></ng-container>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <!-- label  -->
                    <div [id]="item.value.controlName+'-row'" [class]="item.value.cssclass" [class.selected_widget]="item.value.selected" *ngIf="item?.value?.typ==='LABEL'">
                        <div class="row">

                            <label [id]="item.value.controlName" class="col-sm-12 ">{{item.value.label |
                                translatesplit:lang}}</label>
                        </div>
                    </div>

                    <!-- slider -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item?.value?.typ==='SLIDER'">
                        <div class=" row">

                            <label class="col-sm-4">{{item.value.label | translatesplit:lang}}</label>
                            <mat-slider [id]="item.value.controlName" [min]="item.value.minValue" [max]="item.value.maxValue" step="1" (input)="sliderChange($event, item)" [(ngModel)]="item.value.outputValue">
                            </mat-slider>
                            <!-- (change)="controlC(item.value)" -->
                            {{item.value.outputValue}}
                            <!-- <ngx-slider (userChange)="controlC(item.value)" [(value)]="item.value.outputValue"
                                [options]="slideroptions"></ngx-slider> -->
                        </div>
                    </div>

                    <!-- Textbox -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item?.value?.typ==='TEXTBOX'">
                        <div class="form-group row">

                            <label class="col-sm-4">{{item.value.label | translatesplit:lang}}</label>
                            <input [id]="item.value.controlName" [class.inputedited]="item?.value?.edited" [class.inputisparam]="item?.value?.isparam" [id]="item.value.controlName" [(ngModel)]="item.value.outputValue"
                                (click)="controlC(item.value)" class="col-sm-7 form-control form-control-sm" (blur)="controlC(item.value)" (focus)="focusControl(item.value.controlName)" />

                            <span *ngIf="item.value.edited" style="    padding: 6px 3px;    height: 31px;    right: 20px;" (click)="controlC( { resetitem:true, item:item.value })"><i
                                    class="fa  far far fa-window-close"></i></span>

                        </div>
                    </div>

                    <!-- Checkbox -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" style="text-align:left;" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='CHECKBOX'"
                        style="text-align:center;">
                        <div class="form-group form-check row">

                            <input [id]="item.value.controlName" type="checkbox" style="margin-right:6px" [(ngModel)]="item.value.outputValue" (click)="controlC(item.value)"
                                (focus)="focusControl(item.value.controlName)" />
                            <label class="form-check-label" [for]="item.value.controlName">{{item.value.label |
                                translatesplit:lang}}</label>
                        </div>
                    </div>
                    <style class="cp-pen-styles">
                        .nopad {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }

                        /*image gallery*/
                        .image-checkbox {
                            cursor: pointer;
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            -webkit-box-sizing: border-box;

                            float: left;
                        }

                        .image-checkbox input[type="checkbox"] {
                            display: none;
                        }

                        .image-checkbox-checked {
                            border-color: #4783B0;
                        }

                        .image-checkbox .fa {
                            position: absolute;
                            color: #4A79A3;
                            background-color: #fff;
                            padding: 10px;
                            top: 0;
                            right: 0;
                        }

                        .image-checkbox-checked .fa {
                            display: block !important;
                        }
                    </style>
                    <!-- radio -->

                    <!-- <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                        <label class="form-check-label" for="d">Option one</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                        <label class="form-check-label" for="f">Option Two</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                        <label class="form-check-label" for="f">Option tree</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" disabled>
                        <label class="form-check-label" for="inlineRadio3">Option four (disabled)</label>
                    </div> -->
                    <!-- <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email">
                        </div>
                    </div> -->
                    <!-- <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='RADIO'" style="text-align:center;"> -->
                    <div *ngIf="item?.value?.typ==='RADIO' && !item.value?.label?.startsWith('http')" class="form-check form-check-inline" styl2e="float:left;margin-bottom:0;padding-left:8px;">
                        <!-- <label class="form-check-label" [for]="item.value.controlName">{{item.value.label | translatesplit:lang}}</label> -->
                        <img *ngIf="item.value.label.startsWith('http')" [style.border]="item.value.outputValue ? '2px solid red': '2px solid white'"
                            style="width:89px;float:left;margin-right:2px;padding:0px;border-radius:3px;" class="img-responsive" src="https://dummyimage.com/600x400/000/fff">
                        <input class="form-check-input" [style.display]="item?.value?.label?.startsWith('http') ? 'none':''" [id]="item.value?.controlName" name="group1" type="radio"
                            sty2le="margin-right:6px;display:none" [checked]="item.value?.outputValue" (change)="controlC(item.value)" />
                        <!-- <input name="image[]" value="" type="checkbox"> -->
                        <!-- <div *ngIf="!item.value.label.startsWith('http')" style="float:rightt;m2argin-right:20px;">{{item.value.label | translatesplit:lang}}</div> -->
                        <!-- <i class="fa fa-check hidden"></i> -->
                        <label *ngIf="!item.value.label.startsWith('http')" for="group1" class="form-check-label" clas2s="image-checkbox" clas2s="col-sm-2 col-form-label"
                            style2="width:112px;margin-bottom:0px;">{{item.value.label}}
                        </label>
                    </div>


                    <!-- radio -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='RADIO' && item.value?.label?.startsWith('http')"
                        style="text-align:center;">
                        <div class="form-group form-check row" style="float:left;margin-bottom:0;padding-left:8px;">
                            <!-- <label class="form-check-label" [for]="item.value.controlName">{{item.value.label | translatesplit:lang}}</label> -->
                            <label clas2s="image-checkbox" style="width:112px;margin-bottom:0px;">
                                <img *ngIf="item.value.label.startsWith('http')" [style.border]="item.value.outputValue ? '2px solid red': '2px solid white'"
                                    style="width:89px;float:left;margin-right:2px;padding:0px;border-radius:3px;" class="img-responsive" src="https://dummyimage.com/600x400/000/fff">
                                <input [style.display]="item.value.label.startsWith('http') ? 'none':'block'" [id]="item.value.controlName" name="group1" type="radio" style="margin-right:6px;display:none"
                                    [checked]="item.value.outputValue" (change)="controlC(item.value)" />
                                <!-- <input name="image[]" value="" type="checkbox"> -->
                                <div *ngIf="!item.value.label.startsWith('http')" style="float:left;margin-right:20px;">
                                    {{item.value.label | translatesplit:lang}}</div>
                                <!-- <i class="fa fa-check hidden"></i> -->
                            </label>
                        </div>
                    </div>

                    <!-- </div> -->

                    <div *ngIf="item.value.typ=='divider'" style="clear:both">

                    </div>

                    <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="favoriteSeason">
    <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season"> 
        {{season}}
    </mat-radio-button>
</mat-radio-group> -->

                    <!-- colorchooser -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='COLORCHOOSER'" style="text-align:center;">
                        <div class="form-group row">

                            <label class="col-sm-4">{{item.value.label | translatesplit:lang}}</label>
                            <input [id]="item.value.controlName" class="cpinput" name="test" [value]="item.value.outputValue" [style.background]="item.value.outputValue" [(colorPicker)]="item.value.outputValue"
                                (colorPickerChange)="controlC(item.value)" />
                            <!-- (chcolorPickerChangeange)="onChange()" -->
                        </div>
                    </div>

                    <!-- nbrDecimal -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item?.value?.typ==='NUMBER'">
                        <div class="form-group row">

                            <label class="col-sm-5">{{item.value.label| translatesplit:lang}}</label>

                            <div class="col-sm-5" *ngIf="item.value.isReadOnly ">
                                <div> {{(item.value.outputValue| number: '1.0-1' : 'en_US')| mcmpipe:item.value.unit}}
                                    {{item.value.unit|mcmunitpipe}}</div>
                            </div>

                            <div class="input-group input-group-sm col-sm-5" *ngIf="!item.value.isReadOnly && item.value.nbrDecimal > 0">
                                <input type="number" [id]="item.value.controlName" [class.inputedited]="item?.value?.edited" [class.inputisparam]="item?.value?.isparam" [(ngModel)]="item.value.outputValue"
                                    [min]="item.value.minValue" (blur)="controlC(item.value)" (focus)="focusControl(item.value.controlName)" [step]="1/(item.value.nbrDecimal)*1" [max]="item.value.maxValue"
                                    class="form-control form-control-sm" />
                                <div class="input-group-append" *ngIf="item.value.unit">
                                    <div class="input-group-text">{{item.value.unit|mcmunitpipe}}</div>
                                </div>
                            </div>

                            <div class="input-group input-group-sm col-sm-5" *ngIf="!item.value.isReadOnly && item.value.nbrDecimal===0">
                                <input type="number" [id]="item.value.controlName" [class.inputedited]="item?.value?.edited" [class.inputisparam]="item?.value?.isparam" [(ngModel)]="item.value.outputValue"
                                    [min]="item.value.minValue" (blur)="controlC(item.value)" (focus)="focusControl(item.value.controlName)" [step]="1" [max]="item.value.maxValue" class="form-control form-control-sm" />
                                <div class="input-group-append" *ngIf="item.value.unit">
                                    <div class="input-group-text">{{item.value.unit|mcmunitpipe}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Imagebox -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='IMAGE'" style="text-align:center;">
                        <img [id]="item.value.controlName" alt="img" [src]="item.value.outputValue" style="width:100%;max-height:200px;margin-bottom:10px;" />
                    </div>
                    <!-- Button -->
                    <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" [class.selected_widget]="item.value.selected" *ngIf="item.value.typ==='BUTTON'" style="text-align:center;">
                        <button [id]="item.value.controlName" class="btn btn-default form-group" (click)="controlC(item.value)">{{item.value.label|
                            translatesplit:lang}} </button>
                    </div>
                    <!-- Combobox -->
                    <div [class.selected_widget]="item.value.selected" *ngIf="item?.value?.typ==='SELECT'">
                        <div [class]="item.value.cssclass" [id]="item.value.controlName+'-row'" class="form-group row">
                            <label class="col-sm-4" [for]="item.value.controlName">{{item.value.label|
                                translatesplit:lang}}</label>

                            <select class="col-sm-7" [id]="item.value.controlName" [class.inputedited]="item?.value?.edited" [class.inputisparam]="item?.value?.isparam" [(ngModel)]="item.value.outputValue"
                                (focus)="focusControl(item.value.controlName)" (change)="controlC(item.value)" class="form-control form-control-sm col-sm-7">

                                <option *ngFor="let c of item.value.selectOptions" [ngValue]="c.name">{{c.wert}}
                                </option>
                            </select>
                            <span *ngIf="item.value.edited" style="    padding: 6px 3px;    height: 31px;    right: 20px;" (click)="controlC( { resetitem:true, item:item.value })"><i
                                    class="fa  far far fa-window-close"></i></span>
                        </div>
                    </div>

                    <!-- Produkt/ProduktNoLabel -->
                    <!-- <div
                                *ngIf="(item?.value?.typ==='Produkt' || item?.value?.typ==='ProduktNoLabel') && item?.value?.produkte">

                                <div class="form-group row" [hidden]="item?.value?.hideprodukte">
                                    <label [for]="item.value.controlName" class="col-sm-4">{{item.value.label|
                                        translatesplit:lang}}</label>
                                    <select classs="col-sm-7" [id]="item.value.controlName"
                                        [class.inputedited]="item?.value?.edited"
                                        [class.inputisparam]="item?.value?.isparam" [(ngModel)]="item.value.outputValue"
                                        class="form-control form-control-sm col-sm-7"
                                        (focus)="focusControl(item.value.controlName)"
                                        (change)="controlC(item.value, true)">
                                        <option *ngFor="let c of item.value.produkte" [ngValue]="c.produktname">
                                            {{c|translateproduct:lang}}</option>
                                    </select>
                                    <span *ngIf="item.value.edited"
                                        style="    padding: 6px 3px;    height: 31px;    right: 20px;"
                                        (click)="controlC( { resetitem:true, item:item.value },true)"><i
                                            class="fa  far far fa-window-close"></i></span>
                                </div>
                                <div class="form-group row" *ngIf="item.value.selectOptions?.length >1">
                                    <label [for]="item.value.controlName" class="col-sm-4"><span
                                            [hidden]="!item?.value?.hideprodukte">{{item.value.label|
                                            translatesplit:lang}}</span></label>
                                    <select class="col-sm-7" [id]="item.value.controlName+'b'"
                                        [class.inputedited]="item?.value?.edited"
                                        [class.inputisparam]="item?.value?.isparam"
                                        [(ngModel)]="item.value.outputVarianteValue"
                                        class="form-control form-control-sm col-sm-7"  
                                        (focus)="focusControl(item.value.controlName+'b')"
                                        (change)="controlC(item.value)">
                                        <option *ngFor="let c of item.value.selectOptions" [ngValue]="c.name">
                                            {{c|translateproduct:lang}}</option>
                                    </select>
                       
                                </div>
                            </div> -->
                    <!-- -- Table -- -->
                    <!-- removed -->

                    <!-- <ng-container
                            *ngIf="item?.value?.childs && !(item?.value?.groupboxTyp==='Accordium') && !(item?.value?.groupboxTyp==='Tab') && !(item?.value?.typ==='Grid')"> -->

                </ng-container>

                <ng-container *ngIf="item?.value?.typ != 'TAB' && item?.value?.typ != 'EXPANSION'">
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item?.value?.childs  }">
                    </ng-container>
                </ng-container>



            </ng-container>

        </ng-container>



    </ng-template>





    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: jsongui?.childs  }">
    </ng-container>
</ng-container>





<!-- </div> -->