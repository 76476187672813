import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  UPLOADPATH = '';
  showimages = true;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @Input() filter;

  thefilter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<any>;
  curpath = '/';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'uploadtime',
    'name',
    // "path",
    'functions'

  ];
  afauth: any;
  public options: any;
  viewmode = 'grid';
  entries: any;
  user: firebase.User;
  uploaddlg = false;
  lastfolder = '/';
  curfiles: any;
  curfolders: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: max-line-length
  constructor(public afs: AngularFirestore, public auth: AngularFireAuth, public http: HttpClient, private storage: AngularFireStorage, private _snackBar: MatSnackBar) {

    this.dataSource = new InfotableDataSource();
    this.dataSource.data = [];
    this.options = 'option';
    auth.authState.subscribe(user => {
      this.user = user;
      this.curpath = '/';
      //    this.afs.collection(this.curpath).valueChanges({ idField: 'id' }).subscribe(data => {
      // this.curfiles = data;

      this.changefolder(this.curpath);

      //      });
    });

  }

  public addfolder(name) {

    this.afs.doc(this.path2dbpath(this.curpath) + 'folders/' + name).set({ name: name, typ: 'folder', path: this.curpath + name + '/', uploadtime: new Date().getTime() });

  }
  public changefolder(url) {
    this.lastfolder = this.curpath;
    // /files/eSGhHUftYZWzhFNa50N4JN0UQ7r2/folders/folder1/folders/subfolder1/files
    console.log('changefolder', url);
    console.log('path2dbpath ', this.path2dbpath(url));
    this.curpath = url;

    var fs = [];
    this.afs.collection(this.path2dbpath(url) + 'folders').valueChanges({ idField: 'id' }) // , r => r.orderBy("uploadtime", "desc").limit(1000)
      .subscribe(x => {
        fs = x as any;
        this.dataSource.data = x as any;
        console.log(this.curpath, x);
        //      this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;



        this.afs.collection(this.path2dbpath(url) + 'files').valueChanges({ idField: 'id' }) // , r => r.orderBy("uploadtime", "desc").limit(1000)
          .subscribe(f => {
            //            var t = fs.push(...(f as any));
            var t = fs.concat(f);
            console.log(this.curpath, t);
            this.dataSource.data = t as any;
            this.dataSource.filterPredicate = (data: any, filter: string) => {
              filter = filter.trim(); // Remove whitespace
              filter = filter.toLowerCase();

              // include any of array
              // var a = filter.split(' ');
              // if (a[0] == 'gltf')
              //   a.push('glb');
              // var b = a.filter(x => data.name.toLowerCase().includes(x));

              // var result = (b.length > 0) || data.typ == "folder"
              return this.doFilter(data);// result;
            };

            this.dataSource.filter = this.filter;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });

      });


  }


  doFilter(data) {
    var a = this.filter.split(' ');
    if (a[0] == 'gltf')
      a.push('glb');
    if (a[0] == 'png') {
      a.push('jpg');
      a.push('jpeg');
      a.push('svg');

    }
    var b = a.filter(x => data.name.toLowerCase().includes(x));

    var result = (b.length > 0) || data.typ == "folder";
    return result;
  }
  deleteItem(item) {
    if (item.typ == 'folder') {
      // todo
    }

    if (item.typ == 'file') {
      var u = 'files/' + this.user.uid + item.path + item.name;
      console.log('u', u);
      this.storage.storage.refFromURL(item.url).delete();

      var p = this.path2dbpath(item.path) + 'files/' + item.id;
      console.log('p', p);
      this.afs.doc(p).delete();

      //     const storageRef = firebase.storage().ref();
      //      const uploadTask = storageRef.child(`${this.path}/${upload.file.name}`).put(upload.file);

    }

  }

  deleteFolder(path: string) {
    // var body = 'username=myusername&path=' + path;
    // this.http.post('https://us-central1-simulaton2.cloudfunctions.net/deletefolder', body)
    //   .pipe(map((response: any) => console.log('deletefolder respo: ', response.json())));
    console.log('del folder ', '/files/' + this.user?.uid + path);
    this.http.post<any>('https://us-central1-simulaton2.cloudfunctions.net/deletefolder', { path: '/files/' + this.user?.uid + path }).subscribe(data => {
      console.log('res ', data);
      var p = this.path2dbpath(path);
      console.log('p', p);
      this.afs.doc(p).delete();

    })
  }

  private path2dbpath(dbpath: string) {
    var s = dbpath.replace(/\//g, '/folders/');
    s = s.substring(0, s.length - 8);
    var p = 'files/' + this.user.uid + s;
    return p;
  }

  // todo: files in subdir, folders in collection, separat abrufen
  public backgroundUploaded(event) {
    console.log('background uploaded  ', event);
    //   this.afs.collection(this.curpath).add({ name: event.name, type: 'file', url: event.url, uploadtime: new Date().getTime() });

    this.afs.collection(this.path2dbpath(this.curpath) + 'files/').add({
      name: event.name, typ: 'file', path: this.curpath, url: event.url, uploadtime: new Date().getTime()
    }).then(x => {
      console.log('added file', x);

      var filename = event.name.toUpperCase();
      if (filename.endsWith('.GLB') || filename.endsWith('.GLTF')) {
        this.afs.collection("tasks").add({
          dbid: x.id,
          dbpath: this.path2dbpath(this.curpath) + 'files/',
          state: 'pending',
          tries: 0,
          name: event.name, typ: 'gltf', path: this.curpath, url: event.url, uploadtime: new Date().getTime()
        });
      }



      //   this.contactForm.value.logourl = event.url;
      //  this.contactForm.controls['logourl'].setValue(event.url);
      // this.layout.backgroundtype = "uploaded";
    });
  }



  public uploadFailed(event) {
    window.alert('Upload failed.' + event);
  }

  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);


  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  @Output() fileSelected = new EventEmitter<any>();
  fileChoosen(url) {
    this.fileSelected.emit(url);
  }

}

// TODO: Replace this with your own data model type
export interface LogtableItem {
  datetime: any;
  message: string;
  source: string;
  level: string;
  meta: any;
  data: any;
}

export class InfotableDataSource extends MatTableDataSource<LogtableItem> {


  constructor() {
    super();
  }

  disconnect() { }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}








@Component({
  selector: 'dialog-files-dialog',
  templateUrl: 'filesdialog.html',
})
export class FilesDialog {
  public disabled = false;

  public touchUi = false;
  public filter = '';

  constructor(public dialogRef: MatDialogRef<FilesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('dialog data', data);
    this.filter = data.filter;
  }
  fileselected(url) {
    this.dialogRef.close(url);
  }

  onNoClick(): void {
    this.dialogRef.close('test');
  }
}