import { AngularFirestore } from "@angular/fire/firestore";
import * as THREE from "three";
import { Utils } from "./utils";


// Workbook : VBAAPI
class VBAAPI {

    excels: any;
    hot: any;
    scene: any;


    hotdata: any;
    public ActiveWorkbook;
    editor: any;
    controls: any;
    project: any;
    public TransformControls: any;

    constructor(excels, hot, scene, hotdata, editor, controls, transformControls, project, private afs: AngularFirestore) {
        this.excels = excels;
        this.hot = hot;
        this.hotdata = hotdata;
        this.controls = controls;
        this.scene = scene;
        this.editor = editor;
        this.project = project;

        var key = Object.keys(this.excels);
        this.ActiveWorkbook = this.excels[key[0]];
        this.TransformControls = transformControls;

    }

    public async getCellPath(scenepath: string): Promise<string> {
        var cellpath = "";
        var parts = scenepath.split("^");   // 'root^$0#project37'
        var lastproject = this.project.id;
        for (var i = 1; i < parts.length; i++) {
            try {
                var part = parts[i];
                var sheetid = parseInt(part.split("#")[0].replace("$", ""));
                var cellid = part.split("#")[1];


                var object = this.scene.getObjectByName(scenepath);
                if (object) {
                    cellpath += object.name + "_";
                }
                var userdata = object.userData;

                var ca1 = await Utils.searchCellByValue(this.excels[lastproject], { col: 0, row: 30, sheet: sheetid }, cellid, 3);

                lastproject = userdata.subproject;
                // lastproject suchen



            } catch (error) {
                console.log("error", error);
            }

            //   console.log("ca", ca);


            //     cellpath = this.project.id + "_" + this.project.selectedSubprojectPath + "_" + part;
        }

        return cellpath;
    }

    public async createScripts() {


        var projectscriptcode = [];


        // Recursive function to collect script codes
        function collectScriptCodes(configurations, path = '') {
            for (var configKey in configurations) {
                if (configurations.hasOwnProperty(configKey)) {
                    var config = configurations[configKey];
                    var currentPath = path ? `${path}_${configKey}` : configKey;
                    // Push the script code from the current configuration along with its config key path
                    //   if (config.scriptcode) {
                    projectscriptcode.push({ script: config.scriptcode, path: currentPath, configid: config.id });
                    //  }
                    // Recursively call this function if there are nested configurations
                    if (config.configurations) {
                        collectScriptCodes(config.configurations, currentPath);
                    }
                }
            }
        }
        collectScriptCodes(this.project.configurations);
        console.log("projectscriptcode", projectscriptcode);

        for (var i = 0; i < projectscriptcode.length; i++) {
            try {
                var config = projectscriptcode[i];
                var configscriptcode = config.script;
                var configid = config.configid;
                var path = config.path;
                var code = `            
                    class js`+ configid + ` {
                        configid;
                        constructor(configid) {
                            console.log("constructor "+configid);
                            this.configid = configid;
                        }
                        
                        async project_created() {
                            console.log("project_created "+this.configid);
                        }
                        
                        async project_selected() {
                            console.log("selected");
                        }
                        
                        async project_deselected() {
                            console.log("deselected");
                        }

                        async SET(celladress, value) {
                            await this.ActiveWorkbook.setCellContents({ sheet: 0, row: ri, col: 0 }, value);
                            await this.updateUI();
                        }

          
                    }

 globalThis.` + configid + path + ` = new js` + configid + `('` + configid + `')
                    `;


                (0, eval)(code); // Indirect eval, executes in the global scope
                (0, eval)("console.log(globalThis." + configid + path + ")");
                (0, eval)(" globalThis." + configid + path + ".project_created()");
            } catch (error) {
                console.log("error", error);
            }

        }



        return;
        ////
        for (var configKey in this.project.configurations) {
            var config = this.project.configurations[configKey];
            var configscriptcode = config.scriptcode;
            projectscriptcode.push(configscriptcode);
            /////

            var boilerplate = `            
                    class js`+ config.id + ` {
                        configid;
                        constructor(configid) {
                            console.log("constructor "+configid);
                            this.configid = configid;
                        }
                        
                        async created() {
                            console.log("created "+this.configid);
                        }
                        
                        async selected() {
                            console.log("selected");
                        }
                        
                        async deselected() {
                            console.log("deselected");
                        }

                        async SET(celladress, value) {
                            await this.ActiveWorkbook.setCellContents({ sheet: 0, row: ri, col: 0 }, value);
                            await this.updateUI();
                        }

          
                    }

 globalThis.` + config.id + ` = new js` + config.id + `('` + config.id + `')
                    `;


            var code = boilerplate;
            //  eval(code);  // Direct eval, executes in the local scope
            //  (0, eval)(code); // Indirect eval, executes in the global scope
            //  (0, eval)("globalThis." + config.id + " = new config('" + config.id + "')"); // Indirect eval, executes in the global scope
            //  (0, eval)("globalThis." + config.id + ".created()"); // Indirect eval, executes in the global scope

            (0, eval)(code); // Indirect eval, executes in the global scope
            //  (0, eval)("globalThis." + config.id + " = new js" + config.id + "('" + config.id + "')"); // Indirect eval, executes in the global scope
            //   (0, eval)("globalThis." + config.id + ".created()"); // Indirect eval, executes in the global scope
            //      (0, eval)("console.log(js" + config.id + ")")
            //    (0, eval)("globalthis.test2= new js" + config.id + "('abc')")
            //   (0, eval)("var t = new js" + config.id + "('adsf')")
            //       (0, eval)("t.created()")
            (0, eval)("console.log(globalThis." + config.id + ")");
            (0, eval)(" globalThis." + config.id + ".created()");

        }


    }

    public async updateSheetUI() {

    }

    public async update3DScene() {

    }

    public async deactivateMouse() {
        this.controls.enableRotate = false;
        this.controls.enableZoom = false;
        this.controls.enablePan = false;

        // this.controls.mouseButtons =
        // {
        //     LEFT: null,
        //     MIDDLE: null,
        //     RIGHT: null
        // }
    }

    public async activateMouse() {
        // RODO::: je nach controlart
        //     this.controls.enableRotate = true;
        //      this.controls.enableZoom = true;
        //        this.controls.enablePan = true;

        // this.controls.mouseButtons =
        // {
        //     LEFT: THREE.MOUSE.ROTATE,
        //     MIDDLE: THREE.MOUSE.DOLLY,
        //     RIGHT: THREE.MOUSE.PAN
        // }
    }


    public async getFreeOutputRowsIndex(sheetindex = 1) {
        var dataset = this.hotdata;
        var outputrow = 10000;
        for (var i = 0; i < dataset?.length; i++)
            if (dataset[i][0] == "OUTPUTID") {
                outputrow = i + 1;
                break;
            }

        for (var i = outputrow; i < dataset?.length - 1; i++) {

            console.log("getFreeOutputRowsIndex", i, dataset[i][0], dataset[i + 1][0]);

            if (dataset[i][0] == "" && dataset[i + 1][0] == "") {
                console.log("###getFreeOutputRowsIndex");

                //  check if both rows of dataset are completely (each cell) empty
                var empty = true;
                for (var j = 0; j < dataset[i].length; j++)
                    if (dataset[i][j] != "") {
                        empty = false;
                        break;
                    }
                if (empty) {
                    for (var j = 0; j < dataset[i + 1].length; j++)
                        if (dataset[i + 1][j] != "") {
                            empty = false;
                            break;
                        }
                }
                if (empty) {
                    outputrow = i;
                    break;
                }
            }
        }

        return outputrow

    }


    public async getOutputRows(outputtype) {
        this.editor.addPrimitive('cube')
    }
    public async addOutputRows(outputtype, rowindex = -1) { // rowindex=-1 -> searches for free rowindex
        var ri = await this.getFreeOutputRowsIndex();
        var outputs = await this.getOutputRows(outputtype);
        //        var v = await ActiveWorkbook.getCellSerialized({ sheet: 0, row: 1, col: 1 });

        await this.ActiveWorkbook.setCellContents({ sheet: 0, row: ri, col: 0 }, outputs);


    }

    public async updateUI() {
        // update model ------------------------
        // // update controls
        // if (topleft.col != 2) {
        //     await this.updateControls(exportedchanges);
        // }
        console.log("updateUI");
        var changes = await this.ActiveWorkbook.getdatachanges();
        for (var i = 0; i < changes.length; i++) {
            //     console.log("datachanges", changes[i]);

            this.hotdata[changes[i].address.row][changes[i].address.col] = changes[i].newValue;

            //            changes[i].address.sheet

            // console.log("hotdata", this.hotdata);

        }
        await this.editor.updateModel(changes, null, this.scene);
        this.hot.render();
        //this.hotdata
    }




}


export default VBAAPI;

