<app-sidebar></app-sidebar>



<div style="backg2round-color:rgb(244, 244, 244);height:100%; padding: 50px 30px;" *ngIf="project">

    <div class="row">

        <h3 style="margin-left: 20px;margin-bottom:20px; float:left;">Configurations {{project?.name}}
            <a target="_blank" [href]="'p/'+project.id"><i class="fa fas fa-external-link-alt"></i></a>
        </h3>


    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="c2ard">
                <!-- <div class="card-header">
                    <h4 class="card-title">Configurations</h4>
                    <p class="card-category">

                    </p>
                </div> -->
                <div class="car2d-body" style="max-height: 85vh;overflow: auto;">

                    <mat-accordion multi>
                        <ng-container *ngFor="let configuration of configs">

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <table class="table">
                                        <tr>
                                            <td>
                                                {{configuration.enduserdata.savetime | date:'medium'}}
                                            </td>
                                            <td>
                                                <a [href]="'p/'+projectid+'/'+configuration.id">Config {{configuration.enduserdata.name}}</a>
                                            </td>
                                            <td>
                                                {{configuration.enduserdata.mail}}


                                                <a *ngIf="configuration.gltfurl" [href]="configuration.gltfurl">gltf</a>

                                            </td>
                                            <td>
                                                {{configuration.enduserdata.comment}}
                                                <button class="btn btn-primary" (click)="sendmail(configuration)">mail</button>
                                            </td>

                                        </tr>
                                    </table>
                                </mat-expansion-panel-header>

                                <table class="table">
                                    <tr>
                                        <td colspan="4">

                                            <table>
                                                <tr>
                                                    <ng-container *ngFor="let key of configuration.inputs; let i=index">

                                                        <th *ngIf="i>0">{{key}}</th>
                                                    </ng-container>
                                                </tr>
                                                <tr>
                                                    <ng-container *ngFor="let value of configuration.vals; let i=index">


                                                        <td *ngIf="i>0">{{value}}</td>
                                                    </ng-container>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </mat-expansion-panel>

                        </ng-container>
                    </mat-accordion>


                </div>
            </div>
        </div>
    </div>
</div>