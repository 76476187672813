import * as THREE from 'three';
import { Subject } from 'rxjs';

export interface InteractionHandlers {
    mouseEnter?: (object: THREE.Object3D) => void;
    mouseLeave?: (object: THREE.Object3D) => void;
    click?: (object: THREE.Object3D, event: MouseEvent) => void;
    doubleClick?: (object: THREE.Object3D, event: MouseEvent) => void;
    mouseDown?: (object: THREE.Object3D, event: MouseEvent) => void;
    mouseUp?: (object: THREE.Object3D, event: MouseEvent) => void;
    mouseMove?: (object: THREE.Object3D, event: MouseEvent) => void;
}

export class InteractionManager {
    private raycaster: THREE.Raycaster;
    private mouse: THREE.Vector2;
    private interactiveObjects: Set<THREE.Object3D>;
    private hoveredObject: THREE.Object3D | null;
    private selectedObject: THREE.Object3D | null;
    private container: HTMLElement;
    private lastClickTime: number = 0;
    private readonly DOUBLE_CLICK_DELAY = 300; // ms
    private _cursorType: string = 'default';
    public cursorTypeChange = new Subject<string>();

    get cursorType(): string {
        return this._cursorType;
    }

    set cursorType(value: string) {
        if (this._cursorType !== value) {
            this._cursorType = value;
            this.cursorTypeChange.next(value);
            document.body.style.cursor = value;
        }
    }

    constructor(
        private scene: THREE.Scene,
        private camera: THREE.Camera,
        private renderer: THREE.WebGLRenderer,
        cursorType: string = 'default'
    ) {
        this._cursorType = cursorType;
        console.log('Initializing InteractionManager');
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.interactiveObjects = new Set();
        this.hoveredObject = null;
        this.selectedObject = null;
        this.container = this.renderer.domElement;

        // Bind event handlers
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);

        // Add event listeners
        this.container.addEventListener('mousemove', this.handleMouseMove);
        this.container.addEventListener('click', this.handleClick);
        this.container.addEventListener('mousedown', this.handleMouseDown);
        this.container.addEventListener('mouseup', this.handleMouseUp);
    }

    add(object: THREE.Object3D, handlers: InteractionHandlers = {}) {
        console.log('Adding interactive object:', object.name || object.uuid);
        object.userData.handlers = handlers;
        this.interactiveObjects.add(object);
    }

    remove(object: THREE.Object3D) {
        console.log('Removing interactive object:', object.name || object.uuid);
        this.interactiveObjects.delete(object);
        if (this.hoveredObject === object) {
            console.log('Cleared hovered object reference');
            this.hoveredObject = null;
        }
        if (this.selectedObject === object) {
            console.log('Cleared selected object reference');
            this.selectedObject = null;
        }
    }

    private updateMousePosition(event: MouseEvent) {
        const rect = this.container.getBoundingClientRect();
        this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        //  console.debug('Mouse position updated:', { x: this.mouse.x, y: this.mouse.y });
    }

    private getIntersectedObject(event: MouseEvent): THREE.Object3D | null {
        this.updateMousePosition(event);
        this.raycaster.setFromCamera(this.mouse, this.camera);

        const intersects = this.raycaster.intersectObjects([...this.interactiveObjects], true);
        //       console.debug('Intersection test results:', intersects.length);

        // Filter out helper objects and invisible objects
        for (const intersect of intersects) {
            if (!intersect.object.userData.isHelper && intersect.object.visible) {
                //   console.debug('Found valid intersection with:', intersect.object.name || intersect.object.uuid);
                return intersect.object;
            }
        }

        return null;
    }

    private handleMouseMove(event: MouseEvent) {
        const intersectedObject = this.getIntersectedObject(event);

        if (intersectedObject) {
            if (this.hoveredObject !== intersectedObject) {
                console.log('Hover changed:', {
                    from: this.hoveredObject?.name || this.hoveredObject?.uuid || 'none',
                    to: intersectedObject.name || intersectedObject.uuid
                });
                // Mouse Leave for previous object
                if (this.hoveredObject) {
                    const handlers = this.hoveredObject.userData.handlers;
                    handlers?.mouseLeave?.(this.hoveredObject);
                }

                // Mouse Enter for new object
                const handlers = intersectedObject.userData.handlers;
                handlers?.mouseEnter?.(intersectedObject);
                handlers?.mouseMove?.(intersectedObject, event);
                this.hoveredObject = intersectedObject;
            } else {
                // Mouse Move on same object
                const handlers = intersectedObject.userData.handlers;
                handlers?.mouseMove?.(intersectedObject, event);
            }
        } else if (this.hoveredObject) {
            // Mouse Leave when no intersection
            const handlers = this.hoveredObject.userData.handlers;
            handlers?.mouseLeave?.(this.hoveredObject);
            this.hoveredObject = null;
        }
    }

    private handleClick(event: MouseEvent) {
        const intersectedObject = this.getIntersectedObject(event);
        const currentTime = Date.now();

        if (intersectedObject) {
            console.log('Click detected on:', intersectedObject.name || intersectedObject.uuid);
            const handlers = intersectedObject.userData.handlers;

            if (this.selectedObject === intersectedObject &&
                currentTime - this.lastClickTime < this.DOUBLE_CLICK_DELAY) {
                console.log('Double click detected');
                handlers?.doubleClick?.(intersectedObject, event);
            } else {
                console.log('Single click detected');
                handlers?.click?.(intersectedObject, event);
            }

            this.selectedObject = intersectedObject;
            this.lastClickTime = currentTime;
        }
    }

    private handleMouseDown(event: MouseEvent) {
        const intersectedObject = this.getIntersectedObject(event);
        if (intersectedObject) {
            console.log('Mouse down on:', intersectedObject.name || intersectedObject.uuid);
            const handlers = intersectedObject.userData.handlers;
            handlers?.mouseDown?.(intersectedObject, event);
        }
    }

    private handleMouseUp(event: MouseEvent) {
        const intersectedObject = this.getIntersectedObject(event);
        if (intersectedObject) {
            console.log('Mouse up on:', intersectedObject.name || intersectedObject.uuid);
            const handlers = intersectedObject.userData.handlers;
            handlers?.mouseUp?.(intersectedObject, event);
        }
    }

    dispose() {
        console.log('Disposing InteractionManager');
        this.container.removeEventListener('mousemove', this.handleMouseMove);
        this.container.removeEventListener('click', this.handleClick);
        this.container.removeEventListener('mousedown', this.handleMouseDown);
        this.container.removeEventListener('mouseup', this.handleMouseUp);
        this.interactiveObjects.clear();
        this.hoveredObject = null;
        this.selectedObject = null;
    }

    getHoveredObject(): THREE.Object3D | null {
        return this.hoveredObject;
    }

    getSelectedObject(): THREE.Object3D | null {
        return this.selectedObject;
    }
} 