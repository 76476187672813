import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent implements OnInit {
  customerid: any;
  //  @Input() projectid: string = "3bT1leJ7VRxz0PiHPajL";
  configs: any;
  projectid: string;

  project: any;
  constructor(public auth: AngularFireAuth, public afs: AngularFirestore, public route: ActivatedRoute) {

    // get route params
    this.route.params.subscribe(params => {
      this.projectid = params['projectid'];
      console.log('projectid', this.projectid);


      this.auth.authState.subscribe(async x => {
        this.customerid = x.uid;

        //     this.project.configmodel = this.configid;

        var dbpath = "projectspublic" + "/" + this.projectid;
        var pr = this.afs.doc(dbpath).valueChanges({ idField: "id" }).subscribe(data => {
          this.project = data;
        });

        dbpath = "/configs/" + this.customerid + "/" + this.projectid;
        var pr = this.afs.collection(dbpath).valueChanges({ idField: "id" }).subscribe(data => {

          this.configs = data;
          console.log('configs', this.configs);
          // this.project = pr.data();
          // this.project.id = pr.id;
        });

      });

    });

  }

  ngOnInit(): void {



  }

  sendmail(configuration: any) {
    this.afs.collection("mail")
      .add({
        to: configuration.enduserdata.mail,
        message: {
          subject: "Hello from Firebase!",
          text: "This is the plaintext section of the email body.",
          html: "This is the <code>HTML</code> section of the email body.",
        },
      })
      .then(() => console.log("Queued email for delivery!"));
  }

}
