<div style="text-align: center;">
    <div class="row">
        <div class="col-md-12">
            <iframe width="560" height="315" [src]="sanvideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <!-- <a href="{{curvideo}}" target="_blank">Link to video</a> -->
        </div>
    </div>
    <div class="row" style="padding:20px 30px;width:100%;">

        <div class="col-md-6" style="text-align: left;">

            <ul>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');"> 0) Quickstart 3-Min Quickstart</li>
                <li>&nbsp;</li>
                <li (click)="photoURL('https://www.youtube.com/embed/oRhuam0gDpM');"> 1) Introduction sheetBuild</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');"> 2) Introduction UI-Overview</li>
                <li>&nbsp;</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');"> 3) How To: Add Controls and Objects</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');"> 4) How To: Connect controls with 3D Model</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');"> 5) How To: Import 3D files</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');"> 6) How To: Create a parametrized Model</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');"> 7) How To: Projects, Sheets, Controls</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');"> 8) How To: 2D-Overlays</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');"> 9) How To: Charts</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');">10) How To: Timers</li>


            </ul>

        </div>

        <div class="col-md-6" style="text-align: left;">

            <ul>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');">11) How To: Import other projects</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');">12) How To: Publish a project</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');">13) How To: Save End User configurations</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');">14) How To: Change the User Interface Look</li>
                <li>&nbsp;</li>
                <li (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');">15) Expert Scripting Access the API</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');">16) Expert Scripting I Interaction in 3D Scene</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/JOpSUgLM4wQ');">17) Expert Scripting II Import external Data</li>

            </ul>

        </div>
    </div>


</div>