import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-cell-action-menu',
    templateUrl: './cell-action-menu.component.html',
    //    styleUrls: ['./cell-action-menu.component.scss']
})
export class CellActionMenuComponent {
    @Input() cellactiontop: string;
    @Input() cellactionleft: string;
    @Input() cellactionvisible: boolean;
    @Input() cellactionmenuvisible: boolean;
    @Input() curSelectedRow: number;
    @Input() outputrow: number;
    @Input() project: any;
    @Input() contextmenuitem: number;
    @Input() contextmenuitem1: number;

    @Output() addGuiEvent = new EventEmitter<string>();
    @Output() addPrimitiveEvent = new EventEmitter<string>();
    @Output() showfilesDialogEvent = new EventEmitter<any>();
    @Output() addProjectEvent = new EventEmitter<void>();

    showOverlaymenu1 = false;
    showSheetsmenu = false;
    showOverlaymenu = false;
    showothermenu = false;

    addGui(type: string) {
        this.addGuiEvent.emit(type);
    }

    addPrimitive(type: string) {
        this.addPrimitiveEvent.emit(type);
    }

    showfilesDialog(type: string, arg2?: boolean, arg3?: boolean) {
        this.showfilesDialogEvent.emit({ type, arg2, arg3 });
    }

    addProject() {
        this.addProjectEvent.emit();
    }

    showhidemenu() {
        this.cellactionmenuvisible = !this.cellactionmenuvisible;

        // Get window height and element height
        const windowHeight = window.innerHeight;
        const menuElement = document.querySelector('.cell-action-menu') as HTMLElement;
        const menuHeight = menuElement ? menuElement.offsetHeight : 200; // Use actual height if available, fallback to 200px

        // Calculate if menu would go off screen
        var top = parseInt(this.cellactiontop.replace('px', ''));
        const wouldGoOffScreen = (top + menuHeight) > windowHeight;

        // Position menu above or below based on available space
        if (wouldGoOffScreen) {
            // Position menu above the cell if not enough space below
            this.cellactiontop = (top - menuHeight - 10) + 'px';
        } else {
            // Position menu below the cell
            this.cellactiontop = (top + 10) + 'px'; // Add small padding
        }
    }
}
