import { AngularFirestore } from '@angular/fire/firestore';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';

export class CellStyleManager {
    constructor(private afs: AngularFirestore) { }

    setCellBackground(hot: any, dataset: any[][], sheetid: number, customerid: string, projectid: string, e: any) {
        try {
            const s = hot.getSelected() as any;
            const json = { background: e.color.hex };
            this.afs.collection(`/projects/userprojects/${customerid}/${projectid}/tdstyles/`).add({
                datetime: new Date().getTime(),
                sheetid: sheetid,
                s: JSON.stringify(s),
                e: json
            });

            for (let i = s[0][0]; i <= s[0][2]; i++) {
                for (let j = s[0][1]; j <= s[0][3]; j++) {
                    const cell = hot.getCell(i, j);
                    cell.style.background = e.color.hex;
                    let meta = hot.getCellMeta(i, j).stylemeta || {};
                    meta.background = e.color.hex;
                    hot.setCellMeta(i, j, 'stylemeta', meta);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    setCellForeground(hot: any, dataset: any[][], sheetid: number, customerid: string, projectid: string, e: any) {
        try {
            const s = hot.getSelected() as any;
            const json = { foreground: e.color.hex };
            this.afs.collection(`/projects/userprojects/${customerid}/${projectid}/tdstyles/`).add({
                datetime: new Date().getTime(),
                sheetid: sheetid,
                s: JSON.stringify(s),
                e: json
            });

            for (let i = s[0][0]; i <= s[0][2]; i++) {
                for (let j = s[0][1]; j <= s[0][3]; j++) {
                    const cell = hot.getCell(i, j);
                    cell.style.color = e.color.hex;
                    let meta = hot.getCellMeta(i, j).stylemeta || {};
                    meta.color = e.color.hex + "!important";
                    hot.setCellMeta(i, j, 'stylemeta', meta);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    applyCellMetaStyles(hot: Handsontable, dataset: any[][], sheetid: number, isEditMode: boolean, refs: any[], curSelectedRow: number, getOutputRow: () => number) {
        console.log("applyCellMetaStyles");
        if (!isEditMode) return;
        if (!dataset) return;

        const outputrow = getOutputRow();
        const rowcount = dataset.length;

        try {
            for (let i = 0; i < rowcount; i++) {
                const colcount = dataset[i].length;
                for (let j = 0; j < colcount; j++) {
                    try {
                        hot?.setCellMeta(i, j, 'className', "");
                    } catch (error) {
                        console.warn(error);
                    }
                }

                try {
                    hot?.setCellMeta(i, 0, 'className', "typ_cell");
                    hot?.setCellMeta(i, 1, 'className', "typ_cell");
                } catch (error) {
                    console.warn(error);
                }

                // input rows
                if ((dataset[i][0] != '' && dataset[i][0] != null) && i < outputrow) {
                    for (let j = 0; j < colcount; j++) {
                        try {
                            hot.setCellMeta(i, j, 'className', "parameters_cell");
                        } catch (error) {
                            console.warn(error);
                        }
                    }
                }


                // output rows
                if ((dataset[i][0] != '' && dataset[i][0] != null) && i > outputrow) {
                    for (let j = 0; j < colcount; j++) {
                        try {
                            if (dataset[i][0] == "#") {
                                hot.setCellMeta(i, j, 'className', "header_cell");
                            } else {
                                hot.setCellMeta(i, j, 'className', "parameters_cell");
                            }
                        } catch (error) {
                            console.warn(error);
                        }
                    }
                }

                if (dataset[i][0] == "OUTPUTID" || dataset[i][0] == "INPUTID") {
                    for (let j = 0; j < colcount; j++) {
                        try {
                            hot.setCellMeta(i, j, 'className', "head1_cell");
                        } catch (error) {
                            console.warn(error);
                        }
                    }
                }
            }

            for (let i = 0; i < refs?.length; i++) {
                const ref = refs[i];
                try {
                    hot.setCellMeta(ref.row, ref.col, 'className', 'cellref' + i);
                } catch (error) {
                    console.warn(error);
                }
            }
        } catch (err) {
            console.warn(err);
        }

        if (isEditMode) {
            hot.render();
        }
    }
}