import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, switchMap, delay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private taskSubject = new Subject<any>();
  private inputSubject = new Subject<string>();
  private subscriptions = new Subscription();
  isGenerating = false;
  lastInputValue: string | null = null;  // Store the last input value during generation


  constructor() {
    this.subscriptions.add(
      this.inputSubject.pipe(
        debounceTime(20),  // Debounce inputs to reduce frequency
        switchMap(inputValue => {
          if (this.isGenerating) {
            // If a generation is ongoing, save the latest input to be processed later
            this.lastInputValue = inputValue;
            return [] as any;  // Return an empty observable
          } else {
            // Start generation immediately if nothing is currently generating
            return this.generateModel(inputValue);
          }
        })
      ).subscribe(model => {
        console.log('subscribption', model)
        if ((model as any).length > 0) {
          console.log('Model generated:', model);
        }
        this.isGenerating = false;
        // Check if there is a queued last input to process next
/*         if (this.lastInputValue !== null) {
          let nextInput = this.lastInputValue;
          this.lastInputValue = null;  // Reset last input value
          this.inputSubject.next(nextInput);  // Re-emit the last input
        }
 */      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addTask(value: string): void {
    console.log(`Task added: ${value}`);
    this.inputSubject.next(value);
  }

  /*   addTask(task) {
  
    }
   */



  private generateModel(inputValue: string): Observable<any> {
    this.isGenerating = true;
    return new Observable(observer => {
      // Simulate a time-consuming task
      setTimeout(() => {
        console.log(`Model generated for input: ${inputValue}`);
        observer.next(`Model generated for input: ${inputValue}`);
        this.isGenerating = false;
        observer.complete();

      }, 2000);  // simulate delay, representing a long task
    });
  }
  executeTask(task) {

    console.log(`Executing task: ${task}`);
  }
}
