import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';

export class ProjectManager {
    private static readonly SERVER = "https://us-central1-xbuild3d.cloudfunctions.net";

    static async saveProject(
        project: any,
        camera: any,
        customerid: string,
        projectid: string,
        afs: AngularFirestore
    ): Promise<void> {
        project.camera = JSON.stringify(camera.matrix.toArray());
        project.camerazoom = camera.zoom;
        project.lastsave = new Date().getTime();
        await afs.doc(`projects/userprojects/${customerid}/${projectid}`).update(JSON.parse(JSON.stringify(project)));
    }

    static async saveCells(
        customerid: string,
        projectid: string,
        sheetid: number,
        cellformulas: any,
        datasetids: any[],
        http: HttpClient,
        snackBar: MatSnackBar,
        zone: NgZone
    ): Promise<void> {
        console.log('saveCells', { customerid, projectid, sheets: [cellformulas], cells: cellformulas, datasetids });

        let attempt = 0;
        let r;
        while (attempt < 12) {
            try {
                r = await http.post(this.SERVER + "/applyCellUpdates", {
                    customerid,
                    projectid,
                    sheetid,
                    sheets: [cellformulas],
                    cells: cellformulas,
                    datasetids
                }).toPromise();
                if (r) break;
            } catch (e) {
                console.warn('Attempt ' + (attempt + 1) + ' failed', e);
            }
            attempt++;
            await new Promise(resolve => setTimeout(resolve, 400));
        }

        console.log('applyCellUpdates result', r);
        zone.run(() => {
            snackBar.open("Project saved", "", { duration: 1000 });
        });
    }

    static async cloneProject(
        project: any,
        customerid: string,
        http: HttpClient,
        afs: AngularFirestore,
        snackBar: MatSnackBar
    ): Promise<void> {
        const p = JSON.parse(JSON.stringify(project));
        const pid = p.id;
        delete p.id;
        p.name = p.name + "_copy_";
        let currentDate = new Date();
        p.name += currentDate.getFullYear() + "-" +
            ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" +
            ("0" + currentDate.getDate()).slice(-2) + " " +
            ("0" + currentDate.getHours()).slice(-2) + "-" +
            ("0" + currentDate.getMinutes()).slice(-2);
        p.createtime = new Date().getTime();
        p.lastsave = p.createtime;

        const newdoc = await afs.collection(`projects/userprojects/${customerid}`).add(p);
        const npid = newdoc.id;

        let attempt = 0;
        const maxAttempts = 7;
        const attemptInterval = 400; // milliseconds

        const cloneProjectInternal = async () => {
            try {
                const response = await http.post(this.SERVER + "/copySheets", {
                    customerid: customerid,
                    projectid: pid,
                    newprojectid: npid,
                    sheetscount: 5
                }).toPromise();
                console.log('clone result', response);
                snackBar.open("Project duplicated. Go To Dashboard to load copy.", null, { duration: 3000 });
            } catch (error) {
                if (attempt < maxAttempts) {
                    setTimeout(() => {
                        attempt++;
                        cloneProjectInternal();
                    }, attemptInterval);
                } else {
                    console.error('Failed to clone project after ' + maxAttempts + ' attempts', error);
                    snackBar.open("Failed to duplicate project. Please try again.", null, { duration: 3000 });
                }
            }
        };

        await cloneProjectInternal();
    }
}