import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-admindash',
  templateUrl: './admindash.component.html',
  styleUrls: ['./admindash.component.scss']
})
export class AdmindashComponent implements OnInit {


  news: any;
  newtitle: string;
  newcontent: string;

  constructor(public afs: AngularFirestore) {
    this.afs.collection('news').valueChanges({
      idField: 'id'
    }).subscribe(data => {
      console.log(data);
      this.news = data;
    });


  }

  ngOnInit(): void {
  }

  addnews() {
    this.afs.collection('news').add({
      title: this.newtitle,
      content: this.newcontent,
      datetime: new Date().getTime()
    });


  }

  deletenews(n) {
    this.afs.collection('news').doc(n.id).delete();

  }
}