import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-circle',
  templateUrl: './Circle.component.html',
  styleUrls: ['./Circle.component.scss']
})
export class CirclecontrolComponent implements OnInit {


  @Input() angle; // Define an Input property
  handlePosition = { x: 140, y: 60 }; // Default position on the circle's edge
  private isDragging = false;
  private svgCenter = { x: 100, y: 60 }; // Center of the SVG element
  private radius = 40; // Radius of the control circle
  @Output() angleChanged = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    if (this.angle) {
      this.handlePosition.x = this.svgCenter.x + this.radius * Math.cos(this.angle);
      this.handlePosition.y = this.svgCenter.y + this.radius * Math.sin(this.angle);

    }
  }


  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    event.preventDefault(); // Prevent default to avoid text selection etc.
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;

    // Get the bounding rectangle of the SVG to calculate positions relative to it
    const svg = (event.target as HTMLElement).closest('svg');
    if (svg) {
      const svgRect = svg.getBoundingClientRect();
      const mouseX = event.clientX - svgRect.left;
      const mouseY = event.clientY - svgRect.top;

      // Calculate angle using atan2 on the mouse position relative to the SVG center
      const angle = Math.atan2(mouseY - this.svgCenter.y, mouseX - this.svgCenter.x);
      // Update handle position based on the angle, keeping it on the circumference
      this.handlePosition.x = this.svgCenter.x + this.radius * Math.cos(angle);
      this.handlePosition.y = this.svgCenter.y + this.radius * Math.sin(angle);

      this.angleChanged.emit(angle);
    }

  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (this.isDragging) {
      this.isDragging = false;
    }
  }

}