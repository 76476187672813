
class SnippetManager {

    public codeSnippets = [

        {
            name: "set cell",
            code: 'async function setCell () {\n\tawait ActiveWorkbook.setCellContents({ sheet: 0, row: 1, col: 1 }, "hello world");\n\tawait API.updateUI();\n}\n\n'
        },
        {
            name: "edit 3D scene",
            code: "async function readCell () {\n\t\n}\n\n"
        },

        //         async function test1() {

        //             var i = 0;
        //             let myinterval = setInterval(async () => {
        //                 console.log('i ' + i++);
        //                 await ActiveWorkbook.setCellContents({ sheet: 0, row: 1, col: 0 }, i / 15.0 * 4)
        //                 await API.updateUI();

        //                 if (i === 1) {
        //                     clearInterval(myinterval);
        //                 }
        //             }, 10);
        //         }

        // async function select(object, event) {
        //             console.log("#select", object, event);
        //             await API.TransformControls.attach(sceneobjectSelected);
        //             console.log(API.TransformControls)
        //             scene.add(API.TransformControls);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 29, col: 6 }, sceneobjectSelected.position.x)
        //             await API.updateUI();
        //         }
        // async function deselect(object, event) {
        //             console.log("#deselect", object.object, event);

        //             await API.TransformControls.detach(sceneobjectDeSelected);
        //             console.log(API.TransformControls)
        //             scene.remove(API.TransformControls);
        //             //  await ActiveWorkbook.setCellContents({ sheet: 0, row: 29, col: 6 }, enteredobject.position.x ) 
        //             await API.updateUI();
        //         }



        // // object highlighht on hover and selection on click
        // async function enter(object, event) {

        //         }
        // async function leave(object, event) {
        //             console.log("#leave", object, event);
        //             //    API.TransformControls.detach(object);
        //             //   await ActiveWorkbook.setCellContents({ sheet: 0, row: 32, col: 5 }, "gray") 
        //             await API.updateUI();
        //         }



        // // drag arrow
        // async function startdrag(object, event) {
        //             console.log("#startdrag", object, event);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 6 }, true);
        //             await API.updateUI();
        //         }
        // async function drag(object, event) {
        //             //  API.deactivateMouse();   
        //             // var dragging = await ActiveWorkbook.getCellSerialized({ sheet: 0, row: 28, col: 6 }); 
        //             console.log("#drag", object, event);

        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 7 }, event.point.y)
        //             await API.updateUI();
        //             //      API.deactivateMouse();   

        //         }
        // async function enddrag(object, event) {
        //             console.log("#enddrag", object, event);
        //             await ActiveWorkbook.setCellContents({ sheet: 0, row: 28, col: 6 }, false);
        //             await API.updateUI();
        //             //  API.activateMouse();
        //         }

    ]

    public snippets = [
        {
            name: "head1",
            values: [
                ["INPUTID", "TYP", "PARAMETERS", "VALIDATED", "", "", "LABEL", "UNIT", "", "", "", "", "", "PARENT", ""],
            ],
            format: [
                ["head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell", "head1_cell"],
            ]
        },



        // input widgets
        // 'label', 'textbox', 'number', 'image', 'select', 'checkbox','slider', 'expansion', 'tab','stepper','window'

        {
            name: "label",
            values: [
                ["label", "mylabel", "=##", "", "Label", true]
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "textbox",
            values: [
                ["textbox", "mytext", "=##", "", "Textbox", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "number",
            values: [
                ["number", "5", "=IF(##<0;0;##)", "", "Number", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "image",
            values: [
                ["image", "dummyimage.png", "=##", "", "Image", true]
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "select",
            values: [
                ["select", "entry1", '=##', "", "Select", true, "", "", "entry1;entry2;entry3"] // if-Verschachtelung zu kompliziert?
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "checkbox",
            values: [
                ["checkbox", false, "=##", "", "Checkbox", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "radio",
            values: [
                ["radio", false, "=##", "", "Radio", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "slider",
            values: [
                ["slider", 5, "=##", "", "Slider", true, 0, 10] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "colorchooser",
            values: [
                ["colorchooser", "#442200", "=##", "", "Color", true, 0, 10] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "expansion",
            values: [
                ["expansion", "1", "=##", "", "Expansion", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "tab",
            values: [
                ["tab", "1", "=##", "", "Tab", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "stepper",
            values: [
                ["stepper", "1", "=##", "", "Stepper", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "window",
            values: [
                ["window", "1", "=##", "", "Window", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "button",
            values: [
                ["button", "1", "=##", "", "Button", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            name: "savebutton",
            values: [
                ["button", "save", "save", "", "Save", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },
        {
            //            timer23	timer	40		6.10780000000075		true
            name: "timer",
            values: [
                ["timer", "40", "=##", "", "Timer", true] // ## ersetzt das Feld durch die Zellenadresse beim Einfügen
            ],
            format: [
                ["typ_cell", "parameters_cell", "value_cell", "validated_cell", "parameters_cell", "parameters_cell"],
            ]
        },

        // 3d outputs
        {
            name: "line",
            values: [
                ["", "p1", "p2", "width", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["line", "(0,0,50)", "(0,100,50)", 5, "black", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Line",
                params: [
                    { name: 'p1', info: 'Start point, Format: (x,y,z)' },
                    { name: 'p2', info: 'End point, Format: (x,y,z)' },
                    { name: 'width', info: 'Width, Format (x)' },
                    { name: 'material', info: 'test', link: "https://sheetbuild.com/documentation/" },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "shape",
            values: [
                ["", "points", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["shape", "(0,0);(0,100);(50,100);(0,50)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Shape (2d)",
                params: [
                    { name: 'points', info: 'List of points, Format: (x1,y1,z1);(x2,y2,z2);...' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "image3d",
            values: [
                ["", "filename", "points", "width", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["image3d", "dummy.png", "(0,0);(0,100);(50,100);(0,50)", 300, 200, "#555555", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Image",
                params: [
                    { name: 'points', info: 'List of points, Format: (x1,y1,z1);(x2,y2,z2);...' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        }, {
            name: "surface",
            values: [
                ["", "points", "indices", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["surface", "(0,0,0);(0,0,0);(0,0,0);(0,0,0);(0,0,0)", "(1,2,3)", "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Surface",
                params: [
                    { name: 'points', info: 'List of points, Format: (x1, y1, z1);(x2, y2, z2); ...' },
                    { name: 'indices', info: 'List of point indices, 3 indices create a tringale', link: "https://sheetbuild.com/documentation/" },
                    { name: 'material', info: '', link: "https://sheetbuild.com/documentation/" },

                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "cube",
            values: [
                ["", "width", "length", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["cube", 100, 100, 100, "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Cube",
                params: [
                    { name: 'width', info: 'width off the cube' },
                    { name: 'length', info: 'length off the cube' },
                    { name: 'height', info: 'height off the cube' },
                    { name: 'material', info: 'The material, <a target="_blank" href="https://sheetbuild.com/">help</a>' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "sphere",
            values: [
                ["", "radius", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["sphere", 10, "#550000", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Sphere",
                params: [
                    { name: 'Radius', info: 'radius off the sphere' },
                    { name: 'material', info: 'The material, <a target="_blank" href="https://sheetbuild.com/">help</a>' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "extrusion",
            values: [
                ["", "baseplane", "extrusionvector", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["extrusion", "(0,0);(100,0);(200,100);(0,100)", "(0,0,300)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Extrusion",
                params: [
                    { name: 'baseplane', info: 'defines the exturded shape,  Format: (x1,y1);(x2,y2);...' },
                    { name: 'extrusionvector', info: 'Format: (x,y,z)' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                    { name: 'material', info: '', link: "https://sheetbuild.com/documentation/" },
                    {
                        name: '', info: '', link: "https://sheetbuild.com/documentation/"
                    },
                ]
            }

        },

        {
            name: "cylinder",
            values: [
                ["", "radiusTop", "radiusBottom", "height", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["cylinder", 50, 50, 200, "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "header_cell", "header_cell", "header_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Cylinder",
                params: [
                    { name: 'radiusTop', info: 'radius on the top' },
                    { name: 'radiusBottom', info: 'radius on the bottom' },
                    { name: 'height', info: 'the height/length' },
                    { name: 'material', info: 'The material, <a target="_blank" href="https://sheetbuild.com/">help</a>' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }

        },
        {
            name: "lathe",
            values: [
                ["", "points", "material", "x", "y", "z", "rx", "ry", "rz"],
                ["lathe", "(0,0);(100,0);(0,100)", "#445566", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Lathe",
                params: [
                    { name: 'points', info: '' },
                    { name: 'material', info: 'The material, <a target="_blank" href="https://sheetbuild.com/">help</a>' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "subtraction",
            values: [
                ["", "cuttedoutputids", "cutoutputids", "x", "y", "z", "rx", "ry", "rz"],
                ["subtraction", "extrusion1,extursion2", "extrusion3", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "subtraction",
                params: [
                    { name: 'cuttedoutputids', info: 'List of  Ids of cutted objects' },
                    { name: 'cutoutputids', info: 'List of objects which cut' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "textflat",
            values: [
                ["", "textflat", "fontsize", "color", "x", "y", "z", "rx", "ry", "rz"],
                ["textflat", "Your Text", 8, "#222222", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Textflat",
                params: [
                    { name: 'textflat', info: 'The text (content)' },
                    { name: 'fontsize', info: 'size' },
                    { name: 'color', info: 'the color' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },

        {
            name: "group",
            values: [
                ["", "outputids", "x", "y", "z", "rx", "ry", "rz"],
                ["group", "extrusion1,extrusion2", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Group",
                params: [
                    { name: 'outputids', info: 'List of  Ids of objects' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        },
        {
            name: "light",
            values: [
                ["", "intensity", "penumbra", "color", "x", "y", "z", "rx", "ry", "rz"],
                ["light", 200, 100, "#ffffff", 0, 0, 0, 0, 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Light",
                params: [
                    { name: 'intensity ', info: 'rlight intensity, valuues 1-100' },
                    { name: 'penumbra', info: 'penumbra, value 1-100' },
                    { name: 'x,y,z...', info: 'position, scale, rotation' },
                ]
            }
        }, {
            name: "gltf",
            values: [
                ["", "filename", "x", "y", "z", "rx", "ry", "rz", "sx", "sy", "sz"],
                ["gltf", "/assets/test.gltf", 0, 0, 0, 0, 0, 0, 1, 1, 1],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "gltf/glb 3D File",
                params: [
                    { name: 'filename', info: 'url of the file' },

                    { name: 'x,y,z...', info: 'position, scale, rotation' },


                    { name: 'node=[name]', info: 'name of the gltf node, property e.g.: "visible=FALSE" <br><a target="_blank" href="https://sheetbuild.com/">help</a>' },


                ]
            }
        },
        // ---------------- overlays ------------------
        {
            name: "overlay",
            values: [
                ["", "width", "height", "backgroundcolor", "x", "y"],
                ["overlay", "80%", "400px", "#ffffff", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Overlay",
                params: [
                    { name: 'width', info: 'rectangle width of background' },
                    { name: 'height', info: 'rectangle height of background' },
                    { name: 'backgroundcolor', info: 'the background color' },
                    // { name: 'color', info: 'text color' },
                    { name: 'material', info: 'The material, <a target="_blank" href="https://sheetbuild.com/">help</a>' },
                    { name: 'x,y', info: 'position' },
                ]
            }
        },
        {
            name: "chart",
            values: [
                ["", "width", "height", "backgroundcolor", "type", "values", "x", "y"],
                ["chart", "600px", "400px", "#ffffff", "bar", "A2:D7", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ],
            infos: {
                header: "Chart",
                params: [
                    { name: 'width', info: 'radius on the top' },
                    { name: 'height', info: 'radius on the bottom' },
                    { name: 'backgroundcolor', info: 'the height/length' },
                    { name: 'type', info: 'bar chart' },
                    { name: 'values', info: 'cell range of values, format  ([topleft cell]:[rightdown cell])' },
                    { name: 'x,y', info: 'position' },
                ]
            }
        },

        //https://docs.google.com/spreadsheets/d/1SW_Ic11KG-GsFqQziNL9n9ROAzLugXCCf6bzxDA5O-0/edit#gid=0
        // line2D
        // circle2D
        // polyline2D
        // path2D
        // group

        {
            name: "rectangle2d",
            values: [
                ["", "width", "height", "backgroundcolor", "border", "bordercolor", "x", "y"],
                ["rectangle2d", "400px", "150px", "#dddddd", "2px", "#bbbbbb", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },
        {
            name: "text2d",
            values: [
                ["", "text", "width", "height", "color", "fontsize", "backgroundcolor", "x", "y"],
                ["text2d", "yourtext", "200px", "100px", "#000000", "22px", "#ffffff", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },
        {
            name: "image2d",
            values: [
                ["", "width", "height", "url", "x", "y"],
                ["image", "400px", "300px", "", 0, 0],
            ],
            format: [
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"],
                ["typ_cell", "parameters_cell", "parameters_cell", "parameters_cell", "standard_parameters_cell", "standard_parameters_cell"]
            ]
        },




    ]

}


export { SnippetManager }