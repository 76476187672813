import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputdialogComponent } from 'src/app/inputdialog/inputdialog.component';

@Component({
  selector: 'app-dialogdisclaimer',
  templateUrl: './dialogdisclaimer.component.html',
  styleUrls: ['./dialogdisclaimer.component.scss']
})
export class DialogdisclaimerComponent implements OnInit {


  disclaimer;

  constructor(public dialogRef: MatDialogRef<DialogdisclaimerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.disclaimer = data.disclaimer;
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
