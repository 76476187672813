<h1 mat-dialog-title>{{data.head}}</h1>
<div mat-dialog-content>
    <!-- <p>What's your favorite animal?</p> -->
    <mat-form-field appearance="fill">
        <mat-label>{{data.property}}</mat-label>
        <input matInput [(ngModel)]="data.value">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.value" class="btn btn-primary" style="background:#8C30F5;color: white !important;;" cdkFocusInitial>{{data.okbutton}}</button>
</div>