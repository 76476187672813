import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  curvideo = 'https://www.youtube.com/embed/JOpSUgLM4wQ';


  constructor(public sanitizer: DomSanitizer, public ref: ChangeDetectorRef, public appRef: ApplicationRef) {
    this.sanvideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.curvideo);

  }

  sanvideo: any;

  photoURL(newurl) {
    console.log('photo url');
    if (newurl != this.curvideo) {
      this.curvideo = newurl;
      this.sanvideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.curvideo);
      //   this.ref.detectChanges();
      this.appRef.tick();
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    console.log('after view init');
    // this.curvideo = 'https://www.youtube.com/embed/JOpSUgLM4wQ';
    // this.appRef.tick();
    // this.ref.detectChanges();

  }
}
