exports.nodes = {
    root: [
        {
            "id": "buildings",
            "name": "Buildings",
            "children": [
                {
                    "id": "buildings-house",
                    "name": "House",
                    "children": [
                        {
                            "id": "buildings-house-residential",
                            "name": "Residential"
                        },
                        {
                            "id": "buildings-house-garage",
                            "name": "Garage"
                        }
                    ]
                },
                {
                    "id": "buildings-commercial",
                    "name": "Commercial",
                    "children": [
                        {
                            "id": "buildings-commercial-office",
                            "name": "Office"
                        },
                        {
                            "id": "buildings-commercial-retail",
                            "name": "Retail"
                        }
                    ]
                },
                {
                    "id": "buildings-industrial",
                    "name": "Industrial",
                    "children": [
                        {
                            "id": "buildings-industrial-warehouse",
                            "name": "Warehouse"
                        },
                        {
                            "id": "buildings-industrial-factory",
                            "name": "Factory"
                        }
                    ]
                }
            ]
        },
        {
            "id": "machine-parts",
            "name": "Machine Parts",
            "children": [
                {
                    "id": "bearings",
                    "name": "Bearings",
                    "children": [
                        {
                            "id": "bearings-ball",
                            "name": "Ball Bearings"
                        },
                        {
                            "id": "bearings-roller",
                            "name": "Roller Bearings"
                        },
                        {
                            "id": "bearings-thrust",
                            "name": "Thrust Bearings"
                        }
                    ]
                },
                {
                    "id": "profiles",
                    "name": "Profiles",
                    "children": [
                        {
                            "id": "profiles-round",
                            "name": "Round"
                        },
                        {
                            "id": "profiles-squared",
                            "name": "Squared"
                        },
                        {
                            "id": "profiles-ibeam",
                            "name": "I-Beam"
                        },
                        {
                            "id": "profiles-channel",
                            "name": "Channel"
                        }
                    ]
                },
                {
                    "id": "connections",
                    "name": "Connections",
                    "children": [
                        {
                            "id": "connections-profiles",
                            "name": "Profile connections"
                        },
                        {
                            "id": "connections-other",
                            "name": "Other"
                        },
                        {
                            "id": "connections-bolts",
                            "name": "Bolts"
                        },
                        {
                            "id": "connections-welds",
                            "name": "Welds"
                        }
                    ]
                },
                {
                    "id": "gears",
                    "name": "Gears",
                    "children": [
                        {
                            "id": "gears-spur",
                            "name": "Spur Gears"
                        },
                        {
                            "id": "gears-helical",
                            "name": "Helical Gears"
                        },
                        {
                            "id": "gears-bevel",
                            "name": "Bevel Gears"
                        }
                    ]
                }
            ]
        },
        {
            "id": "science",
            "name": "Science",
            "children": [
                {
                    "id": "molecules",
                    "name": "Molecules",
                    "children": [
                        {
                            "id": "molecules-organic",
                            "name": "Organic"
                        },
                        {
                            "id": "molecules-inorganic",
                            "name": "Inorganic"
                        }
                    ]
                },
                {
                    "id": "math",
                    "name": "Math",
                    "children": [
                        {
                            "id": "math-nurbssurface",
                            "name": "Nurbs Surface"
                        },
                        {
                            "id": "math-exponentialcurve",
                            "name": "Exponential Curve"
                        },
                        {
                            "id": "math-bezier",
                            "name": "Bezier Curves"
                        },
                        {
                            "id": "math-splines",
                            "name": "Splines"
                        }
                    ]
                },
                {
                    "id": "physics",
                    "name": "Physics",
                    "children": [
                        {
                            "id": "physics-mechanics",
                            "name": "Mechanics"
                        },
                        {
                            "id": "physics-thermodynamics",
                            "name": "Thermodynamics"
                        }
                    ]
                }
            ]
        },
        {
            "id": "tools",
            "name": "Tools",
            "children": [
                {
                    "id": "tools-measurement",
                    "name": "Measurement",
                    "children": [
                        {
                            "id": "tools-measurement-calipers",
                            "name": "Calipers"
                        },
                        {
                            "id": "tools-measurement-gauges",
                            "name": "Gauges"
                        }
                    ]
                },
                {
                    "id": "tools-cutting",
                    "name": "Cutting",
                    "children": [
                        {
                            "id": "tools-cutting-mills",
                            "name": "Mills"
                        },
                        {
                            "id": "tools-cutting-drills",
                            "name": "Drills"
                        }
                    ]
                }
            ]
        },
        {
            "id": "other",
            "name": "Other"
        }
    ]
};