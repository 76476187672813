import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent implements OnInit, OnDestroy {
  customerid: any;
  //  @Input() projectid: string = "3bT1leJ7VRxz0PiHPajL";
  configs: any;
  projectid: string;
  project: any;

  // Subscriptions to be cleaned up
  private subscriptions: Subscription[] = [];

  constructor(
    public auth: AngularFireAuth,
    public afs: AngularFirestore,
    public route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    // Get route params once
    const routeSub = this.route.params.subscribe(async params => {
      this.projectid = params['projectid'];
      console.log('projectid', this.projectid);

      await this.loadData();
    });

    this.subscriptions.push(routeSub);
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async loadData(): Promise<void> {
    try {
      // Get current user
      const user = await this.auth.authState.pipe(first()).toPromise();
      if (!user) return;

      this.customerid = user.uid;

      // Load project data
      const projectPath = "projectspublic" + "/" + this.projectid;
      this.project = await this.afs.doc(projectPath)
        .valueChanges({ idField: "id" })
        .pipe(first())
        .toPromise();

      // Load configs
      const configsPath = "/configs/" + this.customerid + "/" + this.projectid;
      this.configs = await this.afs.collection(configsPath)
        .valueChanges({ idField: "id" })
        .pipe(first())
        .toPromise();

      // Sort configs by savetime in descending order (newest first)
      if (this.configs) {
        this.configs.sort((a, b) => {
          const timeA = a.enduserdata?.savetime ? new Date(a.enduserdata.savetime).getTime() : 0;
          const timeB = b.enduserdata?.savetime ? new Date(b.enduserdata.savetime).getTime() : 0;
          return timeB - timeA; // Descending order
        });
      }

      console.log('configs', this.configs);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  sendmail(configuration: any) {
    this.afs.collection("mail")
      .add({
        to: configuration.enduserdata.mail,
        message: {
          subject: "Hello from Firebase!",
          text: "This is the plaintext section of the email body.",
          html: "This is the <code>HTML</code> section of the email body.",
        },
      })
      .then(() => console.log("Queued email for delivery!"));
  }
}
