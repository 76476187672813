<div class="analytics-dashboard">
    <div class="dashboard-header">
        <h1>User Analytics Dashboard</h1>

        <div class="filters">
            <div class="date-range">
                <label>Date Range:</label>
                <mat-form-field appearance="outline">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [value]="dateRange.start">
                        <input matEndDate placeholder="End date" [value]="dateRange.end">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="updateDateRange({start: dateRange.start, end: dateRange.end})">Apply</button>
            </div>

            <button mat-raised-button color="accent" (click)="exportDataAsCsv()">
                <mat-icon>download</mat-icon> Export as CSV
            </button>
        </div>
    </div>

    <div class="metrics-cards">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Users</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="metric-value">{{userCount}}</div>
                <div class="metric-label">Unique Users</div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Sessions</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="metric-value">{{sessionCount}}</div>
                <div class="metric-label">Total Sessions</div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Events</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="metric-value">{{filteredData.length}}</div>
                <div class="metric-label">Total Events</div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="charts-row">
        <mat-card class="chart-card">
            <mat-card-header>
                <mat-card-title>Events by Type</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="eventTypeData.length > 0">
                    <ngx-charts-pie-chart [results]="eventTypeData" [scheme]="colorScheme" [gradient]="true" [legend]="true" [labels]="true">
                    </ngx-charts-pie-chart>
                </div>
                <div *ngIf="eventTypeData.length === 0" class="no-data">
                    No data available
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="chart-card">
            <mat-card-header>
                <mat-card-title>User Activity Over Time</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="userActivityData.length > 0">
                    <ngx-charts-bar-vertical [results]="userActivityData" [scheme]="colorScheme" [gradient]="true" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Date'"
                        [yAxisLabel]="'Event Count'">
                    </ngx-charts-bar-vertical>
                </div>
                <div *ngIf="userActivityData.length === 0" class="no-data">
                    No data available
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="charts-row">
        <mat-card class="chart-card">
            <mat-card-header>
                <mat-card-title>Primitive Usage</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="primitiveUsageData.length > 0">
                    <ngx-charts-bar-horizontal [results]="primitiveUsageData" [scheme]="colorScheme" [gradient]="true" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Count'"
                        [yAxisLabel]="'Primitive Type'">
                    </ngx-charts-bar-horizontal>
                </div>
                <div *ngIf="primitiveUsageData.length === 0" class="no-data">
                    No data available
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="chart-card">
            <mat-card-header>
                <mat-card-title>Average FPS by Model Complexity</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="performanceData.length > 0">
                    <ngx-charts-bar-horizontal [results]="performanceData" [scheme]="colorScheme" [gradient]="true" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'FPS'"
                        [yAxisLabel]="'Complexity'">
                    </ngx-charts-bar-horizontal>
                </div>
                <div *ngIf="performanceData.length === 0" class="no-data">
                    No data available
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="data-table-section">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Event Data</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="filter-field">
                    <mat-label>Filter by Event Type</mat-label>
                    <mat-select multiple [(ngModel)]="selectedEventTypes" (selectionChange)="updateEventTypeFilter(selectedEventTypes)">
                        <mat-option *ngFor="let item of eventTypeData" [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" class="data-table">
                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                        <td mat-cell *matCellDef="let item">{{item.clientTimestamp | date:'medium'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="userId">
                        <th mat-header-cell *matHeaderCellDef>User ID</th>
                        <td mat-cell *matCellDef="let item">{{item.userId}}</td>
                    </ng-container>

                    <ng-container matColumnDef="sessionId">
                        <th mat-header-cell *matHeaderCellDef>Session ID</th>
                        <td mat-cell *matCellDef="let item">{{item.sessionId}}</td>
                    </ng-container>

                    <ng-container matColumnDef="eventName">
                        <th mat-header-cell *matHeaderCellDef>Event</th>
                        <td mat-cell *matCellDef="let item">{{item.eventName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef>Details</th>
                        <td mat-cell *matCellDef="let item">
                            <button mat-icon-button (click)="item.showDetails = !item.showDetails">
                                <mat-icon>{{item.showDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details-expand">
                        <td mat-cell *matCellDef="let item" [attr.colspan]="5">
                            <div class="details-container" *ngIf="item.showDetails">
                                <pre>{{item | json}}</pre>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['timestamp', 'userId', 'sessionId', 'eventName', 'details']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['timestamp', 'userId', 'sessionId', 'eventName', 'details']"></tr>

                    <tr mat-row *matRowDef="let row; columns: ['details-expand']; when: showExpansionRow"></tr>
                </table>

                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>