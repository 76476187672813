<app-adminnav></app-adminnav>


<div style="position:relative">

    <h3 style="margin-left:20px;margin-bottom: 20px;float:left;">Public Projects</h3>

    <div style="float:right;margin-right:20px;margin-bottom:10px;">
        <input type="text" class="form-control" placeholder="Search projects..." [(ngModel)]="searchText" (keyup)="filterProjects()">
    </div>

    <div style="float:left;margin-left:10px;">Selected Project: {{curProject?.id}}</div>
    <br>
    <div style="float:left;margin-left:10px;">Selected Category: {{curCategoryID}}</div>

    <div (click)="save()" class="btn btn-primary">Save</div>

    <button class="btn btn-primary" style="float:right;margin-right:20px;" (click)="showexamples=!showexamples">Examples</button>
    <div [hidden]="!showexamples" style="background-color: white;position:absolute;width:400px; height: 300px;padding:10px; top:40px;right:20px;border:1px solid rgb(184, 184, 184);">
        <tree-root [nodes]="examples" (nodeActivate)="node2Activate($event)"></tree-root>
    </div>

    <button class="btn btn-primary" style="float:right;margin-right:20px;" (click)="showcategories=!showcategories">Categories</button>
    <div [hidden]="!showcategories" style="background-color: white;position:absolute;width:400px; height: 300px;padding:10px; top:40px;right:20px;border:1px solid rgb(184, 184, 184);">
        <tree-root [nodes]="nodes" (select)="select($event)" (activate)="activate($event)" (nodeActivate)="nodeActivate($event)"></tree-root>
    </div>

    <table class="table table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th></th>
                <th>uid</th>
                <th>category</th>
                <th>example</th>
                <!-- <th>Project Status</th>
                <th>Project Start Date</th>
                <th>Project End Date</th>
                <th>Project Budget</th>
                <th>Project Manager</th>
                <th>Project Team</th>
                <th>Project Tasks</th>
                <th>Project Files</th>
                <th>Project Notes</th>
                <th>Project Actions</th> -->
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let project of filteredProjects">

                <tr [style.background]="project==curProject ? '#dedede':''">
                    <td (click)="curProject=project"><a [href]="'/p/'+project.id">{{project.name}}</a></td>
                    <td>
                        <img [src]="project.image" style="max-height:40px;max-width:50px;margin-right:10px;float:left;" />

                    </td>
                    <td>{{project.userid}}</td>
                    <td>
                        {{project.publicCategoryID}}
                        <button *ngIf="project.publicCategoryID" class="btn btn-outline-danger btn-sm btn-xs" (click)="delCategory(project)"><i class="fa fa-trash"></i></button>
                    </td>
                    <td>
                        {{project.publicExampleID}}
                        <button *ngIf="project.publicExampleID" class="btn btn-outline-danger btn-sm btn-xs" (click)="delExample(project)"><i class="fa fa-trash"></i></button>
                    </td>
                    <!-- <td>{{project.projectStatus}}</td>
                    <td>{{project.projectStartDate}}</td>
                    <td>{{project.projectEndDate}}</td>
                    <td>{{project.projectBudget}}</td>
                    <td>{{project.projectManager}}</td>
                    <td>{{project.projectTeam}}</td>
                    <td>{{project.projectTasks}}</td>
                    <td>{{project.projectFiles}}</td>
                    <td>{{project.projectNotes}}</td>
                    <td>
                        <button class="btn btn-primary">Edit</button>
                        <button class="btn btn-danger">Delete</button>
                    </td> -->
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>