<div class="padding: 10px 20px;">


    <div style="padding-bottom:40px;">{{text}}</div>

    <div style="">

        <button style="margin-right: 40px;" class="btn btn-primary" (click)="onYesClick()">Yes</button>
        <button class="btn btn-default" (click)="onNoClick()">No</button>

    </div>
</div>