import { ThisReceiver } from '@angular/compiler';
import { ApplicationRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { toNumber } from 'lodash';
import { Utils } from '../utils';

@Component({
  selector: 'app-dialogshape',
  templateUrl: './dialogshape.component.html',
  styleUrls: ['./dialogshape.component.scss']
})
export class DialogshapeComponent implements OnInit {

  circles = [
    { cx: 100, cy: 100 },
    { cx: 150, cy: 25 },
    { cx: 150, cy: 75 },
    { cx: 200, cy: 0 },
  ];

  curcircle = -1;
  value1 = "";

  mdown = false;
  copytable = false;
  cellref = "D10";
  private hotRegisterer = new HotTableRegisterer();
  public hot: Handsontable;
  public hot2: Handsontable;
  hotid = 'hotInstance';
  hotid2 = 'hotInstance2';
  @ViewChild("rendererContainer") rendererContainer: ElementRef;
  @ViewChild("hot") hotContainer: ElementRef;
  @ViewChild("hot2") hotContainer2: ElementRef;

  // Add new properties for panning
  panOffset = { x: 0, y: 0 };
  isPanning = false;
  lastMousePos = { x: 0, y: 0 };

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, public appref: ApplicationRef) {
    this.value1 = JSON.parse(JSON.stringify(data.value));
    var v = this.value1.split(";");
    this.circles = [];
    for (var i = 0; i < v.length; i++) {
      var p = v[i].replace("(", "").replace(")", "").split(",");
      this.circles.push({ cx: parseInt(p[0]), cy: parseInt(p[1]) });
    }

  }

  ngOnInit(): void {
    // Prevent context menu on right click
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    // Initialize grid
    this.gridchange();
  }

  afterChange = async (e) => {

    console.log('afterChange', e);
    // [1, 0, 100, '33']#try{
    try {
      if (e[0][1] == 0) {
        this.circles[e[0][0]].cx = toNumber(e[0][3]);
      }
      if (e[0][1] == 1) {
        this.circles[e[0][0]].cy = toNumber(e[0][3]);
      }
      this.updateData();


    } catch (err) {
      console.log(err);
    }
  }

  currow = null;
  cellSelected = async (a) => {
    this.currow = a;
    console.log('selecred', a, this.circles);
  }

  dataset = [];
  dataset2 = [];
  polyline = "";
  polyline2 = "";

  hotsettings = {

  };
  svgMouseDown(event: MouseEvent) {
    if (event.button === 2) { // Right mouse button
      this.isPanning = true;
      this.lastMousePos = { x: event.offsetX, y: event.offsetY };
      event.preventDefault();
    }
  }

  mousedown(event: MouseEvent, i: number) {
    event.stopPropagation(); // Prevent event from bubbling to SVG
    this.curcircle = i;
    this.currow = i;
    this.mdown = true;
    try {
      this.hot.selectCell(i, 0, i, 1, false, false);
      console.log(event);
    } catch (error) {
      console.error('Error selecting cell:', error);
    }
  }

  mouseup(event: MouseEvent) {
    this.mdown = false;
    this.isPanning = false;
    console.log(event);
  }

  mousemove(event: MouseEvent) {
    if (this.isPanning) {
      const dx = event.offsetX - this.lastMousePos.x;
      const dy = event.offsetY - this.lastMousePos.y;
      this.panOffset.x += dx;
      this.panOffset.y += dy;
      this.lastMousePos = { x: event.offsetX, y: event.offsetY };
      return;
    }

    if (this.mdown) {
      if (this.curcircle > -1) {
        // Get the SVG element's bounding rect
        const svgRect = (event.target as SVGElement).closest('svg').getBoundingClientRect();

        // Calculate the center point of the SVG
        const centerX = svgRect.width / 2;
        const centerY = svgRect.height / 2;

        // Convert screen coordinates to SVG coordinates, properly scaling the offset
        const svgX = -(event.offsetX - centerX - (this.panOffset.x / this.zoom)) / this.zoom;
        const svgY = (event.offsetY - centerY - (this.panOffset.y / this.zoom)) / this.zoom;

        this.circles[this.curcircle].cx = svgX;
        this.circles[this.curcircle].cy = svgY;

        if (this.snap) {
          this.snapToGrid(this.circles[this.curcircle]);
        }
        this.updateData();
      }
    }
  }

  snap = true;
  gridsize = 50;
  snapToGrid(curcircle) {

    // size of grid cells in pixels
    var columns = 10; // number of columns in the grid
    var rows = 10; // number of rows in the grid
    // Calculate the position of the element relative to the top-left corner of the grid
    // const elementLeft = element.offsetLeft - grid.offsetLeft;
    // const elementTop = element.offsetTop - grid.offsetTop;

    // Calculate the column and row that the element is currently in
    const column = Math.round(curcircle.cx / this.gridsize);
    const row = Math.round(curcircle.cy / this.gridsize);

    // Calculate the top and left positions of the nearest grid cell
    const snappedLeft = column * this.gridsize;;// + grid.offsetLeft;
    const snappedTop = row * this.gridsize;// + grid.offsetTop;
    curcircle.cx = snappedLeft;
    curcircle.cy = snappedTop;
    // Update the element's position
    // element.style.left = snappedLeft + 'px';
    // element.style.top = snappedTop + 'px';
  }

  xs = [];
  ys = [];
  gridchange() {
    // Calculate number of grid lines based on viewBox size and grid size
    var countx = Math.round(600 / this.gridsize) + 4; // Add extra lines for padding
    // if odd add one
    if (countx % 2 == 1) {
      countx++;
    }

    var county = Math.round(400 / this.gridsize) + 4; // Add extra lines for padding
    // if odd add one
    if (county % 2 == 1) {
      county++;
    }

    // Create arrays with more grid lines
    this.xs = Array.from({ length: countx + 1 }, (_, i) => i - Math.floor(countx / 2));
    this.ys = Array.from({ length: county + 1 }, (_, i) => i - Math.floor(county / 2));
  }

  updateData() {
    try {

      this.polyline = "";
      this.dataset = [];
      this.value1 = "";
      if (this.copytable) {
        this.value1 = '="';

        var ij = Utils.cellname2indices(this.cellref);
        for (var i = 0; i < this.circles.length; i++) {
          this.polyline += this.circles[i].cx + "," + this.circles[i].cy + " ";
          this.dataset.push([this.circles[i].cx, this.circles[i].cy]);
          var namex = Utils.indices2cellaname(ij[0], ij[1] + i);
          var namey = Utils.indices2cellaname(ij[0] + 1, ij[1] + i);
          this.value1 += '("&' + namex + '&","&' + namey + '&");';

        }
        this.value1 = this.value1.slice(0, -1)
        this.value1 += '"';
      }
      else {
        for (var i = 0; i < this.circles.length; i++) {
          this.polyline += this.circles[i].cx + "," + this.circles[i].cy + " ";
          this.dataset.push([this.circles[i].cx, this.circles[i].cy]);
          this.value1 += "(" + this.circles[i].cx + "," + this.circles[i].cy + ");";
        }
        this.value1 = this.value1.slice(0, -1);
      }

      this.hot.render();
      this.appref.tick();
    } catch (err) {
      console.log(err);
    }
  }


  apply() {
    var ij = Utils.cellname2indices(this.cellref);
    var ij2 = [ij[1], ij[0]];
    this.dialogRef.close({ val: this.value1, cellref: ij2, copytable: this.copytable, td: this.dataset });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  ngAfterViewInit(): void {

    this.hot = this.hotRegisterer.getInstance(this.hotid); //.loadData([['new', 'data']]);
    this.hot2 = this.hotRegisterer.getInstance(this.hotid2); //.loadData([['new', 'data']]);

    this.updateData();
  }

  zoom = 1;

  zoomIn() {
    this.zoom += 0.1;
  }
  zoomOut() {
    this.zoom -= 0.1;

  }
  addPoint() {
    var lastcircle = this.circles[this.circles.length - 1];
    this.circles.push({ cx: lastcircle.cx + 20, cy: lastcircle.cy + 20 });
    this.updateData();
  }
  removePoint() {
    this.circles.pop();
    this.updateData();
  }

  // Add wheel event handler for zooming
  onWheel(event: WheelEvent) {
    event.preventDefault();
    const zoomFactor = 0.1;
    if (event.deltaY < 0) {
      this.zoom *= (1 + zoomFactor);
    } else {
      this.zoom *= (1 - zoomFactor);
    }
    // Clamp zoom between reasonable values
    this.zoom = Math.max(0.1, Math.min(5, this.zoom));
  }
}
