<h2>Save configuration</h2>
<div class="row">
    <div class="col-md-6">
        <input type="text" *ngIf="project.userformname" class="form-control form-group" [(ngModel)]="name" placeholder="name">
        <input type="text" *ngIf="project.userformmail" class="form-control form-group" [(ngModel)]="mail" placeholder="mail">
        <input type="text" *ngIf="project.userformphone" class="form-control form-group" [(ngModel)]="phone" placeholder="phone">
        <input type="text" *ngIf="project.userformcompany" class="form-control form-group" [(ngModel)]="company" placeholder="company">





    </div>
    <div class="col-md-6">
        <textarea class="form-control" *ngIf="project.userformcomment" form-group [(ngModel)]="comment" placeholder="comment"></textarea>

    </div>
</div>

<div class="row">
    <div class="col-md-6">

    </div>
    <div class="col-md-6">

    </div>
</div>


<hr>

<div class="row">
    <div class="col-md-12">
        <button class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>