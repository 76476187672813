import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inputdialog',
  templateUrl: './inputdialog.component.html',
  styleUrls: ['./inputdialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputdialogComponent {

  constructor(public dialogRef: MatDialogRef<InputdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
