
import * as THREE from "three";
// import { MathUtils, Float32BufferAttribute } from "three";
//import * as firebase from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { AngularFireStorage } from "@angular/fire/storage";
import { ThreeUtils } from "./threeutils";


class Taskmanager {

    loader: GLTFLoader;
    constructor(public afs: AngularFirestore, public storage: AngularFireStorage, public renderer: THREE.WebGLRenderer, public camera: THREE.PerspectiveCamera, public scene: THREE.Scene, public controls: any) {
        THREE.Cache.enabled = true;
        //  console.log('cache', THREE.Cache.files)
        this.loader = new GLTFLoader();


        this.ProcessTasks();
    }


    public async ProcessTasks() {
        var userid = "8OPd7JHVKNcFu5hmZLDE16jPDEB2";
        // this.afs.collection("projects/userprojects/" + userid, ref => ref.where('image', '==', "")).valueChanges({ idField: 'id' }).subscribe(x => {

        //     console.log("userpojects", x);
        //     if (x.length > 0) {
        //         var task = x[0];
        //         console.log("task0", task);
        //     }
        // });



        this.scene.fog.color = new THREE.Color(null);
        this.scene.background = new THREE.Color(null);

        //  while (true) {


        this.afs.collection("tasks", ref => ref.where('state', '==', 'pending').limit(1)).valueChanges({ idField: 'id' }).subscribe(async (tasks: any) => {
            console.log('tasks', tasks);
            for (var i = 0; i < tasks.length; i++) {
                console.log('task', tasks[i]);
                if (tasks[i].state == "running") {
                    //    this.runningtasks.push(t);
                }
                if (tasks[i].state == "pending") {
                    //this.pendingtasks.push(t);

                    try {
                        if (tasks[i].typ == "gltf") {
                            console.log('gltf task', tasks[i]);
                            const url = tasks[i].url;
                            var gltf = await (this.loader).loadAsync(url);

                            console.log('gltf', gltf);
                            gltf.scene.scale.set(1000, 1000, 1000);
                            this.scene.add(gltf.scene);
                            this.centerGltf(gltf);
                            // this.takeGltfScreenshot(this.renderer, gltf, this.camera);
                            ThreeUtils.zoomObject(this.camera, this.controls, gltf.scene);
                            this.renderer.render(this.scene, this.camera);


                            //



                            // wait 5 seconds

                            this.renderer.domElement.toBlob((blob) => {
                                // upload to firebase storage
                                this.storage.ref('thumbnails/' + tasks[0].dbid + '.png').put(blob).then(async (snapshot) => {
                                    // update file meta
                                    var url = await snapshot.ref.getDownloadURL();
                                    var path = tasks[0].dbpath + tasks[0].dbid;
                                    this.afs.doc(path).update({
                                        thumbnail: url,
                                    })

                                    // update task
                                    await this.afs.collection("tasks").doc(tasks[0].id).update({ state: "done", thumbnail: snapshot.ref.fullPath });
                                    // page reload
                                    window.location.reload();

                                });

                            });




                        }
                    } catch (e) {
                        console.log('error', e);
                    }

                }

            }
            await new Promise(r => setTimeout(r, 5000));

        });


        //        }

    }

    public takeGltfScreenshot(renderer, scene, camera) {
        renderer.render(scene, camera);

        // gtlf laden, dann screenshot machen, dann update metainfos

        renderer.domElement.toBlob((blob) => {
            // const storageRef = firebase.storage().ref();
            // var imageRef = storageRef.child('thumbnails/' + 'fdsfs' + '.png');
            // const uploadTask = imageRef.put(blob);
            // uploadTask.on(
            //     firebase.storage.TaskEvent.STATE_CHANGED,
            //     snapshot => {
            //         const snap = snapshot as firebase.storage.UploadTaskSnapshot;
            //         console.log('snap', snap);
            //     },
            //     error => {
            //         console.log(error);
            //     },
            //     () => {
            //         var url2 = uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            //             console.log(url)

            //         })
            //         return undefined;
            //     }
            // );
        }, 'image/png', 1.0);

    }

    public centerGltf(gltf) {
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());

        gltf.scene.position.x += (gltf.scene.position.x - center.x);
        gltf.scene.position.y += (gltf.scene.position.y - center.y);
        gltf.scene.position.z += (gltf.scene.position.z - center.z);
    }






}

export { Taskmanager };