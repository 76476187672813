import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translatesplit'
})
export class TranslatesplitPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    var lang = 'de';
    value = value.toString();
    if (args?.length > 0)
      lang = args[0] as string;

    if (value == null)
      return null;
    var r = value.split('//');
    if (lang == 'de')
      return r[0];

    if (lang == 'fr' && r.length > 1)
      return r[1];

    if (lang == 'en' && r.length > 2)
      return r[2];


    return r[0];
  }

}
