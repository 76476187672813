// vgl https://firebase.googleblog.com/2018/03/cleanse-your-angular-components.html

import { Component, OnInit, Injectable, ViewChild } from "@angular/core";
import { Router, CanActivate, ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { AngularFirestore } from "@angular/fire/firestore";
import { User } from "../admin/users/users.component";
import { TranslateService } from "@ngx-translate/core";
import { TosComponent } from "./tos/tos.component";

enum AuthProvider {
  ALL = "all",
  ANONYMOUS = "anonymous",
  EmailAndPassword = "firebase",
  Google = "google",
  Apple = "apple",
  Facebook = "facebook",
  Twitter = "twitter",
  Github = "github",
  Microsoft = "microsoft",
  Yahoo = "yahoo",
  PhoneNumber = "phoneNumber"
}
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  vm: any;
  test: AuthProvider;
  usero: any;
  savemessage2: string;
  oldpass: string;
  newpass: string;
  newpass2: string;
  email: any;
  authState: firebase.auth.UserCredential;
  tosAccepted = false;

  constructor(public translate: TranslateService, public afAuth: AngularFireAuth, public afs: AngularFirestore, public router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar, private dialog: MatDialog) {


    // this.route.queryParams.subscribe(params => {
    //   console.log("params", params, afAuth);

    //   // TODO: login optimieren, hmac wird aus shopify immer übergeben
    //   //   if (params["hmac"]) {
    //   //      this.tabGroup.selectedIndex = 1;
    //   //     return;
    //   //   }

    //   // afAuth.currentUser.then((user) => {
    //   //   console.log('login user', user);
    //   // });

    console.log('af ', this.afAuth);
    this.afAuth.authState.subscribe(x => {

      console.log("authstate sub: ", x);
      this.afAuth.getRedirectResult().then(result => {
        console.log("getreditrectresult ", result);
        if (result) {
          this.router.navigateByUrl("dashboard");
          //   if (x)
          //     if (x.email === "info@seoaachen.de") {
          //       console.log("adminlogin");
          //       this.router.navigate(["/admin"]);
          //     } else {
          //       this.router.navigate(["/dashboard"]);
          //     }
        }
      });
    });
    //    });
  }
  ngOnInit() {

  }

  printUser(event) {
    console.log('printUser', event);
  }

  printError(event) {
    console.error(event);
  } loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afAuth
      .signInWithPopup(provider)
      .then(credential => {
        console.log("credential ", credential);
        //   this.authState = credential.user;
        this.router.navigate(["/dashboard"]);
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }
  loginWithEmail(mail, pass) {
    console.log("loginuser ", mail, pass);
    this.afAuth
      .signInWithEmailAndPassword(mail, pass)
      .then(x => {
        // this.items = this.afs.collection("users", ref => ref.orderBy('Datum', 'desc')).snapshotChanges().map(actions => {
        //     return actions.map(a => {
        //         const data = a.payload.doc.data();
        //         const id = a.payload.doc.id;
        //         return { id, ...data };
        //     });
        // });

        if (x.user.email === "info@seoaachen.de") {
          console.log("adminlogin");
          this.router.navigate(["/admin"]);
        } else {
          this.router.navigate(["/dashboard"]);
        }
      })
      .catch(error => {
        this.snackBar.open("Falsche E-Mail Adresse oder Passwort!", "", { duration: 12000 });
      });
  }
  registerWithEmail(mail, pass) { }
  logout() {
    this.afAuth.signOut();
    this.router.navigate(["/login"]);
  }

  // private changeCountry(val) {
  //   this.usero.country = val;
  //   console.log(val, this.usero.country);
  //   if (
  //     this.usero.country === "Austria" ||
  //     this.usero.country === "Belgium" ||
  //     this.usero.country === "Bulgaria" ||
  //     this.usero.country === "Croatia" ||
  //     this.usero.country === "Cyprus" ||
  //     this.usero.country === "Czech Republic" ||
  //     this.usero.country === "Denmark" ||
  //     this.usero.country === "Estonia" ||
  //     this.usero.country === "Finland" ||
  //     this.usero.country === "France" ||
  //     this.usero.country === "Greece" ||
  //     this.usero.country === "Hungary" ||
  //     this.usero.country === "Ireland" ||
  //     this.usero.country === "Italy" ||
  //     this.usero.country === "Latvia" ||
  //     this.usero.country === "Lithuania" ||
  //     this.usero.country === "Belgium" ||
  //     this.usero.country === "Bulgaria" ||
  //     this.usero.country === "Croatia" ||
  //     this.usero.country === "Cyprus" ||
  //     this.usero.country === "Czech Republic" ||
  //     this.usero.country === "Luxembourg" ||
  //     this.usero.country === "Malta" ||
  //     this.usero.country === "Netherlands" ||
  //     this.usero.country === "Poland" ||
  //     this.usero.country === "Portugal" ||
  //     this.usero.country === "Romania" ||
  //     this.usero.country === "Slovakia" ||
  //     this.usero.country === "Slovenia" ||
  //     this.usero.country === "Spain" ||
  //     this.usero.country === "Sweden" ||
  //     this.usero.country === "United Kingdom"
  //   )
  //     this.showtax = true;
  //   else this.showtax = false;
  // }

  public saveNewPass() {
    if (this.newpass2 !== this.newpass) {
      this.savemessage2 = "Password confirmation incorrect";

      return;
    }

    //    this.afAuth.auth.verifyPasswordResetCode;
    this.afAuth
      .confirmPasswordReset("code", this.vm.newpass)
      .then(x => { })
      .catch(err => {
        this.snackBar.open(err, "", { duration: 12000 });
      });

    // ref.changePassword(
    //   {
    //     email: this.usero.email,
    //     oldPassword: this.oldpass,
    //     newPassword: this.newpass
    //   },
    //   error => {
    //     if (error) {
    //       switch (error.code) {
    //         case "INVALID_PASSWORD":
    //           this.savemessage2 = "The specified user account password is incorrect.";
    //           break;
    //         case "INVALID_USER":
    //           this.savemessage2 = "The specified user account does not exist.";
    //           break;
    //         default:
    //           this.savemessage2 = "Error changing password: " + error;
    //       }
    //     } else {
    //       this.savemessage2 = "User password changed successfully!";
    //     }
    //   }
    // );
  }

  public addUser() {
    // this.afAuth.auth.createUserWithEmailAndPassword(this.vm.regmail, this.vm.regpass).then(x => {
    //   this.afAuth.auth
    //     .createUserAndRetrieveDataWithEmailAndPassword(this.vm.regmail, this.vm.regpass)
    //     .then(x => { })
    //     .catch(err => {
    //       this.snackBar.open(err, "", { duration: 12000 });
    //     });
    //   (error, fbauth) => {
    //     if (error) {
    //     } else {
    //       ///standardwerte festlegen
    //       const newuser = {
    //         mail: this.vm.regmail
    //       };
    //       this.afs
    //         .collection("users")
    //         .doc("erew")
    //         .set(newuser);
    //       // let r = new Firebase(this.DBSERVER + "/users/" + this.fbauth.uid);
    //       // r.update({
    //       //   subscription: {
    //       //     diskusage: 0,
    //       //     cputime: 0,
    //       //     typ: "free"
    //       //   }
    //       //          });
    //     }
    //   }
    // );
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  //// Social Auth ////

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then(credential => {
        //   this.authState = credential.user;
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }

  //// Email/Password Auth ////

  showTosDialog(): void {
    const dialogRef = this.dialog.open(TosComponent, {
      width: '800px',
      maxHeight: '80vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('ToS dialog closed');
    });
  }

  onTosLinkClick(event: Event): void {
    event.preventDefault();
    this.showTosDialog();
  }

  emailSignUp(email: string, password: string) {
    if (!this.tosAccepted) {
      this.snackBar.open("Please accept the Terms of Service to continue", "", { duration: 5000 });
      return;
    }

    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(authState => {
        this.authState = authState;
        authState.user
          .sendEmailVerification()
          .then(success => {
            console.log("registered: ", authState.user);
            const u = {
              uid: authState.user.uid,
              registerDate: new Date().getTime(),
              lastLogin: new Date().getTime(),
              mail: authState.user.email,
              name: authState.user.email,
              tosAccepted: true,
              tosAcceptedDate: new Date().getTime()
            };
            console.log("user: ", u);
            this.afs.doc("/users/" + u.uid).set(<any>u);

            this.snackBar.open("please verify your email", "", { duration: 12000 });
            this.router.navigateByUrl("/dashboard");
          })
          .catch(err => {
            this.snackBar.open(err, "", { duration: 12000 });
          });
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const auth = firebase.auth();

    return auth
      .sendPasswordResetEmail(email)
      .then(() => this.snackBar.open("email sent", "", { duration: 12000 }))
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }
}
