import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mcmpipe'
})
export class McmpipePipe implements PipeTransform {

  transform(val: any, ...args: unknown[]): unknown {
    // if (args[0] === 'cm' || args[0] === 'm')
    //   return parseInt(value) / 100;
    // else

    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/,/g, "");
    } else {
      return "";
    }
    return val;
  }

}


@Pipe({
  name: 'mcmunitpipe'
})
export class McmunitPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    // if (value === 'cm')
    //   return 'm';
    // else
    return value;
  }

}
