<app-sidebar></app-sidebar>



<div style="backg2round-color:rgb(244, 244, 244);height:100%; padding: 50px 30px;" *ngIf="project">

    <div class="row">

        <h1 style="margin-left: 20px;margin-bottom:20px; float:left;font-size:32px;">Project Configurations:
            {{project?.name}}</h1>
        <a target="_blank" style="margin-left: 20px; float:left;font-size:22px;" [href]="'p/'+project.id"><i class="fa fas fa-external-link-alt"></i></a>
        <hr>

    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="c2ard">
                <!-- <div class="card-header">
                    <h4 class="card-title">Configurations</h4>
                    <p class="card-category">

                    </p>
                </div> -->
                <div class="car2d-body" style="max-height: 85vh;overflow: auto;">
                    <ng-container>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Save Date</th>
                                    <th>User</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let configuration of configs">
                                <td>
                                    {{configuration.enduserdata.savetime | date:'medium'}}
                                </td>
                                <td>
                                    <div *ngIf="configuration.enduserdata.mail">
                                        <a [href]="'mailto:'+configuration.enduserdata.mail">{{configuration.enduserdata.mail}}</a>
                                    </div>
                                    <div *ngIf="configuration.enduserdata.phone">
                                        <strong>Phone:</strong> {{configuration.enduserdata.phone}}
                                    </div>
                                    <div *ngIf="configuration.enduserdata.name">
                                        <strong>Name:</strong> {{configuration.enduserdata.name}}
                                    </div>
                                    <div *ngIf="configuration.enduserdata.comment">
                                        <strong>Comment:</strong> {{configuration.enduserdata.comment}}
                                    </div>

                                </td>
                                <td>
                                    <!-- <a [href]="'p/'+projectid+'/'+configuration.id">Config
                                        {{configuration.enduserdata.name}}</a> -->
                                    <a class="btn btn-primary" [href]="'p/'+projectid+'/'+configuration.id">Open
                                        Configuration</a>
                                </td>
                                <!-- <a *ngIf="configuration.gltfurl" [href]="configuration.gltfurl">gltf</a> -->
                                <!-- <td>
                                    {{configuration.enduserdata.comment}}
                                    <button class="btn btn-primary" (click)="sendmail(configuration)">mail</button>
                                </td> -->

                            </tr>
                        </table>
                    </ng-container>

                    <!-- <mat-accordion multi>
                        <ng-container *ngFor="let configuration of configs">

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <table class="table">
                                        <tr>
                                            <td>
                                                {{configuration.enduserdata.savetime | date:'medium'}}
                                            </td>
                                            <td>
                                                <a [href]="'p/'+projectid+'/'+configuration.id">Config {{configuration.enduserdata.name}}</a>


                                                <a [href]="'p/'+projectid+'/'+configuration.id">Edit</a>
                                            </td>
                                            <td>
                                                {{configuration.enduserdata.mail}}


                                                <a *ngIf="configuration.gltfurl" [href]="configuration.gltfurl">gltf</a>

                                            </td>
                                            <td>
                                                {{configuration.enduserdata.comment}}
                                                <button class="btn btn-primary" (click)="sendmail(configuration)">mail</button>
                                            </td>

                                        </tr>
                                    </table>
                                </mat-expansion-panel-header>

                                <table class="table">
                                    <tr>
                                        <td colspan="4">

                                            <table>
                                                <tr>
                                                    <ng-container *ngFor="let key of configuration.inputs; let i=index">

                                                        <th *ngIf="i>0">{{key}}</th>
                                                    </ng-container>
                                                </tr>
                                                <tr>
                                                    <ng-container *ngFor="let value of configuration.vals; let i=index">


                                                        <td *ngIf="i>0">{{value}}</td>
                                                    </ng-container>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </mat-expansion-panel>

                        </ng-container>
                    </mat-accordion> -->


                </div>
            </div>
        </div>
    </div>
</div>