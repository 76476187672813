import { Injectable } from '@angular/core';
import * as firebase from "firebase";
import * as THREE from "three";
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class ScreenshotService2 {

    public static takeScreenshot(scene: THREE.Scene, camera: THREE.Camera, renderer: THREE.WebGLRenderer, projectid: string, customerid: string, project: any, afs: AngularFirestore, overwrite: boolean) {
        const originalFogColor = scene.fog?.color;
        const originalBackground = scene.background;

        scene.background = null;
        renderer.render(scene, camera);

        if (!project.imageoverwritten || overwrite) {
            renderer.setClearColor(0x000000, 0);
            renderer.domElement.toBlob((blob) => {
                const storageRef = firebase.storage().ref();
                const imageRef = storageRef.child('thumbnails/' + projectid + '.png');
                const uploadTask = imageRef.put(blob);

                uploadTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    snapshot => {
                        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
                        console.log('snap', snap);

                        scene.background = originalBackground;
                        if (scene.fog) scene.fog.color = originalFogColor;
                    },
                    error => {
                        console.log(error);
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                            console.log(url);
                            project.image = url;
                            if (overwrite) {
                                project.imageoverwritten = true;
                            }

                            afs.doc("projects/userprojects/" + customerid + "/" + projectid).update(JSON.parse(JSON.stringify(project)));
                        });
                    }
                );
            }, 'image/png', 1.0);
        }
    }
}