<app-uploadwidget style="float:left;" (uploadCompleted)="backgroundUploaded($event)" (uploadFailed)="uploadFailed($event)" [label]="'Upload File'" [path]="'/files/'+this.user?.uid +curpath">
</app-uploadwidget>
<div style="margin: 6px 10px 10px 3px;    bo2rder-right: 1px solid #b5b5b5;    float: left;    height: 37px;"></div>
<div class="btn btn-outline-primary" style="position: relative;float:left;color:#8C30F5;border-color:#8C30F5" (click)="uploaddlg=!uploaddlg">

  <div><i class="fa fas far fa-folder"></i> Create Folder</div>

  <div style="background:white;padding:5px 10px;position: absolute;z-index:99;top:60px;border:1px solid gray" class="box-shadow" (click)="$event.stopPropagation()" [hidden]="!uploaddlg">

    <mat-form-field>
      <input matInput style="color:black;" #foldername matInput placeholder="name" value="value">
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="addfolder(foldername.value);uploaddlg=false;">Add</button>
  </div>
</div>


<div style="margin: 6px 10px 10px 3px;    bord2er-right: 1px solid #b5b5b5;    float: left;    height: 37px;"></div>

<div style="float:left;width:450px">
  <div style="padding-right:10px;float:left;margin-right:10px;">View:</div>
  <!-- 
  <mat-checkbox [(ngModel)]="showimages" style="margin-right:10px;float:left;">Images</mat-checkbox>
  <mat-checkbox style="float:left; margin-right:30px;">Result Files</mat-checkbox> -->



  <!-- radio group -->
  <mat-radio-group [(ngModel)]="viewmode">
    <mat-radio-button value="list" style="margin-right:10px;">List</mat-radio-button>
    <mat-radio-button value="grid">Grid</mat-radio-button>
  </mat-radio-group>


</div>


<div class="3container">
  <div class="gap10" style="height:56px;"></div>

  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <button mat-stroked-button (click)="changefolder(curpath.substring(0, curpath.substring(0, curpath.length-1).lastIndexOf('/'))+'/')"><i class="fa fas fa-level-up-alt"></i> </button>
      </td>

      <td style="width:80%">
        Path: {{curpath}}
      </td>
      <td>
        <!-- <mat-form-field class="example-full-width"> -->
        <!-- <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" /> -->
        <!-- </mat-form-field> -->
        <!-- {{ filter }} -->
      </td>
      <td>
        <!-- <mat-form-field>
             <mat-label>Level</mat-label>
              <mat-select placeholder="all">
                <mat-option value="option">debug</mat-option>
                <mat-option value="option">error</mat-option>
                <mat-option value="option">info</mat-option>
                <mat-option value="option">all</mat-option>
              </mat-select>
            </mat-form-field> -->
      </td>

      <!-- <td>
        <button mat-raised-button>Filter</button>
      </td> -->
      <!-- <td>

            <div fxLayout="row wrap" fxLayoutAlign="space-around center">

              <mat-slide-toggle [id]="34343"> fasdfas </mat-slide-toggle>
              <mat-slide-toggle [id]="3433343">fdsfs fasdfas </mat-slide-toggle>
            </div>
          </td>
          <td>
            <button mat-raised-button (click)="openEditDialog(undefined)">new logitem</button>
          </td> -->
    </tr>
  </table>


  <div class="gap10"></div>

  <div class="m2at-elevation-z2">

    <!-- ----------------------- grid --------------------------------- -->
    <div *ngIf="viewmode=='grid'">
      <ng-container *ngFor="let row of dataSource.data">
        <div *ngIf="doFilter(row)" class="grid-container" style="float:left;width:200px;height:200px;border-radius:8px; margin:2px;">
          <div class="grid-item">



            <!-- <div (click)="upPath(oldpath)" style="margin:2px;;width:100%px;h2eight:100px;cursor:pointer;">
                <div *ngIf="oldpath" class="projectfolder" style="float:left;margin-right:20px;padding:5px 10px;border-radius:8px;"><i style="font-size:20px;" class="fa far fas far fa-arrow-alt-circle-up"></i>
                  Folder Up
                </div>
                <div style="padding:5px 10px;font-size:16px;font-weight:bold;">Current Folder: <span *ngIf="!curnode">root</span> {{curnode?.name}}</div>
              </div>
              <div style="clear:both;"></div>
              <div *ngFor="let e of entries; let i = index">
                <div class="projectfolder" style="margin:2px;border-radius:8px;padding:20px; w2idth:120px;h2eight:100px;float:left;cursor:pointer;" (click)="this.changePath(e.name,i,e)">
                  <img src="/assets/folder.png" style="height:100px;">
                  <br>
                  <div style="width:100%; text-align:center;font-size:16px;"> {{e.name}}</div>
                </div>
              </div> -->

            <div class="grid-item-content-header-title-text-name-text">


              <div *ngIf="row.typ=='folder'" (click)="changefolder( row.path)" style="cursor:pointer;text-align:center;">


                <div class="projectfolder" style="margin:2px;border-radius:8px;padding:20px; w2idth:120px;h2eight:100px;float:left;cursor:pointer;">
                  <img src="/assets/folder.png" style="height:100px;">
                  <br>
                  <div style="width:100%; text-align:center;font-size:16px;"> {{row.name}}</div>
                </div>

              </div>
              <div *ngIf="row.typ==='file'" style="text-align:center;cursor:pointer;" (click)="fileChoosen(row.url)">

                <img *ngIf="row.thumbnail" [src]="row.thumbnail" style="height:150px;max-width:190px;margin-top:9px;" />
                <img *ngIf="!row.thumbnail" [src]="row.url" style="height:150px;max-width:190px;margin-top:9px;" />
                <br>
                <a style="font-size: 14px;color: #007bff;display:block; white-space: nowrap;cursor: pointer;max-width:180px;overflow:hidden;text-align:center; text-overflow: ellipsis;" htref="#"
                  target="_blank">{{row.name}}</a>
              </div>

            </div>
          </div>

        </div>
      </ng-container>
    </div>


    <!-- ----------------------- list --------------------------------- -->

    <mat-table *ngIf="viewmode=='list'" #table [dataSource]="dataSource" matSort matSortActive="datetime" matSortDirection="desc" aria-label="Elements">
      <!-- datetime Column -->
      <ng-container matColumnDef="uploadtime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Uploaded</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
            <div>{{ row.uploadtime | date: "short" }}</div>
            <div style="font-size:10px;color:gray;">{{ row.uploadtime | amTimeAgo }}</div>

          </div>
        </mat-cell>
      </ng-container>
      <!-- meta Column -->
      <ng-container matColumnDef="meta">
        <mat-header-cell *matHeaderCellDef mat-sort-header>meta</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button *ngIf="row.meta != {} && row.meta !== '{}' && row.meta != undefined" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="PopOver Me!">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <!-- <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()"> -->
            <!-- popover content begin -->
            <div style="font-size:12px;height:400px;">
            </div>
            <!--  -->
            <!-- popover content end  -->
            <!-- </span> -->
          </mat-menu>
        </mat-cell>
      </ng-container>
      <!-- message Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>name</mat-header-cell>
        <mat-cell *matCellDef="let row" class="filefolderlink" style="min-width:600px;">
          <div *ngIf="row.typ=='folder'">

            <a [routerLink]="[]" (click)="changefolder( row.path)">
              <!-- <i class="fas fa-folder"></i>  -->
              <img src="/assets/folder.png" style="height:40px;">
              {{row.name}}
            </a>

          </div>
          <div *ngIf="row.typ==='file'">
            <!-- {{row|json}} -->
            <img [src]="row.thumbnail" style="height:40px;" />
            <img *ngIf="!row.thumbnail" [src]="row.url" style="height:40px;" />
            <a (click)="fileChoosen(row.url)" target="_blank">
              <!-- <i class="fa fa-file"></i>  -->
              {{row.name}}
            </a>
          </div>
        </mat-cell>


      </ng-container>
      <ng-container matColumnDef="path">
        <mat-header-cell *matHeaderCellDef mat-sort-header>path</mat-header-cell>
        <mat-cell *matCellDef="let row" style="width: 100px;">
          {{ row.path }}
        </mat-cell>
      </ng-container>
      <!-- source Column -->
      <ng-container matColumnDef="url">
        <mat-header-cell *matHeaderCellDef mat-sort-header>url</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.url }}
        </mat-cell>
      </ng-container>

      <!-- level Column -->
      <ng-container matColumnDef="level">
        <mat-header-cell *matHeaderCellDef mat-sort-header>level</mat-header-cell>
        <!-- <mat-cell *matCellDef="let row"><span [innerHTML]="row.level | debuglevel"></span></mat-cell> -->
      </ng-container>

      <!-- functions Column -->
      <ng-container matColumnDef="functions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-flat-button mat-small color="warn" class="btn-functions mat-small" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click">
            <i class="fa far fa-trash-alt"></i>
          </button>
          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
            <mat-card style="max-width: 300px">
              <mat-card-content>
                <a mat-button style="margin-right:10px;">cancel</a>
                <button *ngIf="row.typ=='file'" mat-flat-button color="warn" (click)="deleteItem(row)">
                  delete
                </button>

                <button *ngIf="row.typ=='folder'" mat-flat-button color="warn" (click)="deleteFolder(row.path)">
                  delete
                </button>


              </mat-card-content>
            </mat-card>
          </mde-popover>
          <!-- &nbsp;
          <button mat-raised-button mat-small class="btn-functions mat-small" (click)="openEditDialog(row)">
            <i class="fa far fa-window-restore"></i>
          </button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="50"
      [pageSizeOptions]="[5, 10, 50, 100]"> </mat-paginator> -->
  </div>
</div>