import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as TWEEN from '@tweenjs/tween.js';

/**
 * CameraFlight class for creating cinematic camera animations around a 3D object.
 * This class allows for smooth camera flights interpolating between a set of points
 * while always keeping a target object centered in the view.
 */
export class CameraFlight {
    private camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    private controls: OrbitControls;
    private scene: THREE.Scene;

    private waypoints: THREE.Vector3[] = [];
    private targetObject: THREE.Object3D | null = null;
    private objectCenter: THREE.Vector3 = new THREE.Vector3();

    public isPlaying: boolean = false;
    private currentTween: any = null;
    private flightDuration: number = 5000; // ms per segment
    private flightLoop: boolean = false;
    private onCompleteCallback: (() => void) | null = null;

    // Flight path visualization
    private pathCurve: THREE.CatmullRomCurve3 | null = null;
    private pathVisual: THREE.Line | null = null;
    private showPath: boolean = false;

    // Properties for manual animation
    private _manualAnimStartTime: number = 0;
    private _manualAnimProgress: number = 0;

    /**
     * Creates a new CameraFlight instance
     * 
     * @param camera The camera to animate
     * @param controls The OrbitControls for the camera
     * @param scene The THREE.Scene containing the objects
     */
    constructor(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
        controls: OrbitControls,
        scene: THREE.Scene) {
        this.camera = camera;
        this.controls = controls;
        this.scene = scene;
    }

    /**
     * Set the target object to focus on during the flight
     * 
     * @param object The 3D object to center on
     */
    setTargetObject(object: THREE.Object3D): void {
        this.targetObject = object;

        // Calculate the center of the object
        const boundingBox = new THREE.Box3().setFromObject(object);
        boundingBox.getCenter(this.objectCenter);
    }

    /**
     * Add a waypoint to the camera flight path
     * 
     * @param position Vector3 position or x,y,z coordinates
     */
    addWaypoint(position: THREE.Vector3 | number[]): void {
        if (Array.isArray(position)) {
            this.waypoints.push(new THREE.Vector3(position[0], position[1], position[2]));
        } else {
            this.waypoints.push(position.clone());
        }

        // Update the flight path visualization
        this.updatePathVisualization();
    }

    /**
     * Clear all waypoints
     */
    clearWaypoints(): void {
        this.waypoints = [];
        this.removePathVisualization();
    }

    /**
     * Generate waypoints automatically around the target object
     * 
     * @param count Number of waypoints to generate
     * @param distance Distance from the object to place waypoints
     * @param heightVariation Amount of vertical position variation (0-1)
     */
    generateWaypoints(count: number = 8, distance: number = 2.5, heightVariation: number = 0.3): void {
        console.log("Generating waypoints", { count, distance, heightVariation });

        if (!this.targetObject) {
            console.error('No target object set. Call setTargetObject first.');
            return;
        }

        // Get object size to determine appropriate distance
        const boundingBox = new THREE.Box3().setFromObject(this.targetObject);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);
        const maxSize = Math.max(size.x, size.y, size.z);
        const actualDistance = maxSize * distance;

        console.log("Object size:", size, "Max size:", maxSize, "Calculated distance:", actualDistance);

        // Keep first waypoint if it exists (typically the initial camera position)
        const initialWaypoint = this.waypoints.length > 0 ? this.waypoints[0].clone() : null;

        // Clear existing waypoints but preserve the first one if it exists
        this.clearWaypoints();

        // Add back the initial waypoint if it existed
        if (initialWaypoint) {
            this.addWaypoint(initialWaypoint);
            console.log("Preserved initial waypoint:", initialWaypoint);
        }

        // Generate points in a circle around the object, with height variation
        for (let i = 0; i < count; i++) {
            const angle = (i / count) * Math.PI * 2;
            const x = this.objectCenter.x + Math.cos(angle) * actualDistance;
            const y = this.objectCenter.y + Math.sin(angle) * actualDistance;
            const heightOffset = Math.sin(angle * 2) * maxSize * heightVariation;
            const z = this.objectCenter.z + heightOffset + maxSize * 0.5;

            this.addWaypoint([x, y, z]);
            console.log(`Waypoint ${i + (initialWaypoint ? 1 : 0)}: [${x}, ${y}, ${z}]`);
        }

        console.log(`Generated ${this.waypoints.length} waypoints around object`);
    }

    /**
     * Set the duration of the flight
     * 
     * @param milliseconds Total duration in milliseconds
     */
    setDuration(milliseconds: number): void {
        this.flightDuration = milliseconds;
    }

    /**
     * Set whether the flight should loop continuously
     * 
     * @param loop True to loop the flight path
     */
    setLoop(loop: boolean): void {
        this.flightLoop = loop;
    }

    /**
     * Set a callback function to be called when the flight completes
     * 
     * @param callback Function to call on completion
     */
    setOnComplete(callback: () => void): void {
        this.onCompleteCallback = callback;
    }

    /**
     * Toggle the visibility of the flight path visualization
     * 
     * @param visible True to show the path visualization
     */
    setPathVisibility(visible: boolean): void {
        this.showPath = visible;
        if (this.pathVisual) {
            this.pathVisual.visible = visible;
        } else if (visible) {
            this.updatePathVisualization();
        }
    }

    /**
     * Start the camera flight animation
     */
    start(): void {
        console.log("Starting camera flight", {
            waypoints: this.waypoints.length,
            targetObject: this.targetObject ? (this.targetObject.name || "unnamed object") : "none",
            camera: this.camera ? true : false,
            controls: this.controls ? true : false,
            objectCenter: this.objectCenter
        });

        if (!this.targetObject) {
            console.error('No target object set. Cannot start camera flight.');
            throw new Error('No target object set');
        }

        if (this.waypoints.length < 2) {
            console.error('Not enough waypoints for a flight. Need at least 2 waypoints.');

            // If we have current camera position as first waypoint, try to generate additional waypoints
            if (this.waypoints.length === 1) {
                console.log("Only one waypoint exists, generating additional waypoints");
                const currentPos = this.waypoints[0].clone();
                this.generateWaypoints();

                // If still not enough waypoints, throw error
                if (this.waypoints.length < 2) {
                    throw new Error('Failed to generate enough waypoints');
                }
            } else {
                throw new Error('Not enough waypoints');
            }
        }

        // Check if we already have a valid centerpoint
        if (this.objectCenter.length() === 0) {
            console.warn('Object center not set properly, recalculating...');
            // Recalculate center
            const boundingBox = new THREE.Box3().setFromObject(this.targetObject);
            boundingBox.getCenter(this.objectCenter);
            console.log("Updated object center:", this.objectCenter);
        }

        // Stop any existing animation first
        if (this.isPlaying) {
            this.stop();
        }

        this.isPlaying = true;
        this.disableControls();

        // Reset manual animation progress
        this._manualAnimStartTime = 0;
        this._manualAnimProgress = 0;

        // Start the animation
        this.animateFlightPath();
    }

    /**
     * Stop the camera flight animation
     */
    stop(): void {
        if (this.currentTween) {
            this.currentTween.stop();
            this.currentTween = null;
        }

        this.isPlaying = false;
        this.enableControls();

        // Reset camera control target
        if (this.targetObject) {
            this.controls.target.copy(this.objectCenter);
            this.controls.update();
        }
    }

    /**
     * Update method to be called in animation loop
     */
    update(): void {
        // TWEEN.update is now called in editor.component.ts animate method
        // No need to call it here again

        // If the flight is active, ensure camera is always looking at the target
        if (this.isPlaying && this.targetObject) {
            // Manual animation as a fallback if TWEEN isn't working
            if (!this.currentTween || !this.currentTween._isPlaying) {
                // Re-create the path curve if needed
                if (!this.pathCurve && this.waypoints.length >= 2) {
                    console.log("Recreating path curve");
                    this.pathCurve = new THREE.CatmullRomCurve3(this.waypoints);
                    this.pathCurve.closed = this.flightLoop;
                }

                // Manual animation based on time
                if (this.pathCurve) {
                    // Calculate progress based on time 
                    const now = Date.now();
                    if (!this._manualAnimStartTime) {
                        this._manualAnimStartTime = now;
                        this._manualAnimProgress = 0;
                    }

                    // Update progress
                    const elapsed = now - this._manualAnimStartTime;
                    this._manualAnimProgress = (elapsed % this.flightDuration) / this.flightDuration;

                    // Update camera position
                    const position = this.pathCurve.getPointAt(this._manualAnimProgress);
                    this.camera.position.copy(position);

                    // Log occasional updates
                    if (Math.random() < 0.01) {
                        console.log(`Manual animation progress: ${(this._manualAnimProgress * 100).toFixed(1)}%, position:`, position);
                    }
                }
            }

            // Always ensure the camera is looking at the target
            this.camera.lookAt(this.objectCenter);
        }
    }

    /**
     * Create or update the flight path visualization
     */
    private updatePathVisualization(): void {
        if (!this.showPath || this.waypoints.length < 2) return;

        this.removePathVisualization();

        // Create a smooth curve through all waypoints
        this.pathCurve = new THREE.CatmullRomCurve3(this.waypoints);
        this.pathCurve.closed = this.flightLoop;

        // Create a visual representation of the path
        const points = this.pathCurve.getPoints(50);
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const material = new THREE.LineBasicMaterial({ color: 0x00ff00, opacity: 0.35, transparent: true });

        this.pathVisual = new THREE.Line(geometry, material);
        this.scene.add(this.pathVisual);
    }

    /**
     * Remove the flight path visualization from the scene
     */
    private removePathVisualization(): void {
        if (this.pathVisual) {
            this.scene.remove(this.pathVisual);
            this.pathVisual.geometry.dispose();
            (this.pathVisual.material as THREE.Material).dispose();
            this.pathVisual = null;
        }

        this.pathCurve = null;
    }

    /**
     * Animate the camera along the flight path
     */
    private animateFlightPath(): void {
        console.log("Starting camera flight animation", {
            waypoints: this.waypoints.length,
            targetObject: this.targetObject ? this.targetObject.name || "unnamed object" : "none",
            centerPoint: this.objectCenter,
            isPlaying: this.isPlaying,
            currentCameraPos: this.camera.position.clone()
        });

        if (!this.pathCurve || this.waypoints.length < 2) {
            if (this.waypoints.length < 2) {
                console.error('Not enough waypoints for animation, need at least 2');
                return;
            }

            console.log("Creating new path curve");
            this.pathCurve = new THREE.CatmullRomCurve3(this.waypoints);
            this.pathCurve.closed = this.flightLoop;
        }

        // Animation progress values
        const progress = { t: 0 };
        const target = { t: 1 };

        // Log current camera position
        console.log("Starting camera position:", this.camera.position.clone());
        console.log("Target object center:", this.objectCenter);

        // Stop any existing tween
        if (this.currentTween) {
            this.currentTween.stop();
            this.currentTween = null;
        }

        // Create the tween animation
        this.currentTween = new TWEEN.Tween(progress)
            .to(target, this.flightDuration)
            .easing(TWEEN.Easing.Sinusoidal.InOut)
            .onStart(() => {
                console.log("Tween animation started");

                // Ensure path exists and is valid
                if (!this.pathCurve || this.waypoints.length < 2) {
                    console.log("Recreating path curve on animation start");
                    this.pathCurve = new THREE.CatmullRomCurve3(this.waypoints);
                    this.pathCurve.closed = this.flightLoop;
                }

                // Force initial position update
                try {
                    const initialPosition = this.pathCurve!.getPointAt(0);
                    console.log("Initial animation position:", initialPosition);
                    this.camera.position.copy(initialPosition);
                } catch (error) {
                    console.error("Error setting initial position:", error);
                }

                if (this.targetObject) {
                    this.camera.lookAt(this.objectCenter);
                    this.controls.target.copy(this.objectCenter);
                }
            })
            .onUpdate(() => {
                try {
                    // Get position on curve at current progress
                    const position = this.pathCurve!.getPointAt(progress.t);
                    this.camera.position.copy(position);

                    // Always look at the target object
                    if (this.targetObject) {
                        this.camera.lookAt(this.objectCenter);
                        this.controls.target.copy(this.objectCenter);
                    }

                    // Log occasional updates (not every frame to avoid console spam)
                    if (Math.random() < 0.01) {
                        console.log(`Animation progress: ${(progress.t * 100).toFixed(1)}%, position:`, position);
                    }
                } catch (error) {
                    console.error("Error in tween update:", error, progress.t);
                }
            })
            .onComplete(() => {
                console.log("Tween animation completed");
                if (this.flightLoop) {
                    // If looping, start the animation again
                    console.log("Looping enabled, restarting animation");
                    this.animateFlightPath();
                } else {
                    // Otherwise, finish the flight
                    console.log("Animation finished, restoring controls");
                    this.isPlaying = false;
                    this.enableControls();

                    if (this.onCompleteCallback) {
                        this.onCompleteCallback();
                    }
                }
            });

        // Make sure animation is properly started
        console.log("Starting animation tween");
        this.currentTween.start();

        // Force an immediate TWEEN update to trigger the animation
        TWEEN.update();
    }

    /**
     * Disable OrbitControls during camera flight
     */
    private disableControls(): void {
        this.controls.enabled = false;
    }

    /**
     * Re-enable OrbitControls after camera flight
     */
    private enableControls(): void {
        this.controls.enabled = true;
    }
} 